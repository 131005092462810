/* eslint-disable */
// file autogenerated by http://github.com/mathieutu/tailwind-ts


/** Because of { exportClassesChoice: true } or { exportClassNamesHelper: true } configuration. */
export type TailwindClass = (
  | 'sr-only'
  | 'not-sr-only'
  | 'focus:sr-only'
  | 'focus:not-sr-only'
  | 'appearance-none'
  | 'bg-fixed'
  | 'bg-local'
  | 'bg-scroll'
  | 'bg-transparent'
  | 'bg-white'
  | 'bg-black'
  | 'bg-gray-50'
  | 'bg-gray-100'
  | 'bg-gray-200'
  | 'bg-gray-300'
  | 'bg-gray-400'
  | 'bg-gray-500'
  | 'bg-gray-600'
  | 'bg-gray-700'
  | 'bg-gray-800'
  | 'bg-gray-900'
  | 'bg-cool-gray-50'
  | 'bg-cool-gray-100'
  | 'bg-cool-gray-200'
  | 'bg-cool-gray-300'
  | 'bg-cool-gray-400'
  | 'bg-cool-gray-500'
  | 'bg-cool-gray-600'
  | 'bg-cool-gray-700'
  | 'bg-cool-gray-800'
  | 'bg-cool-gray-900'
  | 'bg-red-50'
  | 'bg-red-100'
  | 'bg-red-200'
  | 'bg-red-300'
  | 'bg-red-400'
  | 'bg-red-500'
  | 'bg-red-600'
  | 'bg-red-700'
  | 'bg-red-800'
  | 'bg-red-900'
  | 'bg-orange-50'
  | 'bg-orange-100'
  | 'bg-orange-200'
  | 'bg-orange-300'
  | 'bg-orange-400'
  | 'bg-orange-500'
  | 'bg-orange-600'
  | 'bg-orange-700'
  | 'bg-orange-800'
  | 'bg-orange-900'
  | 'bg-yellow-50'
  | 'bg-yellow-100'
  | 'bg-yellow-200'
  | 'bg-yellow-300'
  | 'bg-yellow-400'
  | 'bg-yellow-500'
  | 'bg-yellow-600'
  | 'bg-yellow-700'
  | 'bg-yellow-800'
  | 'bg-yellow-900'
  | 'bg-green-50'
  | 'bg-green-100'
  | 'bg-green-200'
  | 'bg-green-300'
  | 'bg-green-400'
  | 'bg-green-500'
  | 'bg-green-600'
  | 'bg-green-700'
  | 'bg-green-800'
  | 'bg-green-900'
  | 'bg-teal-50'
  | 'bg-teal-100'
  | 'bg-teal-200'
  | 'bg-teal-300'
  | 'bg-teal-400'
  | 'bg-teal-500'
  | 'bg-teal-600'
  | 'bg-teal-700'
  | 'bg-teal-800'
  | 'bg-teal-900'
  | 'bg-blue-50'
  | 'bg-blue-100'
  | 'bg-blue-200'
  | 'bg-blue-300'
  | 'bg-blue-400'
  | 'bg-blue-500'
  | 'bg-blue-600'
  | 'bg-blue-700'
  | 'bg-blue-800'
  | 'bg-blue-900'
  | 'bg-indigo-50'
  | 'bg-indigo-100'
  | 'bg-indigo-200'
  | 'bg-indigo-300'
  | 'bg-indigo-400'
  | 'bg-indigo-500'
  | 'bg-indigo-600'
  | 'bg-indigo-700'
  | 'bg-indigo-800'
  | 'bg-indigo-900'
  | 'bg-purple-50'
  | 'bg-purple-100'
  | 'bg-purple-200'
  | 'bg-purple-300'
  | 'bg-purple-400'
  | 'bg-purple-500'
  | 'bg-purple-600'
  | 'bg-purple-700'
  | 'bg-purple-800'
  | 'bg-purple-900'
  | 'bg-pink-50'
  | 'bg-pink-100'
  | 'bg-pink-200'
  | 'bg-pink-300'
  | 'bg-pink-400'
  | 'bg-pink-500'
  | 'bg-pink-600'
  | 'bg-pink-700'
  | 'bg-pink-800'
  | 'bg-pink-900'
  | 'group-hover:bg-transparent'
  | 'group-hover:bg-white'
  | 'group-hover:bg-black'
  | 'group-hover:bg-gray-50'
  | 'group-hover:bg-gray-100'
  | 'group-hover:bg-gray-200'
  | 'group-hover:bg-gray-300'
  | 'group-hover:bg-gray-400'
  | 'group-hover:bg-gray-500'
  | 'group-hover:bg-gray-600'
  | 'group-hover:bg-gray-700'
  | 'group-hover:bg-gray-800'
  | 'group-hover:bg-gray-900'
  | 'group-hover:bg-cool-gray-50'
  | 'group-hover:bg-cool-gray-100'
  | 'group-hover:bg-cool-gray-200'
  | 'group-hover:bg-cool-gray-300'
  | 'group-hover:bg-cool-gray-400'
  | 'group-hover:bg-cool-gray-500'
  | 'group-hover:bg-cool-gray-600'
  | 'group-hover:bg-cool-gray-700'
  | 'group-hover:bg-cool-gray-800'
  | 'group-hover:bg-cool-gray-900'
  | 'group-hover:bg-red-50'
  | 'group-hover:bg-red-100'
  | 'group-hover:bg-red-200'
  | 'group-hover:bg-red-300'
  | 'group-hover:bg-red-400'
  | 'group-hover:bg-red-500'
  | 'group-hover:bg-red-600'
  | 'group-hover:bg-red-700'
  | 'group-hover:bg-red-800'
  | 'group-hover:bg-red-900'
  | 'group-hover:bg-orange-50'
  | 'group-hover:bg-orange-100'
  | 'group-hover:bg-orange-200'
  | 'group-hover:bg-orange-300'
  | 'group-hover:bg-orange-400'
  | 'group-hover:bg-orange-500'
  | 'group-hover:bg-orange-600'
  | 'group-hover:bg-orange-700'
  | 'group-hover:bg-orange-800'
  | 'group-hover:bg-orange-900'
  | 'group-hover:bg-yellow-50'
  | 'group-hover:bg-yellow-100'
  | 'group-hover:bg-yellow-200'
  | 'group-hover:bg-yellow-300'
  | 'group-hover:bg-yellow-400'
  | 'group-hover:bg-yellow-500'
  | 'group-hover:bg-yellow-600'
  | 'group-hover:bg-yellow-700'
  | 'group-hover:bg-yellow-800'
  | 'group-hover:bg-yellow-900'
  | 'group-hover:bg-green-50'
  | 'group-hover:bg-green-100'
  | 'group-hover:bg-green-200'
  | 'group-hover:bg-green-300'
  | 'group-hover:bg-green-400'
  | 'group-hover:bg-green-500'
  | 'group-hover:bg-green-600'
  | 'group-hover:bg-green-700'
  | 'group-hover:bg-green-800'
  | 'group-hover:bg-green-900'
  | 'group-hover:bg-teal-50'
  | 'group-hover:bg-teal-100'
  | 'group-hover:bg-teal-200'
  | 'group-hover:bg-teal-300'
  | 'group-hover:bg-teal-400'
  | 'group-hover:bg-teal-500'
  | 'group-hover:bg-teal-600'
  | 'group-hover:bg-teal-700'
  | 'group-hover:bg-teal-800'
  | 'group-hover:bg-teal-900'
  | 'group-hover:bg-blue-50'
  | 'group-hover:bg-blue-100'
  | 'group-hover:bg-blue-200'
  | 'group-hover:bg-blue-300'
  | 'group-hover:bg-blue-400'
  | 'group-hover:bg-blue-500'
  | 'group-hover:bg-blue-600'
  | 'group-hover:bg-blue-700'
  | 'group-hover:bg-blue-800'
  | 'group-hover:bg-blue-900'
  | 'group-hover:bg-indigo-50'
  | 'group-hover:bg-indigo-100'
  | 'group-hover:bg-indigo-200'
  | 'group-hover:bg-indigo-300'
  | 'group-hover:bg-indigo-400'
  | 'group-hover:bg-indigo-500'
  | 'group-hover:bg-indigo-600'
  | 'group-hover:bg-indigo-700'
  | 'group-hover:bg-indigo-800'
  | 'group-hover:bg-indigo-900'
  | 'group-hover:bg-purple-50'
  | 'group-hover:bg-purple-100'
  | 'group-hover:bg-purple-200'
  | 'group-hover:bg-purple-300'
  | 'group-hover:bg-purple-400'
  | 'group-hover:bg-purple-500'
  | 'group-hover:bg-purple-600'
  | 'group-hover:bg-purple-700'
  | 'group-hover:bg-purple-800'
  | 'group-hover:bg-purple-900'
  | 'group-hover:bg-pink-50'
  | 'group-hover:bg-pink-100'
  | 'group-hover:bg-pink-200'
  | 'group-hover:bg-pink-300'
  | 'group-hover:bg-pink-400'
  | 'group-hover:bg-pink-500'
  | 'group-hover:bg-pink-600'
  | 'group-hover:bg-pink-700'
  | 'group-hover:bg-pink-800'
  | 'group-hover:bg-pink-900'
  | 'group-focus:bg-transparent'
  | 'group-focus:bg-white'
  | 'group-focus:bg-black'
  | 'group-focus:bg-gray-50'
  | 'group-focus:bg-gray-100'
  | 'group-focus:bg-gray-200'
  | 'group-focus:bg-gray-300'
  | 'group-focus:bg-gray-400'
  | 'group-focus:bg-gray-500'
  | 'group-focus:bg-gray-600'
  | 'group-focus:bg-gray-700'
  | 'group-focus:bg-gray-800'
  | 'group-focus:bg-gray-900'
  | 'group-focus:bg-cool-gray-50'
  | 'group-focus:bg-cool-gray-100'
  | 'group-focus:bg-cool-gray-200'
  | 'group-focus:bg-cool-gray-300'
  | 'group-focus:bg-cool-gray-400'
  | 'group-focus:bg-cool-gray-500'
  | 'group-focus:bg-cool-gray-600'
  | 'group-focus:bg-cool-gray-700'
  | 'group-focus:bg-cool-gray-800'
  | 'group-focus:bg-cool-gray-900'
  | 'group-focus:bg-red-50'
  | 'group-focus:bg-red-100'
  | 'group-focus:bg-red-200'
  | 'group-focus:bg-red-300'
  | 'group-focus:bg-red-400'
  | 'group-focus:bg-red-500'
  | 'group-focus:bg-red-600'
  | 'group-focus:bg-red-700'
  | 'group-focus:bg-red-800'
  | 'group-focus:bg-red-900'
  | 'group-focus:bg-orange-50'
  | 'group-focus:bg-orange-100'
  | 'group-focus:bg-orange-200'
  | 'group-focus:bg-orange-300'
  | 'group-focus:bg-orange-400'
  | 'group-focus:bg-orange-500'
  | 'group-focus:bg-orange-600'
  | 'group-focus:bg-orange-700'
  | 'group-focus:bg-orange-800'
  | 'group-focus:bg-orange-900'
  | 'group-focus:bg-yellow-50'
  | 'group-focus:bg-yellow-100'
  | 'group-focus:bg-yellow-200'
  | 'group-focus:bg-yellow-300'
  | 'group-focus:bg-yellow-400'
  | 'group-focus:bg-yellow-500'
  | 'group-focus:bg-yellow-600'
  | 'group-focus:bg-yellow-700'
  | 'group-focus:bg-yellow-800'
  | 'group-focus:bg-yellow-900'
  | 'group-focus:bg-green-50'
  | 'group-focus:bg-green-100'
  | 'group-focus:bg-green-200'
  | 'group-focus:bg-green-300'
  | 'group-focus:bg-green-400'
  | 'group-focus:bg-green-500'
  | 'group-focus:bg-green-600'
  | 'group-focus:bg-green-700'
  | 'group-focus:bg-green-800'
  | 'group-focus:bg-green-900'
  | 'group-focus:bg-teal-50'
  | 'group-focus:bg-teal-100'
  | 'group-focus:bg-teal-200'
  | 'group-focus:bg-teal-300'
  | 'group-focus:bg-teal-400'
  | 'group-focus:bg-teal-500'
  | 'group-focus:bg-teal-600'
  | 'group-focus:bg-teal-700'
  | 'group-focus:bg-teal-800'
  | 'group-focus:bg-teal-900'
  | 'group-focus:bg-blue-50'
  | 'group-focus:bg-blue-100'
  | 'group-focus:bg-blue-200'
  | 'group-focus:bg-blue-300'
  | 'group-focus:bg-blue-400'
  | 'group-focus:bg-blue-500'
  | 'group-focus:bg-blue-600'
  | 'group-focus:bg-blue-700'
  | 'group-focus:bg-blue-800'
  | 'group-focus:bg-blue-900'
  | 'group-focus:bg-indigo-50'
  | 'group-focus:bg-indigo-100'
  | 'group-focus:bg-indigo-200'
  | 'group-focus:bg-indigo-300'
  | 'group-focus:bg-indigo-400'
  | 'group-focus:bg-indigo-500'
  | 'group-focus:bg-indigo-600'
  | 'group-focus:bg-indigo-700'
  | 'group-focus:bg-indigo-800'
  | 'group-focus:bg-indigo-900'
  | 'group-focus:bg-purple-50'
  | 'group-focus:bg-purple-100'
  | 'group-focus:bg-purple-200'
  | 'group-focus:bg-purple-300'
  | 'group-focus:bg-purple-400'
  | 'group-focus:bg-purple-500'
  | 'group-focus:bg-purple-600'
  | 'group-focus:bg-purple-700'
  | 'group-focus:bg-purple-800'
  | 'group-focus:bg-purple-900'
  | 'group-focus:bg-pink-50'
  | 'group-focus:bg-pink-100'
  | 'group-focus:bg-pink-200'
  | 'group-focus:bg-pink-300'
  | 'group-focus:bg-pink-400'
  | 'group-focus:bg-pink-500'
  | 'group-focus:bg-pink-600'
  | 'group-focus:bg-pink-700'
  | 'group-focus:bg-pink-800'
  | 'group-focus:bg-pink-900'
  | 'hover:bg-transparent'
  | 'hover:bg-white'
  | 'hover:bg-black'
  | 'hover:bg-gray-50'
  | 'hover:bg-gray-100'
  | 'hover:bg-gray-200'
  | 'hover:bg-gray-300'
  | 'hover:bg-gray-400'
  | 'hover:bg-gray-500'
  | 'hover:bg-gray-600'
  | 'hover:bg-gray-700'
  | 'hover:bg-gray-800'
  | 'hover:bg-gray-900'
  | 'hover:bg-cool-gray-50'
  | 'hover:bg-cool-gray-100'
  | 'hover:bg-cool-gray-200'
  | 'hover:bg-cool-gray-300'
  | 'hover:bg-cool-gray-400'
  | 'hover:bg-cool-gray-500'
  | 'hover:bg-cool-gray-600'
  | 'hover:bg-cool-gray-700'
  | 'hover:bg-cool-gray-800'
  | 'hover:bg-cool-gray-900'
  | 'hover:bg-red-50'
  | 'hover:bg-red-100'
  | 'hover:bg-red-200'
  | 'hover:bg-red-300'
  | 'hover:bg-red-400'
  | 'hover:bg-red-500'
  | 'hover:bg-red-600'
  | 'hover:bg-red-700'
  | 'hover:bg-red-800'
  | 'hover:bg-red-900'
  | 'hover:bg-orange-50'
  | 'hover:bg-orange-100'
  | 'hover:bg-orange-200'
  | 'hover:bg-orange-300'
  | 'hover:bg-orange-400'
  | 'hover:bg-orange-500'
  | 'hover:bg-orange-600'
  | 'hover:bg-orange-700'
  | 'hover:bg-orange-800'
  | 'hover:bg-orange-900'
  | 'hover:bg-yellow-50'
  | 'hover:bg-yellow-100'
  | 'hover:bg-yellow-200'
  | 'hover:bg-yellow-300'
  | 'hover:bg-yellow-400'
  | 'hover:bg-yellow-500'
  | 'hover:bg-yellow-600'
  | 'hover:bg-yellow-700'
  | 'hover:bg-yellow-800'
  | 'hover:bg-yellow-900'
  | 'hover:bg-green-50'
  | 'hover:bg-green-100'
  | 'hover:bg-green-200'
  | 'hover:bg-green-300'
  | 'hover:bg-green-400'
  | 'hover:bg-green-500'
  | 'hover:bg-green-600'
  | 'hover:bg-green-700'
  | 'hover:bg-green-800'
  | 'hover:bg-green-900'
  | 'hover:bg-teal-50'
  | 'hover:bg-teal-100'
  | 'hover:bg-teal-200'
  | 'hover:bg-teal-300'
  | 'hover:bg-teal-400'
  | 'hover:bg-teal-500'
  | 'hover:bg-teal-600'
  | 'hover:bg-teal-700'
  | 'hover:bg-teal-800'
  | 'hover:bg-teal-900'
  | 'hover:bg-blue-50'
  | 'hover:bg-blue-100'
  | 'hover:bg-blue-200'
  | 'hover:bg-blue-300'
  | 'hover:bg-blue-400'
  | 'hover:bg-blue-500'
  | 'hover:bg-blue-600'
  | 'hover:bg-blue-700'
  | 'hover:bg-blue-800'
  | 'hover:bg-blue-900'
  | 'hover:bg-indigo-50'
  | 'hover:bg-indigo-100'
  | 'hover:bg-indigo-200'
  | 'hover:bg-indigo-300'
  | 'hover:bg-indigo-400'
  | 'hover:bg-indigo-500'
  | 'hover:bg-indigo-600'
  | 'hover:bg-indigo-700'
  | 'hover:bg-indigo-800'
  | 'hover:bg-indigo-900'
  | 'hover:bg-purple-50'
  | 'hover:bg-purple-100'
  | 'hover:bg-purple-200'
  | 'hover:bg-purple-300'
  | 'hover:bg-purple-400'
  | 'hover:bg-purple-500'
  | 'hover:bg-purple-600'
  | 'hover:bg-purple-700'
  | 'hover:bg-purple-800'
  | 'hover:bg-purple-900'
  | 'hover:bg-pink-50'
  | 'hover:bg-pink-100'
  | 'hover:bg-pink-200'
  | 'hover:bg-pink-300'
  | 'hover:bg-pink-400'
  | 'hover:bg-pink-500'
  | 'hover:bg-pink-600'
  | 'hover:bg-pink-700'
  | 'hover:bg-pink-800'
  | 'hover:bg-pink-900'
  | 'focus:bg-transparent'
  | 'focus:bg-white'
  | 'focus:bg-black'
  | 'focus:bg-gray-50'
  | 'focus:bg-gray-100'
  | 'focus:bg-gray-200'
  | 'focus:bg-gray-300'
  | 'focus:bg-gray-400'
  | 'focus:bg-gray-500'
  | 'focus:bg-gray-600'
  | 'focus:bg-gray-700'
  | 'focus:bg-gray-800'
  | 'focus:bg-gray-900'
  | 'focus:bg-cool-gray-50'
  | 'focus:bg-cool-gray-100'
  | 'focus:bg-cool-gray-200'
  | 'focus:bg-cool-gray-300'
  | 'focus:bg-cool-gray-400'
  | 'focus:bg-cool-gray-500'
  | 'focus:bg-cool-gray-600'
  | 'focus:bg-cool-gray-700'
  | 'focus:bg-cool-gray-800'
  | 'focus:bg-cool-gray-900'
  | 'focus:bg-red-50'
  | 'focus:bg-red-100'
  | 'focus:bg-red-200'
  | 'focus:bg-red-300'
  | 'focus:bg-red-400'
  | 'focus:bg-red-500'
  | 'focus:bg-red-600'
  | 'focus:bg-red-700'
  | 'focus:bg-red-800'
  | 'focus:bg-red-900'
  | 'focus:bg-orange-50'
  | 'focus:bg-orange-100'
  | 'focus:bg-orange-200'
  | 'focus:bg-orange-300'
  | 'focus:bg-orange-400'
  | 'focus:bg-orange-500'
  | 'focus:bg-orange-600'
  | 'focus:bg-orange-700'
  | 'focus:bg-orange-800'
  | 'focus:bg-orange-900'
  | 'focus:bg-yellow-50'
  | 'focus:bg-yellow-100'
  | 'focus:bg-yellow-200'
  | 'focus:bg-yellow-300'
  | 'focus:bg-yellow-400'
  | 'focus:bg-yellow-500'
  | 'focus:bg-yellow-600'
  | 'focus:bg-yellow-700'
  | 'focus:bg-yellow-800'
  | 'focus:bg-yellow-900'
  | 'focus:bg-green-50'
  | 'focus:bg-green-100'
  | 'focus:bg-green-200'
  | 'focus:bg-green-300'
  | 'focus:bg-green-400'
  | 'focus:bg-green-500'
  | 'focus:bg-green-600'
  | 'focus:bg-green-700'
  | 'focus:bg-green-800'
  | 'focus:bg-green-900'
  | 'focus:bg-teal-50'
  | 'focus:bg-teal-100'
  | 'focus:bg-teal-200'
  | 'focus:bg-teal-300'
  | 'focus:bg-teal-400'
  | 'focus:bg-teal-500'
  | 'focus:bg-teal-600'
  | 'focus:bg-teal-700'
  | 'focus:bg-teal-800'
  | 'focus:bg-teal-900'
  | 'focus:bg-blue-50'
  | 'focus:bg-blue-100'
  | 'focus:bg-blue-200'
  | 'focus:bg-blue-300'
  | 'focus:bg-blue-400'
  | 'focus:bg-blue-500'
  | 'focus:bg-blue-600'
  | 'focus:bg-blue-700'
  | 'focus:bg-blue-800'
  | 'focus:bg-blue-900'
  | 'focus:bg-indigo-50'
  | 'focus:bg-indigo-100'
  | 'focus:bg-indigo-200'
  | 'focus:bg-indigo-300'
  | 'focus:bg-indigo-400'
  | 'focus:bg-indigo-500'
  | 'focus:bg-indigo-600'
  | 'focus:bg-indigo-700'
  | 'focus:bg-indigo-800'
  | 'focus:bg-indigo-900'
  | 'focus:bg-purple-50'
  | 'focus:bg-purple-100'
  | 'focus:bg-purple-200'
  | 'focus:bg-purple-300'
  | 'focus:bg-purple-400'
  | 'focus:bg-purple-500'
  | 'focus:bg-purple-600'
  | 'focus:bg-purple-700'
  | 'focus:bg-purple-800'
  | 'focus:bg-purple-900'
  | 'focus:bg-pink-50'
  | 'focus:bg-pink-100'
  | 'focus:bg-pink-200'
  | 'focus:bg-pink-300'
  | 'focus:bg-pink-400'
  | 'focus:bg-pink-500'
  | 'focus:bg-pink-600'
  | 'focus:bg-pink-700'
  | 'focus:bg-pink-800'
  | 'focus:bg-pink-900'
  | 'active:bg-transparent'
  | 'active:bg-white'
  | 'active:bg-black'
  | 'active:bg-gray-50'
  | 'active:bg-gray-100'
  | 'active:bg-gray-200'
  | 'active:bg-gray-300'
  | 'active:bg-gray-400'
  | 'active:bg-gray-500'
  | 'active:bg-gray-600'
  | 'active:bg-gray-700'
  | 'active:bg-gray-800'
  | 'active:bg-gray-900'
  | 'active:bg-cool-gray-50'
  | 'active:bg-cool-gray-100'
  | 'active:bg-cool-gray-200'
  | 'active:bg-cool-gray-300'
  | 'active:bg-cool-gray-400'
  | 'active:bg-cool-gray-500'
  | 'active:bg-cool-gray-600'
  | 'active:bg-cool-gray-700'
  | 'active:bg-cool-gray-800'
  | 'active:bg-cool-gray-900'
  | 'active:bg-red-50'
  | 'active:bg-red-100'
  | 'active:bg-red-200'
  | 'active:bg-red-300'
  | 'active:bg-red-400'
  | 'active:bg-red-500'
  | 'active:bg-red-600'
  | 'active:bg-red-700'
  | 'active:bg-red-800'
  | 'active:bg-red-900'
  | 'active:bg-orange-50'
  | 'active:bg-orange-100'
  | 'active:bg-orange-200'
  | 'active:bg-orange-300'
  | 'active:bg-orange-400'
  | 'active:bg-orange-500'
  | 'active:bg-orange-600'
  | 'active:bg-orange-700'
  | 'active:bg-orange-800'
  | 'active:bg-orange-900'
  | 'active:bg-yellow-50'
  | 'active:bg-yellow-100'
  | 'active:bg-yellow-200'
  | 'active:bg-yellow-300'
  | 'active:bg-yellow-400'
  | 'active:bg-yellow-500'
  | 'active:bg-yellow-600'
  | 'active:bg-yellow-700'
  | 'active:bg-yellow-800'
  | 'active:bg-yellow-900'
  | 'active:bg-green-50'
  | 'active:bg-green-100'
  | 'active:bg-green-200'
  | 'active:bg-green-300'
  | 'active:bg-green-400'
  | 'active:bg-green-500'
  | 'active:bg-green-600'
  | 'active:bg-green-700'
  | 'active:bg-green-800'
  | 'active:bg-green-900'
  | 'active:bg-teal-50'
  | 'active:bg-teal-100'
  | 'active:bg-teal-200'
  | 'active:bg-teal-300'
  | 'active:bg-teal-400'
  | 'active:bg-teal-500'
  | 'active:bg-teal-600'
  | 'active:bg-teal-700'
  | 'active:bg-teal-800'
  | 'active:bg-teal-900'
  | 'active:bg-blue-50'
  | 'active:bg-blue-100'
  | 'active:bg-blue-200'
  | 'active:bg-blue-300'
  | 'active:bg-blue-400'
  | 'active:bg-blue-500'
  | 'active:bg-blue-600'
  | 'active:bg-blue-700'
  | 'active:bg-blue-800'
  | 'active:bg-blue-900'
  | 'active:bg-indigo-50'
  | 'active:bg-indigo-100'
  | 'active:bg-indigo-200'
  | 'active:bg-indigo-300'
  | 'active:bg-indigo-400'
  | 'active:bg-indigo-500'
  | 'active:bg-indigo-600'
  | 'active:bg-indigo-700'
  | 'active:bg-indigo-800'
  | 'active:bg-indigo-900'
  | 'active:bg-purple-50'
  | 'active:bg-purple-100'
  | 'active:bg-purple-200'
  | 'active:bg-purple-300'
  | 'active:bg-purple-400'
  | 'active:bg-purple-500'
  | 'active:bg-purple-600'
  | 'active:bg-purple-700'
  | 'active:bg-purple-800'
  | 'active:bg-purple-900'
  | 'active:bg-pink-50'
  | 'active:bg-pink-100'
  | 'active:bg-pink-200'
  | 'active:bg-pink-300'
  | 'active:bg-pink-400'
  | 'active:bg-pink-500'
  | 'active:bg-pink-600'
  | 'active:bg-pink-700'
  | 'active:bg-pink-800'
  | 'active:bg-pink-900'
  | 'bg-bottom'
  | 'bg-center'
  | 'bg-left'
  | 'bg-left-bottom'
  | 'bg-left-top'
  | 'bg-right'
  | 'bg-right-bottom'
  | 'bg-right-top'
  | 'bg-top'
  | 'bg-repeat'
  | 'bg-no-repeat'
  | 'bg-repeat-x'
  | 'bg-repeat-y'
  | 'bg-repeat-round'
  | 'bg-repeat-space'
  | 'bg-auto'
  | 'bg-cover'
  | 'bg-contain'
  | 'border-collapse'
  | 'border-separate'
  | 'border-transparent'
  | 'border-white'
  | 'border-black'
  | 'border-gray-50'
  | 'border-gray-100'
  | 'border-gray-200'
  | 'border-gray-300'
  | 'border-gray-400'
  | 'border-gray-500'
  | 'border-gray-600'
  | 'border-gray-700'
  | 'border-gray-800'
  | 'border-gray-900'
  | 'border-cool-gray-50'
  | 'border-cool-gray-100'
  | 'border-cool-gray-200'
  | 'border-cool-gray-300'
  | 'border-cool-gray-400'
  | 'border-cool-gray-500'
  | 'border-cool-gray-600'
  | 'border-cool-gray-700'
  | 'border-cool-gray-800'
  | 'border-cool-gray-900'
  | 'border-red-50'
  | 'border-red-100'
  | 'border-red-200'
  | 'border-red-300'
  | 'border-red-400'
  | 'border-red-500'
  | 'border-red-600'
  | 'border-red-700'
  | 'border-red-800'
  | 'border-red-900'
  | 'border-orange-50'
  | 'border-orange-100'
  | 'border-orange-200'
  | 'border-orange-300'
  | 'border-orange-400'
  | 'border-orange-500'
  | 'border-orange-600'
  | 'border-orange-700'
  | 'border-orange-800'
  | 'border-orange-900'
  | 'border-yellow-50'
  | 'border-yellow-100'
  | 'border-yellow-200'
  | 'border-yellow-300'
  | 'border-yellow-400'
  | 'border-yellow-500'
  | 'border-yellow-600'
  | 'border-yellow-700'
  | 'border-yellow-800'
  | 'border-yellow-900'
  | 'border-green-50'
  | 'border-green-100'
  | 'border-green-200'
  | 'border-green-300'
  | 'border-green-400'
  | 'border-green-500'
  | 'border-green-600'
  | 'border-green-700'
  | 'border-green-800'
  | 'border-green-900'
  | 'border-teal-50'
  | 'border-teal-100'
  | 'border-teal-200'
  | 'border-teal-300'
  | 'border-teal-400'
  | 'border-teal-500'
  | 'border-teal-600'
  | 'border-teal-700'
  | 'border-teal-800'
  | 'border-teal-900'
  | 'border-blue-50'
  | 'border-blue-100'
  | 'border-blue-200'
  | 'border-blue-300'
  | 'border-blue-400'
  | 'border-blue-500'
  | 'border-blue-600'
  | 'border-blue-700'
  | 'border-blue-800'
  | 'border-blue-900'
  | 'border-indigo-50'
  | 'border-indigo-100'
  | 'border-indigo-200'
  | 'border-indigo-300'
  | 'border-indigo-400'
  | 'border-indigo-500'
  | 'border-indigo-600'
  | 'border-indigo-700'
  | 'border-indigo-800'
  | 'border-indigo-900'
  | 'border-purple-50'
  | 'border-purple-100'
  | 'border-purple-200'
  | 'border-purple-300'
  | 'border-purple-400'
  | 'border-purple-500'
  | 'border-purple-600'
  | 'border-purple-700'
  | 'border-purple-800'
  | 'border-purple-900'
  | 'border-pink-50'
  | 'border-pink-100'
  | 'border-pink-200'
  | 'border-pink-300'
  | 'border-pink-400'
  | 'border-pink-500'
  | 'border-pink-600'
  | 'border-pink-700'
  | 'border-pink-800'
  | 'border-pink-900'
  | 'group-hover:border-transparent'
  | 'group-hover:border-white'
  | 'group-hover:border-black'
  | 'group-hover:border-gray-50'
  | 'group-hover:border-gray-100'
  | 'group-hover:border-gray-200'
  | 'group-hover:border-gray-300'
  | 'group-hover:border-gray-400'
  | 'group-hover:border-gray-500'
  | 'group-hover:border-gray-600'
  | 'group-hover:border-gray-700'
  | 'group-hover:border-gray-800'
  | 'group-hover:border-gray-900'
  | 'group-hover:border-cool-gray-50'
  | 'group-hover:border-cool-gray-100'
  | 'group-hover:border-cool-gray-200'
  | 'group-hover:border-cool-gray-300'
  | 'group-hover:border-cool-gray-400'
  | 'group-hover:border-cool-gray-500'
  | 'group-hover:border-cool-gray-600'
  | 'group-hover:border-cool-gray-700'
  | 'group-hover:border-cool-gray-800'
  | 'group-hover:border-cool-gray-900'
  | 'group-hover:border-red-50'
  | 'group-hover:border-red-100'
  | 'group-hover:border-red-200'
  | 'group-hover:border-red-300'
  | 'group-hover:border-red-400'
  | 'group-hover:border-red-500'
  | 'group-hover:border-red-600'
  | 'group-hover:border-red-700'
  | 'group-hover:border-red-800'
  | 'group-hover:border-red-900'
  | 'group-hover:border-orange-50'
  | 'group-hover:border-orange-100'
  | 'group-hover:border-orange-200'
  | 'group-hover:border-orange-300'
  | 'group-hover:border-orange-400'
  | 'group-hover:border-orange-500'
  | 'group-hover:border-orange-600'
  | 'group-hover:border-orange-700'
  | 'group-hover:border-orange-800'
  | 'group-hover:border-orange-900'
  | 'group-hover:border-yellow-50'
  | 'group-hover:border-yellow-100'
  | 'group-hover:border-yellow-200'
  | 'group-hover:border-yellow-300'
  | 'group-hover:border-yellow-400'
  | 'group-hover:border-yellow-500'
  | 'group-hover:border-yellow-600'
  | 'group-hover:border-yellow-700'
  | 'group-hover:border-yellow-800'
  | 'group-hover:border-yellow-900'
  | 'group-hover:border-green-50'
  | 'group-hover:border-green-100'
  | 'group-hover:border-green-200'
  | 'group-hover:border-green-300'
  | 'group-hover:border-green-400'
  | 'group-hover:border-green-500'
  | 'group-hover:border-green-600'
  | 'group-hover:border-green-700'
  | 'group-hover:border-green-800'
  | 'group-hover:border-green-900'
  | 'group-hover:border-teal-50'
  | 'group-hover:border-teal-100'
  | 'group-hover:border-teal-200'
  | 'group-hover:border-teal-300'
  | 'group-hover:border-teal-400'
  | 'group-hover:border-teal-500'
  | 'group-hover:border-teal-600'
  | 'group-hover:border-teal-700'
  | 'group-hover:border-teal-800'
  | 'group-hover:border-teal-900'
  | 'group-hover:border-blue-50'
  | 'group-hover:border-blue-100'
  | 'group-hover:border-blue-200'
  | 'group-hover:border-blue-300'
  | 'group-hover:border-blue-400'
  | 'group-hover:border-blue-500'
  | 'group-hover:border-blue-600'
  | 'group-hover:border-blue-700'
  | 'group-hover:border-blue-800'
  | 'group-hover:border-blue-900'
  | 'group-hover:border-indigo-50'
  | 'group-hover:border-indigo-100'
  | 'group-hover:border-indigo-200'
  | 'group-hover:border-indigo-300'
  | 'group-hover:border-indigo-400'
  | 'group-hover:border-indigo-500'
  | 'group-hover:border-indigo-600'
  | 'group-hover:border-indigo-700'
  | 'group-hover:border-indigo-800'
  | 'group-hover:border-indigo-900'
  | 'group-hover:border-purple-50'
  | 'group-hover:border-purple-100'
  | 'group-hover:border-purple-200'
  | 'group-hover:border-purple-300'
  | 'group-hover:border-purple-400'
  | 'group-hover:border-purple-500'
  | 'group-hover:border-purple-600'
  | 'group-hover:border-purple-700'
  | 'group-hover:border-purple-800'
  | 'group-hover:border-purple-900'
  | 'group-hover:border-pink-50'
  | 'group-hover:border-pink-100'
  | 'group-hover:border-pink-200'
  | 'group-hover:border-pink-300'
  | 'group-hover:border-pink-400'
  | 'group-hover:border-pink-500'
  | 'group-hover:border-pink-600'
  | 'group-hover:border-pink-700'
  | 'group-hover:border-pink-800'
  | 'group-hover:border-pink-900'
  | 'group-focus:border-transparent'
  | 'group-focus:border-white'
  | 'group-focus:border-black'
  | 'group-focus:border-gray-50'
  | 'group-focus:border-gray-100'
  | 'group-focus:border-gray-200'
  | 'group-focus:border-gray-300'
  | 'group-focus:border-gray-400'
  | 'group-focus:border-gray-500'
  | 'group-focus:border-gray-600'
  | 'group-focus:border-gray-700'
  | 'group-focus:border-gray-800'
  | 'group-focus:border-gray-900'
  | 'group-focus:border-cool-gray-50'
  | 'group-focus:border-cool-gray-100'
  | 'group-focus:border-cool-gray-200'
  | 'group-focus:border-cool-gray-300'
  | 'group-focus:border-cool-gray-400'
  | 'group-focus:border-cool-gray-500'
  | 'group-focus:border-cool-gray-600'
  | 'group-focus:border-cool-gray-700'
  | 'group-focus:border-cool-gray-800'
  | 'group-focus:border-cool-gray-900'
  | 'group-focus:border-red-50'
  | 'group-focus:border-red-100'
  | 'group-focus:border-red-200'
  | 'group-focus:border-red-300'
  | 'group-focus:border-red-400'
  | 'group-focus:border-red-500'
  | 'group-focus:border-red-600'
  | 'group-focus:border-red-700'
  | 'group-focus:border-red-800'
  | 'group-focus:border-red-900'
  | 'group-focus:border-orange-50'
  | 'group-focus:border-orange-100'
  | 'group-focus:border-orange-200'
  | 'group-focus:border-orange-300'
  | 'group-focus:border-orange-400'
  | 'group-focus:border-orange-500'
  | 'group-focus:border-orange-600'
  | 'group-focus:border-orange-700'
  | 'group-focus:border-orange-800'
  | 'group-focus:border-orange-900'
  | 'group-focus:border-yellow-50'
  | 'group-focus:border-yellow-100'
  | 'group-focus:border-yellow-200'
  | 'group-focus:border-yellow-300'
  | 'group-focus:border-yellow-400'
  | 'group-focus:border-yellow-500'
  | 'group-focus:border-yellow-600'
  | 'group-focus:border-yellow-700'
  | 'group-focus:border-yellow-800'
  | 'group-focus:border-yellow-900'
  | 'group-focus:border-green-50'
  | 'group-focus:border-green-100'
  | 'group-focus:border-green-200'
  | 'group-focus:border-green-300'
  | 'group-focus:border-green-400'
  | 'group-focus:border-green-500'
  | 'group-focus:border-green-600'
  | 'group-focus:border-green-700'
  | 'group-focus:border-green-800'
  | 'group-focus:border-green-900'
  | 'group-focus:border-teal-50'
  | 'group-focus:border-teal-100'
  | 'group-focus:border-teal-200'
  | 'group-focus:border-teal-300'
  | 'group-focus:border-teal-400'
  | 'group-focus:border-teal-500'
  | 'group-focus:border-teal-600'
  | 'group-focus:border-teal-700'
  | 'group-focus:border-teal-800'
  | 'group-focus:border-teal-900'
  | 'group-focus:border-blue-50'
  | 'group-focus:border-blue-100'
  | 'group-focus:border-blue-200'
  | 'group-focus:border-blue-300'
  | 'group-focus:border-blue-400'
  | 'group-focus:border-blue-500'
  | 'group-focus:border-blue-600'
  | 'group-focus:border-blue-700'
  | 'group-focus:border-blue-800'
  | 'group-focus:border-blue-900'
  | 'group-focus:border-indigo-50'
  | 'group-focus:border-indigo-100'
  | 'group-focus:border-indigo-200'
  | 'group-focus:border-indigo-300'
  | 'group-focus:border-indigo-400'
  | 'group-focus:border-indigo-500'
  | 'group-focus:border-indigo-600'
  | 'group-focus:border-indigo-700'
  | 'group-focus:border-indigo-800'
  | 'group-focus:border-indigo-900'
  | 'group-focus:border-purple-50'
  | 'group-focus:border-purple-100'
  | 'group-focus:border-purple-200'
  | 'group-focus:border-purple-300'
  | 'group-focus:border-purple-400'
  | 'group-focus:border-purple-500'
  | 'group-focus:border-purple-600'
  | 'group-focus:border-purple-700'
  | 'group-focus:border-purple-800'
  | 'group-focus:border-purple-900'
  | 'group-focus:border-pink-50'
  | 'group-focus:border-pink-100'
  | 'group-focus:border-pink-200'
  | 'group-focus:border-pink-300'
  | 'group-focus:border-pink-400'
  | 'group-focus:border-pink-500'
  | 'group-focus:border-pink-600'
  | 'group-focus:border-pink-700'
  | 'group-focus:border-pink-800'
  | 'group-focus:border-pink-900'
  | 'hover:border-transparent'
  | 'hover:border-white'
  | 'hover:border-black'
  | 'hover:border-gray-50'
  | 'hover:border-gray-100'
  | 'hover:border-gray-200'
  | 'hover:border-gray-300'
  | 'hover:border-gray-400'
  | 'hover:border-gray-500'
  | 'hover:border-gray-600'
  | 'hover:border-gray-700'
  | 'hover:border-gray-800'
  | 'hover:border-gray-900'
  | 'hover:border-cool-gray-50'
  | 'hover:border-cool-gray-100'
  | 'hover:border-cool-gray-200'
  | 'hover:border-cool-gray-300'
  | 'hover:border-cool-gray-400'
  | 'hover:border-cool-gray-500'
  | 'hover:border-cool-gray-600'
  | 'hover:border-cool-gray-700'
  | 'hover:border-cool-gray-800'
  | 'hover:border-cool-gray-900'
  | 'hover:border-red-50'
  | 'hover:border-red-100'
  | 'hover:border-red-200'
  | 'hover:border-red-300'
  | 'hover:border-red-400'
  | 'hover:border-red-500'
  | 'hover:border-red-600'
  | 'hover:border-red-700'
  | 'hover:border-red-800'
  | 'hover:border-red-900'
  | 'hover:border-orange-50'
  | 'hover:border-orange-100'
  | 'hover:border-orange-200'
  | 'hover:border-orange-300'
  | 'hover:border-orange-400'
  | 'hover:border-orange-500'
  | 'hover:border-orange-600'
  | 'hover:border-orange-700'
  | 'hover:border-orange-800'
  | 'hover:border-orange-900'
  | 'hover:border-yellow-50'
  | 'hover:border-yellow-100'
  | 'hover:border-yellow-200'
  | 'hover:border-yellow-300'
  | 'hover:border-yellow-400'
  | 'hover:border-yellow-500'
  | 'hover:border-yellow-600'
  | 'hover:border-yellow-700'
  | 'hover:border-yellow-800'
  | 'hover:border-yellow-900'
  | 'hover:border-green-50'
  | 'hover:border-green-100'
  | 'hover:border-green-200'
  | 'hover:border-green-300'
  | 'hover:border-green-400'
  | 'hover:border-green-500'
  | 'hover:border-green-600'
  | 'hover:border-green-700'
  | 'hover:border-green-800'
  | 'hover:border-green-900'
  | 'hover:border-teal-50'
  | 'hover:border-teal-100'
  | 'hover:border-teal-200'
  | 'hover:border-teal-300'
  | 'hover:border-teal-400'
  | 'hover:border-teal-500'
  | 'hover:border-teal-600'
  | 'hover:border-teal-700'
  | 'hover:border-teal-800'
  | 'hover:border-teal-900'
  | 'hover:border-blue-50'
  | 'hover:border-blue-100'
  | 'hover:border-blue-200'
  | 'hover:border-blue-300'
  | 'hover:border-blue-400'
  | 'hover:border-blue-500'
  | 'hover:border-blue-600'
  | 'hover:border-blue-700'
  | 'hover:border-blue-800'
  | 'hover:border-blue-900'
  | 'hover:border-indigo-50'
  | 'hover:border-indigo-100'
  | 'hover:border-indigo-200'
  | 'hover:border-indigo-300'
  | 'hover:border-indigo-400'
  | 'hover:border-indigo-500'
  | 'hover:border-indigo-600'
  | 'hover:border-indigo-700'
  | 'hover:border-indigo-800'
  | 'hover:border-indigo-900'
  | 'hover:border-purple-50'
  | 'hover:border-purple-100'
  | 'hover:border-purple-200'
  | 'hover:border-purple-300'
  | 'hover:border-purple-400'
  | 'hover:border-purple-500'
  | 'hover:border-purple-600'
  | 'hover:border-purple-700'
  | 'hover:border-purple-800'
  | 'hover:border-purple-900'
  | 'hover:border-pink-50'
  | 'hover:border-pink-100'
  | 'hover:border-pink-200'
  | 'hover:border-pink-300'
  | 'hover:border-pink-400'
  | 'hover:border-pink-500'
  | 'hover:border-pink-600'
  | 'hover:border-pink-700'
  | 'hover:border-pink-800'
  | 'hover:border-pink-900'
  | 'focus:border-transparent'
  | 'focus:border-white'
  | 'focus:border-black'
  | 'focus:border-gray-50'
  | 'focus:border-gray-100'
  | 'focus:border-gray-200'
  | 'focus:border-gray-300'
  | 'focus:border-gray-400'
  | 'focus:border-gray-500'
  | 'focus:border-gray-600'
  | 'focus:border-gray-700'
  | 'focus:border-gray-800'
  | 'focus:border-gray-900'
  | 'focus:border-cool-gray-50'
  | 'focus:border-cool-gray-100'
  | 'focus:border-cool-gray-200'
  | 'focus:border-cool-gray-300'
  | 'focus:border-cool-gray-400'
  | 'focus:border-cool-gray-500'
  | 'focus:border-cool-gray-600'
  | 'focus:border-cool-gray-700'
  | 'focus:border-cool-gray-800'
  | 'focus:border-cool-gray-900'
  | 'focus:border-red-50'
  | 'focus:border-red-100'
  | 'focus:border-red-200'
  | 'focus:border-red-300'
  | 'focus:border-red-400'
  | 'focus:border-red-500'
  | 'focus:border-red-600'
  | 'focus:border-red-700'
  | 'focus:border-red-800'
  | 'focus:border-red-900'
  | 'focus:border-orange-50'
  | 'focus:border-orange-100'
  | 'focus:border-orange-200'
  | 'focus:border-orange-300'
  | 'focus:border-orange-400'
  | 'focus:border-orange-500'
  | 'focus:border-orange-600'
  | 'focus:border-orange-700'
  | 'focus:border-orange-800'
  | 'focus:border-orange-900'
  | 'focus:border-yellow-50'
  | 'focus:border-yellow-100'
  | 'focus:border-yellow-200'
  | 'focus:border-yellow-300'
  | 'focus:border-yellow-400'
  | 'focus:border-yellow-500'
  | 'focus:border-yellow-600'
  | 'focus:border-yellow-700'
  | 'focus:border-yellow-800'
  | 'focus:border-yellow-900'
  | 'focus:border-green-50'
  | 'focus:border-green-100'
  | 'focus:border-green-200'
  | 'focus:border-green-300'
  | 'focus:border-green-400'
  | 'focus:border-green-500'
  | 'focus:border-green-600'
  | 'focus:border-green-700'
  | 'focus:border-green-800'
  | 'focus:border-green-900'
  | 'focus:border-teal-50'
  | 'focus:border-teal-100'
  | 'focus:border-teal-200'
  | 'focus:border-teal-300'
  | 'focus:border-teal-400'
  | 'focus:border-teal-500'
  | 'focus:border-teal-600'
  | 'focus:border-teal-700'
  | 'focus:border-teal-800'
  | 'focus:border-teal-900'
  | 'focus:border-blue-50'
  | 'focus:border-blue-100'
  | 'focus:border-blue-200'
  | 'focus:border-blue-300'
  | 'focus:border-blue-400'
  | 'focus:border-blue-500'
  | 'focus:border-blue-600'
  | 'focus:border-blue-700'
  | 'focus:border-blue-800'
  | 'focus:border-blue-900'
  | 'focus:border-indigo-50'
  | 'focus:border-indigo-100'
  | 'focus:border-indigo-200'
  | 'focus:border-indigo-300'
  | 'focus:border-indigo-400'
  | 'focus:border-indigo-500'
  | 'focus:border-indigo-600'
  | 'focus:border-indigo-700'
  | 'focus:border-indigo-800'
  | 'focus:border-indigo-900'
  | 'focus:border-purple-50'
  | 'focus:border-purple-100'
  | 'focus:border-purple-200'
  | 'focus:border-purple-300'
  | 'focus:border-purple-400'
  | 'focus:border-purple-500'
  | 'focus:border-purple-600'
  | 'focus:border-purple-700'
  | 'focus:border-purple-800'
  | 'focus:border-purple-900'
  | 'focus:border-pink-50'
  | 'focus:border-pink-100'
  | 'focus:border-pink-200'
  | 'focus:border-pink-300'
  | 'focus:border-pink-400'
  | 'focus:border-pink-500'
  | 'focus:border-pink-600'
  | 'focus:border-pink-700'
  | 'focus:border-pink-800'
  | 'focus:border-pink-900'
  | 'rounded-none'
  | 'rounded-sm'
  | 'rounded'
  | 'rounded-md'
  | 'rounded-lg'
  | 'rounded-full'
  | 'rounded-t-none'
  | 'rounded-r-none'
  | 'rounded-b-none'
  | 'rounded-l-none'
  | 'rounded-t-sm'
  | 'rounded-r-sm'
  | 'rounded-b-sm'
  | 'rounded-l-sm'
  | 'rounded-t'
  | 'rounded-r'
  | 'rounded-b'
  | 'rounded-l'
  | 'rounded-t-md'
  | 'rounded-r-md'
  | 'rounded-b-md'
  | 'rounded-l-md'
  | 'rounded-t-lg'
  | 'rounded-r-lg'
  | 'rounded-b-lg'
  | 'rounded-l-lg'
  | 'rounded-t-full'
  | 'rounded-r-full'
  | 'rounded-b-full'
  | 'rounded-l-full'
  | 'rounded-tl-none'
  | 'rounded-tr-none'
  | 'rounded-br-none'
  | 'rounded-bl-none'
  | 'rounded-tl-sm'
  | 'rounded-tr-sm'
  | 'rounded-br-sm'
  | 'rounded-bl-sm'
  | 'rounded-tl'
  | 'rounded-tr'
  | 'rounded-br'
  | 'rounded-bl'
  | 'rounded-tl-md'
  | 'rounded-tr-md'
  | 'rounded-br-md'
  | 'rounded-bl-md'
  | 'rounded-tl-lg'
  | 'rounded-tr-lg'
  | 'rounded-br-lg'
  | 'rounded-bl-lg'
  | 'rounded-tl-full'
  | 'rounded-tr-full'
  | 'rounded-br-full'
  | 'rounded-bl-full'
  | 'border-solid'
  | 'border-dashed'
  | 'border-dotted'
  | 'border-double'
  | 'border-none'
  | 'border-0'
  | 'border-2'
  | 'border-4'
  | 'border-8'
  | 'border'
  | 'border-t-0'
  | 'border-r-0'
  | 'border-b-0'
  | 'border-l-0'
  | 'border-t-2'
  | 'border-r-2'
  | 'border-b-2'
  | 'border-l-2'
  | 'border-t-4'
  | 'border-r-4'
  | 'border-b-4'
  | 'border-l-4'
  | 'border-t-8'
  | 'border-r-8'
  | 'border-b-8'
  | 'border-l-8'
  | 'border-t'
  | 'border-r'
  | 'border-b'
  | 'border-l'
  | 'box-border'
  | 'box-content'
  | 'cursor-auto'
  | 'cursor-default'
  | 'cursor-pointer'
  | 'cursor-wait'
  | 'cursor-text'
  | 'cursor-move'
  | 'cursor-not-allowed'
  | 'block'
  | 'inline-block'
  | 'inline'
  | 'flex'
  | 'inline-flex'
  | 'grid'
  | 'table'
  | 'table-caption'
  | 'table-cell'
  | 'table-column'
  | 'table-column-group'
  | 'table-footer-group'
  | 'table-header-group'
  | 'table-row-group'
  | 'table-row'
  | 'hidden'
  | 'flex-row'
  | 'flex-row-reverse'
  | 'flex-col'
  | 'flex-col-reverse'
  | 'flex-wrap'
  | 'flex-wrap-reverse'
  | 'flex-no-wrap'
  | 'items-start'
  | 'items-end'
  | 'items-center'
  | 'items-baseline'
  | 'items-stretch'
  | 'self-auto'
  | 'self-start'
  | 'self-end'
  | 'self-center'
  | 'self-stretch'
  | 'justify-start'
  | 'justify-end'
  | 'justify-center'
  | 'justify-between'
  | 'justify-around'
  | 'justify-evenly'
  | 'content-center'
  | 'content-start'
  | 'content-end'
  | 'content-between'
  | 'content-around'
  | 'flex-1'
  | 'flex-auto'
  | 'flex-initial'
  | 'flex-none'
  | 'flex-grow-0'
  | 'flex-grow'
  | 'flex-shrink-0'
  | 'flex-shrink'
  | 'order-1'
  | 'order-2'
  | 'order-3'
  | 'order-4'
  | 'order-5'
  | 'order-6'
  | 'order-7'
  | 'order-8'
  | 'order-9'
  | 'order-10'
  | 'order-11'
  | 'order-12'
  | 'order-first'
  | 'order-last'
  | 'order-none'
  | 'float-right'
  | 'float-left'
  | 'float-none'
  | 'clearfix'
  | 'clear-left'
  | 'clear-right'
  | 'clear-both'
  | 'font-sans'
  | 'font-serif'
  | 'font-mono'
  | 'font-hairline'
  | 'font-thin'
  | 'font-light'
  | 'font-normal'
  | 'font-medium'
  | 'font-semibold'
  | 'font-bold'
  | 'font-extrabold'
  | 'font-black'
  | 'hover:font-hairline'
  | 'hover:font-thin'
  | 'hover:font-light'
  | 'hover:font-normal'
  | 'hover:font-medium'
  | 'hover:font-semibold'
  | 'hover:font-bold'
  | 'hover:font-extrabold'
  | 'hover:font-black'
  | 'focus:font-hairline'
  | 'focus:font-thin'
  | 'focus:font-light'
  | 'focus:font-normal'
  | 'focus:font-medium'
  | 'focus:font-semibold'
  | 'focus:font-bold'
  | 'focus:font-extrabold'
  | 'focus:font-black'
  | 'h-0'
  | 'h-1'
  | 'h-2'
  | 'h-3'
  | 'h-4'
  | 'h-5'
  | 'h-6'
  | 'h-7'
  | 'h-8'
  | 'h-9'
  | 'h-10'
  | 'h-11'
  | 'h-12'
  | 'h-13'
  | 'h-14'
  | 'h-15'
  | 'h-16'
  | 'h-20'
  | 'h-24'
  | 'h-28'
  | 'h-32'
  | 'h-36'
  | 'h-40'
  | 'h-48'
  | 'h-56'
  | 'h-60'
  | 'h-64'
  | 'h-72'
  | 'h-80'
  | 'h-96'
  | 'h-auto'
  | 'h-px'
  | 'h-0.5'
  | 'h-1.5'
  | 'h-2.5'
  | 'h-3.5'
  | 'h-1/2'
  | 'h-1/3'
  | 'h-2/3'
  | 'h-1/4'
  | 'h-2/4'
  | 'h-3/4'
  | 'h-1/5'
  | 'h-2/5'
  | 'h-3/5'
  | 'h-4/5'
  | 'h-1/6'
  | 'h-2/6'
  | 'h-3/6'
  | 'h-4/6'
  | 'h-5/6'
  | 'h-1/12'
  | 'h-2/12'
  | 'h-3/12'
  | 'h-4/12'
  | 'h-5/12'
  | 'h-6/12'
  | 'h-7/12'
  | 'h-8/12'
  | 'h-9/12'
  | 'h-10/12'
  | 'h-11/12'
  | 'h-full'
  | 'h-screen'
  | 'leading-3'
  | 'leading-4'
  | 'leading-5'
  | 'leading-6'
  | 'leading-7'
  | 'leading-8'
  | 'leading-9'
  | 'leading-10'
  | 'leading-none'
  | 'leading-tight'
  | 'leading-snug'
  | 'leading-normal'
  | 'leading-relaxed'
  | 'leading-loose'
  | 'list-inside'
  | 'list-outside'
  | 'list-none'
  | 'list-disc'
  | 'list-decimal'
  | 'm-0'
  | 'm-1'
  | 'm-2'
  | 'm-3'
  | 'm-4'
  | 'm-5'
  | 'm-6'
  | 'm-7'
  | 'm-8'
  | 'm-9'
  | 'm-10'
  | 'm-11'
  | 'm-12'
  | 'm-13'
  | 'm-14'
  | 'm-15'
  | 'm-16'
  | 'm-20'
  | 'm-24'
  | 'm-28'
  | 'm-32'
  | 'm-36'
  | 'm-40'
  | 'm-48'
  | 'm-56'
  | 'm-60'
  | 'm-64'
  | 'm-72'
  | 'm-80'
  | 'm-96'
  | 'm-auto'
  | 'm-px'
  | 'm-0.5'
  | 'm-1.5'
  | 'm-2.5'
  | 'm-3.5'
  | 'm-1/2'
  | 'm-1/3'
  | 'm-2/3'
  | 'm-1/4'
  | 'm-2/4'
  | 'm-3/4'
  | 'm-1/5'
  | 'm-2/5'
  | 'm-3/5'
  | 'm-4/5'
  | 'm-1/6'
  | 'm-2/6'
  | 'm-3/6'
  | 'm-4/6'
  | 'm-5/6'
  | 'm-1/12'
  | 'm-2/12'
  | 'm-3/12'
  | 'm-4/12'
  | 'm-5/12'
  | 'm-6/12'
  | 'm-7/12'
  | 'm-8/12'
  | 'm-9/12'
  | 'm-10/12'
  | 'm-11/12'
  | 'm-full'
  | '-m-1'
  | '-m-2'
  | '-m-3'
  | '-m-4'
  | '-m-5'
  | '-m-6'
  | '-m-7'
  | '-m-8'
  | '-m-9'
  | '-m-10'
  | '-m-11'
  | '-m-12'
  | '-m-13'
  | '-m-14'
  | '-m-15'
  | '-m-16'
  | '-m-20'
  | '-m-24'
  | '-m-28'
  | '-m-32'
  | '-m-36'
  | '-m-40'
  | '-m-48'
  | '-m-56'
  | '-m-60'
  | '-m-64'
  | '-m-72'
  | '-m-80'
  | '-m-96'
  | '-m-px'
  | '-m-0.5'
  | '-m-1.5'
  | '-m-2.5'
  | '-m-3.5'
  | '-m-1/2'
  | '-m-1/3'
  | '-m-2/3'
  | '-m-1/4'
  | '-m-2/4'
  | '-m-3/4'
  | '-m-1/5'
  | '-m-2/5'
  | '-m-3/5'
  | '-m-4/5'
  | '-m-1/6'
  | '-m-2/6'
  | '-m-3/6'
  | '-m-4/6'
  | '-m-5/6'
  | '-m-1/12'
  | '-m-2/12'
  | '-m-3/12'
  | '-m-4/12'
  | '-m-5/12'
  | '-m-6/12'
  | '-m-7/12'
  | '-m-8/12'
  | '-m-9/12'
  | '-m-10/12'
  | '-m-11/12'
  | '-m-full'
  | 'my-0'
  | 'mx-0'
  | 'my-1'
  | 'mx-1'
  | 'my-2'
  | 'mx-2'
  | 'my-3'
  | 'mx-3'
  | 'my-4'
  | 'mx-4'
  | 'my-5'
  | 'mx-5'
  | 'my-6'
  | 'mx-6'
  | 'my-7'
  | 'mx-7'
  | 'my-8'
  | 'mx-8'
  | 'my-9'
  | 'mx-9'
  | 'my-10'
  | 'mx-10'
  | 'my-11'
  | 'mx-11'
  | 'my-12'
  | 'mx-12'
  | 'my-13'
  | 'mx-13'
  | 'my-14'
  | 'mx-14'
  | 'my-15'
  | 'mx-15'
  | 'my-16'
  | 'mx-16'
  | 'my-20'
  | 'mx-20'
  | 'my-24'
  | 'mx-24'
  | 'my-28'
  | 'mx-28'
  | 'my-32'
  | 'mx-32'
  | 'my-36'
  | 'mx-36'
  | 'my-40'
  | 'mx-40'
  | 'my-48'
  | 'mx-48'
  | 'my-56'
  | 'mx-56'
  | 'my-60'
  | 'mx-60'
  | 'my-64'
  | 'mx-64'
  | 'my-72'
  | 'mx-72'
  | 'my-80'
  | 'mx-80'
  | 'my-96'
  | 'mx-96'
  | 'my-auto'
  | 'mx-auto'
  | 'my-px'
  | 'mx-px'
  | 'my-0.5'
  | 'mx-0.5'
  | 'my-1.5'
  | 'mx-1.5'
  | 'my-2.5'
  | 'mx-2.5'
  | 'my-3.5'
  | 'mx-3.5'
  | 'my-1/2'
  | 'mx-1/2'
  | 'my-1/3'
  | 'mx-1/3'
  | 'my-2/3'
  | 'mx-2/3'
  | 'my-1/4'
  | 'mx-1/4'
  | 'my-2/4'
  | 'mx-2/4'
  | 'my-3/4'
  | 'mx-3/4'
  | 'my-1/5'
  | 'mx-1/5'
  | 'my-2/5'
  | 'mx-2/5'
  | 'my-3/5'
  | 'mx-3/5'
  | 'my-4/5'
  | 'mx-4/5'
  | 'my-1/6'
  | 'mx-1/6'
  | 'my-2/6'
  | 'mx-2/6'
  | 'my-3/6'
  | 'mx-3/6'
  | 'my-4/6'
  | 'mx-4/6'
  | 'my-5/6'
  | 'mx-5/6'
  | 'my-1/12'
  | 'mx-1/12'
  | 'my-2/12'
  | 'mx-2/12'
  | 'my-3/12'
  | 'mx-3/12'
  | 'my-4/12'
  | 'mx-4/12'
  | 'my-5/12'
  | 'mx-5/12'
  | 'my-6/12'
  | 'mx-6/12'
  | 'my-7/12'
  | 'mx-7/12'
  | 'my-8/12'
  | 'mx-8/12'
  | 'my-9/12'
  | 'mx-9/12'
  | 'my-10/12'
  | 'mx-10/12'
  | 'my-11/12'
  | 'mx-11/12'
  | 'my-full'
  | 'mx-full'
  | '-my-1'
  | '-mx-1'
  | '-my-2'
  | '-mx-2'
  | '-my-3'
  | '-mx-3'
  | '-my-4'
  | '-mx-4'
  | '-my-5'
  | '-mx-5'
  | '-my-6'
  | '-mx-6'
  | '-my-7'
  | '-mx-7'
  | '-my-8'
  | '-mx-8'
  | '-my-9'
  | '-mx-9'
  | '-my-10'
  | '-mx-10'
  | '-my-11'
  | '-mx-11'
  | '-my-12'
  | '-mx-12'
  | '-my-13'
  | '-mx-13'
  | '-my-14'
  | '-mx-14'
  | '-my-15'
  | '-mx-15'
  | '-my-16'
  | '-mx-16'
  | '-my-20'
  | '-mx-20'
  | '-my-24'
  | '-mx-24'
  | '-my-28'
  | '-mx-28'
  | '-my-32'
  | '-mx-32'
  | '-my-36'
  | '-mx-36'
  | '-my-40'
  | '-mx-40'
  | '-my-48'
  | '-mx-48'
  | '-my-56'
  | '-mx-56'
  | '-my-60'
  | '-mx-60'
  | '-my-64'
  | '-mx-64'
  | '-my-72'
  | '-mx-72'
  | '-my-80'
  | '-mx-80'
  | '-my-96'
  | '-mx-96'
  | '-my-px'
  | '-mx-px'
  | '-my-0.5'
  | '-mx-0.5'
  | '-my-1.5'
  | '-mx-1.5'
  | '-my-2.5'
  | '-mx-2.5'
  | '-my-3.5'
  | '-mx-3.5'
  | '-my-1/2'
  | '-mx-1/2'
  | '-my-1/3'
  | '-mx-1/3'
  | '-my-2/3'
  | '-mx-2/3'
  | '-my-1/4'
  | '-mx-1/4'
  | '-my-2/4'
  | '-mx-2/4'
  | '-my-3/4'
  | '-mx-3/4'
  | '-my-1/5'
  | '-mx-1/5'
  | '-my-2/5'
  | '-mx-2/5'
  | '-my-3/5'
  | '-mx-3/5'
  | '-my-4/5'
  | '-mx-4/5'
  | '-my-1/6'
  | '-mx-1/6'
  | '-my-2/6'
  | '-mx-2/6'
  | '-my-3/6'
  | '-mx-3/6'
  | '-my-4/6'
  | '-mx-4/6'
  | '-my-5/6'
  | '-mx-5/6'
  | '-my-1/12'
  | '-mx-1/12'
  | '-my-2/12'
  | '-mx-2/12'
  | '-my-3/12'
  | '-mx-3/12'
  | '-my-4/12'
  | '-mx-4/12'
  | '-my-5/12'
  | '-mx-5/12'
  | '-my-6/12'
  | '-mx-6/12'
  | '-my-7/12'
  | '-mx-7/12'
  | '-my-8/12'
  | '-mx-8/12'
  | '-my-9/12'
  | '-mx-9/12'
  | '-my-10/12'
  | '-mx-10/12'
  | '-my-11/12'
  | '-mx-11/12'
  | '-my-full'
  | '-mx-full'
  | 'mt-0'
  | 'mr-0'
  | 'mb-0'
  | 'ml-0'
  | 'mt-1'
  | 'mr-1'
  | 'mb-1'
  | 'ml-1'
  | 'mt-2'
  | 'mr-2'
  | 'mb-2'
  | 'ml-2'
  | 'mt-3'
  | 'mr-3'
  | 'mb-3'
  | 'ml-3'
  | 'mt-4'
  | 'mr-4'
  | 'mb-4'
  | 'ml-4'
  | 'mt-5'
  | 'mr-5'
  | 'mb-5'
  | 'ml-5'
  | 'mt-6'
  | 'mr-6'
  | 'mb-6'
  | 'ml-6'
  | 'mt-7'
  | 'mr-7'
  | 'mb-7'
  | 'ml-7'
  | 'mt-8'
  | 'mr-8'
  | 'mb-8'
  | 'ml-8'
  | 'mt-9'
  | 'mr-9'
  | 'mb-9'
  | 'ml-9'
  | 'mt-10'
  | 'mr-10'
  | 'mb-10'
  | 'ml-10'
  | 'mt-11'
  | 'mr-11'
  | 'mb-11'
  | 'ml-11'
  | 'mt-12'
  | 'mr-12'
  | 'mb-12'
  | 'ml-12'
  | 'mt-13'
  | 'mr-13'
  | 'mb-13'
  | 'ml-13'
  | 'mt-14'
  | 'mr-14'
  | 'mb-14'
  | 'ml-14'
  | 'mt-15'
  | 'mr-15'
  | 'mb-15'
  | 'ml-15'
  | 'mt-16'
  | 'mr-16'
  | 'mb-16'
  | 'ml-16'
  | 'mt-20'
  | 'mr-20'
  | 'mb-20'
  | 'ml-20'
  | 'mt-24'
  | 'mr-24'
  | 'mb-24'
  | 'ml-24'
  | 'mt-28'
  | 'mr-28'
  | 'mb-28'
  | 'ml-28'
  | 'mt-32'
  | 'mr-32'
  | 'mb-32'
  | 'ml-32'
  | 'mt-36'
  | 'mr-36'
  | 'mb-36'
  | 'ml-36'
  | 'mt-40'
  | 'mr-40'
  | 'mb-40'
  | 'ml-40'
  | 'mt-48'
  | 'mr-48'
  | 'mb-48'
  | 'ml-48'
  | 'mt-56'
  | 'mr-56'
  | 'mb-56'
  | 'ml-56'
  | 'mt-60'
  | 'mr-60'
  | 'mb-60'
  | 'ml-60'
  | 'mt-64'
  | 'mr-64'
  | 'mb-64'
  | 'ml-64'
  | 'mt-72'
  | 'mr-72'
  | 'mb-72'
  | 'ml-72'
  | 'mt-80'
  | 'mr-80'
  | 'mb-80'
  | 'ml-80'
  | 'mt-96'
  | 'mr-96'
  | 'mb-96'
  | 'ml-96'
  | 'mt-auto'
  | 'mr-auto'
  | 'mb-auto'
  | 'ml-auto'
  | 'mt-px'
  | 'mr-px'
  | 'mb-px'
  | 'ml-px'
  | 'mt-0.5'
  | 'mr-0.5'
  | 'mb-0.5'
  | 'ml-0.5'
  | 'mt-1.5'
  | 'mr-1.5'
  | 'mb-1.5'
  | 'ml-1.5'
  | 'mt-2.5'
  | 'mr-2.5'
  | 'mb-2.5'
  | 'ml-2.5'
  | 'mt-3.5'
  | 'mr-3.5'
  | 'mb-3.5'
  | 'ml-3.5'
  | 'mt-1/2'
  | 'mr-1/2'
  | 'mb-1/2'
  | 'ml-1/2'
  | 'mt-1/3'
  | 'mr-1/3'
  | 'mb-1/3'
  | 'ml-1/3'
  | 'mt-2/3'
  | 'mr-2/3'
  | 'mb-2/3'
  | 'ml-2/3'
  | 'mt-1/4'
  | 'mr-1/4'
  | 'mb-1/4'
  | 'ml-1/4'
  | 'mt-2/4'
  | 'mr-2/4'
  | 'mb-2/4'
  | 'ml-2/4'
  | 'mt-3/4'
  | 'mr-3/4'
  | 'mb-3/4'
  | 'ml-3/4'
  | 'mt-1/5'
  | 'mr-1/5'
  | 'mb-1/5'
  | 'ml-1/5'
  | 'mt-2/5'
  | 'mr-2/5'
  | 'mb-2/5'
  | 'ml-2/5'
  | 'mt-3/5'
  | 'mr-3/5'
  | 'mb-3/5'
  | 'ml-3/5'
  | 'mt-4/5'
  | 'mr-4/5'
  | 'mb-4/5'
  | 'ml-4/5'
  | 'mt-1/6'
  | 'mr-1/6'
  | 'mb-1/6'
  | 'ml-1/6'
  | 'mt-2/6'
  | 'mr-2/6'
  | 'mb-2/6'
  | 'ml-2/6'
  | 'mt-3/6'
  | 'mr-3/6'
  | 'mb-3/6'
  | 'ml-3/6'
  | 'mt-4/6'
  | 'mr-4/6'
  | 'mb-4/6'
  | 'ml-4/6'
  | 'mt-5/6'
  | 'mr-5/6'
  | 'mb-5/6'
  | 'ml-5/6'
  | 'mt-1/12'
  | 'mr-1/12'
  | 'mb-1/12'
  | 'ml-1/12'
  | 'mt-2/12'
  | 'mr-2/12'
  | 'mb-2/12'
  | 'ml-2/12'
  | 'mt-3/12'
  | 'mr-3/12'
  | 'mb-3/12'
  | 'ml-3/12'
  | 'mt-4/12'
  | 'mr-4/12'
  | 'mb-4/12'
  | 'ml-4/12'
  | 'mt-5/12'
  | 'mr-5/12'
  | 'mb-5/12'
  | 'ml-5/12'
  | 'mt-6/12'
  | 'mr-6/12'
  | 'mb-6/12'
  | 'ml-6/12'
  | 'mt-7/12'
  | 'mr-7/12'
  | 'mb-7/12'
  | 'ml-7/12'
  | 'mt-8/12'
  | 'mr-8/12'
  | 'mb-8/12'
  | 'ml-8/12'
  | 'mt-9/12'
  | 'mr-9/12'
  | 'mb-9/12'
  | 'ml-9/12'
  | 'mt-10/12'
  | 'mr-10/12'
  | 'mb-10/12'
  | 'ml-10/12'
  | 'mt-11/12'
  | 'mr-11/12'
  | 'mb-11/12'
  | 'ml-11/12'
  | 'mt-full'
  | 'mr-full'
  | 'mb-full'
  | 'ml-full'
  | '-mt-1'
  | '-mr-1'
  | '-mb-1'
  | '-ml-1'
  | '-mt-2'
  | '-mr-2'
  | '-mb-2'
  | '-ml-2'
  | '-mt-3'
  | '-mr-3'
  | '-mb-3'
  | '-ml-3'
  | '-mt-4'
  | '-mr-4'
  | '-mb-4'
  | '-ml-4'
  | '-mt-5'
  | '-mr-5'
  | '-mb-5'
  | '-ml-5'
  | '-mt-6'
  | '-mr-6'
  | '-mb-6'
  | '-ml-6'
  | '-mt-7'
  | '-mr-7'
  | '-mb-7'
  | '-ml-7'
  | '-mt-8'
  | '-mr-8'
  | '-mb-8'
  | '-ml-8'
  | '-mt-9'
  | '-mr-9'
  | '-mb-9'
  | '-ml-9'
  | '-mt-10'
  | '-mr-10'
  | '-mb-10'
  | '-ml-10'
  | '-mt-11'
  | '-mr-11'
  | '-mb-11'
  | '-ml-11'
  | '-mt-12'
  | '-mr-12'
  | '-mb-12'
  | '-ml-12'
  | '-mt-13'
  | '-mr-13'
  | '-mb-13'
  | '-ml-13'
  | '-mt-14'
  | '-mr-14'
  | '-mb-14'
  | '-ml-14'
  | '-mt-15'
  | '-mr-15'
  | '-mb-15'
  | '-ml-15'
  | '-mt-16'
  | '-mr-16'
  | '-mb-16'
  | '-ml-16'
  | '-mt-20'
  | '-mr-20'
  | '-mb-20'
  | '-ml-20'
  | '-mt-24'
  | '-mr-24'
  | '-mb-24'
  | '-ml-24'
  | '-mt-28'
  | '-mr-28'
  | '-mb-28'
  | '-ml-28'
  | '-mt-32'
  | '-mr-32'
  | '-mb-32'
  | '-ml-32'
  | '-mt-36'
  | '-mr-36'
  | '-mb-36'
  | '-ml-36'
  | '-mt-40'
  | '-mr-40'
  | '-mb-40'
  | '-ml-40'
  | '-mt-48'
  | '-mr-48'
  | '-mb-48'
  | '-ml-48'
  | '-mt-56'
  | '-mr-56'
  | '-mb-56'
  | '-ml-56'
  | '-mt-60'
  | '-mr-60'
  | '-mb-60'
  | '-ml-60'
  | '-mt-64'
  | '-mr-64'
  | '-mb-64'
  | '-ml-64'
  | '-mt-72'
  | '-mr-72'
  | '-mb-72'
  | '-ml-72'
  | '-mt-80'
  | '-mr-80'
  | '-mb-80'
  | '-ml-80'
  | '-mt-96'
  | '-mr-96'
  | '-mb-96'
  | '-ml-96'
  | '-mt-px'
  | '-mr-px'
  | '-mb-px'
  | '-ml-px'
  | '-mt-0.5'
  | '-mr-0.5'
  | '-mb-0.5'
  | '-ml-0.5'
  | '-mt-1.5'
  | '-mr-1.5'
  | '-mb-1.5'
  | '-ml-1.5'
  | '-mt-2.5'
  | '-mr-2.5'
  | '-mb-2.5'
  | '-ml-2.5'
  | '-mt-3.5'
  | '-mr-3.5'
  | '-mb-3.5'
  | '-ml-3.5'
  | '-mt-1/2'
  | '-mr-1/2'
  | '-mb-1/2'
  | '-ml-1/2'
  | '-mt-1/3'
  | '-mr-1/3'
  | '-mb-1/3'
  | '-ml-1/3'
  | '-mt-2/3'
  | '-mr-2/3'
  | '-mb-2/3'
  | '-ml-2/3'
  | '-mt-1/4'
  | '-mr-1/4'
  | '-mb-1/4'
  | '-ml-1/4'
  | '-mt-2/4'
  | '-mr-2/4'
  | '-mb-2/4'
  | '-ml-2/4'
  | '-mt-3/4'
  | '-mr-3/4'
  | '-mb-3/4'
  | '-ml-3/4'
  | '-mt-1/5'
  | '-mr-1/5'
  | '-mb-1/5'
  | '-ml-1/5'
  | '-mt-2/5'
  | '-mr-2/5'
  | '-mb-2/5'
  | '-ml-2/5'
  | '-mt-3/5'
  | '-mr-3/5'
  | '-mb-3/5'
  | '-ml-3/5'
  | '-mt-4/5'
  | '-mr-4/5'
  | '-mb-4/5'
  | '-ml-4/5'
  | '-mt-1/6'
  | '-mr-1/6'
  | '-mb-1/6'
  | '-ml-1/6'
  | '-mt-2/6'
  | '-mr-2/6'
  | '-mb-2/6'
  | '-ml-2/6'
  | '-mt-3/6'
  | '-mr-3/6'
  | '-mb-3/6'
  | '-ml-3/6'
  | '-mt-4/6'
  | '-mr-4/6'
  | '-mb-4/6'
  | '-ml-4/6'
  | '-mt-5/6'
  | '-mr-5/6'
  | '-mb-5/6'
  | '-ml-5/6'
  | '-mt-1/12'
  | '-mr-1/12'
  | '-mb-1/12'
  | '-ml-1/12'
  | '-mt-2/12'
  | '-mr-2/12'
  | '-mb-2/12'
  | '-ml-2/12'
  | '-mt-3/12'
  | '-mr-3/12'
  | '-mb-3/12'
  | '-ml-3/12'
  | '-mt-4/12'
  | '-mr-4/12'
  | '-mb-4/12'
  | '-ml-4/12'
  | '-mt-5/12'
  | '-mr-5/12'
  | '-mb-5/12'
  | '-ml-5/12'
  | '-mt-6/12'
  | '-mr-6/12'
  | '-mb-6/12'
  | '-ml-6/12'
  | '-mt-7/12'
  | '-mr-7/12'
  | '-mb-7/12'
  | '-ml-7/12'
  | '-mt-8/12'
  | '-mr-8/12'
  | '-mb-8/12'
  | '-ml-8/12'
  | '-mt-9/12'
  | '-mr-9/12'
  | '-mb-9/12'
  | '-ml-9/12'
  | '-mt-10/12'
  | '-mr-10/12'
  | '-mb-10/12'
  | '-ml-10/12'
  | '-mt-11/12'
  | '-mr-11/12'
  | '-mb-11/12'
  | '-ml-11/12'
  | '-mt-full'
  | '-mr-full'
  | '-mb-full'
  | '-ml-full'
  | 'max-h-0'
  | 'max-h-1'
  | 'max-h-2'
  | 'max-h-3'
  | 'max-h-4'
  | 'max-h-5'
  | 'max-h-6'
  | 'max-h-7'
  | 'max-h-8'
  | 'max-h-9'
  | 'max-h-10'
  | 'max-h-11'
  | 'max-h-12'
  | 'max-h-13'
  | 'max-h-14'
  | 'max-h-15'
  | 'max-h-16'
  | 'max-h-20'
  | 'max-h-24'
  | 'max-h-28'
  | 'max-h-32'
  | 'max-h-36'
  | 'max-h-40'
  | 'max-h-48'
  | 'max-h-56'
  | 'max-h-60'
  | 'max-h-64'
  | 'max-h-72'
  | 'max-h-80'
  | 'max-h-96'
  | 'max-h-screen'
  | 'max-h-px'
  | 'max-h-0.5'
  | 'max-h-1.5'
  | 'max-h-2.5'
  | 'max-h-3.5'
  | 'max-h-1/2'
  | 'max-h-1/3'
  | 'max-h-2/3'
  | 'max-h-1/4'
  | 'max-h-2/4'
  | 'max-h-3/4'
  | 'max-h-1/5'
  | 'max-h-2/5'
  | 'max-h-3/5'
  | 'max-h-4/5'
  | 'max-h-1/6'
  | 'max-h-2/6'
  | 'max-h-3/6'
  | 'max-h-4/6'
  | 'max-h-5/6'
  | 'max-h-1/12'
  | 'max-h-2/12'
  | 'max-h-3/12'
  | 'max-h-4/12'
  | 'max-h-5/12'
  | 'max-h-6/12'
  | 'max-h-7/12'
  | 'max-h-8/12'
  | 'max-h-9/12'
  | 'max-h-10/12'
  | 'max-h-11/12'
  | 'max-h-full'
  | 'max-w-none'
  | 'max-w-xs'
  | 'max-w-sm'
  | 'max-w-md'
  | 'max-w-lg'
  | 'max-w-xl'
  | 'max-w-2xl'
  | 'max-w-3xl'
  | 'max-w-4xl'
  | 'max-w-5xl'
  | 'max-w-6xl'
  | 'max-w-7xl'
  | 'max-w-full'
  | 'max-w-screen-sm'
  | 'max-w-screen-md'
  | 'max-w-screen-lg'
  | 'max-w-screen-xl'
  | 'min-h-0'
  | 'min-h-full'
  | 'min-h-screen'
  | 'min-w-0'
  | 'min-w-full'
  | 'object-contain'
  | 'object-cover'
  | 'object-fill'
  | 'object-none'
  | 'object-scale-down'
  | 'object-bottom'
  | 'object-center'
  | 'object-left'
  | 'object-left-bottom'
  | 'object-left-top'
  | 'object-right'
  | 'object-right-bottom'
  | 'object-right-top'
  | 'object-top'
  | 'opacity-0'
  | 'opacity-25'
  | 'opacity-50'
  | 'opacity-75'
  | 'opacity-100'
  | 'hover:opacity-0'
  | 'hover:opacity-25'
  | 'hover:opacity-50'
  | 'hover:opacity-75'
  | 'hover:opacity-100'
  | 'focus:opacity-0'
  | 'focus:opacity-25'
  | 'focus:opacity-50'
  | 'focus:opacity-75'
  | 'focus:opacity-100'
  | 'outline-none'
  | 'focus:outline-none'
  | 'overflow-auto'
  | 'overflow-hidden'
  | 'overflow-visible'
  | 'overflow-scroll'
  | 'overflow-x-auto'
  | 'overflow-y-auto'
  | 'overflow-x-hidden'
  | 'overflow-y-hidden'
  | 'overflow-x-visible'
  | 'overflow-y-visible'
  | 'overflow-x-scroll'
  | 'overflow-y-scroll'
  | 'scrolling-touch'
  | 'scrolling-auto'
  | 'p-0'
  | 'p-1'
  | 'p-2'
  | 'p-3'
  | 'p-4'
  | 'p-5'
  | 'p-6'
  | 'p-7'
  | 'p-8'
  | 'p-9'
  | 'p-10'
  | 'p-11'
  | 'p-12'
  | 'p-13'
  | 'p-14'
  | 'p-15'
  | 'p-16'
  | 'p-20'
  | 'p-24'
  | 'p-28'
  | 'p-32'
  | 'p-36'
  | 'p-40'
  | 'p-48'
  | 'p-56'
  | 'p-60'
  | 'p-64'
  | 'p-72'
  | 'p-80'
  | 'p-96'
  | 'p-px'
  | 'p-0.5'
  | 'p-1.5'
  | 'p-2.5'
  | 'p-3.5'
  | 'p-1/2'
  | 'p-1/3'
  | 'p-2/3'
  | 'p-1/4'
  | 'p-2/4'
  | 'p-3/4'
  | 'p-1/5'
  | 'p-2/5'
  | 'p-3/5'
  | 'p-4/5'
  | 'p-1/6'
  | 'p-2/6'
  | 'p-3/6'
  | 'p-4/6'
  | 'p-5/6'
  | 'p-1/12'
  | 'p-2/12'
  | 'p-3/12'
  | 'p-4/12'
  | 'p-5/12'
  | 'p-6/12'
  | 'p-7/12'
  | 'p-8/12'
  | 'p-9/12'
  | 'p-10/12'
  | 'p-11/12'
  | 'p-full'
  | 'py-0'
  | 'px-0'
  | 'py-1'
  | 'px-1'
  | 'py-2'
  | 'px-2'
  | 'py-3'
  | 'px-3'
  | 'py-4'
  | 'px-4'
  | 'py-5'
  | 'px-5'
  | 'py-6'
  | 'px-6'
  | 'py-7'
  | 'px-7'
  | 'py-8'
  | 'px-8'
  | 'py-9'
  | 'px-9'
  | 'py-10'
  | 'px-10'
  | 'py-11'
  | 'px-11'
  | 'py-12'
  | 'px-12'
  | 'py-13'
  | 'px-13'
  | 'py-14'
  | 'px-14'
  | 'py-15'
  | 'px-15'
  | 'py-16'
  | 'px-16'
  | 'py-20'
  | 'px-20'
  | 'py-24'
  | 'px-24'
  | 'py-28'
  | 'px-28'
  | 'py-32'
  | 'px-32'
  | 'py-36'
  | 'px-36'
  | 'py-40'
  | 'px-40'
  | 'py-48'
  | 'px-48'
  | 'py-56'
  | 'px-56'
  | 'py-60'
  | 'px-60'
  | 'py-64'
  | 'px-64'
  | 'py-72'
  | 'px-72'
  | 'py-80'
  | 'px-80'
  | 'py-96'
  | 'px-96'
  | 'py-px'
  | 'px-px'
  | 'py-0.5'
  | 'px-0.5'
  | 'py-1.5'
  | 'px-1.5'
  | 'py-2.5'
  | 'px-2.5'
  | 'py-3.5'
  | 'px-3.5'
  | 'py-1/2'
  | 'px-1/2'
  | 'py-1/3'
  | 'px-1/3'
  | 'py-2/3'
  | 'px-2/3'
  | 'py-1/4'
  | 'px-1/4'
  | 'py-2/4'
  | 'px-2/4'
  | 'py-3/4'
  | 'px-3/4'
  | 'py-1/5'
  | 'px-1/5'
  | 'py-2/5'
  | 'px-2/5'
  | 'py-3/5'
  | 'px-3/5'
  | 'py-4/5'
  | 'px-4/5'
  | 'py-1/6'
  | 'px-1/6'
  | 'py-2/6'
  | 'px-2/6'
  | 'py-3/6'
  | 'px-3/6'
  | 'py-4/6'
  | 'px-4/6'
  | 'py-5/6'
  | 'px-5/6'
  | 'py-1/12'
  | 'px-1/12'
  | 'py-2/12'
  | 'px-2/12'
  | 'py-3/12'
  | 'px-3/12'
  | 'py-4/12'
  | 'px-4/12'
  | 'py-5/12'
  | 'px-5/12'
  | 'py-6/12'
  | 'px-6/12'
  | 'py-7/12'
  | 'px-7/12'
  | 'py-8/12'
  | 'px-8/12'
  | 'py-9/12'
  | 'px-9/12'
  | 'py-10/12'
  | 'px-10/12'
  | 'py-11/12'
  | 'px-11/12'
  | 'py-full'
  | 'px-full'
  | 'pt-0'
  | 'pr-0'
  | 'pb-0'
  | 'pl-0'
  | 'pt-1'
  | 'pr-1'
  | 'pb-1'
  | 'pl-1'
  | 'pt-2'
  | 'pr-2'
  | 'pb-2'
  | 'pl-2'
  | 'pt-3'
  | 'pr-3'
  | 'pb-3'
  | 'pl-3'
  | 'pt-4'
  | 'pr-4'
  | 'pb-4'
  | 'pl-4'
  | 'pt-5'
  | 'pr-5'
  | 'pb-5'
  | 'pl-5'
  | 'pt-6'
  | 'pr-6'
  | 'pb-6'
  | 'pl-6'
  | 'pt-7'
  | 'pr-7'
  | 'pb-7'
  | 'pl-7'
  | 'pt-8'
  | 'pr-8'
  | 'pb-8'
  | 'pl-8'
  | 'pt-9'
  | 'pr-9'
  | 'pb-9'
  | 'pl-9'
  | 'pt-10'
  | 'pr-10'
  | 'pb-10'
  | 'pl-10'
  | 'pt-11'
  | 'pr-11'
  | 'pb-11'
  | 'pl-11'
  | 'pt-12'
  | 'pr-12'
  | 'pb-12'
  | 'pl-12'
  | 'pt-13'
  | 'pr-13'
  | 'pb-13'
  | 'pl-13'
  | 'pt-14'
  | 'pr-14'
  | 'pb-14'
  | 'pl-14'
  | 'pt-15'
  | 'pr-15'
  | 'pb-15'
  | 'pl-15'
  | 'pt-16'
  | 'pr-16'
  | 'pb-16'
  | 'pl-16'
  | 'pt-20'
  | 'pr-20'
  | 'pb-20'
  | 'pl-20'
  | 'pt-24'
  | 'pr-24'
  | 'pb-24'
  | 'pl-24'
  | 'pt-28'
  | 'pr-28'
  | 'pb-28'
  | 'pl-28'
  | 'pt-32'
  | 'pr-32'
  | 'pb-32'
  | 'pl-32'
  | 'pt-36'
  | 'pr-36'
  | 'pb-36'
  | 'pl-36'
  | 'pt-40'
  | 'pr-40'
  | 'pb-40'
  | 'pl-40'
  | 'pt-48'
  | 'pr-48'
  | 'pb-48'
  | 'pl-48'
  | 'pt-56'
  | 'pr-56'
  | 'pb-56'
  | 'pl-56'
  | 'pt-60'
  | 'pr-60'
  | 'pb-60'
  | 'pl-60'
  | 'pt-64'
  | 'pr-64'
  | 'pb-64'
  | 'pl-64'
  | 'pt-72'
  | 'pr-72'
  | 'pb-72'
  | 'pl-72'
  | 'pt-80'
  | 'pr-80'
  | 'pb-80'
  | 'pl-80'
  | 'pt-96'
  | 'pr-96'
  | 'pb-96'
  | 'pl-96'
  | 'pt-px'
  | 'pr-px'
  | 'pb-px'
  | 'pl-px'
  | 'pt-0.5'
  | 'pr-0.5'
  | 'pb-0.5'
  | 'pl-0.5'
  | 'pt-1.5'
  | 'pr-1.5'
  | 'pb-1.5'
  | 'pl-1.5'
  | 'pt-2.5'
  | 'pr-2.5'
  | 'pb-2.5'
  | 'pl-2.5'
  | 'pt-3.5'
  | 'pr-3.5'
  | 'pb-3.5'
  | 'pl-3.5'
  | 'pt-1/2'
  | 'pr-1/2'
  | 'pb-1/2'
  | 'pl-1/2'
  | 'pt-1/3'
  | 'pr-1/3'
  | 'pb-1/3'
  | 'pl-1/3'
  | 'pt-2/3'
  | 'pr-2/3'
  | 'pb-2/3'
  | 'pl-2/3'
  | 'pt-1/4'
  | 'pr-1/4'
  | 'pb-1/4'
  | 'pl-1/4'
  | 'pt-2/4'
  | 'pr-2/4'
  | 'pb-2/4'
  | 'pl-2/4'
  | 'pt-3/4'
  | 'pr-3/4'
  | 'pb-3/4'
  | 'pl-3/4'
  | 'pt-1/5'
  | 'pr-1/5'
  | 'pb-1/5'
  | 'pl-1/5'
  | 'pt-2/5'
  | 'pr-2/5'
  | 'pb-2/5'
  | 'pl-2/5'
  | 'pt-3/5'
  | 'pr-3/5'
  | 'pb-3/5'
  | 'pl-3/5'
  | 'pt-4/5'
  | 'pr-4/5'
  | 'pb-4/5'
  | 'pl-4/5'
  | 'pt-1/6'
  | 'pr-1/6'
  | 'pb-1/6'
  | 'pl-1/6'
  | 'pt-2/6'
  | 'pr-2/6'
  | 'pb-2/6'
  | 'pl-2/6'
  | 'pt-3/6'
  | 'pr-3/6'
  | 'pb-3/6'
  | 'pl-3/6'
  | 'pt-4/6'
  | 'pr-4/6'
  | 'pb-4/6'
  | 'pl-4/6'
  | 'pt-5/6'
  | 'pr-5/6'
  | 'pb-5/6'
  | 'pl-5/6'
  | 'pt-1/12'
  | 'pr-1/12'
  | 'pb-1/12'
  | 'pl-1/12'
  | 'pt-2/12'
  | 'pr-2/12'
  | 'pb-2/12'
  | 'pl-2/12'
  | 'pt-3/12'
  | 'pr-3/12'
  | 'pb-3/12'
  | 'pl-3/12'
  | 'pt-4/12'
  | 'pr-4/12'
  | 'pb-4/12'
  | 'pl-4/12'
  | 'pt-5/12'
  | 'pr-5/12'
  | 'pb-5/12'
  | 'pl-5/12'
  | 'pt-6/12'
  | 'pr-6/12'
  | 'pb-6/12'
  | 'pl-6/12'
  | 'pt-7/12'
  | 'pr-7/12'
  | 'pb-7/12'
  | 'pl-7/12'
  | 'pt-8/12'
  | 'pr-8/12'
  | 'pb-8/12'
  | 'pl-8/12'
  | 'pt-9/12'
  | 'pr-9/12'
  | 'pb-9/12'
  | 'pl-9/12'
  | 'pt-10/12'
  | 'pr-10/12'
  | 'pb-10/12'
  | 'pl-10/12'
  | 'pt-11/12'
  | 'pr-11/12'
  | 'pb-11/12'
  | 'pl-11/12'
  | 'pt-full'
  | 'pr-full'
  | 'pb-full'
  | 'pl-full'
  | 'placeholder-transparent'
  | 'placeholder-white'
  | 'placeholder-black'
  | 'placeholder-gray-50'
  | 'placeholder-gray-100'
  | 'placeholder-gray-200'
  | 'placeholder-gray-300'
  | 'placeholder-gray-400'
  | 'placeholder-gray-500'
  | 'placeholder-gray-600'
  | 'placeholder-gray-700'
  | 'placeholder-gray-800'
  | 'placeholder-gray-900'
  | 'placeholder-cool-gray-50'
  | 'placeholder-cool-gray-100'
  | 'placeholder-cool-gray-200'
  | 'placeholder-cool-gray-300'
  | 'placeholder-cool-gray-400'
  | 'placeholder-cool-gray-500'
  | 'placeholder-cool-gray-600'
  | 'placeholder-cool-gray-700'
  | 'placeholder-cool-gray-800'
  | 'placeholder-cool-gray-900'
  | 'placeholder-red-50'
  | 'placeholder-red-100'
  | 'placeholder-red-200'
  | 'placeholder-red-300'
  | 'placeholder-red-400'
  | 'placeholder-red-500'
  | 'placeholder-red-600'
  | 'placeholder-red-700'
  | 'placeholder-red-800'
  | 'placeholder-red-900'
  | 'placeholder-orange-50'
  | 'placeholder-orange-100'
  | 'placeholder-orange-200'
  | 'placeholder-orange-300'
  | 'placeholder-orange-400'
  | 'placeholder-orange-500'
  | 'placeholder-orange-600'
  | 'placeholder-orange-700'
  | 'placeholder-orange-800'
  | 'placeholder-orange-900'
  | 'placeholder-yellow-50'
  | 'placeholder-yellow-100'
  | 'placeholder-yellow-200'
  | 'placeholder-yellow-300'
  | 'placeholder-yellow-400'
  | 'placeholder-yellow-500'
  | 'placeholder-yellow-600'
  | 'placeholder-yellow-700'
  | 'placeholder-yellow-800'
  | 'placeholder-yellow-900'
  | 'placeholder-green-50'
  | 'placeholder-green-100'
  | 'placeholder-green-200'
  | 'placeholder-green-300'
  | 'placeholder-green-400'
  | 'placeholder-green-500'
  | 'placeholder-green-600'
  | 'placeholder-green-700'
  | 'placeholder-green-800'
  | 'placeholder-green-900'
  | 'placeholder-teal-50'
  | 'placeholder-teal-100'
  | 'placeholder-teal-200'
  | 'placeholder-teal-300'
  | 'placeholder-teal-400'
  | 'placeholder-teal-500'
  | 'placeholder-teal-600'
  | 'placeholder-teal-700'
  | 'placeholder-teal-800'
  | 'placeholder-teal-900'
  | 'placeholder-blue-50'
  | 'placeholder-blue-100'
  | 'placeholder-blue-200'
  | 'placeholder-blue-300'
  | 'placeholder-blue-400'
  | 'placeholder-blue-500'
  | 'placeholder-blue-600'
  | 'placeholder-blue-700'
  | 'placeholder-blue-800'
  | 'placeholder-blue-900'
  | 'placeholder-indigo-50'
  | 'placeholder-indigo-100'
  | 'placeholder-indigo-200'
  | 'placeholder-indigo-300'
  | 'placeholder-indigo-400'
  | 'placeholder-indigo-500'
  | 'placeholder-indigo-600'
  | 'placeholder-indigo-700'
  | 'placeholder-indigo-800'
  | 'placeholder-indigo-900'
  | 'placeholder-purple-50'
  | 'placeholder-purple-100'
  | 'placeholder-purple-200'
  | 'placeholder-purple-300'
  | 'placeholder-purple-400'
  | 'placeholder-purple-500'
  | 'placeholder-purple-600'
  | 'placeholder-purple-700'
  | 'placeholder-purple-800'
  | 'placeholder-purple-900'
  | 'placeholder-pink-50'
  | 'placeholder-pink-100'
  | 'placeholder-pink-200'
  | 'placeholder-pink-300'
  | 'placeholder-pink-400'
  | 'placeholder-pink-500'
  | 'placeholder-pink-600'
  | 'placeholder-pink-700'
  | 'placeholder-pink-800'
  | 'placeholder-pink-900'
  | 'focus:placeholder-transparent'
  | 'focus:placeholder-white'
  | 'focus:placeholder-black'
  | 'focus:placeholder-gray-50'
  | 'focus:placeholder-gray-100'
  | 'focus:placeholder-gray-200'
  | 'focus:placeholder-gray-300'
  | 'focus:placeholder-gray-400'
  | 'focus:placeholder-gray-500'
  | 'focus:placeholder-gray-600'
  | 'focus:placeholder-gray-700'
  | 'focus:placeholder-gray-800'
  | 'focus:placeholder-gray-900'
  | 'focus:placeholder-cool-gray-50'
  | 'focus:placeholder-cool-gray-100'
  | 'focus:placeholder-cool-gray-200'
  | 'focus:placeholder-cool-gray-300'
  | 'focus:placeholder-cool-gray-400'
  | 'focus:placeholder-cool-gray-500'
  | 'focus:placeholder-cool-gray-600'
  | 'focus:placeholder-cool-gray-700'
  | 'focus:placeholder-cool-gray-800'
  | 'focus:placeholder-cool-gray-900'
  | 'focus:placeholder-red-50'
  | 'focus:placeholder-red-100'
  | 'focus:placeholder-red-200'
  | 'focus:placeholder-red-300'
  | 'focus:placeholder-red-400'
  | 'focus:placeholder-red-500'
  | 'focus:placeholder-red-600'
  | 'focus:placeholder-red-700'
  | 'focus:placeholder-red-800'
  | 'focus:placeholder-red-900'
  | 'focus:placeholder-orange-50'
  | 'focus:placeholder-orange-100'
  | 'focus:placeholder-orange-200'
  | 'focus:placeholder-orange-300'
  | 'focus:placeholder-orange-400'
  | 'focus:placeholder-orange-500'
  | 'focus:placeholder-orange-600'
  | 'focus:placeholder-orange-700'
  | 'focus:placeholder-orange-800'
  | 'focus:placeholder-orange-900'
  | 'focus:placeholder-yellow-50'
  | 'focus:placeholder-yellow-100'
  | 'focus:placeholder-yellow-200'
  | 'focus:placeholder-yellow-300'
  | 'focus:placeholder-yellow-400'
  | 'focus:placeholder-yellow-500'
  | 'focus:placeholder-yellow-600'
  | 'focus:placeholder-yellow-700'
  | 'focus:placeholder-yellow-800'
  | 'focus:placeholder-yellow-900'
  | 'focus:placeholder-green-50'
  | 'focus:placeholder-green-100'
  | 'focus:placeholder-green-200'
  | 'focus:placeholder-green-300'
  | 'focus:placeholder-green-400'
  | 'focus:placeholder-green-500'
  | 'focus:placeholder-green-600'
  | 'focus:placeholder-green-700'
  | 'focus:placeholder-green-800'
  | 'focus:placeholder-green-900'
  | 'focus:placeholder-teal-50'
  | 'focus:placeholder-teal-100'
  | 'focus:placeholder-teal-200'
  | 'focus:placeholder-teal-300'
  | 'focus:placeholder-teal-400'
  | 'focus:placeholder-teal-500'
  | 'focus:placeholder-teal-600'
  | 'focus:placeholder-teal-700'
  | 'focus:placeholder-teal-800'
  | 'focus:placeholder-teal-900'
  | 'focus:placeholder-blue-50'
  | 'focus:placeholder-blue-100'
  | 'focus:placeholder-blue-200'
  | 'focus:placeholder-blue-300'
  | 'focus:placeholder-blue-400'
  | 'focus:placeholder-blue-500'
  | 'focus:placeholder-blue-600'
  | 'focus:placeholder-blue-700'
  | 'focus:placeholder-blue-800'
  | 'focus:placeholder-blue-900'
  | 'focus:placeholder-indigo-50'
  | 'focus:placeholder-indigo-100'
  | 'focus:placeholder-indigo-200'
  | 'focus:placeholder-indigo-300'
  | 'focus:placeholder-indigo-400'
  | 'focus:placeholder-indigo-500'
  | 'focus:placeholder-indigo-600'
  | 'focus:placeholder-indigo-700'
  | 'focus:placeholder-indigo-800'
  | 'focus:placeholder-indigo-900'
  | 'focus:placeholder-purple-50'
  | 'focus:placeholder-purple-100'
  | 'focus:placeholder-purple-200'
  | 'focus:placeholder-purple-300'
  | 'focus:placeholder-purple-400'
  | 'focus:placeholder-purple-500'
  | 'focus:placeholder-purple-600'
  | 'focus:placeholder-purple-700'
  | 'focus:placeholder-purple-800'
  | 'focus:placeholder-purple-900'
  | 'focus:placeholder-pink-50'
  | 'focus:placeholder-pink-100'
  | 'focus:placeholder-pink-200'
  | 'focus:placeholder-pink-300'
  | 'focus:placeholder-pink-400'
  | 'focus:placeholder-pink-500'
  | 'focus:placeholder-pink-600'
  | 'focus:placeholder-pink-700'
  | 'focus:placeholder-pink-800'
  | 'focus:placeholder-pink-900'
  | 'pointer-events-none'
  | 'pointer-events-auto'
  | 'static'
  | 'fixed'
  | 'absolute'
  | 'relative'
  | 'sticky'
  | 'inset-0'
  | 'inset-1'
  | 'inset-2'
  | 'inset-3'
  | 'inset-4'
  | 'inset-5'
  | 'inset-6'
  | 'inset-7'
  | 'inset-8'
  | 'inset-9'
  | 'inset-10'
  | 'inset-11'
  | 'inset-12'
  | 'inset-13'
  | 'inset-14'
  | 'inset-15'
  | 'inset-16'
  | 'inset-20'
  | 'inset-24'
  | 'inset-28'
  | 'inset-32'
  | 'inset-36'
  | 'inset-40'
  | 'inset-48'
  | 'inset-56'
  | 'inset-60'
  | 'inset-64'
  | 'inset-72'
  | 'inset-80'
  | 'inset-96'
  | 'inset-auto'
  | 'inset-px'
  | 'inset-0.5'
  | 'inset-1.5'
  | 'inset-2.5'
  | 'inset-3.5'
  | 'inset-1/2'
  | 'inset-1/3'
  | 'inset-2/3'
  | 'inset-1/4'
  | 'inset-2/4'
  | 'inset-3/4'
  | 'inset-1/5'
  | 'inset-2/5'
  | 'inset-3/5'
  | 'inset-4/5'
  | 'inset-1/6'
  | 'inset-2/6'
  | 'inset-3/6'
  | 'inset-4/6'
  | 'inset-5/6'
  | 'inset-1/12'
  | 'inset-2/12'
  | 'inset-3/12'
  | 'inset-4/12'
  | 'inset-5/12'
  | 'inset-6/12'
  | 'inset-7/12'
  | 'inset-8/12'
  | 'inset-9/12'
  | 'inset-10/12'
  | 'inset-11/12'
  | 'inset-full'
  | 'inset-y-0'
  | 'inset-x-0'
  | 'inset-y-1'
  | 'inset-x-1'
  | 'inset-y-2'
  | 'inset-x-2'
  | 'inset-y-3'
  | 'inset-x-3'
  | 'inset-y-4'
  | 'inset-x-4'
  | 'inset-y-5'
  | 'inset-x-5'
  | 'inset-y-6'
  | 'inset-x-6'
  | 'inset-y-7'
  | 'inset-x-7'
  | 'inset-y-8'
  | 'inset-x-8'
  | 'inset-y-9'
  | 'inset-x-9'
  | 'inset-y-10'
  | 'inset-x-10'
  | 'inset-y-11'
  | 'inset-x-11'
  | 'inset-y-12'
  | 'inset-x-12'
  | 'inset-y-13'
  | 'inset-x-13'
  | 'inset-y-14'
  | 'inset-x-14'
  | 'inset-y-15'
  | 'inset-x-15'
  | 'inset-y-16'
  | 'inset-x-16'
  | 'inset-y-20'
  | 'inset-x-20'
  | 'inset-y-24'
  | 'inset-x-24'
  | 'inset-y-28'
  | 'inset-x-28'
  | 'inset-y-32'
  | 'inset-x-32'
  | 'inset-y-36'
  | 'inset-x-36'
  | 'inset-y-40'
  | 'inset-x-40'
  | 'inset-y-48'
  | 'inset-x-48'
  | 'inset-y-56'
  | 'inset-x-56'
  | 'inset-y-60'
  | 'inset-x-60'
  | 'inset-y-64'
  | 'inset-x-64'
  | 'inset-y-72'
  | 'inset-x-72'
  | 'inset-y-80'
  | 'inset-x-80'
  | 'inset-y-96'
  | 'inset-x-96'
  | 'inset-y-auto'
  | 'inset-x-auto'
  | 'inset-y-px'
  | 'inset-x-px'
  | 'inset-y-0.5'
  | 'inset-x-0.5'
  | 'inset-y-1.5'
  | 'inset-x-1.5'
  | 'inset-y-2.5'
  | 'inset-x-2.5'
  | 'inset-y-3.5'
  | 'inset-x-3.5'
  | 'inset-y-1/2'
  | 'inset-x-1/2'
  | 'inset-y-1/3'
  | 'inset-x-1/3'
  | 'inset-y-2/3'
  | 'inset-x-2/3'
  | 'inset-y-1/4'
  | 'inset-x-1/4'
  | 'inset-y-2/4'
  | 'inset-x-2/4'
  | 'inset-y-3/4'
  | 'inset-x-3/4'
  | 'inset-y-1/5'
  | 'inset-x-1/5'
  | 'inset-y-2/5'
  | 'inset-x-2/5'
  | 'inset-y-3/5'
  | 'inset-x-3/5'
  | 'inset-y-4/5'
  | 'inset-x-4/5'
  | 'inset-y-1/6'
  | 'inset-x-1/6'
  | 'inset-y-2/6'
  | 'inset-x-2/6'
  | 'inset-y-3/6'
  | 'inset-x-3/6'
  | 'inset-y-4/6'
  | 'inset-x-4/6'
  | 'inset-y-5/6'
  | 'inset-x-5/6'
  | 'inset-y-1/12'
  | 'inset-x-1/12'
  | 'inset-y-2/12'
  | 'inset-x-2/12'
  | 'inset-y-3/12'
  | 'inset-x-3/12'
  | 'inset-y-4/12'
  | 'inset-x-4/12'
  | 'inset-y-5/12'
  | 'inset-x-5/12'
  | 'inset-y-6/12'
  | 'inset-x-6/12'
  | 'inset-y-7/12'
  | 'inset-x-7/12'
  | 'inset-y-8/12'
  | 'inset-x-8/12'
  | 'inset-y-9/12'
  | 'inset-x-9/12'
  | 'inset-y-10/12'
  | 'inset-x-10/12'
  | 'inset-y-11/12'
  | 'inset-x-11/12'
  | 'inset-y-full'
  | 'inset-x-full'
  | 'top-0'
  | 'right-0'
  | 'bottom-0'
  | 'left-0'
  | 'top-1'
  | 'right-1'
  | 'bottom-1'
  | 'left-1'
  | 'top-2'
  | 'right-2'
  | 'bottom-2'
  | 'left-2'
  | 'top-3'
  | 'right-3'
  | 'bottom-3'
  | 'left-3'
  | 'top-4'
  | 'right-4'
  | 'bottom-4'
  | 'left-4'
  | 'top-5'
  | 'right-5'
  | 'bottom-5'
  | 'left-5'
  | 'top-6'
  | 'right-6'
  | 'bottom-6'
  | 'left-6'
  | 'top-7'
  | 'right-7'
  | 'bottom-7'
  | 'left-7'
  | 'top-8'
  | 'right-8'
  | 'bottom-8'
  | 'left-8'
  | 'top-9'
  | 'right-9'
  | 'bottom-9'
  | 'left-9'
  | 'top-10'
  | 'right-10'
  | 'bottom-10'
  | 'left-10'
  | 'top-11'
  | 'right-11'
  | 'bottom-11'
  | 'left-11'
  | 'top-12'
  | 'right-12'
  | 'bottom-12'
  | 'left-12'
  | 'top-13'
  | 'right-13'
  | 'bottom-13'
  | 'left-13'
  | 'top-14'
  | 'right-14'
  | 'bottom-14'
  | 'left-14'
  | 'top-15'
  | 'right-15'
  | 'bottom-15'
  | 'left-15'
  | 'top-16'
  | 'right-16'
  | 'bottom-16'
  | 'left-16'
  | 'top-20'
  | 'right-20'
  | 'bottom-20'
  | 'left-20'
  | 'top-24'
  | 'right-24'
  | 'bottom-24'
  | 'left-24'
  | 'top-28'
  | 'right-28'
  | 'bottom-28'
  | 'left-28'
  | 'top-32'
  | 'right-32'
  | 'bottom-32'
  | 'left-32'
  | 'top-36'
  | 'right-36'
  | 'bottom-36'
  | 'left-36'
  | 'top-40'
  | 'right-40'
  | 'bottom-40'
  | 'left-40'
  | 'top-48'
  | 'right-48'
  | 'bottom-48'
  | 'left-48'
  | 'top-56'
  | 'right-56'
  | 'bottom-56'
  | 'left-56'
  | 'top-60'
  | 'right-60'
  | 'bottom-60'
  | 'left-60'
  | 'top-64'
  | 'right-64'
  | 'bottom-64'
  | 'left-64'
  | 'top-72'
  | 'right-72'
  | 'bottom-72'
  | 'left-72'
  | 'top-80'
  | 'right-80'
  | 'bottom-80'
  | 'left-80'
  | 'top-96'
  | 'right-96'
  | 'bottom-96'
  | 'left-96'
  | 'top-auto'
  | 'right-auto'
  | 'bottom-auto'
  | 'left-auto'
  | 'top-px'
  | 'right-px'
  | 'bottom-px'
  | 'left-px'
  | 'top-0.5'
  | 'right-0.5'
  | 'bottom-0.5'
  | 'left-0.5'
  | 'top-1.5'
  | 'right-1.5'
  | 'bottom-1.5'
  | 'left-1.5'
  | 'top-2.5'
  | 'right-2.5'
  | 'bottom-2.5'
  | 'left-2.5'
  | 'top-3.5'
  | 'right-3.5'
  | 'bottom-3.5'
  | 'left-3.5'
  | 'top-1/2'
  | 'right-1/2'
  | 'bottom-1/2'
  | 'left-1/2'
  | 'top-1/3'
  | 'right-1/3'
  | 'bottom-1/3'
  | 'left-1/3'
  | 'top-2/3'
  | 'right-2/3'
  | 'bottom-2/3'
  | 'left-2/3'
  | 'top-1/4'
  | 'right-1/4'
  | 'bottom-1/4'
  | 'left-1/4'
  | 'top-2/4'
  | 'right-2/4'
  | 'bottom-2/4'
  | 'left-2/4'
  | 'top-3/4'
  | 'right-3/4'
  | 'bottom-3/4'
  | 'left-3/4'
  | 'top-1/5'
  | 'right-1/5'
  | 'bottom-1/5'
  | 'left-1/5'
  | 'top-2/5'
  | 'right-2/5'
  | 'bottom-2/5'
  | 'left-2/5'
  | 'top-3/5'
  | 'right-3/5'
  | 'bottom-3/5'
  | 'left-3/5'
  | 'top-4/5'
  | 'right-4/5'
  | 'bottom-4/5'
  | 'left-4/5'
  | 'top-1/6'
  | 'right-1/6'
  | 'bottom-1/6'
  | 'left-1/6'
  | 'top-2/6'
  | 'right-2/6'
  | 'bottom-2/6'
  | 'left-2/6'
  | 'top-3/6'
  | 'right-3/6'
  | 'bottom-3/6'
  | 'left-3/6'
  | 'top-4/6'
  | 'right-4/6'
  | 'bottom-4/6'
  | 'left-4/6'
  | 'top-5/6'
  | 'right-5/6'
  | 'bottom-5/6'
  | 'left-5/6'
  | 'top-1/12'
  | 'right-1/12'
  | 'bottom-1/12'
  | 'left-1/12'
  | 'top-2/12'
  | 'right-2/12'
  | 'bottom-2/12'
  | 'left-2/12'
  | 'top-3/12'
  | 'right-3/12'
  | 'bottom-3/12'
  | 'left-3/12'
  | 'top-4/12'
  | 'right-4/12'
  | 'bottom-4/12'
  | 'left-4/12'
  | 'top-5/12'
  | 'right-5/12'
  | 'bottom-5/12'
  | 'left-5/12'
  | 'top-6/12'
  | 'right-6/12'
  | 'bottom-6/12'
  | 'left-6/12'
  | 'top-7/12'
  | 'right-7/12'
  | 'bottom-7/12'
  | 'left-7/12'
  | 'top-8/12'
  | 'right-8/12'
  | 'bottom-8/12'
  | 'left-8/12'
  | 'top-9/12'
  | 'right-9/12'
  | 'bottom-9/12'
  | 'left-9/12'
  | 'top-10/12'
  | 'right-10/12'
  | 'bottom-10/12'
  | 'left-10/12'
  | 'top-11/12'
  | 'right-11/12'
  | 'bottom-11/12'
  | 'left-11/12'
  | 'top-full'
  | 'right-full'
  | 'bottom-full'
  | 'left-full'
  | 'resize-none'
  | 'resize-y'
  | 'resize-x'
  | 'resize'
  | 'shadow-xs'
  | 'shadow-sm'
  | 'shadow'
  | 'shadow-md'
  | 'shadow-lg'
  | 'shadow-xl'
  | 'shadow-2xl'
  | 'shadow-inner'
  | 'shadow-outline'
  | 'shadow-none'
  | 'shadow-solid'
  | 'shadow-outline-gray'
  | 'shadow-outline-blue'
  | 'shadow-outline-teal'
  | 'shadow-outline-green'
  | 'shadow-outline-yellow'
  | 'shadow-outline-orange'
  | 'shadow-outline-red'
  | 'shadow-outline-pink'
  | 'shadow-outline-purple'
  | 'shadow-outline-indigo'
  | 'hover:shadow-xs'
  | 'hover:shadow-sm'
  | 'hover:shadow'
  | 'hover:shadow-md'
  | 'hover:shadow-lg'
  | 'hover:shadow-xl'
  | 'hover:shadow-2xl'
  | 'hover:shadow-inner'
  | 'hover:shadow-outline'
  | 'hover:shadow-none'
  | 'hover:shadow-solid'
  | 'hover:shadow-outline-gray'
  | 'hover:shadow-outline-blue'
  | 'hover:shadow-outline-teal'
  | 'hover:shadow-outline-green'
  | 'hover:shadow-outline-yellow'
  | 'hover:shadow-outline-orange'
  | 'hover:shadow-outline-red'
  | 'hover:shadow-outline-pink'
  | 'hover:shadow-outline-purple'
  | 'hover:shadow-outline-indigo'
  | 'focus:shadow-xs'
  | 'focus:shadow-sm'
  | 'focus:shadow'
  | 'focus:shadow-md'
  | 'focus:shadow-lg'
  | 'focus:shadow-xl'
  | 'focus:shadow-2xl'
  | 'focus:shadow-inner'
  | 'focus:shadow-outline'
  | 'focus:shadow-none'
  | 'focus:shadow-solid'
  | 'focus:shadow-outline-gray'
  | 'focus:shadow-outline-blue'
  | 'focus:shadow-outline-teal'
  | 'focus:shadow-outline-green'
  | 'focus:shadow-outline-yellow'
  | 'focus:shadow-outline-orange'
  | 'focus:shadow-outline-red'
  | 'focus:shadow-outline-pink'
  | 'focus:shadow-outline-purple'
  | 'focus:shadow-outline-indigo'
  | 'fill-current'
  | 'stroke-current'
  | 'stroke-0'
  | 'stroke-1'
  | 'stroke-2'
  | 'table-auto'
  | 'table-fixed'
  | 'text-left'
  | 'text-center'
  | 'text-right'
  | 'text-justify'
  | 'text-transparent'
  | 'text-white'
  | 'text-black'
  | 'text-gray-50'
  | 'text-gray-100'
  | 'text-gray-200'
  | 'text-gray-300'
  | 'text-gray-400'
  | 'text-gray-500'
  | 'text-gray-600'
  | 'text-gray-700'
  | 'text-gray-800'
  | 'text-gray-900'
  | 'text-cool-gray-50'
  | 'text-cool-gray-100'
  | 'text-cool-gray-200'
  | 'text-cool-gray-300'
  | 'text-cool-gray-400'
  | 'text-cool-gray-500'
  | 'text-cool-gray-600'
  | 'text-cool-gray-700'
  | 'text-cool-gray-800'
  | 'text-cool-gray-900'
  | 'text-red-50'
  | 'text-red-100'
  | 'text-red-200'
  | 'text-red-300'
  | 'text-red-400'
  | 'text-red-500'
  | 'text-red-600'
  | 'text-red-700'
  | 'text-red-800'
  | 'text-red-900'
  | 'text-orange-50'
  | 'text-orange-100'
  | 'text-orange-200'
  | 'text-orange-300'
  | 'text-orange-400'
  | 'text-orange-500'
  | 'text-orange-600'
  | 'text-orange-700'
  | 'text-orange-800'
  | 'text-orange-900'
  | 'text-yellow-50'
  | 'text-yellow-100'
  | 'text-yellow-200'
  | 'text-yellow-300'
  | 'text-yellow-400'
  | 'text-yellow-500'
  | 'text-yellow-600'
  | 'text-yellow-700'
  | 'text-yellow-800'
  | 'text-yellow-900'
  | 'text-green-50'
  | 'text-green-100'
  | 'text-green-200'
  | 'text-green-300'
  | 'text-green-400'
  | 'text-green-500'
  | 'text-green-600'
  | 'text-green-700'
  | 'text-green-800'
  | 'text-green-900'
  | 'text-teal-50'
  | 'text-teal-100'
  | 'text-teal-200'
  | 'text-teal-300'
  | 'text-teal-400'
  | 'text-teal-500'
  | 'text-teal-600'
  | 'text-teal-700'
  | 'text-teal-800'
  | 'text-teal-900'
  | 'text-blue-50'
  | 'text-blue-100'
  | 'text-blue-200'
  | 'text-blue-300'
  | 'text-blue-400'
  | 'text-blue-500'
  | 'text-blue-600'
  | 'text-blue-700'
  | 'text-blue-800'
  | 'text-blue-900'
  | 'text-indigo-50'
  | 'text-indigo-100'
  | 'text-indigo-200'
  | 'text-indigo-300'
  | 'text-indigo-400'
  | 'text-indigo-500'
  | 'text-indigo-600'
  | 'text-indigo-700'
  | 'text-indigo-800'
  | 'text-indigo-900'
  | 'text-purple-50'
  | 'text-purple-100'
  | 'text-purple-200'
  | 'text-purple-300'
  | 'text-purple-400'
  | 'text-purple-500'
  | 'text-purple-600'
  | 'text-purple-700'
  | 'text-purple-800'
  | 'text-purple-900'
  | 'text-pink-50'
  | 'text-pink-100'
  | 'text-pink-200'
  | 'text-pink-300'
  | 'text-pink-400'
  | 'text-pink-500'
  | 'text-pink-600'
  | 'text-pink-700'
  | 'text-pink-800'
  | 'text-pink-900'
  | 'group-hover:text-transparent'
  | 'group-hover:text-white'
  | 'group-hover:text-black'
  | 'group-hover:text-gray-50'
  | 'group-hover:text-gray-100'
  | 'group-hover:text-gray-200'
  | 'group-hover:text-gray-300'
  | 'group-hover:text-gray-400'
  | 'group-hover:text-gray-500'
  | 'group-hover:text-gray-600'
  | 'group-hover:text-gray-700'
  | 'group-hover:text-gray-800'
  | 'group-hover:text-gray-900'
  | 'group-hover:text-cool-gray-50'
  | 'group-hover:text-cool-gray-100'
  | 'group-hover:text-cool-gray-200'
  | 'group-hover:text-cool-gray-300'
  | 'group-hover:text-cool-gray-400'
  | 'group-hover:text-cool-gray-500'
  | 'group-hover:text-cool-gray-600'
  | 'group-hover:text-cool-gray-700'
  | 'group-hover:text-cool-gray-800'
  | 'group-hover:text-cool-gray-900'
  | 'group-hover:text-red-50'
  | 'group-hover:text-red-100'
  | 'group-hover:text-red-200'
  | 'group-hover:text-red-300'
  | 'group-hover:text-red-400'
  | 'group-hover:text-red-500'
  | 'group-hover:text-red-600'
  | 'group-hover:text-red-700'
  | 'group-hover:text-red-800'
  | 'group-hover:text-red-900'
  | 'group-hover:text-orange-50'
  | 'group-hover:text-orange-100'
  | 'group-hover:text-orange-200'
  | 'group-hover:text-orange-300'
  | 'group-hover:text-orange-400'
  | 'group-hover:text-orange-500'
  | 'group-hover:text-orange-600'
  | 'group-hover:text-orange-700'
  | 'group-hover:text-orange-800'
  | 'group-hover:text-orange-900'
  | 'group-hover:text-yellow-50'
  | 'group-hover:text-yellow-100'
  | 'group-hover:text-yellow-200'
  | 'group-hover:text-yellow-300'
  | 'group-hover:text-yellow-400'
  | 'group-hover:text-yellow-500'
  | 'group-hover:text-yellow-600'
  | 'group-hover:text-yellow-700'
  | 'group-hover:text-yellow-800'
  | 'group-hover:text-yellow-900'
  | 'group-hover:text-green-50'
  | 'group-hover:text-green-100'
  | 'group-hover:text-green-200'
  | 'group-hover:text-green-300'
  | 'group-hover:text-green-400'
  | 'group-hover:text-green-500'
  | 'group-hover:text-green-600'
  | 'group-hover:text-green-700'
  | 'group-hover:text-green-800'
  | 'group-hover:text-green-900'
  | 'group-hover:text-teal-50'
  | 'group-hover:text-teal-100'
  | 'group-hover:text-teal-200'
  | 'group-hover:text-teal-300'
  | 'group-hover:text-teal-400'
  | 'group-hover:text-teal-500'
  | 'group-hover:text-teal-600'
  | 'group-hover:text-teal-700'
  | 'group-hover:text-teal-800'
  | 'group-hover:text-teal-900'
  | 'group-hover:text-blue-50'
  | 'group-hover:text-blue-100'
  | 'group-hover:text-blue-200'
  | 'group-hover:text-blue-300'
  | 'group-hover:text-blue-400'
  | 'group-hover:text-blue-500'
  | 'group-hover:text-blue-600'
  | 'group-hover:text-blue-700'
  | 'group-hover:text-blue-800'
  | 'group-hover:text-blue-900'
  | 'group-hover:text-indigo-50'
  | 'group-hover:text-indigo-100'
  | 'group-hover:text-indigo-200'
  | 'group-hover:text-indigo-300'
  | 'group-hover:text-indigo-400'
  | 'group-hover:text-indigo-500'
  | 'group-hover:text-indigo-600'
  | 'group-hover:text-indigo-700'
  | 'group-hover:text-indigo-800'
  | 'group-hover:text-indigo-900'
  | 'group-hover:text-purple-50'
  | 'group-hover:text-purple-100'
  | 'group-hover:text-purple-200'
  | 'group-hover:text-purple-300'
  | 'group-hover:text-purple-400'
  | 'group-hover:text-purple-500'
  | 'group-hover:text-purple-600'
  | 'group-hover:text-purple-700'
  | 'group-hover:text-purple-800'
  | 'group-hover:text-purple-900'
  | 'group-hover:text-pink-50'
  | 'group-hover:text-pink-100'
  | 'group-hover:text-pink-200'
  | 'group-hover:text-pink-300'
  | 'group-hover:text-pink-400'
  | 'group-hover:text-pink-500'
  | 'group-hover:text-pink-600'
  | 'group-hover:text-pink-700'
  | 'group-hover:text-pink-800'
  | 'group-hover:text-pink-900'
  | 'group-focus:text-transparent'
  | 'group-focus:text-white'
  | 'group-focus:text-black'
  | 'group-focus:text-gray-50'
  | 'group-focus:text-gray-100'
  | 'group-focus:text-gray-200'
  | 'group-focus:text-gray-300'
  | 'group-focus:text-gray-400'
  | 'group-focus:text-gray-500'
  | 'group-focus:text-gray-600'
  | 'group-focus:text-gray-700'
  | 'group-focus:text-gray-800'
  | 'group-focus:text-gray-900'
  | 'group-focus:text-cool-gray-50'
  | 'group-focus:text-cool-gray-100'
  | 'group-focus:text-cool-gray-200'
  | 'group-focus:text-cool-gray-300'
  | 'group-focus:text-cool-gray-400'
  | 'group-focus:text-cool-gray-500'
  | 'group-focus:text-cool-gray-600'
  | 'group-focus:text-cool-gray-700'
  | 'group-focus:text-cool-gray-800'
  | 'group-focus:text-cool-gray-900'
  | 'group-focus:text-red-50'
  | 'group-focus:text-red-100'
  | 'group-focus:text-red-200'
  | 'group-focus:text-red-300'
  | 'group-focus:text-red-400'
  | 'group-focus:text-red-500'
  | 'group-focus:text-red-600'
  | 'group-focus:text-red-700'
  | 'group-focus:text-red-800'
  | 'group-focus:text-red-900'
  | 'group-focus:text-orange-50'
  | 'group-focus:text-orange-100'
  | 'group-focus:text-orange-200'
  | 'group-focus:text-orange-300'
  | 'group-focus:text-orange-400'
  | 'group-focus:text-orange-500'
  | 'group-focus:text-orange-600'
  | 'group-focus:text-orange-700'
  | 'group-focus:text-orange-800'
  | 'group-focus:text-orange-900'
  | 'group-focus:text-yellow-50'
  | 'group-focus:text-yellow-100'
  | 'group-focus:text-yellow-200'
  | 'group-focus:text-yellow-300'
  | 'group-focus:text-yellow-400'
  | 'group-focus:text-yellow-500'
  | 'group-focus:text-yellow-600'
  | 'group-focus:text-yellow-700'
  | 'group-focus:text-yellow-800'
  | 'group-focus:text-yellow-900'
  | 'group-focus:text-green-50'
  | 'group-focus:text-green-100'
  | 'group-focus:text-green-200'
  | 'group-focus:text-green-300'
  | 'group-focus:text-green-400'
  | 'group-focus:text-green-500'
  | 'group-focus:text-green-600'
  | 'group-focus:text-green-700'
  | 'group-focus:text-green-800'
  | 'group-focus:text-green-900'
  | 'group-focus:text-teal-50'
  | 'group-focus:text-teal-100'
  | 'group-focus:text-teal-200'
  | 'group-focus:text-teal-300'
  | 'group-focus:text-teal-400'
  | 'group-focus:text-teal-500'
  | 'group-focus:text-teal-600'
  | 'group-focus:text-teal-700'
  | 'group-focus:text-teal-800'
  | 'group-focus:text-teal-900'
  | 'group-focus:text-blue-50'
  | 'group-focus:text-blue-100'
  | 'group-focus:text-blue-200'
  | 'group-focus:text-blue-300'
  | 'group-focus:text-blue-400'
  | 'group-focus:text-blue-500'
  | 'group-focus:text-blue-600'
  | 'group-focus:text-blue-700'
  | 'group-focus:text-blue-800'
  | 'group-focus:text-blue-900'
  | 'group-focus:text-indigo-50'
  | 'group-focus:text-indigo-100'
  | 'group-focus:text-indigo-200'
  | 'group-focus:text-indigo-300'
  | 'group-focus:text-indigo-400'
  | 'group-focus:text-indigo-500'
  | 'group-focus:text-indigo-600'
  | 'group-focus:text-indigo-700'
  | 'group-focus:text-indigo-800'
  | 'group-focus:text-indigo-900'
  | 'group-focus:text-purple-50'
  | 'group-focus:text-purple-100'
  | 'group-focus:text-purple-200'
  | 'group-focus:text-purple-300'
  | 'group-focus:text-purple-400'
  | 'group-focus:text-purple-500'
  | 'group-focus:text-purple-600'
  | 'group-focus:text-purple-700'
  | 'group-focus:text-purple-800'
  | 'group-focus:text-purple-900'
  | 'group-focus:text-pink-50'
  | 'group-focus:text-pink-100'
  | 'group-focus:text-pink-200'
  | 'group-focus:text-pink-300'
  | 'group-focus:text-pink-400'
  | 'group-focus:text-pink-500'
  | 'group-focus:text-pink-600'
  | 'group-focus:text-pink-700'
  | 'group-focus:text-pink-800'
  | 'group-focus:text-pink-900'
  | 'hover:text-transparent'
  | 'hover:text-white'
  | 'hover:text-black'
  | 'hover:text-gray-50'
  | 'hover:text-gray-100'
  | 'hover:text-gray-200'
  | 'hover:text-gray-300'
  | 'hover:text-gray-400'
  | 'hover:text-gray-500'
  | 'hover:text-gray-600'
  | 'hover:text-gray-700'
  | 'hover:text-gray-800'
  | 'hover:text-gray-900'
  | 'hover:text-cool-gray-50'
  | 'hover:text-cool-gray-100'
  | 'hover:text-cool-gray-200'
  | 'hover:text-cool-gray-300'
  | 'hover:text-cool-gray-400'
  | 'hover:text-cool-gray-500'
  | 'hover:text-cool-gray-600'
  | 'hover:text-cool-gray-700'
  | 'hover:text-cool-gray-800'
  | 'hover:text-cool-gray-900'
  | 'hover:text-red-50'
  | 'hover:text-red-100'
  | 'hover:text-red-200'
  | 'hover:text-red-300'
  | 'hover:text-red-400'
  | 'hover:text-red-500'
  | 'hover:text-red-600'
  | 'hover:text-red-700'
  | 'hover:text-red-800'
  | 'hover:text-red-900'
  | 'hover:text-orange-50'
  | 'hover:text-orange-100'
  | 'hover:text-orange-200'
  | 'hover:text-orange-300'
  | 'hover:text-orange-400'
  | 'hover:text-orange-500'
  | 'hover:text-orange-600'
  | 'hover:text-orange-700'
  | 'hover:text-orange-800'
  | 'hover:text-orange-900'
  | 'hover:text-yellow-50'
  | 'hover:text-yellow-100'
  | 'hover:text-yellow-200'
  | 'hover:text-yellow-300'
  | 'hover:text-yellow-400'
  | 'hover:text-yellow-500'
  | 'hover:text-yellow-600'
  | 'hover:text-yellow-700'
  | 'hover:text-yellow-800'
  | 'hover:text-yellow-900'
  | 'hover:text-green-50'
  | 'hover:text-green-100'
  | 'hover:text-green-200'
  | 'hover:text-green-300'
  | 'hover:text-green-400'
  | 'hover:text-green-500'
  | 'hover:text-green-600'
  | 'hover:text-green-700'
  | 'hover:text-green-800'
  | 'hover:text-green-900'
  | 'hover:text-teal-50'
  | 'hover:text-teal-100'
  | 'hover:text-teal-200'
  | 'hover:text-teal-300'
  | 'hover:text-teal-400'
  | 'hover:text-teal-500'
  | 'hover:text-teal-600'
  | 'hover:text-teal-700'
  | 'hover:text-teal-800'
  | 'hover:text-teal-900'
  | 'hover:text-blue-50'
  | 'hover:text-blue-100'
  | 'hover:text-blue-200'
  | 'hover:text-blue-300'
  | 'hover:text-blue-400'
  | 'hover:text-blue-500'
  | 'hover:text-blue-600'
  | 'hover:text-blue-700'
  | 'hover:text-blue-800'
  | 'hover:text-blue-900'
  | 'hover:text-indigo-50'
  | 'hover:text-indigo-100'
  | 'hover:text-indigo-200'
  | 'hover:text-indigo-300'
  | 'hover:text-indigo-400'
  | 'hover:text-indigo-500'
  | 'hover:text-indigo-600'
  | 'hover:text-indigo-700'
  | 'hover:text-indigo-800'
  | 'hover:text-indigo-900'
  | 'hover:text-purple-50'
  | 'hover:text-purple-100'
  | 'hover:text-purple-200'
  | 'hover:text-purple-300'
  | 'hover:text-purple-400'
  | 'hover:text-purple-500'
  | 'hover:text-purple-600'
  | 'hover:text-purple-700'
  | 'hover:text-purple-800'
  | 'hover:text-purple-900'
  | 'hover:text-pink-50'
  | 'hover:text-pink-100'
  | 'hover:text-pink-200'
  | 'hover:text-pink-300'
  | 'hover:text-pink-400'
  | 'hover:text-pink-500'
  | 'hover:text-pink-600'
  | 'hover:text-pink-700'
  | 'hover:text-pink-800'
  | 'hover:text-pink-900'
  | 'focus-within:text-transparent'
  | 'focus-within:text-white'
  | 'focus-within:text-black'
  | 'focus-within:text-gray-50'
  | 'focus-within:text-gray-100'
  | 'focus-within:text-gray-200'
  | 'focus-within:text-gray-300'
  | 'focus-within:text-gray-400'
  | 'focus-within:text-gray-500'
  | 'focus-within:text-gray-600'
  | 'focus-within:text-gray-700'
  | 'focus-within:text-gray-800'
  | 'focus-within:text-gray-900'
  | 'focus-within:text-cool-gray-50'
  | 'focus-within:text-cool-gray-100'
  | 'focus-within:text-cool-gray-200'
  | 'focus-within:text-cool-gray-300'
  | 'focus-within:text-cool-gray-400'
  | 'focus-within:text-cool-gray-500'
  | 'focus-within:text-cool-gray-600'
  | 'focus-within:text-cool-gray-700'
  | 'focus-within:text-cool-gray-800'
  | 'focus-within:text-cool-gray-900'
  | 'focus-within:text-red-50'
  | 'focus-within:text-red-100'
  | 'focus-within:text-red-200'
  | 'focus-within:text-red-300'
  | 'focus-within:text-red-400'
  | 'focus-within:text-red-500'
  | 'focus-within:text-red-600'
  | 'focus-within:text-red-700'
  | 'focus-within:text-red-800'
  | 'focus-within:text-red-900'
  | 'focus-within:text-orange-50'
  | 'focus-within:text-orange-100'
  | 'focus-within:text-orange-200'
  | 'focus-within:text-orange-300'
  | 'focus-within:text-orange-400'
  | 'focus-within:text-orange-500'
  | 'focus-within:text-orange-600'
  | 'focus-within:text-orange-700'
  | 'focus-within:text-orange-800'
  | 'focus-within:text-orange-900'
  | 'focus-within:text-yellow-50'
  | 'focus-within:text-yellow-100'
  | 'focus-within:text-yellow-200'
  | 'focus-within:text-yellow-300'
  | 'focus-within:text-yellow-400'
  | 'focus-within:text-yellow-500'
  | 'focus-within:text-yellow-600'
  | 'focus-within:text-yellow-700'
  | 'focus-within:text-yellow-800'
  | 'focus-within:text-yellow-900'
  | 'focus-within:text-green-50'
  | 'focus-within:text-green-100'
  | 'focus-within:text-green-200'
  | 'focus-within:text-green-300'
  | 'focus-within:text-green-400'
  | 'focus-within:text-green-500'
  | 'focus-within:text-green-600'
  | 'focus-within:text-green-700'
  | 'focus-within:text-green-800'
  | 'focus-within:text-green-900'
  | 'focus-within:text-teal-50'
  | 'focus-within:text-teal-100'
  | 'focus-within:text-teal-200'
  | 'focus-within:text-teal-300'
  | 'focus-within:text-teal-400'
  | 'focus-within:text-teal-500'
  | 'focus-within:text-teal-600'
  | 'focus-within:text-teal-700'
  | 'focus-within:text-teal-800'
  | 'focus-within:text-teal-900'
  | 'focus-within:text-blue-50'
  | 'focus-within:text-blue-100'
  | 'focus-within:text-blue-200'
  | 'focus-within:text-blue-300'
  | 'focus-within:text-blue-400'
  | 'focus-within:text-blue-500'
  | 'focus-within:text-blue-600'
  | 'focus-within:text-blue-700'
  | 'focus-within:text-blue-800'
  | 'focus-within:text-blue-900'
  | 'focus-within:text-indigo-50'
  | 'focus-within:text-indigo-100'
  | 'focus-within:text-indigo-200'
  | 'focus-within:text-indigo-300'
  | 'focus-within:text-indigo-400'
  | 'focus-within:text-indigo-500'
  | 'focus-within:text-indigo-600'
  | 'focus-within:text-indigo-700'
  | 'focus-within:text-indigo-800'
  | 'focus-within:text-indigo-900'
  | 'focus-within:text-purple-50'
  | 'focus-within:text-purple-100'
  | 'focus-within:text-purple-200'
  | 'focus-within:text-purple-300'
  | 'focus-within:text-purple-400'
  | 'focus-within:text-purple-500'
  | 'focus-within:text-purple-600'
  | 'focus-within:text-purple-700'
  | 'focus-within:text-purple-800'
  | 'focus-within:text-purple-900'
  | 'focus-within:text-pink-50'
  | 'focus-within:text-pink-100'
  | 'focus-within:text-pink-200'
  | 'focus-within:text-pink-300'
  | 'focus-within:text-pink-400'
  | 'focus-within:text-pink-500'
  | 'focus-within:text-pink-600'
  | 'focus-within:text-pink-700'
  | 'focus-within:text-pink-800'
  | 'focus-within:text-pink-900'
  | 'focus:text-transparent'
  | 'focus:text-white'
  | 'focus:text-black'
  | 'focus:text-gray-50'
  | 'focus:text-gray-100'
  | 'focus:text-gray-200'
  | 'focus:text-gray-300'
  | 'focus:text-gray-400'
  | 'focus:text-gray-500'
  | 'focus:text-gray-600'
  | 'focus:text-gray-700'
  | 'focus:text-gray-800'
  | 'focus:text-gray-900'
  | 'focus:text-cool-gray-50'
  | 'focus:text-cool-gray-100'
  | 'focus:text-cool-gray-200'
  | 'focus:text-cool-gray-300'
  | 'focus:text-cool-gray-400'
  | 'focus:text-cool-gray-500'
  | 'focus:text-cool-gray-600'
  | 'focus:text-cool-gray-700'
  | 'focus:text-cool-gray-800'
  | 'focus:text-cool-gray-900'
  | 'focus:text-red-50'
  | 'focus:text-red-100'
  | 'focus:text-red-200'
  | 'focus:text-red-300'
  | 'focus:text-red-400'
  | 'focus:text-red-500'
  | 'focus:text-red-600'
  | 'focus:text-red-700'
  | 'focus:text-red-800'
  | 'focus:text-red-900'
  | 'focus:text-orange-50'
  | 'focus:text-orange-100'
  | 'focus:text-orange-200'
  | 'focus:text-orange-300'
  | 'focus:text-orange-400'
  | 'focus:text-orange-500'
  | 'focus:text-orange-600'
  | 'focus:text-orange-700'
  | 'focus:text-orange-800'
  | 'focus:text-orange-900'
  | 'focus:text-yellow-50'
  | 'focus:text-yellow-100'
  | 'focus:text-yellow-200'
  | 'focus:text-yellow-300'
  | 'focus:text-yellow-400'
  | 'focus:text-yellow-500'
  | 'focus:text-yellow-600'
  | 'focus:text-yellow-700'
  | 'focus:text-yellow-800'
  | 'focus:text-yellow-900'
  | 'focus:text-green-50'
  | 'focus:text-green-100'
  | 'focus:text-green-200'
  | 'focus:text-green-300'
  | 'focus:text-green-400'
  | 'focus:text-green-500'
  | 'focus:text-green-600'
  | 'focus:text-green-700'
  | 'focus:text-green-800'
  | 'focus:text-green-900'
  | 'focus:text-teal-50'
  | 'focus:text-teal-100'
  | 'focus:text-teal-200'
  | 'focus:text-teal-300'
  | 'focus:text-teal-400'
  | 'focus:text-teal-500'
  | 'focus:text-teal-600'
  | 'focus:text-teal-700'
  | 'focus:text-teal-800'
  | 'focus:text-teal-900'
  | 'focus:text-blue-50'
  | 'focus:text-blue-100'
  | 'focus:text-blue-200'
  | 'focus:text-blue-300'
  | 'focus:text-blue-400'
  | 'focus:text-blue-500'
  | 'focus:text-blue-600'
  | 'focus:text-blue-700'
  | 'focus:text-blue-800'
  | 'focus:text-blue-900'
  | 'focus:text-indigo-50'
  | 'focus:text-indigo-100'
  | 'focus:text-indigo-200'
  | 'focus:text-indigo-300'
  | 'focus:text-indigo-400'
  | 'focus:text-indigo-500'
  | 'focus:text-indigo-600'
  | 'focus:text-indigo-700'
  | 'focus:text-indigo-800'
  | 'focus:text-indigo-900'
  | 'focus:text-purple-50'
  | 'focus:text-purple-100'
  | 'focus:text-purple-200'
  | 'focus:text-purple-300'
  | 'focus:text-purple-400'
  | 'focus:text-purple-500'
  | 'focus:text-purple-600'
  | 'focus:text-purple-700'
  | 'focus:text-purple-800'
  | 'focus:text-purple-900'
  | 'focus:text-pink-50'
  | 'focus:text-pink-100'
  | 'focus:text-pink-200'
  | 'focus:text-pink-300'
  | 'focus:text-pink-400'
  | 'focus:text-pink-500'
  | 'focus:text-pink-600'
  | 'focus:text-pink-700'
  | 'focus:text-pink-800'
  | 'focus:text-pink-900'
  | 'active:text-transparent'
  | 'active:text-white'
  | 'active:text-black'
  | 'active:text-gray-50'
  | 'active:text-gray-100'
  | 'active:text-gray-200'
  | 'active:text-gray-300'
  | 'active:text-gray-400'
  | 'active:text-gray-500'
  | 'active:text-gray-600'
  | 'active:text-gray-700'
  | 'active:text-gray-800'
  | 'active:text-gray-900'
  | 'active:text-cool-gray-50'
  | 'active:text-cool-gray-100'
  | 'active:text-cool-gray-200'
  | 'active:text-cool-gray-300'
  | 'active:text-cool-gray-400'
  | 'active:text-cool-gray-500'
  | 'active:text-cool-gray-600'
  | 'active:text-cool-gray-700'
  | 'active:text-cool-gray-800'
  | 'active:text-cool-gray-900'
  | 'active:text-red-50'
  | 'active:text-red-100'
  | 'active:text-red-200'
  | 'active:text-red-300'
  | 'active:text-red-400'
  | 'active:text-red-500'
  | 'active:text-red-600'
  | 'active:text-red-700'
  | 'active:text-red-800'
  | 'active:text-red-900'
  | 'active:text-orange-50'
  | 'active:text-orange-100'
  | 'active:text-orange-200'
  | 'active:text-orange-300'
  | 'active:text-orange-400'
  | 'active:text-orange-500'
  | 'active:text-orange-600'
  | 'active:text-orange-700'
  | 'active:text-orange-800'
  | 'active:text-orange-900'
  | 'active:text-yellow-50'
  | 'active:text-yellow-100'
  | 'active:text-yellow-200'
  | 'active:text-yellow-300'
  | 'active:text-yellow-400'
  | 'active:text-yellow-500'
  | 'active:text-yellow-600'
  | 'active:text-yellow-700'
  | 'active:text-yellow-800'
  | 'active:text-yellow-900'
  | 'active:text-green-50'
  | 'active:text-green-100'
  | 'active:text-green-200'
  | 'active:text-green-300'
  | 'active:text-green-400'
  | 'active:text-green-500'
  | 'active:text-green-600'
  | 'active:text-green-700'
  | 'active:text-green-800'
  | 'active:text-green-900'
  | 'active:text-teal-50'
  | 'active:text-teal-100'
  | 'active:text-teal-200'
  | 'active:text-teal-300'
  | 'active:text-teal-400'
  | 'active:text-teal-500'
  | 'active:text-teal-600'
  | 'active:text-teal-700'
  | 'active:text-teal-800'
  | 'active:text-teal-900'
  | 'active:text-blue-50'
  | 'active:text-blue-100'
  | 'active:text-blue-200'
  | 'active:text-blue-300'
  | 'active:text-blue-400'
  | 'active:text-blue-500'
  | 'active:text-blue-600'
  | 'active:text-blue-700'
  | 'active:text-blue-800'
  | 'active:text-blue-900'
  | 'active:text-indigo-50'
  | 'active:text-indigo-100'
  | 'active:text-indigo-200'
  | 'active:text-indigo-300'
  | 'active:text-indigo-400'
  | 'active:text-indigo-500'
  | 'active:text-indigo-600'
  | 'active:text-indigo-700'
  | 'active:text-indigo-800'
  | 'active:text-indigo-900'
  | 'active:text-purple-50'
  | 'active:text-purple-100'
  | 'active:text-purple-200'
  | 'active:text-purple-300'
  | 'active:text-purple-400'
  | 'active:text-purple-500'
  | 'active:text-purple-600'
  | 'active:text-purple-700'
  | 'active:text-purple-800'
  | 'active:text-purple-900'
  | 'active:text-pink-50'
  | 'active:text-pink-100'
  | 'active:text-pink-200'
  | 'active:text-pink-300'
  | 'active:text-pink-400'
  | 'active:text-pink-500'
  | 'active:text-pink-600'
  | 'active:text-pink-700'
  | 'active:text-pink-800'
  | 'active:text-pink-900'
  | 'text-xs'
  | 'text-sm'
  | 'text-base'
  | 'text-lg'
  | 'text-xl'
  | 'text-2xl'
  | 'text-3xl'
  | 'text-4xl'
  | 'text-5xl'
  | 'text-6xl'
  | 'italic'
  | 'not-italic'
  | 'uppercase'
  | 'lowercase'
  | 'capitalize'
  | 'normal-case'
  | 'underline'
  | 'line-through'
  | 'no-underline'
  | 'group-hover:underline'
  | 'group-hover:line-through'
  | 'group-hover:no-underline'
  | 'group-focus:underline'
  | 'group-focus:line-through'
  | 'group-focus:no-underline'
  | 'hover:underline'
  | 'hover:line-through'
  | 'hover:no-underline'
  | 'focus:underline'
  | 'focus:line-through'
  | 'focus:no-underline'
  | 'antialiased'
  | 'subpixel-antialiased'
  | 'tracking-tighter'
  | 'tracking-tight'
  | 'tracking-normal'
  | 'tracking-wide'
  | 'tracking-wider'
  | 'tracking-widest'
  | 'select-none'
  | 'select-text'
  | 'select-all'
  | 'select-auto'
  | 'align-baseline'
  | 'align-top'
  | 'align-middle'
  | 'align-bottom'
  | 'align-text-top'
  | 'align-text-bottom'
  | 'visible'
  | 'invisible'
  | 'whitespace-normal'
  | 'whitespace-no-wrap'
  | 'whitespace-pre'
  | 'whitespace-pre-line'
  | 'whitespace-pre-wrap'
  | 'break-normal'
  | 'break-words'
  | 'break-all'
  | 'truncate'
  | 'w-0'
  | 'w-1'
  | 'w-2'
  | 'w-3'
  | 'w-4'
  | 'w-5'
  | 'w-6'
  | 'w-7'
  | 'w-8'
  | 'w-9'
  | 'w-10'
  | 'w-11'
  | 'w-12'
  | 'w-13'
  | 'w-14'
  | 'w-15'
  | 'w-16'
  | 'w-20'
  | 'w-24'
  | 'w-28'
  | 'w-32'
  | 'w-36'
  | 'w-40'
  | 'w-48'
  | 'w-56'
  | 'w-60'
  | 'w-64'
  | 'w-72'
  | 'w-80'
  | 'w-96'
  | 'w-auto'
  | 'w-px'
  | 'w-0.5'
  | 'w-1.5'
  | 'w-2.5'
  | 'w-3.5'
  | 'w-1/2'
  | 'w-1/3'
  | 'w-2/3'
  | 'w-1/4'
  | 'w-2/4'
  | 'w-3/4'
  | 'w-1/5'
  | 'w-2/5'
  | 'w-3/5'
  | 'w-4/5'
  | 'w-1/6'
  | 'w-2/6'
  | 'w-3/6'
  | 'w-4/6'
  | 'w-5/6'
  | 'w-1/12'
  | 'w-2/12'
  | 'w-3/12'
  | 'w-4/12'
  | 'w-5/12'
  | 'w-6/12'
  | 'w-7/12'
  | 'w-8/12'
  | 'w-9/12'
  | 'w-10/12'
  | 'w-11/12'
  | 'w-full'
  | 'w-screen'
  | 'z-0'
  | 'z-10'
  | 'z-20'
  | 'z-30'
  | 'z-40'
  | 'z-50'
  | 'z-auto'
  | 'focus-within:z-0'
  | 'focus-within:z-10'
  | 'focus-within:z-20'
  | 'focus-within:z-30'
  | 'focus-within:z-40'
  | 'focus-within:z-50'
  | 'focus-within:z-auto'
  | 'focus:z-0'
  | 'focus:z-10'
  | 'focus:z-20'
  | 'focus:z-30'
  | 'focus:z-40'
  | 'focus:z-50'
  | 'focus:z-auto'
  | 'gap-0'
  | 'gap-1'
  | 'gap-2'
  | 'gap-3'
  | 'gap-4'
  | 'gap-5'
  | 'gap-6'
  | 'gap-7'
  | 'gap-8'
  | 'gap-9'
  | 'gap-10'
  | 'gap-11'
  | 'gap-12'
  | 'gap-13'
  | 'gap-14'
  | 'gap-15'
  | 'gap-16'
  | 'gap-20'
  | 'gap-24'
  | 'gap-28'
  | 'gap-32'
  | 'gap-36'
  | 'gap-40'
  | 'gap-48'
  | 'gap-56'
  | 'gap-60'
  | 'gap-64'
  | 'gap-72'
  | 'gap-80'
  | 'gap-96'
  | 'gap-px'
  | 'gap-0.5'
  | 'gap-1.5'
  | 'gap-2.5'
  | 'gap-3.5'
  | 'gap-1/2'
  | 'gap-1/3'
  | 'gap-2/3'
  | 'gap-1/4'
  | 'gap-2/4'
  | 'gap-3/4'
  | 'gap-1/5'
  | 'gap-2/5'
  | 'gap-3/5'
  | 'gap-4/5'
  | 'gap-1/6'
  | 'gap-2/6'
  | 'gap-3/6'
  | 'gap-4/6'
  | 'gap-5/6'
  | 'gap-1/12'
  | 'gap-2/12'
  | 'gap-3/12'
  | 'gap-4/12'
  | 'gap-5/12'
  | 'gap-6/12'
  | 'gap-7/12'
  | 'gap-8/12'
  | 'gap-9/12'
  | 'gap-10/12'
  | 'gap-11/12'
  | 'gap-full'
  | 'col-gap-0'
  | 'col-gap-1'
  | 'col-gap-2'
  | 'col-gap-3'
  | 'col-gap-4'
  | 'col-gap-5'
  | 'col-gap-6'
  | 'col-gap-7'
  | 'col-gap-8'
  | 'col-gap-9'
  | 'col-gap-10'
  | 'col-gap-11'
  | 'col-gap-12'
  | 'col-gap-13'
  | 'col-gap-14'
  | 'col-gap-15'
  | 'col-gap-16'
  | 'col-gap-20'
  | 'col-gap-24'
  | 'col-gap-28'
  | 'col-gap-32'
  | 'col-gap-36'
  | 'col-gap-40'
  | 'col-gap-48'
  | 'col-gap-56'
  | 'col-gap-60'
  | 'col-gap-64'
  | 'col-gap-72'
  | 'col-gap-80'
  | 'col-gap-96'
  | 'col-gap-px'
  | 'col-gap-0.5'
  | 'col-gap-1.5'
  | 'col-gap-2.5'
  | 'col-gap-3.5'
  | 'col-gap-1/2'
  | 'col-gap-1/3'
  | 'col-gap-2/3'
  | 'col-gap-1/4'
  | 'col-gap-2/4'
  | 'col-gap-3/4'
  | 'col-gap-1/5'
  | 'col-gap-2/5'
  | 'col-gap-3/5'
  | 'col-gap-4/5'
  | 'col-gap-1/6'
  | 'col-gap-2/6'
  | 'col-gap-3/6'
  | 'col-gap-4/6'
  | 'col-gap-5/6'
  | 'col-gap-1/12'
  | 'col-gap-2/12'
  | 'col-gap-3/12'
  | 'col-gap-4/12'
  | 'col-gap-5/12'
  | 'col-gap-6/12'
  | 'col-gap-7/12'
  | 'col-gap-8/12'
  | 'col-gap-9/12'
  | 'col-gap-10/12'
  | 'col-gap-11/12'
  | 'col-gap-full'
  | 'row-gap-0'
  | 'row-gap-1'
  | 'row-gap-2'
  | 'row-gap-3'
  | 'row-gap-4'
  | 'row-gap-5'
  | 'row-gap-6'
  | 'row-gap-7'
  | 'row-gap-8'
  | 'row-gap-9'
  | 'row-gap-10'
  | 'row-gap-11'
  | 'row-gap-12'
  | 'row-gap-13'
  | 'row-gap-14'
  | 'row-gap-15'
  | 'row-gap-16'
  | 'row-gap-20'
  | 'row-gap-24'
  | 'row-gap-28'
  | 'row-gap-32'
  | 'row-gap-36'
  | 'row-gap-40'
  | 'row-gap-48'
  | 'row-gap-56'
  | 'row-gap-60'
  | 'row-gap-64'
  | 'row-gap-72'
  | 'row-gap-80'
  | 'row-gap-96'
  | 'row-gap-px'
  | 'row-gap-0.5'
  | 'row-gap-1.5'
  | 'row-gap-2.5'
  | 'row-gap-3.5'
  | 'row-gap-1/2'
  | 'row-gap-1/3'
  | 'row-gap-2/3'
  | 'row-gap-1/4'
  | 'row-gap-2/4'
  | 'row-gap-3/4'
  | 'row-gap-1/5'
  | 'row-gap-2/5'
  | 'row-gap-3/5'
  | 'row-gap-4/5'
  | 'row-gap-1/6'
  | 'row-gap-2/6'
  | 'row-gap-3/6'
  | 'row-gap-4/6'
  | 'row-gap-5/6'
  | 'row-gap-1/12'
  | 'row-gap-2/12'
  | 'row-gap-3/12'
  | 'row-gap-4/12'
  | 'row-gap-5/12'
  | 'row-gap-6/12'
  | 'row-gap-7/12'
  | 'row-gap-8/12'
  | 'row-gap-9/12'
  | 'row-gap-10/12'
  | 'row-gap-11/12'
  | 'row-gap-full'
  | 'grid-flow-row'
  | 'grid-flow-col'
  | 'grid-flow-row-dense'
  | 'grid-flow-col-dense'
  | 'grid-cols-1'
  | 'grid-cols-2'
  | 'grid-cols-3'
  | 'grid-cols-4'
  | 'grid-cols-5'
  | 'grid-cols-6'
  | 'grid-cols-7'
  | 'grid-cols-8'
  | 'grid-cols-9'
  | 'grid-cols-10'
  | 'grid-cols-11'
  | 'grid-cols-12'
  | 'grid-cols-none'
  | 'col-auto'
  | 'col-span-1'
  | 'col-span-2'
  | 'col-span-3'
  | 'col-span-4'
  | 'col-span-5'
  | 'col-span-6'
  | 'col-span-7'
  | 'col-span-8'
  | 'col-span-9'
  | 'col-span-10'
  | 'col-span-11'
  | 'col-span-12'
  | 'col-start-1'
  | 'col-start-2'
  | 'col-start-3'
  | 'col-start-4'
  | 'col-start-5'
  | 'col-start-6'
  | 'col-start-7'
  | 'col-start-8'
  | 'col-start-9'
  | 'col-start-10'
  | 'col-start-11'
  | 'col-start-12'
  | 'col-start-13'
  | 'col-start-auto'
  | 'col-end-1'
  | 'col-end-2'
  | 'col-end-3'
  | 'col-end-4'
  | 'col-end-5'
  | 'col-end-6'
  | 'col-end-7'
  | 'col-end-8'
  | 'col-end-9'
  | 'col-end-10'
  | 'col-end-11'
  | 'col-end-12'
  | 'col-end-13'
  | 'col-end-auto'
  | 'grid-rows-1'
  | 'grid-rows-2'
  | 'grid-rows-3'
  | 'grid-rows-4'
  | 'grid-rows-5'
  | 'grid-rows-6'
  | 'grid-rows-none'
  | 'row-auto'
  | 'row-span-1'
  | 'row-span-2'
  | 'row-span-3'
  | 'row-span-4'
  | 'row-span-5'
  | 'row-span-6'
  | 'row-start-1'
  | 'row-start-2'
  | 'row-start-3'
  | 'row-start-4'
  | 'row-start-5'
  | 'row-start-6'
  | 'row-start-7'
  | 'row-start-auto'
  | 'row-end-1'
  | 'row-end-2'
  | 'row-end-3'
  | 'row-end-4'
  | 'row-end-5'
  | 'row-end-6'
  | 'row-end-7'
  | 'row-end-auto'
  | 'transform'
  | 'transform-none'
  | 'origin-center'
  | 'origin-top'
  | 'origin-top-right'
  | 'origin-right'
  | 'origin-bottom-right'
  | 'origin-bottom'
  | 'origin-bottom-left'
  | 'origin-left'
  | 'origin-top-left'
  | 'scale-0'
  | 'scale-50'
  | 'scale-75'
  | 'scale-90'
  | 'scale-95'
  | 'scale-100'
  | 'scale-105'
  | 'scale-110'
  | 'scale-125'
  | 'scale-150'
  | 'scale-x-0'
  | 'scale-x-50'
  | 'scale-x-75'
  | 'scale-x-90'
  | 'scale-x-95'
  | 'scale-x-100'
  | 'scale-x-105'
  | 'scale-x-110'
  | 'scale-x-125'
  | 'scale-x-150'
  | 'scale-y-0'
  | 'scale-y-50'
  | 'scale-y-75'
  | 'scale-y-90'
  | 'scale-y-95'
  | 'scale-y-100'
  | 'scale-y-105'
  | 'scale-y-110'
  | 'scale-y-125'
  | 'scale-y-150'
  | 'hover:scale-0'
  | 'hover:scale-50'
  | 'hover:scale-75'
  | 'hover:scale-90'
  | 'hover:scale-95'
  | 'hover:scale-100'
  | 'hover:scale-105'
  | 'hover:scale-110'
  | 'hover:scale-125'
  | 'hover:scale-150'
  | 'hover:scale-x-0'
  | 'hover:scale-x-50'
  | 'hover:scale-x-75'
  | 'hover:scale-x-90'
  | 'hover:scale-x-95'
  | 'hover:scale-x-100'
  | 'hover:scale-x-105'
  | 'hover:scale-x-110'
  | 'hover:scale-x-125'
  | 'hover:scale-x-150'
  | 'hover:scale-y-0'
  | 'hover:scale-y-50'
  | 'hover:scale-y-75'
  | 'hover:scale-y-90'
  | 'hover:scale-y-95'
  | 'hover:scale-y-100'
  | 'hover:scale-y-105'
  | 'hover:scale-y-110'
  | 'hover:scale-y-125'
  | 'hover:scale-y-150'
  | 'focus:scale-0'
  | 'focus:scale-50'
  | 'focus:scale-75'
  | 'focus:scale-90'
  | 'focus:scale-95'
  | 'focus:scale-100'
  | 'focus:scale-105'
  | 'focus:scale-110'
  | 'focus:scale-125'
  | 'focus:scale-150'
  | 'focus:scale-x-0'
  | 'focus:scale-x-50'
  | 'focus:scale-x-75'
  | 'focus:scale-x-90'
  | 'focus:scale-x-95'
  | 'focus:scale-x-100'
  | 'focus:scale-x-105'
  | 'focus:scale-x-110'
  | 'focus:scale-x-125'
  | 'focus:scale-x-150'
  | 'focus:scale-y-0'
  | 'focus:scale-y-50'
  | 'focus:scale-y-75'
  | 'focus:scale-y-90'
  | 'focus:scale-y-95'
  | 'focus:scale-y-100'
  | 'focus:scale-y-105'
  | 'focus:scale-y-110'
  | 'focus:scale-y-125'
  | 'focus:scale-y-150'
  | 'rotate-0'
  | 'rotate-45'
  | 'rotate-90'
  | 'rotate-180'
  | '-rotate-180'
  | '-rotate-90'
  | '-rotate-45'
  | 'hover:rotate-0'
  | 'hover:rotate-45'
  | 'hover:rotate-90'
  | 'hover:rotate-180'
  | 'hover:-rotate-180'
  | 'hover:-rotate-90'
  | 'hover:-rotate-45'
  | 'focus:rotate-0'
  | 'focus:rotate-45'
  | 'focus:rotate-90'
  | 'focus:rotate-180'
  | 'focus:-rotate-180'
  | 'focus:-rotate-90'
  | 'focus:-rotate-45'
  | 'translate-x-0'
  | 'translate-x-1'
  | 'translate-x-2'
  | 'translate-x-3'
  | 'translate-x-4'
  | 'translate-x-5'
  | 'translate-x-6'
  | 'translate-x-7'
  | 'translate-x-8'
  | 'translate-x-9'
  | 'translate-x-10'
  | 'translate-x-11'
  | 'translate-x-12'
  | 'translate-x-13'
  | 'translate-x-14'
  | 'translate-x-15'
  | 'translate-x-16'
  | 'translate-x-20'
  | 'translate-x-24'
  | 'translate-x-28'
  | 'translate-x-32'
  | 'translate-x-36'
  | 'translate-x-40'
  | 'translate-x-48'
  | 'translate-x-56'
  | 'translate-x-60'
  | 'translate-x-64'
  | 'translate-x-72'
  | 'translate-x-80'
  | 'translate-x-96'
  | 'translate-x-px'
  | 'translate-x-0.5'
  | 'translate-x-1.5'
  | 'translate-x-2.5'
  | 'translate-x-3.5'
  | 'translate-x-1/2'
  | 'translate-x-1/3'
  | 'translate-x-2/3'
  | 'translate-x-1/4'
  | 'translate-x-2/4'
  | 'translate-x-3/4'
  | 'translate-x-1/5'
  | 'translate-x-2/5'
  | 'translate-x-3/5'
  | 'translate-x-4/5'
  | 'translate-x-1/6'
  | 'translate-x-2/6'
  | 'translate-x-3/6'
  | 'translate-x-4/6'
  | 'translate-x-5/6'
  | 'translate-x-1/12'
  | 'translate-x-2/12'
  | 'translate-x-3/12'
  | 'translate-x-4/12'
  | 'translate-x-5/12'
  | 'translate-x-6/12'
  | 'translate-x-7/12'
  | 'translate-x-8/12'
  | 'translate-x-9/12'
  | 'translate-x-10/12'
  | 'translate-x-11/12'
  | 'translate-x-full'
  | '-translate-x-1'
  | '-translate-x-2'
  | '-translate-x-3'
  | '-translate-x-4'
  | '-translate-x-5'
  | '-translate-x-6'
  | '-translate-x-7'
  | '-translate-x-8'
  | '-translate-x-9'
  | '-translate-x-10'
  | '-translate-x-11'
  | '-translate-x-12'
  | '-translate-x-13'
  | '-translate-x-14'
  | '-translate-x-15'
  | '-translate-x-16'
  | '-translate-x-20'
  | '-translate-x-24'
  | '-translate-x-28'
  | '-translate-x-32'
  | '-translate-x-36'
  | '-translate-x-40'
  | '-translate-x-48'
  | '-translate-x-56'
  | '-translate-x-60'
  | '-translate-x-64'
  | '-translate-x-72'
  | '-translate-x-80'
  | '-translate-x-96'
  | '-translate-x-px'
  | '-translate-x-0.5'
  | '-translate-x-1.5'
  | '-translate-x-2.5'
  | '-translate-x-3.5'
  | '-translate-x-1/2'
  | '-translate-x-1/3'
  | '-translate-x-2/3'
  | '-translate-x-1/4'
  | '-translate-x-2/4'
  | '-translate-x-3/4'
  | '-translate-x-1/5'
  | '-translate-x-2/5'
  | '-translate-x-3/5'
  | '-translate-x-4/5'
  | '-translate-x-1/6'
  | '-translate-x-2/6'
  | '-translate-x-3/6'
  | '-translate-x-4/6'
  | '-translate-x-5/6'
  | '-translate-x-1/12'
  | '-translate-x-2/12'
  | '-translate-x-3/12'
  | '-translate-x-4/12'
  | '-translate-x-5/12'
  | '-translate-x-6/12'
  | '-translate-x-7/12'
  | '-translate-x-8/12'
  | '-translate-x-9/12'
  | '-translate-x-10/12'
  | '-translate-x-11/12'
  | '-translate-x-full'
  | 'translate-y-0'
  | 'translate-y-1'
  | 'translate-y-2'
  | 'translate-y-3'
  | 'translate-y-4'
  | 'translate-y-5'
  | 'translate-y-6'
  | 'translate-y-7'
  | 'translate-y-8'
  | 'translate-y-9'
  | 'translate-y-10'
  | 'translate-y-11'
  | 'translate-y-12'
  | 'translate-y-13'
  | 'translate-y-14'
  | 'translate-y-15'
  | 'translate-y-16'
  | 'translate-y-20'
  | 'translate-y-24'
  | 'translate-y-28'
  | 'translate-y-32'
  | 'translate-y-36'
  | 'translate-y-40'
  | 'translate-y-48'
  | 'translate-y-56'
  | 'translate-y-60'
  | 'translate-y-64'
  | 'translate-y-72'
  | 'translate-y-80'
  | 'translate-y-96'
  | 'translate-y-px'
  | 'translate-y-0.5'
  | 'translate-y-1.5'
  | 'translate-y-2.5'
  | 'translate-y-3.5'
  | 'translate-y-1/2'
  | 'translate-y-1/3'
  | 'translate-y-2/3'
  | 'translate-y-1/4'
  | 'translate-y-2/4'
  | 'translate-y-3/4'
  | 'translate-y-1/5'
  | 'translate-y-2/5'
  | 'translate-y-3/5'
  | 'translate-y-4/5'
  | 'translate-y-1/6'
  | 'translate-y-2/6'
  | 'translate-y-3/6'
  | 'translate-y-4/6'
  | 'translate-y-5/6'
  | 'translate-y-1/12'
  | 'translate-y-2/12'
  | 'translate-y-3/12'
  | 'translate-y-4/12'
  | 'translate-y-5/12'
  | 'translate-y-6/12'
  | 'translate-y-7/12'
  | 'translate-y-8/12'
  | 'translate-y-9/12'
  | 'translate-y-10/12'
  | 'translate-y-11/12'
  | 'translate-y-full'
  | '-translate-y-1'
  | '-translate-y-2'
  | '-translate-y-3'
  | '-translate-y-4'
  | '-translate-y-5'
  | '-translate-y-6'
  | '-translate-y-7'
  | '-translate-y-8'
  | '-translate-y-9'
  | '-translate-y-10'
  | '-translate-y-11'
  | '-translate-y-12'
  | '-translate-y-13'
  | '-translate-y-14'
  | '-translate-y-15'
  | '-translate-y-16'
  | '-translate-y-20'
  | '-translate-y-24'
  | '-translate-y-28'
  | '-translate-y-32'
  | '-translate-y-36'
  | '-translate-y-40'
  | '-translate-y-48'
  | '-translate-y-56'
  | '-translate-y-60'
  | '-translate-y-64'
  | '-translate-y-72'
  | '-translate-y-80'
  | '-translate-y-96'
  | '-translate-y-px'
  | '-translate-y-0.5'
  | '-translate-y-1.5'
  | '-translate-y-2.5'
  | '-translate-y-3.5'
  | '-translate-y-1/2'
  | '-translate-y-1/3'
  | '-translate-y-2/3'
  | '-translate-y-1/4'
  | '-translate-y-2/4'
  | '-translate-y-3/4'
  | '-translate-y-1/5'
  | '-translate-y-2/5'
  | '-translate-y-3/5'
  | '-translate-y-4/5'
  | '-translate-y-1/6'
  | '-translate-y-2/6'
  | '-translate-y-3/6'
  | '-translate-y-4/6'
  | '-translate-y-5/6'
  | '-translate-y-1/12'
  | '-translate-y-2/12'
  | '-translate-y-3/12'
  | '-translate-y-4/12'
  | '-translate-y-5/12'
  | '-translate-y-6/12'
  | '-translate-y-7/12'
  | '-translate-y-8/12'
  | '-translate-y-9/12'
  | '-translate-y-10/12'
  | '-translate-y-11/12'
  | '-translate-y-full'
  | 'hover:translate-x-0'
  | 'hover:translate-x-1'
  | 'hover:translate-x-2'
  | 'hover:translate-x-3'
  | 'hover:translate-x-4'
  | 'hover:translate-x-5'
  | 'hover:translate-x-6'
  | 'hover:translate-x-7'
  | 'hover:translate-x-8'
  | 'hover:translate-x-9'
  | 'hover:translate-x-10'
  | 'hover:translate-x-11'
  | 'hover:translate-x-12'
  | 'hover:translate-x-13'
  | 'hover:translate-x-14'
  | 'hover:translate-x-15'
  | 'hover:translate-x-16'
  | 'hover:translate-x-20'
  | 'hover:translate-x-24'
  | 'hover:translate-x-28'
  | 'hover:translate-x-32'
  | 'hover:translate-x-36'
  | 'hover:translate-x-40'
  | 'hover:translate-x-48'
  | 'hover:translate-x-56'
  | 'hover:translate-x-60'
  | 'hover:translate-x-64'
  | 'hover:translate-x-72'
  | 'hover:translate-x-80'
  | 'hover:translate-x-96'
  | 'hover:translate-x-px'
  | 'hover:translate-x-0.5'
  | 'hover:translate-x-1.5'
  | 'hover:translate-x-2.5'
  | 'hover:translate-x-3.5'
  | 'hover:translate-x-1/2'
  | 'hover:translate-x-1/3'
  | 'hover:translate-x-2/3'
  | 'hover:translate-x-1/4'
  | 'hover:translate-x-2/4'
  | 'hover:translate-x-3/4'
  | 'hover:translate-x-1/5'
  | 'hover:translate-x-2/5'
  | 'hover:translate-x-3/5'
  | 'hover:translate-x-4/5'
  | 'hover:translate-x-1/6'
  | 'hover:translate-x-2/6'
  | 'hover:translate-x-3/6'
  | 'hover:translate-x-4/6'
  | 'hover:translate-x-5/6'
  | 'hover:translate-x-1/12'
  | 'hover:translate-x-2/12'
  | 'hover:translate-x-3/12'
  | 'hover:translate-x-4/12'
  | 'hover:translate-x-5/12'
  | 'hover:translate-x-6/12'
  | 'hover:translate-x-7/12'
  | 'hover:translate-x-8/12'
  | 'hover:translate-x-9/12'
  | 'hover:translate-x-10/12'
  | 'hover:translate-x-11/12'
  | 'hover:translate-x-full'
  | 'hover:-translate-x-1'
  | 'hover:-translate-x-2'
  | 'hover:-translate-x-3'
  | 'hover:-translate-x-4'
  | 'hover:-translate-x-5'
  | 'hover:-translate-x-6'
  | 'hover:-translate-x-7'
  | 'hover:-translate-x-8'
  | 'hover:-translate-x-9'
  | 'hover:-translate-x-10'
  | 'hover:-translate-x-11'
  | 'hover:-translate-x-12'
  | 'hover:-translate-x-13'
  | 'hover:-translate-x-14'
  | 'hover:-translate-x-15'
  | 'hover:-translate-x-16'
  | 'hover:-translate-x-20'
  | 'hover:-translate-x-24'
  | 'hover:-translate-x-28'
  | 'hover:-translate-x-32'
  | 'hover:-translate-x-36'
  | 'hover:-translate-x-40'
  | 'hover:-translate-x-48'
  | 'hover:-translate-x-56'
  | 'hover:-translate-x-60'
  | 'hover:-translate-x-64'
  | 'hover:-translate-x-72'
  | 'hover:-translate-x-80'
  | 'hover:-translate-x-96'
  | 'hover:-translate-x-px'
  | 'hover:-translate-x-0.5'
  | 'hover:-translate-x-1.5'
  | 'hover:-translate-x-2.5'
  | 'hover:-translate-x-3.5'
  | 'hover:-translate-x-1/2'
  | 'hover:-translate-x-1/3'
  | 'hover:-translate-x-2/3'
  | 'hover:-translate-x-1/4'
  | 'hover:-translate-x-2/4'
  | 'hover:-translate-x-3/4'
  | 'hover:-translate-x-1/5'
  | 'hover:-translate-x-2/5'
  | 'hover:-translate-x-3/5'
  | 'hover:-translate-x-4/5'
  | 'hover:-translate-x-1/6'
  | 'hover:-translate-x-2/6'
  | 'hover:-translate-x-3/6'
  | 'hover:-translate-x-4/6'
  | 'hover:-translate-x-5/6'
  | 'hover:-translate-x-1/12'
  | 'hover:-translate-x-2/12'
  | 'hover:-translate-x-3/12'
  | 'hover:-translate-x-4/12'
  | 'hover:-translate-x-5/12'
  | 'hover:-translate-x-6/12'
  | 'hover:-translate-x-7/12'
  | 'hover:-translate-x-8/12'
  | 'hover:-translate-x-9/12'
  | 'hover:-translate-x-10/12'
  | 'hover:-translate-x-11/12'
  | 'hover:-translate-x-full'
  | 'hover:translate-y-0'
  | 'hover:translate-y-1'
  | 'hover:translate-y-2'
  | 'hover:translate-y-3'
  | 'hover:translate-y-4'
  | 'hover:translate-y-5'
  | 'hover:translate-y-6'
  | 'hover:translate-y-7'
  | 'hover:translate-y-8'
  | 'hover:translate-y-9'
  | 'hover:translate-y-10'
  | 'hover:translate-y-11'
  | 'hover:translate-y-12'
  | 'hover:translate-y-13'
  | 'hover:translate-y-14'
  | 'hover:translate-y-15'
  | 'hover:translate-y-16'
  | 'hover:translate-y-20'
  | 'hover:translate-y-24'
  | 'hover:translate-y-28'
  | 'hover:translate-y-32'
  | 'hover:translate-y-36'
  | 'hover:translate-y-40'
  | 'hover:translate-y-48'
  | 'hover:translate-y-56'
  | 'hover:translate-y-60'
  | 'hover:translate-y-64'
  | 'hover:translate-y-72'
  | 'hover:translate-y-80'
  | 'hover:translate-y-96'
  | 'hover:translate-y-px'
  | 'hover:translate-y-0.5'
  | 'hover:translate-y-1.5'
  | 'hover:translate-y-2.5'
  | 'hover:translate-y-3.5'
  | 'hover:translate-y-1/2'
  | 'hover:translate-y-1/3'
  | 'hover:translate-y-2/3'
  | 'hover:translate-y-1/4'
  | 'hover:translate-y-2/4'
  | 'hover:translate-y-3/4'
  | 'hover:translate-y-1/5'
  | 'hover:translate-y-2/5'
  | 'hover:translate-y-3/5'
  | 'hover:translate-y-4/5'
  | 'hover:translate-y-1/6'
  | 'hover:translate-y-2/6'
  | 'hover:translate-y-3/6'
  | 'hover:translate-y-4/6'
  | 'hover:translate-y-5/6'
  | 'hover:translate-y-1/12'
  | 'hover:translate-y-2/12'
  | 'hover:translate-y-3/12'
  | 'hover:translate-y-4/12'
  | 'hover:translate-y-5/12'
  | 'hover:translate-y-6/12'
  | 'hover:translate-y-7/12'
  | 'hover:translate-y-8/12'
  | 'hover:translate-y-9/12'
  | 'hover:translate-y-10/12'
  | 'hover:translate-y-11/12'
  | 'hover:translate-y-full'
  | 'hover:-translate-y-1'
  | 'hover:-translate-y-2'
  | 'hover:-translate-y-3'
  | 'hover:-translate-y-4'
  | 'hover:-translate-y-5'
  | 'hover:-translate-y-6'
  | 'hover:-translate-y-7'
  | 'hover:-translate-y-8'
  | 'hover:-translate-y-9'
  | 'hover:-translate-y-10'
  | 'hover:-translate-y-11'
  | 'hover:-translate-y-12'
  | 'hover:-translate-y-13'
  | 'hover:-translate-y-14'
  | 'hover:-translate-y-15'
  | 'hover:-translate-y-16'
  | 'hover:-translate-y-20'
  | 'hover:-translate-y-24'
  | 'hover:-translate-y-28'
  | 'hover:-translate-y-32'
  | 'hover:-translate-y-36'
  | 'hover:-translate-y-40'
  | 'hover:-translate-y-48'
  | 'hover:-translate-y-56'
  | 'hover:-translate-y-60'
  | 'hover:-translate-y-64'
  | 'hover:-translate-y-72'
  | 'hover:-translate-y-80'
  | 'hover:-translate-y-96'
  | 'hover:-translate-y-px'
  | 'hover:-translate-y-0.5'
  | 'hover:-translate-y-1.5'
  | 'hover:-translate-y-2.5'
  | 'hover:-translate-y-3.5'
  | 'hover:-translate-y-1/2'
  | 'hover:-translate-y-1/3'
  | 'hover:-translate-y-2/3'
  | 'hover:-translate-y-1/4'
  | 'hover:-translate-y-2/4'
  | 'hover:-translate-y-3/4'
  | 'hover:-translate-y-1/5'
  | 'hover:-translate-y-2/5'
  | 'hover:-translate-y-3/5'
  | 'hover:-translate-y-4/5'
  | 'hover:-translate-y-1/6'
  | 'hover:-translate-y-2/6'
  | 'hover:-translate-y-3/6'
  | 'hover:-translate-y-4/6'
  | 'hover:-translate-y-5/6'
  | 'hover:-translate-y-1/12'
  | 'hover:-translate-y-2/12'
  | 'hover:-translate-y-3/12'
  | 'hover:-translate-y-4/12'
  | 'hover:-translate-y-5/12'
  | 'hover:-translate-y-6/12'
  | 'hover:-translate-y-7/12'
  | 'hover:-translate-y-8/12'
  | 'hover:-translate-y-9/12'
  | 'hover:-translate-y-10/12'
  | 'hover:-translate-y-11/12'
  | 'hover:-translate-y-full'
  | 'focus:translate-x-0'
  | 'focus:translate-x-1'
  | 'focus:translate-x-2'
  | 'focus:translate-x-3'
  | 'focus:translate-x-4'
  | 'focus:translate-x-5'
  | 'focus:translate-x-6'
  | 'focus:translate-x-7'
  | 'focus:translate-x-8'
  | 'focus:translate-x-9'
  | 'focus:translate-x-10'
  | 'focus:translate-x-11'
  | 'focus:translate-x-12'
  | 'focus:translate-x-13'
  | 'focus:translate-x-14'
  | 'focus:translate-x-15'
  | 'focus:translate-x-16'
  | 'focus:translate-x-20'
  | 'focus:translate-x-24'
  | 'focus:translate-x-28'
  | 'focus:translate-x-32'
  | 'focus:translate-x-36'
  | 'focus:translate-x-40'
  | 'focus:translate-x-48'
  | 'focus:translate-x-56'
  | 'focus:translate-x-60'
  | 'focus:translate-x-64'
  | 'focus:translate-x-72'
  | 'focus:translate-x-80'
  | 'focus:translate-x-96'
  | 'focus:translate-x-px'
  | 'focus:translate-x-0.5'
  | 'focus:translate-x-1.5'
  | 'focus:translate-x-2.5'
  | 'focus:translate-x-3.5'
  | 'focus:translate-x-1/2'
  | 'focus:translate-x-1/3'
  | 'focus:translate-x-2/3'
  | 'focus:translate-x-1/4'
  | 'focus:translate-x-2/4'
  | 'focus:translate-x-3/4'
  | 'focus:translate-x-1/5'
  | 'focus:translate-x-2/5'
  | 'focus:translate-x-3/5'
  | 'focus:translate-x-4/5'
  | 'focus:translate-x-1/6'
  | 'focus:translate-x-2/6'
  | 'focus:translate-x-3/6'
  | 'focus:translate-x-4/6'
  | 'focus:translate-x-5/6'
  | 'focus:translate-x-1/12'
  | 'focus:translate-x-2/12'
  | 'focus:translate-x-3/12'
  | 'focus:translate-x-4/12'
  | 'focus:translate-x-5/12'
  | 'focus:translate-x-6/12'
  | 'focus:translate-x-7/12'
  | 'focus:translate-x-8/12'
  | 'focus:translate-x-9/12'
  | 'focus:translate-x-10/12'
  | 'focus:translate-x-11/12'
  | 'focus:translate-x-full'
  | 'focus:-translate-x-1'
  | 'focus:-translate-x-2'
  | 'focus:-translate-x-3'
  | 'focus:-translate-x-4'
  | 'focus:-translate-x-5'
  | 'focus:-translate-x-6'
  | 'focus:-translate-x-7'
  | 'focus:-translate-x-8'
  | 'focus:-translate-x-9'
  | 'focus:-translate-x-10'
  | 'focus:-translate-x-11'
  | 'focus:-translate-x-12'
  | 'focus:-translate-x-13'
  | 'focus:-translate-x-14'
  | 'focus:-translate-x-15'
  | 'focus:-translate-x-16'
  | 'focus:-translate-x-20'
  | 'focus:-translate-x-24'
  | 'focus:-translate-x-28'
  | 'focus:-translate-x-32'
  | 'focus:-translate-x-36'
  | 'focus:-translate-x-40'
  | 'focus:-translate-x-48'
  | 'focus:-translate-x-56'
  | 'focus:-translate-x-60'
  | 'focus:-translate-x-64'
  | 'focus:-translate-x-72'
  | 'focus:-translate-x-80'
  | 'focus:-translate-x-96'
  | 'focus:-translate-x-px'
  | 'focus:-translate-x-0.5'
  | 'focus:-translate-x-1.5'
  | 'focus:-translate-x-2.5'
  | 'focus:-translate-x-3.5'
  | 'focus:-translate-x-1/2'
  | 'focus:-translate-x-1/3'
  | 'focus:-translate-x-2/3'
  | 'focus:-translate-x-1/4'
  | 'focus:-translate-x-2/4'
  | 'focus:-translate-x-3/4'
  | 'focus:-translate-x-1/5'
  | 'focus:-translate-x-2/5'
  | 'focus:-translate-x-3/5'
  | 'focus:-translate-x-4/5'
  | 'focus:-translate-x-1/6'
  | 'focus:-translate-x-2/6'
  | 'focus:-translate-x-3/6'
  | 'focus:-translate-x-4/6'
  | 'focus:-translate-x-5/6'
  | 'focus:-translate-x-1/12'
  | 'focus:-translate-x-2/12'
  | 'focus:-translate-x-3/12'
  | 'focus:-translate-x-4/12'
  | 'focus:-translate-x-5/12'
  | 'focus:-translate-x-6/12'
  | 'focus:-translate-x-7/12'
  | 'focus:-translate-x-8/12'
  | 'focus:-translate-x-9/12'
  | 'focus:-translate-x-10/12'
  | 'focus:-translate-x-11/12'
  | 'focus:-translate-x-full'
  | 'focus:translate-y-0'
  | 'focus:translate-y-1'
  | 'focus:translate-y-2'
  | 'focus:translate-y-3'
  | 'focus:translate-y-4'
  | 'focus:translate-y-5'
  | 'focus:translate-y-6'
  | 'focus:translate-y-7'
  | 'focus:translate-y-8'
  | 'focus:translate-y-9'
  | 'focus:translate-y-10'
  | 'focus:translate-y-11'
  | 'focus:translate-y-12'
  | 'focus:translate-y-13'
  | 'focus:translate-y-14'
  | 'focus:translate-y-15'
  | 'focus:translate-y-16'
  | 'focus:translate-y-20'
  | 'focus:translate-y-24'
  | 'focus:translate-y-28'
  | 'focus:translate-y-32'
  | 'focus:translate-y-36'
  | 'focus:translate-y-40'
  | 'focus:translate-y-48'
  | 'focus:translate-y-56'
  | 'focus:translate-y-60'
  | 'focus:translate-y-64'
  | 'focus:translate-y-72'
  | 'focus:translate-y-80'
  | 'focus:translate-y-96'
  | 'focus:translate-y-px'
  | 'focus:translate-y-0.5'
  | 'focus:translate-y-1.5'
  | 'focus:translate-y-2.5'
  | 'focus:translate-y-3.5'
  | 'focus:translate-y-1/2'
  | 'focus:translate-y-1/3'
  | 'focus:translate-y-2/3'
  | 'focus:translate-y-1/4'
  | 'focus:translate-y-2/4'
  | 'focus:translate-y-3/4'
  | 'focus:translate-y-1/5'
  | 'focus:translate-y-2/5'
  | 'focus:translate-y-3/5'
  | 'focus:translate-y-4/5'
  | 'focus:translate-y-1/6'
  | 'focus:translate-y-2/6'
  | 'focus:translate-y-3/6'
  | 'focus:translate-y-4/6'
  | 'focus:translate-y-5/6'
  | 'focus:translate-y-1/12'
  | 'focus:translate-y-2/12'
  | 'focus:translate-y-3/12'
  | 'focus:translate-y-4/12'
  | 'focus:translate-y-5/12'
  | 'focus:translate-y-6/12'
  | 'focus:translate-y-7/12'
  | 'focus:translate-y-8/12'
  | 'focus:translate-y-9/12'
  | 'focus:translate-y-10/12'
  | 'focus:translate-y-11/12'
  | 'focus:translate-y-full'
  | 'focus:-translate-y-1'
  | 'focus:-translate-y-2'
  | 'focus:-translate-y-3'
  | 'focus:-translate-y-4'
  | 'focus:-translate-y-5'
  | 'focus:-translate-y-6'
  | 'focus:-translate-y-7'
  | 'focus:-translate-y-8'
  | 'focus:-translate-y-9'
  | 'focus:-translate-y-10'
  | 'focus:-translate-y-11'
  | 'focus:-translate-y-12'
  | 'focus:-translate-y-13'
  | 'focus:-translate-y-14'
  | 'focus:-translate-y-15'
  | 'focus:-translate-y-16'
  | 'focus:-translate-y-20'
  | 'focus:-translate-y-24'
  | 'focus:-translate-y-28'
  | 'focus:-translate-y-32'
  | 'focus:-translate-y-36'
  | 'focus:-translate-y-40'
  | 'focus:-translate-y-48'
  | 'focus:-translate-y-56'
  | 'focus:-translate-y-60'
  | 'focus:-translate-y-64'
  | 'focus:-translate-y-72'
  | 'focus:-translate-y-80'
  | 'focus:-translate-y-96'
  | 'focus:-translate-y-px'
  | 'focus:-translate-y-0.5'
  | 'focus:-translate-y-1.5'
  | 'focus:-translate-y-2.5'
  | 'focus:-translate-y-3.5'
  | 'focus:-translate-y-1/2'
  | 'focus:-translate-y-1/3'
  | 'focus:-translate-y-2/3'
  | 'focus:-translate-y-1/4'
  | 'focus:-translate-y-2/4'
  | 'focus:-translate-y-3/4'
  | 'focus:-translate-y-1/5'
  | 'focus:-translate-y-2/5'
  | 'focus:-translate-y-3/5'
  | 'focus:-translate-y-4/5'
  | 'focus:-translate-y-1/6'
  | 'focus:-translate-y-2/6'
  | 'focus:-translate-y-3/6'
  | 'focus:-translate-y-4/6'
  | 'focus:-translate-y-5/6'
  | 'focus:-translate-y-1/12'
  | 'focus:-translate-y-2/12'
  | 'focus:-translate-y-3/12'
  | 'focus:-translate-y-4/12'
  | 'focus:-translate-y-5/12'
  | 'focus:-translate-y-6/12'
  | 'focus:-translate-y-7/12'
  | 'focus:-translate-y-8/12'
  | 'focus:-translate-y-9/12'
  | 'focus:-translate-y-10/12'
  | 'focus:-translate-y-11/12'
  | 'focus:-translate-y-full'
  | 'skew-x-0'
  | 'skew-x-3'
  | 'skew-x-6'
  | 'skew-x-12'
  | '-skew-x-12'
  | '-skew-x-6'
  | '-skew-x-3'
  | 'skew-y-0'
  | 'skew-y-3'
  | 'skew-y-6'
  | 'skew-y-12'
  | '-skew-y-12'
  | '-skew-y-6'
  | '-skew-y-3'
  | 'hover:skew-x-0'
  | 'hover:skew-x-3'
  | 'hover:skew-x-6'
  | 'hover:skew-x-12'
  | 'hover:-skew-x-12'
  | 'hover:-skew-x-6'
  | 'hover:-skew-x-3'
  | 'hover:skew-y-0'
  | 'hover:skew-y-3'
  | 'hover:skew-y-6'
  | 'hover:skew-y-12'
  | 'hover:-skew-y-12'
  | 'hover:-skew-y-6'
  | 'hover:-skew-y-3'
  | 'focus:skew-x-0'
  | 'focus:skew-x-3'
  | 'focus:skew-x-6'
  | 'focus:skew-x-12'
  | 'focus:-skew-x-12'
  | 'focus:-skew-x-6'
  | 'focus:-skew-x-3'
  | 'focus:skew-y-0'
  | 'focus:skew-y-3'
  | 'focus:skew-y-6'
  | 'focus:skew-y-12'
  | 'focus:-skew-y-12'
  | 'focus:-skew-y-6'
  | 'focus:-skew-y-3'
  | 'transition-none'
  | 'transition-all'
  | 'transition'
  | 'transition-colors'
  | 'transition-opacity'
  | 'transition-shadow'
  | 'transition-transform'
  | 'ease-linear'
  | 'ease-in'
  | 'ease-out'
  | 'ease-in-out'
  | 'duration-75'
  | 'duration-100'
  | 'duration-150'
  | 'duration-200'
  | 'duration-300'
  | 'duration-500'
  | 'duration-700'
  | 'duration-1000'
  | 'sm:sr-only'
  | 'sm:not-sr-only'
  | 'sm:focus:sr-only'
  | 'sm:focus:not-sr-only'
  | 'sm:appearance-none'
  | 'sm:bg-fixed'
  | 'sm:bg-local'
  | 'sm:bg-scroll'
  | 'sm:bg-transparent'
  | 'sm:bg-white'
  | 'sm:bg-black'
  | 'sm:bg-gray-50'
  | 'sm:bg-gray-100'
  | 'sm:bg-gray-200'
  | 'sm:bg-gray-300'
  | 'sm:bg-gray-400'
  | 'sm:bg-gray-500'
  | 'sm:bg-gray-600'
  | 'sm:bg-gray-700'
  | 'sm:bg-gray-800'
  | 'sm:bg-gray-900'
  | 'sm:bg-cool-gray-50'
  | 'sm:bg-cool-gray-100'
  | 'sm:bg-cool-gray-200'
  | 'sm:bg-cool-gray-300'
  | 'sm:bg-cool-gray-400'
  | 'sm:bg-cool-gray-500'
  | 'sm:bg-cool-gray-600'
  | 'sm:bg-cool-gray-700'
  | 'sm:bg-cool-gray-800'
  | 'sm:bg-cool-gray-900'
  | 'sm:bg-red-50'
  | 'sm:bg-red-100'
  | 'sm:bg-red-200'
  | 'sm:bg-red-300'
  | 'sm:bg-red-400'
  | 'sm:bg-red-500'
  | 'sm:bg-red-600'
  | 'sm:bg-red-700'
  | 'sm:bg-red-800'
  | 'sm:bg-red-900'
  | 'sm:bg-orange-50'
  | 'sm:bg-orange-100'
  | 'sm:bg-orange-200'
  | 'sm:bg-orange-300'
  | 'sm:bg-orange-400'
  | 'sm:bg-orange-500'
  | 'sm:bg-orange-600'
  | 'sm:bg-orange-700'
  | 'sm:bg-orange-800'
  | 'sm:bg-orange-900'
  | 'sm:bg-yellow-50'
  | 'sm:bg-yellow-100'
  | 'sm:bg-yellow-200'
  | 'sm:bg-yellow-300'
  | 'sm:bg-yellow-400'
  | 'sm:bg-yellow-500'
  | 'sm:bg-yellow-600'
  | 'sm:bg-yellow-700'
  | 'sm:bg-yellow-800'
  | 'sm:bg-yellow-900'
  | 'sm:bg-green-50'
  | 'sm:bg-green-100'
  | 'sm:bg-green-200'
  | 'sm:bg-green-300'
  | 'sm:bg-green-400'
  | 'sm:bg-green-500'
  | 'sm:bg-green-600'
  | 'sm:bg-green-700'
  | 'sm:bg-green-800'
  | 'sm:bg-green-900'
  | 'sm:bg-teal-50'
  | 'sm:bg-teal-100'
  | 'sm:bg-teal-200'
  | 'sm:bg-teal-300'
  | 'sm:bg-teal-400'
  | 'sm:bg-teal-500'
  | 'sm:bg-teal-600'
  | 'sm:bg-teal-700'
  | 'sm:bg-teal-800'
  | 'sm:bg-teal-900'
  | 'sm:bg-blue-50'
  | 'sm:bg-blue-100'
  | 'sm:bg-blue-200'
  | 'sm:bg-blue-300'
  | 'sm:bg-blue-400'
  | 'sm:bg-blue-500'
  | 'sm:bg-blue-600'
  | 'sm:bg-blue-700'
  | 'sm:bg-blue-800'
  | 'sm:bg-blue-900'
  | 'sm:bg-indigo-50'
  | 'sm:bg-indigo-100'
  | 'sm:bg-indigo-200'
  | 'sm:bg-indigo-300'
  | 'sm:bg-indigo-400'
  | 'sm:bg-indigo-500'
  | 'sm:bg-indigo-600'
  | 'sm:bg-indigo-700'
  | 'sm:bg-indigo-800'
  | 'sm:bg-indigo-900'
  | 'sm:bg-purple-50'
  | 'sm:bg-purple-100'
  | 'sm:bg-purple-200'
  | 'sm:bg-purple-300'
  | 'sm:bg-purple-400'
  | 'sm:bg-purple-500'
  | 'sm:bg-purple-600'
  | 'sm:bg-purple-700'
  | 'sm:bg-purple-800'
  | 'sm:bg-purple-900'
  | 'sm:bg-pink-50'
  | 'sm:bg-pink-100'
  | 'sm:bg-pink-200'
  | 'sm:bg-pink-300'
  | 'sm:bg-pink-400'
  | 'sm:bg-pink-500'
  | 'sm:bg-pink-600'
  | 'sm:bg-pink-700'
  | 'sm:bg-pink-800'
  | 'sm:bg-pink-900'
  | 'sm:group-hover:bg-transparent'
  | 'sm:group-hover:bg-white'
  | 'sm:group-hover:bg-black'
  | 'sm:group-hover:bg-gray-50'
  | 'sm:group-hover:bg-gray-100'
  | 'sm:group-hover:bg-gray-200'
  | 'sm:group-hover:bg-gray-300'
  | 'sm:group-hover:bg-gray-400'
  | 'sm:group-hover:bg-gray-500'
  | 'sm:group-hover:bg-gray-600'
  | 'sm:group-hover:bg-gray-700'
  | 'sm:group-hover:bg-gray-800'
  | 'sm:group-hover:bg-gray-900'
  | 'sm:group-hover:bg-cool-gray-50'
  | 'sm:group-hover:bg-cool-gray-100'
  | 'sm:group-hover:bg-cool-gray-200'
  | 'sm:group-hover:bg-cool-gray-300'
  | 'sm:group-hover:bg-cool-gray-400'
  | 'sm:group-hover:bg-cool-gray-500'
  | 'sm:group-hover:bg-cool-gray-600'
  | 'sm:group-hover:bg-cool-gray-700'
  | 'sm:group-hover:bg-cool-gray-800'
  | 'sm:group-hover:bg-cool-gray-900'
  | 'sm:group-hover:bg-red-50'
  | 'sm:group-hover:bg-red-100'
  | 'sm:group-hover:bg-red-200'
  | 'sm:group-hover:bg-red-300'
  | 'sm:group-hover:bg-red-400'
  | 'sm:group-hover:bg-red-500'
  | 'sm:group-hover:bg-red-600'
  | 'sm:group-hover:bg-red-700'
  | 'sm:group-hover:bg-red-800'
  | 'sm:group-hover:bg-red-900'
  | 'sm:group-hover:bg-orange-50'
  | 'sm:group-hover:bg-orange-100'
  | 'sm:group-hover:bg-orange-200'
  | 'sm:group-hover:bg-orange-300'
  | 'sm:group-hover:bg-orange-400'
  | 'sm:group-hover:bg-orange-500'
  | 'sm:group-hover:bg-orange-600'
  | 'sm:group-hover:bg-orange-700'
  | 'sm:group-hover:bg-orange-800'
  | 'sm:group-hover:bg-orange-900'
  | 'sm:group-hover:bg-yellow-50'
  | 'sm:group-hover:bg-yellow-100'
  | 'sm:group-hover:bg-yellow-200'
  | 'sm:group-hover:bg-yellow-300'
  | 'sm:group-hover:bg-yellow-400'
  | 'sm:group-hover:bg-yellow-500'
  | 'sm:group-hover:bg-yellow-600'
  | 'sm:group-hover:bg-yellow-700'
  | 'sm:group-hover:bg-yellow-800'
  | 'sm:group-hover:bg-yellow-900'
  | 'sm:group-hover:bg-green-50'
  | 'sm:group-hover:bg-green-100'
  | 'sm:group-hover:bg-green-200'
  | 'sm:group-hover:bg-green-300'
  | 'sm:group-hover:bg-green-400'
  | 'sm:group-hover:bg-green-500'
  | 'sm:group-hover:bg-green-600'
  | 'sm:group-hover:bg-green-700'
  | 'sm:group-hover:bg-green-800'
  | 'sm:group-hover:bg-green-900'
  | 'sm:group-hover:bg-teal-50'
  | 'sm:group-hover:bg-teal-100'
  | 'sm:group-hover:bg-teal-200'
  | 'sm:group-hover:bg-teal-300'
  | 'sm:group-hover:bg-teal-400'
  | 'sm:group-hover:bg-teal-500'
  | 'sm:group-hover:bg-teal-600'
  | 'sm:group-hover:bg-teal-700'
  | 'sm:group-hover:bg-teal-800'
  | 'sm:group-hover:bg-teal-900'
  | 'sm:group-hover:bg-blue-50'
  | 'sm:group-hover:bg-blue-100'
  | 'sm:group-hover:bg-blue-200'
  | 'sm:group-hover:bg-blue-300'
  | 'sm:group-hover:bg-blue-400'
  | 'sm:group-hover:bg-blue-500'
  | 'sm:group-hover:bg-blue-600'
  | 'sm:group-hover:bg-blue-700'
  | 'sm:group-hover:bg-blue-800'
  | 'sm:group-hover:bg-blue-900'
  | 'sm:group-hover:bg-indigo-50'
  | 'sm:group-hover:bg-indigo-100'
  | 'sm:group-hover:bg-indigo-200'
  | 'sm:group-hover:bg-indigo-300'
  | 'sm:group-hover:bg-indigo-400'
  | 'sm:group-hover:bg-indigo-500'
  | 'sm:group-hover:bg-indigo-600'
  | 'sm:group-hover:bg-indigo-700'
  | 'sm:group-hover:bg-indigo-800'
  | 'sm:group-hover:bg-indigo-900'
  | 'sm:group-hover:bg-purple-50'
  | 'sm:group-hover:bg-purple-100'
  | 'sm:group-hover:bg-purple-200'
  | 'sm:group-hover:bg-purple-300'
  | 'sm:group-hover:bg-purple-400'
  | 'sm:group-hover:bg-purple-500'
  | 'sm:group-hover:bg-purple-600'
  | 'sm:group-hover:bg-purple-700'
  | 'sm:group-hover:bg-purple-800'
  | 'sm:group-hover:bg-purple-900'
  | 'sm:group-hover:bg-pink-50'
  | 'sm:group-hover:bg-pink-100'
  | 'sm:group-hover:bg-pink-200'
  | 'sm:group-hover:bg-pink-300'
  | 'sm:group-hover:bg-pink-400'
  | 'sm:group-hover:bg-pink-500'
  | 'sm:group-hover:bg-pink-600'
  | 'sm:group-hover:bg-pink-700'
  | 'sm:group-hover:bg-pink-800'
  | 'sm:group-hover:bg-pink-900'
  | 'sm:group-focus:bg-transparent'
  | 'sm:group-focus:bg-white'
  | 'sm:group-focus:bg-black'
  | 'sm:group-focus:bg-gray-50'
  | 'sm:group-focus:bg-gray-100'
  | 'sm:group-focus:bg-gray-200'
  | 'sm:group-focus:bg-gray-300'
  | 'sm:group-focus:bg-gray-400'
  | 'sm:group-focus:bg-gray-500'
  | 'sm:group-focus:bg-gray-600'
  | 'sm:group-focus:bg-gray-700'
  | 'sm:group-focus:bg-gray-800'
  | 'sm:group-focus:bg-gray-900'
  | 'sm:group-focus:bg-cool-gray-50'
  | 'sm:group-focus:bg-cool-gray-100'
  | 'sm:group-focus:bg-cool-gray-200'
  | 'sm:group-focus:bg-cool-gray-300'
  | 'sm:group-focus:bg-cool-gray-400'
  | 'sm:group-focus:bg-cool-gray-500'
  | 'sm:group-focus:bg-cool-gray-600'
  | 'sm:group-focus:bg-cool-gray-700'
  | 'sm:group-focus:bg-cool-gray-800'
  | 'sm:group-focus:bg-cool-gray-900'
  | 'sm:group-focus:bg-red-50'
  | 'sm:group-focus:bg-red-100'
  | 'sm:group-focus:bg-red-200'
  | 'sm:group-focus:bg-red-300'
  | 'sm:group-focus:bg-red-400'
  | 'sm:group-focus:bg-red-500'
  | 'sm:group-focus:bg-red-600'
  | 'sm:group-focus:bg-red-700'
  | 'sm:group-focus:bg-red-800'
  | 'sm:group-focus:bg-red-900'
  | 'sm:group-focus:bg-orange-50'
  | 'sm:group-focus:bg-orange-100'
  | 'sm:group-focus:bg-orange-200'
  | 'sm:group-focus:bg-orange-300'
  | 'sm:group-focus:bg-orange-400'
  | 'sm:group-focus:bg-orange-500'
  | 'sm:group-focus:bg-orange-600'
  | 'sm:group-focus:bg-orange-700'
  | 'sm:group-focus:bg-orange-800'
  | 'sm:group-focus:bg-orange-900'
  | 'sm:group-focus:bg-yellow-50'
  | 'sm:group-focus:bg-yellow-100'
  | 'sm:group-focus:bg-yellow-200'
  | 'sm:group-focus:bg-yellow-300'
  | 'sm:group-focus:bg-yellow-400'
  | 'sm:group-focus:bg-yellow-500'
  | 'sm:group-focus:bg-yellow-600'
  | 'sm:group-focus:bg-yellow-700'
  | 'sm:group-focus:bg-yellow-800'
  | 'sm:group-focus:bg-yellow-900'
  | 'sm:group-focus:bg-green-50'
  | 'sm:group-focus:bg-green-100'
  | 'sm:group-focus:bg-green-200'
  | 'sm:group-focus:bg-green-300'
  | 'sm:group-focus:bg-green-400'
  | 'sm:group-focus:bg-green-500'
  | 'sm:group-focus:bg-green-600'
  | 'sm:group-focus:bg-green-700'
  | 'sm:group-focus:bg-green-800'
  | 'sm:group-focus:bg-green-900'
  | 'sm:group-focus:bg-teal-50'
  | 'sm:group-focus:bg-teal-100'
  | 'sm:group-focus:bg-teal-200'
  | 'sm:group-focus:bg-teal-300'
  | 'sm:group-focus:bg-teal-400'
  | 'sm:group-focus:bg-teal-500'
  | 'sm:group-focus:bg-teal-600'
  | 'sm:group-focus:bg-teal-700'
  | 'sm:group-focus:bg-teal-800'
  | 'sm:group-focus:bg-teal-900'
  | 'sm:group-focus:bg-blue-50'
  | 'sm:group-focus:bg-blue-100'
  | 'sm:group-focus:bg-blue-200'
  | 'sm:group-focus:bg-blue-300'
  | 'sm:group-focus:bg-blue-400'
  | 'sm:group-focus:bg-blue-500'
  | 'sm:group-focus:bg-blue-600'
  | 'sm:group-focus:bg-blue-700'
  | 'sm:group-focus:bg-blue-800'
  | 'sm:group-focus:bg-blue-900'
  | 'sm:group-focus:bg-indigo-50'
  | 'sm:group-focus:bg-indigo-100'
  | 'sm:group-focus:bg-indigo-200'
  | 'sm:group-focus:bg-indigo-300'
  | 'sm:group-focus:bg-indigo-400'
  | 'sm:group-focus:bg-indigo-500'
  | 'sm:group-focus:bg-indigo-600'
  | 'sm:group-focus:bg-indigo-700'
  | 'sm:group-focus:bg-indigo-800'
  | 'sm:group-focus:bg-indigo-900'
  | 'sm:group-focus:bg-purple-50'
  | 'sm:group-focus:bg-purple-100'
  | 'sm:group-focus:bg-purple-200'
  | 'sm:group-focus:bg-purple-300'
  | 'sm:group-focus:bg-purple-400'
  | 'sm:group-focus:bg-purple-500'
  | 'sm:group-focus:bg-purple-600'
  | 'sm:group-focus:bg-purple-700'
  | 'sm:group-focus:bg-purple-800'
  | 'sm:group-focus:bg-purple-900'
  | 'sm:group-focus:bg-pink-50'
  | 'sm:group-focus:bg-pink-100'
  | 'sm:group-focus:bg-pink-200'
  | 'sm:group-focus:bg-pink-300'
  | 'sm:group-focus:bg-pink-400'
  | 'sm:group-focus:bg-pink-500'
  | 'sm:group-focus:bg-pink-600'
  | 'sm:group-focus:bg-pink-700'
  | 'sm:group-focus:bg-pink-800'
  | 'sm:group-focus:bg-pink-900'
  | 'sm:hover:bg-transparent'
  | 'sm:hover:bg-white'
  | 'sm:hover:bg-black'
  | 'sm:hover:bg-gray-50'
  | 'sm:hover:bg-gray-100'
  | 'sm:hover:bg-gray-200'
  | 'sm:hover:bg-gray-300'
  | 'sm:hover:bg-gray-400'
  | 'sm:hover:bg-gray-500'
  | 'sm:hover:bg-gray-600'
  | 'sm:hover:bg-gray-700'
  | 'sm:hover:bg-gray-800'
  | 'sm:hover:bg-gray-900'
  | 'sm:hover:bg-cool-gray-50'
  | 'sm:hover:bg-cool-gray-100'
  | 'sm:hover:bg-cool-gray-200'
  | 'sm:hover:bg-cool-gray-300'
  | 'sm:hover:bg-cool-gray-400'
  | 'sm:hover:bg-cool-gray-500'
  | 'sm:hover:bg-cool-gray-600'
  | 'sm:hover:bg-cool-gray-700'
  | 'sm:hover:bg-cool-gray-800'
  | 'sm:hover:bg-cool-gray-900'
  | 'sm:hover:bg-red-50'
  | 'sm:hover:bg-red-100'
  | 'sm:hover:bg-red-200'
  | 'sm:hover:bg-red-300'
  | 'sm:hover:bg-red-400'
  | 'sm:hover:bg-red-500'
  | 'sm:hover:bg-red-600'
  | 'sm:hover:bg-red-700'
  | 'sm:hover:bg-red-800'
  | 'sm:hover:bg-red-900'
  | 'sm:hover:bg-orange-50'
  | 'sm:hover:bg-orange-100'
  | 'sm:hover:bg-orange-200'
  | 'sm:hover:bg-orange-300'
  | 'sm:hover:bg-orange-400'
  | 'sm:hover:bg-orange-500'
  | 'sm:hover:bg-orange-600'
  | 'sm:hover:bg-orange-700'
  | 'sm:hover:bg-orange-800'
  | 'sm:hover:bg-orange-900'
  | 'sm:hover:bg-yellow-50'
  | 'sm:hover:bg-yellow-100'
  | 'sm:hover:bg-yellow-200'
  | 'sm:hover:bg-yellow-300'
  | 'sm:hover:bg-yellow-400'
  | 'sm:hover:bg-yellow-500'
  | 'sm:hover:bg-yellow-600'
  | 'sm:hover:bg-yellow-700'
  | 'sm:hover:bg-yellow-800'
  | 'sm:hover:bg-yellow-900'
  | 'sm:hover:bg-green-50'
  | 'sm:hover:bg-green-100'
  | 'sm:hover:bg-green-200'
  | 'sm:hover:bg-green-300'
  | 'sm:hover:bg-green-400'
  | 'sm:hover:bg-green-500'
  | 'sm:hover:bg-green-600'
  | 'sm:hover:bg-green-700'
  | 'sm:hover:bg-green-800'
  | 'sm:hover:bg-green-900'
  | 'sm:hover:bg-teal-50'
  | 'sm:hover:bg-teal-100'
  | 'sm:hover:bg-teal-200'
  | 'sm:hover:bg-teal-300'
  | 'sm:hover:bg-teal-400'
  | 'sm:hover:bg-teal-500'
  | 'sm:hover:bg-teal-600'
  | 'sm:hover:bg-teal-700'
  | 'sm:hover:bg-teal-800'
  | 'sm:hover:bg-teal-900'
  | 'sm:hover:bg-blue-50'
  | 'sm:hover:bg-blue-100'
  | 'sm:hover:bg-blue-200'
  | 'sm:hover:bg-blue-300'
  | 'sm:hover:bg-blue-400'
  | 'sm:hover:bg-blue-500'
  | 'sm:hover:bg-blue-600'
  | 'sm:hover:bg-blue-700'
  | 'sm:hover:bg-blue-800'
  | 'sm:hover:bg-blue-900'
  | 'sm:hover:bg-indigo-50'
  | 'sm:hover:bg-indigo-100'
  | 'sm:hover:bg-indigo-200'
  | 'sm:hover:bg-indigo-300'
  | 'sm:hover:bg-indigo-400'
  | 'sm:hover:bg-indigo-500'
  | 'sm:hover:bg-indigo-600'
  | 'sm:hover:bg-indigo-700'
  | 'sm:hover:bg-indigo-800'
  | 'sm:hover:bg-indigo-900'
  | 'sm:hover:bg-purple-50'
  | 'sm:hover:bg-purple-100'
  | 'sm:hover:bg-purple-200'
  | 'sm:hover:bg-purple-300'
  | 'sm:hover:bg-purple-400'
  | 'sm:hover:bg-purple-500'
  | 'sm:hover:bg-purple-600'
  | 'sm:hover:bg-purple-700'
  | 'sm:hover:bg-purple-800'
  | 'sm:hover:bg-purple-900'
  | 'sm:hover:bg-pink-50'
  | 'sm:hover:bg-pink-100'
  | 'sm:hover:bg-pink-200'
  | 'sm:hover:bg-pink-300'
  | 'sm:hover:bg-pink-400'
  | 'sm:hover:bg-pink-500'
  | 'sm:hover:bg-pink-600'
  | 'sm:hover:bg-pink-700'
  | 'sm:hover:bg-pink-800'
  | 'sm:hover:bg-pink-900'
  | 'sm:focus:bg-transparent'
  | 'sm:focus:bg-white'
  | 'sm:focus:bg-black'
  | 'sm:focus:bg-gray-50'
  | 'sm:focus:bg-gray-100'
  | 'sm:focus:bg-gray-200'
  | 'sm:focus:bg-gray-300'
  | 'sm:focus:bg-gray-400'
  | 'sm:focus:bg-gray-500'
  | 'sm:focus:bg-gray-600'
  | 'sm:focus:bg-gray-700'
  | 'sm:focus:bg-gray-800'
  | 'sm:focus:bg-gray-900'
  | 'sm:focus:bg-cool-gray-50'
  | 'sm:focus:bg-cool-gray-100'
  | 'sm:focus:bg-cool-gray-200'
  | 'sm:focus:bg-cool-gray-300'
  | 'sm:focus:bg-cool-gray-400'
  | 'sm:focus:bg-cool-gray-500'
  | 'sm:focus:bg-cool-gray-600'
  | 'sm:focus:bg-cool-gray-700'
  | 'sm:focus:bg-cool-gray-800'
  | 'sm:focus:bg-cool-gray-900'
  | 'sm:focus:bg-red-50'
  | 'sm:focus:bg-red-100'
  | 'sm:focus:bg-red-200'
  | 'sm:focus:bg-red-300'
  | 'sm:focus:bg-red-400'
  | 'sm:focus:bg-red-500'
  | 'sm:focus:bg-red-600'
  | 'sm:focus:bg-red-700'
  | 'sm:focus:bg-red-800'
  | 'sm:focus:bg-red-900'
  | 'sm:focus:bg-orange-50'
  | 'sm:focus:bg-orange-100'
  | 'sm:focus:bg-orange-200'
  | 'sm:focus:bg-orange-300'
  | 'sm:focus:bg-orange-400'
  | 'sm:focus:bg-orange-500'
  | 'sm:focus:bg-orange-600'
  | 'sm:focus:bg-orange-700'
  | 'sm:focus:bg-orange-800'
  | 'sm:focus:bg-orange-900'
  | 'sm:focus:bg-yellow-50'
  | 'sm:focus:bg-yellow-100'
  | 'sm:focus:bg-yellow-200'
  | 'sm:focus:bg-yellow-300'
  | 'sm:focus:bg-yellow-400'
  | 'sm:focus:bg-yellow-500'
  | 'sm:focus:bg-yellow-600'
  | 'sm:focus:bg-yellow-700'
  | 'sm:focus:bg-yellow-800'
  | 'sm:focus:bg-yellow-900'
  | 'sm:focus:bg-green-50'
  | 'sm:focus:bg-green-100'
  | 'sm:focus:bg-green-200'
  | 'sm:focus:bg-green-300'
  | 'sm:focus:bg-green-400'
  | 'sm:focus:bg-green-500'
  | 'sm:focus:bg-green-600'
  | 'sm:focus:bg-green-700'
  | 'sm:focus:bg-green-800'
  | 'sm:focus:bg-green-900'
  | 'sm:focus:bg-teal-50'
  | 'sm:focus:bg-teal-100'
  | 'sm:focus:bg-teal-200'
  | 'sm:focus:bg-teal-300'
  | 'sm:focus:bg-teal-400'
  | 'sm:focus:bg-teal-500'
  | 'sm:focus:bg-teal-600'
  | 'sm:focus:bg-teal-700'
  | 'sm:focus:bg-teal-800'
  | 'sm:focus:bg-teal-900'
  | 'sm:focus:bg-blue-50'
  | 'sm:focus:bg-blue-100'
  | 'sm:focus:bg-blue-200'
  | 'sm:focus:bg-blue-300'
  | 'sm:focus:bg-blue-400'
  | 'sm:focus:bg-blue-500'
  | 'sm:focus:bg-blue-600'
  | 'sm:focus:bg-blue-700'
  | 'sm:focus:bg-blue-800'
  | 'sm:focus:bg-blue-900'
  | 'sm:focus:bg-indigo-50'
  | 'sm:focus:bg-indigo-100'
  | 'sm:focus:bg-indigo-200'
  | 'sm:focus:bg-indigo-300'
  | 'sm:focus:bg-indigo-400'
  | 'sm:focus:bg-indigo-500'
  | 'sm:focus:bg-indigo-600'
  | 'sm:focus:bg-indigo-700'
  | 'sm:focus:bg-indigo-800'
  | 'sm:focus:bg-indigo-900'
  | 'sm:focus:bg-purple-50'
  | 'sm:focus:bg-purple-100'
  | 'sm:focus:bg-purple-200'
  | 'sm:focus:bg-purple-300'
  | 'sm:focus:bg-purple-400'
  | 'sm:focus:bg-purple-500'
  | 'sm:focus:bg-purple-600'
  | 'sm:focus:bg-purple-700'
  | 'sm:focus:bg-purple-800'
  | 'sm:focus:bg-purple-900'
  | 'sm:focus:bg-pink-50'
  | 'sm:focus:bg-pink-100'
  | 'sm:focus:bg-pink-200'
  | 'sm:focus:bg-pink-300'
  | 'sm:focus:bg-pink-400'
  | 'sm:focus:bg-pink-500'
  | 'sm:focus:bg-pink-600'
  | 'sm:focus:bg-pink-700'
  | 'sm:focus:bg-pink-800'
  | 'sm:focus:bg-pink-900'
  | 'sm:active:bg-transparent'
  | 'sm:active:bg-white'
  | 'sm:active:bg-black'
  | 'sm:active:bg-gray-50'
  | 'sm:active:bg-gray-100'
  | 'sm:active:bg-gray-200'
  | 'sm:active:bg-gray-300'
  | 'sm:active:bg-gray-400'
  | 'sm:active:bg-gray-500'
  | 'sm:active:bg-gray-600'
  | 'sm:active:bg-gray-700'
  | 'sm:active:bg-gray-800'
  | 'sm:active:bg-gray-900'
  | 'sm:active:bg-cool-gray-50'
  | 'sm:active:bg-cool-gray-100'
  | 'sm:active:bg-cool-gray-200'
  | 'sm:active:bg-cool-gray-300'
  | 'sm:active:bg-cool-gray-400'
  | 'sm:active:bg-cool-gray-500'
  | 'sm:active:bg-cool-gray-600'
  | 'sm:active:bg-cool-gray-700'
  | 'sm:active:bg-cool-gray-800'
  | 'sm:active:bg-cool-gray-900'
  | 'sm:active:bg-red-50'
  | 'sm:active:bg-red-100'
  | 'sm:active:bg-red-200'
  | 'sm:active:bg-red-300'
  | 'sm:active:bg-red-400'
  | 'sm:active:bg-red-500'
  | 'sm:active:bg-red-600'
  | 'sm:active:bg-red-700'
  | 'sm:active:bg-red-800'
  | 'sm:active:bg-red-900'
  | 'sm:active:bg-orange-50'
  | 'sm:active:bg-orange-100'
  | 'sm:active:bg-orange-200'
  | 'sm:active:bg-orange-300'
  | 'sm:active:bg-orange-400'
  | 'sm:active:bg-orange-500'
  | 'sm:active:bg-orange-600'
  | 'sm:active:bg-orange-700'
  | 'sm:active:bg-orange-800'
  | 'sm:active:bg-orange-900'
  | 'sm:active:bg-yellow-50'
  | 'sm:active:bg-yellow-100'
  | 'sm:active:bg-yellow-200'
  | 'sm:active:bg-yellow-300'
  | 'sm:active:bg-yellow-400'
  | 'sm:active:bg-yellow-500'
  | 'sm:active:bg-yellow-600'
  | 'sm:active:bg-yellow-700'
  | 'sm:active:bg-yellow-800'
  | 'sm:active:bg-yellow-900'
  | 'sm:active:bg-green-50'
  | 'sm:active:bg-green-100'
  | 'sm:active:bg-green-200'
  | 'sm:active:bg-green-300'
  | 'sm:active:bg-green-400'
  | 'sm:active:bg-green-500'
  | 'sm:active:bg-green-600'
  | 'sm:active:bg-green-700'
  | 'sm:active:bg-green-800'
  | 'sm:active:bg-green-900'
  | 'sm:active:bg-teal-50'
  | 'sm:active:bg-teal-100'
  | 'sm:active:bg-teal-200'
  | 'sm:active:bg-teal-300'
  | 'sm:active:bg-teal-400'
  | 'sm:active:bg-teal-500'
  | 'sm:active:bg-teal-600'
  | 'sm:active:bg-teal-700'
  | 'sm:active:bg-teal-800'
  | 'sm:active:bg-teal-900'
  | 'sm:active:bg-blue-50'
  | 'sm:active:bg-blue-100'
  | 'sm:active:bg-blue-200'
  | 'sm:active:bg-blue-300'
  | 'sm:active:bg-blue-400'
  | 'sm:active:bg-blue-500'
  | 'sm:active:bg-blue-600'
  | 'sm:active:bg-blue-700'
  | 'sm:active:bg-blue-800'
  | 'sm:active:bg-blue-900'
  | 'sm:active:bg-indigo-50'
  | 'sm:active:bg-indigo-100'
  | 'sm:active:bg-indigo-200'
  | 'sm:active:bg-indigo-300'
  | 'sm:active:bg-indigo-400'
  | 'sm:active:bg-indigo-500'
  | 'sm:active:bg-indigo-600'
  | 'sm:active:bg-indigo-700'
  | 'sm:active:bg-indigo-800'
  | 'sm:active:bg-indigo-900'
  | 'sm:active:bg-purple-50'
  | 'sm:active:bg-purple-100'
  | 'sm:active:bg-purple-200'
  | 'sm:active:bg-purple-300'
  | 'sm:active:bg-purple-400'
  | 'sm:active:bg-purple-500'
  | 'sm:active:bg-purple-600'
  | 'sm:active:bg-purple-700'
  | 'sm:active:bg-purple-800'
  | 'sm:active:bg-purple-900'
  | 'sm:active:bg-pink-50'
  | 'sm:active:bg-pink-100'
  | 'sm:active:bg-pink-200'
  | 'sm:active:bg-pink-300'
  | 'sm:active:bg-pink-400'
  | 'sm:active:bg-pink-500'
  | 'sm:active:bg-pink-600'
  | 'sm:active:bg-pink-700'
  | 'sm:active:bg-pink-800'
  | 'sm:active:bg-pink-900'
  | 'sm:bg-bottom'
  | 'sm:bg-center'
  | 'sm:bg-left'
  | 'sm:bg-left-bottom'
  | 'sm:bg-left-top'
  | 'sm:bg-right'
  | 'sm:bg-right-bottom'
  | 'sm:bg-right-top'
  | 'sm:bg-top'
  | 'sm:bg-repeat'
  | 'sm:bg-no-repeat'
  | 'sm:bg-repeat-x'
  | 'sm:bg-repeat-y'
  | 'sm:bg-repeat-round'
  | 'sm:bg-repeat-space'
  | 'sm:bg-auto'
  | 'sm:bg-cover'
  | 'sm:bg-contain'
  | 'sm:border-collapse'
  | 'sm:border-separate'
  | 'sm:border-transparent'
  | 'sm:border-white'
  | 'sm:border-black'
  | 'sm:border-gray-50'
  | 'sm:border-gray-100'
  | 'sm:border-gray-200'
  | 'sm:border-gray-300'
  | 'sm:border-gray-400'
  | 'sm:border-gray-500'
  | 'sm:border-gray-600'
  | 'sm:border-gray-700'
  | 'sm:border-gray-800'
  | 'sm:border-gray-900'
  | 'sm:border-cool-gray-50'
  | 'sm:border-cool-gray-100'
  | 'sm:border-cool-gray-200'
  | 'sm:border-cool-gray-300'
  | 'sm:border-cool-gray-400'
  | 'sm:border-cool-gray-500'
  | 'sm:border-cool-gray-600'
  | 'sm:border-cool-gray-700'
  | 'sm:border-cool-gray-800'
  | 'sm:border-cool-gray-900'
  | 'sm:border-red-50'
  | 'sm:border-red-100'
  | 'sm:border-red-200'
  | 'sm:border-red-300'
  | 'sm:border-red-400'
  | 'sm:border-red-500'
  | 'sm:border-red-600'
  | 'sm:border-red-700'
  | 'sm:border-red-800'
  | 'sm:border-red-900'
  | 'sm:border-orange-50'
  | 'sm:border-orange-100'
  | 'sm:border-orange-200'
  | 'sm:border-orange-300'
  | 'sm:border-orange-400'
  | 'sm:border-orange-500'
  | 'sm:border-orange-600'
  | 'sm:border-orange-700'
  | 'sm:border-orange-800'
  | 'sm:border-orange-900'
  | 'sm:border-yellow-50'
  | 'sm:border-yellow-100'
  | 'sm:border-yellow-200'
  | 'sm:border-yellow-300'
  | 'sm:border-yellow-400'
  | 'sm:border-yellow-500'
  | 'sm:border-yellow-600'
  | 'sm:border-yellow-700'
  | 'sm:border-yellow-800'
  | 'sm:border-yellow-900'
  | 'sm:border-green-50'
  | 'sm:border-green-100'
  | 'sm:border-green-200'
  | 'sm:border-green-300'
  | 'sm:border-green-400'
  | 'sm:border-green-500'
  | 'sm:border-green-600'
  | 'sm:border-green-700'
  | 'sm:border-green-800'
  | 'sm:border-green-900'
  | 'sm:border-teal-50'
  | 'sm:border-teal-100'
  | 'sm:border-teal-200'
  | 'sm:border-teal-300'
  | 'sm:border-teal-400'
  | 'sm:border-teal-500'
  | 'sm:border-teal-600'
  | 'sm:border-teal-700'
  | 'sm:border-teal-800'
  | 'sm:border-teal-900'
  | 'sm:border-blue-50'
  | 'sm:border-blue-100'
  | 'sm:border-blue-200'
  | 'sm:border-blue-300'
  | 'sm:border-blue-400'
  | 'sm:border-blue-500'
  | 'sm:border-blue-600'
  | 'sm:border-blue-700'
  | 'sm:border-blue-800'
  | 'sm:border-blue-900'
  | 'sm:border-indigo-50'
  | 'sm:border-indigo-100'
  | 'sm:border-indigo-200'
  | 'sm:border-indigo-300'
  | 'sm:border-indigo-400'
  | 'sm:border-indigo-500'
  | 'sm:border-indigo-600'
  | 'sm:border-indigo-700'
  | 'sm:border-indigo-800'
  | 'sm:border-indigo-900'
  | 'sm:border-purple-50'
  | 'sm:border-purple-100'
  | 'sm:border-purple-200'
  | 'sm:border-purple-300'
  | 'sm:border-purple-400'
  | 'sm:border-purple-500'
  | 'sm:border-purple-600'
  | 'sm:border-purple-700'
  | 'sm:border-purple-800'
  | 'sm:border-purple-900'
  | 'sm:border-pink-50'
  | 'sm:border-pink-100'
  | 'sm:border-pink-200'
  | 'sm:border-pink-300'
  | 'sm:border-pink-400'
  | 'sm:border-pink-500'
  | 'sm:border-pink-600'
  | 'sm:border-pink-700'
  | 'sm:border-pink-800'
  | 'sm:border-pink-900'
  | 'sm:group-hover:border-transparent'
  | 'sm:group-hover:border-white'
  | 'sm:group-hover:border-black'
  | 'sm:group-hover:border-gray-50'
  | 'sm:group-hover:border-gray-100'
  | 'sm:group-hover:border-gray-200'
  | 'sm:group-hover:border-gray-300'
  | 'sm:group-hover:border-gray-400'
  | 'sm:group-hover:border-gray-500'
  | 'sm:group-hover:border-gray-600'
  | 'sm:group-hover:border-gray-700'
  | 'sm:group-hover:border-gray-800'
  | 'sm:group-hover:border-gray-900'
  | 'sm:group-hover:border-cool-gray-50'
  | 'sm:group-hover:border-cool-gray-100'
  | 'sm:group-hover:border-cool-gray-200'
  | 'sm:group-hover:border-cool-gray-300'
  | 'sm:group-hover:border-cool-gray-400'
  | 'sm:group-hover:border-cool-gray-500'
  | 'sm:group-hover:border-cool-gray-600'
  | 'sm:group-hover:border-cool-gray-700'
  | 'sm:group-hover:border-cool-gray-800'
  | 'sm:group-hover:border-cool-gray-900'
  | 'sm:group-hover:border-red-50'
  | 'sm:group-hover:border-red-100'
  | 'sm:group-hover:border-red-200'
  | 'sm:group-hover:border-red-300'
  | 'sm:group-hover:border-red-400'
  | 'sm:group-hover:border-red-500'
  | 'sm:group-hover:border-red-600'
  | 'sm:group-hover:border-red-700'
  | 'sm:group-hover:border-red-800'
  | 'sm:group-hover:border-red-900'
  | 'sm:group-hover:border-orange-50'
  | 'sm:group-hover:border-orange-100'
  | 'sm:group-hover:border-orange-200'
  | 'sm:group-hover:border-orange-300'
  | 'sm:group-hover:border-orange-400'
  | 'sm:group-hover:border-orange-500'
  | 'sm:group-hover:border-orange-600'
  | 'sm:group-hover:border-orange-700'
  | 'sm:group-hover:border-orange-800'
  | 'sm:group-hover:border-orange-900'
  | 'sm:group-hover:border-yellow-50'
  | 'sm:group-hover:border-yellow-100'
  | 'sm:group-hover:border-yellow-200'
  | 'sm:group-hover:border-yellow-300'
  | 'sm:group-hover:border-yellow-400'
  | 'sm:group-hover:border-yellow-500'
  | 'sm:group-hover:border-yellow-600'
  | 'sm:group-hover:border-yellow-700'
  | 'sm:group-hover:border-yellow-800'
  | 'sm:group-hover:border-yellow-900'
  | 'sm:group-hover:border-green-50'
  | 'sm:group-hover:border-green-100'
  | 'sm:group-hover:border-green-200'
  | 'sm:group-hover:border-green-300'
  | 'sm:group-hover:border-green-400'
  | 'sm:group-hover:border-green-500'
  | 'sm:group-hover:border-green-600'
  | 'sm:group-hover:border-green-700'
  | 'sm:group-hover:border-green-800'
  | 'sm:group-hover:border-green-900'
  | 'sm:group-hover:border-teal-50'
  | 'sm:group-hover:border-teal-100'
  | 'sm:group-hover:border-teal-200'
  | 'sm:group-hover:border-teal-300'
  | 'sm:group-hover:border-teal-400'
  | 'sm:group-hover:border-teal-500'
  | 'sm:group-hover:border-teal-600'
  | 'sm:group-hover:border-teal-700'
  | 'sm:group-hover:border-teal-800'
  | 'sm:group-hover:border-teal-900'
  | 'sm:group-hover:border-blue-50'
  | 'sm:group-hover:border-blue-100'
  | 'sm:group-hover:border-blue-200'
  | 'sm:group-hover:border-blue-300'
  | 'sm:group-hover:border-blue-400'
  | 'sm:group-hover:border-blue-500'
  | 'sm:group-hover:border-blue-600'
  | 'sm:group-hover:border-blue-700'
  | 'sm:group-hover:border-blue-800'
  | 'sm:group-hover:border-blue-900'
  | 'sm:group-hover:border-indigo-50'
  | 'sm:group-hover:border-indigo-100'
  | 'sm:group-hover:border-indigo-200'
  | 'sm:group-hover:border-indigo-300'
  | 'sm:group-hover:border-indigo-400'
  | 'sm:group-hover:border-indigo-500'
  | 'sm:group-hover:border-indigo-600'
  | 'sm:group-hover:border-indigo-700'
  | 'sm:group-hover:border-indigo-800'
  | 'sm:group-hover:border-indigo-900'
  | 'sm:group-hover:border-purple-50'
  | 'sm:group-hover:border-purple-100'
  | 'sm:group-hover:border-purple-200'
  | 'sm:group-hover:border-purple-300'
  | 'sm:group-hover:border-purple-400'
  | 'sm:group-hover:border-purple-500'
  | 'sm:group-hover:border-purple-600'
  | 'sm:group-hover:border-purple-700'
  | 'sm:group-hover:border-purple-800'
  | 'sm:group-hover:border-purple-900'
  | 'sm:group-hover:border-pink-50'
  | 'sm:group-hover:border-pink-100'
  | 'sm:group-hover:border-pink-200'
  | 'sm:group-hover:border-pink-300'
  | 'sm:group-hover:border-pink-400'
  | 'sm:group-hover:border-pink-500'
  | 'sm:group-hover:border-pink-600'
  | 'sm:group-hover:border-pink-700'
  | 'sm:group-hover:border-pink-800'
  | 'sm:group-hover:border-pink-900'
  | 'sm:group-focus:border-transparent'
  | 'sm:group-focus:border-white'
  | 'sm:group-focus:border-black'
  | 'sm:group-focus:border-gray-50'
  | 'sm:group-focus:border-gray-100'
  | 'sm:group-focus:border-gray-200'
  | 'sm:group-focus:border-gray-300'
  | 'sm:group-focus:border-gray-400'
  | 'sm:group-focus:border-gray-500'
  | 'sm:group-focus:border-gray-600'
  | 'sm:group-focus:border-gray-700'
  | 'sm:group-focus:border-gray-800'
  | 'sm:group-focus:border-gray-900'
  | 'sm:group-focus:border-cool-gray-50'
  | 'sm:group-focus:border-cool-gray-100'
  | 'sm:group-focus:border-cool-gray-200'
  | 'sm:group-focus:border-cool-gray-300'
  | 'sm:group-focus:border-cool-gray-400'
  | 'sm:group-focus:border-cool-gray-500'
  | 'sm:group-focus:border-cool-gray-600'
  | 'sm:group-focus:border-cool-gray-700'
  | 'sm:group-focus:border-cool-gray-800'
  | 'sm:group-focus:border-cool-gray-900'
  | 'sm:group-focus:border-red-50'
  | 'sm:group-focus:border-red-100'
  | 'sm:group-focus:border-red-200'
  | 'sm:group-focus:border-red-300'
  | 'sm:group-focus:border-red-400'
  | 'sm:group-focus:border-red-500'
  | 'sm:group-focus:border-red-600'
  | 'sm:group-focus:border-red-700'
  | 'sm:group-focus:border-red-800'
  | 'sm:group-focus:border-red-900'
  | 'sm:group-focus:border-orange-50'
  | 'sm:group-focus:border-orange-100'
  | 'sm:group-focus:border-orange-200'
  | 'sm:group-focus:border-orange-300'
  | 'sm:group-focus:border-orange-400'
  | 'sm:group-focus:border-orange-500'
  | 'sm:group-focus:border-orange-600'
  | 'sm:group-focus:border-orange-700'
  | 'sm:group-focus:border-orange-800'
  | 'sm:group-focus:border-orange-900'
  | 'sm:group-focus:border-yellow-50'
  | 'sm:group-focus:border-yellow-100'
  | 'sm:group-focus:border-yellow-200'
  | 'sm:group-focus:border-yellow-300'
  | 'sm:group-focus:border-yellow-400'
  | 'sm:group-focus:border-yellow-500'
  | 'sm:group-focus:border-yellow-600'
  | 'sm:group-focus:border-yellow-700'
  | 'sm:group-focus:border-yellow-800'
  | 'sm:group-focus:border-yellow-900'
  | 'sm:group-focus:border-green-50'
  | 'sm:group-focus:border-green-100'
  | 'sm:group-focus:border-green-200'
  | 'sm:group-focus:border-green-300'
  | 'sm:group-focus:border-green-400'
  | 'sm:group-focus:border-green-500'
  | 'sm:group-focus:border-green-600'
  | 'sm:group-focus:border-green-700'
  | 'sm:group-focus:border-green-800'
  | 'sm:group-focus:border-green-900'
  | 'sm:group-focus:border-teal-50'
  | 'sm:group-focus:border-teal-100'
  | 'sm:group-focus:border-teal-200'
  | 'sm:group-focus:border-teal-300'
  | 'sm:group-focus:border-teal-400'
  | 'sm:group-focus:border-teal-500'
  | 'sm:group-focus:border-teal-600'
  | 'sm:group-focus:border-teal-700'
  | 'sm:group-focus:border-teal-800'
  | 'sm:group-focus:border-teal-900'
  | 'sm:group-focus:border-blue-50'
  | 'sm:group-focus:border-blue-100'
  | 'sm:group-focus:border-blue-200'
  | 'sm:group-focus:border-blue-300'
  | 'sm:group-focus:border-blue-400'
  | 'sm:group-focus:border-blue-500'
  | 'sm:group-focus:border-blue-600'
  | 'sm:group-focus:border-blue-700'
  | 'sm:group-focus:border-blue-800'
  | 'sm:group-focus:border-blue-900'
  | 'sm:group-focus:border-indigo-50'
  | 'sm:group-focus:border-indigo-100'
  | 'sm:group-focus:border-indigo-200'
  | 'sm:group-focus:border-indigo-300'
  | 'sm:group-focus:border-indigo-400'
  | 'sm:group-focus:border-indigo-500'
  | 'sm:group-focus:border-indigo-600'
  | 'sm:group-focus:border-indigo-700'
  | 'sm:group-focus:border-indigo-800'
  | 'sm:group-focus:border-indigo-900'
  | 'sm:group-focus:border-purple-50'
  | 'sm:group-focus:border-purple-100'
  | 'sm:group-focus:border-purple-200'
  | 'sm:group-focus:border-purple-300'
  | 'sm:group-focus:border-purple-400'
  | 'sm:group-focus:border-purple-500'
  | 'sm:group-focus:border-purple-600'
  | 'sm:group-focus:border-purple-700'
  | 'sm:group-focus:border-purple-800'
  | 'sm:group-focus:border-purple-900'
  | 'sm:group-focus:border-pink-50'
  | 'sm:group-focus:border-pink-100'
  | 'sm:group-focus:border-pink-200'
  | 'sm:group-focus:border-pink-300'
  | 'sm:group-focus:border-pink-400'
  | 'sm:group-focus:border-pink-500'
  | 'sm:group-focus:border-pink-600'
  | 'sm:group-focus:border-pink-700'
  | 'sm:group-focus:border-pink-800'
  | 'sm:group-focus:border-pink-900'
  | 'sm:hover:border-transparent'
  | 'sm:hover:border-white'
  | 'sm:hover:border-black'
  | 'sm:hover:border-gray-50'
  | 'sm:hover:border-gray-100'
  | 'sm:hover:border-gray-200'
  | 'sm:hover:border-gray-300'
  | 'sm:hover:border-gray-400'
  | 'sm:hover:border-gray-500'
  | 'sm:hover:border-gray-600'
  | 'sm:hover:border-gray-700'
  | 'sm:hover:border-gray-800'
  | 'sm:hover:border-gray-900'
  | 'sm:hover:border-cool-gray-50'
  | 'sm:hover:border-cool-gray-100'
  | 'sm:hover:border-cool-gray-200'
  | 'sm:hover:border-cool-gray-300'
  | 'sm:hover:border-cool-gray-400'
  | 'sm:hover:border-cool-gray-500'
  | 'sm:hover:border-cool-gray-600'
  | 'sm:hover:border-cool-gray-700'
  | 'sm:hover:border-cool-gray-800'
  | 'sm:hover:border-cool-gray-900'
  | 'sm:hover:border-red-50'
  | 'sm:hover:border-red-100'
  | 'sm:hover:border-red-200'
  | 'sm:hover:border-red-300'
  | 'sm:hover:border-red-400'
  | 'sm:hover:border-red-500'
  | 'sm:hover:border-red-600'
  | 'sm:hover:border-red-700'
  | 'sm:hover:border-red-800'
  | 'sm:hover:border-red-900'
  | 'sm:hover:border-orange-50'
  | 'sm:hover:border-orange-100'
  | 'sm:hover:border-orange-200'
  | 'sm:hover:border-orange-300'
  | 'sm:hover:border-orange-400'
  | 'sm:hover:border-orange-500'
  | 'sm:hover:border-orange-600'
  | 'sm:hover:border-orange-700'
  | 'sm:hover:border-orange-800'
  | 'sm:hover:border-orange-900'
  | 'sm:hover:border-yellow-50'
  | 'sm:hover:border-yellow-100'
  | 'sm:hover:border-yellow-200'
  | 'sm:hover:border-yellow-300'
  | 'sm:hover:border-yellow-400'
  | 'sm:hover:border-yellow-500'
  | 'sm:hover:border-yellow-600'
  | 'sm:hover:border-yellow-700'
  | 'sm:hover:border-yellow-800'
  | 'sm:hover:border-yellow-900'
  | 'sm:hover:border-green-50'
  | 'sm:hover:border-green-100'
  | 'sm:hover:border-green-200'
  | 'sm:hover:border-green-300'
  | 'sm:hover:border-green-400'
  | 'sm:hover:border-green-500'
  | 'sm:hover:border-green-600'
  | 'sm:hover:border-green-700'
  | 'sm:hover:border-green-800'
  | 'sm:hover:border-green-900'
  | 'sm:hover:border-teal-50'
  | 'sm:hover:border-teal-100'
  | 'sm:hover:border-teal-200'
  | 'sm:hover:border-teal-300'
  | 'sm:hover:border-teal-400'
  | 'sm:hover:border-teal-500'
  | 'sm:hover:border-teal-600'
  | 'sm:hover:border-teal-700'
  | 'sm:hover:border-teal-800'
  | 'sm:hover:border-teal-900'
  | 'sm:hover:border-blue-50'
  | 'sm:hover:border-blue-100'
  | 'sm:hover:border-blue-200'
  | 'sm:hover:border-blue-300'
  | 'sm:hover:border-blue-400'
  | 'sm:hover:border-blue-500'
  | 'sm:hover:border-blue-600'
  | 'sm:hover:border-blue-700'
  | 'sm:hover:border-blue-800'
  | 'sm:hover:border-blue-900'
  | 'sm:hover:border-indigo-50'
  | 'sm:hover:border-indigo-100'
  | 'sm:hover:border-indigo-200'
  | 'sm:hover:border-indigo-300'
  | 'sm:hover:border-indigo-400'
  | 'sm:hover:border-indigo-500'
  | 'sm:hover:border-indigo-600'
  | 'sm:hover:border-indigo-700'
  | 'sm:hover:border-indigo-800'
  | 'sm:hover:border-indigo-900'
  | 'sm:hover:border-purple-50'
  | 'sm:hover:border-purple-100'
  | 'sm:hover:border-purple-200'
  | 'sm:hover:border-purple-300'
  | 'sm:hover:border-purple-400'
  | 'sm:hover:border-purple-500'
  | 'sm:hover:border-purple-600'
  | 'sm:hover:border-purple-700'
  | 'sm:hover:border-purple-800'
  | 'sm:hover:border-purple-900'
  | 'sm:hover:border-pink-50'
  | 'sm:hover:border-pink-100'
  | 'sm:hover:border-pink-200'
  | 'sm:hover:border-pink-300'
  | 'sm:hover:border-pink-400'
  | 'sm:hover:border-pink-500'
  | 'sm:hover:border-pink-600'
  | 'sm:hover:border-pink-700'
  | 'sm:hover:border-pink-800'
  | 'sm:hover:border-pink-900'
  | 'sm:focus:border-transparent'
  | 'sm:focus:border-white'
  | 'sm:focus:border-black'
  | 'sm:focus:border-gray-50'
  | 'sm:focus:border-gray-100'
  | 'sm:focus:border-gray-200'
  | 'sm:focus:border-gray-300'
  | 'sm:focus:border-gray-400'
  | 'sm:focus:border-gray-500'
  | 'sm:focus:border-gray-600'
  | 'sm:focus:border-gray-700'
  | 'sm:focus:border-gray-800'
  | 'sm:focus:border-gray-900'
  | 'sm:focus:border-cool-gray-50'
  | 'sm:focus:border-cool-gray-100'
  | 'sm:focus:border-cool-gray-200'
  | 'sm:focus:border-cool-gray-300'
  | 'sm:focus:border-cool-gray-400'
  | 'sm:focus:border-cool-gray-500'
  | 'sm:focus:border-cool-gray-600'
  | 'sm:focus:border-cool-gray-700'
  | 'sm:focus:border-cool-gray-800'
  | 'sm:focus:border-cool-gray-900'
  | 'sm:focus:border-red-50'
  | 'sm:focus:border-red-100'
  | 'sm:focus:border-red-200'
  | 'sm:focus:border-red-300'
  | 'sm:focus:border-red-400'
  | 'sm:focus:border-red-500'
  | 'sm:focus:border-red-600'
  | 'sm:focus:border-red-700'
  | 'sm:focus:border-red-800'
  | 'sm:focus:border-red-900'
  | 'sm:focus:border-orange-50'
  | 'sm:focus:border-orange-100'
  | 'sm:focus:border-orange-200'
  | 'sm:focus:border-orange-300'
  | 'sm:focus:border-orange-400'
  | 'sm:focus:border-orange-500'
  | 'sm:focus:border-orange-600'
  | 'sm:focus:border-orange-700'
  | 'sm:focus:border-orange-800'
  | 'sm:focus:border-orange-900'
  | 'sm:focus:border-yellow-50'
  | 'sm:focus:border-yellow-100'
  | 'sm:focus:border-yellow-200'
  | 'sm:focus:border-yellow-300'
  | 'sm:focus:border-yellow-400'
  | 'sm:focus:border-yellow-500'
  | 'sm:focus:border-yellow-600'
  | 'sm:focus:border-yellow-700'
  | 'sm:focus:border-yellow-800'
  | 'sm:focus:border-yellow-900'
  | 'sm:focus:border-green-50'
  | 'sm:focus:border-green-100'
  | 'sm:focus:border-green-200'
  | 'sm:focus:border-green-300'
  | 'sm:focus:border-green-400'
  | 'sm:focus:border-green-500'
  | 'sm:focus:border-green-600'
  | 'sm:focus:border-green-700'
  | 'sm:focus:border-green-800'
  | 'sm:focus:border-green-900'
  | 'sm:focus:border-teal-50'
  | 'sm:focus:border-teal-100'
  | 'sm:focus:border-teal-200'
  | 'sm:focus:border-teal-300'
  | 'sm:focus:border-teal-400'
  | 'sm:focus:border-teal-500'
  | 'sm:focus:border-teal-600'
  | 'sm:focus:border-teal-700'
  | 'sm:focus:border-teal-800'
  | 'sm:focus:border-teal-900'
  | 'sm:focus:border-blue-50'
  | 'sm:focus:border-blue-100'
  | 'sm:focus:border-blue-200'
  | 'sm:focus:border-blue-300'
  | 'sm:focus:border-blue-400'
  | 'sm:focus:border-blue-500'
  | 'sm:focus:border-blue-600'
  | 'sm:focus:border-blue-700'
  | 'sm:focus:border-blue-800'
  | 'sm:focus:border-blue-900'
  | 'sm:focus:border-indigo-50'
  | 'sm:focus:border-indigo-100'
  | 'sm:focus:border-indigo-200'
  | 'sm:focus:border-indigo-300'
  | 'sm:focus:border-indigo-400'
  | 'sm:focus:border-indigo-500'
  | 'sm:focus:border-indigo-600'
  | 'sm:focus:border-indigo-700'
  | 'sm:focus:border-indigo-800'
  | 'sm:focus:border-indigo-900'
  | 'sm:focus:border-purple-50'
  | 'sm:focus:border-purple-100'
  | 'sm:focus:border-purple-200'
  | 'sm:focus:border-purple-300'
  | 'sm:focus:border-purple-400'
  | 'sm:focus:border-purple-500'
  | 'sm:focus:border-purple-600'
  | 'sm:focus:border-purple-700'
  | 'sm:focus:border-purple-800'
  | 'sm:focus:border-purple-900'
  | 'sm:focus:border-pink-50'
  | 'sm:focus:border-pink-100'
  | 'sm:focus:border-pink-200'
  | 'sm:focus:border-pink-300'
  | 'sm:focus:border-pink-400'
  | 'sm:focus:border-pink-500'
  | 'sm:focus:border-pink-600'
  | 'sm:focus:border-pink-700'
  | 'sm:focus:border-pink-800'
  | 'sm:focus:border-pink-900'
  | 'sm:rounded-none'
  | 'sm:rounded-sm'
  | 'sm:rounded'
  | 'sm:rounded-md'
  | 'sm:rounded-lg'
  | 'sm:rounded-full'
  | 'sm:rounded-t-none'
  | 'sm:rounded-r-none'
  | 'sm:rounded-b-none'
  | 'sm:rounded-l-none'
  | 'sm:rounded-t-sm'
  | 'sm:rounded-r-sm'
  | 'sm:rounded-b-sm'
  | 'sm:rounded-l-sm'
  | 'sm:rounded-t'
  | 'sm:rounded-r'
  | 'sm:rounded-b'
  | 'sm:rounded-l'
  | 'sm:rounded-t-md'
  | 'sm:rounded-r-md'
  | 'sm:rounded-b-md'
  | 'sm:rounded-l-md'
  | 'sm:rounded-t-lg'
  | 'sm:rounded-r-lg'
  | 'sm:rounded-b-lg'
  | 'sm:rounded-l-lg'
  | 'sm:rounded-t-full'
  | 'sm:rounded-r-full'
  | 'sm:rounded-b-full'
  | 'sm:rounded-l-full'
  | 'sm:rounded-tl-none'
  | 'sm:rounded-tr-none'
  | 'sm:rounded-br-none'
  | 'sm:rounded-bl-none'
  | 'sm:rounded-tl-sm'
  | 'sm:rounded-tr-sm'
  | 'sm:rounded-br-sm'
  | 'sm:rounded-bl-sm'
  | 'sm:rounded-tl'
  | 'sm:rounded-tr'
  | 'sm:rounded-br'
  | 'sm:rounded-bl'
  | 'sm:rounded-tl-md'
  | 'sm:rounded-tr-md'
  | 'sm:rounded-br-md'
  | 'sm:rounded-bl-md'
  | 'sm:rounded-tl-lg'
  | 'sm:rounded-tr-lg'
  | 'sm:rounded-br-lg'
  | 'sm:rounded-bl-lg'
  | 'sm:rounded-tl-full'
  | 'sm:rounded-tr-full'
  | 'sm:rounded-br-full'
  | 'sm:rounded-bl-full'
  | 'sm:border-solid'
  | 'sm:border-dashed'
  | 'sm:border-dotted'
  | 'sm:border-double'
  | 'sm:border-none'
  | 'sm:border-0'
  | 'sm:border-2'
  | 'sm:border-4'
  | 'sm:border-8'
  | 'sm:border'
  | 'sm:border-t-0'
  | 'sm:border-r-0'
  | 'sm:border-b-0'
  | 'sm:border-l-0'
  | 'sm:border-t-2'
  | 'sm:border-r-2'
  | 'sm:border-b-2'
  | 'sm:border-l-2'
  | 'sm:border-t-4'
  | 'sm:border-r-4'
  | 'sm:border-b-4'
  | 'sm:border-l-4'
  | 'sm:border-t-8'
  | 'sm:border-r-8'
  | 'sm:border-b-8'
  | 'sm:border-l-8'
  | 'sm:border-t'
  | 'sm:border-r'
  | 'sm:border-b'
  | 'sm:border-l'
  | 'sm:box-border'
  | 'sm:box-content'
  | 'sm:cursor-auto'
  | 'sm:cursor-default'
  | 'sm:cursor-pointer'
  | 'sm:cursor-wait'
  | 'sm:cursor-text'
  | 'sm:cursor-move'
  | 'sm:cursor-not-allowed'
  | 'sm:block'
  | 'sm:inline-block'
  | 'sm:inline'
  | 'sm:flex'
  | 'sm:inline-flex'
  | 'sm:grid'
  | 'sm:table'
  | 'sm:table-caption'
  | 'sm:table-cell'
  | 'sm:table-column'
  | 'sm:table-column-group'
  | 'sm:table-footer-group'
  | 'sm:table-header-group'
  | 'sm:table-row-group'
  | 'sm:table-row'
  | 'sm:hidden'
  | 'sm:flex-row'
  | 'sm:flex-row-reverse'
  | 'sm:flex-col'
  | 'sm:flex-col-reverse'
  | 'sm:flex-wrap'
  | 'sm:flex-wrap-reverse'
  | 'sm:flex-no-wrap'
  | 'sm:items-start'
  | 'sm:items-end'
  | 'sm:items-center'
  | 'sm:items-baseline'
  | 'sm:items-stretch'
  | 'sm:self-auto'
  | 'sm:self-start'
  | 'sm:self-end'
  | 'sm:self-center'
  | 'sm:self-stretch'
  | 'sm:justify-start'
  | 'sm:justify-end'
  | 'sm:justify-center'
  | 'sm:justify-between'
  | 'sm:justify-around'
  | 'sm:justify-evenly'
  | 'sm:content-center'
  | 'sm:content-start'
  | 'sm:content-end'
  | 'sm:content-between'
  | 'sm:content-around'
  | 'sm:flex-1'
  | 'sm:flex-auto'
  | 'sm:flex-initial'
  | 'sm:flex-none'
  | 'sm:flex-grow-0'
  | 'sm:flex-grow'
  | 'sm:flex-shrink-0'
  | 'sm:flex-shrink'
  | 'sm:order-1'
  | 'sm:order-2'
  | 'sm:order-3'
  | 'sm:order-4'
  | 'sm:order-5'
  | 'sm:order-6'
  | 'sm:order-7'
  | 'sm:order-8'
  | 'sm:order-9'
  | 'sm:order-10'
  | 'sm:order-11'
  | 'sm:order-12'
  | 'sm:order-first'
  | 'sm:order-last'
  | 'sm:order-none'
  | 'sm:float-right'
  | 'sm:float-left'
  | 'sm:float-none'
  | 'sm:clearfix'
  | 'sm:clear-left'
  | 'sm:clear-right'
  | 'sm:clear-both'
  | 'sm:font-sans'
  | 'sm:font-serif'
  | 'sm:font-mono'
  | 'sm:font-hairline'
  | 'sm:font-thin'
  | 'sm:font-light'
  | 'sm:font-normal'
  | 'sm:font-medium'
  | 'sm:font-semibold'
  | 'sm:font-bold'
  | 'sm:font-extrabold'
  | 'sm:font-black'
  | 'sm:hover:font-hairline'
  | 'sm:hover:font-thin'
  | 'sm:hover:font-light'
  | 'sm:hover:font-normal'
  | 'sm:hover:font-medium'
  | 'sm:hover:font-semibold'
  | 'sm:hover:font-bold'
  | 'sm:hover:font-extrabold'
  | 'sm:hover:font-black'
  | 'sm:focus:font-hairline'
  | 'sm:focus:font-thin'
  | 'sm:focus:font-light'
  | 'sm:focus:font-normal'
  | 'sm:focus:font-medium'
  | 'sm:focus:font-semibold'
  | 'sm:focus:font-bold'
  | 'sm:focus:font-extrabold'
  | 'sm:focus:font-black'
  | 'sm:h-0'
  | 'sm:h-1'
  | 'sm:h-2'
  | 'sm:h-3'
  | 'sm:h-4'
  | 'sm:h-5'
  | 'sm:h-6'
  | 'sm:h-7'
  | 'sm:h-8'
  | 'sm:h-9'
  | 'sm:h-10'
  | 'sm:h-11'
  | 'sm:h-12'
  | 'sm:h-13'
  | 'sm:h-14'
  | 'sm:h-15'
  | 'sm:h-16'
  | 'sm:h-20'
  | 'sm:h-24'
  | 'sm:h-28'
  | 'sm:h-32'
  | 'sm:h-36'
  | 'sm:h-40'
  | 'sm:h-48'
  | 'sm:h-56'
  | 'sm:h-60'
  | 'sm:h-64'
  | 'sm:h-72'
  | 'sm:h-80'
  | 'sm:h-96'
  | 'sm:h-auto'
  | 'sm:h-px'
  | 'sm:h-0.5'
  | 'sm:h-1.5'
  | 'sm:h-2.5'
  | 'sm:h-3.5'
  | 'sm:h-1/2'
  | 'sm:h-1/3'
  | 'sm:h-2/3'
  | 'sm:h-1/4'
  | 'sm:h-2/4'
  | 'sm:h-3/4'
  | 'sm:h-1/5'
  | 'sm:h-2/5'
  | 'sm:h-3/5'
  | 'sm:h-4/5'
  | 'sm:h-1/6'
  | 'sm:h-2/6'
  | 'sm:h-3/6'
  | 'sm:h-4/6'
  | 'sm:h-5/6'
  | 'sm:h-1/12'
  | 'sm:h-2/12'
  | 'sm:h-3/12'
  | 'sm:h-4/12'
  | 'sm:h-5/12'
  | 'sm:h-6/12'
  | 'sm:h-7/12'
  | 'sm:h-8/12'
  | 'sm:h-9/12'
  | 'sm:h-10/12'
  | 'sm:h-11/12'
  | 'sm:h-full'
  | 'sm:h-screen'
  | 'sm:leading-3'
  | 'sm:leading-4'
  | 'sm:leading-5'
  | 'sm:leading-6'
  | 'sm:leading-7'
  | 'sm:leading-8'
  | 'sm:leading-9'
  | 'sm:leading-10'
  | 'sm:leading-none'
  | 'sm:leading-tight'
  | 'sm:leading-snug'
  | 'sm:leading-normal'
  | 'sm:leading-relaxed'
  | 'sm:leading-loose'
  | 'sm:list-inside'
  | 'sm:list-outside'
  | 'sm:list-none'
  | 'sm:list-disc'
  | 'sm:list-decimal'
  | 'sm:m-0'
  | 'sm:m-1'
  | 'sm:m-2'
  | 'sm:m-3'
  | 'sm:m-4'
  | 'sm:m-5'
  | 'sm:m-6'
  | 'sm:m-7'
  | 'sm:m-8'
  | 'sm:m-9'
  | 'sm:m-10'
  | 'sm:m-11'
  | 'sm:m-12'
  | 'sm:m-13'
  | 'sm:m-14'
  | 'sm:m-15'
  | 'sm:m-16'
  | 'sm:m-20'
  | 'sm:m-24'
  | 'sm:m-28'
  | 'sm:m-32'
  | 'sm:m-36'
  | 'sm:m-40'
  | 'sm:m-48'
  | 'sm:m-56'
  | 'sm:m-60'
  | 'sm:m-64'
  | 'sm:m-72'
  | 'sm:m-80'
  | 'sm:m-96'
  | 'sm:m-auto'
  | 'sm:m-px'
  | 'sm:m-0.5'
  | 'sm:m-1.5'
  | 'sm:m-2.5'
  | 'sm:m-3.5'
  | 'sm:m-1/2'
  | 'sm:m-1/3'
  | 'sm:m-2/3'
  | 'sm:m-1/4'
  | 'sm:m-2/4'
  | 'sm:m-3/4'
  | 'sm:m-1/5'
  | 'sm:m-2/5'
  | 'sm:m-3/5'
  | 'sm:m-4/5'
  | 'sm:m-1/6'
  | 'sm:m-2/6'
  | 'sm:m-3/6'
  | 'sm:m-4/6'
  | 'sm:m-5/6'
  | 'sm:m-1/12'
  | 'sm:m-2/12'
  | 'sm:m-3/12'
  | 'sm:m-4/12'
  | 'sm:m-5/12'
  | 'sm:m-6/12'
  | 'sm:m-7/12'
  | 'sm:m-8/12'
  | 'sm:m-9/12'
  | 'sm:m-10/12'
  | 'sm:m-11/12'
  | 'sm:m-full'
  | 'sm:-m-1'
  | 'sm:-m-2'
  | 'sm:-m-3'
  | 'sm:-m-4'
  | 'sm:-m-5'
  | 'sm:-m-6'
  | 'sm:-m-7'
  | 'sm:-m-8'
  | 'sm:-m-9'
  | 'sm:-m-10'
  | 'sm:-m-11'
  | 'sm:-m-12'
  | 'sm:-m-13'
  | 'sm:-m-14'
  | 'sm:-m-15'
  | 'sm:-m-16'
  | 'sm:-m-20'
  | 'sm:-m-24'
  | 'sm:-m-28'
  | 'sm:-m-32'
  | 'sm:-m-36'
  | 'sm:-m-40'
  | 'sm:-m-48'
  | 'sm:-m-56'
  | 'sm:-m-60'
  | 'sm:-m-64'
  | 'sm:-m-72'
  | 'sm:-m-80'
  | 'sm:-m-96'
  | 'sm:-m-px'
  | 'sm:-m-0.5'
  | 'sm:-m-1.5'
  | 'sm:-m-2.5'
  | 'sm:-m-3.5'
  | 'sm:-m-1/2'
  | 'sm:-m-1/3'
  | 'sm:-m-2/3'
  | 'sm:-m-1/4'
  | 'sm:-m-2/4'
  | 'sm:-m-3/4'
  | 'sm:-m-1/5'
  | 'sm:-m-2/5'
  | 'sm:-m-3/5'
  | 'sm:-m-4/5'
  | 'sm:-m-1/6'
  | 'sm:-m-2/6'
  | 'sm:-m-3/6'
  | 'sm:-m-4/6'
  | 'sm:-m-5/6'
  | 'sm:-m-1/12'
  | 'sm:-m-2/12'
  | 'sm:-m-3/12'
  | 'sm:-m-4/12'
  | 'sm:-m-5/12'
  | 'sm:-m-6/12'
  | 'sm:-m-7/12'
  | 'sm:-m-8/12'
  | 'sm:-m-9/12'
  | 'sm:-m-10/12'
  | 'sm:-m-11/12'
  | 'sm:-m-full'
  | 'sm:my-0'
  | 'sm:mx-0'
  | 'sm:my-1'
  | 'sm:mx-1'
  | 'sm:my-2'
  | 'sm:mx-2'
  | 'sm:my-3'
  | 'sm:mx-3'
  | 'sm:my-4'
  | 'sm:mx-4'
  | 'sm:my-5'
  | 'sm:mx-5'
  | 'sm:my-6'
  | 'sm:mx-6'
  | 'sm:my-7'
  | 'sm:mx-7'
  | 'sm:my-8'
  | 'sm:mx-8'
  | 'sm:my-9'
  | 'sm:mx-9'
  | 'sm:my-10'
  | 'sm:mx-10'
  | 'sm:my-11'
  | 'sm:mx-11'
  | 'sm:my-12'
  | 'sm:mx-12'
  | 'sm:my-13'
  | 'sm:mx-13'
  | 'sm:my-14'
  | 'sm:mx-14'
  | 'sm:my-15'
  | 'sm:mx-15'
  | 'sm:my-16'
  | 'sm:mx-16'
  | 'sm:my-20'
  | 'sm:mx-20'
  | 'sm:my-24'
  | 'sm:mx-24'
  | 'sm:my-28'
  | 'sm:mx-28'
  | 'sm:my-32'
  | 'sm:mx-32'
  | 'sm:my-36'
  | 'sm:mx-36'
  | 'sm:my-40'
  | 'sm:mx-40'
  | 'sm:my-48'
  | 'sm:mx-48'
  | 'sm:my-56'
  | 'sm:mx-56'
  | 'sm:my-60'
  | 'sm:mx-60'
  | 'sm:my-64'
  | 'sm:mx-64'
  | 'sm:my-72'
  | 'sm:mx-72'
  | 'sm:my-80'
  | 'sm:mx-80'
  | 'sm:my-96'
  | 'sm:mx-96'
  | 'sm:my-auto'
  | 'sm:mx-auto'
  | 'sm:my-px'
  | 'sm:mx-px'
  | 'sm:my-0.5'
  | 'sm:mx-0.5'
  | 'sm:my-1.5'
  | 'sm:mx-1.5'
  | 'sm:my-2.5'
  | 'sm:mx-2.5'
  | 'sm:my-3.5'
  | 'sm:mx-3.5'
  | 'sm:my-1/2'
  | 'sm:mx-1/2'
  | 'sm:my-1/3'
  | 'sm:mx-1/3'
  | 'sm:my-2/3'
  | 'sm:mx-2/3'
  | 'sm:my-1/4'
  | 'sm:mx-1/4'
  | 'sm:my-2/4'
  | 'sm:mx-2/4'
  | 'sm:my-3/4'
  | 'sm:mx-3/4'
  | 'sm:my-1/5'
  | 'sm:mx-1/5'
  | 'sm:my-2/5'
  | 'sm:mx-2/5'
  | 'sm:my-3/5'
  | 'sm:mx-3/5'
  | 'sm:my-4/5'
  | 'sm:mx-4/5'
  | 'sm:my-1/6'
  | 'sm:mx-1/6'
  | 'sm:my-2/6'
  | 'sm:mx-2/6'
  | 'sm:my-3/6'
  | 'sm:mx-3/6'
  | 'sm:my-4/6'
  | 'sm:mx-4/6'
  | 'sm:my-5/6'
  | 'sm:mx-5/6'
  | 'sm:my-1/12'
  | 'sm:mx-1/12'
  | 'sm:my-2/12'
  | 'sm:mx-2/12'
  | 'sm:my-3/12'
  | 'sm:mx-3/12'
  | 'sm:my-4/12'
  | 'sm:mx-4/12'
  | 'sm:my-5/12'
  | 'sm:mx-5/12'
  | 'sm:my-6/12'
  | 'sm:mx-6/12'
  | 'sm:my-7/12'
  | 'sm:mx-7/12'
  | 'sm:my-8/12'
  | 'sm:mx-8/12'
  | 'sm:my-9/12'
  | 'sm:mx-9/12'
  | 'sm:my-10/12'
  | 'sm:mx-10/12'
  | 'sm:my-11/12'
  | 'sm:mx-11/12'
  | 'sm:my-full'
  | 'sm:mx-full'
  | 'sm:-my-1'
  | 'sm:-mx-1'
  | 'sm:-my-2'
  | 'sm:-mx-2'
  | 'sm:-my-3'
  | 'sm:-mx-3'
  | 'sm:-my-4'
  | 'sm:-mx-4'
  | 'sm:-my-5'
  | 'sm:-mx-5'
  | 'sm:-my-6'
  | 'sm:-mx-6'
  | 'sm:-my-7'
  | 'sm:-mx-7'
  | 'sm:-my-8'
  | 'sm:-mx-8'
  | 'sm:-my-9'
  | 'sm:-mx-9'
  | 'sm:-my-10'
  | 'sm:-mx-10'
  | 'sm:-my-11'
  | 'sm:-mx-11'
  | 'sm:-my-12'
  | 'sm:-mx-12'
  | 'sm:-my-13'
  | 'sm:-mx-13'
  | 'sm:-my-14'
  | 'sm:-mx-14'
  | 'sm:-my-15'
  | 'sm:-mx-15'
  | 'sm:-my-16'
  | 'sm:-mx-16'
  | 'sm:-my-20'
  | 'sm:-mx-20'
  | 'sm:-my-24'
  | 'sm:-mx-24'
  | 'sm:-my-28'
  | 'sm:-mx-28'
  | 'sm:-my-32'
  | 'sm:-mx-32'
  | 'sm:-my-36'
  | 'sm:-mx-36'
  | 'sm:-my-40'
  | 'sm:-mx-40'
  | 'sm:-my-48'
  | 'sm:-mx-48'
  | 'sm:-my-56'
  | 'sm:-mx-56'
  | 'sm:-my-60'
  | 'sm:-mx-60'
  | 'sm:-my-64'
  | 'sm:-mx-64'
  | 'sm:-my-72'
  | 'sm:-mx-72'
  | 'sm:-my-80'
  | 'sm:-mx-80'
  | 'sm:-my-96'
  | 'sm:-mx-96'
  | 'sm:-my-px'
  | 'sm:-mx-px'
  | 'sm:-my-0.5'
  | 'sm:-mx-0.5'
  | 'sm:-my-1.5'
  | 'sm:-mx-1.5'
  | 'sm:-my-2.5'
  | 'sm:-mx-2.5'
  | 'sm:-my-3.5'
  | 'sm:-mx-3.5'
  | 'sm:-my-1/2'
  | 'sm:-mx-1/2'
  | 'sm:-my-1/3'
  | 'sm:-mx-1/3'
  | 'sm:-my-2/3'
  | 'sm:-mx-2/3'
  | 'sm:-my-1/4'
  | 'sm:-mx-1/4'
  | 'sm:-my-2/4'
  | 'sm:-mx-2/4'
  | 'sm:-my-3/4'
  | 'sm:-mx-3/4'
  | 'sm:-my-1/5'
  | 'sm:-mx-1/5'
  | 'sm:-my-2/5'
  | 'sm:-mx-2/5'
  | 'sm:-my-3/5'
  | 'sm:-mx-3/5'
  | 'sm:-my-4/5'
  | 'sm:-mx-4/5'
  | 'sm:-my-1/6'
  | 'sm:-mx-1/6'
  | 'sm:-my-2/6'
  | 'sm:-mx-2/6'
  | 'sm:-my-3/6'
  | 'sm:-mx-3/6'
  | 'sm:-my-4/6'
  | 'sm:-mx-4/6'
  | 'sm:-my-5/6'
  | 'sm:-mx-5/6'
  | 'sm:-my-1/12'
  | 'sm:-mx-1/12'
  | 'sm:-my-2/12'
  | 'sm:-mx-2/12'
  | 'sm:-my-3/12'
  | 'sm:-mx-3/12'
  | 'sm:-my-4/12'
  | 'sm:-mx-4/12'
  | 'sm:-my-5/12'
  | 'sm:-mx-5/12'
  | 'sm:-my-6/12'
  | 'sm:-mx-6/12'
  | 'sm:-my-7/12'
  | 'sm:-mx-7/12'
  | 'sm:-my-8/12'
  | 'sm:-mx-8/12'
  | 'sm:-my-9/12'
  | 'sm:-mx-9/12'
  | 'sm:-my-10/12'
  | 'sm:-mx-10/12'
  | 'sm:-my-11/12'
  | 'sm:-mx-11/12'
  | 'sm:-my-full'
  | 'sm:-mx-full'
  | 'sm:mt-0'
  | 'sm:mr-0'
  | 'sm:mb-0'
  | 'sm:ml-0'
  | 'sm:mt-1'
  | 'sm:mr-1'
  | 'sm:mb-1'
  | 'sm:ml-1'
  | 'sm:mt-2'
  | 'sm:mr-2'
  | 'sm:mb-2'
  | 'sm:ml-2'
  | 'sm:mt-3'
  | 'sm:mr-3'
  | 'sm:mb-3'
  | 'sm:ml-3'
  | 'sm:mt-4'
  | 'sm:mr-4'
  | 'sm:mb-4'
  | 'sm:ml-4'
  | 'sm:mt-5'
  | 'sm:mr-5'
  | 'sm:mb-5'
  | 'sm:ml-5'
  | 'sm:mt-6'
  | 'sm:mr-6'
  | 'sm:mb-6'
  | 'sm:ml-6'
  | 'sm:mt-7'
  | 'sm:mr-7'
  | 'sm:mb-7'
  | 'sm:ml-7'
  | 'sm:mt-8'
  | 'sm:mr-8'
  | 'sm:mb-8'
  | 'sm:ml-8'
  | 'sm:mt-9'
  | 'sm:mr-9'
  | 'sm:mb-9'
  | 'sm:ml-9'
  | 'sm:mt-10'
  | 'sm:mr-10'
  | 'sm:mb-10'
  | 'sm:ml-10'
  | 'sm:mt-11'
  | 'sm:mr-11'
  | 'sm:mb-11'
  | 'sm:ml-11'
  | 'sm:mt-12'
  | 'sm:mr-12'
  | 'sm:mb-12'
  | 'sm:ml-12'
  | 'sm:mt-13'
  | 'sm:mr-13'
  | 'sm:mb-13'
  | 'sm:ml-13'
  | 'sm:mt-14'
  | 'sm:mr-14'
  | 'sm:mb-14'
  | 'sm:ml-14'
  | 'sm:mt-15'
  | 'sm:mr-15'
  | 'sm:mb-15'
  | 'sm:ml-15'
  | 'sm:mt-16'
  | 'sm:mr-16'
  | 'sm:mb-16'
  | 'sm:ml-16'
  | 'sm:mt-20'
  | 'sm:mr-20'
  | 'sm:mb-20'
  | 'sm:ml-20'
  | 'sm:mt-24'
  | 'sm:mr-24'
  | 'sm:mb-24'
  | 'sm:ml-24'
  | 'sm:mt-28'
  | 'sm:mr-28'
  | 'sm:mb-28'
  | 'sm:ml-28'
  | 'sm:mt-32'
  | 'sm:mr-32'
  | 'sm:mb-32'
  | 'sm:ml-32'
  | 'sm:mt-36'
  | 'sm:mr-36'
  | 'sm:mb-36'
  | 'sm:ml-36'
  | 'sm:mt-40'
  | 'sm:mr-40'
  | 'sm:mb-40'
  | 'sm:ml-40'
  | 'sm:mt-48'
  | 'sm:mr-48'
  | 'sm:mb-48'
  | 'sm:ml-48'
  | 'sm:mt-56'
  | 'sm:mr-56'
  | 'sm:mb-56'
  | 'sm:ml-56'
  | 'sm:mt-60'
  | 'sm:mr-60'
  | 'sm:mb-60'
  | 'sm:ml-60'
  | 'sm:mt-64'
  | 'sm:mr-64'
  | 'sm:mb-64'
  | 'sm:ml-64'
  | 'sm:mt-72'
  | 'sm:mr-72'
  | 'sm:mb-72'
  | 'sm:ml-72'
  | 'sm:mt-80'
  | 'sm:mr-80'
  | 'sm:mb-80'
  | 'sm:ml-80'
  | 'sm:mt-96'
  | 'sm:mr-96'
  | 'sm:mb-96'
  | 'sm:ml-96'
  | 'sm:mt-auto'
  | 'sm:mr-auto'
  | 'sm:mb-auto'
  | 'sm:ml-auto'
  | 'sm:mt-px'
  | 'sm:mr-px'
  | 'sm:mb-px'
  | 'sm:ml-px'
  | 'sm:mt-0.5'
  | 'sm:mr-0.5'
  | 'sm:mb-0.5'
  | 'sm:ml-0.5'
  | 'sm:mt-1.5'
  | 'sm:mr-1.5'
  | 'sm:mb-1.5'
  | 'sm:ml-1.5'
  | 'sm:mt-2.5'
  | 'sm:mr-2.5'
  | 'sm:mb-2.5'
  | 'sm:ml-2.5'
  | 'sm:mt-3.5'
  | 'sm:mr-3.5'
  | 'sm:mb-3.5'
  | 'sm:ml-3.5'
  | 'sm:mt-1/2'
  | 'sm:mr-1/2'
  | 'sm:mb-1/2'
  | 'sm:ml-1/2'
  | 'sm:mt-1/3'
  | 'sm:mr-1/3'
  | 'sm:mb-1/3'
  | 'sm:ml-1/3'
  | 'sm:mt-2/3'
  | 'sm:mr-2/3'
  | 'sm:mb-2/3'
  | 'sm:ml-2/3'
  | 'sm:mt-1/4'
  | 'sm:mr-1/4'
  | 'sm:mb-1/4'
  | 'sm:ml-1/4'
  | 'sm:mt-2/4'
  | 'sm:mr-2/4'
  | 'sm:mb-2/4'
  | 'sm:ml-2/4'
  | 'sm:mt-3/4'
  | 'sm:mr-3/4'
  | 'sm:mb-3/4'
  | 'sm:ml-3/4'
  | 'sm:mt-1/5'
  | 'sm:mr-1/5'
  | 'sm:mb-1/5'
  | 'sm:ml-1/5'
  | 'sm:mt-2/5'
  | 'sm:mr-2/5'
  | 'sm:mb-2/5'
  | 'sm:ml-2/5'
  | 'sm:mt-3/5'
  | 'sm:mr-3/5'
  | 'sm:mb-3/5'
  | 'sm:ml-3/5'
  | 'sm:mt-4/5'
  | 'sm:mr-4/5'
  | 'sm:mb-4/5'
  | 'sm:ml-4/5'
  | 'sm:mt-1/6'
  | 'sm:mr-1/6'
  | 'sm:mb-1/6'
  | 'sm:ml-1/6'
  | 'sm:mt-2/6'
  | 'sm:mr-2/6'
  | 'sm:mb-2/6'
  | 'sm:ml-2/6'
  | 'sm:mt-3/6'
  | 'sm:mr-3/6'
  | 'sm:mb-3/6'
  | 'sm:ml-3/6'
  | 'sm:mt-4/6'
  | 'sm:mr-4/6'
  | 'sm:mb-4/6'
  | 'sm:ml-4/6'
  | 'sm:mt-5/6'
  | 'sm:mr-5/6'
  | 'sm:mb-5/6'
  | 'sm:ml-5/6'
  | 'sm:mt-1/12'
  | 'sm:mr-1/12'
  | 'sm:mb-1/12'
  | 'sm:ml-1/12'
  | 'sm:mt-2/12'
  | 'sm:mr-2/12'
  | 'sm:mb-2/12'
  | 'sm:ml-2/12'
  | 'sm:mt-3/12'
  | 'sm:mr-3/12'
  | 'sm:mb-3/12'
  | 'sm:ml-3/12'
  | 'sm:mt-4/12'
  | 'sm:mr-4/12'
  | 'sm:mb-4/12'
  | 'sm:ml-4/12'
  | 'sm:mt-5/12'
  | 'sm:mr-5/12'
  | 'sm:mb-5/12'
  | 'sm:ml-5/12'
  | 'sm:mt-6/12'
  | 'sm:mr-6/12'
  | 'sm:mb-6/12'
  | 'sm:ml-6/12'
  | 'sm:mt-7/12'
  | 'sm:mr-7/12'
  | 'sm:mb-7/12'
  | 'sm:ml-7/12'
  | 'sm:mt-8/12'
  | 'sm:mr-8/12'
  | 'sm:mb-8/12'
  | 'sm:ml-8/12'
  | 'sm:mt-9/12'
  | 'sm:mr-9/12'
  | 'sm:mb-9/12'
  | 'sm:ml-9/12'
  | 'sm:mt-10/12'
  | 'sm:mr-10/12'
  | 'sm:mb-10/12'
  | 'sm:ml-10/12'
  | 'sm:mt-11/12'
  | 'sm:mr-11/12'
  | 'sm:mb-11/12'
  | 'sm:ml-11/12'
  | 'sm:mt-full'
  | 'sm:mr-full'
  | 'sm:mb-full'
  | 'sm:ml-full'
  | 'sm:-mt-1'
  | 'sm:-mr-1'
  | 'sm:-mb-1'
  | 'sm:-ml-1'
  | 'sm:-mt-2'
  | 'sm:-mr-2'
  | 'sm:-mb-2'
  | 'sm:-ml-2'
  | 'sm:-mt-3'
  | 'sm:-mr-3'
  | 'sm:-mb-3'
  | 'sm:-ml-3'
  | 'sm:-mt-4'
  | 'sm:-mr-4'
  | 'sm:-mb-4'
  | 'sm:-ml-4'
  | 'sm:-mt-5'
  | 'sm:-mr-5'
  | 'sm:-mb-5'
  | 'sm:-ml-5'
  | 'sm:-mt-6'
  | 'sm:-mr-6'
  | 'sm:-mb-6'
  | 'sm:-ml-6'
  | 'sm:-mt-7'
  | 'sm:-mr-7'
  | 'sm:-mb-7'
  | 'sm:-ml-7'
  | 'sm:-mt-8'
  | 'sm:-mr-8'
  | 'sm:-mb-8'
  | 'sm:-ml-8'
  | 'sm:-mt-9'
  | 'sm:-mr-9'
  | 'sm:-mb-9'
  | 'sm:-ml-9'
  | 'sm:-mt-10'
  | 'sm:-mr-10'
  | 'sm:-mb-10'
  | 'sm:-ml-10'
  | 'sm:-mt-11'
  | 'sm:-mr-11'
  | 'sm:-mb-11'
  | 'sm:-ml-11'
  | 'sm:-mt-12'
  | 'sm:-mr-12'
  | 'sm:-mb-12'
  | 'sm:-ml-12'
  | 'sm:-mt-13'
  | 'sm:-mr-13'
  | 'sm:-mb-13'
  | 'sm:-ml-13'
  | 'sm:-mt-14'
  | 'sm:-mr-14'
  | 'sm:-mb-14'
  | 'sm:-ml-14'
  | 'sm:-mt-15'
  | 'sm:-mr-15'
  | 'sm:-mb-15'
  | 'sm:-ml-15'
  | 'sm:-mt-16'
  | 'sm:-mr-16'
  | 'sm:-mb-16'
  | 'sm:-ml-16'
  | 'sm:-mt-20'
  | 'sm:-mr-20'
  | 'sm:-mb-20'
  | 'sm:-ml-20'
  | 'sm:-mt-24'
  | 'sm:-mr-24'
  | 'sm:-mb-24'
  | 'sm:-ml-24'
  | 'sm:-mt-28'
  | 'sm:-mr-28'
  | 'sm:-mb-28'
  | 'sm:-ml-28'
  | 'sm:-mt-32'
  | 'sm:-mr-32'
  | 'sm:-mb-32'
  | 'sm:-ml-32'
  | 'sm:-mt-36'
  | 'sm:-mr-36'
  | 'sm:-mb-36'
  | 'sm:-ml-36'
  | 'sm:-mt-40'
  | 'sm:-mr-40'
  | 'sm:-mb-40'
  | 'sm:-ml-40'
  | 'sm:-mt-48'
  | 'sm:-mr-48'
  | 'sm:-mb-48'
  | 'sm:-ml-48'
  | 'sm:-mt-56'
  | 'sm:-mr-56'
  | 'sm:-mb-56'
  | 'sm:-ml-56'
  | 'sm:-mt-60'
  | 'sm:-mr-60'
  | 'sm:-mb-60'
  | 'sm:-ml-60'
  | 'sm:-mt-64'
  | 'sm:-mr-64'
  | 'sm:-mb-64'
  | 'sm:-ml-64'
  | 'sm:-mt-72'
  | 'sm:-mr-72'
  | 'sm:-mb-72'
  | 'sm:-ml-72'
  | 'sm:-mt-80'
  | 'sm:-mr-80'
  | 'sm:-mb-80'
  | 'sm:-ml-80'
  | 'sm:-mt-96'
  | 'sm:-mr-96'
  | 'sm:-mb-96'
  | 'sm:-ml-96'
  | 'sm:-mt-px'
  | 'sm:-mr-px'
  | 'sm:-mb-px'
  | 'sm:-ml-px'
  | 'sm:-mt-0.5'
  | 'sm:-mr-0.5'
  | 'sm:-mb-0.5'
  | 'sm:-ml-0.5'
  | 'sm:-mt-1.5'
  | 'sm:-mr-1.5'
  | 'sm:-mb-1.5'
  | 'sm:-ml-1.5'
  | 'sm:-mt-2.5'
  | 'sm:-mr-2.5'
  | 'sm:-mb-2.5'
  | 'sm:-ml-2.5'
  | 'sm:-mt-3.5'
  | 'sm:-mr-3.5'
  | 'sm:-mb-3.5'
  | 'sm:-ml-3.5'
  | 'sm:-mt-1/2'
  | 'sm:-mr-1/2'
  | 'sm:-mb-1/2'
  | 'sm:-ml-1/2'
  | 'sm:-mt-1/3'
  | 'sm:-mr-1/3'
  | 'sm:-mb-1/3'
  | 'sm:-ml-1/3'
  | 'sm:-mt-2/3'
  | 'sm:-mr-2/3'
  | 'sm:-mb-2/3'
  | 'sm:-ml-2/3'
  | 'sm:-mt-1/4'
  | 'sm:-mr-1/4'
  | 'sm:-mb-1/4'
  | 'sm:-ml-1/4'
  | 'sm:-mt-2/4'
  | 'sm:-mr-2/4'
  | 'sm:-mb-2/4'
  | 'sm:-ml-2/4'
  | 'sm:-mt-3/4'
  | 'sm:-mr-3/4'
  | 'sm:-mb-3/4'
  | 'sm:-ml-3/4'
  | 'sm:-mt-1/5'
  | 'sm:-mr-1/5'
  | 'sm:-mb-1/5'
  | 'sm:-ml-1/5'
  | 'sm:-mt-2/5'
  | 'sm:-mr-2/5'
  | 'sm:-mb-2/5'
  | 'sm:-ml-2/5'
  | 'sm:-mt-3/5'
  | 'sm:-mr-3/5'
  | 'sm:-mb-3/5'
  | 'sm:-ml-3/5'
  | 'sm:-mt-4/5'
  | 'sm:-mr-4/5'
  | 'sm:-mb-4/5'
  | 'sm:-ml-4/5'
  | 'sm:-mt-1/6'
  | 'sm:-mr-1/6'
  | 'sm:-mb-1/6'
  | 'sm:-ml-1/6'
  | 'sm:-mt-2/6'
  | 'sm:-mr-2/6'
  | 'sm:-mb-2/6'
  | 'sm:-ml-2/6'
  | 'sm:-mt-3/6'
  | 'sm:-mr-3/6'
  | 'sm:-mb-3/6'
  | 'sm:-ml-3/6'
  | 'sm:-mt-4/6'
  | 'sm:-mr-4/6'
  | 'sm:-mb-4/6'
  | 'sm:-ml-4/6'
  | 'sm:-mt-5/6'
  | 'sm:-mr-5/6'
  | 'sm:-mb-5/6'
  | 'sm:-ml-5/6'
  | 'sm:-mt-1/12'
  | 'sm:-mr-1/12'
  | 'sm:-mb-1/12'
  | 'sm:-ml-1/12'
  | 'sm:-mt-2/12'
  | 'sm:-mr-2/12'
  | 'sm:-mb-2/12'
  | 'sm:-ml-2/12'
  | 'sm:-mt-3/12'
  | 'sm:-mr-3/12'
  | 'sm:-mb-3/12'
  | 'sm:-ml-3/12'
  | 'sm:-mt-4/12'
  | 'sm:-mr-4/12'
  | 'sm:-mb-4/12'
  | 'sm:-ml-4/12'
  | 'sm:-mt-5/12'
  | 'sm:-mr-5/12'
  | 'sm:-mb-5/12'
  | 'sm:-ml-5/12'
  | 'sm:-mt-6/12'
  | 'sm:-mr-6/12'
  | 'sm:-mb-6/12'
  | 'sm:-ml-6/12'
  | 'sm:-mt-7/12'
  | 'sm:-mr-7/12'
  | 'sm:-mb-7/12'
  | 'sm:-ml-7/12'
  | 'sm:-mt-8/12'
  | 'sm:-mr-8/12'
  | 'sm:-mb-8/12'
  | 'sm:-ml-8/12'
  | 'sm:-mt-9/12'
  | 'sm:-mr-9/12'
  | 'sm:-mb-9/12'
  | 'sm:-ml-9/12'
  | 'sm:-mt-10/12'
  | 'sm:-mr-10/12'
  | 'sm:-mb-10/12'
  | 'sm:-ml-10/12'
  | 'sm:-mt-11/12'
  | 'sm:-mr-11/12'
  | 'sm:-mb-11/12'
  | 'sm:-ml-11/12'
  | 'sm:-mt-full'
  | 'sm:-mr-full'
  | 'sm:-mb-full'
  | 'sm:-ml-full'
  | 'sm:max-h-0'
  | 'sm:max-h-1'
  | 'sm:max-h-2'
  | 'sm:max-h-3'
  | 'sm:max-h-4'
  | 'sm:max-h-5'
  | 'sm:max-h-6'
  | 'sm:max-h-7'
  | 'sm:max-h-8'
  | 'sm:max-h-9'
  | 'sm:max-h-10'
  | 'sm:max-h-11'
  | 'sm:max-h-12'
  | 'sm:max-h-13'
  | 'sm:max-h-14'
  | 'sm:max-h-15'
  | 'sm:max-h-16'
  | 'sm:max-h-20'
  | 'sm:max-h-24'
  | 'sm:max-h-28'
  | 'sm:max-h-32'
  | 'sm:max-h-36'
  | 'sm:max-h-40'
  | 'sm:max-h-48'
  | 'sm:max-h-56'
  | 'sm:max-h-60'
  | 'sm:max-h-64'
  | 'sm:max-h-72'
  | 'sm:max-h-80'
  | 'sm:max-h-96'
  | 'sm:max-h-screen'
  | 'sm:max-h-px'
  | 'sm:max-h-0.5'
  | 'sm:max-h-1.5'
  | 'sm:max-h-2.5'
  | 'sm:max-h-3.5'
  | 'sm:max-h-1/2'
  | 'sm:max-h-1/3'
  | 'sm:max-h-2/3'
  | 'sm:max-h-1/4'
  | 'sm:max-h-2/4'
  | 'sm:max-h-3/4'
  | 'sm:max-h-1/5'
  | 'sm:max-h-2/5'
  | 'sm:max-h-3/5'
  | 'sm:max-h-4/5'
  | 'sm:max-h-1/6'
  | 'sm:max-h-2/6'
  | 'sm:max-h-3/6'
  | 'sm:max-h-4/6'
  | 'sm:max-h-5/6'
  | 'sm:max-h-1/12'
  | 'sm:max-h-2/12'
  | 'sm:max-h-3/12'
  | 'sm:max-h-4/12'
  | 'sm:max-h-5/12'
  | 'sm:max-h-6/12'
  | 'sm:max-h-7/12'
  | 'sm:max-h-8/12'
  | 'sm:max-h-9/12'
  | 'sm:max-h-10/12'
  | 'sm:max-h-11/12'
  | 'sm:max-h-full'
  | 'sm:max-w-none'
  | 'sm:max-w-xs'
  | 'sm:max-w-sm'
  | 'sm:max-w-md'
  | 'sm:max-w-lg'
  | 'sm:max-w-xl'
  | 'sm:max-w-2xl'
  | 'sm:max-w-3xl'
  | 'sm:max-w-4xl'
  | 'sm:max-w-5xl'
  | 'sm:max-w-6xl'
  | 'sm:max-w-7xl'
  | 'sm:max-w-full'
  | 'sm:max-w-screen-sm'
  | 'sm:max-w-screen-md'
  | 'sm:max-w-screen-lg'
  | 'sm:max-w-screen-xl'
  | 'sm:min-h-0'
  | 'sm:min-h-full'
  | 'sm:min-h-screen'
  | 'sm:min-w-0'
  | 'sm:min-w-full'
  | 'sm:object-contain'
  | 'sm:object-cover'
  | 'sm:object-fill'
  | 'sm:object-none'
  | 'sm:object-scale-down'
  | 'sm:object-bottom'
  | 'sm:object-center'
  | 'sm:object-left'
  | 'sm:object-left-bottom'
  | 'sm:object-left-top'
  | 'sm:object-right'
  | 'sm:object-right-bottom'
  | 'sm:object-right-top'
  | 'sm:object-top'
  | 'sm:opacity-0'
  | 'sm:opacity-25'
  | 'sm:opacity-50'
  | 'sm:opacity-75'
  | 'sm:opacity-100'
  | 'sm:hover:opacity-0'
  | 'sm:hover:opacity-25'
  | 'sm:hover:opacity-50'
  | 'sm:hover:opacity-75'
  | 'sm:hover:opacity-100'
  | 'sm:focus:opacity-0'
  | 'sm:focus:opacity-25'
  | 'sm:focus:opacity-50'
  | 'sm:focus:opacity-75'
  | 'sm:focus:opacity-100'
  | 'sm:outline-none'
  | 'sm:focus:outline-none'
  | 'sm:overflow-auto'
  | 'sm:overflow-hidden'
  | 'sm:overflow-visible'
  | 'sm:overflow-scroll'
  | 'sm:overflow-x-auto'
  | 'sm:overflow-y-auto'
  | 'sm:overflow-x-hidden'
  | 'sm:overflow-y-hidden'
  | 'sm:overflow-x-visible'
  | 'sm:overflow-y-visible'
  | 'sm:overflow-x-scroll'
  | 'sm:overflow-y-scroll'
  | 'sm:scrolling-touch'
  | 'sm:scrolling-auto'
  | 'sm:p-0'
  | 'sm:p-1'
  | 'sm:p-2'
  | 'sm:p-3'
  | 'sm:p-4'
  | 'sm:p-5'
  | 'sm:p-6'
  | 'sm:p-7'
  | 'sm:p-8'
  | 'sm:p-9'
  | 'sm:p-10'
  | 'sm:p-11'
  | 'sm:p-12'
  | 'sm:p-13'
  | 'sm:p-14'
  | 'sm:p-15'
  | 'sm:p-16'
  | 'sm:p-20'
  | 'sm:p-24'
  | 'sm:p-28'
  | 'sm:p-32'
  | 'sm:p-36'
  | 'sm:p-40'
  | 'sm:p-48'
  | 'sm:p-56'
  | 'sm:p-60'
  | 'sm:p-64'
  | 'sm:p-72'
  | 'sm:p-80'
  | 'sm:p-96'
  | 'sm:p-px'
  | 'sm:p-0.5'
  | 'sm:p-1.5'
  | 'sm:p-2.5'
  | 'sm:p-3.5'
  | 'sm:p-1/2'
  | 'sm:p-1/3'
  | 'sm:p-2/3'
  | 'sm:p-1/4'
  | 'sm:p-2/4'
  | 'sm:p-3/4'
  | 'sm:p-1/5'
  | 'sm:p-2/5'
  | 'sm:p-3/5'
  | 'sm:p-4/5'
  | 'sm:p-1/6'
  | 'sm:p-2/6'
  | 'sm:p-3/6'
  | 'sm:p-4/6'
  | 'sm:p-5/6'
  | 'sm:p-1/12'
  | 'sm:p-2/12'
  | 'sm:p-3/12'
  | 'sm:p-4/12'
  | 'sm:p-5/12'
  | 'sm:p-6/12'
  | 'sm:p-7/12'
  | 'sm:p-8/12'
  | 'sm:p-9/12'
  | 'sm:p-10/12'
  | 'sm:p-11/12'
  | 'sm:p-full'
  | 'sm:py-0'
  | 'sm:px-0'
  | 'sm:py-1'
  | 'sm:px-1'
  | 'sm:py-2'
  | 'sm:px-2'
  | 'sm:py-3'
  | 'sm:px-3'
  | 'sm:py-4'
  | 'sm:px-4'
  | 'sm:py-5'
  | 'sm:px-5'
  | 'sm:py-6'
  | 'sm:px-6'
  | 'sm:py-7'
  | 'sm:px-7'
  | 'sm:py-8'
  | 'sm:px-8'
  | 'sm:py-9'
  | 'sm:px-9'
  | 'sm:py-10'
  | 'sm:px-10'
  | 'sm:py-11'
  | 'sm:px-11'
  | 'sm:py-12'
  | 'sm:px-12'
  | 'sm:py-13'
  | 'sm:px-13'
  | 'sm:py-14'
  | 'sm:px-14'
  | 'sm:py-15'
  | 'sm:px-15'
  | 'sm:py-16'
  | 'sm:px-16'
  | 'sm:py-20'
  | 'sm:px-20'
  | 'sm:py-24'
  | 'sm:px-24'
  | 'sm:py-28'
  | 'sm:px-28'
  | 'sm:py-32'
  | 'sm:px-32'
  | 'sm:py-36'
  | 'sm:px-36'
  | 'sm:py-40'
  | 'sm:px-40'
  | 'sm:py-48'
  | 'sm:px-48'
  | 'sm:py-56'
  | 'sm:px-56'
  | 'sm:py-60'
  | 'sm:px-60'
  | 'sm:py-64'
  | 'sm:px-64'
  | 'sm:py-72'
  | 'sm:px-72'
  | 'sm:py-80'
  | 'sm:px-80'
  | 'sm:py-96'
  | 'sm:px-96'
  | 'sm:py-px'
  | 'sm:px-px'
  | 'sm:py-0.5'
  | 'sm:px-0.5'
  | 'sm:py-1.5'
  | 'sm:px-1.5'
  | 'sm:py-2.5'
  | 'sm:px-2.5'
  | 'sm:py-3.5'
  | 'sm:px-3.5'
  | 'sm:py-1/2'
  | 'sm:px-1/2'
  | 'sm:py-1/3'
  | 'sm:px-1/3'
  | 'sm:py-2/3'
  | 'sm:px-2/3'
  | 'sm:py-1/4'
  | 'sm:px-1/4'
  | 'sm:py-2/4'
  | 'sm:px-2/4'
  | 'sm:py-3/4'
  | 'sm:px-3/4'
  | 'sm:py-1/5'
  | 'sm:px-1/5'
  | 'sm:py-2/5'
  | 'sm:px-2/5'
  | 'sm:py-3/5'
  | 'sm:px-3/5'
  | 'sm:py-4/5'
  | 'sm:px-4/5'
  | 'sm:py-1/6'
  | 'sm:px-1/6'
  | 'sm:py-2/6'
  | 'sm:px-2/6'
  | 'sm:py-3/6'
  | 'sm:px-3/6'
  | 'sm:py-4/6'
  | 'sm:px-4/6'
  | 'sm:py-5/6'
  | 'sm:px-5/6'
  | 'sm:py-1/12'
  | 'sm:px-1/12'
  | 'sm:py-2/12'
  | 'sm:px-2/12'
  | 'sm:py-3/12'
  | 'sm:px-3/12'
  | 'sm:py-4/12'
  | 'sm:px-4/12'
  | 'sm:py-5/12'
  | 'sm:px-5/12'
  | 'sm:py-6/12'
  | 'sm:px-6/12'
  | 'sm:py-7/12'
  | 'sm:px-7/12'
  | 'sm:py-8/12'
  | 'sm:px-8/12'
  | 'sm:py-9/12'
  | 'sm:px-9/12'
  | 'sm:py-10/12'
  | 'sm:px-10/12'
  | 'sm:py-11/12'
  | 'sm:px-11/12'
  | 'sm:py-full'
  | 'sm:px-full'
  | 'sm:pt-0'
  | 'sm:pr-0'
  | 'sm:pb-0'
  | 'sm:pl-0'
  | 'sm:pt-1'
  | 'sm:pr-1'
  | 'sm:pb-1'
  | 'sm:pl-1'
  | 'sm:pt-2'
  | 'sm:pr-2'
  | 'sm:pb-2'
  | 'sm:pl-2'
  | 'sm:pt-3'
  | 'sm:pr-3'
  | 'sm:pb-3'
  | 'sm:pl-3'
  | 'sm:pt-4'
  | 'sm:pr-4'
  | 'sm:pb-4'
  | 'sm:pl-4'
  | 'sm:pt-5'
  | 'sm:pr-5'
  | 'sm:pb-5'
  | 'sm:pl-5'
  | 'sm:pt-6'
  | 'sm:pr-6'
  | 'sm:pb-6'
  | 'sm:pl-6'
  | 'sm:pt-7'
  | 'sm:pr-7'
  | 'sm:pb-7'
  | 'sm:pl-7'
  | 'sm:pt-8'
  | 'sm:pr-8'
  | 'sm:pb-8'
  | 'sm:pl-8'
  | 'sm:pt-9'
  | 'sm:pr-9'
  | 'sm:pb-9'
  | 'sm:pl-9'
  | 'sm:pt-10'
  | 'sm:pr-10'
  | 'sm:pb-10'
  | 'sm:pl-10'
  | 'sm:pt-11'
  | 'sm:pr-11'
  | 'sm:pb-11'
  | 'sm:pl-11'
  | 'sm:pt-12'
  | 'sm:pr-12'
  | 'sm:pb-12'
  | 'sm:pl-12'
  | 'sm:pt-13'
  | 'sm:pr-13'
  | 'sm:pb-13'
  | 'sm:pl-13'
  | 'sm:pt-14'
  | 'sm:pr-14'
  | 'sm:pb-14'
  | 'sm:pl-14'
  | 'sm:pt-15'
  | 'sm:pr-15'
  | 'sm:pb-15'
  | 'sm:pl-15'
  | 'sm:pt-16'
  | 'sm:pr-16'
  | 'sm:pb-16'
  | 'sm:pl-16'
  | 'sm:pt-20'
  | 'sm:pr-20'
  | 'sm:pb-20'
  | 'sm:pl-20'
  | 'sm:pt-24'
  | 'sm:pr-24'
  | 'sm:pb-24'
  | 'sm:pl-24'
  | 'sm:pt-28'
  | 'sm:pr-28'
  | 'sm:pb-28'
  | 'sm:pl-28'
  | 'sm:pt-32'
  | 'sm:pr-32'
  | 'sm:pb-32'
  | 'sm:pl-32'
  | 'sm:pt-36'
  | 'sm:pr-36'
  | 'sm:pb-36'
  | 'sm:pl-36'
  | 'sm:pt-40'
  | 'sm:pr-40'
  | 'sm:pb-40'
  | 'sm:pl-40'
  | 'sm:pt-48'
  | 'sm:pr-48'
  | 'sm:pb-48'
  | 'sm:pl-48'
  | 'sm:pt-56'
  | 'sm:pr-56'
  | 'sm:pb-56'
  | 'sm:pl-56'
  | 'sm:pt-60'
  | 'sm:pr-60'
  | 'sm:pb-60'
  | 'sm:pl-60'
  | 'sm:pt-64'
  | 'sm:pr-64'
  | 'sm:pb-64'
  | 'sm:pl-64'
  | 'sm:pt-72'
  | 'sm:pr-72'
  | 'sm:pb-72'
  | 'sm:pl-72'
  | 'sm:pt-80'
  | 'sm:pr-80'
  | 'sm:pb-80'
  | 'sm:pl-80'
  | 'sm:pt-96'
  | 'sm:pr-96'
  | 'sm:pb-96'
  | 'sm:pl-96'
  | 'sm:pt-px'
  | 'sm:pr-px'
  | 'sm:pb-px'
  | 'sm:pl-px'
  | 'sm:pt-0.5'
  | 'sm:pr-0.5'
  | 'sm:pb-0.5'
  | 'sm:pl-0.5'
  | 'sm:pt-1.5'
  | 'sm:pr-1.5'
  | 'sm:pb-1.5'
  | 'sm:pl-1.5'
  | 'sm:pt-2.5'
  | 'sm:pr-2.5'
  | 'sm:pb-2.5'
  | 'sm:pl-2.5'
  | 'sm:pt-3.5'
  | 'sm:pr-3.5'
  | 'sm:pb-3.5'
  | 'sm:pl-3.5'
  | 'sm:pt-1/2'
  | 'sm:pr-1/2'
  | 'sm:pb-1/2'
  | 'sm:pl-1/2'
  | 'sm:pt-1/3'
  | 'sm:pr-1/3'
  | 'sm:pb-1/3'
  | 'sm:pl-1/3'
  | 'sm:pt-2/3'
  | 'sm:pr-2/3'
  | 'sm:pb-2/3'
  | 'sm:pl-2/3'
  | 'sm:pt-1/4'
  | 'sm:pr-1/4'
  | 'sm:pb-1/4'
  | 'sm:pl-1/4'
  | 'sm:pt-2/4'
  | 'sm:pr-2/4'
  | 'sm:pb-2/4'
  | 'sm:pl-2/4'
  | 'sm:pt-3/4'
  | 'sm:pr-3/4'
  | 'sm:pb-3/4'
  | 'sm:pl-3/4'
  | 'sm:pt-1/5'
  | 'sm:pr-1/5'
  | 'sm:pb-1/5'
  | 'sm:pl-1/5'
  | 'sm:pt-2/5'
  | 'sm:pr-2/5'
  | 'sm:pb-2/5'
  | 'sm:pl-2/5'
  | 'sm:pt-3/5'
  | 'sm:pr-3/5'
  | 'sm:pb-3/5'
  | 'sm:pl-3/5'
  | 'sm:pt-4/5'
  | 'sm:pr-4/5'
  | 'sm:pb-4/5'
  | 'sm:pl-4/5'
  | 'sm:pt-1/6'
  | 'sm:pr-1/6'
  | 'sm:pb-1/6'
  | 'sm:pl-1/6'
  | 'sm:pt-2/6'
  | 'sm:pr-2/6'
  | 'sm:pb-2/6'
  | 'sm:pl-2/6'
  | 'sm:pt-3/6'
  | 'sm:pr-3/6'
  | 'sm:pb-3/6'
  | 'sm:pl-3/6'
  | 'sm:pt-4/6'
  | 'sm:pr-4/6'
  | 'sm:pb-4/6'
  | 'sm:pl-4/6'
  | 'sm:pt-5/6'
  | 'sm:pr-5/6'
  | 'sm:pb-5/6'
  | 'sm:pl-5/6'
  | 'sm:pt-1/12'
  | 'sm:pr-1/12'
  | 'sm:pb-1/12'
  | 'sm:pl-1/12'
  | 'sm:pt-2/12'
  | 'sm:pr-2/12'
  | 'sm:pb-2/12'
  | 'sm:pl-2/12'
  | 'sm:pt-3/12'
  | 'sm:pr-3/12'
  | 'sm:pb-3/12'
  | 'sm:pl-3/12'
  | 'sm:pt-4/12'
  | 'sm:pr-4/12'
  | 'sm:pb-4/12'
  | 'sm:pl-4/12'
  | 'sm:pt-5/12'
  | 'sm:pr-5/12'
  | 'sm:pb-5/12'
  | 'sm:pl-5/12'
  | 'sm:pt-6/12'
  | 'sm:pr-6/12'
  | 'sm:pb-6/12'
  | 'sm:pl-6/12'
  | 'sm:pt-7/12'
  | 'sm:pr-7/12'
  | 'sm:pb-7/12'
  | 'sm:pl-7/12'
  | 'sm:pt-8/12'
  | 'sm:pr-8/12'
  | 'sm:pb-8/12'
  | 'sm:pl-8/12'
  | 'sm:pt-9/12'
  | 'sm:pr-9/12'
  | 'sm:pb-9/12'
  | 'sm:pl-9/12'
  | 'sm:pt-10/12'
  | 'sm:pr-10/12'
  | 'sm:pb-10/12'
  | 'sm:pl-10/12'
  | 'sm:pt-11/12'
  | 'sm:pr-11/12'
  | 'sm:pb-11/12'
  | 'sm:pl-11/12'
  | 'sm:pt-full'
  | 'sm:pr-full'
  | 'sm:pb-full'
  | 'sm:pl-full'
  | 'sm:placeholder-transparent'
  | 'sm:placeholder-white'
  | 'sm:placeholder-black'
  | 'sm:placeholder-gray-50'
  | 'sm:placeholder-gray-100'
  | 'sm:placeholder-gray-200'
  | 'sm:placeholder-gray-300'
  | 'sm:placeholder-gray-400'
  | 'sm:placeholder-gray-500'
  | 'sm:placeholder-gray-600'
  | 'sm:placeholder-gray-700'
  | 'sm:placeholder-gray-800'
  | 'sm:placeholder-gray-900'
  | 'sm:placeholder-cool-gray-50'
  | 'sm:placeholder-cool-gray-100'
  | 'sm:placeholder-cool-gray-200'
  | 'sm:placeholder-cool-gray-300'
  | 'sm:placeholder-cool-gray-400'
  | 'sm:placeholder-cool-gray-500'
  | 'sm:placeholder-cool-gray-600'
  | 'sm:placeholder-cool-gray-700'
  | 'sm:placeholder-cool-gray-800'
  | 'sm:placeholder-cool-gray-900'
  | 'sm:placeholder-red-50'
  | 'sm:placeholder-red-100'
  | 'sm:placeholder-red-200'
  | 'sm:placeholder-red-300'
  | 'sm:placeholder-red-400'
  | 'sm:placeholder-red-500'
  | 'sm:placeholder-red-600'
  | 'sm:placeholder-red-700'
  | 'sm:placeholder-red-800'
  | 'sm:placeholder-red-900'
  | 'sm:placeholder-orange-50'
  | 'sm:placeholder-orange-100'
  | 'sm:placeholder-orange-200'
  | 'sm:placeholder-orange-300'
  | 'sm:placeholder-orange-400'
  | 'sm:placeholder-orange-500'
  | 'sm:placeholder-orange-600'
  | 'sm:placeholder-orange-700'
  | 'sm:placeholder-orange-800'
  | 'sm:placeholder-orange-900'
  | 'sm:placeholder-yellow-50'
  | 'sm:placeholder-yellow-100'
  | 'sm:placeholder-yellow-200'
  | 'sm:placeholder-yellow-300'
  | 'sm:placeholder-yellow-400'
  | 'sm:placeholder-yellow-500'
  | 'sm:placeholder-yellow-600'
  | 'sm:placeholder-yellow-700'
  | 'sm:placeholder-yellow-800'
  | 'sm:placeholder-yellow-900'
  | 'sm:placeholder-green-50'
  | 'sm:placeholder-green-100'
  | 'sm:placeholder-green-200'
  | 'sm:placeholder-green-300'
  | 'sm:placeholder-green-400'
  | 'sm:placeholder-green-500'
  | 'sm:placeholder-green-600'
  | 'sm:placeholder-green-700'
  | 'sm:placeholder-green-800'
  | 'sm:placeholder-green-900'
  | 'sm:placeholder-teal-50'
  | 'sm:placeholder-teal-100'
  | 'sm:placeholder-teal-200'
  | 'sm:placeholder-teal-300'
  | 'sm:placeholder-teal-400'
  | 'sm:placeholder-teal-500'
  | 'sm:placeholder-teal-600'
  | 'sm:placeholder-teal-700'
  | 'sm:placeholder-teal-800'
  | 'sm:placeholder-teal-900'
  | 'sm:placeholder-blue-50'
  | 'sm:placeholder-blue-100'
  | 'sm:placeholder-blue-200'
  | 'sm:placeholder-blue-300'
  | 'sm:placeholder-blue-400'
  | 'sm:placeholder-blue-500'
  | 'sm:placeholder-blue-600'
  | 'sm:placeholder-blue-700'
  | 'sm:placeholder-blue-800'
  | 'sm:placeholder-blue-900'
  | 'sm:placeholder-indigo-50'
  | 'sm:placeholder-indigo-100'
  | 'sm:placeholder-indigo-200'
  | 'sm:placeholder-indigo-300'
  | 'sm:placeholder-indigo-400'
  | 'sm:placeholder-indigo-500'
  | 'sm:placeholder-indigo-600'
  | 'sm:placeholder-indigo-700'
  | 'sm:placeholder-indigo-800'
  | 'sm:placeholder-indigo-900'
  | 'sm:placeholder-purple-50'
  | 'sm:placeholder-purple-100'
  | 'sm:placeholder-purple-200'
  | 'sm:placeholder-purple-300'
  | 'sm:placeholder-purple-400'
  | 'sm:placeholder-purple-500'
  | 'sm:placeholder-purple-600'
  | 'sm:placeholder-purple-700'
  | 'sm:placeholder-purple-800'
  | 'sm:placeholder-purple-900'
  | 'sm:placeholder-pink-50'
  | 'sm:placeholder-pink-100'
  | 'sm:placeholder-pink-200'
  | 'sm:placeholder-pink-300'
  | 'sm:placeholder-pink-400'
  | 'sm:placeholder-pink-500'
  | 'sm:placeholder-pink-600'
  | 'sm:placeholder-pink-700'
  | 'sm:placeholder-pink-800'
  | 'sm:placeholder-pink-900'
  | 'sm:focus:placeholder-transparent'
  | 'sm:focus:placeholder-white'
  | 'sm:focus:placeholder-black'
  | 'sm:focus:placeholder-gray-50'
  | 'sm:focus:placeholder-gray-100'
  | 'sm:focus:placeholder-gray-200'
  | 'sm:focus:placeholder-gray-300'
  | 'sm:focus:placeholder-gray-400'
  | 'sm:focus:placeholder-gray-500'
  | 'sm:focus:placeholder-gray-600'
  | 'sm:focus:placeholder-gray-700'
  | 'sm:focus:placeholder-gray-800'
  | 'sm:focus:placeholder-gray-900'
  | 'sm:focus:placeholder-cool-gray-50'
  | 'sm:focus:placeholder-cool-gray-100'
  | 'sm:focus:placeholder-cool-gray-200'
  | 'sm:focus:placeholder-cool-gray-300'
  | 'sm:focus:placeholder-cool-gray-400'
  | 'sm:focus:placeholder-cool-gray-500'
  | 'sm:focus:placeholder-cool-gray-600'
  | 'sm:focus:placeholder-cool-gray-700'
  | 'sm:focus:placeholder-cool-gray-800'
  | 'sm:focus:placeholder-cool-gray-900'
  | 'sm:focus:placeholder-red-50'
  | 'sm:focus:placeholder-red-100'
  | 'sm:focus:placeholder-red-200'
  | 'sm:focus:placeholder-red-300'
  | 'sm:focus:placeholder-red-400'
  | 'sm:focus:placeholder-red-500'
  | 'sm:focus:placeholder-red-600'
  | 'sm:focus:placeholder-red-700'
  | 'sm:focus:placeholder-red-800'
  | 'sm:focus:placeholder-red-900'
  | 'sm:focus:placeholder-orange-50'
  | 'sm:focus:placeholder-orange-100'
  | 'sm:focus:placeholder-orange-200'
  | 'sm:focus:placeholder-orange-300'
  | 'sm:focus:placeholder-orange-400'
  | 'sm:focus:placeholder-orange-500'
  | 'sm:focus:placeholder-orange-600'
  | 'sm:focus:placeholder-orange-700'
  | 'sm:focus:placeholder-orange-800'
  | 'sm:focus:placeholder-orange-900'
  | 'sm:focus:placeholder-yellow-50'
  | 'sm:focus:placeholder-yellow-100'
  | 'sm:focus:placeholder-yellow-200'
  | 'sm:focus:placeholder-yellow-300'
  | 'sm:focus:placeholder-yellow-400'
  | 'sm:focus:placeholder-yellow-500'
  | 'sm:focus:placeholder-yellow-600'
  | 'sm:focus:placeholder-yellow-700'
  | 'sm:focus:placeholder-yellow-800'
  | 'sm:focus:placeholder-yellow-900'
  | 'sm:focus:placeholder-green-50'
  | 'sm:focus:placeholder-green-100'
  | 'sm:focus:placeholder-green-200'
  | 'sm:focus:placeholder-green-300'
  | 'sm:focus:placeholder-green-400'
  | 'sm:focus:placeholder-green-500'
  | 'sm:focus:placeholder-green-600'
  | 'sm:focus:placeholder-green-700'
  | 'sm:focus:placeholder-green-800'
  | 'sm:focus:placeholder-green-900'
  | 'sm:focus:placeholder-teal-50'
  | 'sm:focus:placeholder-teal-100'
  | 'sm:focus:placeholder-teal-200'
  | 'sm:focus:placeholder-teal-300'
  | 'sm:focus:placeholder-teal-400'
  | 'sm:focus:placeholder-teal-500'
  | 'sm:focus:placeholder-teal-600'
  | 'sm:focus:placeholder-teal-700'
  | 'sm:focus:placeholder-teal-800'
  | 'sm:focus:placeholder-teal-900'
  | 'sm:focus:placeholder-blue-50'
  | 'sm:focus:placeholder-blue-100'
  | 'sm:focus:placeholder-blue-200'
  | 'sm:focus:placeholder-blue-300'
  | 'sm:focus:placeholder-blue-400'
  | 'sm:focus:placeholder-blue-500'
  | 'sm:focus:placeholder-blue-600'
  | 'sm:focus:placeholder-blue-700'
  | 'sm:focus:placeholder-blue-800'
  | 'sm:focus:placeholder-blue-900'
  | 'sm:focus:placeholder-indigo-50'
  | 'sm:focus:placeholder-indigo-100'
  | 'sm:focus:placeholder-indigo-200'
  | 'sm:focus:placeholder-indigo-300'
  | 'sm:focus:placeholder-indigo-400'
  | 'sm:focus:placeholder-indigo-500'
  | 'sm:focus:placeholder-indigo-600'
  | 'sm:focus:placeholder-indigo-700'
  | 'sm:focus:placeholder-indigo-800'
  | 'sm:focus:placeholder-indigo-900'
  | 'sm:focus:placeholder-purple-50'
  | 'sm:focus:placeholder-purple-100'
  | 'sm:focus:placeholder-purple-200'
  | 'sm:focus:placeholder-purple-300'
  | 'sm:focus:placeholder-purple-400'
  | 'sm:focus:placeholder-purple-500'
  | 'sm:focus:placeholder-purple-600'
  | 'sm:focus:placeholder-purple-700'
  | 'sm:focus:placeholder-purple-800'
  | 'sm:focus:placeholder-purple-900'
  | 'sm:focus:placeholder-pink-50'
  | 'sm:focus:placeholder-pink-100'
  | 'sm:focus:placeholder-pink-200'
  | 'sm:focus:placeholder-pink-300'
  | 'sm:focus:placeholder-pink-400'
  | 'sm:focus:placeholder-pink-500'
  | 'sm:focus:placeholder-pink-600'
  | 'sm:focus:placeholder-pink-700'
  | 'sm:focus:placeholder-pink-800'
  | 'sm:focus:placeholder-pink-900'
  | 'sm:pointer-events-none'
  | 'sm:pointer-events-auto'
  | 'sm:static'
  | 'sm:fixed'
  | 'sm:absolute'
  | 'sm:relative'
  | 'sm:sticky'
  | 'sm:inset-0'
  | 'sm:inset-1'
  | 'sm:inset-2'
  | 'sm:inset-3'
  | 'sm:inset-4'
  | 'sm:inset-5'
  | 'sm:inset-6'
  | 'sm:inset-7'
  | 'sm:inset-8'
  | 'sm:inset-9'
  | 'sm:inset-10'
  | 'sm:inset-11'
  | 'sm:inset-12'
  | 'sm:inset-13'
  | 'sm:inset-14'
  | 'sm:inset-15'
  | 'sm:inset-16'
  | 'sm:inset-20'
  | 'sm:inset-24'
  | 'sm:inset-28'
  | 'sm:inset-32'
  | 'sm:inset-36'
  | 'sm:inset-40'
  | 'sm:inset-48'
  | 'sm:inset-56'
  | 'sm:inset-60'
  | 'sm:inset-64'
  | 'sm:inset-72'
  | 'sm:inset-80'
  | 'sm:inset-96'
  | 'sm:inset-auto'
  | 'sm:inset-px'
  | 'sm:inset-0.5'
  | 'sm:inset-1.5'
  | 'sm:inset-2.5'
  | 'sm:inset-3.5'
  | 'sm:inset-1/2'
  | 'sm:inset-1/3'
  | 'sm:inset-2/3'
  | 'sm:inset-1/4'
  | 'sm:inset-2/4'
  | 'sm:inset-3/4'
  | 'sm:inset-1/5'
  | 'sm:inset-2/5'
  | 'sm:inset-3/5'
  | 'sm:inset-4/5'
  | 'sm:inset-1/6'
  | 'sm:inset-2/6'
  | 'sm:inset-3/6'
  | 'sm:inset-4/6'
  | 'sm:inset-5/6'
  | 'sm:inset-1/12'
  | 'sm:inset-2/12'
  | 'sm:inset-3/12'
  | 'sm:inset-4/12'
  | 'sm:inset-5/12'
  | 'sm:inset-6/12'
  | 'sm:inset-7/12'
  | 'sm:inset-8/12'
  | 'sm:inset-9/12'
  | 'sm:inset-10/12'
  | 'sm:inset-11/12'
  | 'sm:inset-full'
  | 'sm:inset-y-0'
  | 'sm:inset-x-0'
  | 'sm:inset-y-1'
  | 'sm:inset-x-1'
  | 'sm:inset-y-2'
  | 'sm:inset-x-2'
  | 'sm:inset-y-3'
  | 'sm:inset-x-3'
  | 'sm:inset-y-4'
  | 'sm:inset-x-4'
  | 'sm:inset-y-5'
  | 'sm:inset-x-5'
  | 'sm:inset-y-6'
  | 'sm:inset-x-6'
  | 'sm:inset-y-7'
  | 'sm:inset-x-7'
  | 'sm:inset-y-8'
  | 'sm:inset-x-8'
  | 'sm:inset-y-9'
  | 'sm:inset-x-9'
  | 'sm:inset-y-10'
  | 'sm:inset-x-10'
  | 'sm:inset-y-11'
  | 'sm:inset-x-11'
  | 'sm:inset-y-12'
  | 'sm:inset-x-12'
  | 'sm:inset-y-13'
  | 'sm:inset-x-13'
  | 'sm:inset-y-14'
  | 'sm:inset-x-14'
  | 'sm:inset-y-15'
  | 'sm:inset-x-15'
  | 'sm:inset-y-16'
  | 'sm:inset-x-16'
  | 'sm:inset-y-20'
  | 'sm:inset-x-20'
  | 'sm:inset-y-24'
  | 'sm:inset-x-24'
  | 'sm:inset-y-28'
  | 'sm:inset-x-28'
  | 'sm:inset-y-32'
  | 'sm:inset-x-32'
  | 'sm:inset-y-36'
  | 'sm:inset-x-36'
  | 'sm:inset-y-40'
  | 'sm:inset-x-40'
  | 'sm:inset-y-48'
  | 'sm:inset-x-48'
  | 'sm:inset-y-56'
  | 'sm:inset-x-56'
  | 'sm:inset-y-60'
  | 'sm:inset-x-60'
  | 'sm:inset-y-64'
  | 'sm:inset-x-64'
  | 'sm:inset-y-72'
  | 'sm:inset-x-72'
  | 'sm:inset-y-80'
  | 'sm:inset-x-80'
  | 'sm:inset-y-96'
  | 'sm:inset-x-96'
  | 'sm:inset-y-auto'
  | 'sm:inset-x-auto'
  | 'sm:inset-y-px'
  | 'sm:inset-x-px'
  | 'sm:inset-y-0.5'
  | 'sm:inset-x-0.5'
  | 'sm:inset-y-1.5'
  | 'sm:inset-x-1.5'
  | 'sm:inset-y-2.5'
  | 'sm:inset-x-2.5'
  | 'sm:inset-y-3.5'
  | 'sm:inset-x-3.5'
  | 'sm:inset-y-1/2'
  | 'sm:inset-x-1/2'
  | 'sm:inset-y-1/3'
  | 'sm:inset-x-1/3'
  | 'sm:inset-y-2/3'
  | 'sm:inset-x-2/3'
  | 'sm:inset-y-1/4'
  | 'sm:inset-x-1/4'
  | 'sm:inset-y-2/4'
  | 'sm:inset-x-2/4'
  | 'sm:inset-y-3/4'
  | 'sm:inset-x-3/4'
  | 'sm:inset-y-1/5'
  | 'sm:inset-x-1/5'
  | 'sm:inset-y-2/5'
  | 'sm:inset-x-2/5'
  | 'sm:inset-y-3/5'
  | 'sm:inset-x-3/5'
  | 'sm:inset-y-4/5'
  | 'sm:inset-x-4/5'
  | 'sm:inset-y-1/6'
  | 'sm:inset-x-1/6'
  | 'sm:inset-y-2/6'
  | 'sm:inset-x-2/6'
  | 'sm:inset-y-3/6'
  | 'sm:inset-x-3/6'
  | 'sm:inset-y-4/6'
  | 'sm:inset-x-4/6'
  | 'sm:inset-y-5/6'
  | 'sm:inset-x-5/6'
  | 'sm:inset-y-1/12'
  | 'sm:inset-x-1/12'
  | 'sm:inset-y-2/12'
  | 'sm:inset-x-2/12'
  | 'sm:inset-y-3/12'
  | 'sm:inset-x-3/12'
  | 'sm:inset-y-4/12'
  | 'sm:inset-x-4/12'
  | 'sm:inset-y-5/12'
  | 'sm:inset-x-5/12'
  | 'sm:inset-y-6/12'
  | 'sm:inset-x-6/12'
  | 'sm:inset-y-7/12'
  | 'sm:inset-x-7/12'
  | 'sm:inset-y-8/12'
  | 'sm:inset-x-8/12'
  | 'sm:inset-y-9/12'
  | 'sm:inset-x-9/12'
  | 'sm:inset-y-10/12'
  | 'sm:inset-x-10/12'
  | 'sm:inset-y-11/12'
  | 'sm:inset-x-11/12'
  | 'sm:inset-y-full'
  | 'sm:inset-x-full'
  | 'sm:top-0'
  | 'sm:right-0'
  | 'sm:bottom-0'
  | 'sm:left-0'
  | 'sm:top-1'
  | 'sm:right-1'
  | 'sm:bottom-1'
  | 'sm:left-1'
  | 'sm:top-2'
  | 'sm:right-2'
  | 'sm:bottom-2'
  | 'sm:left-2'
  | 'sm:top-3'
  | 'sm:right-3'
  | 'sm:bottom-3'
  | 'sm:left-3'
  | 'sm:top-4'
  | 'sm:right-4'
  | 'sm:bottom-4'
  | 'sm:left-4'
  | 'sm:top-5'
  | 'sm:right-5'
  | 'sm:bottom-5'
  | 'sm:left-5'
  | 'sm:top-6'
  | 'sm:right-6'
  | 'sm:bottom-6'
  | 'sm:left-6'
  | 'sm:top-7'
  | 'sm:right-7'
  | 'sm:bottom-7'
  | 'sm:left-7'
  | 'sm:top-8'
  | 'sm:right-8'
  | 'sm:bottom-8'
  | 'sm:left-8'
  | 'sm:top-9'
  | 'sm:right-9'
  | 'sm:bottom-9'
  | 'sm:left-9'
  | 'sm:top-10'
  | 'sm:right-10'
  | 'sm:bottom-10'
  | 'sm:left-10'
  | 'sm:top-11'
  | 'sm:right-11'
  | 'sm:bottom-11'
  | 'sm:left-11'
  | 'sm:top-12'
  | 'sm:right-12'
  | 'sm:bottom-12'
  | 'sm:left-12'
  | 'sm:top-13'
  | 'sm:right-13'
  | 'sm:bottom-13'
  | 'sm:left-13'
  | 'sm:top-14'
  | 'sm:right-14'
  | 'sm:bottom-14'
  | 'sm:left-14'
  | 'sm:top-15'
  | 'sm:right-15'
  | 'sm:bottom-15'
  | 'sm:left-15'
  | 'sm:top-16'
  | 'sm:right-16'
  | 'sm:bottom-16'
  | 'sm:left-16'
  | 'sm:top-20'
  | 'sm:right-20'
  | 'sm:bottom-20'
  | 'sm:left-20'
  | 'sm:top-24'
  | 'sm:right-24'
  | 'sm:bottom-24'
  | 'sm:left-24'
  | 'sm:top-28'
  | 'sm:right-28'
  | 'sm:bottom-28'
  | 'sm:left-28'
  | 'sm:top-32'
  | 'sm:right-32'
  | 'sm:bottom-32'
  | 'sm:left-32'
  | 'sm:top-36'
  | 'sm:right-36'
  | 'sm:bottom-36'
  | 'sm:left-36'
  | 'sm:top-40'
  | 'sm:right-40'
  | 'sm:bottom-40'
  | 'sm:left-40'
  | 'sm:top-48'
  | 'sm:right-48'
  | 'sm:bottom-48'
  | 'sm:left-48'
  | 'sm:top-56'
  | 'sm:right-56'
  | 'sm:bottom-56'
  | 'sm:left-56'
  | 'sm:top-60'
  | 'sm:right-60'
  | 'sm:bottom-60'
  | 'sm:left-60'
  | 'sm:top-64'
  | 'sm:right-64'
  | 'sm:bottom-64'
  | 'sm:left-64'
  | 'sm:top-72'
  | 'sm:right-72'
  | 'sm:bottom-72'
  | 'sm:left-72'
  | 'sm:top-80'
  | 'sm:right-80'
  | 'sm:bottom-80'
  | 'sm:left-80'
  | 'sm:top-96'
  | 'sm:right-96'
  | 'sm:bottom-96'
  | 'sm:left-96'
  | 'sm:top-auto'
  | 'sm:right-auto'
  | 'sm:bottom-auto'
  | 'sm:left-auto'
  | 'sm:top-px'
  | 'sm:right-px'
  | 'sm:bottom-px'
  | 'sm:left-px'
  | 'sm:top-0.5'
  | 'sm:right-0.5'
  | 'sm:bottom-0.5'
  | 'sm:left-0.5'
  | 'sm:top-1.5'
  | 'sm:right-1.5'
  | 'sm:bottom-1.5'
  | 'sm:left-1.5'
  | 'sm:top-2.5'
  | 'sm:right-2.5'
  | 'sm:bottom-2.5'
  | 'sm:left-2.5'
  | 'sm:top-3.5'
  | 'sm:right-3.5'
  | 'sm:bottom-3.5'
  | 'sm:left-3.5'
  | 'sm:top-1/2'
  | 'sm:right-1/2'
  | 'sm:bottom-1/2'
  | 'sm:left-1/2'
  | 'sm:top-1/3'
  | 'sm:right-1/3'
  | 'sm:bottom-1/3'
  | 'sm:left-1/3'
  | 'sm:top-2/3'
  | 'sm:right-2/3'
  | 'sm:bottom-2/3'
  | 'sm:left-2/3'
  | 'sm:top-1/4'
  | 'sm:right-1/4'
  | 'sm:bottom-1/4'
  | 'sm:left-1/4'
  | 'sm:top-2/4'
  | 'sm:right-2/4'
  | 'sm:bottom-2/4'
  | 'sm:left-2/4'
  | 'sm:top-3/4'
  | 'sm:right-3/4'
  | 'sm:bottom-3/4'
  | 'sm:left-3/4'
  | 'sm:top-1/5'
  | 'sm:right-1/5'
  | 'sm:bottom-1/5'
  | 'sm:left-1/5'
  | 'sm:top-2/5'
  | 'sm:right-2/5'
  | 'sm:bottom-2/5'
  | 'sm:left-2/5'
  | 'sm:top-3/5'
  | 'sm:right-3/5'
  | 'sm:bottom-3/5'
  | 'sm:left-3/5'
  | 'sm:top-4/5'
  | 'sm:right-4/5'
  | 'sm:bottom-4/5'
  | 'sm:left-4/5'
  | 'sm:top-1/6'
  | 'sm:right-1/6'
  | 'sm:bottom-1/6'
  | 'sm:left-1/6'
  | 'sm:top-2/6'
  | 'sm:right-2/6'
  | 'sm:bottom-2/6'
  | 'sm:left-2/6'
  | 'sm:top-3/6'
  | 'sm:right-3/6'
  | 'sm:bottom-3/6'
  | 'sm:left-3/6'
  | 'sm:top-4/6'
  | 'sm:right-4/6'
  | 'sm:bottom-4/6'
  | 'sm:left-4/6'
  | 'sm:top-5/6'
  | 'sm:right-5/6'
  | 'sm:bottom-5/6'
  | 'sm:left-5/6'
  | 'sm:top-1/12'
  | 'sm:right-1/12'
  | 'sm:bottom-1/12'
  | 'sm:left-1/12'
  | 'sm:top-2/12'
  | 'sm:right-2/12'
  | 'sm:bottom-2/12'
  | 'sm:left-2/12'
  | 'sm:top-3/12'
  | 'sm:right-3/12'
  | 'sm:bottom-3/12'
  | 'sm:left-3/12'
  | 'sm:top-4/12'
  | 'sm:right-4/12'
  | 'sm:bottom-4/12'
  | 'sm:left-4/12'
  | 'sm:top-5/12'
  | 'sm:right-5/12'
  | 'sm:bottom-5/12'
  | 'sm:left-5/12'
  | 'sm:top-6/12'
  | 'sm:right-6/12'
  | 'sm:bottom-6/12'
  | 'sm:left-6/12'
  | 'sm:top-7/12'
  | 'sm:right-7/12'
  | 'sm:bottom-7/12'
  | 'sm:left-7/12'
  | 'sm:top-8/12'
  | 'sm:right-8/12'
  | 'sm:bottom-8/12'
  | 'sm:left-8/12'
  | 'sm:top-9/12'
  | 'sm:right-9/12'
  | 'sm:bottom-9/12'
  | 'sm:left-9/12'
  | 'sm:top-10/12'
  | 'sm:right-10/12'
  | 'sm:bottom-10/12'
  | 'sm:left-10/12'
  | 'sm:top-11/12'
  | 'sm:right-11/12'
  | 'sm:bottom-11/12'
  | 'sm:left-11/12'
  | 'sm:top-full'
  | 'sm:right-full'
  | 'sm:bottom-full'
  | 'sm:left-full'
  | 'sm:resize-none'
  | 'sm:resize-y'
  | 'sm:resize-x'
  | 'sm:resize'
  | 'sm:shadow-xs'
  | 'sm:shadow-sm'
  | 'sm:shadow'
  | 'sm:shadow-md'
  | 'sm:shadow-lg'
  | 'sm:shadow-xl'
  | 'sm:shadow-2xl'
  | 'sm:shadow-inner'
  | 'sm:shadow-outline'
  | 'sm:shadow-none'
  | 'sm:shadow-solid'
  | 'sm:shadow-outline-gray'
  | 'sm:shadow-outline-blue'
  | 'sm:shadow-outline-teal'
  | 'sm:shadow-outline-green'
  | 'sm:shadow-outline-yellow'
  | 'sm:shadow-outline-orange'
  | 'sm:shadow-outline-red'
  | 'sm:shadow-outline-pink'
  | 'sm:shadow-outline-purple'
  | 'sm:shadow-outline-indigo'
  | 'sm:hover:shadow-xs'
  | 'sm:hover:shadow-sm'
  | 'sm:hover:shadow'
  | 'sm:hover:shadow-md'
  | 'sm:hover:shadow-lg'
  | 'sm:hover:shadow-xl'
  | 'sm:hover:shadow-2xl'
  | 'sm:hover:shadow-inner'
  | 'sm:hover:shadow-outline'
  | 'sm:hover:shadow-none'
  | 'sm:hover:shadow-solid'
  | 'sm:hover:shadow-outline-gray'
  | 'sm:hover:shadow-outline-blue'
  | 'sm:hover:shadow-outline-teal'
  | 'sm:hover:shadow-outline-green'
  | 'sm:hover:shadow-outline-yellow'
  | 'sm:hover:shadow-outline-orange'
  | 'sm:hover:shadow-outline-red'
  | 'sm:hover:shadow-outline-pink'
  | 'sm:hover:shadow-outline-purple'
  | 'sm:hover:shadow-outline-indigo'
  | 'sm:focus:shadow-xs'
  | 'sm:focus:shadow-sm'
  | 'sm:focus:shadow'
  | 'sm:focus:shadow-md'
  | 'sm:focus:shadow-lg'
  | 'sm:focus:shadow-xl'
  | 'sm:focus:shadow-2xl'
  | 'sm:focus:shadow-inner'
  | 'sm:focus:shadow-outline'
  | 'sm:focus:shadow-none'
  | 'sm:focus:shadow-solid'
  | 'sm:focus:shadow-outline-gray'
  | 'sm:focus:shadow-outline-blue'
  | 'sm:focus:shadow-outline-teal'
  | 'sm:focus:shadow-outline-green'
  | 'sm:focus:shadow-outline-yellow'
  | 'sm:focus:shadow-outline-orange'
  | 'sm:focus:shadow-outline-red'
  | 'sm:focus:shadow-outline-pink'
  | 'sm:focus:shadow-outline-purple'
  | 'sm:focus:shadow-outline-indigo'
  | 'sm:fill-current'
  | 'sm:stroke-current'
  | 'sm:stroke-0'
  | 'sm:stroke-1'
  | 'sm:stroke-2'
  | 'sm:table-auto'
  | 'sm:table-fixed'
  | 'sm:text-left'
  | 'sm:text-center'
  | 'sm:text-right'
  | 'sm:text-justify'
  | 'sm:text-transparent'
  | 'sm:text-white'
  | 'sm:text-black'
  | 'sm:text-gray-50'
  | 'sm:text-gray-100'
  | 'sm:text-gray-200'
  | 'sm:text-gray-300'
  | 'sm:text-gray-400'
  | 'sm:text-gray-500'
  | 'sm:text-gray-600'
  | 'sm:text-gray-700'
  | 'sm:text-gray-800'
  | 'sm:text-gray-900'
  | 'sm:text-cool-gray-50'
  | 'sm:text-cool-gray-100'
  | 'sm:text-cool-gray-200'
  | 'sm:text-cool-gray-300'
  | 'sm:text-cool-gray-400'
  | 'sm:text-cool-gray-500'
  | 'sm:text-cool-gray-600'
  | 'sm:text-cool-gray-700'
  | 'sm:text-cool-gray-800'
  | 'sm:text-cool-gray-900'
  | 'sm:text-red-50'
  | 'sm:text-red-100'
  | 'sm:text-red-200'
  | 'sm:text-red-300'
  | 'sm:text-red-400'
  | 'sm:text-red-500'
  | 'sm:text-red-600'
  | 'sm:text-red-700'
  | 'sm:text-red-800'
  | 'sm:text-red-900'
  | 'sm:text-orange-50'
  | 'sm:text-orange-100'
  | 'sm:text-orange-200'
  | 'sm:text-orange-300'
  | 'sm:text-orange-400'
  | 'sm:text-orange-500'
  | 'sm:text-orange-600'
  | 'sm:text-orange-700'
  | 'sm:text-orange-800'
  | 'sm:text-orange-900'
  | 'sm:text-yellow-50'
  | 'sm:text-yellow-100'
  | 'sm:text-yellow-200'
  | 'sm:text-yellow-300'
  | 'sm:text-yellow-400'
  | 'sm:text-yellow-500'
  | 'sm:text-yellow-600'
  | 'sm:text-yellow-700'
  | 'sm:text-yellow-800'
  | 'sm:text-yellow-900'
  | 'sm:text-green-50'
  | 'sm:text-green-100'
  | 'sm:text-green-200'
  | 'sm:text-green-300'
  | 'sm:text-green-400'
  | 'sm:text-green-500'
  | 'sm:text-green-600'
  | 'sm:text-green-700'
  | 'sm:text-green-800'
  | 'sm:text-green-900'
  | 'sm:text-teal-50'
  | 'sm:text-teal-100'
  | 'sm:text-teal-200'
  | 'sm:text-teal-300'
  | 'sm:text-teal-400'
  | 'sm:text-teal-500'
  | 'sm:text-teal-600'
  | 'sm:text-teal-700'
  | 'sm:text-teal-800'
  | 'sm:text-teal-900'
  | 'sm:text-blue-50'
  | 'sm:text-blue-100'
  | 'sm:text-blue-200'
  | 'sm:text-blue-300'
  | 'sm:text-blue-400'
  | 'sm:text-blue-500'
  | 'sm:text-blue-600'
  | 'sm:text-blue-700'
  | 'sm:text-blue-800'
  | 'sm:text-blue-900'
  | 'sm:text-indigo-50'
  | 'sm:text-indigo-100'
  | 'sm:text-indigo-200'
  | 'sm:text-indigo-300'
  | 'sm:text-indigo-400'
  | 'sm:text-indigo-500'
  | 'sm:text-indigo-600'
  | 'sm:text-indigo-700'
  | 'sm:text-indigo-800'
  | 'sm:text-indigo-900'
  | 'sm:text-purple-50'
  | 'sm:text-purple-100'
  | 'sm:text-purple-200'
  | 'sm:text-purple-300'
  | 'sm:text-purple-400'
  | 'sm:text-purple-500'
  | 'sm:text-purple-600'
  | 'sm:text-purple-700'
  | 'sm:text-purple-800'
  | 'sm:text-purple-900'
  | 'sm:text-pink-50'
  | 'sm:text-pink-100'
  | 'sm:text-pink-200'
  | 'sm:text-pink-300'
  | 'sm:text-pink-400'
  | 'sm:text-pink-500'
  | 'sm:text-pink-600'
  | 'sm:text-pink-700'
  | 'sm:text-pink-800'
  | 'sm:text-pink-900'
  | 'sm:group-hover:text-transparent'
  | 'sm:group-hover:text-white'
  | 'sm:group-hover:text-black'
  | 'sm:group-hover:text-gray-50'
  | 'sm:group-hover:text-gray-100'
  | 'sm:group-hover:text-gray-200'
  | 'sm:group-hover:text-gray-300'
  | 'sm:group-hover:text-gray-400'
  | 'sm:group-hover:text-gray-500'
  | 'sm:group-hover:text-gray-600'
  | 'sm:group-hover:text-gray-700'
  | 'sm:group-hover:text-gray-800'
  | 'sm:group-hover:text-gray-900'
  | 'sm:group-hover:text-cool-gray-50'
  | 'sm:group-hover:text-cool-gray-100'
  | 'sm:group-hover:text-cool-gray-200'
  | 'sm:group-hover:text-cool-gray-300'
  | 'sm:group-hover:text-cool-gray-400'
  | 'sm:group-hover:text-cool-gray-500'
  | 'sm:group-hover:text-cool-gray-600'
  | 'sm:group-hover:text-cool-gray-700'
  | 'sm:group-hover:text-cool-gray-800'
  | 'sm:group-hover:text-cool-gray-900'
  | 'sm:group-hover:text-red-50'
  | 'sm:group-hover:text-red-100'
  | 'sm:group-hover:text-red-200'
  | 'sm:group-hover:text-red-300'
  | 'sm:group-hover:text-red-400'
  | 'sm:group-hover:text-red-500'
  | 'sm:group-hover:text-red-600'
  | 'sm:group-hover:text-red-700'
  | 'sm:group-hover:text-red-800'
  | 'sm:group-hover:text-red-900'
  | 'sm:group-hover:text-orange-50'
  | 'sm:group-hover:text-orange-100'
  | 'sm:group-hover:text-orange-200'
  | 'sm:group-hover:text-orange-300'
  | 'sm:group-hover:text-orange-400'
  | 'sm:group-hover:text-orange-500'
  | 'sm:group-hover:text-orange-600'
  | 'sm:group-hover:text-orange-700'
  | 'sm:group-hover:text-orange-800'
  | 'sm:group-hover:text-orange-900'
  | 'sm:group-hover:text-yellow-50'
  | 'sm:group-hover:text-yellow-100'
  | 'sm:group-hover:text-yellow-200'
  | 'sm:group-hover:text-yellow-300'
  | 'sm:group-hover:text-yellow-400'
  | 'sm:group-hover:text-yellow-500'
  | 'sm:group-hover:text-yellow-600'
  | 'sm:group-hover:text-yellow-700'
  | 'sm:group-hover:text-yellow-800'
  | 'sm:group-hover:text-yellow-900'
  | 'sm:group-hover:text-green-50'
  | 'sm:group-hover:text-green-100'
  | 'sm:group-hover:text-green-200'
  | 'sm:group-hover:text-green-300'
  | 'sm:group-hover:text-green-400'
  | 'sm:group-hover:text-green-500'
  | 'sm:group-hover:text-green-600'
  | 'sm:group-hover:text-green-700'
  | 'sm:group-hover:text-green-800'
  | 'sm:group-hover:text-green-900'
  | 'sm:group-hover:text-teal-50'
  | 'sm:group-hover:text-teal-100'
  | 'sm:group-hover:text-teal-200'
  | 'sm:group-hover:text-teal-300'
  | 'sm:group-hover:text-teal-400'
  | 'sm:group-hover:text-teal-500'
  | 'sm:group-hover:text-teal-600'
  | 'sm:group-hover:text-teal-700'
  | 'sm:group-hover:text-teal-800'
  | 'sm:group-hover:text-teal-900'
  | 'sm:group-hover:text-blue-50'
  | 'sm:group-hover:text-blue-100'
  | 'sm:group-hover:text-blue-200'
  | 'sm:group-hover:text-blue-300'
  | 'sm:group-hover:text-blue-400'
  | 'sm:group-hover:text-blue-500'
  | 'sm:group-hover:text-blue-600'
  | 'sm:group-hover:text-blue-700'
  | 'sm:group-hover:text-blue-800'
  | 'sm:group-hover:text-blue-900'
  | 'sm:group-hover:text-indigo-50'
  | 'sm:group-hover:text-indigo-100'
  | 'sm:group-hover:text-indigo-200'
  | 'sm:group-hover:text-indigo-300'
  | 'sm:group-hover:text-indigo-400'
  | 'sm:group-hover:text-indigo-500'
  | 'sm:group-hover:text-indigo-600'
  | 'sm:group-hover:text-indigo-700'
  | 'sm:group-hover:text-indigo-800'
  | 'sm:group-hover:text-indigo-900'
  | 'sm:group-hover:text-purple-50'
  | 'sm:group-hover:text-purple-100'
  | 'sm:group-hover:text-purple-200'
  | 'sm:group-hover:text-purple-300'
  | 'sm:group-hover:text-purple-400'
  | 'sm:group-hover:text-purple-500'
  | 'sm:group-hover:text-purple-600'
  | 'sm:group-hover:text-purple-700'
  | 'sm:group-hover:text-purple-800'
  | 'sm:group-hover:text-purple-900'
  | 'sm:group-hover:text-pink-50'
  | 'sm:group-hover:text-pink-100'
  | 'sm:group-hover:text-pink-200'
  | 'sm:group-hover:text-pink-300'
  | 'sm:group-hover:text-pink-400'
  | 'sm:group-hover:text-pink-500'
  | 'sm:group-hover:text-pink-600'
  | 'sm:group-hover:text-pink-700'
  | 'sm:group-hover:text-pink-800'
  | 'sm:group-hover:text-pink-900'
  | 'sm:group-focus:text-transparent'
  | 'sm:group-focus:text-white'
  | 'sm:group-focus:text-black'
  | 'sm:group-focus:text-gray-50'
  | 'sm:group-focus:text-gray-100'
  | 'sm:group-focus:text-gray-200'
  | 'sm:group-focus:text-gray-300'
  | 'sm:group-focus:text-gray-400'
  | 'sm:group-focus:text-gray-500'
  | 'sm:group-focus:text-gray-600'
  | 'sm:group-focus:text-gray-700'
  | 'sm:group-focus:text-gray-800'
  | 'sm:group-focus:text-gray-900'
  | 'sm:group-focus:text-cool-gray-50'
  | 'sm:group-focus:text-cool-gray-100'
  | 'sm:group-focus:text-cool-gray-200'
  | 'sm:group-focus:text-cool-gray-300'
  | 'sm:group-focus:text-cool-gray-400'
  | 'sm:group-focus:text-cool-gray-500'
  | 'sm:group-focus:text-cool-gray-600'
  | 'sm:group-focus:text-cool-gray-700'
  | 'sm:group-focus:text-cool-gray-800'
  | 'sm:group-focus:text-cool-gray-900'
  | 'sm:group-focus:text-red-50'
  | 'sm:group-focus:text-red-100'
  | 'sm:group-focus:text-red-200'
  | 'sm:group-focus:text-red-300'
  | 'sm:group-focus:text-red-400'
  | 'sm:group-focus:text-red-500'
  | 'sm:group-focus:text-red-600'
  | 'sm:group-focus:text-red-700'
  | 'sm:group-focus:text-red-800'
  | 'sm:group-focus:text-red-900'
  | 'sm:group-focus:text-orange-50'
  | 'sm:group-focus:text-orange-100'
  | 'sm:group-focus:text-orange-200'
  | 'sm:group-focus:text-orange-300'
  | 'sm:group-focus:text-orange-400'
  | 'sm:group-focus:text-orange-500'
  | 'sm:group-focus:text-orange-600'
  | 'sm:group-focus:text-orange-700'
  | 'sm:group-focus:text-orange-800'
  | 'sm:group-focus:text-orange-900'
  | 'sm:group-focus:text-yellow-50'
  | 'sm:group-focus:text-yellow-100'
  | 'sm:group-focus:text-yellow-200'
  | 'sm:group-focus:text-yellow-300'
  | 'sm:group-focus:text-yellow-400'
  | 'sm:group-focus:text-yellow-500'
  | 'sm:group-focus:text-yellow-600'
  | 'sm:group-focus:text-yellow-700'
  | 'sm:group-focus:text-yellow-800'
  | 'sm:group-focus:text-yellow-900'
  | 'sm:group-focus:text-green-50'
  | 'sm:group-focus:text-green-100'
  | 'sm:group-focus:text-green-200'
  | 'sm:group-focus:text-green-300'
  | 'sm:group-focus:text-green-400'
  | 'sm:group-focus:text-green-500'
  | 'sm:group-focus:text-green-600'
  | 'sm:group-focus:text-green-700'
  | 'sm:group-focus:text-green-800'
  | 'sm:group-focus:text-green-900'
  | 'sm:group-focus:text-teal-50'
  | 'sm:group-focus:text-teal-100'
  | 'sm:group-focus:text-teal-200'
  | 'sm:group-focus:text-teal-300'
  | 'sm:group-focus:text-teal-400'
  | 'sm:group-focus:text-teal-500'
  | 'sm:group-focus:text-teal-600'
  | 'sm:group-focus:text-teal-700'
  | 'sm:group-focus:text-teal-800'
  | 'sm:group-focus:text-teal-900'
  | 'sm:group-focus:text-blue-50'
  | 'sm:group-focus:text-blue-100'
  | 'sm:group-focus:text-blue-200'
  | 'sm:group-focus:text-blue-300'
  | 'sm:group-focus:text-blue-400'
  | 'sm:group-focus:text-blue-500'
  | 'sm:group-focus:text-blue-600'
  | 'sm:group-focus:text-blue-700'
  | 'sm:group-focus:text-blue-800'
  | 'sm:group-focus:text-blue-900'
  | 'sm:group-focus:text-indigo-50'
  | 'sm:group-focus:text-indigo-100'
  | 'sm:group-focus:text-indigo-200'
  | 'sm:group-focus:text-indigo-300'
  | 'sm:group-focus:text-indigo-400'
  | 'sm:group-focus:text-indigo-500'
  | 'sm:group-focus:text-indigo-600'
  | 'sm:group-focus:text-indigo-700'
  | 'sm:group-focus:text-indigo-800'
  | 'sm:group-focus:text-indigo-900'
  | 'sm:group-focus:text-purple-50'
  | 'sm:group-focus:text-purple-100'
  | 'sm:group-focus:text-purple-200'
  | 'sm:group-focus:text-purple-300'
  | 'sm:group-focus:text-purple-400'
  | 'sm:group-focus:text-purple-500'
  | 'sm:group-focus:text-purple-600'
  | 'sm:group-focus:text-purple-700'
  | 'sm:group-focus:text-purple-800'
  | 'sm:group-focus:text-purple-900'
  | 'sm:group-focus:text-pink-50'
  | 'sm:group-focus:text-pink-100'
  | 'sm:group-focus:text-pink-200'
  | 'sm:group-focus:text-pink-300'
  | 'sm:group-focus:text-pink-400'
  | 'sm:group-focus:text-pink-500'
  | 'sm:group-focus:text-pink-600'
  | 'sm:group-focus:text-pink-700'
  | 'sm:group-focus:text-pink-800'
  | 'sm:group-focus:text-pink-900'
  | 'sm:hover:text-transparent'
  | 'sm:hover:text-white'
  | 'sm:hover:text-black'
  | 'sm:hover:text-gray-50'
  | 'sm:hover:text-gray-100'
  | 'sm:hover:text-gray-200'
  | 'sm:hover:text-gray-300'
  | 'sm:hover:text-gray-400'
  | 'sm:hover:text-gray-500'
  | 'sm:hover:text-gray-600'
  | 'sm:hover:text-gray-700'
  | 'sm:hover:text-gray-800'
  | 'sm:hover:text-gray-900'
  | 'sm:hover:text-cool-gray-50'
  | 'sm:hover:text-cool-gray-100'
  | 'sm:hover:text-cool-gray-200'
  | 'sm:hover:text-cool-gray-300'
  | 'sm:hover:text-cool-gray-400'
  | 'sm:hover:text-cool-gray-500'
  | 'sm:hover:text-cool-gray-600'
  | 'sm:hover:text-cool-gray-700'
  | 'sm:hover:text-cool-gray-800'
  | 'sm:hover:text-cool-gray-900'
  | 'sm:hover:text-red-50'
  | 'sm:hover:text-red-100'
  | 'sm:hover:text-red-200'
  | 'sm:hover:text-red-300'
  | 'sm:hover:text-red-400'
  | 'sm:hover:text-red-500'
  | 'sm:hover:text-red-600'
  | 'sm:hover:text-red-700'
  | 'sm:hover:text-red-800'
  | 'sm:hover:text-red-900'
  | 'sm:hover:text-orange-50'
  | 'sm:hover:text-orange-100'
  | 'sm:hover:text-orange-200'
  | 'sm:hover:text-orange-300'
  | 'sm:hover:text-orange-400'
  | 'sm:hover:text-orange-500'
  | 'sm:hover:text-orange-600'
  | 'sm:hover:text-orange-700'
  | 'sm:hover:text-orange-800'
  | 'sm:hover:text-orange-900'
  | 'sm:hover:text-yellow-50'
  | 'sm:hover:text-yellow-100'
  | 'sm:hover:text-yellow-200'
  | 'sm:hover:text-yellow-300'
  | 'sm:hover:text-yellow-400'
  | 'sm:hover:text-yellow-500'
  | 'sm:hover:text-yellow-600'
  | 'sm:hover:text-yellow-700'
  | 'sm:hover:text-yellow-800'
  | 'sm:hover:text-yellow-900'
  | 'sm:hover:text-green-50'
  | 'sm:hover:text-green-100'
  | 'sm:hover:text-green-200'
  | 'sm:hover:text-green-300'
  | 'sm:hover:text-green-400'
  | 'sm:hover:text-green-500'
  | 'sm:hover:text-green-600'
  | 'sm:hover:text-green-700'
  | 'sm:hover:text-green-800'
  | 'sm:hover:text-green-900'
  | 'sm:hover:text-teal-50'
  | 'sm:hover:text-teal-100'
  | 'sm:hover:text-teal-200'
  | 'sm:hover:text-teal-300'
  | 'sm:hover:text-teal-400'
  | 'sm:hover:text-teal-500'
  | 'sm:hover:text-teal-600'
  | 'sm:hover:text-teal-700'
  | 'sm:hover:text-teal-800'
  | 'sm:hover:text-teal-900'
  | 'sm:hover:text-blue-50'
  | 'sm:hover:text-blue-100'
  | 'sm:hover:text-blue-200'
  | 'sm:hover:text-blue-300'
  | 'sm:hover:text-blue-400'
  | 'sm:hover:text-blue-500'
  | 'sm:hover:text-blue-600'
  | 'sm:hover:text-blue-700'
  | 'sm:hover:text-blue-800'
  | 'sm:hover:text-blue-900'
  | 'sm:hover:text-indigo-50'
  | 'sm:hover:text-indigo-100'
  | 'sm:hover:text-indigo-200'
  | 'sm:hover:text-indigo-300'
  | 'sm:hover:text-indigo-400'
  | 'sm:hover:text-indigo-500'
  | 'sm:hover:text-indigo-600'
  | 'sm:hover:text-indigo-700'
  | 'sm:hover:text-indigo-800'
  | 'sm:hover:text-indigo-900'
  | 'sm:hover:text-purple-50'
  | 'sm:hover:text-purple-100'
  | 'sm:hover:text-purple-200'
  | 'sm:hover:text-purple-300'
  | 'sm:hover:text-purple-400'
  | 'sm:hover:text-purple-500'
  | 'sm:hover:text-purple-600'
  | 'sm:hover:text-purple-700'
  | 'sm:hover:text-purple-800'
  | 'sm:hover:text-purple-900'
  | 'sm:hover:text-pink-50'
  | 'sm:hover:text-pink-100'
  | 'sm:hover:text-pink-200'
  | 'sm:hover:text-pink-300'
  | 'sm:hover:text-pink-400'
  | 'sm:hover:text-pink-500'
  | 'sm:hover:text-pink-600'
  | 'sm:hover:text-pink-700'
  | 'sm:hover:text-pink-800'
  | 'sm:hover:text-pink-900'
  | 'sm:focus-within:text-transparent'
  | 'sm:focus-within:text-white'
  | 'sm:focus-within:text-black'
  | 'sm:focus-within:text-gray-50'
  | 'sm:focus-within:text-gray-100'
  | 'sm:focus-within:text-gray-200'
  | 'sm:focus-within:text-gray-300'
  | 'sm:focus-within:text-gray-400'
  | 'sm:focus-within:text-gray-500'
  | 'sm:focus-within:text-gray-600'
  | 'sm:focus-within:text-gray-700'
  | 'sm:focus-within:text-gray-800'
  | 'sm:focus-within:text-gray-900'
  | 'sm:focus-within:text-cool-gray-50'
  | 'sm:focus-within:text-cool-gray-100'
  | 'sm:focus-within:text-cool-gray-200'
  | 'sm:focus-within:text-cool-gray-300'
  | 'sm:focus-within:text-cool-gray-400'
  | 'sm:focus-within:text-cool-gray-500'
  | 'sm:focus-within:text-cool-gray-600'
  | 'sm:focus-within:text-cool-gray-700'
  | 'sm:focus-within:text-cool-gray-800'
  | 'sm:focus-within:text-cool-gray-900'
  | 'sm:focus-within:text-red-50'
  | 'sm:focus-within:text-red-100'
  | 'sm:focus-within:text-red-200'
  | 'sm:focus-within:text-red-300'
  | 'sm:focus-within:text-red-400'
  | 'sm:focus-within:text-red-500'
  | 'sm:focus-within:text-red-600'
  | 'sm:focus-within:text-red-700'
  | 'sm:focus-within:text-red-800'
  | 'sm:focus-within:text-red-900'
  | 'sm:focus-within:text-orange-50'
  | 'sm:focus-within:text-orange-100'
  | 'sm:focus-within:text-orange-200'
  | 'sm:focus-within:text-orange-300'
  | 'sm:focus-within:text-orange-400'
  | 'sm:focus-within:text-orange-500'
  | 'sm:focus-within:text-orange-600'
  | 'sm:focus-within:text-orange-700'
  | 'sm:focus-within:text-orange-800'
  | 'sm:focus-within:text-orange-900'
  | 'sm:focus-within:text-yellow-50'
  | 'sm:focus-within:text-yellow-100'
  | 'sm:focus-within:text-yellow-200'
  | 'sm:focus-within:text-yellow-300'
  | 'sm:focus-within:text-yellow-400'
  | 'sm:focus-within:text-yellow-500'
  | 'sm:focus-within:text-yellow-600'
  | 'sm:focus-within:text-yellow-700'
  | 'sm:focus-within:text-yellow-800'
  | 'sm:focus-within:text-yellow-900'
  | 'sm:focus-within:text-green-50'
  | 'sm:focus-within:text-green-100'
  | 'sm:focus-within:text-green-200'
  | 'sm:focus-within:text-green-300'
  | 'sm:focus-within:text-green-400'
  | 'sm:focus-within:text-green-500'
  | 'sm:focus-within:text-green-600'
  | 'sm:focus-within:text-green-700'
  | 'sm:focus-within:text-green-800'
  | 'sm:focus-within:text-green-900'
  | 'sm:focus-within:text-teal-50'
  | 'sm:focus-within:text-teal-100'
  | 'sm:focus-within:text-teal-200'
  | 'sm:focus-within:text-teal-300'
  | 'sm:focus-within:text-teal-400'
  | 'sm:focus-within:text-teal-500'
  | 'sm:focus-within:text-teal-600'
  | 'sm:focus-within:text-teal-700'
  | 'sm:focus-within:text-teal-800'
  | 'sm:focus-within:text-teal-900'
  | 'sm:focus-within:text-blue-50'
  | 'sm:focus-within:text-blue-100'
  | 'sm:focus-within:text-blue-200'
  | 'sm:focus-within:text-blue-300'
  | 'sm:focus-within:text-blue-400'
  | 'sm:focus-within:text-blue-500'
  | 'sm:focus-within:text-blue-600'
  | 'sm:focus-within:text-blue-700'
  | 'sm:focus-within:text-blue-800'
  | 'sm:focus-within:text-blue-900'
  | 'sm:focus-within:text-indigo-50'
  | 'sm:focus-within:text-indigo-100'
  | 'sm:focus-within:text-indigo-200'
  | 'sm:focus-within:text-indigo-300'
  | 'sm:focus-within:text-indigo-400'
  | 'sm:focus-within:text-indigo-500'
  | 'sm:focus-within:text-indigo-600'
  | 'sm:focus-within:text-indigo-700'
  | 'sm:focus-within:text-indigo-800'
  | 'sm:focus-within:text-indigo-900'
  | 'sm:focus-within:text-purple-50'
  | 'sm:focus-within:text-purple-100'
  | 'sm:focus-within:text-purple-200'
  | 'sm:focus-within:text-purple-300'
  | 'sm:focus-within:text-purple-400'
  | 'sm:focus-within:text-purple-500'
  | 'sm:focus-within:text-purple-600'
  | 'sm:focus-within:text-purple-700'
  | 'sm:focus-within:text-purple-800'
  | 'sm:focus-within:text-purple-900'
  | 'sm:focus-within:text-pink-50'
  | 'sm:focus-within:text-pink-100'
  | 'sm:focus-within:text-pink-200'
  | 'sm:focus-within:text-pink-300'
  | 'sm:focus-within:text-pink-400'
  | 'sm:focus-within:text-pink-500'
  | 'sm:focus-within:text-pink-600'
  | 'sm:focus-within:text-pink-700'
  | 'sm:focus-within:text-pink-800'
  | 'sm:focus-within:text-pink-900'
  | 'sm:focus:text-transparent'
  | 'sm:focus:text-white'
  | 'sm:focus:text-black'
  | 'sm:focus:text-gray-50'
  | 'sm:focus:text-gray-100'
  | 'sm:focus:text-gray-200'
  | 'sm:focus:text-gray-300'
  | 'sm:focus:text-gray-400'
  | 'sm:focus:text-gray-500'
  | 'sm:focus:text-gray-600'
  | 'sm:focus:text-gray-700'
  | 'sm:focus:text-gray-800'
  | 'sm:focus:text-gray-900'
  | 'sm:focus:text-cool-gray-50'
  | 'sm:focus:text-cool-gray-100'
  | 'sm:focus:text-cool-gray-200'
  | 'sm:focus:text-cool-gray-300'
  | 'sm:focus:text-cool-gray-400'
  | 'sm:focus:text-cool-gray-500'
  | 'sm:focus:text-cool-gray-600'
  | 'sm:focus:text-cool-gray-700'
  | 'sm:focus:text-cool-gray-800'
  | 'sm:focus:text-cool-gray-900'
  | 'sm:focus:text-red-50'
  | 'sm:focus:text-red-100'
  | 'sm:focus:text-red-200'
  | 'sm:focus:text-red-300'
  | 'sm:focus:text-red-400'
  | 'sm:focus:text-red-500'
  | 'sm:focus:text-red-600'
  | 'sm:focus:text-red-700'
  | 'sm:focus:text-red-800'
  | 'sm:focus:text-red-900'
  | 'sm:focus:text-orange-50'
  | 'sm:focus:text-orange-100'
  | 'sm:focus:text-orange-200'
  | 'sm:focus:text-orange-300'
  | 'sm:focus:text-orange-400'
  | 'sm:focus:text-orange-500'
  | 'sm:focus:text-orange-600'
  | 'sm:focus:text-orange-700'
  | 'sm:focus:text-orange-800'
  | 'sm:focus:text-orange-900'
  | 'sm:focus:text-yellow-50'
  | 'sm:focus:text-yellow-100'
  | 'sm:focus:text-yellow-200'
  | 'sm:focus:text-yellow-300'
  | 'sm:focus:text-yellow-400'
  | 'sm:focus:text-yellow-500'
  | 'sm:focus:text-yellow-600'
  | 'sm:focus:text-yellow-700'
  | 'sm:focus:text-yellow-800'
  | 'sm:focus:text-yellow-900'
  | 'sm:focus:text-green-50'
  | 'sm:focus:text-green-100'
  | 'sm:focus:text-green-200'
  | 'sm:focus:text-green-300'
  | 'sm:focus:text-green-400'
  | 'sm:focus:text-green-500'
  | 'sm:focus:text-green-600'
  | 'sm:focus:text-green-700'
  | 'sm:focus:text-green-800'
  | 'sm:focus:text-green-900'
  | 'sm:focus:text-teal-50'
  | 'sm:focus:text-teal-100'
  | 'sm:focus:text-teal-200'
  | 'sm:focus:text-teal-300'
  | 'sm:focus:text-teal-400'
  | 'sm:focus:text-teal-500'
  | 'sm:focus:text-teal-600'
  | 'sm:focus:text-teal-700'
  | 'sm:focus:text-teal-800'
  | 'sm:focus:text-teal-900'
  | 'sm:focus:text-blue-50'
  | 'sm:focus:text-blue-100'
  | 'sm:focus:text-blue-200'
  | 'sm:focus:text-blue-300'
  | 'sm:focus:text-blue-400'
  | 'sm:focus:text-blue-500'
  | 'sm:focus:text-blue-600'
  | 'sm:focus:text-blue-700'
  | 'sm:focus:text-blue-800'
  | 'sm:focus:text-blue-900'
  | 'sm:focus:text-indigo-50'
  | 'sm:focus:text-indigo-100'
  | 'sm:focus:text-indigo-200'
  | 'sm:focus:text-indigo-300'
  | 'sm:focus:text-indigo-400'
  | 'sm:focus:text-indigo-500'
  | 'sm:focus:text-indigo-600'
  | 'sm:focus:text-indigo-700'
  | 'sm:focus:text-indigo-800'
  | 'sm:focus:text-indigo-900'
  | 'sm:focus:text-purple-50'
  | 'sm:focus:text-purple-100'
  | 'sm:focus:text-purple-200'
  | 'sm:focus:text-purple-300'
  | 'sm:focus:text-purple-400'
  | 'sm:focus:text-purple-500'
  | 'sm:focus:text-purple-600'
  | 'sm:focus:text-purple-700'
  | 'sm:focus:text-purple-800'
  | 'sm:focus:text-purple-900'
  | 'sm:focus:text-pink-50'
  | 'sm:focus:text-pink-100'
  | 'sm:focus:text-pink-200'
  | 'sm:focus:text-pink-300'
  | 'sm:focus:text-pink-400'
  | 'sm:focus:text-pink-500'
  | 'sm:focus:text-pink-600'
  | 'sm:focus:text-pink-700'
  | 'sm:focus:text-pink-800'
  | 'sm:focus:text-pink-900'
  | 'sm:active:text-transparent'
  | 'sm:active:text-white'
  | 'sm:active:text-black'
  | 'sm:active:text-gray-50'
  | 'sm:active:text-gray-100'
  | 'sm:active:text-gray-200'
  | 'sm:active:text-gray-300'
  | 'sm:active:text-gray-400'
  | 'sm:active:text-gray-500'
  | 'sm:active:text-gray-600'
  | 'sm:active:text-gray-700'
  | 'sm:active:text-gray-800'
  | 'sm:active:text-gray-900'
  | 'sm:active:text-cool-gray-50'
  | 'sm:active:text-cool-gray-100'
  | 'sm:active:text-cool-gray-200'
  | 'sm:active:text-cool-gray-300'
  | 'sm:active:text-cool-gray-400'
  | 'sm:active:text-cool-gray-500'
  | 'sm:active:text-cool-gray-600'
  | 'sm:active:text-cool-gray-700'
  | 'sm:active:text-cool-gray-800'
  | 'sm:active:text-cool-gray-900'
  | 'sm:active:text-red-50'
  | 'sm:active:text-red-100'
  | 'sm:active:text-red-200'
  | 'sm:active:text-red-300'
  | 'sm:active:text-red-400'
  | 'sm:active:text-red-500'
  | 'sm:active:text-red-600'
  | 'sm:active:text-red-700'
  | 'sm:active:text-red-800'
  | 'sm:active:text-red-900'
  | 'sm:active:text-orange-50'
  | 'sm:active:text-orange-100'
  | 'sm:active:text-orange-200'
  | 'sm:active:text-orange-300'
  | 'sm:active:text-orange-400'
  | 'sm:active:text-orange-500'
  | 'sm:active:text-orange-600'
  | 'sm:active:text-orange-700'
  | 'sm:active:text-orange-800'
  | 'sm:active:text-orange-900'
  | 'sm:active:text-yellow-50'
  | 'sm:active:text-yellow-100'
  | 'sm:active:text-yellow-200'
  | 'sm:active:text-yellow-300'
  | 'sm:active:text-yellow-400'
  | 'sm:active:text-yellow-500'
  | 'sm:active:text-yellow-600'
  | 'sm:active:text-yellow-700'
  | 'sm:active:text-yellow-800'
  | 'sm:active:text-yellow-900'
  | 'sm:active:text-green-50'
  | 'sm:active:text-green-100'
  | 'sm:active:text-green-200'
  | 'sm:active:text-green-300'
  | 'sm:active:text-green-400'
  | 'sm:active:text-green-500'
  | 'sm:active:text-green-600'
  | 'sm:active:text-green-700'
  | 'sm:active:text-green-800'
  | 'sm:active:text-green-900'
  | 'sm:active:text-teal-50'
  | 'sm:active:text-teal-100'
  | 'sm:active:text-teal-200'
  | 'sm:active:text-teal-300'
  | 'sm:active:text-teal-400'
  | 'sm:active:text-teal-500'
  | 'sm:active:text-teal-600'
  | 'sm:active:text-teal-700'
  | 'sm:active:text-teal-800'
  | 'sm:active:text-teal-900'
  | 'sm:active:text-blue-50'
  | 'sm:active:text-blue-100'
  | 'sm:active:text-blue-200'
  | 'sm:active:text-blue-300'
  | 'sm:active:text-blue-400'
  | 'sm:active:text-blue-500'
  | 'sm:active:text-blue-600'
  | 'sm:active:text-blue-700'
  | 'sm:active:text-blue-800'
  | 'sm:active:text-blue-900'
  | 'sm:active:text-indigo-50'
  | 'sm:active:text-indigo-100'
  | 'sm:active:text-indigo-200'
  | 'sm:active:text-indigo-300'
  | 'sm:active:text-indigo-400'
  | 'sm:active:text-indigo-500'
  | 'sm:active:text-indigo-600'
  | 'sm:active:text-indigo-700'
  | 'sm:active:text-indigo-800'
  | 'sm:active:text-indigo-900'
  | 'sm:active:text-purple-50'
  | 'sm:active:text-purple-100'
  | 'sm:active:text-purple-200'
  | 'sm:active:text-purple-300'
  | 'sm:active:text-purple-400'
  | 'sm:active:text-purple-500'
  | 'sm:active:text-purple-600'
  | 'sm:active:text-purple-700'
  | 'sm:active:text-purple-800'
  | 'sm:active:text-purple-900'
  | 'sm:active:text-pink-50'
  | 'sm:active:text-pink-100'
  | 'sm:active:text-pink-200'
  | 'sm:active:text-pink-300'
  | 'sm:active:text-pink-400'
  | 'sm:active:text-pink-500'
  | 'sm:active:text-pink-600'
  | 'sm:active:text-pink-700'
  | 'sm:active:text-pink-800'
  | 'sm:active:text-pink-900'
  | 'sm:text-xs'
  | 'sm:text-sm'
  | 'sm:text-base'
  | 'sm:text-lg'
  | 'sm:text-xl'
  | 'sm:text-2xl'
  | 'sm:text-3xl'
  | 'sm:text-4xl'
  | 'sm:text-5xl'
  | 'sm:text-6xl'
  | 'sm:italic'
  | 'sm:not-italic'
  | 'sm:uppercase'
  | 'sm:lowercase'
  | 'sm:capitalize'
  | 'sm:normal-case'
  | 'sm:underline'
  | 'sm:line-through'
  | 'sm:no-underline'
  | 'sm:group-hover:underline'
  | 'sm:group-hover:line-through'
  | 'sm:group-hover:no-underline'
  | 'sm:group-focus:underline'
  | 'sm:group-focus:line-through'
  | 'sm:group-focus:no-underline'
  | 'sm:hover:underline'
  | 'sm:hover:line-through'
  | 'sm:hover:no-underline'
  | 'sm:focus:underline'
  | 'sm:focus:line-through'
  | 'sm:focus:no-underline'
  | 'sm:antialiased'
  | 'sm:subpixel-antialiased'
  | 'sm:tracking-tighter'
  | 'sm:tracking-tight'
  | 'sm:tracking-normal'
  | 'sm:tracking-wide'
  | 'sm:tracking-wider'
  | 'sm:tracking-widest'
  | 'sm:select-none'
  | 'sm:select-text'
  | 'sm:select-all'
  | 'sm:select-auto'
  | 'sm:align-baseline'
  | 'sm:align-top'
  | 'sm:align-middle'
  | 'sm:align-bottom'
  | 'sm:align-text-top'
  | 'sm:align-text-bottom'
  | 'sm:visible'
  | 'sm:invisible'
  | 'sm:whitespace-normal'
  | 'sm:whitespace-no-wrap'
  | 'sm:whitespace-pre'
  | 'sm:whitespace-pre-line'
  | 'sm:whitespace-pre-wrap'
  | 'sm:break-normal'
  | 'sm:break-words'
  | 'sm:break-all'
  | 'sm:truncate'
  | 'sm:w-0'
  | 'sm:w-1'
  | 'sm:w-2'
  | 'sm:w-3'
  | 'sm:w-4'
  | 'sm:w-5'
  | 'sm:w-6'
  | 'sm:w-7'
  | 'sm:w-8'
  | 'sm:w-9'
  | 'sm:w-10'
  | 'sm:w-11'
  | 'sm:w-12'
  | 'sm:w-13'
  | 'sm:w-14'
  | 'sm:w-15'
  | 'sm:w-16'
  | 'sm:w-20'
  | 'sm:w-24'
  | 'sm:w-28'
  | 'sm:w-32'
  | 'sm:w-36'
  | 'sm:w-40'
  | 'sm:w-48'
  | 'sm:w-56'
  | 'sm:w-60'
  | 'sm:w-64'
  | 'sm:w-72'
  | 'sm:w-80'
  | 'sm:w-96'
  | 'sm:w-auto'
  | 'sm:w-px'
  | 'sm:w-0.5'
  | 'sm:w-1.5'
  | 'sm:w-2.5'
  | 'sm:w-3.5'
  | 'sm:w-1/2'
  | 'sm:w-1/3'
  | 'sm:w-2/3'
  | 'sm:w-1/4'
  | 'sm:w-2/4'
  | 'sm:w-3/4'
  | 'sm:w-1/5'
  | 'sm:w-2/5'
  | 'sm:w-3/5'
  | 'sm:w-4/5'
  | 'sm:w-1/6'
  | 'sm:w-2/6'
  | 'sm:w-3/6'
  | 'sm:w-4/6'
  | 'sm:w-5/6'
  | 'sm:w-1/12'
  | 'sm:w-2/12'
  | 'sm:w-3/12'
  | 'sm:w-4/12'
  | 'sm:w-5/12'
  | 'sm:w-6/12'
  | 'sm:w-7/12'
  | 'sm:w-8/12'
  | 'sm:w-9/12'
  | 'sm:w-10/12'
  | 'sm:w-11/12'
  | 'sm:w-full'
  | 'sm:w-screen'
  | 'sm:z-0'
  | 'sm:z-10'
  | 'sm:z-20'
  | 'sm:z-30'
  | 'sm:z-40'
  | 'sm:z-50'
  | 'sm:z-auto'
  | 'sm:focus-within:z-0'
  | 'sm:focus-within:z-10'
  | 'sm:focus-within:z-20'
  | 'sm:focus-within:z-30'
  | 'sm:focus-within:z-40'
  | 'sm:focus-within:z-50'
  | 'sm:focus-within:z-auto'
  | 'sm:focus:z-0'
  | 'sm:focus:z-10'
  | 'sm:focus:z-20'
  | 'sm:focus:z-30'
  | 'sm:focus:z-40'
  | 'sm:focus:z-50'
  | 'sm:focus:z-auto'
  | 'sm:gap-0'
  | 'sm:gap-1'
  | 'sm:gap-2'
  | 'sm:gap-3'
  | 'sm:gap-4'
  | 'sm:gap-5'
  | 'sm:gap-6'
  | 'sm:gap-7'
  | 'sm:gap-8'
  | 'sm:gap-9'
  | 'sm:gap-10'
  | 'sm:gap-11'
  | 'sm:gap-12'
  | 'sm:gap-13'
  | 'sm:gap-14'
  | 'sm:gap-15'
  | 'sm:gap-16'
  | 'sm:gap-20'
  | 'sm:gap-24'
  | 'sm:gap-28'
  | 'sm:gap-32'
  | 'sm:gap-36'
  | 'sm:gap-40'
  | 'sm:gap-48'
  | 'sm:gap-56'
  | 'sm:gap-60'
  | 'sm:gap-64'
  | 'sm:gap-72'
  | 'sm:gap-80'
  | 'sm:gap-96'
  | 'sm:gap-px'
  | 'sm:gap-0.5'
  | 'sm:gap-1.5'
  | 'sm:gap-2.5'
  | 'sm:gap-3.5'
  | 'sm:gap-1/2'
  | 'sm:gap-1/3'
  | 'sm:gap-2/3'
  | 'sm:gap-1/4'
  | 'sm:gap-2/4'
  | 'sm:gap-3/4'
  | 'sm:gap-1/5'
  | 'sm:gap-2/5'
  | 'sm:gap-3/5'
  | 'sm:gap-4/5'
  | 'sm:gap-1/6'
  | 'sm:gap-2/6'
  | 'sm:gap-3/6'
  | 'sm:gap-4/6'
  | 'sm:gap-5/6'
  | 'sm:gap-1/12'
  | 'sm:gap-2/12'
  | 'sm:gap-3/12'
  | 'sm:gap-4/12'
  | 'sm:gap-5/12'
  | 'sm:gap-6/12'
  | 'sm:gap-7/12'
  | 'sm:gap-8/12'
  | 'sm:gap-9/12'
  | 'sm:gap-10/12'
  | 'sm:gap-11/12'
  | 'sm:gap-full'
  | 'sm:col-gap-0'
  | 'sm:col-gap-1'
  | 'sm:col-gap-2'
  | 'sm:col-gap-3'
  | 'sm:col-gap-4'
  | 'sm:col-gap-5'
  | 'sm:col-gap-6'
  | 'sm:col-gap-7'
  | 'sm:col-gap-8'
  | 'sm:col-gap-9'
  | 'sm:col-gap-10'
  | 'sm:col-gap-11'
  | 'sm:col-gap-12'
  | 'sm:col-gap-13'
  | 'sm:col-gap-14'
  | 'sm:col-gap-15'
  | 'sm:col-gap-16'
  | 'sm:col-gap-20'
  | 'sm:col-gap-24'
  | 'sm:col-gap-28'
  | 'sm:col-gap-32'
  | 'sm:col-gap-36'
  | 'sm:col-gap-40'
  | 'sm:col-gap-48'
  | 'sm:col-gap-56'
  | 'sm:col-gap-60'
  | 'sm:col-gap-64'
  | 'sm:col-gap-72'
  | 'sm:col-gap-80'
  | 'sm:col-gap-96'
  | 'sm:col-gap-px'
  | 'sm:col-gap-0.5'
  | 'sm:col-gap-1.5'
  | 'sm:col-gap-2.5'
  | 'sm:col-gap-3.5'
  | 'sm:col-gap-1/2'
  | 'sm:col-gap-1/3'
  | 'sm:col-gap-2/3'
  | 'sm:col-gap-1/4'
  | 'sm:col-gap-2/4'
  | 'sm:col-gap-3/4'
  | 'sm:col-gap-1/5'
  | 'sm:col-gap-2/5'
  | 'sm:col-gap-3/5'
  | 'sm:col-gap-4/5'
  | 'sm:col-gap-1/6'
  | 'sm:col-gap-2/6'
  | 'sm:col-gap-3/6'
  | 'sm:col-gap-4/6'
  | 'sm:col-gap-5/6'
  | 'sm:col-gap-1/12'
  | 'sm:col-gap-2/12'
  | 'sm:col-gap-3/12'
  | 'sm:col-gap-4/12'
  | 'sm:col-gap-5/12'
  | 'sm:col-gap-6/12'
  | 'sm:col-gap-7/12'
  | 'sm:col-gap-8/12'
  | 'sm:col-gap-9/12'
  | 'sm:col-gap-10/12'
  | 'sm:col-gap-11/12'
  | 'sm:col-gap-full'
  | 'sm:row-gap-0'
  | 'sm:row-gap-1'
  | 'sm:row-gap-2'
  | 'sm:row-gap-3'
  | 'sm:row-gap-4'
  | 'sm:row-gap-5'
  | 'sm:row-gap-6'
  | 'sm:row-gap-7'
  | 'sm:row-gap-8'
  | 'sm:row-gap-9'
  | 'sm:row-gap-10'
  | 'sm:row-gap-11'
  | 'sm:row-gap-12'
  | 'sm:row-gap-13'
  | 'sm:row-gap-14'
  | 'sm:row-gap-15'
  | 'sm:row-gap-16'
  | 'sm:row-gap-20'
  | 'sm:row-gap-24'
  | 'sm:row-gap-28'
  | 'sm:row-gap-32'
  | 'sm:row-gap-36'
  | 'sm:row-gap-40'
  | 'sm:row-gap-48'
  | 'sm:row-gap-56'
  | 'sm:row-gap-60'
  | 'sm:row-gap-64'
  | 'sm:row-gap-72'
  | 'sm:row-gap-80'
  | 'sm:row-gap-96'
  | 'sm:row-gap-px'
  | 'sm:row-gap-0.5'
  | 'sm:row-gap-1.5'
  | 'sm:row-gap-2.5'
  | 'sm:row-gap-3.5'
  | 'sm:row-gap-1/2'
  | 'sm:row-gap-1/3'
  | 'sm:row-gap-2/3'
  | 'sm:row-gap-1/4'
  | 'sm:row-gap-2/4'
  | 'sm:row-gap-3/4'
  | 'sm:row-gap-1/5'
  | 'sm:row-gap-2/5'
  | 'sm:row-gap-3/5'
  | 'sm:row-gap-4/5'
  | 'sm:row-gap-1/6'
  | 'sm:row-gap-2/6'
  | 'sm:row-gap-3/6'
  | 'sm:row-gap-4/6'
  | 'sm:row-gap-5/6'
  | 'sm:row-gap-1/12'
  | 'sm:row-gap-2/12'
  | 'sm:row-gap-3/12'
  | 'sm:row-gap-4/12'
  | 'sm:row-gap-5/12'
  | 'sm:row-gap-6/12'
  | 'sm:row-gap-7/12'
  | 'sm:row-gap-8/12'
  | 'sm:row-gap-9/12'
  | 'sm:row-gap-10/12'
  | 'sm:row-gap-11/12'
  | 'sm:row-gap-full'
  | 'sm:grid-flow-row'
  | 'sm:grid-flow-col'
  | 'sm:grid-flow-row-dense'
  | 'sm:grid-flow-col-dense'
  | 'sm:grid-cols-1'
  | 'sm:grid-cols-2'
  | 'sm:grid-cols-3'
  | 'sm:grid-cols-4'
  | 'sm:grid-cols-5'
  | 'sm:grid-cols-6'
  | 'sm:grid-cols-7'
  | 'sm:grid-cols-8'
  | 'sm:grid-cols-9'
  | 'sm:grid-cols-10'
  | 'sm:grid-cols-11'
  | 'sm:grid-cols-12'
  | 'sm:grid-cols-none'
  | 'sm:col-auto'
  | 'sm:col-span-1'
  | 'sm:col-span-2'
  | 'sm:col-span-3'
  | 'sm:col-span-4'
  | 'sm:col-span-5'
  | 'sm:col-span-6'
  | 'sm:col-span-7'
  | 'sm:col-span-8'
  | 'sm:col-span-9'
  | 'sm:col-span-10'
  | 'sm:col-span-11'
  | 'sm:col-span-12'
  | 'sm:col-start-1'
  | 'sm:col-start-2'
  | 'sm:col-start-3'
  | 'sm:col-start-4'
  | 'sm:col-start-5'
  | 'sm:col-start-6'
  | 'sm:col-start-7'
  | 'sm:col-start-8'
  | 'sm:col-start-9'
  | 'sm:col-start-10'
  | 'sm:col-start-11'
  | 'sm:col-start-12'
  | 'sm:col-start-13'
  | 'sm:col-start-auto'
  | 'sm:col-end-1'
  | 'sm:col-end-2'
  | 'sm:col-end-3'
  | 'sm:col-end-4'
  | 'sm:col-end-5'
  | 'sm:col-end-6'
  | 'sm:col-end-7'
  | 'sm:col-end-8'
  | 'sm:col-end-9'
  | 'sm:col-end-10'
  | 'sm:col-end-11'
  | 'sm:col-end-12'
  | 'sm:col-end-13'
  | 'sm:col-end-auto'
  | 'sm:grid-rows-1'
  | 'sm:grid-rows-2'
  | 'sm:grid-rows-3'
  | 'sm:grid-rows-4'
  | 'sm:grid-rows-5'
  | 'sm:grid-rows-6'
  | 'sm:grid-rows-none'
  | 'sm:row-auto'
  | 'sm:row-span-1'
  | 'sm:row-span-2'
  | 'sm:row-span-3'
  | 'sm:row-span-4'
  | 'sm:row-span-5'
  | 'sm:row-span-6'
  | 'sm:row-start-1'
  | 'sm:row-start-2'
  | 'sm:row-start-3'
  | 'sm:row-start-4'
  | 'sm:row-start-5'
  | 'sm:row-start-6'
  | 'sm:row-start-7'
  | 'sm:row-start-auto'
  | 'sm:row-end-1'
  | 'sm:row-end-2'
  | 'sm:row-end-3'
  | 'sm:row-end-4'
  | 'sm:row-end-5'
  | 'sm:row-end-6'
  | 'sm:row-end-7'
  | 'sm:row-end-auto'
  | 'sm:transform'
  | 'sm:transform-none'
  | 'sm:origin-center'
  | 'sm:origin-top'
  | 'sm:origin-top-right'
  | 'sm:origin-right'
  | 'sm:origin-bottom-right'
  | 'sm:origin-bottom'
  | 'sm:origin-bottom-left'
  | 'sm:origin-left'
  | 'sm:origin-top-left'
  | 'sm:scale-0'
  | 'sm:scale-50'
  | 'sm:scale-75'
  | 'sm:scale-90'
  | 'sm:scale-95'
  | 'sm:scale-100'
  | 'sm:scale-105'
  | 'sm:scale-110'
  | 'sm:scale-125'
  | 'sm:scale-150'
  | 'sm:scale-x-0'
  | 'sm:scale-x-50'
  | 'sm:scale-x-75'
  | 'sm:scale-x-90'
  | 'sm:scale-x-95'
  | 'sm:scale-x-100'
  | 'sm:scale-x-105'
  | 'sm:scale-x-110'
  | 'sm:scale-x-125'
  | 'sm:scale-x-150'
  | 'sm:scale-y-0'
  | 'sm:scale-y-50'
  | 'sm:scale-y-75'
  | 'sm:scale-y-90'
  | 'sm:scale-y-95'
  | 'sm:scale-y-100'
  | 'sm:scale-y-105'
  | 'sm:scale-y-110'
  | 'sm:scale-y-125'
  | 'sm:scale-y-150'
  | 'sm:hover:scale-0'
  | 'sm:hover:scale-50'
  | 'sm:hover:scale-75'
  | 'sm:hover:scale-90'
  | 'sm:hover:scale-95'
  | 'sm:hover:scale-100'
  | 'sm:hover:scale-105'
  | 'sm:hover:scale-110'
  | 'sm:hover:scale-125'
  | 'sm:hover:scale-150'
  | 'sm:hover:scale-x-0'
  | 'sm:hover:scale-x-50'
  | 'sm:hover:scale-x-75'
  | 'sm:hover:scale-x-90'
  | 'sm:hover:scale-x-95'
  | 'sm:hover:scale-x-100'
  | 'sm:hover:scale-x-105'
  | 'sm:hover:scale-x-110'
  | 'sm:hover:scale-x-125'
  | 'sm:hover:scale-x-150'
  | 'sm:hover:scale-y-0'
  | 'sm:hover:scale-y-50'
  | 'sm:hover:scale-y-75'
  | 'sm:hover:scale-y-90'
  | 'sm:hover:scale-y-95'
  | 'sm:hover:scale-y-100'
  | 'sm:hover:scale-y-105'
  | 'sm:hover:scale-y-110'
  | 'sm:hover:scale-y-125'
  | 'sm:hover:scale-y-150'
  | 'sm:focus:scale-0'
  | 'sm:focus:scale-50'
  | 'sm:focus:scale-75'
  | 'sm:focus:scale-90'
  | 'sm:focus:scale-95'
  | 'sm:focus:scale-100'
  | 'sm:focus:scale-105'
  | 'sm:focus:scale-110'
  | 'sm:focus:scale-125'
  | 'sm:focus:scale-150'
  | 'sm:focus:scale-x-0'
  | 'sm:focus:scale-x-50'
  | 'sm:focus:scale-x-75'
  | 'sm:focus:scale-x-90'
  | 'sm:focus:scale-x-95'
  | 'sm:focus:scale-x-100'
  | 'sm:focus:scale-x-105'
  | 'sm:focus:scale-x-110'
  | 'sm:focus:scale-x-125'
  | 'sm:focus:scale-x-150'
  | 'sm:focus:scale-y-0'
  | 'sm:focus:scale-y-50'
  | 'sm:focus:scale-y-75'
  | 'sm:focus:scale-y-90'
  | 'sm:focus:scale-y-95'
  | 'sm:focus:scale-y-100'
  | 'sm:focus:scale-y-105'
  | 'sm:focus:scale-y-110'
  | 'sm:focus:scale-y-125'
  | 'sm:focus:scale-y-150'
  | 'sm:rotate-0'
  | 'sm:rotate-45'
  | 'sm:rotate-90'
  | 'sm:rotate-180'
  | 'sm:-rotate-180'
  | 'sm:-rotate-90'
  | 'sm:-rotate-45'
  | 'sm:hover:rotate-0'
  | 'sm:hover:rotate-45'
  | 'sm:hover:rotate-90'
  | 'sm:hover:rotate-180'
  | 'sm:hover:-rotate-180'
  | 'sm:hover:-rotate-90'
  | 'sm:hover:-rotate-45'
  | 'sm:focus:rotate-0'
  | 'sm:focus:rotate-45'
  | 'sm:focus:rotate-90'
  | 'sm:focus:rotate-180'
  | 'sm:focus:-rotate-180'
  | 'sm:focus:-rotate-90'
  | 'sm:focus:-rotate-45'
  | 'sm:translate-x-0'
  | 'sm:translate-x-1'
  | 'sm:translate-x-2'
  | 'sm:translate-x-3'
  | 'sm:translate-x-4'
  | 'sm:translate-x-5'
  | 'sm:translate-x-6'
  | 'sm:translate-x-7'
  | 'sm:translate-x-8'
  | 'sm:translate-x-9'
  | 'sm:translate-x-10'
  | 'sm:translate-x-11'
  | 'sm:translate-x-12'
  | 'sm:translate-x-13'
  | 'sm:translate-x-14'
  | 'sm:translate-x-15'
  | 'sm:translate-x-16'
  | 'sm:translate-x-20'
  | 'sm:translate-x-24'
  | 'sm:translate-x-28'
  | 'sm:translate-x-32'
  | 'sm:translate-x-36'
  | 'sm:translate-x-40'
  | 'sm:translate-x-48'
  | 'sm:translate-x-56'
  | 'sm:translate-x-60'
  | 'sm:translate-x-64'
  | 'sm:translate-x-72'
  | 'sm:translate-x-80'
  | 'sm:translate-x-96'
  | 'sm:translate-x-px'
  | 'sm:translate-x-0.5'
  | 'sm:translate-x-1.5'
  | 'sm:translate-x-2.5'
  | 'sm:translate-x-3.5'
  | 'sm:translate-x-1/2'
  | 'sm:translate-x-1/3'
  | 'sm:translate-x-2/3'
  | 'sm:translate-x-1/4'
  | 'sm:translate-x-2/4'
  | 'sm:translate-x-3/4'
  | 'sm:translate-x-1/5'
  | 'sm:translate-x-2/5'
  | 'sm:translate-x-3/5'
  | 'sm:translate-x-4/5'
  | 'sm:translate-x-1/6'
  | 'sm:translate-x-2/6'
  | 'sm:translate-x-3/6'
  | 'sm:translate-x-4/6'
  | 'sm:translate-x-5/6'
  | 'sm:translate-x-1/12'
  | 'sm:translate-x-2/12'
  | 'sm:translate-x-3/12'
  | 'sm:translate-x-4/12'
  | 'sm:translate-x-5/12'
  | 'sm:translate-x-6/12'
  | 'sm:translate-x-7/12'
  | 'sm:translate-x-8/12'
  | 'sm:translate-x-9/12'
  | 'sm:translate-x-10/12'
  | 'sm:translate-x-11/12'
  | 'sm:translate-x-full'
  | 'sm:-translate-x-1'
  | 'sm:-translate-x-2'
  | 'sm:-translate-x-3'
  | 'sm:-translate-x-4'
  | 'sm:-translate-x-5'
  | 'sm:-translate-x-6'
  | 'sm:-translate-x-7'
  | 'sm:-translate-x-8'
  | 'sm:-translate-x-9'
  | 'sm:-translate-x-10'
  | 'sm:-translate-x-11'
  | 'sm:-translate-x-12'
  | 'sm:-translate-x-13'
  | 'sm:-translate-x-14'
  | 'sm:-translate-x-15'
  | 'sm:-translate-x-16'
  | 'sm:-translate-x-20'
  | 'sm:-translate-x-24'
  | 'sm:-translate-x-28'
  | 'sm:-translate-x-32'
  | 'sm:-translate-x-36'
  | 'sm:-translate-x-40'
  | 'sm:-translate-x-48'
  | 'sm:-translate-x-56'
  | 'sm:-translate-x-60'
  | 'sm:-translate-x-64'
  | 'sm:-translate-x-72'
  | 'sm:-translate-x-80'
  | 'sm:-translate-x-96'
  | 'sm:-translate-x-px'
  | 'sm:-translate-x-0.5'
  | 'sm:-translate-x-1.5'
  | 'sm:-translate-x-2.5'
  | 'sm:-translate-x-3.5'
  | 'sm:-translate-x-1/2'
  | 'sm:-translate-x-1/3'
  | 'sm:-translate-x-2/3'
  | 'sm:-translate-x-1/4'
  | 'sm:-translate-x-2/4'
  | 'sm:-translate-x-3/4'
  | 'sm:-translate-x-1/5'
  | 'sm:-translate-x-2/5'
  | 'sm:-translate-x-3/5'
  | 'sm:-translate-x-4/5'
  | 'sm:-translate-x-1/6'
  | 'sm:-translate-x-2/6'
  | 'sm:-translate-x-3/6'
  | 'sm:-translate-x-4/6'
  | 'sm:-translate-x-5/6'
  | 'sm:-translate-x-1/12'
  | 'sm:-translate-x-2/12'
  | 'sm:-translate-x-3/12'
  | 'sm:-translate-x-4/12'
  | 'sm:-translate-x-5/12'
  | 'sm:-translate-x-6/12'
  | 'sm:-translate-x-7/12'
  | 'sm:-translate-x-8/12'
  | 'sm:-translate-x-9/12'
  | 'sm:-translate-x-10/12'
  | 'sm:-translate-x-11/12'
  | 'sm:-translate-x-full'
  | 'sm:translate-y-0'
  | 'sm:translate-y-1'
  | 'sm:translate-y-2'
  | 'sm:translate-y-3'
  | 'sm:translate-y-4'
  | 'sm:translate-y-5'
  | 'sm:translate-y-6'
  | 'sm:translate-y-7'
  | 'sm:translate-y-8'
  | 'sm:translate-y-9'
  | 'sm:translate-y-10'
  | 'sm:translate-y-11'
  | 'sm:translate-y-12'
  | 'sm:translate-y-13'
  | 'sm:translate-y-14'
  | 'sm:translate-y-15'
  | 'sm:translate-y-16'
  | 'sm:translate-y-20'
  | 'sm:translate-y-24'
  | 'sm:translate-y-28'
  | 'sm:translate-y-32'
  | 'sm:translate-y-36'
  | 'sm:translate-y-40'
  | 'sm:translate-y-48'
  | 'sm:translate-y-56'
  | 'sm:translate-y-60'
  | 'sm:translate-y-64'
  | 'sm:translate-y-72'
  | 'sm:translate-y-80'
  | 'sm:translate-y-96'
  | 'sm:translate-y-px'
  | 'sm:translate-y-0.5'
  | 'sm:translate-y-1.5'
  | 'sm:translate-y-2.5'
  | 'sm:translate-y-3.5'
  | 'sm:translate-y-1/2'
  | 'sm:translate-y-1/3'
  | 'sm:translate-y-2/3'
  | 'sm:translate-y-1/4'
  | 'sm:translate-y-2/4'
  | 'sm:translate-y-3/4'
  | 'sm:translate-y-1/5'
  | 'sm:translate-y-2/5'
  | 'sm:translate-y-3/5'
  | 'sm:translate-y-4/5'
  | 'sm:translate-y-1/6'
  | 'sm:translate-y-2/6'
  | 'sm:translate-y-3/6'
  | 'sm:translate-y-4/6'
  | 'sm:translate-y-5/6'
  | 'sm:translate-y-1/12'
  | 'sm:translate-y-2/12'
  | 'sm:translate-y-3/12'
  | 'sm:translate-y-4/12'
  | 'sm:translate-y-5/12'
  | 'sm:translate-y-6/12'
  | 'sm:translate-y-7/12'
  | 'sm:translate-y-8/12'
  | 'sm:translate-y-9/12'
  | 'sm:translate-y-10/12'
  | 'sm:translate-y-11/12'
  | 'sm:translate-y-full'
  | 'sm:-translate-y-1'
  | 'sm:-translate-y-2'
  | 'sm:-translate-y-3'
  | 'sm:-translate-y-4'
  | 'sm:-translate-y-5'
  | 'sm:-translate-y-6'
  | 'sm:-translate-y-7'
  | 'sm:-translate-y-8'
  | 'sm:-translate-y-9'
  | 'sm:-translate-y-10'
  | 'sm:-translate-y-11'
  | 'sm:-translate-y-12'
  | 'sm:-translate-y-13'
  | 'sm:-translate-y-14'
  | 'sm:-translate-y-15'
  | 'sm:-translate-y-16'
  | 'sm:-translate-y-20'
  | 'sm:-translate-y-24'
  | 'sm:-translate-y-28'
  | 'sm:-translate-y-32'
  | 'sm:-translate-y-36'
  | 'sm:-translate-y-40'
  | 'sm:-translate-y-48'
  | 'sm:-translate-y-56'
  | 'sm:-translate-y-60'
  | 'sm:-translate-y-64'
  | 'sm:-translate-y-72'
  | 'sm:-translate-y-80'
  | 'sm:-translate-y-96'
  | 'sm:-translate-y-px'
  | 'sm:-translate-y-0.5'
  | 'sm:-translate-y-1.5'
  | 'sm:-translate-y-2.5'
  | 'sm:-translate-y-3.5'
  | 'sm:-translate-y-1/2'
  | 'sm:-translate-y-1/3'
  | 'sm:-translate-y-2/3'
  | 'sm:-translate-y-1/4'
  | 'sm:-translate-y-2/4'
  | 'sm:-translate-y-3/4'
  | 'sm:-translate-y-1/5'
  | 'sm:-translate-y-2/5'
  | 'sm:-translate-y-3/5'
  | 'sm:-translate-y-4/5'
  | 'sm:-translate-y-1/6'
  | 'sm:-translate-y-2/6'
  | 'sm:-translate-y-3/6'
  | 'sm:-translate-y-4/6'
  | 'sm:-translate-y-5/6'
  | 'sm:-translate-y-1/12'
  | 'sm:-translate-y-2/12'
  | 'sm:-translate-y-3/12'
  | 'sm:-translate-y-4/12'
  | 'sm:-translate-y-5/12'
  | 'sm:-translate-y-6/12'
  | 'sm:-translate-y-7/12'
  | 'sm:-translate-y-8/12'
  | 'sm:-translate-y-9/12'
  | 'sm:-translate-y-10/12'
  | 'sm:-translate-y-11/12'
  | 'sm:-translate-y-full'
  | 'sm:hover:translate-x-0'
  | 'sm:hover:translate-x-1'
  | 'sm:hover:translate-x-2'
  | 'sm:hover:translate-x-3'
  | 'sm:hover:translate-x-4'
  | 'sm:hover:translate-x-5'
  | 'sm:hover:translate-x-6'
  | 'sm:hover:translate-x-7'
  | 'sm:hover:translate-x-8'
  | 'sm:hover:translate-x-9'
  | 'sm:hover:translate-x-10'
  | 'sm:hover:translate-x-11'
  | 'sm:hover:translate-x-12'
  | 'sm:hover:translate-x-13'
  | 'sm:hover:translate-x-14'
  | 'sm:hover:translate-x-15'
  | 'sm:hover:translate-x-16'
  | 'sm:hover:translate-x-20'
  | 'sm:hover:translate-x-24'
  | 'sm:hover:translate-x-28'
  | 'sm:hover:translate-x-32'
  | 'sm:hover:translate-x-36'
  | 'sm:hover:translate-x-40'
  | 'sm:hover:translate-x-48'
  | 'sm:hover:translate-x-56'
  | 'sm:hover:translate-x-60'
  | 'sm:hover:translate-x-64'
  | 'sm:hover:translate-x-72'
  | 'sm:hover:translate-x-80'
  | 'sm:hover:translate-x-96'
  | 'sm:hover:translate-x-px'
  | 'sm:hover:translate-x-0.5'
  | 'sm:hover:translate-x-1.5'
  | 'sm:hover:translate-x-2.5'
  | 'sm:hover:translate-x-3.5'
  | 'sm:hover:translate-x-1/2'
  | 'sm:hover:translate-x-1/3'
  | 'sm:hover:translate-x-2/3'
  | 'sm:hover:translate-x-1/4'
  | 'sm:hover:translate-x-2/4'
  | 'sm:hover:translate-x-3/4'
  | 'sm:hover:translate-x-1/5'
  | 'sm:hover:translate-x-2/5'
  | 'sm:hover:translate-x-3/5'
  | 'sm:hover:translate-x-4/5'
  | 'sm:hover:translate-x-1/6'
  | 'sm:hover:translate-x-2/6'
  | 'sm:hover:translate-x-3/6'
  | 'sm:hover:translate-x-4/6'
  | 'sm:hover:translate-x-5/6'
  | 'sm:hover:translate-x-1/12'
  | 'sm:hover:translate-x-2/12'
  | 'sm:hover:translate-x-3/12'
  | 'sm:hover:translate-x-4/12'
  | 'sm:hover:translate-x-5/12'
  | 'sm:hover:translate-x-6/12'
  | 'sm:hover:translate-x-7/12'
  | 'sm:hover:translate-x-8/12'
  | 'sm:hover:translate-x-9/12'
  | 'sm:hover:translate-x-10/12'
  | 'sm:hover:translate-x-11/12'
  | 'sm:hover:translate-x-full'
  | 'sm:hover:-translate-x-1'
  | 'sm:hover:-translate-x-2'
  | 'sm:hover:-translate-x-3'
  | 'sm:hover:-translate-x-4'
  | 'sm:hover:-translate-x-5'
  | 'sm:hover:-translate-x-6'
  | 'sm:hover:-translate-x-7'
  | 'sm:hover:-translate-x-8'
  | 'sm:hover:-translate-x-9'
  | 'sm:hover:-translate-x-10'
  | 'sm:hover:-translate-x-11'
  | 'sm:hover:-translate-x-12'
  | 'sm:hover:-translate-x-13'
  | 'sm:hover:-translate-x-14'
  | 'sm:hover:-translate-x-15'
  | 'sm:hover:-translate-x-16'
  | 'sm:hover:-translate-x-20'
  | 'sm:hover:-translate-x-24'
  | 'sm:hover:-translate-x-28'
  | 'sm:hover:-translate-x-32'
  | 'sm:hover:-translate-x-36'
  | 'sm:hover:-translate-x-40'
  | 'sm:hover:-translate-x-48'
  | 'sm:hover:-translate-x-56'
  | 'sm:hover:-translate-x-60'
  | 'sm:hover:-translate-x-64'
  | 'sm:hover:-translate-x-72'
  | 'sm:hover:-translate-x-80'
  | 'sm:hover:-translate-x-96'
  | 'sm:hover:-translate-x-px'
  | 'sm:hover:-translate-x-0.5'
  | 'sm:hover:-translate-x-1.5'
  | 'sm:hover:-translate-x-2.5'
  | 'sm:hover:-translate-x-3.5'
  | 'sm:hover:-translate-x-1/2'
  | 'sm:hover:-translate-x-1/3'
  | 'sm:hover:-translate-x-2/3'
  | 'sm:hover:-translate-x-1/4'
  | 'sm:hover:-translate-x-2/4'
  | 'sm:hover:-translate-x-3/4'
  | 'sm:hover:-translate-x-1/5'
  | 'sm:hover:-translate-x-2/5'
  | 'sm:hover:-translate-x-3/5'
  | 'sm:hover:-translate-x-4/5'
  | 'sm:hover:-translate-x-1/6'
  | 'sm:hover:-translate-x-2/6'
  | 'sm:hover:-translate-x-3/6'
  | 'sm:hover:-translate-x-4/6'
  | 'sm:hover:-translate-x-5/6'
  | 'sm:hover:-translate-x-1/12'
  | 'sm:hover:-translate-x-2/12'
  | 'sm:hover:-translate-x-3/12'
  | 'sm:hover:-translate-x-4/12'
  | 'sm:hover:-translate-x-5/12'
  | 'sm:hover:-translate-x-6/12'
  | 'sm:hover:-translate-x-7/12'
  | 'sm:hover:-translate-x-8/12'
  | 'sm:hover:-translate-x-9/12'
  | 'sm:hover:-translate-x-10/12'
  | 'sm:hover:-translate-x-11/12'
  | 'sm:hover:-translate-x-full'
  | 'sm:hover:translate-y-0'
  | 'sm:hover:translate-y-1'
  | 'sm:hover:translate-y-2'
  | 'sm:hover:translate-y-3'
  | 'sm:hover:translate-y-4'
  | 'sm:hover:translate-y-5'
  | 'sm:hover:translate-y-6'
  | 'sm:hover:translate-y-7'
  | 'sm:hover:translate-y-8'
  | 'sm:hover:translate-y-9'
  | 'sm:hover:translate-y-10'
  | 'sm:hover:translate-y-11'
  | 'sm:hover:translate-y-12'
  | 'sm:hover:translate-y-13'
  | 'sm:hover:translate-y-14'
  | 'sm:hover:translate-y-15'
  | 'sm:hover:translate-y-16'
  | 'sm:hover:translate-y-20'
  | 'sm:hover:translate-y-24'
  | 'sm:hover:translate-y-28'
  | 'sm:hover:translate-y-32'
  | 'sm:hover:translate-y-36'
  | 'sm:hover:translate-y-40'
  | 'sm:hover:translate-y-48'
  | 'sm:hover:translate-y-56'
  | 'sm:hover:translate-y-60'
  | 'sm:hover:translate-y-64'
  | 'sm:hover:translate-y-72'
  | 'sm:hover:translate-y-80'
  | 'sm:hover:translate-y-96'
  | 'sm:hover:translate-y-px'
  | 'sm:hover:translate-y-0.5'
  | 'sm:hover:translate-y-1.5'
  | 'sm:hover:translate-y-2.5'
  | 'sm:hover:translate-y-3.5'
  | 'sm:hover:translate-y-1/2'
  | 'sm:hover:translate-y-1/3'
  | 'sm:hover:translate-y-2/3'
  | 'sm:hover:translate-y-1/4'
  | 'sm:hover:translate-y-2/4'
  | 'sm:hover:translate-y-3/4'
  | 'sm:hover:translate-y-1/5'
  | 'sm:hover:translate-y-2/5'
  | 'sm:hover:translate-y-3/5'
  | 'sm:hover:translate-y-4/5'
  | 'sm:hover:translate-y-1/6'
  | 'sm:hover:translate-y-2/6'
  | 'sm:hover:translate-y-3/6'
  | 'sm:hover:translate-y-4/6'
  | 'sm:hover:translate-y-5/6'
  | 'sm:hover:translate-y-1/12'
  | 'sm:hover:translate-y-2/12'
  | 'sm:hover:translate-y-3/12'
  | 'sm:hover:translate-y-4/12'
  | 'sm:hover:translate-y-5/12'
  | 'sm:hover:translate-y-6/12'
  | 'sm:hover:translate-y-7/12'
  | 'sm:hover:translate-y-8/12'
  | 'sm:hover:translate-y-9/12'
  | 'sm:hover:translate-y-10/12'
  | 'sm:hover:translate-y-11/12'
  | 'sm:hover:translate-y-full'
  | 'sm:hover:-translate-y-1'
  | 'sm:hover:-translate-y-2'
  | 'sm:hover:-translate-y-3'
  | 'sm:hover:-translate-y-4'
  | 'sm:hover:-translate-y-5'
  | 'sm:hover:-translate-y-6'
  | 'sm:hover:-translate-y-7'
  | 'sm:hover:-translate-y-8'
  | 'sm:hover:-translate-y-9'
  | 'sm:hover:-translate-y-10'
  | 'sm:hover:-translate-y-11'
  | 'sm:hover:-translate-y-12'
  | 'sm:hover:-translate-y-13'
  | 'sm:hover:-translate-y-14'
  | 'sm:hover:-translate-y-15'
  | 'sm:hover:-translate-y-16'
  | 'sm:hover:-translate-y-20'
  | 'sm:hover:-translate-y-24'
  | 'sm:hover:-translate-y-28'
  | 'sm:hover:-translate-y-32'
  | 'sm:hover:-translate-y-36'
  | 'sm:hover:-translate-y-40'
  | 'sm:hover:-translate-y-48'
  | 'sm:hover:-translate-y-56'
  | 'sm:hover:-translate-y-60'
  | 'sm:hover:-translate-y-64'
  | 'sm:hover:-translate-y-72'
  | 'sm:hover:-translate-y-80'
  | 'sm:hover:-translate-y-96'
  | 'sm:hover:-translate-y-px'
  | 'sm:hover:-translate-y-0.5'
  | 'sm:hover:-translate-y-1.5'
  | 'sm:hover:-translate-y-2.5'
  | 'sm:hover:-translate-y-3.5'
  | 'sm:hover:-translate-y-1/2'
  | 'sm:hover:-translate-y-1/3'
  | 'sm:hover:-translate-y-2/3'
  | 'sm:hover:-translate-y-1/4'
  | 'sm:hover:-translate-y-2/4'
  | 'sm:hover:-translate-y-3/4'
  | 'sm:hover:-translate-y-1/5'
  | 'sm:hover:-translate-y-2/5'
  | 'sm:hover:-translate-y-3/5'
  | 'sm:hover:-translate-y-4/5'
  | 'sm:hover:-translate-y-1/6'
  | 'sm:hover:-translate-y-2/6'
  | 'sm:hover:-translate-y-3/6'
  | 'sm:hover:-translate-y-4/6'
  | 'sm:hover:-translate-y-5/6'
  | 'sm:hover:-translate-y-1/12'
  | 'sm:hover:-translate-y-2/12'
  | 'sm:hover:-translate-y-3/12'
  | 'sm:hover:-translate-y-4/12'
  | 'sm:hover:-translate-y-5/12'
  | 'sm:hover:-translate-y-6/12'
  | 'sm:hover:-translate-y-7/12'
  | 'sm:hover:-translate-y-8/12'
  | 'sm:hover:-translate-y-9/12'
  | 'sm:hover:-translate-y-10/12'
  | 'sm:hover:-translate-y-11/12'
  | 'sm:hover:-translate-y-full'
  | 'sm:focus:translate-x-0'
  | 'sm:focus:translate-x-1'
  | 'sm:focus:translate-x-2'
  | 'sm:focus:translate-x-3'
  | 'sm:focus:translate-x-4'
  | 'sm:focus:translate-x-5'
  | 'sm:focus:translate-x-6'
  | 'sm:focus:translate-x-7'
  | 'sm:focus:translate-x-8'
  | 'sm:focus:translate-x-9'
  | 'sm:focus:translate-x-10'
  | 'sm:focus:translate-x-11'
  | 'sm:focus:translate-x-12'
  | 'sm:focus:translate-x-13'
  | 'sm:focus:translate-x-14'
  | 'sm:focus:translate-x-15'
  | 'sm:focus:translate-x-16'
  | 'sm:focus:translate-x-20'
  | 'sm:focus:translate-x-24'
  | 'sm:focus:translate-x-28'
  | 'sm:focus:translate-x-32'
  | 'sm:focus:translate-x-36'
  | 'sm:focus:translate-x-40'
  | 'sm:focus:translate-x-48'
  | 'sm:focus:translate-x-56'
  | 'sm:focus:translate-x-60'
  | 'sm:focus:translate-x-64'
  | 'sm:focus:translate-x-72'
  | 'sm:focus:translate-x-80'
  | 'sm:focus:translate-x-96'
  | 'sm:focus:translate-x-px'
  | 'sm:focus:translate-x-0.5'
  | 'sm:focus:translate-x-1.5'
  | 'sm:focus:translate-x-2.5'
  | 'sm:focus:translate-x-3.5'
  | 'sm:focus:translate-x-1/2'
  | 'sm:focus:translate-x-1/3'
  | 'sm:focus:translate-x-2/3'
  | 'sm:focus:translate-x-1/4'
  | 'sm:focus:translate-x-2/4'
  | 'sm:focus:translate-x-3/4'
  | 'sm:focus:translate-x-1/5'
  | 'sm:focus:translate-x-2/5'
  | 'sm:focus:translate-x-3/5'
  | 'sm:focus:translate-x-4/5'
  | 'sm:focus:translate-x-1/6'
  | 'sm:focus:translate-x-2/6'
  | 'sm:focus:translate-x-3/6'
  | 'sm:focus:translate-x-4/6'
  | 'sm:focus:translate-x-5/6'
  | 'sm:focus:translate-x-1/12'
  | 'sm:focus:translate-x-2/12'
  | 'sm:focus:translate-x-3/12'
  | 'sm:focus:translate-x-4/12'
  | 'sm:focus:translate-x-5/12'
  | 'sm:focus:translate-x-6/12'
  | 'sm:focus:translate-x-7/12'
  | 'sm:focus:translate-x-8/12'
  | 'sm:focus:translate-x-9/12'
  | 'sm:focus:translate-x-10/12'
  | 'sm:focus:translate-x-11/12'
  | 'sm:focus:translate-x-full'
  | 'sm:focus:-translate-x-1'
  | 'sm:focus:-translate-x-2'
  | 'sm:focus:-translate-x-3'
  | 'sm:focus:-translate-x-4'
  | 'sm:focus:-translate-x-5'
  | 'sm:focus:-translate-x-6'
  | 'sm:focus:-translate-x-7'
  | 'sm:focus:-translate-x-8'
  | 'sm:focus:-translate-x-9'
  | 'sm:focus:-translate-x-10'
  | 'sm:focus:-translate-x-11'
  | 'sm:focus:-translate-x-12'
  | 'sm:focus:-translate-x-13'
  | 'sm:focus:-translate-x-14'
  | 'sm:focus:-translate-x-15'
  | 'sm:focus:-translate-x-16'
  | 'sm:focus:-translate-x-20'
  | 'sm:focus:-translate-x-24'
  | 'sm:focus:-translate-x-28'
  | 'sm:focus:-translate-x-32'
  | 'sm:focus:-translate-x-36'
  | 'sm:focus:-translate-x-40'
  | 'sm:focus:-translate-x-48'
  | 'sm:focus:-translate-x-56'
  | 'sm:focus:-translate-x-60'
  | 'sm:focus:-translate-x-64'
  | 'sm:focus:-translate-x-72'
  | 'sm:focus:-translate-x-80'
  | 'sm:focus:-translate-x-96'
  | 'sm:focus:-translate-x-px'
  | 'sm:focus:-translate-x-0.5'
  | 'sm:focus:-translate-x-1.5'
  | 'sm:focus:-translate-x-2.5'
  | 'sm:focus:-translate-x-3.5'
  | 'sm:focus:-translate-x-1/2'
  | 'sm:focus:-translate-x-1/3'
  | 'sm:focus:-translate-x-2/3'
  | 'sm:focus:-translate-x-1/4'
  | 'sm:focus:-translate-x-2/4'
  | 'sm:focus:-translate-x-3/4'
  | 'sm:focus:-translate-x-1/5'
  | 'sm:focus:-translate-x-2/5'
  | 'sm:focus:-translate-x-3/5'
  | 'sm:focus:-translate-x-4/5'
  | 'sm:focus:-translate-x-1/6'
  | 'sm:focus:-translate-x-2/6'
  | 'sm:focus:-translate-x-3/6'
  | 'sm:focus:-translate-x-4/6'
  | 'sm:focus:-translate-x-5/6'
  | 'sm:focus:-translate-x-1/12'
  | 'sm:focus:-translate-x-2/12'
  | 'sm:focus:-translate-x-3/12'
  | 'sm:focus:-translate-x-4/12'
  | 'sm:focus:-translate-x-5/12'
  | 'sm:focus:-translate-x-6/12'
  | 'sm:focus:-translate-x-7/12'
  | 'sm:focus:-translate-x-8/12'
  | 'sm:focus:-translate-x-9/12'
  | 'sm:focus:-translate-x-10/12'
  | 'sm:focus:-translate-x-11/12'
  | 'sm:focus:-translate-x-full'
  | 'sm:focus:translate-y-0'
  | 'sm:focus:translate-y-1'
  | 'sm:focus:translate-y-2'
  | 'sm:focus:translate-y-3'
  | 'sm:focus:translate-y-4'
  | 'sm:focus:translate-y-5'
  | 'sm:focus:translate-y-6'
  | 'sm:focus:translate-y-7'
  | 'sm:focus:translate-y-8'
  | 'sm:focus:translate-y-9'
  | 'sm:focus:translate-y-10'
  | 'sm:focus:translate-y-11'
  | 'sm:focus:translate-y-12'
  | 'sm:focus:translate-y-13'
  | 'sm:focus:translate-y-14'
  | 'sm:focus:translate-y-15'
  | 'sm:focus:translate-y-16'
  | 'sm:focus:translate-y-20'
  | 'sm:focus:translate-y-24'
  | 'sm:focus:translate-y-28'
  | 'sm:focus:translate-y-32'
  | 'sm:focus:translate-y-36'
  | 'sm:focus:translate-y-40'
  | 'sm:focus:translate-y-48'
  | 'sm:focus:translate-y-56'
  | 'sm:focus:translate-y-60'
  | 'sm:focus:translate-y-64'
  | 'sm:focus:translate-y-72'
  | 'sm:focus:translate-y-80'
  | 'sm:focus:translate-y-96'
  | 'sm:focus:translate-y-px'
  | 'sm:focus:translate-y-0.5'
  | 'sm:focus:translate-y-1.5'
  | 'sm:focus:translate-y-2.5'
  | 'sm:focus:translate-y-3.5'
  | 'sm:focus:translate-y-1/2'
  | 'sm:focus:translate-y-1/3'
  | 'sm:focus:translate-y-2/3'
  | 'sm:focus:translate-y-1/4'
  | 'sm:focus:translate-y-2/4'
  | 'sm:focus:translate-y-3/4'
  | 'sm:focus:translate-y-1/5'
  | 'sm:focus:translate-y-2/5'
  | 'sm:focus:translate-y-3/5'
  | 'sm:focus:translate-y-4/5'
  | 'sm:focus:translate-y-1/6'
  | 'sm:focus:translate-y-2/6'
  | 'sm:focus:translate-y-3/6'
  | 'sm:focus:translate-y-4/6'
  | 'sm:focus:translate-y-5/6'
  | 'sm:focus:translate-y-1/12'
  | 'sm:focus:translate-y-2/12'
  | 'sm:focus:translate-y-3/12'
  | 'sm:focus:translate-y-4/12'
  | 'sm:focus:translate-y-5/12'
  | 'sm:focus:translate-y-6/12'
  | 'sm:focus:translate-y-7/12'
  | 'sm:focus:translate-y-8/12'
  | 'sm:focus:translate-y-9/12'
  | 'sm:focus:translate-y-10/12'
  | 'sm:focus:translate-y-11/12'
  | 'sm:focus:translate-y-full'
  | 'sm:focus:-translate-y-1'
  | 'sm:focus:-translate-y-2'
  | 'sm:focus:-translate-y-3'
  | 'sm:focus:-translate-y-4'
  | 'sm:focus:-translate-y-5'
  | 'sm:focus:-translate-y-6'
  | 'sm:focus:-translate-y-7'
  | 'sm:focus:-translate-y-8'
  | 'sm:focus:-translate-y-9'
  | 'sm:focus:-translate-y-10'
  | 'sm:focus:-translate-y-11'
  | 'sm:focus:-translate-y-12'
  | 'sm:focus:-translate-y-13'
  | 'sm:focus:-translate-y-14'
  | 'sm:focus:-translate-y-15'
  | 'sm:focus:-translate-y-16'
  | 'sm:focus:-translate-y-20'
  | 'sm:focus:-translate-y-24'
  | 'sm:focus:-translate-y-28'
  | 'sm:focus:-translate-y-32'
  | 'sm:focus:-translate-y-36'
  | 'sm:focus:-translate-y-40'
  | 'sm:focus:-translate-y-48'
  | 'sm:focus:-translate-y-56'
  | 'sm:focus:-translate-y-60'
  | 'sm:focus:-translate-y-64'
  | 'sm:focus:-translate-y-72'
  | 'sm:focus:-translate-y-80'
  | 'sm:focus:-translate-y-96'
  | 'sm:focus:-translate-y-px'
  | 'sm:focus:-translate-y-0.5'
  | 'sm:focus:-translate-y-1.5'
  | 'sm:focus:-translate-y-2.5'
  | 'sm:focus:-translate-y-3.5'
  | 'sm:focus:-translate-y-1/2'
  | 'sm:focus:-translate-y-1/3'
  | 'sm:focus:-translate-y-2/3'
  | 'sm:focus:-translate-y-1/4'
  | 'sm:focus:-translate-y-2/4'
  | 'sm:focus:-translate-y-3/4'
  | 'sm:focus:-translate-y-1/5'
  | 'sm:focus:-translate-y-2/5'
  | 'sm:focus:-translate-y-3/5'
  | 'sm:focus:-translate-y-4/5'
  | 'sm:focus:-translate-y-1/6'
  | 'sm:focus:-translate-y-2/6'
  | 'sm:focus:-translate-y-3/6'
  | 'sm:focus:-translate-y-4/6'
  | 'sm:focus:-translate-y-5/6'
  | 'sm:focus:-translate-y-1/12'
  | 'sm:focus:-translate-y-2/12'
  | 'sm:focus:-translate-y-3/12'
  | 'sm:focus:-translate-y-4/12'
  | 'sm:focus:-translate-y-5/12'
  | 'sm:focus:-translate-y-6/12'
  | 'sm:focus:-translate-y-7/12'
  | 'sm:focus:-translate-y-8/12'
  | 'sm:focus:-translate-y-9/12'
  | 'sm:focus:-translate-y-10/12'
  | 'sm:focus:-translate-y-11/12'
  | 'sm:focus:-translate-y-full'
  | 'sm:skew-x-0'
  | 'sm:skew-x-3'
  | 'sm:skew-x-6'
  | 'sm:skew-x-12'
  | 'sm:-skew-x-12'
  | 'sm:-skew-x-6'
  | 'sm:-skew-x-3'
  | 'sm:skew-y-0'
  | 'sm:skew-y-3'
  | 'sm:skew-y-6'
  | 'sm:skew-y-12'
  | 'sm:-skew-y-12'
  | 'sm:-skew-y-6'
  | 'sm:-skew-y-3'
  | 'sm:hover:skew-x-0'
  | 'sm:hover:skew-x-3'
  | 'sm:hover:skew-x-6'
  | 'sm:hover:skew-x-12'
  | 'sm:hover:-skew-x-12'
  | 'sm:hover:-skew-x-6'
  | 'sm:hover:-skew-x-3'
  | 'sm:hover:skew-y-0'
  | 'sm:hover:skew-y-3'
  | 'sm:hover:skew-y-6'
  | 'sm:hover:skew-y-12'
  | 'sm:hover:-skew-y-12'
  | 'sm:hover:-skew-y-6'
  | 'sm:hover:-skew-y-3'
  | 'sm:focus:skew-x-0'
  | 'sm:focus:skew-x-3'
  | 'sm:focus:skew-x-6'
  | 'sm:focus:skew-x-12'
  | 'sm:focus:-skew-x-12'
  | 'sm:focus:-skew-x-6'
  | 'sm:focus:-skew-x-3'
  | 'sm:focus:skew-y-0'
  | 'sm:focus:skew-y-3'
  | 'sm:focus:skew-y-6'
  | 'sm:focus:skew-y-12'
  | 'sm:focus:-skew-y-12'
  | 'sm:focus:-skew-y-6'
  | 'sm:focus:-skew-y-3'
  | 'sm:transition-none'
  | 'sm:transition-all'
  | 'sm:transition'
  | 'sm:transition-colors'
  | 'sm:transition-opacity'
  | 'sm:transition-shadow'
  | 'sm:transition-transform'
  | 'sm:ease-linear'
  | 'sm:ease-in'
  | 'sm:ease-out'
  | 'sm:ease-in-out'
  | 'sm:duration-75'
  | 'sm:duration-100'
  | 'sm:duration-150'
  | 'sm:duration-200'
  | 'sm:duration-300'
  | 'sm:duration-500'
  | 'sm:duration-700'
  | 'sm:duration-1000'
  | 'md:sr-only'
  | 'md:not-sr-only'
  | 'md:focus:sr-only'
  | 'md:focus:not-sr-only'
  | 'md:appearance-none'
  | 'md:bg-fixed'
  | 'md:bg-local'
  | 'md:bg-scroll'
  | 'md:bg-transparent'
  | 'md:bg-white'
  | 'md:bg-black'
  | 'md:bg-gray-50'
  | 'md:bg-gray-100'
  | 'md:bg-gray-200'
  | 'md:bg-gray-300'
  | 'md:bg-gray-400'
  | 'md:bg-gray-500'
  | 'md:bg-gray-600'
  | 'md:bg-gray-700'
  | 'md:bg-gray-800'
  | 'md:bg-gray-900'
  | 'md:bg-cool-gray-50'
  | 'md:bg-cool-gray-100'
  | 'md:bg-cool-gray-200'
  | 'md:bg-cool-gray-300'
  | 'md:bg-cool-gray-400'
  | 'md:bg-cool-gray-500'
  | 'md:bg-cool-gray-600'
  | 'md:bg-cool-gray-700'
  | 'md:bg-cool-gray-800'
  | 'md:bg-cool-gray-900'
  | 'md:bg-red-50'
  | 'md:bg-red-100'
  | 'md:bg-red-200'
  | 'md:bg-red-300'
  | 'md:bg-red-400'
  | 'md:bg-red-500'
  | 'md:bg-red-600'
  | 'md:bg-red-700'
  | 'md:bg-red-800'
  | 'md:bg-red-900'
  | 'md:bg-orange-50'
  | 'md:bg-orange-100'
  | 'md:bg-orange-200'
  | 'md:bg-orange-300'
  | 'md:bg-orange-400'
  | 'md:bg-orange-500'
  | 'md:bg-orange-600'
  | 'md:bg-orange-700'
  | 'md:bg-orange-800'
  | 'md:bg-orange-900'
  | 'md:bg-yellow-50'
  | 'md:bg-yellow-100'
  | 'md:bg-yellow-200'
  | 'md:bg-yellow-300'
  | 'md:bg-yellow-400'
  | 'md:bg-yellow-500'
  | 'md:bg-yellow-600'
  | 'md:bg-yellow-700'
  | 'md:bg-yellow-800'
  | 'md:bg-yellow-900'
  | 'md:bg-green-50'
  | 'md:bg-green-100'
  | 'md:bg-green-200'
  | 'md:bg-green-300'
  | 'md:bg-green-400'
  | 'md:bg-green-500'
  | 'md:bg-green-600'
  | 'md:bg-green-700'
  | 'md:bg-green-800'
  | 'md:bg-green-900'
  | 'md:bg-teal-50'
  | 'md:bg-teal-100'
  | 'md:bg-teal-200'
  | 'md:bg-teal-300'
  | 'md:bg-teal-400'
  | 'md:bg-teal-500'
  | 'md:bg-teal-600'
  | 'md:bg-teal-700'
  | 'md:bg-teal-800'
  | 'md:bg-teal-900'
  | 'md:bg-blue-50'
  | 'md:bg-blue-100'
  | 'md:bg-blue-200'
  | 'md:bg-blue-300'
  | 'md:bg-blue-400'
  | 'md:bg-blue-500'
  | 'md:bg-blue-600'
  | 'md:bg-blue-700'
  | 'md:bg-blue-800'
  | 'md:bg-blue-900'
  | 'md:bg-indigo-50'
  | 'md:bg-indigo-100'
  | 'md:bg-indigo-200'
  | 'md:bg-indigo-300'
  | 'md:bg-indigo-400'
  | 'md:bg-indigo-500'
  | 'md:bg-indigo-600'
  | 'md:bg-indigo-700'
  | 'md:bg-indigo-800'
  | 'md:bg-indigo-900'
  | 'md:bg-purple-50'
  | 'md:bg-purple-100'
  | 'md:bg-purple-200'
  | 'md:bg-purple-300'
  | 'md:bg-purple-400'
  | 'md:bg-purple-500'
  | 'md:bg-purple-600'
  | 'md:bg-purple-700'
  | 'md:bg-purple-800'
  | 'md:bg-purple-900'
  | 'md:bg-pink-50'
  | 'md:bg-pink-100'
  | 'md:bg-pink-200'
  | 'md:bg-pink-300'
  | 'md:bg-pink-400'
  | 'md:bg-pink-500'
  | 'md:bg-pink-600'
  | 'md:bg-pink-700'
  | 'md:bg-pink-800'
  | 'md:bg-pink-900'
  | 'md:group-hover:bg-transparent'
  | 'md:group-hover:bg-white'
  | 'md:group-hover:bg-black'
  | 'md:group-hover:bg-gray-50'
  | 'md:group-hover:bg-gray-100'
  | 'md:group-hover:bg-gray-200'
  | 'md:group-hover:bg-gray-300'
  | 'md:group-hover:bg-gray-400'
  | 'md:group-hover:bg-gray-500'
  | 'md:group-hover:bg-gray-600'
  | 'md:group-hover:bg-gray-700'
  | 'md:group-hover:bg-gray-800'
  | 'md:group-hover:bg-gray-900'
  | 'md:group-hover:bg-cool-gray-50'
  | 'md:group-hover:bg-cool-gray-100'
  | 'md:group-hover:bg-cool-gray-200'
  | 'md:group-hover:bg-cool-gray-300'
  | 'md:group-hover:bg-cool-gray-400'
  | 'md:group-hover:bg-cool-gray-500'
  | 'md:group-hover:bg-cool-gray-600'
  | 'md:group-hover:bg-cool-gray-700'
  | 'md:group-hover:bg-cool-gray-800'
  | 'md:group-hover:bg-cool-gray-900'
  | 'md:group-hover:bg-red-50'
  | 'md:group-hover:bg-red-100'
  | 'md:group-hover:bg-red-200'
  | 'md:group-hover:bg-red-300'
  | 'md:group-hover:bg-red-400'
  | 'md:group-hover:bg-red-500'
  | 'md:group-hover:bg-red-600'
  | 'md:group-hover:bg-red-700'
  | 'md:group-hover:bg-red-800'
  | 'md:group-hover:bg-red-900'
  | 'md:group-hover:bg-orange-50'
  | 'md:group-hover:bg-orange-100'
  | 'md:group-hover:bg-orange-200'
  | 'md:group-hover:bg-orange-300'
  | 'md:group-hover:bg-orange-400'
  | 'md:group-hover:bg-orange-500'
  | 'md:group-hover:bg-orange-600'
  | 'md:group-hover:bg-orange-700'
  | 'md:group-hover:bg-orange-800'
  | 'md:group-hover:bg-orange-900'
  | 'md:group-hover:bg-yellow-50'
  | 'md:group-hover:bg-yellow-100'
  | 'md:group-hover:bg-yellow-200'
  | 'md:group-hover:bg-yellow-300'
  | 'md:group-hover:bg-yellow-400'
  | 'md:group-hover:bg-yellow-500'
  | 'md:group-hover:bg-yellow-600'
  | 'md:group-hover:bg-yellow-700'
  | 'md:group-hover:bg-yellow-800'
  | 'md:group-hover:bg-yellow-900'
  | 'md:group-hover:bg-green-50'
  | 'md:group-hover:bg-green-100'
  | 'md:group-hover:bg-green-200'
  | 'md:group-hover:bg-green-300'
  | 'md:group-hover:bg-green-400'
  | 'md:group-hover:bg-green-500'
  | 'md:group-hover:bg-green-600'
  | 'md:group-hover:bg-green-700'
  | 'md:group-hover:bg-green-800'
  | 'md:group-hover:bg-green-900'
  | 'md:group-hover:bg-teal-50'
  | 'md:group-hover:bg-teal-100'
  | 'md:group-hover:bg-teal-200'
  | 'md:group-hover:bg-teal-300'
  | 'md:group-hover:bg-teal-400'
  | 'md:group-hover:bg-teal-500'
  | 'md:group-hover:bg-teal-600'
  | 'md:group-hover:bg-teal-700'
  | 'md:group-hover:bg-teal-800'
  | 'md:group-hover:bg-teal-900'
  | 'md:group-hover:bg-blue-50'
  | 'md:group-hover:bg-blue-100'
  | 'md:group-hover:bg-blue-200'
  | 'md:group-hover:bg-blue-300'
  | 'md:group-hover:bg-blue-400'
  | 'md:group-hover:bg-blue-500'
  | 'md:group-hover:bg-blue-600'
  | 'md:group-hover:bg-blue-700'
  | 'md:group-hover:bg-blue-800'
  | 'md:group-hover:bg-blue-900'
  | 'md:group-hover:bg-indigo-50'
  | 'md:group-hover:bg-indigo-100'
  | 'md:group-hover:bg-indigo-200'
  | 'md:group-hover:bg-indigo-300'
  | 'md:group-hover:bg-indigo-400'
  | 'md:group-hover:bg-indigo-500'
  | 'md:group-hover:bg-indigo-600'
  | 'md:group-hover:bg-indigo-700'
  | 'md:group-hover:bg-indigo-800'
  | 'md:group-hover:bg-indigo-900'
  | 'md:group-hover:bg-purple-50'
  | 'md:group-hover:bg-purple-100'
  | 'md:group-hover:bg-purple-200'
  | 'md:group-hover:bg-purple-300'
  | 'md:group-hover:bg-purple-400'
  | 'md:group-hover:bg-purple-500'
  | 'md:group-hover:bg-purple-600'
  | 'md:group-hover:bg-purple-700'
  | 'md:group-hover:bg-purple-800'
  | 'md:group-hover:bg-purple-900'
  | 'md:group-hover:bg-pink-50'
  | 'md:group-hover:bg-pink-100'
  | 'md:group-hover:bg-pink-200'
  | 'md:group-hover:bg-pink-300'
  | 'md:group-hover:bg-pink-400'
  | 'md:group-hover:bg-pink-500'
  | 'md:group-hover:bg-pink-600'
  | 'md:group-hover:bg-pink-700'
  | 'md:group-hover:bg-pink-800'
  | 'md:group-hover:bg-pink-900'
  | 'md:group-focus:bg-transparent'
  | 'md:group-focus:bg-white'
  | 'md:group-focus:bg-black'
  | 'md:group-focus:bg-gray-50'
  | 'md:group-focus:bg-gray-100'
  | 'md:group-focus:bg-gray-200'
  | 'md:group-focus:bg-gray-300'
  | 'md:group-focus:bg-gray-400'
  | 'md:group-focus:bg-gray-500'
  | 'md:group-focus:bg-gray-600'
  | 'md:group-focus:bg-gray-700'
  | 'md:group-focus:bg-gray-800'
  | 'md:group-focus:bg-gray-900'
  | 'md:group-focus:bg-cool-gray-50'
  | 'md:group-focus:bg-cool-gray-100'
  | 'md:group-focus:bg-cool-gray-200'
  | 'md:group-focus:bg-cool-gray-300'
  | 'md:group-focus:bg-cool-gray-400'
  | 'md:group-focus:bg-cool-gray-500'
  | 'md:group-focus:bg-cool-gray-600'
  | 'md:group-focus:bg-cool-gray-700'
  | 'md:group-focus:bg-cool-gray-800'
  | 'md:group-focus:bg-cool-gray-900'
  | 'md:group-focus:bg-red-50'
  | 'md:group-focus:bg-red-100'
  | 'md:group-focus:bg-red-200'
  | 'md:group-focus:bg-red-300'
  | 'md:group-focus:bg-red-400'
  | 'md:group-focus:bg-red-500'
  | 'md:group-focus:bg-red-600'
  | 'md:group-focus:bg-red-700'
  | 'md:group-focus:bg-red-800'
  | 'md:group-focus:bg-red-900'
  | 'md:group-focus:bg-orange-50'
  | 'md:group-focus:bg-orange-100'
  | 'md:group-focus:bg-orange-200'
  | 'md:group-focus:bg-orange-300'
  | 'md:group-focus:bg-orange-400'
  | 'md:group-focus:bg-orange-500'
  | 'md:group-focus:bg-orange-600'
  | 'md:group-focus:bg-orange-700'
  | 'md:group-focus:bg-orange-800'
  | 'md:group-focus:bg-orange-900'
  | 'md:group-focus:bg-yellow-50'
  | 'md:group-focus:bg-yellow-100'
  | 'md:group-focus:bg-yellow-200'
  | 'md:group-focus:bg-yellow-300'
  | 'md:group-focus:bg-yellow-400'
  | 'md:group-focus:bg-yellow-500'
  | 'md:group-focus:bg-yellow-600'
  | 'md:group-focus:bg-yellow-700'
  | 'md:group-focus:bg-yellow-800'
  | 'md:group-focus:bg-yellow-900'
  | 'md:group-focus:bg-green-50'
  | 'md:group-focus:bg-green-100'
  | 'md:group-focus:bg-green-200'
  | 'md:group-focus:bg-green-300'
  | 'md:group-focus:bg-green-400'
  | 'md:group-focus:bg-green-500'
  | 'md:group-focus:bg-green-600'
  | 'md:group-focus:bg-green-700'
  | 'md:group-focus:bg-green-800'
  | 'md:group-focus:bg-green-900'
  | 'md:group-focus:bg-teal-50'
  | 'md:group-focus:bg-teal-100'
  | 'md:group-focus:bg-teal-200'
  | 'md:group-focus:bg-teal-300'
  | 'md:group-focus:bg-teal-400'
  | 'md:group-focus:bg-teal-500'
  | 'md:group-focus:bg-teal-600'
  | 'md:group-focus:bg-teal-700'
  | 'md:group-focus:bg-teal-800'
  | 'md:group-focus:bg-teal-900'
  | 'md:group-focus:bg-blue-50'
  | 'md:group-focus:bg-blue-100'
  | 'md:group-focus:bg-blue-200'
  | 'md:group-focus:bg-blue-300'
  | 'md:group-focus:bg-blue-400'
  | 'md:group-focus:bg-blue-500'
  | 'md:group-focus:bg-blue-600'
  | 'md:group-focus:bg-blue-700'
  | 'md:group-focus:bg-blue-800'
  | 'md:group-focus:bg-blue-900'
  | 'md:group-focus:bg-indigo-50'
  | 'md:group-focus:bg-indigo-100'
  | 'md:group-focus:bg-indigo-200'
  | 'md:group-focus:bg-indigo-300'
  | 'md:group-focus:bg-indigo-400'
  | 'md:group-focus:bg-indigo-500'
  | 'md:group-focus:bg-indigo-600'
  | 'md:group-focus:bg-indigo-700'
  | 'md:group-focus:bg-indigo-800'
  | 'md:group-focus:bg-indigo-900'
  | 'md:group-focus:bg-purple-50'
  | 'md:group-focus:bg-purple-100'
  | 'md:group-focus:bg-purple-200'
  | 'md:group-focus:bg-purple-300'
  | 'md:group-focus:bg-purple-400'
  | 'md:group-focus:bg-purple-500'
  | 'md:group-focus:bg-purple-600'
  | 'md:group-focus:bg-purple-700'
  | 'md:group-focus:bg-purple-800'
  | 'md:group-focus:bg-purple-900'
  | 'md:group-focus:bg-pink-50'
  | 'md:group-focus:bg-pink-100'
  | 'md:group-focus:bg-pink-200'
  | 'md:group-focus:bg-pink-300'
  | 'md:group-focus:bg-pink-400'
  | 'md:group-focus:bg-pink-500'
  | 'md:group-focus:bg-pink-600'
  | 'md:group-focus:bg-pink-700'
  | 'md:group-focus:bg-pink-800'
  | 'md:group-focus:bg-pink-900'
  | 'md:hover:bg-transparent'
  | 'md:hover:bg-white'
  | 'md:hover:bg-black'
  | 'md:hover:bg-gray-50'
  | 'md:hover:bg-gray-100'
  | 'md:hover:bg-gray-200'
  | 'md:hover:bg-gray-300'
  | 'md:hover:bg-gray-400'
  | 'md:hover:bg-gray-500'
  | 'md:hover:bg-gray-600'
  | 'md:hover:bg-gray-700'
  | 'md:hover:bg-gray-800'
  | 'md:hover:bg-gray-900'
  | 'md:hover:bg-cool-gray-50'
  | 'md:hover:bg-cool-gray-100'
  | 'md:hover:bg-cool-gray-200'
  | 'md:hover:bg-cool-gray-300'
  | 'md:hover:bg-cool-gray-400'
  | 'md:hover:bg-cool-gray-500'
  | 'md:hover:bg-cool-gray-600'
  | 'md:hover:bg-cool-gray-700'
  | 'md:hover:bg-cool-gray-800'
  | 'md:hover:bg-cool-gray-900'
  | 'md:hover:bg-red-50'
  | 'md:hover:bg-red-100'
  | 'md:hover:bg-red-200'
  | 'md:hover:bg-red-300'
  | 'md:hover:bg-red-400'
  | 'md:hover:bg-red-500'
  | 'md:hover:bg-red-600'
  | 'md:hover:bg-red-700'
  | 'md:hover:bg-red-800'
  | 'md:hover:bg-red-900'
  | 'md:hover:bg-orange-50'
  | 'md:hover:bg-orange-100'
  | 'md:hover:bg-orange-200'
  | 'md:hover:bg-orange-300'
  | 'md:hover:bg-orange-400'
  | 'md:hover:bg-orange-500'
  | 'md:hover:bg-orange-600'
  | 'md:hover:bg-orange-700'
  | 'md:hover:bg-orange-800'
  | 'md:hover:bg-orange-900'
  | 'md:hover:bg-yellow-50'
  | 'md:hover:bg-yellow-100'
  | 'md:hover:bg-yellow-200'
  | 'md:hover:bg-yellow-300'
  | 'md:hover:bg-yellow-400'
  | 'md:hover:bg-yellow-500'
  | 'md:hover:bg-yellow-600'
  | 'md:hover:bg-yellow-700'
  | 'md:hover:bg-yellow-800'
  | 'md:hover:bg-yellow-900'
  | 'md:hover:bg-green-50'
  | 'md:hover:bg-green-100'
  | 'md:hover:bg-green-200'
  | 'md:hover:bg-green-300'
  | 'md:hover:bg-green-400'
  | 'md:hover:bg-green-500'
  | 'md:hover:bg-green-600'
  | 'md:hover:bg-green-700'
  | 'md:hover:bg-green-800'
  | 'md:hover:bg-green-900'
  | 'md:hover:bg-teal-50'
  | 'md:hover:bg-teal-100'
  | 'md:hover:bg-teal-200'
  | 'md:hover:bg-teal-300'
  | 'md:hover:bg-teal-400'
  | 'md:hover:bg-teal-500'
  | 'md:hover:bg-teal-600'
  | 'md:hover:bg-teal-700'
  | 'md:hover:bg-teal-800'
  | 'md:hover:bg-teal-900'
  | 'md:hover:bg-blue-50'
  | 'md:hover:bg-blue-100'
  | 'md:hover:bg-blue-200'
  | 'md:hover:bg-blue-300'
  | 'md:hover:bg-blue-400'
  | 'md:hover:bg-blue-500'
  | 'md:hover:bg-blue-600'
  | 'md:hover:bg-blue-700'
  | 'md:hover:bg-blue-800'
  | 'md:hover:bg-blue-900'
  | 'md:hover:bg-indigo-50'
  | 'md:hover:bg-indigo-100'
  | 'md:hover:bg-indigo-200'
  | 'md:hover:bg-indigo-300'
  | 'md:hover:bg-indigo-400'
  | 'md:hover:bg-indigo-500'
  | 'md:hover:bg-indigo-600'
  | 'md:hover:bg-indigo-700'
  | 'md:hover:bg-indigo-800'
  | 'md:hover:bg-indigo-900'
  | 'md:hover:bg-purple-50'
  | 'md:hover:bg-purple-100'
  | 'md:hover:bg-purple-200'
  | 'md:hover:bg-purple-300'
  | 'md:hover:bg-purple-400'
  | 'md:hover:bg-purple-500'
  | 'md:hover:bg-purple-600'
  | 'md:hover:bg-purple-700'
  | 'md:hover:bg-purple-800'
  | 'md:hover:bg-purple-900'
  | 'md:hover:bg-pink-50'
  | 'md:hover:bg-pink-100'
  | 'md:hover:bg-pink-200'
  | 'md:hover:bg-pink-300'
  | 'md:hover:bg-pink-400'
  | 'md:hover:bg-pink-500'
  | 'md:hover:bg-pink-600'
  | 'md:hover:bg-pink-700'
  | 'md:hover:bg-pink-800'
  | 'md:hover:bg-pink-900'
  | 'md:focus:bg-transparent'
  | 'md:focus:bg-white'
  | 'md:focus:bg-black'
  | 'md:focus:bg-gray-50'
  | 'md:focus:bg-gray-100'
  | 'md:focus:bg-gray-200'
  | 'md:focus:bg-gray-300'
  | 'md:focus:bg-gray-400'
  | 'md:focus:bg-gray-500'
  | 'md:focus:bg-gray-600'
  | 'md:focus:bg-gray-700'
  | 'md:focus:bg-gray-800'
  | 'md:focus:bg-gray-900'
  | 'md:focus:bg-cool-gray-50'
  | 'md:focus:bg-cool-gray-100'
  | 'md:focus:bg-cool-gray-200'
  | 'md:focus:bg-cool-gray-300'
  | 'md:focus:bg-cool-gray-400'
  | 'md:focus:bg-cool-gray-500'
  | 'md:focus:bg-cool-gray-600'
  | 'md:focus:bg-cool-gray-700'
  | 'md:focus:bg-cool-gray-800'
  | 'md:focus:bg-cool-gray-900'
  | 'md:focus:bg-red-50'
  | 'md:focus:bg-red-100'
  | 'md:focus:bg-red-200'
  | 'md:focus:bg-red-300'
  | 'md:focus:bg-red-400'
  | 'md:focus:bg-red-500'
  | 'md:focus:bg-red-600'
  | 'md:focus:bg-red-700'
  | 'md:focus:bg-red-800'
  | 'md:focus:bg-red-900'
  | 'md:focus:bg-orange-50'
  | 'md:focus:bg-orange-100'
  | 'md:focus:bg-orange-200'
  | 'md:focus:bg-orange-300'
  | 'md:focus:bg-orange-400'
  | 'md:focus:bg-orange-500'
  | 'md:focus:bg-orange-600'
  | 'md:focus:bg-orange-700'
  | 'md:focus:bg-orange-800'
  | 'md:focus:bg-orange-900'
  | 'md:focus:bg-yellow-50'
  | 'md:focus:bg-yellow-100'
  | 'md:focus:bg-yellow-200'
  | 'md:focus:bg-yellow-300'
  | 'md:focus:bg-yellow-400'
  | 'md:focus:bg-yellow-500'
  | 'md:focus:bg-yellow-600'
  | 'md:focus:bg-yellow-700'
  | 'md:focus:bg-yellow-800'
  | 'md:focus:bg-yellow-900'
  | 'md:focus:bg-green-50'
  | 'md:focus:bg-green-100'
  | 'md:focus:bg-green-200'
  | 'md:focus:bg-green-300'
  | 'md:focus:bg-green-400'
  | 'md:focus:bg-green-500'
  | 'md:focus:bg-green-600'
  | 'md:focus:bg-green-700'
  | 'md:focus:bg-green-800'
  | 'md:focus:bg-green-900'
  | 'md:focus:bg-teal-50'
  | 'md:focus:bg-teal-100'
  | 'md:focus:bg-teal-200'
  | 'md:focus:bg-teal-300'
  | 'md:focus:bg-teal-400'
  | 'md:focus:bg-teal-500'
  | 'md:focus:bg-teal-600'
  | 'md:focus:bg-teal-700'
  | 'md:focus:bg-teal-800'
  | 'md:focus:bg-teal-900'
  | 'md:focus:bg-blue-50'
  | 'md:focus:bg-blue-100'
  | 'md:focus:bg-blue-200'
  | 'md:focus:bg-blue-300'
  | 'md:focus:bg-blue-400'
  | 'md:focus:bg-blue-500'
  | 'md:focus:bg-blue-600'
  | 'md:focus:bg-blue-700'
  | 'md:focus:bg-blue-800'
  | 'md:focus:bg-blue-900'
  | 'md:focus:bg-indigo-50'
  | 'md:focus:bg-indigo-100'
  | 'md:focus:bg-indigo-200'
  | 'md:focus:bg-indigo-300'
  | 'md:focus:bg-indigo-400'
  | 'md:focus:bg-indigo-500'
  | 'md:focus:bg-indigo-600'
  | 'md:focus:bg-indigo-700'
  | 'md:focus:bg-indigo-800'
  | 'md:focus:bg-indigo-900'
  | 'md:focus:bg-purple-50'
  | 'md:focus:bg-purple-100'
  | 'md:focus:bg-purple-200'
  | 'md:focus:bg-purple-300'
  | 'md:focus:bg-purple-400'
  | 'md:focus:bg-purple-500'
  | 'md:focus:bg-purple-600'
  | 'md:focus:bg-purple-700'
  | 'md:focus:bg-purple-800'
  | 'md:focus:bg-purple-900'
  | 'md:focus:bg-pink-50'
  | 'md:focus:bg-pink-100'
  | 'md:focus:bg-pink-200'
  | 'md:focus:bg-pink-300'
  | 'md:focus:bg-pink-400'
  | 'md:focus:bg-pink-500'
  | 'md:focus:bg-pink-600'
  | 'md:focus:bg-pink-700'
  | 'md:focus:bg-pink-800'
  | 'md:focus:bg-pink-900'
  | 'md:active:bg-transparent'
  | 'md:active:bg-white'
  | 'md:active:bg-black'
  | 'md:active:bg-gray-50'
  | 'md:active:bg-gray-100'
  | 'md:active:bg-gray-200'
  | 'md:active:bg-gray-300'
  | 'md:active:bg-gray-400'
  | 'md:active:bg-gray-500'
  | 'md:active:bg-gray-600'
  | 'md:active:bg-gray-700'
  | 'md:active:bg-gray-800'
  | 'md:active:bg-gray-900'
  | 'md:active:bg-cool-gray-50'
  | 'md:active:bg-cool-gray-100'
  | 'md:active:bg-cool-gray-200'
  | 'md:active:bg-cool-gray-300'
  | 'md:active:bg-cool-gray-400'
  | 'md:active:bg-cool-gray-500'
  | 'md:active:bg-cool-gray-600'
  | 'md:active:bg-cool-gray-700'
  | 'md:active:bg-cool-gray-800'
  | 'md:active:bg-cool-gray-900'
  | 'md:active:bg-red-50'
  | 'md:active:bg-red-100'
  | 'md:active:bg-red-200'
  | 'md:active:bg-red-300'
  | 'md:active:bg-red-400'
  | 'md:active:bg-red-500'
  | 'md:active:bg-red-600'
  | 'md:active:bg-red-700'
  | 'md:active:bg-red-800'
  | 'md:active:bg-red-900'
  | 'md:active:bg-orange-50'
  | 'md:active:bg-orange-100'
  | 'md:active:bg-orange-200'
  | 'md:active:bg-orange-300'
  | 'md:active:bg-orange-400'
  | 'md:active:bg-orange-500'
  | 'md:active:bg-orange-600'
  | 'md:active:bg-orange-700'
  | 'md:active:bg-orange-800'
  | 'md:active:bg-orange-900'
  | 'md:active:bg-yellow-50'
  | 'md:active:bg-yellow-100'
  | 'md:active:bg-yellow-200'
  | 'md:active:bg-yellow-300'
  | 'md:active:bg-yellow-400'
  | 'md:active:bg-yellow-500'
  | 'md:active:bg-yellow-600'
  | 'md:active:bg-yellow-700'
  | 'md:active:bg-yellow-800'
  | 'md:active:bg-yellow-900'
  | 'md:active:bg-green-50'
  | 'md:active:bg-green-100'
  | 'md:active:bg-green-200'
  | 'md:active:bg-green-300'
  | 'md:active:bg-green-400'
  | 'md:active:bg-green-500'
  | 'md:active:bg-green-600'
  | 'md:active:bg-green-700'
  | 'md:active:bg-green-800'
  | 'md:active:bg-green-900'
  | 'md:active:bg-teal-50'
  | 'md:active:bg-teal-100'
  | 'md:active:bg-teal-200'
  | 'md:active:bg-teal-300'
  | 'md:active:bg-teal-400'
  | 'md:active:bg-teal-500'
  | 'md:active:bg-teal-600'
  | 'md:active:bg-teal-700'
  | 'md:active:bg-teal-800'
  | 'md:active:bg-teal-900'
  | 'md:active:bg-blue-50'
  | 'md:active:bg-blue-100'
  | 'md:active:bg-blue-200'
  | 'md:active:bg-blue-300'
  | 'md:active:bg-blue-400'
  | 'md:active:bg-blue-500'
  | 'md:active:bg-blue-600'
  | 'md:active:bg-blue-700'
  | 'md:active:bg-blue-800'
  | 'md:active:bg-blue-900'
  | 'md:active:bg-indigo-50'
  | 'md:active:bg-indigo-100'
  | 'md:active:bg-indigo-200'
  | 'md:active:bg-indigo-300'
  | 'md:active:bg-indigo-400'
  | 'md:active:bg-indigo-500'
  | 'md:active:bg-indigo-600'
  | 'md:active:bg-indigo-700'
  | 'md:active:bg-indigo-800'
  | 'md:active:bg-indigo-900'
  | 'md:active:bg-purple-50'
  | 'md:active:bg-purple-100'
  | 'md:active:bg-purple-200'
  | 'md:active:bg-purple-300'
  | 'md:active:bg-purple-400'
  | 'md:active:bg-purple-500'
  | 'md:active:bg-purple-600'
  | 'md:active:bg-purple-700'
  | 'md:active:bg-purple-800'
  | 'md:active:bg-purple-900'
  | 'md:active:bg-pink-50'
  | 'md:active:bg-pink-100'
  | 'md:active:bg-pink-200'
  | 'md:active:bg-pink-300'
  | 'md:active:bg-pink-400'
  | 'md:active:bg-pink-500'
  | 'md:active:bg-pink-600'
  | 'md:active:bg-pink-700'
  | 'md:active:bg-pink-800'
  | 'md:active:bg-pink-900'
  | 'md:bg-bottom'
  | 'md:bg-center'
  | 'md:bg-left'
  | 'md:bg-left-bottom'
  | 'md:bg-left-top'
  | 'md:bg-right'
  | 'md:bg-right-bottom'
  | 'md:bg-right-top'
  | 'md:bg-top'
  | 'md:bg-repeat'
  | 'md:bg-no-repeat'
  | 'md:bg-repeat-x'
  | 'md:bg-repeat-y'
  | 'md:bg-repeat-round'
  | 'md:bg-repeat-space'
  | 'md:bg-auto'
  | 'md:bg-cover'
  | 'md:bg-contain'
  | 'md:border-collapse'
  | 'md:border-separate'
  | 'md:border-transparent'
  | 'md:border-white'
  | 'md:border-black'
  | 'md:border-gray-50'
  | 'md:border-gray-100'
  | 'md:border-gray-200'
  | 'md:border-gray-300'
  | 'md:border-gray-400'
  | 'md:border-gray-500'
  | 'md:border-gray-600'
  | 'md:border-gray-700'
  | 'md:border-gray-800'
  | 'md:border-gray-900'
  | 'md:border-cool-gray-50'
  | 'md:border-cool-gray-100'
  | 'md:border-cool-gray-200'
  | 'md:border-cool-gray-300'
  | 'md:border-cool-gray-400'
  | 'md:border-cool-gray-500'
  | 'md:border-cool-gray-600'
  | 'md:border-cool-gray-700'
  | 'md:border-cool-gray-800'
  | 'md:border-cool-gray-900'
  | 'md:border-red-50'
  | 'md:border-red-100'
  | 'md:border-red-200'
  | 'md:border-red-300'
  | 'md:border-red-400'
  | 'md:border-red-500'
  | 'md:border-red-600'
  | 'md:border-red-700'
  | 'md:border-red-800'
  | 'md:border-red-900'
  | 'md:border-orange-50'
  | 'md:border-orange-100'
  | 'md:border-orange-200'
  | 'md:border-orange-300'
  | 'md:border-orange-400'
  | 'md:border-orange-500'
  | 'md:border-orange-600'
  | 'md:border-orange-700'
  | 'md:border-orange-800'
  | 'md:border-orange-900'
  | 'md:border-yellow-50'
  | 'md:border-yellow-100'
  | 'md:border-yellow-200'
  | 'md:border-yellow-300'
  | 'md:border-yellow-400'
  | 'md:border-yellow-500'
  | 'md:border-yellow-600'
  | 'md:border-yellow-700'
  | 'md:border-yellow-800'
  | 'md:border-yellow-900'
  | 'md:border-green-50'
  | 'md:border-green-100'
  | 'md:border-green-200'
  | 'md:border-green-300'
  | 'md:border-green-400'
  | 'md:border-green-500'
  | 'md:border-green-600'
  | 'md:border-green-700'
  | 'md:border-green-800'
  | 'md:border-green-900'
  | 'md:border-teal-50'
  | 'md:border-teal-100'
  | 'md:border-teal-200'
  | 'md:border-teal-300'
  | 'md:border-teal-400'
  | 'md:border-teal-500'
  | 'md:border-teal-600'
  | 'md:border-teal-700'
  | 'md:border-teal-800'
  | 'md:border-teal-900'
  | 'md:border-blue-50'
  | 'md:border-blue-100'
  | 'md:border-blue-200'
  | 'md:border-blue-300'
  | 'md:border-blue-400'
  | 'md:border-blue-500'
  | 'md:border-blue-600'
  | 'md:border-blue-700'
  | 'md:border-blue-800'
  | 'md:border-blue-900'
  | 'md:border-indigo-50'
  | 'md:border-indigo-100'
  | 'md:border-indigo-200'
  | 'md:border-indigo-300'
  | 'md:border-indigo-400'
  | 'md:border-indigo-500'
  | 'md:border-indigo-600'
  | 'md:border-indigo-700'
  | 'md:border-indigo-800'
  | 'md:border-indigo-900'
  | 'md:border-purple-50'
  | 'md:border-purple-100'
  | 'md:border-purple-200'
  | 'md:border-purple-300'
  | 'md:border-purple-400'
  | 'md:border-purple-500'
  | 'md:border-purple-600'
  | 'md:border-purple-700'
  | 'md:border-purple-800'
  | 'md:border-purple-900'
  | 'md:border-pink-50'
  | 'md:border-pink-100'
  | 'md:border-pink-200'
  | 'md:border-pink-300'
  | 'md:border-pink-400'
  | 'md:border-pink-500'
  | 'md:border-pink-600'
  | 'md:border-pink-700'
  | 'md:border-pink-800'
  | 'md:border-pink-900'
  | 'md:group-hover:border-transparent'
  | 'md:group-hover:border-white'
  | 'md:group-hover:border-black'
  | 'md:group-hover:border-gray-50'
  | 'md:group-hover:border-gray-100'
  | 'md:group-hover:border-gray-200'
  | 'md:group-hover:border-gray-300'
  | 'md:group-hover:border-gray-400'
  | 'md:group-hover:border-gray-500'
  | 'md:group-hover:border-gray-600'
  | 'md:group-hover:border-gray-700'
  | 'md:group-hover:border-gray-800'
  | 'md:group-hover:border-gray-900'
  | 'md:group-hover:border-cool-gray-50'
  | 'md:group-hover:border-cool-gray-100'
  | 'md:group-hover:border-cool-gray-200'
  | 'md:group-hover:border-cool-gray-300'
  | 'md:group-hover:border-cool-gray-400'
  | 'md:group-hover:border-cool-gray-500'
  | 'md:group-hover:border-cool-gray-600'
  | 'md:group-hover:border-cool-gray-700'
  | 'md:group-hover:border-cool-gray-800'
  | 'md:group-hover:border-cool-gray-900'
  | 'md:group-hover:border-red-50'
  | 'md:group-hover:border-red-100'
  | 'md:group-hover:border-red-200'
  | 'md:group-hover:border-red-300'
  | 'md:group-hover:border-red-400'
  | 'md:group-hover:border-red-500'
  | 'md:group-hover:border-red-600'
  | 'md:group-hover:border-red-700'
  | 'md:group-hover:border-red-800'
  | 'md:group-hover:border-red-900'
  | 'md:group-hover:border-orange-50'
  | 'md:group-hover:border-orange-100'
  | 'md:group-hover:border-orange-200'
  | 'md:group-hover:border-orange-300'
  | 'md:group-hover:border-orange-400'
  | 'md:group-hover:border-orange-500'
  | 'md:group-hover:border-orange-600'
  | 'md:group-hover:border-orange-700'
  | 'md:group-hover:border-orange-800'
  | 'md:group-hover:border-orange-900'
  | 'md:group-hover:border-yellow-50'
  | 'md:group-hover:border-yellow-100'
  | 'md:group-hover:border-yellow-200'
  | 'md:group-hover:border-yellow-300'
  | 'md:group-hover:border-yellow-400'
  | 'md:group-hover:border-yellow-500'
  | 'md:group-hover:border-yellow-600'
  | 'md:group-hover:border-yellow-700'
  | 'md:group-hover:border-yellow-800'
  | 'md:group-hover:border-yellow-900'
  | 'md:group-hover:border-green-50'
  | 'md:group-hover:border-green-100'
  | 'md:group-hover:border-green-200'
  | 'md:group-hover:border-green-300'
  | 'md:group-hover:border-green-400'
  | 'md:group-hover:border-green-500'
  | 'md:group-hover:border-green-600'
  | 'md:group-hover:border-green-700'
  | 'md:group-hover:border-green-800'
  | 'md:group-hover:border-green-900'
  | 'md:group-hover:border-teal-50'
  | 'md:group-hover:border-teal-100'
  | 'md:group-hover:border-teal-200'
  | 'md:group-hover:border-teal-300'
  | 'md:group-hover:border-teal-400'
  | 'md:group-hover:border-teal-500'
  | 'md:group-hover:border-teal-600'
  | 'md:group-hover:border-teal-700'
  | 'md:group-hover:border-teal-800'
  | 'md:group-hover:border-teal-900'
  | 'md:group-hover:border-blue-50'
  | 'md:group-hover:border-blue-100'
  | 'md:group-hover:border-blue-200'
  | 'md:group-hover:border-blue-300'
  | 'md:group-hover:border-blue-400'
  | 'md:group-hover:border-blue-500'
  | 'md:group-hover:border-blue-600'
  | 'md:group-hover:border-blue-700'
  | 'md:group-hover:border-blue-800'
  | 'md:group-hover:border-blue-900'
  | 'md:group-hover:border-indigo-50'
  | 'md:group-hover:border-indigo-100'
  | 'md:group-hover:border-indigo-200'
  | 'md:group-hover:border-indigo-300'
  | 'md:group-hover:border-indigo-400'
  | 'md:group-hover:border-indigo-500'
  | 'md:group-hover:border-indigo-600'
  | 'md:group-hover:border-indigo-700'
  | 'md:group-hover:border-indigo-800'
  | 'md:group-hover:border-indigo-900'
  | 'md:group-hover:border-purple-50'
  | 'md:group-hover:border-purple-100'
  | 'md:group-hover:border-purple-200'
  | 'md:group-hover:border-purple-300'
  | 'md:group-hover:border-purple-400'
  | 'md:group-hover:border-purple-500'
  | 'md:group-hover:border-purple-600'
  | 'md:group-hover:border-purple-700'
  | 'md:group-hover:border-purple-800'
  | 'md:group-hover:border-purple-900'
  | 'md:group-hover:border-pink-50'
  | 'md:group-hover:border-pink-100'
  | 'md:group-hover:border-pink-200'
  | 'md:group-hover:border-pink-300'
  | 'md:group-hover:border-pink-400'
  | 'md:group-hover:border-pink-500'
  | 'md:group-hover:border-pink-600'
  | 'md:group-hover:border-pink-700'
  | 'md:group-hover:border-pink-800'
  | 'md:group-hover:border-pink-900'
  | 'md:group-focus:border-transparent'
  | 'md:group-focus:border-white'
  | 'md:group-focus:border-black'
  | 'md:group-focus:border-gray-50'
  | 'md:group-focus:border-gray-100'
  | 'md:group-focus:border-gray-200'
  | 'md:group-focus:border-gray-300'
  | 'md:group-focus:border-gray-400'
  | 'md:group-focus:border-gray-500'
  | 'md:group-focus:border-gray-600'
  | 'md:group-focus:border-gray-700'
  | 'md:group-focus:border-gray-800'
  | 'md:group-focus:border-gray-900'
  | 'md:group-focus:border-cool-gray-50'
  | 'md:group-focus:border-cool-gray-100'
  | 'md:group-focus:border-cool-gray-200'
  | 'md:group-focus:border-cool-gray-300'
  | 'md:group-focus:border-cool-gray-400'
  | 'md:group-focus:border-cool-gray-500'
  | 'md:group-focus:border-cool-gray-600'
  | 'md:group-focus:border-cool-gray-700'
  | 'md:group-focus:border-cool-gray-800'
  | 'md:group-focus:border-cool-gray-900'
  | 'md:group-focus:border-red-50'
  | 'md:group-focus:border-red-100'
  | 'md:group-focus:border-red-200'
  | 'md:group-focus:border-red-300'
  | 'md:group-focus:border-red-400'
  | 'md:group-focus:border-red-500'
  | 'md:group-focus:border-red-600'
  | 'md:group-focus:border-red-700'
  | 'md:group-focus:border-red-800'
  | 'md:group-focus:border-red-900'
  | 'md:group-focus:border-orange-50'
  | 'md:group-focus:border-orange-100'
  | 'md:group-focus:border-orange-200'
  | 'md:group-focus:border-orange-300'
  | 'md:group-focus:border-orange-400'
  | 'md:group-focus:border-orange-500'
  | 'md:group-focus:border-orange-600'
  | 'md:group-focus:border-orange-700'
  | 'md:group-focus:border-orange-800'
  | 'md:group-focus:border-orange-900'
  | 'md:group-focus:border-yellow-50'
  | 'md:group-focus:border-yellow-100'
  | 'md:group-focus:border-yellow-200'
  | 'md:group-focus:border-yellow-300'
  | 'md:group-focus:border-yellow-400'
  | 'md:group-focus:border-yellow-500'
  | 'md:group-focus:border-yellow-600'
  | 'md:group-focus:border-yellow-700'
  | 'md:group-focus:border-yellow-800'
  | 'md:group-focus:border-yellow-900'
  | 'md:group-focus:border-green-50'
  | 'md:group-focus:border-green-100'
  | 'md:group-focus:border-green-200'
  | 'md:group-focus:border-green-300'
  | 'md:group-focus:border-green-400'
  | 'md:group-focus:border-green-500'
  | 'md:group-focus:border-green-600'
  | 'md:group-focus:border-green-700'
  | 'md:group-focus:border-green-800'
  | 'md:group-focus:border-green-900'
  | 'md:group-focus:border-teal-50'
  | 'md:group-focus:border-teal-100'
  | 'md:group-focus:border-teal-200'
  | 'md:group-focus:border-teal-300'
  | 'md:group-focus:border-teal-400'
  | 'md:group-focus:border-teal-500'
  | 'md:group-focus:border-teal-600'
  | 'md:group-focus:border-teal-700'
  | 'md:group-focus:border-teal-800'
  | 'md:group-focus:border-teal-900'
  | 'md:group-focus:border-blue-50'
  | 'md:group-focus:border-blue-100'
  | 'md:group-focus:border-blue-200'
  | 'md:group-focus:border-blue-300'
  | 'md:group-focus:border-blue-400'
  | 'md:group-focus:border-blue-500'
  | 'md:group-focus:border-blue-600'
  | 'md:group-focus:border-blue-700'
  | 'md:group-focus:border-blue-800'
  | 'md:group-focus:border-blue-900'
  | 'md:group-focus:border-indigo-50'
  | 'md:group-focus:border-indigo-100'
  | 'md:group-focus:border-indigo-200'
  | 'md:group-focus:border-indigo-300'
  | 'md:group-focus:border-indigo-400'
  | 'md:group-focus:border-indigo-500'
  | 'md:group-focus:border-indigo-600'
  | 'md:group-focus:border-indigo-700'
  | 'md:group-focus:border-indigo-800'
  | 'md:group-focus:border-indigo-900'
  | 'md:group-focus:border-purple-50'
  | 'md:group-focus:border-purple-100'
  | 'md:group-focus:border-purple-200'
  | 'md:group-focus:border-purple-300'
  | 'md:group-focus:border-purple-400'
  | 'md:group-focus:border-purple-500'
  | 'md:group-focus:border-purple-600'
  | 'md:group-focus:border-purple-700'
  | 'md:group-focus:border-purple-800'
  | 'md:group-focus:border-purple-900'
  | 'md:group-focus:border-pink-50'
  | 'md:group-focus:border-pink-100'
  | 'md:group-focus:border-pink-200'
  | 'md:group-focus:border-pink-300'
  | 'md:group-focus:border-pink-400'
  | 'md:group-focus:border-pink-500'
  | 'md:group-focus:border-pink-600'
  | 'md:group-focus:border-pink-700'
  | 'md:group-focus:border-pink-800'
  | 'md:group-focus:border-pink-900'
  | 'md:hover:border-transparent'
  | 'md:hover:border-white'
  | 'md:hover:border-black'
  | 'md:hover:border-gray-50'
  | 'md:hover:border-gray-100'
  | 'md:hover:border-gray-200'
  | 'md:hover:border-gray-300'
  | 'md:hover:border-gray-400'
  | 'md:hover:border-gray-500'
  | 'md:hover:border-gray-600'
  | 'md:hover:border-gray-700'
  | 'md:hover:border-gray-800'
  | 'md:hover:border-gray-900'
  | 'md:hover:border-cool-gray-50'
  | 'md:hover:border-cool-gray-100'
  | 'md:hover:border-cool-gray-200'
  | 'md:hover:border-cool-gray-300'
  | 'md:hover:border-cool-gray-400'
  | 'md:hover:border-cool-gray-500'
  | 'md:hover:border-cool-gray-600'
  | 'md:hover:border-cool-gray-700'
  | 'md:hover:border-cool-gray-800'
  | 'md:hover:border-cool-gray-900'
  | 'md:hover:border-red-50'
  | 'md:hover:border-red-100'
  | 'md:hover:border-red-200'
  | 'md:hover:border-red-300'
  | 'md:hover:border-red-400'
  | 'md:hover:border-red-500'
  | 'md:hover:border-red-600'
  | 'md:hover:border-red-700'
  | 'md:hover:border-red-800'
  | 'md:hover:border-red-900'
  | 'md:hover:border-orange-50'
  | 'md:hover:border-orange-100'
  | 'md:hover:border-orange-200'
  | 'md:hover:border-orange-300'
  | 'md:hover:border-orange-400'
  | 'md:hover:border-orange-500'
  | 'md:hover:border-orange-600'
  | 'md:hover:border-orange-700'
  | 'md:hover:border-orange-800'
  | 'md:hover:border-orange-900'
  | 'md:hover:border-yellow-50'
  | 'md:hover:border-yellow-100'
  | 'md:hover:border-yellow-200'
  | 'md:hover:border-yellow-300'
  | 'md:hover:border-yellow-400'
  | 'md:hover:border-yellow-500'
  | 'md:hover:border-yellow-600'
  | 'md:hover:border-yellow-700'
  | 'md:hover:border-yellow-800'
  | 'md:hover:border-yellow-900'
  | 'md:hover:border-green-50'
  | 'md:hover:border-green-100'
  | 'md:hover:border-green-200'
  | 'md:hover:border-green-300'
  | 'md:hover:border-green-400'
  | 'md:hover:border-green-500'
  | 'md:hover:border-green-600'
  | 'md:hover:border-green-700'
  | 'md:hover:border-green-800'
  | 'md:hover:border-green-900'
  | 'md:hover:border-teal-50'
  | 'md:hover:border-teal-100'
  | 'md:hover:border-teal-200'
  | 'md:hover:border-teal-300'
  | 'md:hover:border-teal-400'
  | 'md:hover:border-teal-500'
  | 'md:hover:border-teal-600'
  | 'md:hover:border-teal-700'
  | 'md:hover:border-teal-800'
  | 'md:hover:border-teal-900'
  | 'md:hover:border-blue-50'
  | 'md:hover:border-blue-100'
  | 'md:hover:border-blue-200'
  | 'md:hover:border-blue-300'
  | 'md:hover:border-blue-400'
  | 'md:hover:border-blue-500'
  | 'md:hover:border-blue-600'
  | 'md:hover:border-blue-700'
  | 'md:hover:border-blue-800'
  | 'md:hover:border-blue-900'
  | 'md:hover:border-indigo-50'
  | 'md:hover:border-indigo-100'
  | 'md:hover:border-indigo-200'
  | 'md:hover:border-indigo-300'
  | 'md:hover:border-indigo-400'
  | 'md:hover:border-indigo-500'
  | 'md:hover:border-indigo-600'
  | 'md:hover:border-indigo-700'
  | 'md:hover:border-indigo-800'
  | 'md:hover:border-indigo-900'
  | 'md:hover:border-purple-50'
  | 'md:hover:border-purple-100'
  | 'md:hover:border-purple-200'
  | 'md:hover:border-purple-300'
  | 'md:hover:border-purple-400'
  | 'md:hover:border-purple-500'
  | 'md:hover:border-purple-600'
  | 'md:hover:border-purple-700'
  | 'md:hover:border-purple-800'
  | 'md:hover:border-purple-900'
  | 'md:hover:border-pink-50'
  | 'md:hover:border-pink-100'
  | 'md:hover:border-pink-200'
  | 'md:hover:border-pink-300'
  | 'md:hover:border-pink-400'
  | 'md:hover:border-pink-500'
  | 'md:hover:border-pink-600'
  | 'md:hover:border-pink-700'
  | 'md:hover:border-pink-800'
  | 'md:hover:border-pink-900'
  | 'md:focus:border-transparent'
  | 'md:focus:border-white'
  | 'md:focus:border-black'
  | 'md:focus:border-gray-50'
  | 'md:focus:border-gray-100'
  | 'md:focus:border-gray-200'
  | 'md:focus:border-gray-300'
  | 'md:focus:border-gray-400'
  | 'md:focus:border-gray-500'
  | 'md:focus:border-gray-600'
  | 'md:focus:border-gray-700'
  | 'md:focus:border-gray-800'
  | 'md:focus:border-gray-900'
  | 'md:focus:border-cool-gray-50'
  | 'md:focus:border-cool-gray-100'
  | 'md:focus:border-cool-gray-200'
  | 'md:focus:border-cool-gray-300'
  | 'md:focus:border-cool-gray-400'
  | 'md:focus:border-cool-gray-500'
  | 'md:focus:border-cool-gray-600'
  | 'md:focus:border-cool-gray-700'
  | 'md:focus:border-cool-gray-800'
  | 'md:focus:border-cool-gray-900'
  | 'md:focus:border-red-50'
  | 'md:focus:border-red-100'
  | 'md:focus:border-red-200'
  | 'md:focus:border-red-300'
  | 'md:focus:border-red-400'
  | 'md:focus:border-red-500'
  | 'md:focus:border-red-600'
  | 'md:focus:border-red-700'
  | 'md:focus:border-red-800'
  | 'md:focus:border-red-900'
  | 'md:focus:border-orange-50'
  | 'md:focus:border-orange-100'
  | 'md:focus:border-orange-200'
  | 'md:focus:border-orange-300'
  | 'md:focus:border-orange-400'
  | 'md:focus:border-orange-500'
  | 'md:focus:border-orange-600'
  | 'md:focus:border-orange-700'
  | 'md:focus:border-orange-800'
  | 'md:focus:border-orange-900'
  | 'md:focus:border-yellow-50'
  | 'md:focus:border-yellow-100'
  | 'md:focus:border-yellow-200'
  | 'md:focus:border-yellow-300'
  | 'md:focus:border-yellow-400'
  | 'md:focus:border-yellow-500'
  | 'md:focus:border-yellow-600'
  | 'md:focus:border-yellow-700'
  | 'md:focus:border-yellow-800'
  | 'md:focus:border-yellow-900'
  | 'md:focus:border-green-50'
  | 'md:focus:border-green-100'
  | 'md:focus:border-green-200'
  | 'md:focus:border-green-300'
  | 'md:focus:border-green-400'
  | 'md:focus:border-green-500'
  | 'md:focus:border-green-600'
  | 'md:focus:border-green-700'
  | 'md:focus:border-green-800'
  | 'md:focus:border-green-900'
  | 'md:focus:border-teal-50'
  | 'md:focus:border-teal-100'
  | 'md:focus:border-teal-200'
  | 'md:focus:border-teal-300'
  | 'md:focus:border-teal-400'
  | 'md:focus:border-teal-500'
  | 'md:focus:border-teal-600'
  | 'md:focus:border-teal-700'
  | 'md:focus:border-teal-800'
  | 'md:focus:border-teal-900'
  | 'md:focus:border-blue-50'
  | 'md:focus:border-blue-100'
  | 'md:focus:border-blue-200'
  | 'md:focus:border-blue-300'
  | 'md:focus:border-blue-400'
  | 'md:focus:border-blue-500'
  | 'md:focus:border-blue-600'
  | 'md:focus:border-blue-700'
  | 'md:focus:border-blue-800'
  | 'md:focus:border-blue-900'
  | 'md:focus:border-indigo-50'
  | 'md:focus:border-indigo-100'
  | 'md:focus:border-indigo-200'
  | 'md:focus:border-indigo-300'
  | 'md:focus:border-indigo-400'
  | 'md:focus:border-indigo-500'
  | 'md:focus:border-indigo-600'
  | 'md:focus:border-indigo-700'
  | 'md:focus:border-indigo-800'
  | 'md:focus:border-indigo-900'
  | 'md:focus:border-purple-50'
  | 'md:focus:border-purple-100'
  | 'md:focus:border-purple-200'
  | 'md:focus:border-purple-300'
  | 'md:focus:border-purple-400'
  | 'md:focus:border-purple-500'
  | 'md:focus:border-purple-600'
  | 'md:focus:border-purple-700'
  | 'md:focus:border-purple-800'
  | 'md:focus:border-purple-900'
  | 'md:focus:border-pink-50'
  | 'md:focus:border-pink-100'
  | 'md:focus:border-pink-200'
  | 'md:focus:border-pink-300'
  | 'md:focus:border-pink-400'
  | 'md:focus:border-pink-500'
  | 'md:focus:border-pink-600'
  | 'md:focus:border-pink-700'
  | 'md:focus:border-pink-800'
  | 'md:focus:border-pink-900'
  | 'md:rounded-none'
  | 'md:rounded-sm'
  | 'md:rounded'
  | 'md:rounded-md'
  | 'md:rounded-lg'
  | 'md:rounded-full'
  | 'md:rounded-t-none'
  | 'md:rounded-r-none'
  | 'md:rounded-b-none'
  | 'md:rounded-l-none'
  | 'md:rounded-t-sm'
  | 'md:rounded-r-sm'
  | 'md:rounded-b-sm'
  | 'md:rounded-l-sm'
  | 'md:rounded-t'
  | 'md:rounded-r'
  | 'md:rounded-b'
  | 'md:rounded-l'
  | 'md:rounded-t-md'
  | 'md:rounded-r-md'
  | 'md:rounded-b-md'
  | 'md:rounded-l-md'
  | 'md:rounded-t-lg'
  | 'md:rounded-r-lg'
  | 'md:rounded-b-lg'
  | 'md:rounded-l-lg'
  | 'md:rounded-t-full'
  | 'md:rounded-r-full'
  | 'md:rounded-b-full'
  | 'md:rounded-l-full'
  | 'md:rounded-tl-none'
  | 'md:rounded-tr-none'
  | 'md:rounded-br-none'
  | 'md:rounded-bl-none'
  | 'md:rounded-tl-sm'
  | 'md:rounded-tr-sm'
  | 'md:rounded-br-sm'
  | 'md:rounded-bl-sm'
  | 'md:rounded-tl'
  | 'md:rounded-tr'
  | 'md:rounded-br'
  | 'md:rounded-bl'
  | 'md:rounded-tl-md'
  | 'md:rounded-tr-md'
  | 'md:rounded-br-md'
  | 'md:rounded-bl-md'
  | 'md:rounded-tl-lg'
  | 'md:rounded-tr-lg'
  | 'md:rounded-br-lg'
  | 'md:rounded-bl-lg'
  | 'md:rounded-tl-full'
  | 'md:rounded-tr-full'
  | 'md:rounded-br-full'
  | 'md:rounded-bl-full'
  | 'md:border-solid'
  | 'md:border-dashed'
  | 'md:border-dotted'
  | 'md:border-double'
  | 'md:border-none'
  | 'md:border-0'
  | 'md:border-2'
  | 'md:border-4'
  | 'md:border-8'
  | 'md:border'
  | 'md:border-t-0'
  | 'md:border-r-0'
  | 'md:border-b-0'
  | 'md:border-l-0'
  | 'md:border-t-2'
  | 'md:border-r-2'
  | 'md:border-b-2'
  | 'md:border-l-2'
  | 'md:border-t-4'
  | 'md:border-r-4'
  | 'md:border-b-4'
  | 'md:border-l-4'
  | 'md:border-t-8'
  | 'md:border-r-8'
  | 'md:border-b-8'
  | 'md:border-l-8'
  | 'md:border-t'
  | 'md:border-r'
  | 'md:border-b'
  | 'md:border-l'
  | 'md:box-border'
  | 'md:box-content'
  | 'md:cursor-auto'
  | 'md:cursor-default'
  | 'md:cursor-pointer'
  | 'md:cursor-wait'
  | 'md:cursor-text'
  | 'md:cursor-move'
  | 'md:cursor-not-allowed'
  | 'md:block'
  | 'md:inline-block'
  | 'md:inline'
  | 'md:flex'
  | 'md:inline-flex'
  | 'md:grid'
  | 'md:table'
  | 'md:table-caption'
  | 'md:table-cell'
  | 'md:table-column'
  | 'md:table-column-group'
  | 'md:table-footer-group'
  | 'md:table-header-group'
  | 'md:table-row-group'
  | 'md:table-row'
  | 'md:hidden'
  | 'md:flex-row'
  | 'md:flex-row-reverse'
  | 'md:flex-col'
  | 'md:flex-col-reverse'
  | 'md:flex-wrap'
  | 'md:flex-wrap-reverse'
  | 'md:flex-no-wrap'
  | 'md:items-start'
  | 'md:items-end'
  | 'md:items-center'
  | 'md:items-baseline'
  | 'md:items-stretch'
  | 'md:self-auto'
  | 'md:self-start'
  | 'md:self-end'
  | 'md:self-center'
  | 'md:self-stretch'
  | 'md:justify-start'
  | 'md:justify-end'
  | 'md:justify-center'
  | 'md:justify-between'
  | 'md:justify-around'
  | 'md:justify-evenly'
  | 'md:content-center'
  | 'md:content-start'
  | 'md:content-end'
  | 'md:content-between'
  | 'md:content-around'
  | 'md:flex-1'
  | 'md:flex-auto'
  | 'md:flex-initial'
  | 'md:flex-none'
  | 'md:flex-grow-0'
  | 'md:flex-grow'
  | 'md:flex-shrink-0'
  | 'md:flex-shrink'
  | 'md:order-1'
  | 'md:order-2'
  | 'md:order-3'
  | 'md:order-4'
  | 'md:order-5'
  | 'md:order-6'
  | 'md:order-7'
  | 'md:order-8'
  | 'md:order-9'
  | 'md:order-10'
  | 'md:order-11'
  | 'md:order-12'
  | 'md:order-first'
  | 'md:order-last'
  | 'md:order-none'
  | 'md:float-right'
  | 'md:float-left'
  | 'md:float-none'
  | 'md:clearfix'
  | 'md:clear-left'
  | 'md:clear-right'
  | 'md:clear-both'
  | 'md:font-sans'
  | 'md:font-serif'
  | 'md:font-mono'
  | 'md:font-hairline'
  | 'md:font-thin'
  | 'md:font-light'
  | 'md:font-normal'
  | 'md:font-medium'
  | 'md:font-semibold'
  | 'md:font-bold'
  | 'md:font-extrabold'
  | 'md:font-black'
  | 'md:hover:font-hairline'
  | 'md:hover:font-thin'
  | 'md:hover:font-light'
  | 'md:hover:font-normal'
  | 'md:hover:font-medium'
  | 'md:hover:font-semibold'
  | 'md:hover:font-bold'
  | 'md:hover:font-extrabold'
  | 'md:hover:font-black'
  | 'md:focus:font-hairline'
  | 'md:focus:font-thin'
  | 'md:focus:font-light'
  | 'md:focus:font-normal'
  | 'md:focus:font-medium'
  | 'md:focus:font-semibold'
  | 'md:focus:font-bold'
  | 'md:focus:font-extrabold'
  | 'md:focus:font-black'
  | 'md:h-0'
  | 'md:h-1'
  | 'md:h-2'
  | 'md:h-3'
  | 'md:h-4'
  | 'md:h-5'
  | 'md:h-6'
  | 'md:h-7'
  | 'md:h-8'
  | 'md:h-9'
  | 'md:h-10'
  | 'md:h-11'
  | 'md:h-12'
  | 'md:h-13'
  | 'md:h-14'
  | 'md:h-15'
  | 'md:h-16'
  | 'md:h-20'
  | 'md:h-24'
  | 'md:h-28'
  | 'md:h-32'
  | 'md:h-36'
  | 'md:h-40'
  | 'md:h-48'
  | 'md:h-56'
  | 'md:h-60'
  | 'md:h-64'
  | 'md:h-72'
  | 'md:h-80'
  | 'md:h-96'
  | 'md:h-auto'
  | 'md:h-px'
  | 'md:h-0.5'
  | 'md:h-1.5'
  | 'md:h-2.5'
  | 'md:h-3.5'
  | 'md:h-1/2'
  | 'md:h-1/3'
  | 'md:h-2/3'
  | 'md:h-1/4'
  | 'md:h-2/4'
  | 'md:h-3/4'
  | 'md:h-1/5'
  | 'md:h-2/5'
  | 'md:h-3/5'
  | 'md:h-4/5'
  | 'md:h-1/6'
  | 'md:h-2/6'
  | 'md:h-3/6'
  | 'md:h-4/6'
  | 'md:h-5/6'
  | 'md:h-1/12'
  | 'md:h-2/12'
  | 'md:h-3/12'
  | 'md:h-4/12'
  | 'md:h-5/12'
  | 'md:h-6/12'
  | 'md:h-7/12'
  | 'md:h-8/12'
  | 'md:h-9/12'
  | 'md:h-10/12'
  | 'md:h-11/12'
  | 'md:h-full'
  | 'md:h-screen'
  | 'md:leading-3'
  | 'md:leading-4'
  | 'md:leading-5'
  | 'md:leading-6'
  | 'md:leading-7'
  | 'md:leading-8'
  | 'md:leading-9'
  | 'md:leading-10'
  | 'md:leading-none'
  | 'md:leading-tight'
  | 'md:leading-snug'
  | 'md:leading-normal'
  | 'md:leading-relaxed'
  | 'md:leading-loose'
  | 'md:list-inside'
  | 'md:list-outside'
  | 'md:list-none'
  | 'md:list-disc'
  | 'md:list-decimal'
  | 'md:m-0'
  | 'md:m-1'
  | 'md:m-2'
  | 'md:m-3'
  | 'md:m-4'
  | 'md:m-5'
  | 'md:m-6'
  | 'md:m-7'
  | 'md:m-8'
  | 'md:m-9'
  | 'md:m-10'
  | 'md:m-11'
  | 'md:m-12'
  | 'md:m-13'
  | 'md:m-14'
  | 'md:m-15'
  | 'md:m-16'
  | 'md:m-20'
  | 'md:m-24'
  | 'md:m-28'
  | 'md:m-32'
  | 'md:m-36'
  | 'md:m-40'
  | 'md:m-48'
  | 'md:m-56'
  | 'md:m-60'
  | 'md:m-64'
  | 'md:m-72'
  | 'md:m-80'
  | 'md:m-96'
  | 'md:m-auto'
  | 'md:m-px'
  | 'md:m-0.5'
  | 'md:m-1.5'
  | 'md:m-2.5'
  | 'md:m-3.5'
  | 'md:m-1/2'
  | 'md:m-1/3'
  | 'md:m-2/3'
  | 'md:m-1/4'
  | 'md:m-2/4'
  | 'md:m-3/4'
  | 'md:m-1/5'
  | 'md:m-2/5'
  | 'md:m-3/5'
  | 'md:m-4/5'
  | 'md:m-1/6'
  | 'md:m-2/6'
  | 'md:m-3/6'
  | 'md:m-4/6'
  | 'md:m-5/6'
  | 'md:m-1/12'
  | 'md:m-2/12'
  | 'md:m-3/12'
  | 'md:m-4/12'
  | 'md:m-5/12'
  | 'md:m-6/12'
  | 'md:m-7/12'
  | 'md:m-8/12'
  | 'md:m-9/12'
  | 'md:m-10/12'
  | 'md:m-11/12'
  | 'md:m-full'
  | 'md:-m-1'
  | 'md:-m-2'
  | 'md:-m-3'
  | 'md:-m-4'
  | 'md:-m-5'
  | 'md:-m-6'
  | 'md:-m-7'
  | 'md:-m-8'
  | 'md:-m-9'
  | 'md:-m-10'
  | 'md:-m-11'
  | 'md:-m-12'
  | 'md:-m-13'
  | 'md:-m-14'
  | 'md:-m-15'
  | 'md:-m-16'
  | 'md:-m-20'
  | 'md:-m-24'
  | 'md:-m-28'
  | 'md:-m-32'
  | 'md:-m-36'
  | 'md:-m-40'
  | 'md:-m-48'
  | 'md:-m-56'
  | 'md:-m-60'
  | 'md:-m-64'
  | 'md:-m-72'
  | 'md:-m-80'
  | 'md:-m-96'
  | 'md:-m-px'
  | 'md:-m-0.5'
  | 'md:-m-1.5'
  | 'md:-m-2.5'
  | 'md:-m-3.5'
  | 'md:-m-1/2'
  | 'md:-m-1/3'
  | 'md:-m-2/3'
  | 'md:-m-1/4'
  | 'md:-m-2/4'
  | 'md:-m-3/4'
  | 'md:-m-1/5'
  | 'md:-m-2/5'
  | 'md:-m-3/5'
  | 'md:-m-4/5'
  | 'md:-m-1/6'
  | 'md:-m-2/6'
  | 'md:-m-3/6'
  | 'md:-m-4/6'
  | 'md:-m-5/6'
  | 'md:-m-1/12'
  | 'md:-m-2/12'
  | 'md:-m-3/12'
  | 'md:-m-4/12'
  | 'md:-m-5/12'
  | 'md:-m-6/12'
  | 'md:-m-7/12'
  | 'md:-m-8/12'
  | 'md:-m-9/12'
  | 'md:-m-10/12'
  | 'md:-m-11/12'
  | 'md:-m-full'
  | 'md:my-0'
  | 'md:mx-0'
  | 'md:my-1'
  | 'md:mx-1'
  | 'md:my-2'
  | 'md:mx-2'
  | 'md:my-3'
  | 'md:mx-3'
  | 'md:my-4'
  | 'md:mx-4'
  | 'md:my-5'
  | 'md:mx-5'
  | 'md:my-6'
  | 'md:mx-6'
  | 'md:my-7'
  | 'md:mx-7'
  | 'md:my-8'
  | 'md:mx-8'
  | 'md:my-9'
  | 'md:mx-9'
  | 'md:my-10'
  | 'md:mx-10'
  | 'md:my-11'
  | 'md:mx-11'
  | 'md:my-12'
  | 'md:mx-12'
  | 'md:my-13'
  | 'md:mx-13'
  | 'md:my-14'
  | 'md:mx-14'
  | 'md:my-15'
  | 'md:mx-15'
  | 'md:my-16'
  | 'md:mx-16'
  | 'md:my-20'
  | 'md:mx-20'
  | 'md:my-24'
  | 'md:mx-24'
  | 'md:my-28'
  | 'md:mx-28'
  | 'md:my-32'
  | 'md:mx-32'
  | 'md:my-36'
  | 'md:mx-36'
  | 'md:my-40'
  | 'md:mx-40'
  | 'md:my-48'
  | 'md:mx-48'
  | 'md:my-56'
  | 'md:mx-56'
  | 'md:my-60'
  | 'md:mx-60'
  | 'md:my-64'
  | 'md:mx-64'
  | 'md:my-72'
  | 'md:mx-72'
  | 'md:my-80'
  | 'md:mx-80'
  | 'md:my-96'
  | 'md:mx-96'
  | 'md:my-auto'
  | 'md:mx-auto'
  | 'md:my-px'
  | 'md:mx-px'
  | 'md:my-0.5'
  | 'md:mx-0.5'
  | 'md:my-1.5'
  | 'md:mx-1.5'
  | 'md:my-2.5'
  | 'md:mx-2.5'
  | 'md:my-3.5'
  | 'md:mx-3.5'
  | 'md:my-1/2'
  | 'md:mx-1/2'
  | 'md:my-1/3'
  | 'md:mx-1/3'
  | 'md:my-2/3'
  | 'md:mx-2/3'
  | 'md:my-1/4'
  | 'md:mx-1/4'
  | 'md:my-2/4'
  | 'md:mx-2/4'
  | 'md:my-3/4'
  | 'md:mx-3/4'
  | 'md:my-1/5'
  | 'md:mx-1/5'
  | 'md:my-2/5'
  | 'md:mx-2/5'
  | 'md:my-3/5'
  | 'md:mx-3/5'
  | 'md:my-4/5'
  | 'md:mx-4/5'
  | 'md:my-1/6'
  | 'md:mx-1/6'
  | 'md:my-2/6'
  | 'md:mx-2/6'
  | 'md:my-3/6'
  | 'md:mx-3/6'
  | 'md:my-4/6'
  | 'md:mx-4/6'
  | 'md:my-5/6'
  | 'md:mx-5/6'
  | 'md:my-1/12'
  | 'md:mx-1/12'
  | 'md:my-2/12'
  | 'md:mx-2/12'
  | 'md:my-3/12'
  | 'md:mx-3/12'
  | 'md:my-4/12'
  | 'md:mx-4/12'
  | 'md:my-5/12'
  | 'md:mx-5/12'
  | 'md:my-6/12'
  | 'md:mx-6/12'
  | 'md:my-7/12'
  | 'md:mx-7/12'
  | 'md:my-8/12'
  | 'md:mx-8/12'
  | 'md:my-9/12'
  | 'md:mx-9/12'
  | 'md:my-10/12'
  | 'md:mx-10/12'
  | 'md:my-11/12'
  | 'md:mx-11/12'
  | 'md:my-full'
  | 'md:mx-full'
  | 'md:-my-1'
  | 'md:-mx-1'
  | 'md:-my-2'
  | 'md:-mx-2'
  | 'md:-my-3'
  | 'md:-mx-3'
  | 'md:-my-4'
  | 'md:-mx-4'
  | 'md:-my-5'
  | 'md:-mx-5'
  | 'md:-my-6'
  | 'md:-mx-6'
  | 'md:-my-7'
  | 'md:-mx-7'
  | 'md:-my-8'
  | 'md:-mx-8'
  | 'md:-my-9'
  | 'md:-mx-9'
  | 'md:-my-10'
  | 'md:-mx-10'
  | 'md:-my-11'
  | 'md:-mx-11'
  | 'md:-my-12'
  | 'md:-mx-12'
  | 'md:-my-13'
  | 'md:-mx-13'
  | 'md:-my-14'
  | 'md:-mx-14'
  | 'md:-my-15'
  | 'md:-mx-15'
  | 'md:-my-16'
  | 'md:-mx-16'
  | 'md:-my-20'
  | 'md:-mx-20'
  | 'md:-my-24'
  | 'md:-mx-24'
  | 'md:-my-28'
  | 'md:-mx-28'
  | 'md:-my-32'
  | 'md:-mx-32'
  | 'md:-my-36'
  | 'md:-mx-36'
  | 'md:-my-40'
  | 'md:-mx-40'
  | 'md:-my-48'
  | 'md:-mx-48'
  | 'md:-my-56'
  | 'md:-mx-56'
  | 'md:-my-60'
  | 'md:-mx-60'
  | 'md:-my-64'
  | 'md:-mx-64'
  | 'md:-my-72'
  | 'md:-mx-72'
  | 'md:-my-80'
  | 'md:-mx-80'
  | 'md:-my-96'
  | 'md:-mx-96'
  | 'md:-my-px'
  | 'md:-mx-px'
  | 'md:-my-0.5'
  | 'md:-mx-0.5'
  | 'md:-my-1.5'
  | 'md:-mx-1.5'
  | 'md:-my-2.5'
  | 'md:-mx-2.5'
  | 'md:-my-3.5'
  | 'md:-mx-3.5'
  | 'md:-my-1/2'
  | 'md:-mx-1/2'
  | 'md:-my-1/3'
  | 'md:-mx-1/3'
  | 'md:-my-2/3'
  | 'md:-mx-2/3'
  | 'md:-my-1/4'
  | 'md:-mx-1/4'
  | 'md:-my-2/4'
  | 'md:-mx-2/4'
  | 'md:-my-3/4'
  | 'md:-mx-3/4'
  | 'md:-my-1/5'
  | 'md:-mx-1/5'
  | 'md:-my-2/5'
  | 'md:-mx-2/5'
  | 'md:-my-3/5'
  | 'md:-mx-3/5'
  | 'md:-my-4/5'
  | 'md:-mx-4/5'
  | 'md:-my-1/6'
  | 'md:-mx-1/6'
  | 'md:-my-2/6'
  | 'md:-mx-2/6'
  | 'md:-my-3/6'
  | 'md:-mx-3/6'
  | 'md:-my-4/6'
  | 'md:-mx-4/6'
  | 'md:-my-5/6'
  | 'md:-mx-5/6'
  | 'md:-my-1/12'
  | 'md:-mx-1/12'
  | 'md:-my-2/12'
  | 'md:-mx-2/12'
  | 'md:-my-3/12'
  | 'md:-mx-3/12'
  | 'md:-my-4/12'
  | 'md:-mx-4/12'
  | 'md:-my-5/12'
  | 'md:-mx-5/12'
  | 'md:-my-6/12'
  | 'md:-mx-6/12'
  | 'md:-my-7/12'
  | 'md:-mx-7/12'
  | 'md:-my-8/12'
  | 'md:-mx-8/12'
  | 'md:-my-9/12'
  | 'md:-mx-9/12'
  | 'md:-my-10/12'
  | 'md:-mx-10/12'
  | 'md:-my-11/12'
  | 'md:-mx-11/12'
  | 'md:-my-full'
  | 'md:-mx-full'
  | 'md:mt-0'
  | 'md:mr-0'
  | 'md:mb-0'
  | 'md:ml-0'
  | 'md:mt-1'
  | 'md:mr-1'
  | 'md:mb-1'
  | 'md:ml-1'
  | 'md:mt-2'
  | 'md:mr-2'
  | 'md:mb-2'
  | 'md:ml-2'
  | 'md:mt-3'
  | 'md:mr-3'
  | 'md:mb-3'
  | 'md:ml-3'
  | 'md:mt-4'
  | 'md:mr-4'
  | 'md:mb-4'
  | 'md:ml-4'
  | 'md:mt-5'
  | 'md:mr-5'
  | 'md:mb-5'
  | 'md:ml-5'
  | 'md:mt-6'
  | 'md:mr-6'
  | 'md:mb-6'
  | 'md:ml-6'
  | 'md:mt-7'
  | 'md:mr-7'
  | 'md:mb-7'
  | 'md:ml-7'
  | 'md:mt-8'
  | 'md:mr-8'
  | 'md:mb-8'
  | 'md:ml-8'
  | 'md:mt-9'
  | 'md:mr-9'
  | 'md:mb-9'
  | 'md:ml-9'
  | 'md:mt-10'
  | 'md:mr-10'
  | 'md:mb-10'
  | 'md:ml-10'
  | 'md:mt-11'
  | 'md:mr-11'
  | 'md:mb-11'
  | 'md:ml-11'
  | 'md:mt-12'
  | 'md:mr-12'
  | 'md:mb-12'
  | 'md:ml-12'
  | 'md:mt-13'
  | 'md:mr-13'
  | 'md:mb-13'
  | 'md:ml-13'
  | 'md:mt-14'
  | 'md:mr-14'
  | 'md:mb-14'
  | 'md:ml-14'
  | 'md:mt-15'
  | 'md:mr-15'
  | 'md:mb-15'
  | 'md:ml-15'
  | 'md:mt-16'
  | 'md:mr-16'
  | 'md:mb-16'
  | 'md:ml-16'
  | 'md:mt-20'
  | 'md:mr-20'
  | 'md:mb-20'
  | 'md:ml-20'
  | 'md:mt-24'
  | 'md:mr-24'
  | 'md:mb-24'
  | 'md:ml-24'
  | 'md:mt-28'
  | 'md:mr-28'
  | 'md:mb-28'
  | 'md:ml-28'
  | 'md:mt-32'
  | 'md:mr-32'
  | 'md:mb-32'
  | 'md:ml-32'
  | 'md:mt-36'
  | 'md:mr-36'
  | 'md:mb-36'
  | 'md:ml-36'
  | 'md:mt-40'
  | 'md:mr-40'
  | 'md:mb-40'
  | 'md:ml-40'
  | 'md:mt-48'
  | 'md:mr-48'
  | 'md:mb-48'
  | 'md:ml-48'
  | 'md:mt-56'
  | 'md:mr-56'
  | 'md:mb-56'
  | 'md:ml-56'
  | 'md:mt-60'
  | 'md:mr-60'
  | 'md:mb-60'
  | 'md:ml-60'
  | 'md:mt-64'
  | 'md:mr-64'
  | 'md:mb-64'
  | 'md:ml-64'
  | 'md:mt-72'
  | 'md:mr-72'
  | 'md:mb-72'
  | 'md:ml-72'
  | 'md:mt-80'
  | 'md:mr-80'
  | 'md:mb-80'
  | 'md:ml-80'
  | 'md:mt-96'
  | 'md:mr-96'
  | 'md:mb-96'
  | 'md:ml-96'
  | 'md:mt-auto'
  | 'md:mr-auto'
  | 'md:mb-auto'
  | 'md:ml-auto'
  | 'md:mt-px'
  | 'md:mr-px'
  | 'md:mb-px'
  | 'md:ml-px'
  | 'md:mt-0.5'
  | 'md:mr-0.5'
  | 'md:mb-0.5'
  | 'md:ml-0.5'
  | 'md:mt-1.5'
  | 'md:mr-1.5'
  | 'md:mb-1.5'
  | 'md:ml-1.5'
  | 'md:mt-2.5'
  | 'md:mr-2.5'
  | 'md:mb-2.5'
  | 'md:ml-2.5'
  | 'md:mt-3.5'
  | 'md:mr-3.5'
  | 'md:mb-3.5'
  | 'md:ml-3.5'
  | 'md:mt-1/2'
  | 'md:mr-1/2'
  | 'md:mb-1/2'
  | 'md:ml-1/2'
  | 'md:mt-1/3'
  | 'md:mr-1/3'
  | 'md:mb-1/3'
  | 'md:ml-1/3'
  | 'md:mt-2/3'
  | 'md:mr-2/3'
  | 'md:mb-2/3'
  | 'md:ml-2/3'
  | 'md:mt-1/4'
  | 'md:mr-1/4'
  | 'md:mb-1/4'
  | 'md:ml-1/4'
  | 'md:mt-2/4'
  | 'md:mr-2/4'
  | 'md:mb-2/4'
  | 'md:ml-2/4'
  | 'md:mt-3/4'
  | 'md:mr-3/4'
  | 'md:mb-3/4'
  | 'md:ml-3/4'
  | 'md:mt-1/5'
  | 'md:mr-1/5'
  | 'md:mb-1/5'
  | 'md:ml-1/5'
  | 'md:mt-2/5'
  | 'md:mr-2/5'
  | 'md:mb-2/5'
  | 'md:ml-2/5'
  | 'md:mt-3/5'
  | 'md:mr-3/5'
  | 'md:mb-3/5'
  | 'md:ml-3/5'
  | 'md:mt-4/5'
  | 'md:mr-4/5'
  | 'md:mb-4/5'
  | 'md:ml-4/5'
  | 'md:mt-1/6'
  | 'md:mr-1/6'
  | 'md:mb-1/6'
  | 'md:ml-1/6'
  | 'md:mt-2/6'
  | 'md:mr-2/6'
  | 'md:mb-2/6'
  | 'md:ml-2/6'
  | 'md:mt-3/6'
  | 'md:mr-3/6'
  | 'md:mb-3/6'
  | 'md:ml-3/6'
  | 'md:mt-4/6'
  | 'md:mr-4/6'
  | 'md:mb-4/6'
  | 'md:ml-4/6'
  | 'md:mt-5/6'
  | 'md:mr-5/6'
  | 'md:mb-5/6'
  | 'md:ml-5/6'
  | 'md:mt-1/12'
  | 'md:mr-1/12'
  | 'md:mb-1/12'
  | 'md:ml-1/12'
  | 'md:mt-2/12'
  | 'md:mr-2/12'
  | 'md:mb-2/12'
  | 'md:ml-2/12'
  | 'md:mt-3/12'
  | 'md:mr-3/12'
  | 'md:mb-3/12'
  | 'md:ml-3/12'
  | 'md:mt-4/12'
  | 'md:mr-4/12'
  | 'md:mb-4/12'
  | 'md:ml-4/12'
  | 'md:mt-5/12'
  | 'md:mr-5/12'
  | 'md:mb-5/12'
  | 'md:ml-5/12'
  | 'md:mt-6/12'
  | 'md:mr-6/12'
  | 'md:mb-6/12'
  | 'md:ml-6/12'
  | 'md:mt-7/12'
  | 'md:mr-7/12'
  | 'md:mb-7/12'
  | 'md:ml-7/12'
  | 'md:mt-8/12'
  | 'md:mr-8/12'
  | 'md:mb-8/12'
  | 'md:ml-8/12'
  | 'md:mt-9/12'
  | 'md:mr-9/12'
  | 'md:mb-9/12'
  | 'md:ml-9/12'
  | 'md:mt-10/12'
  | 'md:mr-10/12'
  | 'md:mb-10/12'
  | 'md:ml-10/12'
  | 'md:mt-11/12'
  | 'md:mr-11/12'
  | 'md:mb-11/12'
  | 'md:ml-11/12'
  | 'md:mt-full'
  | 'md:mr-full'
  | 'md:mb-full'
  | 'md:ml-full'
  | 'md:-mt-1'
  | 'md:-mr-1'
  | 'md:-mb-1'
  | 'md:-ml-1'
  | 'md:-mt-2'
  | 'md:-mr-2'
  | 'md:-mb-2'
  | 'md:-ml-2'
  | 'md:-mt-3'
  | 'md:-mr-3'
  | 'md:-mb-3'
  | 'md:-ml-3'
  | 'md:-mt-4'
  | 'md:-mr-4'
  | 'md:-mb-4'
  | 'md:-ml-4'
  | 'md:-mt-5'
  | 'md:-mr-5'
  | 'md:-mb-5'
  | 'md:-ml-5'
  | 'md:-mt-6'
  | 'md:-mr-6'
  | 'md:-mb-6'
  | 'md:-ml-6'
  | 'md:-mt-7'
  | 'md:-mr-7'
  | 'md:-mb-7'
  | 'md:-ml-7'
  | 'md:-mt-8'
  | 'md:-mr-8'
  | 'md:-mb-8'
  | 'md:-ml-8'
  | 'md:-mt-9'
  | 'md:-mr-9'
  | 'md:-mb-9'
  | 'md:-ml-9'
  | 'md:-mt-10'
  | 'md:-mr-10'
  | 'md:-mb-10'
  | 'md:-ml-10'
  | 'md:-mt-11'
  | 'md:-mr-11'
  | 'md:-mb-11'
  | 'md:-ml-11'
  | 'md:-mt-12'
  | 'md:-mr-12'
  | 'md:-mb-12'
  | 'md:-ml-12'
  | 'md:-mt-13'
  | 'md:-mr-13'
  | 'md:-mb-13'
  | 'md:-ml-13'
  | 'md:-mt-14'
  | 'md:-mr-14'
  | 'md:-mb-14'
  | 'md:-ml-14'
  | 'md:-mt-15'
  | 'md:-mr-15'
  | 'md:-mb-15'
  | 'md:-ml-15'
  | 'md:-mt-16'
  | 'md:-mr-16'
  | 'md:-mb-16'
  | 'md:-ml-16'
  | 'md:-mt-20'
  | 'md:-mr-20'
  | 'md:-mb-20'
  | 'md:-ml-20'
  | 'md:-mt-24'
  | 'md:-mr-24'
  | 'md:-mb-24'
  | 'md:-ml-24'
  | 'md:-mt-28'
  | 'md:-mr-28'
  | 'md:-mb-28'
  | 'md:-ml-28'
  | 'md:-mt-32'
  | 'md:-mr-32'
  | 'md:-mb-32'
  | 'md:-ml-32'
  | 'md:-mt-36'
  | 'md:-mr-36'
  | 'md:-mb-36'
  | 'md:-ml-36'
  | 'md:-mt-40'
  | 'md:-mr-40'
  | 'md:-mb-40'
  | 'md:-ml-40'
  | 'md:-mt-48'
  | 'md:-mr-48'
  | 'md:-mb-48'
  | 'md:-ml-48'
  | 'md:-mt-56'
  | 'md:-mr-56'
  | 'md:-mb-56'
  | 'md:-ml-56'
  | 'md:-mt-60'
  | 'md:-mr-60'
  | 'md:-mb-60'
  | 'md:-ml-60'
  | 'md:-mt-64'
  | 'md:-mr-64'
  | 'md:-mb-64'
  | 'md:-ml-64'
  | 'md:-mt-72'
  | 'md:-mr-72'
  | 'md:-mb-72'
  | 'md:-ml-72'
  | 'md:-mt-80'
  | 'md:-mr-80'
  | 'md:-mb-80'
  | 'md:-ml-80'
  | 'md:-mt-96'
  | 'md:-mr-96'
  | 'md:-mb-96'
  | 'md:-ml-96'
  | 'md:-mt-px'
  | 'md:-mr-px'
  | 'md:-mb-px'
  | 'md:-ml-px'
  | 'md:-mt-0.5'
  | 'md:-mr-0.5'
  | 'md:-mb-0.5'
  | 'md:-ml-0.5'
  | 'md:-mt-1.5'
  | 'md:-mr-1.5'
  | 'md:-mb-1.5'
  | 'md:-ml-1.5'
  | 'md:-mt-2.5'
  | 'md:-mr-2.5'
  | 'md:-mb-2.5'
  | 'md:-ml-2.5'
  | 'md:-mt-3.5'
  | 'md:-mr-3.5'
  | 'md:-mb-3.5'
  | 'md:-ml-3.5'
  | 'md:-mt-1/2'
  | 'md:-mr-1/2'
  | 'md:-mb-1/2'
  | 'md:-ml-1/2'
  | 'md:-mt-1/3'
  | 'md:-mr-1/3'
  | 'md:-mb-1/3'
  | 'md:-ml-1/3'
  | 'md:-mt-2/3'
  | 'md:-mr-2/3'
  | 'md:-mb-2/3'
  | 'md:-ml-2/3'
  | 'md:-mt-1/4'
  | 'md:-mr-1/4'
  | 'md:-mb-1/4'
  | 'md:-ml-1/4'
  | 'md:-mt-2/4'
  | 'md:-mr-2/4'
  | 'md:-mb-2/4'
  | 'md:-ml-2/4'
  | 'md:-mt-3/4'
  | 'md:-mr-3/4'
  | 'md:-mb-3/4'
  | 'md:-ml-3/4'
  | 'md:-mt-1/5'
  | 'md:-mr-1/5'
  | 'md:-mb-1/5'
  | 'md:-ml-1/5'
  | 'md:-mt-2/5'
  | 'md:-mr-2/5'
  | 'md:-mb-2/5'
  | 'md:-ml-2/5'
  | 'md:-mt-3/5'
  | 'md:-mr-3/5'
  | 'md:-mb-3/5'
  | 'md:-ml-3/5'
  | 'md:-mt-4/5'
  | 'md:-mr-4/5'
  | 'md:-mb-4/5'
  | 'md:-ml-4/5'
  | 'md:-mt-1/6'
  | 'md:-mr-1/6'
  | 'md:-mb-1/6'
  | 'md:-ml-1/6'
  | 'md:-mt-2/6'
  | 'md:-mr-2/6'
  | 'md:-mb-2/6'
  | 'md:-ml-2/6'
  | 'md:-mt-3/6'
  | 'md:-mr-3/6'
  | 'md:-mb-3/6'
  | 'md:-ml-3/6'
  | 'md:-mt-4/6'
  | 'md:-mr-4/6'
  | 'md:-mb-4/6'
  | 'md:-ml-4/6'
  | 'md:-mt-5/6'
  | 'md:-mr-5/6'
  | 'md:-mb-5/6'
  | 'md:-ml-5/6'
  | 'md:-mt-1/12'
  | 'md:-mr-1/12'
  | 'md:-mb-1/12'
  | 'md:-ml-1/12'
  | 'md:-mt-2/12'
  | 'md:-mr-2/12'
  | 'md:-mb-2/12'
  | 'md:-ml-2/12'
  | 'md:-mt-3/12'
  | 'md:-mr-3/12'
  | 'md:-mb-3/12'
  | 'md:-ml-3/12'
  | 'md:-mt-4/12'
  | 'md:-mr-4/12'
  | 'md:-mb-4/12'
  | 'md:-ml-4/12'
  | 'md:-mt-5/12'
  | 'md:-mr-5/12'
  | 'md:-mb-5/12'
  | 'md:-ml-5/12'
  | 'md:-mt-6/12'
  | 'md:-mr-6/12'
  | 'md:-mb-6/12'
  | 'md:-ml-6/12'
  | 'md:-mt-7/12'
  | 'md:-mr-7/12'
  | 'md:-mb-7/12'
  | 'md:-ml-7/12'
  | 'md:-mt-8/12'
  | 'md:-mr-8/12'
  | 'md:-mb-8/12'
  | 'md:-ml-8/12'
  | 'md:-mt-9/12'
  | 'md:-mr-9/12'
  | 'md:-mb-9/12'
  | 'md:-ml-9/12'
  | 'md:-mt-10/12'
  | 'md:-mr-10/12'
  | 'md:-mb-10/12'
  | 'md:-ml-10/12'
  | 'md:-mt-11/12'
  | 'md:-mr-11/12'
  | 'md:-mb-11/12'
  | 'md:-ml-11/12'
  | 'md:-mt-full'
  | 'md:-mr-full'
  | 'md:-mb-full'
  | 'md:-ml-full'
  | 'md:max-h-0'
  | 'md:max-h-1'
  | 'md:max-h-2'
  | 'md:max-h-3'
  | 'md:max-h-4'
  | 'md:max-h-5'
  | 'md:max-h-6'
  | 'md:max-h-7'
  | 'md:max-h-8'
  | 'md:max-h-9'
  | 'md:max-h-10'
  | 'md:max-h-11'
  | 'md:max-h-12'
  | 'md:max-h-13'
  | 'md:max-h-14'
  | 'md:max-h-15'
  | 'md:max-h-16'
  | 'md:max-h-20'
  | 'md:max-h-24'
  | 'md:max-h-28'
  | 'md:max-h-32'
  | 'md:max-h-36'
  | 'md:max-h-40'
  | 'md:max-h-48'
  | 'md:max-h-56'
  | 'md:max-h-60'
  | 'md:max-h-64'
  | 'md:max-h-72'
  | 'md:max-h-80'
  | 'md:max-h-96'
  | 'md:max-h-screen'
  | 'md:max-h-px'
  | 'md:max-h-0.5'
  | 'md:max-h-1.5'
  | 'md:max-h-2.5'
  | 'md:max-h-3.5'
  | 'md:max-h-1/2'
  | 'md:max-h-1/3'
  | 'md:max-h-2/3'
  | 'md:max-h-1/4'
  | 'md:max-h-2/4'
  | 'md:max-h-3/4'
  | 'md:max-h-1/5'
  | 'md:max-h-2/5'
  | 'md:max-h-3/5'
  | 'md:max-h-4/5'
  | 'md:max-h-1/6'
  | 'md:max-h-2/6'
  | 'md:max-h-3/6'
  | 'md:max-h-4/6'
  | 'md:max-h-5/6'
  | 'md:max-h-1/12'
  | 'md:max-h-2/12'
  | 'md:max-h-3/12'
  | 'md:max-h-4/12'
  | 'md:max-h-5/12'
  | 'md:max-h-6/12'
  | 'md:max-h-7/12'
  | 'md:max-h-8/12'
  | 'md:max-h-9/12'
  | 'md:max-h-10/12'
  | 'md:max-h-11/12'
  | 'md:max-h-full'
  | 'md:max-w-none'
  | 'md:max-w-xs'
  | 'md:max-w-sm'
  | 'md:max-w-md'
  | 'md:max-w-lg'
  | 'md:max-w-xl'
  | 'md:max-w-2xl'
  | 'md:max-w-3xl'
  | 'md:max-w-4xl'
  | 'md:max-w-5xl'
  | 'md:max-w-6xl'
  | 'md:max-w-7xl'
  | 'md:max-w-full'
  | 'md:max-w-screen-sm'
  | 'md:max-w-screen-md'
  | 'md:max-w-screen-lg'
  | 'md:max-w-screen-xl'
  | 'md:min-h-0'
  | 'md:min-h-full'
  | 'md:min-h-screen'
  | 'md:min-w-0'
  | 'md:min-w-full'
  | 'md:object-contain'
  | 'md:object-cover'
  | 'md:object-fill'
  | 'md:object-none'
  | 'md:object-scale-down'
  | 'md:object-bottom'
  | 'md:object-center'
  | 'md:object-left'
  | 'md:object-left-bottom'
  | 'md:object-left-top'
  | 'md:object-right'
  | 'md:object-right-bottom'
  | 'md:object-right-top'
  | 'md:object-top'
  | 'md:opacity-0'
  | 'md:opacity-25'
  | 'md:opacity-50'
  | 'md:opacity-75'
  | 'md:opacity-100'
  | 'md:hover:opacity-0'
  | 'md:hover:opacity-25'
  | 'md:hover:opacity-50'
  | 'md:hover:opacity-75'
  | 'md:hover:opacity-100'
  | 'md:focus:opacity-0'
  | 'md:focus:opacity-25'
  | 'md:focus:opacity-50'
  | 'md:focus:opacity-75'
  | 'md:focus:opacity-100'
  | 'md:outline-none'
  | 'md:focus:outline-none'
  | 'md:overflow-auto'
  | 'md:overflow-hidden'
  | 'md:overflow-visible'
  | 'md:overflow-scroll'
  | 'md:overflow-x-auto'
  | 'md:overflow-y-auto'
  | 'md:overflow-x-hidden'
  | 'md:overflow-y-hidden'
  | 'md:overflow-x-visible'
  | 'md:overflow-y-visible'
  | 'md:overflow-x-scroll'
  | 'md:overflow-y-scroll'
  | 'md:scrolling-touch'
  | 'md:scrolling-auto'
  | 'md:p-0'
  | 'md:p-1'
  | 'md:p-2'
  | 'md:p-3'
  | 'md:p-4'
  | 'md:p-5'
  | 'md:p-6'
  | 'md:p-7'
  | 'md:p-8'
  | 'md:p-9'
  | 'md:p-10'
  | 'md:p-11'
  | 'md:p-12'
  | 'md:p-13'
  | 'md:p-14'
  | 'md:p-15'
  | 'md:p-16'
  | 'md:p-20'
  | 'md:p-24'
  | 'md:p-28'
  | 'md:p-32'
  | 'md:p-36'
  | 'md:p-40'
  | 'md:p-48'
  | 'md:p-56'
  | 'md:p-60'
  | 'md:p-64'
  | 'md:p-72'
  | 'md:p-80'
  | 'md:p-96'
  | 'md:p-px'
  | 'md:p-0.5'
  | 'md:p-1.5'
  | 'md:p-2.5'
  | 'md:p-3.5'
  | 'md:p-1/2'
  | 'md:p-1/3'
  | 'md:p-2/3'
  | 'md:p-1/4'
  | 'md:p-2/4'
  | 'md:p-3/4'
  | 'md:p-1/5'
  | 'md:p-2/5'
  | 'md:p-3/5'
  | 'md:p-4/5'
  | 'md:p-1/6'
  | 'md:p-2/6'
  | 'md:p-3/6'
  | 'md:p-4/6'
  | 'md:p-5/6'
  | 'md:p-1/12'
  | 'md:p-2/12'
  | 'md:p-3/12'
  | 'md:p-4/12'
  | 'md:p-5/12'
  | 'md:p-6/12'
  | 'md:p-7/12'
  | 'md:p-8/12'
  | 'md:p-9/12'
  | 'md:p-10/12'
  | 'md:p-11/12'
  | 'md:p-full'
  | 'md:py-0'
  | 'md:px-0'
  | 'md:py-1'
  | 'md:px-1'
  | 'md:py-2'
  | 'md:px-2'
  | 'md:py-3'
  | 'md:px-3'
  | 'md:py-4'
  | 'md:px-4'
  | 'md:py-5'
  | 'md:px-5'
  | 'md:py-6'
  | 'md:px-6'
  | 'md:py-7'
  | 'md:px-7'
  | 'md:py-8'
  | 'md:px-8'
  | 'md:py-9'
  | 'md:px-9'
  | 'md:py-10'
  | 'md:px-10'
  | 'md:py-11'
  | 'md:px-11'
  | 'md:py-12'
  | 'md:px-12'
  | 'md:py-13'
  | 'md:px-13'
  | 'md:py-14'
  | 'md:px-14'
  | 'md:py-15'
  | 'md:px-15'
  | 'md:py-16'
  | 'md:px-16'
  | 'md:py-20'
  | 'md:px-20'
  | 'md:py-24'
  | 'md:px-24'
  | 'md:py-28'
  | 'md:px-28'
  | 'md:py-32'
  | 'md:px-32'
  | 'md:py-36'
  | 'md:px-36'
  | 'md:py-40'
  | 'md:px-40'
  | 'md:py-48'
  | 'md:px-48'
  | 'md:py-56'
  | 'md:px-56'
  | 'md:py-60'
  | 'md:px-60'
  | 'md:py-64'
  | 'md:px-64'
  | 'md:py-72'
  | 'md:px-72'
  | 'md:py-80'
  | 'md:px-80'
  | 'md:py-96'
  | 'md:px-96'
  | 'md:py-px'
  | 'md:px-px'
  | 'md:py-0.5'
  | 'md:px-0.5'
  | 'md:py-1.5'
  | 'md:px-1.5'
  | 'md:py-2.5'
  | 'md:px-2.5'
  | 'md:py-3.5'
  | 'md:px-3.5'
  | 'md:py-1/2'
  | 'md:px-1/2'
  | 'md:py-1/3'
  | 'md:px-1/3'
  | 'md:py-2/3'
  | 'md:px-2/3'
  | 'md:py-1/4'
  | 'md:px-1/4'
  | 'md:py-2/4'
  | 'md:px-2/4'
  | 'md:py-3/4'
  | 'md:px-3/4'
  | 'md:py-1/5'
  | 'md:px-1/5'
  | 'md:py-2/5'
  | 'md:px-2/5'
  | 'md:py-3/5'
  | 'md:px-3/5'
  | 'md:py-4/5'
  | 'md:px-4/5'
  | 'md:py-1/6'
  | 'md:px-1/6'
  | 'md:py-2/6'
  | 'md:px-2/6'
  | 'md:py-3/6'
  | 'md:px-3/6'
  | 'md:py-4/6'
  | 'md:px-4/6'
  | 'md:py-5/6'
  | 'md:px-5/6'
  | 'md:py-1/12'
  | 'md:px-1/12'
  | 'md:py-2/12'
  | 'md:px-2/12'
  | 'md:py-3/12'
  | 'md:px-3/12'
  | 'md:py-4/12'
  | 'md:px-4/12'
  | 'md:py-5/12'
  | 'md:px-5/12'
  | 'md:py-6/12'
  | 'md:px-6/12'
  | 'md:py-7/12'
  | 'md:px-7/12'
  | 'md:py-8/12'
  | 'md:px-8/12'
  | 'md:py-9/12'
  | 'md:px-9/12'
  | 'md:py-10/12'
  | 'md:px-10/12'
  | 'md:py-11/12'
  | 'md:px-11/12'
  | 'md:py-full'
  | 'md:px-full'
  | 'md:pt-0'
  | 'md:pr-0'
  | 'md:pb-0'
  | 'md:pl-0'
  | 'md:pt-1'
  | 'md:pr-1'
  | 'md:pb-1'
  | 'md:pl-1'
  | 'md:pt-2'
  | 'md:pr-2'
  | 'md:pb-2'
  | 'md:pl-2'
  | 'md:pt-3'
  | 'md:pr-3'
  | 'md:pb-3'
  | 'md:pl-3'
  | 'md:pt-4'
  | 'md:pr-4'
  | 'md:pb-4'
  | 'md:pl-4'
  | 'md:pt-5'
  | 'md:pr-5'
  | 'md:pb-5'
  | 'md:pl-5'
  | 'md:pt-6'
  | 'md:pr-6'
  | 'md:pb-6'
  | 'md:pl-6'
  | 'md:pt-7'
  | 'md:pr-7'
  | 'md:pb-7'
  | 'md:pl-7'
  | 'md:pt-8'
  | 'md:pr-8'
  | 'md:pb-8'
  | 'md:pl-8'
  | 'md:pt-9'
  | 'md:pr-9'
  | 'md:pb-9'
  | 'md:pl-9'
  | 'md:pt-10'
  | 'md:pr-10'
  | 'md:pb-10'
  | 'md:pl-10'
  | 'md:pt-11'
  | 'md:pr-11'
  | 'md:pb-11'
  | 'md:pl-11'
  | 'md:pt-12'
  | 'md:pr-12'
  | 'md:pb-12'
  | 'md:pl-12'
  | 'md:pt-13'
  | 'md:pr-13'
  | 'md:pb-13'
  | 'md:pl-13'
  | 'md:pt-14'
  | 'md:pr-14'
  | 'md:pb-14'
  | 'md:pl-14'
  | 'md:pt-15'
  | 'md:pr-15'
  | 'md:pb-15'
  | 'md:pl-15'
  | 'md:pt-16'
  | 'md:pr-16'
  | 'md:pb-16'
  | 'md:pl-16'
  | 'md:pt-20'
  | 'md:pr-20'
  | 'md:pb-20'
  | 'md:pl-20'
  | 'md:pt-24'
  | 'md:pr-24'
  | 'md:pb-24'
  | 'md:pl-24'
  | 'md:pt-28'
  | 'md:pr-28'
  | 'md:pb-28'
  | 'md:pl-28'
  | 'md:pt-32'
  | 'md:pr-32'
  | 'md:pb-32'
  | 'md:pl-32'
  | 'md:pt-36'
  | 'md:pr-36'
  | 'md:pb-36'
  | 'md:pl-36'
  | 'md:pt-40'
  | 'md:pr-40'
  | 'md:pb-40'
  | 'md:pl-40'
  | 'md:pt-48'
  | 'md:pr-48'
  | 'md:pb-48'
  | 'md:pl-48'
  | 'md:pt-56'
  | 'md:pr-56'
  | 'md:pb-56'
  | 'md:pl-56'
  | 'md:pt-60'
  | 'md:pr-60'
  | 'md:pb-60'
  | 'md:pl-60'
  | 'md:pt-64'
  | 'md:pr-64'
  | 'md:pb-64'
  | 'md:pl-64'
  | 'md:pt-72'
  | 'md:pr-72'
  | 'md:pb-72'
  | 'md:pl-72'
  | 'md:pt-80'
  | 'md:pr-80'
  | 'md:pb-80'
  | 'md:pl-80'
  | 'md:pt-96'
  | 'md:pr-96'
  | 'md:pb-96'
  | 'md:pl-96'
  | 'md:pt-px'
  | 'md:pr-px'
  | 'md:pb-px'
  | 'md:pl-px'
  | 'md:pt-0.5'
  | 'md:pr-0.5'
  | 'md:pb-0.5'
  | 'md:pl-0.5'
  | 'md:pt-1.5'
  | 'md:pr-1.5'
  | 'md:pb-1.5'
  | 'md:pl-1.5'
  | 'md:pt-2.5'
  | 'md:pr-2.5'
  | 'md:pb-2.5'
  | 'md:pl-2.5'
  | 'md:pt-3.5'
  | 'md:pr-3.5'
  | 'md:pb-3.5'
  | 'md:pl-3.5'
  | 'md:pt-1/2'
  | 'md:pr-1/2'
  | 'md:pb-1/2'
  | 'md:pl-1/2'
  | 'md:pt-1/3'
  | 'md:pr-1/3'
  | 'md:pb-1/3'
  | 'md:pl-1/3'
  | 'md:pt-2/3'
  | 'md:pr-2/3'
  | 'md:pb-2/3'
  | 'md:pl-2/3'
  | 'md:pt-1/4'
  | 'md:pr-1/4'
  | 'md:pb-1/4'
  | 'md:pl-1/4'
  | 'md:pt-2/4'
  | 'md:pr-2/4'
  | 'md:pb-2/4'
  | 'md:pl-2/4'
  | 'md:pt-3/4'
  | 'md:pr-3/4'
  | 'md:pb-3/4'
  | 'md:pl-3/4'
  | 'md:pt-1/5'
  | 'md:pr-1/5'
  | 'md:pb-1/5'
  | 'md:pl-1/5'
  | 'md:pt-2/5'
  | 'md:pr-2/5'
  | 'md:pb-2/5'
  | 'md:pl-2/5'
  | 'md:pt-3/5'
  | 'md:pr-3/5'
  | 'md:pb-3/5'
  | 'md:pl-3/5'
  | 'md:pt-4/5'
  | 'md:pr-4/5'
  | 'md:pb-4/5'
  | 'md:pl-4/5'
  | 'md:pt-1/6'
  | 'md:pr-1/6'
  | 'md:pb-1/6'
  | 'md:pl-1/6'
  | 'md:pt-2/6'
  | 'md:pr-2/6'
  | 'md:pb-2/6'
  | 'md:pl-2/6'
  | 'md:pt-3/6'
  | 'md:pr-3/6'
  | 'md:pb-3/6'
  | 'md:pl-3/6'
  | 'md:pt-4/6'
  | 'md:pr-4/6'
  | 'md:pb-4/6'
  | 'md:pl-4/6'
  | 'md:pt-5/6'
  | 'md:pr-5/6'
  | 'md:pb-5/6'
  | 'md:pl-5/6'
  | 'md:pt-1/12'
  | 'md:pr-1/12'
  | 'md:pb-1/12'
  | 'md:pl-1/12'
  | 'md:pt-2/12'
  | 'md:pr-2/12'
  | 'md:pb-2/12'
  | 'md:pl-2/12'
  | 'md:pt-3/12'
  | 'md:pr-3/12'
  | 'md:pb-3/12'
  | 'md:pl-3/12'
  | 'md:pt-4/12'
  | 'md:pr-4/12'
  | 'md:pb-4/12'
  | 'md:pl-4/12'
  | 'md:pt-5/12'
  | 'md:pr-5/12'
  | 'md:pb-5/12'
  | 'md:pl-5/12'
  | 'md:pt-6/12'
  | 'md:pr-6/12'
  | 'md:pb-6/12'
  | 'md:pl-6/12'
  | 'md:pt-7/12'
  | 'md:pr-7/12'
  | 'md:pb-7/12'
  | 'md:pl-7/12'
  | 'md:pt-8/12'
  | 'md:pr-8/12'
  | 'md:pb-8/12'
  | 'md:pl-8/12'
  | 'md:pt-9/12'
  | 'md:pr-9/12'
  | 'md:pb-9/12'
  | 'md:pl-9/12'
  | 'md:pt-10/12'
  | 'md:pr-10/12'
  | 'md:pb-10/12'
  | 'md:pl-10/12'
  | 'md:pt-11/12'
  | 'md:pr-11/12'
  | 'md:pb-11/12'
  | 'md:pl-11/12'
  | 'md:pt-full'
  | 'md:pr-full'
  | 'md:pb-full'
  | 'md:pl-full'
  | 'md:placeholder-transparent'
  | 'md:placeholder-white'
  | 'md:placeholder-black'
  | 'md:placeholder-gray-50'
  | 'md:placeholder-gray-100'
  | 'md:placeholder-gray-200'
  | 'md:placeholder-gray-300'
  | 'md:placeholder-gray-400'
  | 'md:placeholder-gray-500'
  | 'md:placeholder-gray-600'
  | 'md:placeholder-gray-700'
  | 'md:placeholder-gray-800'
  | 'md:placeholder-gray-900'
  | 'md:placeholder-cool-gray-50'
  | 'md:placeholder-cool-gray-100'
  | 'md:placeholder-cool-gray-200'
  | 'md:placeholder-cool-gray-300'
  | 'md:placeholder-cool-gray-400'
  | 'md:placeholder-cool-gray-500'
  | 'md:placeholder-cool-gray-600'
  | 'md:placeholder-cool-gray-700'
  | 'md:placeholder-cool-gray-800'
  | 'md:placeholder-cool-gray-900'
  | 'md:placeholder-red-50'
  | 'md:placeholder-red-100'
  | 'md:placeholder-red-200'
  | 'md:placeholder-red-300'
  | 'md:placeholder-red-400'
  | 'md:placeholder-red-500'
  | 'md:placeholder-red-600'
  | 'md:placeholder-red-700'
  | 'md:placeholder-red-800'
  | 'md:placeholder-red-900'
  | 'md:placeholder-orange-50'
  | 'md:placeholder-orange-100'
  | 'md:placeholder-orange-200'
  | 'md:placeholder-orange-300'
  | 'md:placeholder-orange-400'
  | 'md:placeholder-orange-500'
  | 'md:placeholder-orange-600'
  | 'md:placeholder-orange-700'
  | 'md:placeholder-orange-800'
  | 'md:placeholder-orange-900'
  | 'md:placeholder-yellow-50'
  | 'md:placeholder-yellow-100'
  | 'md:placeholder-yellow-200'
  | 'md:placeholder-yellow-300'
  | 'md:placeholder-yellow-400'
  | 'md:placeholder-yellow-500'
  | 'md:placeholder-yellow-600'
  | 'md:placeholder-yellow-700'
  | 'md:placeholder-yellow-800'
  | 'md:placeholder-yellow-900'
  | 'md:placeholder-green-50'
  | 'md:placeholder-green-100'
  | 'md:placeholder-green-200'
  | 'md:placeholder-green-300'
  | 'md:placeholder-green-400'
  | 'md:placeholder-green-500'
  | 'md:placeholder-green-600'
  | 'md:placeholder-green-700'
  | 'md:placeholder-green-800'
  | 'md:placeholder-green-900'
  | 'md:placeholder-teal-50'
  | 'md:placeholder-teal-100'
  | 'md:placeholder-teal-200'
  | 'md:placeholder-teal-300'
  | 'md:placeholder-teal-400'
  | 'md:placeholder-teal-500'
  | 'md:placeholder-teal-600'
  | 'md:placeholder-teal-700'
  | 'md:placeholder-teal-800'
  | 'md:placeholder-teal-900'
  | 'md:placeholder-blue-50'
  | 'md:placeholder-blue-100'
  | 'md:placeholder-blue-200'
  | 'md:placeholder-blue-300'
  | 'md:placeholder-blue-400'
  | 'md:placeholder-blue-500'
  | 'md:placeholder-blue-600'
  | 'md:placeholder-blue-700'
  | 'md:placeholder-blue-800'
  | 'md:placeholder-blue-900'
  | 'md:placeholder-indigo-50'
  | 'md:placeholder-indigo-100'
  | 'md:placeholder-indigo-200'
  | 'md:placeholder-indigo-300'
  | 'md:placeholder-indigo-400'
  | 'md:placeholder-indigo-500'
  | 'md:placeholder-indigo-600'
  | 'md:placeholder-indigo-700'
  | 'md:placeholder-indigo-800'
  | 'md:placeholder-indigo-900'
  | 'md:placeholder-purple-50'
  | 'md:placeholder-purple-100'
  | 'md:placeholder-purple-200'
  | 'md:placeholder-purple-300'
  | 'md:placeholder-purple-400'
  | 'md:placeholder-purple-500'
  | 'md:placeholder-purple-600'
  | 'md:placeholder-purple-700'
  | 'md:placeholder-purple-800'
  | 'md:placeholder-purple-900'
  | 'md:placeholder-pink-50'
  | 'md:placeholder-pink-100'
  | 'md:placeholder-pink-200'
  | 'md:placeholder-pink-300'
  | 'md:placeholder-pink-400'
  | 'md:placeholder-pink-500'
  | 'md:placeholder-pink-600'
  | 'md:placeholder-pink-700'
  | 'md:placeholder-pink-800'
  | 'md:placeholder-pink-900'
  | 'md:focus:placeholder-transparent'
  | 'md:focus:placeholder-white'
  | 'md:focus:placeholder-black'
  | 'md:focus:placeholder-gray-50'
  | 'md:focus:placeholder-gray-100'
  | 'md:focus:placeholder-gray-200'
  | 'md:focus:placeholder-gray-300'
  | 'md:focus:placeholder-gray-400'
  | 'md:focus:placeholder-gray-500'
  | 'md:focus:placeholder-gray-600'
  | 'md:focus:placeholder-gray-700'
  | 'md:focus:placeholder-gray-800'
  | 'md:focus:placeholder-gray-900'
  | 'md:focus:placeholder-cool-gray-50'
  | 'md:focus:placeholder-cool-gray-100'
  | 'md:focus:placeholder-cool-gray-200'
  | 'md:focus:placeholder-cool-gray-300'
  | 'md:focus:placeholder-cool-gray-400'
  | 'md:focus:placeholder-cool-gray-500'
  | 'md:focus:placeholder-cool-gray-600'
  | 'md:focus:placeholder-cool-gray-700'
  | 'md:focus:placeholder-cool-gray-800'
  | 'md:focus:placeholder-cool-gray-900'
  | 'md:focus:placeholder-red-50'
  | 'md:focus:placeholder-red-100'
  | 'md:focus:placeholder-red-200'
  | 'md:focus:placeholder-red-300'
  | 'md:focus:placeholder-red-400'
  | 'md:focus:placeholder-red-500'
  | 'md:focus:placeholder-red-600'
  | 'md:focus:placeholder-red-700'
  | 'md:focus:placeholder-red-800'
  | 'md:focus:placeholder-red-900'
  | 'md:focus:placeholder-orange-50'
  | 'md:focus:placeholder-orange-100'
  | 'md:focus:placeholder-orange-200'
  | 'md:focus:placeholder-orange-300'
  | 'md:focus:placeholder-orange-400'
  | 'md:focus:placeholder-orange-500'
  | 'md:focus:placeholder-orange-600'
  | 'md:focus:placeholder-orange-700'
  | 'md:focus:placeholder-orange-800'
  | 'md:focus:placeholder-orange-900'
  | 'md:focus:placeholder-yellow-50'
  | 'md:focus:placeholder-yellow-100'
  | 'md:focus:placeholder-yellow-200'
  | 'md:focus:placeholder-yellow-300'
  | 'md:focus:placeholder-yellow-400'
  | 'md:focus:placeholder-yellow-500'
  | 'md:focus:placeholder-yellow-600'
  | 'md:focus:placeholder-yellow-700'
  | 'md:focus:placeholder-yellow-800'
  | 'md:focus:placeholder-yellow-900'
  | 'md:focus:placeholder-green-50'
  | 'md:focus:placeholder-green-100'
  | 'md:focus:placeholder-green-200'
  | 'md:focus:placeholder-green-300'
  | 'md:focus:placeholder-green-400'
  | 'md:focus:placeholder-green-500'
  | 'md:focus:placeholder-green-600'
  | 'md:focus:placeholder-green-700'
  | 'md:focus:placeholder-green-800'
  | 'md:focus:placeholder-green-900'
  | 'md:focus:placeholder-teal-50'
  | 'md:focus:placeholder-teal-100'
  | 'md:focus:placeholder-teal-200'
  | 'md:focus:placeholder-teal-300'
  | 'md:focus:placeholder-teal-400'
  | 'md:focus:placeholder-teal-500'
  | 'md:focus:placeholder-teal-600'
  | 'md:focus:placeholder-teal-700'
  | 'md:focus:placeholder-teal-800'
  | 'md:focus:placeholder-teal-900'
  | 'md:focus:placeholder-blue-50'
  | 'md:focus:placeholder-blue-100'
  | 'md:focus:placeholder-blue-200'
  | 'md:focus:placeholder-blue-300'
  | 'md:focus:placeholder-blue-400'
  | 'md:focus:placeholder-blue-500'
  | 'md:focus:placeholder-blue-600'
  | 'md:focus:placeholder-blue-700'
  | 'md:focus:placeholder-blue-800'
  | 'md:focus:placeholder-blue-900'
  | 'md:focus:placeholder-indigo-50'
  | 'md:focus:placeholder-indigo-100'
  | 'md:focus:placeholder-indigo-200'
  | 'md:focus:placeholder-indigo-300'
  | 'md:focus:placeholder-indigo-400'
  | 'md:focus:placeholder-indigo-500'
  | 'md:focus:placeholder-indigo-600'
  | 'md:focus:placeholder-indigo-700'
  | 'md:focus:placeholder-indigo-800'
  | 'md:focus:placeholder-indigo-900'
  | 'md:focus:placeholder-purple-50'
  | 'md:focus:placeholder-purple-100'
  | 'md:focus:placeholder-purple-200'
  | 'md:focus:placeholder-purple-300'
  | 'md:focus:placeholder-purple-400'
  | 'md:focus:placeholder-purple-500'
  | 'md:focus:placeholder-purple-600'
  | 'md:focus:placeholder-purple-700'
  | 'md:focus:placeholder-purple-800'
  | 'md:focus:placeholder-purple-900'
  | 'md:focus:placeholder-pink-50'
  | 'md:focus:placeholder-pink-100'
  | 'md:focus:placeholder-pink-200'
  | 'md:focus:placeholder-pink-300'
  | 'md:focus:placeholder-pink-400'
  | 'md:focus:placeholder-pink-500'
  | 'md:focus:placeholder-pink-600'
  | 'md:focus:placeholder-pink-700'
  | 'md:focus:placeholder-pink-800'
  | 'md:focus:placeholder-pink-900'
  | 'md:pointer-events-none'
  | 'md:pointer-events-auto'
  | 'md:static'
  | 'md:fixed'
  | 'md:absolute'
  | 'md:relative'
  | 'md:sticky'
  | 'md:inset-0'
  | 'md:inset-1'
  | 'md:inset-2'
  | 'md:inset-3'
  | 'md:inset-4'
  | 'md:inset-5'
  | 'md:inset-6'
  | 'md:inset-7'
  | 'md:inset-8'
  | 'md:inset-9'
  | 'md:inset-10'
  | 'md:inset-11'
  | 'md:inset-12'
  | 'md:inset-13'
  | 'md:inset-14'
  | 'md:inset-15'
  | 'md:inset-16'
  | 'md:inset-20'
  | 'md:inset-24'
  | 'md:inset-28'
  | 'md:inset-32'
  | 'md:inset-36'
  | 'md:inset-40'
  | 'md:inset-48'
  | 'md:inset-56'
  | 'md:inset-60'
  | 'md:inset-64'
  | 'md:inset-72'
  | 'md:inset-80'
  | 'md:inset-96'
  | 'md:inset-auto'
  | 'md:inset-px'
  | 'md:inset-0.5'
  | 'md:inset-1.5'
  | 'md:inset-2.5'
  | 'md:inset-3.5'
  | 'md:inset-1/2'
  | 'md:inset-1/3'
  | 'md:inset-2/3'
  | 'md:inset-1/4'
  | 'md:inset-2/4'
  | 'md:inset-3/4'
  | 'md:inset-1/5'
  | 'md:inset-2/5'
  | 'md:inset-3/5'
  | 'md:inset-4/5'
  | 'md:inset-1/6'
  | 'md:inset-2/6'
  | 'md:inset-3/6'
  | 'md:inset-4/6'
  | 'md:inset-5/6'
  | 'md:inset-1/12'
  | 'md:inset-2/12'
  | 'md:inset-3/12'
  | 'md:inset-4/12'
  | 'md:inset-5/12'
  | 'md:inset-6/12'
  | 'md:inset-7/12'
  | 'md:inset-8/12'
  | 'md:inset-9/12'
  | 'md:inset-10/12'
  | 'md:inset-11/12'
  | 'md:inset-full'
  | 'md:inset-y-0'
  | 'md:inset-x-0'
  | 'md:inset-y-1'
  | 'md:inset-x-1'
  | 'md:inset-y-2'
  | 'md:inset-x-2'
  | 'md:inset-y-3'
  | 'md:inset-x-3'
  | 'md:inset-y-4'
  | 'md:inset-x-4'
  | 'md:inset-y-5'
  | 'md:inset-x-5'
  | 'md:inset-y-6'
  | 'md:inset-x-6'
  | 'md:inset-y-7'
  | 'md:inset-x-7'
  | 'md:inset-y-8'
  | 'md:inset-x-8'
  | 'md:inset-y-9'
  | 'md:inset-x-9'
  | 'md:inset-y-10'
  | 'md:inset-x-10'
  | 'md:inset-y-11'
  | 'md:inset-x-11'
  | 'md:inset-y-12'
  | 'md:inset-x-12'
  | 'md:inset-y-13'
  | 'md:inset-x-13'
  | 'md:inset-y-14'
  | 'md:inset-x-14'
  | 'md:inset-y-15'
  | 'md:inset-x-15'
  | 'md:inset-y-16'
  | 'md:inset-x-16'
  | 'md:inset-y-20'
  | 'md:inset-x-20'
  | 'md:inset-y-24'
  | 'md:inset-x-24'
  | 'md:inset-y-28'
  | 'md:inset-x-28'
  | 'md:inset-y-32'
  | 'md:inset-x-32'
  | 'md:inset-y-36'
  | 'md:inset-x-36'
  | 'md:inset-y-40'
  | 'md:inset-x-40'
  | 'md:inset-y-48'
  | 'md:inset-x-48'
  | 'md:inset-y-56'
  | 'md:inset-x-56'
  | 'md:inset-y-60'
  | 'md:inset-x-60'
  | 'md:inset-y-64'
  | 'md:inset-x-64'
  | 'md:inset-y-72'
  | 'md:inset-x-72'
  | 'md:inset-y-80'
  | 'md:inset-x-80'
  | 'md:inset-y-96'
  | 'md:inset-x-96'
  | 'md:inset-y-auto'
  | 'md:inset-x-auto'
  | 'md:inset-y-px'
  | 'md:inset-x-px'
  | 'md:inset-y-0.5'
  | 'md:inset-x-0.5'
  | 'md:inset-y-1.5'
  | 'md:inset-x-1.5'
  | 'md:inset-y-2.5'
  | 'md:inset-x-2.5'
  | 'md:inset-y-3.5'
  | 'md:inset-x-3.5'
  | 'md:inset-y-1/2'
  | 'md:inset-x-1/2'
  | 'md:inset-y-1/3'
  | 'md:inset-x-1/3'
  | 'md:inset-y-2/3'
  | 'md:inset-x-2/3'
  | 'md:inset-y-1/4'
  | 'md:inset-x-1/4'
  | 'md:inset-y-2/4'
  | 'md:inset-x-2/4'
  | 'md:inset-y-3/4'
  | 'md:inset-x-3/4'
  | 'md:inset-y-1/5'
  | 'md:inset-x-1/5'
  | 'md:inset-y-2/5'
  | 'md:inset-x-2/5'
  | 'md:inset-y-3/5'
  | 'md:inset-x-3/5'
  | 'md:inset-y-4/5'
  | 'md:inset-x-4/5'
  | 'md:inset-y-1/6'
  | 'md:inset-x-1/6'
  | 'md:inset-y-2/6'
  | 'md:inset-x-2/6'
  | 'md:inset-y-3/6'
  | 'md:inset-x-3/6'
  | 'md:inset-y-4/6'
  | 'md:inset-x-4/6'
  | 'md:inset-y-5/6'
  | 'md:inset-x-5/6'
  | 'md:inset-y-1/12'
  | 'md:inset-x-1/12'
  | 'md:inset-y-2/12'
  | 'md:inset-x-2/12'
  | 'md:inset-y-3/12'
  | 'md:inset-x-3/12'
  | 'md:inset-y-4/12'
  | 'md:inset-x-4/12'
  | 'md:inset-y-5/12'
  | 'md:inset-x-5/12'
  | 'md:inset-y-6/12'
  | 'md:inset-x-6/12'
  | 'md:inset-y-7/12'
  | 'md:inset-x-7/12'
  | 'md:inset-y-8/12'
  | 'md:inset-x-8/12'
  | 'md:inset-y-9/12'
  | 'md:inset-x-9/12'
  | 'md:inset-y-10/12'
  | 'md:inset-x-10/12'
  | 'md:inset-y-11/12'
  | 'md:inset-x-11/12'
  | 'md:inset-y-full'
  | 'md:inset-x-full'
  | 'md:top-0'
  | 'md:right-0'
  | 'md:bottom-0'
  | 'md:left-0'
  | 'md:top-1'
  | 'md:right-1'
  | 'md:bottom-1'
  | 'md:left-1'
  | 'md:top-2'
  | 'md:right-2'
  | 'md:bottom-2'
  | 'md:left-2'
  | 'md:top-3'
  | 'md:right-3'
  | 'md:bottom-3'
  | 'md:left-3'
  | 'md:top-4'
  | 'md:right-4'
  | 'md:bottom-4'
  | 'md:left-4'
  | 'md:top-5'
  | 'md:right-5'
  | 'md:bottom-5'
  | 'md:left-5'
  | 'md:top-6'
  | 'md:right-6'
  | 'md:bottom-6'
  | 'md:left-6'
  | 'md:top-7'
  | 'md:right-7'
  | 'md:bottom-7'
  | 'md:left-7'
  | 'md:top-8'
  | 'md:right-8'
  | 'md:bottom-8'
  | 'md:left-8'
  | 'md:top-9'
  | 'md:right-9'
  | 'md:bottom-9'
  | 'md:left-9'
  | 'md:top-10'
  | 'md:right-10'
  | 'md:bottom-10'
  | 'md:left-10'
  | 'md:top-11'
  | 'md:right-11'
  | 'md:bottom-11'
  | 'md:left-11'
  | 'md:top-12'
  | 'md:right-12'
  | 'md:bottom-12'
  | 'md:left-12'
  | 'md:top-13'
  | 'md:right-13'
  | 'md:bottom-13'
  | 'md:left-13'
  | 'md:top-14'
  | 'md:right-14'
  | 'md:bottom-14'
  | 'md:left-14'
  | 'md:top-15'
  | 'md:right-15'
  | 'md:bottom-15'
  | 'md:left-15'
  | 'md:top-16'
  | 'md:right-16'
  | 'md:bottom-16'
  | 'md:left-16'
  | 'md:top-20'
  | 'md:right-20'
  | 'md:bottom-20'
  | 'md:left-20'
  | 'md:top-24'
  | 'md:right-24'
  | 'md:bottom-24'
  | 'md:left-24'
  | 'md:top-28'
  | 'md:right-28'
  | 'md:bottom-28'
  | 'md:left-28'
  | 'md:top-32'
  | 'md:right-32'
  | 'md:bottom-32'
  | 'md:left-32'
  | 'md:top-36'
  | 'md:right-36'
  | 'md:bottom-36'
  | 'md:left-36'
  | 'md:top-40'
  | 'md:right-40'
  | 'md:bottom-40'
  | 'md:left-40'
  | 'md:top-48'
  | 'md:right-48'
  | 'md:bottom-48'
  | 'md:left-48'
  | 'md:top-56'
  | 'md:right-56'
  | 'md:bottom-56'
  | 'md:left-56'
  | 'md:top-60'
  | 'md:right-60'
  | 'md:bottom-60'
  | 'md:left-60'
  | 'md:top-64'
  | 'md:right-64'
  | 'md:bottom-64'
  | 'md:left-64'
  | 'md:top-72'
  | 'md:right-72'
  | 'md:bottom-72'
  | 'md:left-72'
  | 'md:top-80'
  | 'md:right-80'
  | 'md:bottom-80'
  | 'md:left-80'
  | 'md:top-96'
  | 'md:right-96'
  | 'md:bottom-96'
  | 'md:left-96'
  | 'md:top-auto'
  | 'md:right-auto'
  | 'md:bottom-auto'
  | 'md:left-auto'
  | 'md:top-px'
  | 'md:right-px'
  | 'md:bottom-px'
  | 'md:left-px'
  | 'md:top-0.5'
  | 'md:right-0.5'
  | 'md:bottom-0.5'
  | 'md:left-0.5'
  | 'md:top-1.5'
  | 'md:right-1.5'
  | 'md:bottom-1.5'
  | 'md:left-1.5'
  | 'md:top-2.5'
  | 'md:right-2.5'
  | 'md:bottom-2.5'
  | 'md:left-2.5'
  | 'md:top-3.5'
  | 'md:right-3.5'
  | 'md:bottom-3.5'
  | 'md:left-3.5'
  | 'md:top-1/2'
  | 'md:right-1/2'
  | 'md:bottom-1/2'
  | 'md:left-1/2'
  | 'md:top-1/3'
  | 'md:right-1/3'
  | 'md:bottom-1/3'
  | 'md:left-1/3'
  | 'md:top-2/3'
  | 'md:right-2/3'
  | 'md:bottom-2/3'
  | 'md:left-2/3'
  | 'md:top-1/4'
  | 'md:right-1/4'
  | 'md:bottom-1/4'
  | 'md:left-1/4'
  | 'md:top-2/4'
  | 'md:right-2/4'
  | 'md:bottom-2/4'
  | 'md:left-2/4'
  | 'md:top-3/4'
  | 'md:right-3/4'
  | 'md:bottom-3/4'
  | 'md:left-3/4'
  | 'md:top-1/5'
  | 'md:right-1/5'
  | 'md:bottom-1/5'
  | 'md:left-1/5'
  | 'md:top-2/5'
  | 'md:right-2/5'
  | 'md:bottom-2/5'
  | 'md:left-2/5'
  | 'md:top-3/5'
  | 'md:right-3/5'
  | 'md:bottom-3/5'
  | 'md:left-3/5'
  | 'md:top-4/5'
  | 'md:right-4/5'
  | 'md:bottom-4/5'
  | 'md:left-4/5'
  | 'md:top-1/6'
  | 'md:right-1/6'
  | 'md:bottom-1/6'
  | 'md:left-1/6'
  | 'md:top-2/6'
  | 'md:right-2/6'
  | 'md:bottom-2/6'
  | 'md:left-2/6'
  | 'md:top-3/6'
  | 'md:right-3/6'
  | 'md:bottom-3/6'
  | 'md:left-3/6'
  | 'md:top-4/6'
  | 'md:right-4/6'
  | 'md:bottom-4/6'
  | 'md:left-4/6'
  | 'md:top-5/6'
  | 'md:right-5/6'
  | 'md:bottom-5/6'
  | 'md:left-5/6'
  | 'md:top-1/12'
  | 'md:right-1/12'
  | 'md:bottom-1/12'
  | 'md:left-1/12'
  | 'md:top-2/12'
  | 'md:right-2/12'
  | 'md:bottom-2/12'
  | 'md:left-2/12'
  | 'md:top-3/12'
  | 'md:right-3/12'
  | 'md:bottom-3/12'
  | 'md:left-3/12'
  | 'md:top-4/12'
  | 'md:right-4/12'
  | 'md:bottom-4/12'
  | 'md:left-4/12'
  | 'md:top-5/12'
  | 'md:right-5/12'
  | 'md:bottom-5/12'
  | 'md:left-5/12'
  | 'md:top-6/12'
  | 'md:right-6/12'
  | 'md:bottom-6/12'
  | 'md:left-6/12'
  | 'md:top-7/12'
  | 'md:right-7/12'
  | 'md:bottom-7/12'
  | 'md:left-7/12'
  | 'md:top-8/12'
  | 'md:right-8/12'
  | 'md:bottom-8/12'
  | 'md:left-8/12'
  | 'md:top-9/12'
  | 'md:right-9/12'
  | 'md:bottom-9/12'
  | 'md:left-9/12'
  | 'md:top-10/12'
  | 'md:right-10/12'
  | 'md:bottom-10/12'
  | 'md:left-10/12'
  | 'md:top-11/12'
  | 'md:right-11/12'
  | 'md:bottom-11/12'
  | 'md:left-11/12'
  | 'md:top-full'
  | 'md:right-full'
  | 'md:bottom-full'
  | 'md:left-full'
  | 'md:resize-none'
  | 'md:resize-y'
  | 'md:resize-x'
  | 'md:resize'
  | 'md:shadow-xs'
  | 'md:shadow-sm'
  | 'md:shadow'
  | 'md:shadow-md'
  | 'md:shadow-lg'
  | 'md:shadow-xl'
  | 'md:shadow-2xl'
  | 'md:shadow-inner'
  | 'md:shadow-outline'
  | 'md:shadow-none'
  | 'md:shadow-solid'
  | 'md:shadow-outline-gray'
  | 'md:shadow-outline-blue'
  | 'md:shadow-outline-teal'
  | 'md:shadow-outline-green'
  | 'md:shadow-outline-yellow'
  | 'md:shadow-outline-orange'
  | 'md:shadow-outline-red'
  | 'md:shadow-outline-pink'
  | 'md:shadow-outline-purple'
  | 'md:shadow-outline-indigo'
  | 'md:hover:shadow-xs'
  | 'md:hover:shadow-sm'
  | 'md:hover:shadow'
  | 'md:hover:shadow-md'
  | 'md:hover:shadow-lg'
  | 'md:hover:shadow-xl'
  | 'md:hover:shadow-2xl'
  | 'md:hover:shadow-inner'
  | 'md:hover:shadow-outline'
  | 'md:hover:shadow-none'
  | 'md:hover:shadow-solid'
  | 'md:hover:shadow-outline-gray'
  | 'md:hover:shadow-outline-blue'
  | 'md:hover:shadow-outline-teal'
  | 'md:hover:shadow-outline-green'
  | 'md:hover:shadow-outline-yellow'
  | 'md:hover:shadow-outline-orange'
  | 'md:hover:shadow-outline-red'
  | 'md:hover:shadow-outline-pink'
  | 'md:hover:shadow-outline-purple'
  | 'md:hover:shadow-outline-indigo'
  | 'md:focus:shadow-xs'
  | 'md:focus:shadow-sm'
  | 'md:focus:shadow'
  | 'md:focus:shadow-md'
  | 'md:focus:shadow-lg'
  | 'md:focus:shadow-xl'
  | 'md:focus:shadow-2xl'
  | 'md:focus:shadow-inner'
  | 'md:focus:shadow-outline'
  | 'md:focus:shadow-none'
  | 'md:focus:shadow-solid'
  | 'md:focus:shadow-outline-gray'
  | 'md:focus:shadow-outline-blue'
  | 'md:focus:shadow-outline-teal'
  | 'md:focus:shadow-outline-green'
  | 'md:focus:shadow-outline-yellow'
  | 'md:focus:shadow-outline-orange'
  | 'md:focus:shadow-outline-red'
  | 'md:focus:shadow-outline-pink'
  | 'md:focus:shadow-outline-purple'
  | 'md:focus:shadow-outline-indigo'
  | 'md:fill-current'
  | 'md:stroke-current'
  | 'md:stroke-0'
  | 'md:stroke-1'
  | 'md:stroke-2'
  | 'md:table-auto'
  | 'md:table-fixed'
  | 'md:text-left'
  | 'md:text-center'
  | 'md:text-right'
  | 'md:text-justify'
  | 'md:text-transparent'
  | 'md:text-white'
  | 'md:text-black'
  | 'md:text-gray-50'
  | 'md:text-gray-100'
  | 'md:text-gray-200'
  | 'md:text-gray-300'
  | 'md:text-gray-400'
  | 'md:text-gray-500'
  | 'md:text-gray-600'
  | 'md:text-gray-700'
  | 'md:text-gray-800'
  | 'md:text-gray-900'
  | 'md:text-cool-gray-50'
  | 'md:text-cool-gray-100'
  | 'md:text-cool-gray-200'
  | 'md:text-cool-gray-300'
  | 'md:text-cool-gray-400'
  | 'md:text-cool-gray-500'
  | 'md:text-cool-gray-600'
  | 'md:text-cool-gray-700'
  | 'md:text-cool-gray-800'
  | 'md:text-cool-gray-900'
  | 'md:text-red-50'
  | 'md:text-red-100'
  | 'md:text-red-200'
  | 'md:text-red-300'
  | 'md:text-red-400'
  | 'md:text-red-500'
  | 'md:text-red-600'
  | 'md:text-red-700'
  | 'md:text-red-800'
  | 'md:text-red-900'
  | 'md:text-orange-50'
  | 'md:text-orange-100'
  | 'md:text-orange-200'
  | 'md:text-orange-300'
  | 'md:text-orange-400'
  | 'md:text-orange-500'
  | 'md:text-orange-600'
  | 'md:text-orange-700'
  | 'md:text-orange-800'
  | 'md:text-orange-900'
  | 'md:text-yellow-50'
  | 'md:text-yellow-100'
  | 'md:text-yellow-200'
  | 'md:text-yellow-300'
  | 'md:text-yellow-400'
  | 'md:text-yellow-500'
  | 'md:text-yellow-600'
  | 'md:text-yellow-700'
  | 'md:text-yellow-800'
  | 'md:text-yellow-900'
  | 'md:text-green-50'
  | 'md:text-green-100'
  | 'md:text-green-200'
  | 'md:text-green-300'
  | 'md:text-green-400'
  | 'md:text-green-500'
  | 'md:text-green-600'
  | 'md:text-green-700'
  | 'md:text-green-800'
  | 'md:text-green-900'
  | 'md:text-teal-50'
  | 'md:text-teal-100'
  | 'md:text-teal-200'
  | 'md:text-teal-300'
  | 'md:text-teal-400'
  | 'md:text-teal-500'
  | 'md:text-teal-600'
  | 'md:text-teal-700'
  | 'md:text-teal-800'
  | 'md:text-teal-900'
  | 'md:text-blue-50'
  | 'md:text-blue-100'
  | 'md:text-blue-200'
  | 'md:text-blue-300'
  | 'md:text-blue-400'
  | 'md:text-blue-500'
  | 'md:text-blue-600'
  | 'md:text-blue-700'
  | 'md:text-blue-800'
  | 'md:text-blue-900'
  | 'md:text-indigo-50'
  | 'md:text-indigo-100'
  | 'md:text-indigo-200'
  | 'md:text-indigo-300'
  | 'md:text-indigo-400'
  | 'md:text-indigo-500'
  | 'md:text-indigo-600'
  | 'md:text-indigo-700'
  | 'md:text-indigo-800'
  | 'md:text-indigo-900'
  | 'md:text-purple-50'
  | 'md:text-purple-100'
  | 'md:text-purple-200'
  | 'md:text-purple-300'
  | 'md:text-purple-400'
  | 'md:text-purple-500'
  | 'md:text-purple-600'
  | 'md:text-purple-700'
  | 'md:text-purple-800'
  | 'md:text-purple-900'
  | 'md:text-pink-50'
  | 'md:text-pink-100'
  | 'md:text-pink-200'
  | 'md:text-pink-300'
  | 'md:text-pink-400'
  | 'md:text-pink-500'
  | 'md:text-pink-600'
  | 'md:text-pink-700'
  | 'md:text-pink-800'
  | 'md:text-pink-900'
  | 'md:group-hover:text-transparent'
  | 'md:group-hover:text-white'
  | 'md:group-hover:text-black'
  | 'md:group-hover:text-gray-50'
  | 'md:group-hover:text-gray-100'
  | 'md:group-hover:text-gray-200'
  | 'md:group-hover:text-gray-300'
  | 'md:group-hover:text-gray-400'
  | 'md:group-hover:text-gray-500'
  | 'md:group-hover:text-gray-600'
  | 'md:group-hover:text-gray-700'
  | 'md:group-hover:text-gray-800'
  | 'md:group-hover:text-gray-900'
  | 'md:group-hover:text-cool-gray-50'
  | 'md:group-hover:text-cool-gray-100'
  | 'md:group-hover:text-cool-gray-200'
  | 'md:group-hover:text-cool-gray-300'
  | 'md:group-hover:text-cool-gray-400'
  | 'md:group-hover:text-cool-gray-500'
  | 'md:group-hover:text-cool-gray-600'
  | 'md:group-hover:text-cool-gray-700'
  | 'md:group-hover:text-cool-gray-800'
  | 'md:group-hover:text-cool-gray-900'
  | 'md:group-hover:text-red-50'
  | 'md:group-hover:text-red-100'
  | 'md:group-hover:text-red-200'
  | 'md:group-hover:text-red-300'
  | 'md:group-hover:text-red-400'
  | 'md:group-hover:text-red-500'
  | 'md:group-hover:text-red-600'
  | 'md:group-hover:text-red-700'
  | 'md:group-hover:text-red-800'
  | 'md:group-hover:text-red-900'
  | 'md:group-hover:text-orange-50'
  | 'md:group-hover:text-orange-100'
  | 'md:group-hover:text-orange-200'
  | 'md:group-hover:text-orange-300'
  | 'md:group-hover:text-orange-400'
  | 'md:group-hover:text-orange-500'
  | 'md:group-hover:text-orange-600'
  | 'md:group-hover:text-orange-700'
  | 'md:group-hover:text-orange-800'
  | 'md:group-hover:text-orange-900'
  | 'md:group-hover:text-yellow-50'
  | 'md:group-hover:text-yellow-100'
  | 'md:group-hover:text-yellow-200'
  | 'md:group-hover:text-yellow-300'
  | 'md:group-hover:text-yellow-400'
  | 'md:group-hover:text-yellow-500'
  | 'md:group-hover:text-yellow-600'
  | 'md:group-hover:text-yellow-700'
  | 'md:group-hover:text-yellow-800'
  | 'md:group-hover:text-yellow-900'
  | 'md:group-hover:text-green-50'
  | 'md:group-hover:text-green-100'
  | 'md:group-hover:text-green-200'
  | 'md:group-hover:text-green-300'
  | 'md:group-hover:text-green-400'
  | 'md:group-hover:text-green-500'
  | 'md:group-hover:text-green-600'
  | 'md:group-hover:text-green-700'
  | 'md:group-hover:text-green-800'
  | 'md:group-hover:text-green-900'
  | 'md:group-hover:text-teal-50'
  | 'md:group-hover:text-teal-100'
  | 'md:group-hover:text-teal-200'
  | 'md:group-hover:text-teal-300'
  | 'md:group-hover:text-teal-400'
  | 'md:group-hover:text-teal-500'
  | 'md:group-hover:text-teal-600'
  | 'md:group-hover:text-teal-700'
  | 'md:group-hover:text-teal-800'
  | 'md:group-hover:text-teal-900'
  | 'md:group-hover:text-blue-50'
  | 'md:group-hover:text-blue-100'
  | 'md:group-hover:text-blue-200'
  | 'md:group-hover:text-blue-300'
  | 'md:group-hover:text-blue-400'
  | 'md:group-hover:text-blue-500'
  | 'md:group-hover:text-blue-600'
  | 'md:group-hover:text-blue-700'
  | 'md:group-hover:text-blue-800'
  | 'md:group-hover:text-blue-900'
  | 'md:group-hover:text-indigo-50'
  | 'md:group-hover:text-indigo-100'
  | 'md:group-hover:text-indigo-200'
  | 'md:group-hover:text-indigo-300'
  | 'md:group-hover:text-indigo-400'
  | 'md:group-hover:text-indigo-500'
  | 'md:group-hover:text-indigo-600'
  | 'md:group-hover:text-indigo-700'
  | 'md:group-hover:text-indigo-800'
  | 'md:group-hover:text-indigo-900'
  | 'md:group-hover:text-purple-50'
  | 'md:group-hover:text-purple-100'
  | 'md:group-hover:text-purple-200'
  | 'md:group-hover:text-purple-300'
  | 'md:group-hover:text-purple-400'
  | 'md:group-hover:text-purple-500'
  | 'md:group-hover:text-purple-600'
  | 'md:group-hover:text-purple-700'
  | 'md:group-hover:text-purple-800'
  | 'md:group-hover:text-purple-900'
  | 'md:group-hover:text-pink-50'
  | 'md:group-hover:text-pink-100'
  | 'md:group-hover:text-pink-200'
  | 'md:group-hover:text-pink-300'
  | 'md:group-hover:text-pink-400'
  | 'md:group-hover:text-pink-500'
  | 'md:group-hover:text-pink-600'
  | 'md:group-hover:text-pink-700'
  | 'md:group-hover:text-pink-800'
  | 'md:group-hover:text-pink-900'
  | 'md:group-focus:text-transparent'
  | 'md:group-focus:text-white'
  | 'md:group-focus:text-black'
  | 'md:group-focus:text-gray-50'
  | 'md:group-focus:text-gray-100'
  | 'md:group-focus:text-gray-200'
  | 'md:group-focus:text-gray-300'
  | 'md:group-focus:text-gray-400'
  | 'md:group-focus:text-gray-500'
  | 'md:group-focus:text-gray-600'
  | 'md:group-focus:text-gray-700'
  | 'md:group-focus:text-gray-800'
  | 'md:group-focus:text-gray-900'
  | 'md:group-focus:text-cool-gray-50'
  | 'md:group-focus:text-cool-gray-100'
  | 'md:group-focus:text-cool-gray-200'
  | 'md:group-focus:text-cool-gray-300'
  | 'md:group-focus:text-cool-gray-400'
  | 'md:group-focus:text-cool-gray-500'
  | 'md:group-focus:text-cool-gray-600'
  | 'md:group-focus:text-cool-gray-700'
  | 'md:group-focus:text-cool-gray-800'
  | 'md:group-focus:text-cool-gray-900'
  | 'md:group-focus:text-red-50'
  | 'md:group-focus:text-red-100'
  | 'md:group-focus:text-red-200'
  | 'md:group-focus:text-red-300'
  | 'md:group-focus:text-red-400'
  | 'md:group-focus:text-red-500'
  | 'md:group-focus:text-red-600'
  | 'md:group-focus:text-red-700'
  | 'md:group-focus:text-red-800'
  | 'md:group-focus:text-red-900'
  | 'md:group-focus:text-orange-50'
  | 'md:group-focus:text-orange-100'
  | 'md:group-focus:text-orange-200'
  | 'md:group-focus:text-orange-300'
  | 'md:group-focus:text-orange-400'
  | 'md:group-focus:text-orange-500'
  | 'md:group-focus:text-orange-600'
  | 'md:group-focus:text-orange-700'
  | 'md:group-focus:text-orange-800'
  | 'md:group-focus:text-orange-900'
  | 'md:group-focus:text-yellow-50'
  | 'md:group-focus:text-yellow-100'
  | 'md:group-focus:text-yellow-200'
  | 'md:group-focus:text-yellow-300'
  | 'md:group-focus:text-yellow-400'
  | 'md:group-focus:text-yellow-500'
  | 'md:group-focus:text-yellow-600'
  | 'md:group-focus:text-yellow-700'
  | 'md:group-focus:text-yellow-800'
  | 'md:group-focus:text-yellow-900'
  | 'md:group-focus:text-green-50'
  | 'md:group-focus:text-green-100'
  | 'md:group-focus:text-green-200'
  | 'md:group-focus:text-green-300'
  | 'md:group-focus:text-green-400'
  | 'md:group-focus:text-green-500'
  | 'md:group-focus:text-green-600'
  | 'md:group-focus:text-green-700'
  | 'md:group-focus:text-green-800'
  | 'md:group-focus:text-green-900'
  | 'md:group-focus:text-teal-50'
  | 'md:group-focus:text-teal-100'
  | 'md:group-focus:text-teal-200'
  | 'md:group-focus:text-teal-300'
  | 'md:group-focus:text-teal-400'
  | 'md:group-focus:text-teal-500'
  | 'md:group-focus:text-teal-600'
  | 'md:group-focus:text-teal-700'
  | 'md:group-focus:text-teal-800'
  | 'md:group-focus:text-teal-900'
  | 'md:group-focus:text-blue-50'
  | 'md:group-focus:text-blue-100'
  | 'md:group-focus:text-blue-200'
  | 'md:group-focus:text-blue-300'
  | 'md:group-focus:text-blue-400'
  | 'md:group-focus:text-blue-500'
  | 'md:group-focus:text-blue-600'
  | 'md:group-focus:text-blue-700'
  | 'md:group-focus:text-blue-800'
  | 'md:group-focus:text-blue-900'
  | 'md:group-focus:text-indigo-50'
  | 'md:group-focus:text-indigo-100'
  | 'md:group-focus:text-indigo-200'
  | 'md:group-focus:text-indigo-300'
  | 'md:group-focus:text-indigo-400'
  | 'md:group-focus:text-indigo-500'
  | 'md:group-focus:text-indigo-600'
  | 'md:group-focus:text-indigo-700'
  | 'md:group-focus:text-indigo-800'
  | 'md:group-focus:text-indigo-900'
  | 'md:group-focus:text-purple-50'
  | 'md:group-focus:text-purple-100'
  | 'md:group-focus:text-purple-200'
  | 'md:group-focus:text-purple-300'
  | 'md:group-focus:text-purple-400'
  | 'md:group-focus:text-purple-500'
  | 'md:group-focus:text-purple-600'
  | 'md:group-focus:text-purple-700'
  | 'md:group-focus:text-purple-800'
  | 'md:group-focus:text-purple-900'
  | 'md:group-focus:text-pink-50'
  | 'md:group-focus:text-pink-100'
  | 'md:group-focus:text-pink-200'
  | 'md:group-focus:text-pink-300'
  | 'md:group-focus:text-pink-400'
  | 'md:group-focus:text-pink-500'
  | 'md:group-focus:text-pink-600'
  | 'md:group-focus:text-pink-700'
  | 'md:group-focus:text-pink-800'
  | 'md:group-focus:text-pink-900'
  | 'md:hover:text-transparent'
  | 'md:hover:text-white'
  | 'md:hover:text-black'
  | 'md:hover:text-gray-50'
  | 'md:hover:text-gray-100'
  | 'md:hover:text-gray-200'
  | 'md:hover:text-gray-300'
  | 'md:hover:text-gray-400'
  | 'md:hover:text-gray-500'
  | 'md:hover:text-gray-600'
  | 'md:hover:text-gray-700'
  | 'md:hover:text-gray-800'
  | 'md:hover:text-gray-900'
  | 'md:hover:text-cool-gray-50'
  | 'md:hover:text-cool-gray-100'
  | 'md:hover:text-cool-gray-200'
  | 'md:hover:text-cool-gray-300'
  | 'md:hover:text-cool-gray-400'
  | 'md:hover:text-cool-gray-500'
  | 'md:hover:text-cool-gray-600'
  | 'md:hover:text-cool-gray-700'
  | 'md:hover:text-cool-gray-800'
  | 'md:hover:text-cool-gray-900'
  | 'md:hover:text-red-50'
  | 'md:hover:text-red-100'
  | 'md:hover:text-red-200'
  | 'md:hover:text-red-300'
  | 'md:hover:text-red-400'
  | 'md:hover:text-red-500'
  | 'md:hover:text-red-600'
  | 'md:hover:text-red-700'
  | 'md:hover:text-red-800'
  | 'md:hover:text-red-900'
  | 'md:hover:text-orange-50'
  | 'md:hover:text-orange-100'
  | 'md:hover:text-orange-200'
  | 'md:hover:text-orange-300'
  | 'md:hover:text-orange-400'
  | 'md:hover:text-orange-500'
  | 'md:hover:text-orange-600'
  | 'md:hover:text-orange-700'
  | 'md:hover:text-orange-800'
  | 'md:hover:text-orange-900'
  | 'md:hover:text-yellow-50'
  | 'md:hover:text-yellow-100'
  | 'md:hover:text-yellow-200'
  | 'md:hover:text-yellow-300'
  | 'md:hover:text-yellow-400'
  | 'md:hover:text-yellow-500'
  | 'md:hover:text-yellow-600'
  | 'md:hover:text-yellow-700'
  | 'md:hover:text-yellow-800'
  | 'md:hover:text-yellow-900'
  | 'md:hover:text-green-50'
  | 'md:hover:text-green-100'
  | 'md:hover:text-green-200'
  | 'md:hover:text-green-300'
  | 'md:hover:text-green-400'
  | 'md:hover:text-green-500'
  | 'md:hover:text-green-600'
  | 'md:hover:text-green-700'
  | 'md:hover:text-green-800'
  | 'md:hover:text-green-900'
  | 'md:hover:text-teal-50'
  | 'md:hover:text-teal-100'
  | 'md:hover:text-teal-200'
  | 'md:hover:text-teal-300'
  | 'md:hover:text-teal-400'
  | 'md:hover:text-teal-500'
  | 'md:hover:text-teal-600'
  | 'md:hover:text-teal-700'
  | 'md:hover:text-teal-800'
  | 'md:hover:text-teal-900'
  | 'md:hover:text-blue-50'
  | 'md:hover:text-blue-100'
  | 'md:hover:text-blue-200'
  | 'md:hover:text-blue-300'
  | 'md:hover:text-blue-400'
  | 'md:hover:text-blue-500'
  | 'md:hover:text-blue-600'
  | 'md:hover:text-blue-700'
  | 'md:hover:text-blue-800'
  | 'md:hover:text-blue-900'
  | 'md:hover:text-indigo-50'
  | 'md:hover:text-indigo-100'
  | 'md:hover:text-indigo-200'
  | 'md:hover:text-indigo-300'
  | 'md:hover:text-indigo-400'
  | 'md:hover:text-indigo-500'
  | 'md:hover:text-indigo-600'
  | 'md:hover:text-indigo-700'
  | 'md:hover:text-indigo-800'
  | 'md:hover:text-indigo-900'
  | 'md:hover:text-purple-50'
  | 'md:hover:text-purple-100'
  | 'md:hover:text-purple-200'
  | 'md:hover:text-purple-300'
  | 'md:hover:text-purple-400'
  | 'md:hover:text-purple-500'
  | 'md:hover:text-purple-600'
  | 'md:hover:text-purple-700'
  | 'md:hover:text-purple-800'
  | 'md:hover:text-purple-900'
  | 'md:hover:text-pink-50'
  | 'md:hover:text-pink-100'
  | 'md:hover:text-pink-200'
  | 'md:hover:text-pink-300'
  | 'md:hover:text-pink-400'
  | 'md:hover:text-pink-500'
  | 'md:hover:text-pink-600'
  | 'md:hover:text-pink-700'
  | 'md:hover:text-pink-800'
  | 'md:hover:text-pink-900'
  | 'md:focus-within:text-transparent'
  | 'md:focus-within:text-white'
  | 'md:focus-within:text-black'
  | 'md:focus-within:text-gray-50'
  | 'md:focus-within:text-gray-100'
  | 'md:focus-within:text-gray-200'
  | 'md:focus-within:text-gray-300'
  | 'md:focus-within:text-gray-400'
  | 'md:focus-within:text-gray-500'
  | 'md:focus-within:text-gray-600'
  | 'md:focus-within:text-gray-700'
  | 'md:focus-within:text-gray-800'
  | 'md:focus-within:text-gray-900'
  | 'md:focus-within:text-cool-gray-50'
  | 'md:focus-within:text-cool-gray-100'
  | 'md:focus-within:text-cool-gray-200'
  | 'md:focus-within:text-cool-gray-300'
  | 'md:focus-within:text-cool-gray-400'
  | 'md:focus-within:text-cool-gray-500'
  | 'md:focus-within:text-cool-gray-600'
  | 'md:focus-within:text-cool-gray-700'
  | 'md:focus-within:text-cool-gray-800'
  | 'md:focus-within:text-cool-gray-900'
  | 'md:focus-within:text-red-50'
  | 'md:focus-within:text-red-100'
  | 'md:focus-within:text-red-200'
  | 'md:focus-within:text-red-300'
  | 'md:focus-within:text-red-400'
  | 'md:focus-within:text-red-500'
  | 'md:focus-within:text-red-600'
  | 'md:focus-within:text-red-700'
  | 'md:focus-within:text-red-800'
  | 'md:focus-within:text-red-900'
  | 'md:focus-within:text-orange-50'
  | 'md:focus-within:text-orange-100'
  | 'md:focus-within:text-orange-200'
  | 'md:focus-within:text-orange-300'
  | 'md:focus-within:text-orange-400'
  | 'md:focus-within:text-orange-500'
  | 'md:focus-within:text-orange-600'
  | 'md:focus-within:text-orange-700'
  | 'md:focus-within:text-orange-800'
  | 'md:focus-within:text-orange-900'
  | 'md:focus-within:text-yellow-50'
  | 'md:focus-within:text-yellow-100'
  | 'md:focus-within:text-yellow-200'
  | 'md:focus-within:text-yellow-300'
  | 'md:focus-within:text-yellow-400'
  | 'md:focus-within:text-yellow-500'
  | 'md:focus-within:text-yellow-600'
  | 'md:focus-within:text-yellow-700'
  | 'md:focus-within:text-yellow-800'
  | 'md:focus-within:text-yellow-900'
  | 'md:focus-within:text-green-50'
  | 'md:focus-within:text-green-100'
  | 'md:focus-within:text-green-200'
  | 'md:focus-within:text-green-300'
  | 'md:focus-within:text-green-400'
  | 'md:focus-within:text-green-500'
  | 'md:focus-within:text-green-600'
  | 'md:focus-within:text-green-700'
  | 'md:focus-within:text-green-800'
  | 'md:focus-within:text-green-900'
  | 'md:focus-within:text-teal-50'
  | 'md:focus-within:text-teal-100'
  | 'md:focus-within:text-teal-200'
  | 'md:focus-within:text-teal-300'
  | 'md:focus-within:text-teal-400'
  | 'md:focus-within:text-teal-500'
  | 'md:focus-within:text-teal-600'
  | 'md:focus-within:text-teal-700'
  | 'md:focus-within:text-teal-800'
  | 'md:focus-within:text-teal-900'
  | 'md:focus-within:text-blue-50'
  | 'md:focus-within:text-blue-100'
  | 'md:focus-within:text-blue-200'
  | 'md:focus-within:text-blue-300'
  | 'md:focus-within:text-blue-400'
  | 'md:focus-within:text-blue-500'
  | 'md:focus-within:text-blue-600'
  | 'md:focus-within:text-blue-700'
  | 'md:focus-within:text-blue-800'
  | 'md:focus-within:text-blue-900'
  | 'md:focus-within:text-indigo-50'
  | 'md:focus-within:text-indigo-100'
  | 'md:focus-within:text-indigo-200'
  | 'md:focus-within:text-indigo-300'
  | 'md:focus-within:text-indigo-400'
  | 'md:focus-within:text-indigo-500'
  | 'md:focus-within:text-indigo-600'
  | 'md:focus-within:text-indigo-700'
  | 'md:focus-within:text-indigo-800'
  | 'md:focus-within:text-indigo-900'
  | 'md:focus-within:text-purple-50'
  | 'md:focus-within:text-purple-100'
  | 'md:focus-within:text-purple-200'
  | 'md:focus-within:text-purple-300'
  | 'md:focus-within:text-purple-400'
  | 'md:focus-within:text-purple-500'
  | 'md:focus-within:text-purple-600'
  | 'md:focus-within:text-purple-700'
  | 'md:focus-within:text-purple-800'
  | 'md:focus-within:text-purple-900'
  | 'md:focus-within:text-pink-50'
  | 'md:focus-within:text-pink-100'
  | 'md:focus-within:text-pink-200'
  | 'md:focus-within:text-pink-300'
  | 'md:focus-within:text-pink-400'
  | 'md:focus-within:text-pink-500'
  | 'md:focus-within:text-pink-600'
  | 'md:focus-within:text-pink-700'
  | 'md:focus-within:text-pink-800'
  | 'md:focus-within:text-pink-900'
  | 'md:focus:text-transparent'
  | 'md:focus:text-white'
  | 'md:focus:text-black'
  | 'md:focus:text-gray-50'
  | 'md:focus:text-gray-100'
  | 'md:focus:text-gray-200'
  | 'md:focus:text-gray-300'
  | 'md:focus:text-gray-400'
  | 'md:focus:text-gray-500'
  | 'md:focus:text-gray-600'
  | 'md:focus:text-gray-700'
  | 'md:focus:text-gray-800'
  | 'md:focus:text-gray-900'
  | 'md:focus:text-cool-gray-50'
  | 'md:focus:text-cool-gray-100'
  | 'md:focus:text-cool-gray-200'
  | 'md:focus:text-cool-gray-300'
  | 'md:focus:text-cool-gray-400'
  | 'md:focus:text-cool-gray-500'
  | 'md:focus:text-cool-gray-600'
  | 'md:focus:text-cool-gray-700'
  | 'md:focus:text-cool-gray-800'
  | 'md:focus:text-cool-gray-900'
  | 'md:focus:text-red-50'
  | 'md:focus:text-red-100'
  | 'md:focus:text-red-200'
  | 'md:focus:text-red-300'
  | 'md:focus:text-red-400'
  | 'md:focus:text-red-500'
  | 'md:focus:text-red-600'
  | 'md:focus:text-red-700'
  | 'md:focus:text-red-800'
  | 'md:focus:text-red-900'
  | 'md:focus:text-orange-50'
  | 'md:focus:text-orange-100'
  | 'md:focus:text-orange-200'
  | 'md:focus:text-orange-300'
  | 'md:focus:text-orange-400'
  | 'md:focus:text-orange-500'
  | 'md:focus:text-orange-600'
  | 'md:focus:text-orange-700'
  | 'md:focus:text-orange-800'
  | 'md:focus:text-orange-900'
  | 'md:focus:text-yellow-50'
  | 'md:focus:text-yellow-100'
  | 'md:focus:text-yellow-200'
  | 'md:focus:text-yellow-300'
  | 'md:focus:text-yellow-400'
  | 'md:focus:text-yellow-500'
  | 'md:focus:text-yellow-600'
  | 'md:focus:text-yellow-700'
  | 'md:focus:text-yellow-800'
  | 'md:focus:text-yellow-900'
  | 'md:focus:text-green-50'
  | 'md:focus:text-green-100'
  | 'md:focus:text-green-200'
  | 'md:focus:text-green-300'
  | 'md:focus:text-green-400'
  | 'md:focus:text-green-500'
  | 'md:focus:text-green-600'
  | 'md:focus:text-green-700'
  | 'md:focus:text-green-800'
  | 'md:focus:text-green-900'
  | 'md:focus:text-teal-50'
  | 'md:focus:text-teal-100'
  | 'md:focus:text-teal-200'
  | 'md:focus:text-teal-300'
  | 'md:focus:text-teal-400'
  | 'md:focus:text-teal-500'
  | 'md:focus:text-teal-600'
  | 'md:focus:text-teal-700'
  | 'md:focus:text-teal-800'
  | 'md:focus:text-teal-900'
  | 'md:focus:text-blue-50'
  | 'md:focus:text-blue-100'
  | 'md:focus:text-blue-200'
  | 'md:focus:text-blue-300'
  | 'md:focus:text-blue-400'
  | 'md:focus:text-blue-500'
  | 'md:focus:text-blue-600'
  | 'md:focus:text-blue-700'
  | 'md:focus:text-blue-800'
  | 'md:focus:text-blue-900'
  | 'md:focus:text-indigo-50'
  | 'md:focus:text-indigo-100'
  | 'md:focus:text-indigo-200'
  | 'md:focus:text-indigo-300'
  | 'md:focus:text-indigo-400'
  | 'md:focus:text-indigo-500'
  | 'md:focus:text-indigo-600'
  | 'md:focus:text-indigo-700'
  | 'md:focus:text-indigo-800'
  | 'md:focus:text-indigo-900'
  | 'md:focus:text-purple-50'
  | 'md:focus:text-purple-100'
  | 'md:focus:text-purple-200'
  | 'md:focus:text-purple-300'
  | 'md:focus:text-purple-400'
  | 'md:focus:text-purple-500'
  | 'md:focus:text-purple-600'
  | 'md:focus:text-purple-700'
  | 'md:focus:text-purple-800'
  | 'md:focus:text-purple-900'
  | 'md:focus:text-pink-50'
  | 'md:focus:text-pink-100'
  | 'md:focus:text-pink-200'
  | 'md:focus:text-pink-300'
  | 'md:focus:text-pink-400'
  | 'md:focus:text-pink-500'
  | 'md:focus:text-pink-600'
  | 'md:focus:text-pink-700'
  | 'md:focus:text-pink-800'
  | 'md:focus:text-pink-900'
  | 'md:active:text-transparent'
  | 'md:active:text-white'
  | 'md:active:text-black'
  | 'md:active:text-gray-50'
  | 'md:active:text-gray-100'
  | 'md:active:text-gray-200'
  | 'md:active:text-gray-300'
  | 'md:active:text-gray-400'
  | 'md:active:text-gray-500'
  | 'md:active:text-gray-600'
  | 'md:active:text-gray-700'
  | 'md:active:text-gray-800'
  | 'md:active:text-gray-900'
  | 'md:active:text-cool-gray-50'
  | 'md:active:text-cool-gray-100'
  | 'md:active:text-cool-gray-200'
  | 'md:active:text-cool-gray-300'
  | 'md:active:text-cool-gray-400'
  | 'md:active:text-cool-gray-500'
  | 'md:active:text-cool-gray-600'
  | 'md:active:text-cool-gray-700'
  | 'md:active:text-cool-gray-800'
  | 'md:active:text-cool-gray-900'
  | 'md:active:text-red-50'
  | 'md:active:text-red-100'
  | 'md:active:text-red-200'
  | 'md:active:text-red-300'
  | 'md:active:text-red-400'
  | 'md:active:text-red-500'
  | 'md:active:text-red-600'
  | 'md:active:text-red-700'
  | 'md:active:text-red-800'
  | 'md:active:text-red-900'
  | 'md:active:text-orange-50'
  | 'md:active:text-orange-100'
  | 'md:active:text-orange-200'
  | 'md:active:text-orange-300'
  | 'md:active:text-orange-400'
  | 'md:active:text-orange-500'
  | 'md:active:text-orange-600'
  | 'md:active:text-orange-700'
  | 'md:active:text-orange-800'
  | 'md:active:text-orange-900'
  | 'md:active:text-yellow-50'
  | 'md:active:text-yellow-100'
  | 'md:active:text-yellow-200'
  | 'md:active:text-yellow-300'
  | 'md:active:text-yellow-400'
  | 'md:active:text-yellow-500'
  | 'md:active:text-yellow-600'
  | 'md:active:text-yellow-700'
  | 'md:active:text-yellow-800'
  | 'md:active:text-yellow-900'
  | 'md:active:text-green-50'
  | 'md:active:text-green-100'
  | 'md:active:text-green-200'
  | 'md:active:text-green-300'
  | 'md:active:text-green-400'
  | 'md:active:text-green-500'
  | 'md:active:text-green-600'
  | 'md:active:text-green-700'
  | 'md:active:text-green-800'
  | 'md:active:text-green-900'
  | 'md:active:text-teal-50'
  | 'md:active:text-teal-100'
  | 'md:active:text-teal-200'
  | 'md:active:text-teal-300'
  | 'md:active:text-teal-400'
  | 'md:active:text-teal-500'
  | 'md:active:text-teal-600'
  | 'md:active:text-teal-700'
  | 'md:active:text-teal-800'
  | 'md:active:text-teal-900'
  | 'md:active:text-blue-50'
  | 'md:active:text-blue-100'
  | 'md:active:text-blue-200'
  | 'md:active:text-blue-300'
  | 'md:active:text-blue-400'
  | 'md:active:text-blue-500'
  | 'md:active:text-blue-600'
  | 'md:active:text-blue-700'
  | 'md:active:text-blue-800'
  | 'md:active:text-blue-900'
  | 'md:active:text-indigo-50'
  | 'md:active:text-indigo-100'
  | 'md:active:text-indigo-200'
  | 'md:active:text-indigo-300'
  | 'md:active:text-indigo-400'
  | 'md:active:text-indigo-500'
  | 'md:active:text-indigo-600'
  | 'md:active:text-indigo-700'
  | 'md:active:text-indigo-800'
  | 'md:active:text-indigo-900'
  | 'md:active:text-purple-50'
  | 'md:active:text-purple-100'
  | 'md:active:text-purple-200'
  | 'md:active:text-purple-300'
  | 'md:active:text-purple-400'
  | 'md:active:text-purple-500'
  | 'md:active:text-purple-600'
  | 'md:active:text-purple-700'
  | 'md:active:text-purple-800'
  | 'md:active:text-purple-900'
  | 'md:active:text-pink-50'
  | 'md:active:text-pink-100'
  | 'md:active:text-pink-200'
  | 'md:active:text-pink-300'
  | 'md:active:text-pink-400'
  | 'md:active:text-pink-500'
  | 'md:active:text-pink-600'
  | 'md:active:text-pink-700'
  | 'md:active:text-pink-800'
  | 'md:active:text-pink-900'
  | 'md:text-xs'
  | 'md:text-sm'
  | 'md:text-base'
  | 'md:text-lg'
  | 'md:text-xl'
  | 'md:text-2xl'
  | 'md:text-3xl'
  | 'md:text-4xl'
  | 'md:text-5xl'
  | 'md:text-6xl'
  | 'md:italic'
  | 'md:not-italic'
  | 'md:uppercase'
  | 'md:lowercase'
  | 'md:capitalize'
  | 'md:normal-case'
  | 'md:underline'
  | 'md:line-through'
  | 'md:no-underline'
  | 'md:group-hover:underline'
  | 'md:group-hover:line-through'
  | 'md:group-hover:no-underline'
  | 'md:group-focus:underline'
  | 'md:group-focus:line-through'
  | 'md:group-focus:no-underline'
  | 'md:hover:underline'
  | 'md:hover:line-through'
  | 'md:hover:no-underline'
  | 'md:focus:underline'
  | 'md:focus:line-through'
  | 'md:focus:no-underline'
  | 'md:antialiased'
  | 'md:subpixel-antialiased'
  | 'md:tracking-tighter'
  | 'md:tracking-tight'
  | 'md:tracking-normal'
  | 'md:tracking-wide'
  | 'md:tracking-wider'
  | 'md:tracking-widest'
  | 'md:select-none'
  | 'md:select-text'
  | 'md:select-all'
  | 'md:select-auto'
  | 'md:align-baseline'
  | 'md:align-top'
  | 'md:align-middle'
  | 'md:align-bottom'
  | 'md:align-text-top'
  | 'md:align-text-bottom'
  | 'md:visible'
  | 'md:invisible'
  | 'md:whitespace-normal'
  | 'md:whitespace-no-wrap'
  | 'md:whitespace-pre'
  | 'md:whitespace-pre-line'
  | 'md:whitespace-pre-wrap'
  | 'md:break-normal'
  | 'md:break-words'
  | 'md:break-all'
  | 'md:truncate'
  | 'md:w-0'
  | 'md:w-1'
  | 'md:w-2'
  | 'md:w-3'
  | 'md:w-4'
  | 'md:w-5'
  | 'md:w-6'
  | 'md:w-7'
  | 'md:w-8'
  | 'md:w-9'
  | 'md:w-10'
  | 'md:w-11'
  | 'md:w-12'
  | 'md:w-13'
  | 'md:w-14'
  | 'md:w-15'
  | 'md:w-16'
  | 'md:w-20'
  | 'md:w-24'
  | 'md:w-28'
  | 'md:w-32'
  | 'md:w-36'
  | 'md:w-40'
  | 'md:w-48'
  | 'md:w-56'
  | 'md:w-60'
  | 'md:w-64'
  | 'md:w-72'
  | 'md:w-80'
  | 'md:w-96'
  | 'md:w-auto'
  | 'md:w-px'
  | 'md:w-0.5'
  | 'md:w-1.5'
  | 'md:w-2.5'
  | 'md:w-3.5'
  | 'md:w-1/2'
  | 'md:w-1/3'
  | 'md:w-2/3'
  | 'md:w-1/4'
  | 'md:w-2/4'
  | 'md:w-3/4'
  | 'md:w-1/5'
  | 'md:w-2/5'
  | 'md:w-3/5'
  | 'md:w-4/5'
  | 'md:w-1/6'
  | 'md:w-2/6'
  | 'md:w-3/6'
  | 'md:w-4/6'
  | 'md:w-5/6'
  | 'md:w-1/12'
  | 'md:w-2/12'
  | 'md:w-3/12'
  | 'md:w-4/12'
  | 'md:w-5/12'
  | 'md:w-6/12'
  | 'md:w-7/12'
  | 'md:w-8/12'
  | 'md:w-9/12'
  | 'md:w-10/12'
  | 'md:w-11/12'
  | 'md:w-full'
  | 'md:w-screen'
  | 'md:z-0'
  | 'md:z-10'
  | 'md:z-20'
  | 'md:z-30'
  | 'md:z-40'
  | 'md:z-50'
  | 'md:z-auto'
  | 'md:focus-within:z-0'
  | 'md:focus-within:z-10'
  | 'md:focus-within:z-20'
  | 'md:focus-within:z-30'
  | 'md:focus-within:z-40'
  | 'md:focus-within:z-50'
  | 'md:focus-within:z-auto'
  | 'md:focus:z-0'
  | 'md:focus:z-10'
  | 'md:focus:z-20'
  | 'md:focus:z-30'
  | 'md:focus:z-40'
  | 'md:focus:z-50'
  | 'md:focus:z-auto'
  | 'md:gap-0'
  | 'md:gap-1'
  | 'md:gap-2'
  | 'md:gap-3'
  | 'md:gap-4'
  | 'md:gap-5'
  | 'md:gap-6'
  | 'md:gap-7'
  | 'md:gap-8'
  | 'md:gap-9'
  | 'md:gap-10'
  | 'md:gap-11'
  | 'md:gap-12'
  | 'md:gap-13'
  | 'md:gap-14'
  | 'md:gap-15'
  | 'md:gap-16'
  | 'md:gap-20'
  | 'md:gap-24'
  | 'md:gap-28'
  | 'md:gap-32'
  | 'md:gap-36'
  | 'md:gap-40'
  | 'md:gap-48'
  | 'md:gap-56'
  | 'md:gap-60'
  | 'md:gap-64'
  | 'md:gap-72'
  | 'md:gap-80'
  | 'md:gap-96'
  | 'md:gap-px'
  | 'md:gap-0.5'
  | 'md:gap-1.5'
  | 'md:gap-2.5'
  | 'md:gap-3.5'
  | 'md:gap-1/2'
  | 'md:gap-1/3'
  | 'md:gap-2/3'
  | 'md:gap-1/4'
  | 'md:gap-2/4'
  | 'md:gap-3/4'
  | 'md:gap-1/5'
  | 'md:gap-2/5'
  | 'md:gap-3/5'
  | 'md:gap-4/5'
  | 'md:gap-1/6'
  | 'md:gap-2/6'
  | 'md:gap-3/6'
  | 'md:gap-4/6'
  | 'md:gap-5/6'
  | 'md:gap-1/12'
  | 'md:gap-2/12'
  | 'md:gap-3/12'
  | 'md:gap-4/12'
  | 'md:gap-5/12'
  | 'md:gap-6/12'
  | 'md:gap-7/12'
  | 'md:gap-8/12'
  | 'md:gap-9/12'
  | 'md:gap-10/12'
  | 'md:gap-11/12'
  | 'md:gap-full'
  | 'md:col-gap-0'
  | 'md:col-gap-1'
  | 'md:col-gap-2'
  | 'md:col-gap-3'
  | 'md:col-gap-4'
  | 'md:col-gap-5'
  | 'md:col-gap-6'
  | 'md:col-gap-7'
  | 'md:col-gap-8'
  | 'md:col-gap-9'
  | 'md:col-gap-10'
  | 'md:col-gap-11'
  | 'md:col-gap-12'
  | 'md:col-gap-13'
  | 'md:col-gap-14'
  | 'md:col-gap-15'
  | 'md:col-gap-16'
  | 'md:col-gap-20'
  | 'md:col-gap-24'
  | 'md:col-gap-28'
  | 'md:col-gap-32'
  | 'md:col-gap-36'
  | 'md:col-gap-40'
  | 'md:col-gap-48'
  | 'md:col-gap-56'
  | 'md:col-gap-60'
  | 'md:col-gap-64'
  | 'md:col-gap-72'
  | 'md:col-gap-80'
  | 'md:col-gap-96'
  | 'md:col-gap-px'
  | 'md:col-gap-0.5'
  | 'md:col-gap-1.5'
  | 'md:col-gap-2.5'
  | 'md:col-gap-3.5'
  | 'md:col-gap-1/2'
  | 'md:col-gap-1/3'
  | 'md:col-gap-2/3'
  | 'md:col-gap-1/4'
  | 'md:col-gap-2/4'
  | 'md:col-gap-3/4'
  | 'md:col-gap-1/5'
  | 'md:col-gap-2/5'
  | 'md:col-gap-3/5'
  | 'md:col-gap-4/5'
  | 'md:col-gap-1/6'
  | 'md:col-gap-2/6'
  | 'md:col-gap-3/6'
  | 'md:col-gap-4/6'
  | 'md:col-gap-5/6'
  | 'md:col-gap-1/12'
  | 'md:col-gap-2/12'
  | 'md:col-gap-3/12'
  | 'md:col-gap-4/12'
  | 'md:col-gap-5/12'
  | 'md:col-gap-6/12'
  | 'md:col-gap-7/12'
  | 'md:col-gap-8/12'
  | 'md:col-gap-9/12'
  | 'md:col-gap-10/12'
  | 'md:col-gap-11/12'
  | 'md:col-gap-full'
  | 'md:row-gap-0'
  | 'md:row-gap-1'
  | 'md:row-gap-2'
  | 'md:row-gap-3'
  | 'md:row-gap-4'
  | 'md:row-gap-5'
  | 'md:row-gap-6'
  | 'md:row-gap-7'
  | 'md:row-gap-8'
  | 'md:row-gap-9'
  | 'md:row-gap-10'
  | 'md:row-gap-11'
  | 'md:row-gap-12'
  | 'md:row-gap-13'
  | 'md:row-gap-14'
  | 'md:row-gap-15'
  | 'md:row-gap-16'
  | 'md:row-gap-20'
  | 'md:row-gap-24'
  | 'md:row-gap-28'
  | 'md:row-gap-32'
  | 'md:row-gap-36'
  | 'md:row-gap-40'
  | 'md:row-gap-48'
  | 'md:row-gap-56'
  | 'md:row-gap-60'
  | 'md:row-gap-64'
  | 'md:row-gap-72'
  | 'md:row-gap-80'
  | 'md:row-gap-96'
  | 'md:row-gap-px'
  | 'md:row-gap-0.5'
  | 'md:row-gap-1.5'
  | 'md:row-gap-2.5'
  | 'md:row-gap-3.5'
  | 'md:row-gap-1/2'
  | 'md:row-gap-1/3'
  | 'md:row-gap-2/3'
  | 'md:row-gap-1/4'
  | 'md:row-gap-2/4'
  | 'md:row-gap-3/4'
  | 'md:row-gap-1/5'
  | 'md:row-gap-2/5'
  | 'md:row-gap-3/5'
  | 'md:row-gap-4/5'
  | 'md:row-gap-1/6'
  | 'md:row-gap-2/6'
  | 'md:row-gap-3/6'
  | 'md:row-gap-4/6'
  | 'md:row-gap-5/6'
  | 'md:row-gap-1/12'
  | 'md:row-gap-2/12'
  | 'md:row-gap-3/12'
  | 'md:row-gap-4/12'
  | 'md:row-gap-5/12'
  | 'md:row-gap-6/12'
  | 'md:row-gap-7/12'
  | 'md:row-gap-8/12'
  | 'md:row-gap-9/12'
  | 'md:row-gap-10/12'
  | 'md:row-gap-11/12'
  | 'md:row-gap-full'
  | 'md:grid-flow-row'
  | 'md:grid-flow-col'
  | 'md:grid-flow-row-dense'
  | 'md:grid-flow-col-dense'
  | 'md:grid-cols-1'
  | 'md:grid-cols-2'
  | 'md:grid-cols-3'
  | 'md:grid-cols-4'
  | 'md:grid-cols-5'
  | 'md:grid-cols-6'
  | 'md:grid-cols-7'
  | 'md:grid-cols-8'
  | 'md:grid-cols-9'
  | 'md:grid-cols-10'
  | 'md:grid-cols-11'
  | 'md:grid-cols-12'
  | 'md:grid-cols-none'
  | 'md:col-auto'
  | 'md:col-span-1'
  | 'md:col-span-2'
  | 'md:col-span-3'
  | 'md:col-span-4'
  | 'md:col-span-5'
  | 'md:col-span-6'
  | 'md:col-span-7'
  | 'md:col-span-8'
  | 'md:col-span-9'
  | 'md:col-span-10'
  | 'md:col-span-11'
  | 'md:col-span-12'
  | 'md:col-start-1'
  | 'md:col-start-2'
  | 'md:col-start-3'
  | 'md:col-start-4'
  | 'md:col-start-5'
  | 'md:col-start-6'
  | 'md:col-start-7'
  | 'md:col-start-8'
  | 'md:col-start-9'
  | 'md:col-start-10'
  | 'md:col-start-11'
  | 'md:col-start-12'
  | 'md:col-start-13'
  | 'md:col-start-auto'
  | 'md:col-end-1'
  | 'md:col-end-2'
  | 'md:col-end-3'
  | 'md:col-end-4'
  | 'md:col-end-5'
  | 'md:col-end-6'
  | 'md:col-end-7'
  | 'md:col-end-8'
  | 'md:col-end-9'
  | 'md:col-end-10'
  | 'md:col-end-11'
  | 'md:col-end-12'
  | 'md:col-end-13'
  | 'md:col-end-auto'
  | 'md:grid-rows-1'
  | 'md:grid-rows-2'
  | 'md:grid-rows-3'
  | 'md:grid-rows-4'
  | 'md:grid-rows-5'
  | 'md:grid-rows-6'
  | 'md:grid-rows-none'
  | 'md:row-auto'
  | 'md:row-span-1'
  | 'md:row-span-2'
  | 'md:row-span-3'
  | 'md:row-span-4'
  | 'md:row-span-5'
  | 'md:row-span-6'
  | 'md:row-start-1'
  | 'md:row-start-2'
  | 'md:row-start-3'
  | 'md:row-start-4'
  | 'md:row-start-5'
  | 'md:row-start-6'
  | 'md:row-start-7'
  | 'md:row-start-auto'
  | 'md:row-end-1'
  | 'md:row-end-2'
  | 'md:row-end-3'
  | 'md:row-end-4'
  | 'md:row-end-5'
  | 'md:row-end-6'
  | 'md:row-end-7'
  | 'md:row-end-auto'
  | 'md:transform'
  | 'md:transform-none'
  | 'md:origin-center'
  | 'md:origin-top'
  | 'md:origin-top-right'
  | 'md:origin-right'
  | 'md:origin-bottom-right'
  | 'md:origin-bottom'
  | 'md:origin-bottom-left'
  | 'md:origin-left'
  | 'md:origin-top-left'
  | 'md:scale-0'
  | 'md:scale-50'
  | 'md:scale-75'
  | 'md:scale-90'
  | 'md:scale-95'
  | 'md:scale-100'
  | 'md:scale-105'
  | 'md:scale-110'
  | 'md:scale-125'
  | 'md:scale-150'
  | 'md:scale-x-0'
  | 'md:scale-x-50'
  | 'md:scale-x-75'
  | 'md:scale-x-90'
  | 'md:scale-x-95'
  | 'md:scale-x-100'
  | 'md:scale-x-105'
  | 'md:scale-x-110'
  | 'md:scale-x-125'
  | 'md:scale-x-150'
  | 'md:scale-y-0'
  | 'md:scale-y-50'
  | 'md:scale-y-75'
  | 'md:scale-y-90'
  | 'md:scale-y-95'
  | 'md:scale-y-100'
  | 'md:scale-y-105'
  | 'md:scale-y-110'
  | 'md:scale-y-125'
  | 'md:scale-y-150'
  | 'md:hover:scale-0'
  | 'md:hover:scale-50'
  | 'md:hover:scale-75'
  | 'md:hover:scale-90'
  | 'md:hover:scale-95'
  | 'md:hover:scale-100'
  | 'md:hover:scale-105'
  | 'md:hover:scale-110'
  | 'md:hover:scale-125'
  | 'md:hover:scale-150'
  | 'md:hover:scale-x-0'
  | 'md:hover:scale-x-50'
  | 'md:hover:scale-x-75'
  | 'md:hover:scale-x-90'
  | 'md:hover:scale-x-95'
  | 'md:hover:scale-x-100'
  | 'md:hover:scale-x-105'
  | 'md:hover:scale-x-110'
  | 'md:hover:scale-x-125'
  | 'md:hover:scale-x-150'
  | 'md:hover:scale-y-0'
  | 'md:hover:scale-y-50'
  | 'md:hover:scale-y-75'
  | 'md:hover:scale-y-90'
  | 'md:hover:scale-y-95'
  | 'md:hover:scale-y-100'
  | 'md:hover:scale-y-105'
  | 'md:hover:scale-y-110'
  | 'md:hover:scale-y-125'
  | 'md:hover:scale-y-150'
  | 'md:focus:scale-0'
  | 'md:focus:scale-50'
  | 'md:focus:scale-75'
  | 'md:focus:scale-90'
  | 'md:focus:scale-95'
  | 'md:focus:scale-100'
  | 'md:focus:scale-105'
  | 'md:focus:scale-110'
  | 'md:focus:scale-125'
  | 'md:focus:scale-150'
  | 'md:focus:scale-x-0'
  | 'md:focus:scale-x-50'
  | 'md:focus:scale-x-75'
  | 'md:focus:scale-x-90'
  | 'md:focus:scale-x-95'
  | 'md:focus:scale-x-100'
  | 'md:focus:scale-x-105'
  | 'md:focus:scale-x-110'
  | 'md:focus:scale-x-125'
  | 'md:focus:scale-x-150'
  | 'md:focus:scale-y-0'
  | 'md:focus:scale-y-50'
  | 'md:focus:scale-y-75'
  | 'md:focus:scale-y-90'
  | 'md:focus:scale-y-95'
  | 'md:focus:scale-y-100'
  | 'md:focus:scale-y-105'
  | 'md:focus:scale-y-110'
  | 'md:focus:scale-y-125'
  | 'md:focus:scale-y-150'
  | 'md:rotate-0'
  | 'md:rotate-45'
  | 'md:rotate-90'
  | 'md:rotate-180'
  | 'md:-rotate-180'
  | 'md:-rotate-90'
  | 'md:-rotate-45'
  | 'md:hover:rotate-0'
  | 'md:hover:rotate-45'
  | 'md:hover:rotate-90'
  | 'md:hover:rotate-180'
  | 'md:hover:-rotate-180'
  | 'md:hover:-rotate-90'
  | 'md:hover:-rotate-45'
  | 'md:focus:rotate-0'
  | 'md:focus:rotate-45'
  | 'md:focus:rotate-90'
  | 'md:focus:rotate-180'
  | 'md:focus:-rotate-180'
  | 'md:focus:-rotate-90'
  | 'md:focus:-rotate-45'
  | 'md:translate-x-0'
  | 'md:translate-x-1'
  | 'md:translate-x-2'
  | 'md:translate-x-3'
  | 'md:translate-x-4'
  | 'md:translate-x-5'
  | 'md:translate-x-6'
  | 'md:translate-x-7'
  | 'md:translate-x-8'
  | 'md:translate-x-9'
  | 'md:translate-x-10'
  | 'md:translate-x-11'
  | 'md:translate-x-12'
  | 'md:translate-x-13'
  | 'md:translate-x-14'
  | 'md:translate-x-15'
  | 'md:translate-x-16'
  | 'md:translate-x-20'
  | 'md:translate-x-24'
  | 'md:translate-x-28'
  | 'md:translate-x-32'
  | 'md:translate-x-36'
  | 'md:translate-x-40'
  | 'md:translate-x-48'
  | 'md:translate-x-56'
  | 'md:translate-x-60'
  | 'md:translate-x-64'
  | 'md:translate-x-72'
  | 'md:translate-x-80'
  | 'md:translate-x-96'
  | 'md:translate-x-px'
  | 'md:translate-x-0.5'
  | 'md:translate-x-1.5'
  | 'md:translate-x-2.5'
  | 'md:translate-x-3.5'
  | 'md:translate-x-1/2'
  | 'md:translate-x-1/3'
  | 'md:translate-x-2/3'
  | 'md:translate-x-1/4'
  | 'md:translate-x-2/4'
  | 'md:translate-x-3/4'
  | 'md:translate-x-1/5'
  | 'md:translate-x-2/5'
  | 'md:translate-x-3/5'
  | 'md:translate-x-4/5'
  | 'md:translate-x-1/6'
  | 'md:translate-x-2/6'
  | 'md:translate-x-3/6'
  | 'md:translate-x-4/6'
  | 'md:translate-x-5/6'
  | 'md:translate-x-1/12'
  | 'md:translate-x-2/12'
  | 'md:translate-x-3/12'
  | 'md:translate-x-4/12'
  | 'md:translate-x-5/12'
  | 'md:translate-x-6/12'
  | 'md:translate-x-7/12'
  | 'md:translate-x-8/12'
  | 'md:translate-x-9/12'
  | 'md:translate-x-10/12'
  | 'md:translate-x-11/12'
  | 'md:translate-x-full'
  | 'md:-translate-x-1'
  | 'md:-translate-x-2'
  | 'md:-translate-x-3'
  | 'md:-translate-x-4'
  | 'md:-translate-x-5'
  | 'md:-translate-x-6'
  | 'md:-translate-x-7'
  | 'md:-translate-x-8'
  | 'md:-translate-x-9'
  | 'md:-translate-x-10'
  | 'md:-translate-x-11'
  | 'md:-translate-x-12'
  | 'md:-translate-x-13'
  | 'md:-translate-x-14'
  | 'md:-translate-x-15'
  | 'md:-translate-x-16'
  | 'md:-translate-x-20'
  | 'md:-translate-x-24'
  | 'md:-translate-x-28'
  | 'md:-translate-x-32'
  | 'md:-translate-x-36'
  | 'md:-translate-x-40'
  | 'md:-translate-x-48'
  | 'md:-translate-x-56'
  | 'md:-translate-x-60'
  | 'md:-translate-x-64'
  | 'md:-translate-x-72'
  | 'md:-translate-x-80'
  | 'md:-translate-x-96'
  | 'md:-translate-x-px'
  | 'md:-translate-x-0.5'
  | 'md:-translate-x-1.5'
  | 'md:-translate-x-2.5'
  | 'md:-translate-x-3.5'
  | 'md:-translate-x-1/2'
  | 'md:-translate-x-1/3'
  | 'md:-translate-x-2/3'
  | 'md:-translate-x-1/4'
  | 'md:-translate-x-2/4'
  | 'md:-translate-x-3/4'
  | 'md:-translate-x-1/5'
  | 'md:-translate-x-2/5'
  | 'md:-translate-x-3/5'
  | 'md:-translate-x-4/5'
  | 'md:-translate-x-1/6'
  | 'md:-translate-x-2/6'
  | 'md:-translate-x-3/6'
  | 'md:-translate-x-4/6'
  | 'md:-translate-x-5/6'
  | 'md:-translate-x-1/12'
  | 'md:-translate-x-2/12'
  | 'md:-translate-x-3/12'
  | 'md:-translate-x-4/12'
  | 'md:-translate-x-5/12'
  | 'md:-translate-x-6/12'
  | 'md:-translate-x-7/12'
  | 'md:-translate-x-8/12'
  | 'md:-translate-x-9/12'
  | 'md:-translate-x-10/12'
  | 'md:-translate-x-11/12'
  | 'md:-translate-x-full'
  | 'md:translate-y-0'
  | 'md:translate-y-1'
  | 'md:translate-y-2'
  | 'md:translate-y-3'
  | 'md:translate-y-4'
  | 'md:translate-y-5'
  | 'md:translate-y-6'
  | 'md:translate-y-7'
  | 'md:translate-y-8'
  | 'md:translate-y-9'
  | 'md:translate-y-10'
  | 'md:translate-y-11'
  | 'md:translate-y-12'
  | 'md:translate-y-13'
  | 'md:translate-y-14'
  | 'md:translate-y-15'
  | 'md:translate-y-16'
  | 'md:translate-y-20'
  | 'md:translate-y-24'
  | 'md:translate-y-28'
  | 'md:translate-y-32'
  | 'md:translate-y-36'
  | 'md:translate-y-40'
  | 'md:translate-y-48'
  | 'md:translate-y-56'
  | 'md:translate-y-60'
  | 'md:translate-y-64'
  | 'md:translate-y-72'
  | 'md:translate-y-80'
  | 'md:translate-y-96'
  | 'md:translate-y-px'
  | 'md:translate-y-0.5'
  | 'md:translate-y-1.5'
  | 'md:translate-y-2.5'
  | 'md:translate-y-3.5'
  | 'md:translate-y-1/2'
  | 'md:translate-y-1/3'
  | 'md:translate-y-2/3'
  | 'md:translate-y-1/4'
  | 'md:translate-y-2/4'
  | 'md:translate-y-3/4'
  | 'md:translate-y-1/5'
  | 'md:translate-y-2/5'
  | 'md:translate-y-3/5'
  | 'md:translate-y-4/5'
  | 'md:translate-y-1/6'
  | 'md:translate-y-2/6'
  | 'md:translate-y-3/6'
  | 'md:translate-y-4/6'
  | 'md:translate-y-5/6'
  | 'md:translate-y-1/12'
  | 'md:translate-y-2/12'
  | 'md:translate-y-3/12'
  | 'md:translate-y-4/12'
  | 'md:translate-y-5/12'
  | 'md:translate-y-6/12'
  | 'md:translate-y-7/12'
  | 'md:translate-y-8/12'
  | 'md:translate-y-9/12'
  | 'md:translate-y-10/12'
  | 'md:translate-y-11/12'
  | 'md:translate-y-full'
  | 'md:-translate-y-1'
  | 'md:-translate-y-2'
  | 'md:-translate-y-3'
  | 'md:-translate-y-4'
  | 'md:-translate-y-5'
  | 'md:-translate-y-6'
  | 'md:-translate-y-7'
  | 'md:-translate-y-8'
  | 'md:-translate-y-9'
  | 'md:-translate-y-10'
  | 'md:-translate-y-11'
  | 'md:-translate-y-12'
  | 'md:-translate-y-13'
  | 'md:-translate-y-14'
  | 'md:-translate-y-15'
  | 'md:-translate-y-16'
  | 'md:-translate-y-20'
  | 'md:-translate-y-24'
  | 'md:-translate-y-28'
  | 'md:-translate-y-32'
  | 'md:-translate-y-36'
  | 'md:-translate-y-40'
  | 'md:-translate-y-48'
  | 'md:-translate-y-56'
  | 'md:-translate-y-60'
  | 'md:-translate-y-64'
  | 'md:-translate-y-72'
  | 'md:-translate-y-80'
  | 'md:-translate-y-96'
  | 'md:-translate-y-px'
  | 'md:-translate-y-0.5'
  | 'md:-translate-y-1.5'
  | 'md:-translate-y-2.5'
  | 'md:-translate-y-3.5'
  | 'md:-translate-y-1/2'
  | 'md:-translate-y-1/3'
  | 'md:-translate-y-2/3'
  | 'md:-translate-y-1/4'
  | 'md:-translate-y-2/4'
  | 'md:-translate-y-3/4'
  | 'md:-translate-y-1/5'
  | 'md:-translate-y-2/5'
  | 'md:-translate-y-3/5'
  | 'md:-translate-y-4/5'
  | 'md:-translate-y-1/6'
  | 'md:-translate-y-2/6'
  | 'md:-translate-y-3/6'
  | 'md:-translate-y-4/6'
  | 'md:-translate-y-5/6'
  | 'md:-translate-y-1/12'
  | 'md:-translate-y-2/12'
  | 'md:-translate-y-3/12'
  | 'md:-translate-y-4/12'
  | 'md:-translate-y-5/12'
  | 'md:-translate-y-6/12'
  | 'md:-translate-y-7/12'
  | 'md:-translate-y-8/12'
  | 'md:-translate-y-9/12'
  | 'md:-translate-y-10/12'
  | 'md:-translate-y-11/12'
  | 'md:-translate-y-full'
  | 'md:hover:translate-x-0'
  | 'md:hover:translate-x-1'
  | 'md:hover:translate-x-2'
  | 'md:hover:translate-x-3'
  | 'md:hover:translate-x-4'
  | 'md:hover:translate-x-5'
  | 'md:hover:translate-x-6'
  | 'md:hover:translate-x-7'
  | 'md:hover:translate-x-8'
  | 'md:hover:translate-x-9'
  | 'md:hover:translate-x-10'
  | 'md:hover:translate-x-11'
  | 'md:hover:translate-x-12'
  | 'md:hover:translate-x-13'
  | 'md:hover:translate-x-14'
  | 'md:hover:translate-x-15'
  | 'md:hover:translate-x-16'
  | 'md:hover:translate-x-20'
  | 'md:hover:translate-x-24'
  | 'md:hover:translate-x-28'
  | 'md:hover:translate-x-32'
  | 'md:hover:translate-x-36'
  | 'md:hover:translate-x-40'
  | 'md:hover:translate-x-48'
  | 'md:hover:translate-x-56'
  | 'md:hover:translate-x-60'
  | 'md:hover:translate-x-64'
  | 'md:hover:translate-x-72'
  | 'md:hover:translate-x-80'
  | 'md:hover:translate-x-96'
  | 'md:hover:translate-x-px'
  | 'md:hover:translate-x-0.5'
  | 'md:hover:translate-x-1.5'
  | 'md:hover:translate-x-2.5'
  | 'md:hover:translate-x-3.5'
  | 'md:hover:translate-x-1/2'
  | 'md:hover:translate-x-1/3'
  | 'md:hover:translate-x-2/3'
  | 'md:hover:translate-x-1/4'
  | 'md:hover:translate-x-2/4'
  | 'md:hover:translate-x-3/4'
  | 'md:hover:translate-x-1/5'
  | 'md:hover:translate-x-2/5'
  | 'md:hover:translate-x-3/5'
  | 'md:hover:translate-x-4/5'
  | 'md:hover:translate-x-1/6'
  | 'md:hover:translate-x-2/6'
  | 'md:hover:translate-x-3/6'
  | 'md:hover:translate-x-4/6'
  | 'md:hover:translate-x-5/6'
  | 'md:hover:translate-x-1/12'
  | 'md:hover:translate-x-2/12'
  | 'md:hover:translate-x-3/12'
  | 'md:hover:translate-x-4/12'
  | 'md:hover:translate-x-5/12'
  | 'md:hover:translate-x-6/12'
  | 'md:hover:translate-x-7/12'
  | 'md:hover:translate-x-8/12'
  | 'md:hover:translate-x-9/12'
  | 'md:hover:translate-x-10/12'
  | 'md:hover:translate-x-11/12'
  | 'md:hover:translate-x-full'
  | 'md:hover:-translate-x-1'
  | 'md:hover:-translate-x-2'
  | 'md:hover:-translate-x-3'
  | 'md:hover:-translate-x-4'
  | 'md:hover:-translate-x-5'
  | 'md:hover:-translate-x-6'
  | 'md:hover:-translate-x-7'
  | 'md:hover:-translate-x-8'
  | 'md:hover:-translate-x-9'
  | 'md:hover:-translate-x-10'
  | 'md:hover:-translate-x-11'
  | 'md:hover:-translate-x-12'
  | 'md:hover:-translate-x-13'
  | 'md:hover:-translate-x-14'
  | 'md:hover:-translate-x-15'
  | 'md:hover:-translate-x-16'
  | 'md:hover:-translate-x-20'
  | 'md:hover:-translate-x-24'
  | 'md:hover:-translate-x-28'
  | 'md:hover:-translate-x-32'
  | 'md:hover:-translate-x-36'
  | 'md:hover:-translate-x-40'
  | 'md:hover:-translate-x-48'
  | 'md:hover:-translate-x-56'
  | 'md:hover:-translate-x-60'
  | 'md:hover:-translate-x-64'
  | 'md:hover:-translate-x-72'
  | 'md:hover:-translate-x-80'
  | 'md:hover:-translate-x-96'
  | 'md:hover:-translate-x-px'
  | 'md:hover:-translate-x-0.5'
  | 'md:hover:-translate-x-1.5'
  | 'md:hover:-translate-x-2.5'
  | 'md:hover:-translate-x-3.5'
  | 'md:hover:-translate-x-1/2'
  | 'md:hover:-translate-x-1/3'
  | 'md:hover:-translate-x-2/3'
  | 'md:hover:-translate-x-1/4'
  | 'md:hover:-translate-x-2/4'
  | 'md:hover:-translate-x-3/4'
  | 'md:hover:-translate-x-1/5'
  | 'md:hover:-translate-x-2/5'
  | 'md:hover:-translate-x-3/5'
  | 'md:hover:-translate-x-4/5'
  | 'md:hover:-translate-x-1/6'
  | 'md:hover:-translate-x-2/6'
  | 'md:hover:-translate-x-3/6'
  | 'md:hover:-translate-x-4/6'
  | 'md:hover:-translate-x-5/6'
  | 'md:hover:-translate-x-1/12'
  | 'md:hover:-translate-x-2/12'
  | 'md:hover:-translate-x-3/12'
  | 'md:hover:-translate-x-4/12'
  | 'md:hover:-translate-x-5/12'
  | 'md:hover:-translate-x-6/12'
  | 'md:hover:-translate-x-7/12'
  | 'md:hover:-translate-x-8/12'
  | 'md:hover:-translate-x-9/12'
  | 'md:hover:-translate-x-10/12'
  | 'md:hover:-translate-x-11/12'
  | 'md:hover:-translate-x-full'
  | 'md:hover:translate-y-0'
  | 'md:hover:translate-y-1'
  | 'md:hover:translate-y-2'
  | 'md:hover:translate-y-3'
  | 'md:hover:translate-y-4'
  | 'md:hover:translate-y-5'
  | 'md:hover:translate-y-6'
  | 'md:hover:translate-y-7'
  | 'md:hover:translate-y-8'
  | 'md:hover:translate-y-9'
  | 'md:hover:translate-y-10'
  | 'md:hover:translate-y-11'
  | 'md:hover:translate-y-12'
  | 'md:hover:translate-y-13'
  | 'md:hover:translate-y-14'
  | 'md:hover:translate-y-15'
  | 'md:hover:translate-y-16'
  | 'md:hover:translate-y-20'
  | 'md:hover:translate-y-24'
  | 'md:hover:translate-y-28'
  | 'md:hover:translate-y-32'
  | 'md:hover:translate-y-36'
  | 'md:hover:translate-y-40'
  | 'md:hover:translate-y-48'
  | 'md:hover:translate-y-56'
  | 'md:hover:translate-y-60'
  | 'md:hover:translate-y-64'
  | 'md:hover:translate-y-72'
  | 'md:hover:translate-y-80'
  | 'md:hover:translate-y-96'
  | 'md:hover:translate-y-px'
  | 'md:hover:translate-y-0.5'
  | 'md:hover:translate-y-1.5'
  | 'md:hover:translate-y-2.5'
  | 'md:hover:translate-y-3.5'
  | 'md:hover:translate-y-1/2'
  | 'md:hover:translate-y-1/3'
  | 'md:hover:translate-y-2/3'
  | 'md:hover:translate-y-1/4'
  | 'md:hover:translate-y-2/4'
  | 'md:hover:translate-y-3/4'
  | 'md:hover:translate-y-1/5'
  | 'md:hover:translate-y-2/5'
  | 'md:hover:translate-y-3/5'
  | 'md:hover:translate-y-4/5'
  | 'md:hover:translate-y-1/6'
  | 'md:hover:translate-y-2/6'
  | 'md:hover:translate-y-3/6'
  | 'md:hover:translate-y-4/6'
  | 'md:hover:translate-y-5/6'
  | 'md:hover:translate-y-1/12'
  | 'md:hover:translate-y-2/12'
  | 'md:hover:translate-y-3/12'
  | 'md:hover:translate-y-4/12'
  | 'md:hover:translate-y-5/12'
  | 'md:hover:translate-y-6/12'
  | 'md:hover:translate-y-7/12'
  | 'md:hover:translate-y-8/12'
  | 'md:hover:translate-y-9/12'
  | 'md:hover:translate-y-10/12'
  | 'md:hover:translate-y-11/12'
  | 'md:hover:translate-y-full'
  | 'md:hover:-translate-y-1'
  | 'md:hover:-translate-y-2'
  | 'md:hover:-translate-y-3'
  | 'md:hover:-translate-y-4'
  | 'md:hover:-translate-y-5'
  | 'md:hover:-translate-y-6'
  | 'md:hover:-translate-y-7'
  | 'md:hover:-translate-y-8'
  | 'md:hover:-translate-y-9'
  | 'md:hover:-translate-y-10'
  | 'md:hover:-translate-y-11'
  | 'md:hover:-translate-y-12'
  | 'md:hover:-translate-y-13'
  | 'md:hover:-translate-y-14'
  | 'md:hover:-translate-y-15'
  | 'md:hover:-translate-y-16'
  | 'md:hover:-translate-y-20'
  | 'md:hover:-translate-y-24'
  | 'md:hover:-translate-y-28'
  | 'md:hover:-translate-y-32'
  | 'md:hover:-translate-y-36'
  | 'md:hover:-translate-y-40'
  | 'md:hover:-translate-y-48'
  | 'md:hover:-translate-y-56'
  | 'md:hover:-translate-y-60'
  | 'md:hover:-translate-y-64'
  | 'md:hover:-translate-y-72'
  | 'md:hover:-translate-y-80'
  | 'md:hover:-translate-y-96'
  | 'md:hover:-translate-y-px'
  | 'md:hover:-translate-y-0.5'
  | 'md:hover:-translate-y-1.5'
  | 'md:hover:-translate-y-2.5'
  | 'md:hover:-translate-y-3.5'
  | 'md:hover:-translate-y-1/2'
  | 'md:hover:-translate-y-1/3'
  | 'md:hover:-translate-y-2/3'
  | 'md:hover:-translate-y-1/4'
  | 'md:hover:-translate-y-2/4'
  | 'md:hover:-translate-y-3/4'
  | 'md:hover:-translate-y-1/5'
  | 'md:hover:-translate-y-2/5'
  | 'md:hover:-translate-y-3/5'
  | 'md:hover:-translate-y-4/5'
  | 'md:hover:-translate-y-1/6'
  | 'md:hover:-translate-y-2/6'
  | 'md:hover:-translate-y-3/6'
  | 'md:hover:-translate-y-4/6'
  | 'md:hover:-translate-y-5/6'
  | 'md:hover:-translate-y-1/12'
  | 'md:hover:-translate-y-2/12'
  | 'md:hover:-translate-y-3/12'
  | 'md:hover:-translate-y-4/12'
  | 'md:hover:-translate-y-5/12'
  | 'md:hover:-translate-y-6/12'
  | 'md:hover:-translate-y-7/12'
  | 'md:hover:-translate-y-8/12'
  | 'md:hover:-translate-y-9/12'
  | 'md:hover:-translate-y-10/12'
  | 'md:hover:-translate-y-11/12'
  | 'md:hover:-translate-y-full'
  | 'md:focus:translate-x-0'
  | 'md:focus:translate-x-1'
  | 'md:focus:translate-x-2'
  | 'md:focus:translate-x-3'
  | 'md:focus:translate-x-4'
  | 'md:focus:translate-x-5'
  | 'md:focus:translate-x-6'
  | 'md:focus:translate-x-7'
  | 'md:focus:translate-x-8'
  | 'md:focus:translate-x-9'
  | 'md:focus:translate-x-10'
  | 'md:focus:translate-x-11'
  | 'md:focus:translate-x-12'
  | 'md:focus:translate-x-13'
  | 'md:focus:translate-x-14'
  | 'md:focus:translate-x-15'
  | 'md:focus:translate-x-16'
  | 'md:focus:translate-x-20'
  | 'md:focus:translate-x-24'
  | 'md:focus:translate-x-28'
  | 'md:focus:translate-x-32'
  | 'md:focus:translate-x-36'
  | 'md:focus:translate-x-40'
  | 'md:focus:translate-x-48'
  | 'md:focus:translate-x-56'
  | 'md:focus:translate-x-60'
  | 'md:focus:translate-x-64'
  | 'md:focus:translate-x-72'
  | 'md:focus:translate-x-80'
  | 'md:focus:translate-x-96'
  | 'md:focus:translate-x-px'
  | 'md:focus:translate-x-0.5'
  | 'md:focus:translate-x-1.5'
  | 'md:focus:translate-x-2.5'
  | 'md:focus:translate-x-3.5'
  | 'md:focus:translate-x-1/2'
  | 'md:focus:translate-x-1/3'
  | 'md:focus:translate-x-2/3'
  | 'md:focus:translate-x-1/4'
  | 'md:focus:translate-x-2/4'
  | 'md:focus:translate-x-3/4'
  | 'md:focus:translate-x-1/5'
  | 'md:focus:translate-x-2/5'
  | 'md:focus:translate-x-3/5'
  | 'md:focus:translate-x-4/5'
  | 'md:focus:translate-x-1/6'
  | 'md:focus:translate-x-2/6'
  | 'md:focus:translate-x-3/6'
  | 'md:focus:translate-x-4/6'
  | 'md:focus:translate-x-5/6'
  | 'md:focus:translate-x-1/12'
  | 'md:focus:translate-x-2/12'
  | 'md:focus:translate-x-3/12'
  | 'md:focus:translate-x-4/12'
  | 'md:focus:translate-x-5/12'
  | 'md:focus:translate-x-6/12'
  | 'md:focus:translate-x-7/12'
  | 'md:focus:translate-x-8/12'
  | 'md:focus:translate-x-9/12'
  | 'md:focus:translate-x-10/12'
  | 'md:focus:translate-x-11/12'
  | 'md:focus:translate-x-full'
  | 'md:focus:-translate-x-1'
  | 'md:focus:-translate-x-2'
  | 'md:focus:-translate-x-3'
  | 'md:focus:-translate-x-4'
  | 'md:focus:-translate-x-5'
  | 'md:focus:-translate-x-6'
  | 'md:focus:-translate-x-7'
  | 'md:focus:-translate-x-8'
  | 'md:focus:-translate-x-9'
  | 'md:focus:-translate-x-10'
  | 'md:focus:-translate-x-11'
  | 'md:focus:-translate-x-12'
  | 'md:focus:-translate-x-13'
  | 'md:focus:-translate-x-14'
  | 'md:focus:-translate-x-15'
  | 'md:focus:-translate-x-16'
  | 'md:focus:-translate-x-20'
  | 'md:focus:-translate-x-24'
  | 'md:focus:-translate-x-28'
  | 'md:focus:-translate-x-32'
  | 'md:focus:-translate-x-36'
  | 'md:focus:-translate-x-40'
  | 'md:focus:-translate-x-48'
  | 'md:focus:-translate-x-56'
  | 'md:focus:-translate-x-60'
  | 'md:focus:-translate-x-64'
  | 'md:focus:-translate-x-72'
  | 'md:focus:-translate-x-80'
  | 'md:focus:-translate-x-96'
  | 'md:focus:-translate-x-px'
  | 'md:focus:-translate-x-0.5'
  | 'md:focus:-translate-x-1.5'
  | 'md:focus:-translate-x-2.5'
  | 'md:focus:-translate-x-3.5'
  | 'md:focus:-translate-x-1/2'
  | 'md:focus:-translate-x-1/3'
  | 'md:focus:-translate-x-2/3'
  | 'md:focus:-translate-x-1/4'
  | 'md:focus:-translate-x-2/4'
  | 'md:focus:-translate-x-3/4'
  | 'md:focus:-translate-x-1/5'
  | 'md:focus:-translate-x-2/5'
  | 'md:focus:-translate-x-3/5'
  | 'md:focus:-translate-x-4/5'
  | 'md:focus:-translate-x-1/6'
  | 'md:focus:-translate-x-2/6'
  | 'md:focus:-translate-x-3/6'
  | 'md:focus:-translate-x-4/6'
  | 'md:focus:-translate-x-5/6'
  | 'md:focus:-translate-x-1/12'
  | 'md:focus:-translate-x-2/12'
  | 'md:focus:-translate-x-3/12'
  | 'md:focus:-translate-x-4/12'
  | 'md:focus:-translate-x-5/12'
  | 'md:focus:-translate-x-6/12'
  | 'md:focus:-translate-x-7/12'
  | 'md:focus:-translate-x-8/12'
  | 'md:focus:-translate-x-9/12'
  | 'md:focus:-translate-x-10/12'
  | 'md:focus:-translate-x-11/12'
  | 'md:focus:-translate-x-full'
  | 'md:focus:translate-y-0'
  | 'md:focus:translate-y-1'
  | 'md:focus:translate-y-2'
  | 'md:focus:translate-y-3'
  | 'md:focus:translate-y-4'
  | 'md:focus:translate-y-5'
  | 'md:focus:translate-y-6'
  | 'md:focus:translate-y-7'
  | 'md:focus:translate-y-8'
  | 'md:focus:translate-y-9'
  | 'md:focus:translate-y-10'
  | 'md:focus:translate-y-11'
  | 'md:focus:translate-y-12'
  | 'md:focus:translate-y-13'
  | 'md:focus:translate-y-14'
  | 'md:focus:translate-y-15'
  | 'md:focus:translate-y-16'
  | 'md:focus:translate-y-20'
  | 'md:focus:translate-y-24'
  | 'md:focus:translate-y-28'
  | 'md:focus:translate-y-32'
  | 'md:focus:translate-y-36'
  | 'md:focus:translate-y-40'
  | 'md:focus:translate-y-48'
  | 'md:focus:translate-y-56'
  | 'md:focus:translate-y-60'
  | 'md:focus:translate-y-64'
  | 'md:focus:translate-y-72'
  | 'md:focus:translate-y-80'
  | 'md:focus:translate-y-96'
  | 'md:focus:translate-y-px'
  | 'md:focus:translate-y-0.5'
  | 'md:focus:translate-y-1.5'
  | 'md:focus:translate-y-2.5'
  | 'md:focus:translate-y-3.5'
  | 'md:focus:translate-y-1/2'
  | 'md:focus:translate-y-1/3'
  | 'md:focus:translate-y-2/3'
  | 'md:focus:translate-y-1/4'
  | 'md:focus:translate-y-2/4'
  | 'md:focus:translate-y-3/4'
  | 'md:focus:translate-y-1/5'
  | 'md:focus:translate-y-2/5'
  | 'md:focus:translate-y-3/5'
  | 'md:focus:translate-y-4/5'
  | 'md:focus:translate-y-1/6'
  | 'md:focus:translate-y-2/6'
  | 'md:focus:translate-y-3/6'
  | 'md:focus:translate-y-4/6'
  | 'md:focus:translate-y-5/6'
  | 'md:focus:translate-y-1/12'
  | 'md:focus:translate-y-2/12'
  | 'md:focus:translate-y-3/12'
  | 'md:focus:translate-y-4/12'
  | 'md:focus:translate-y-5/12'
  | 'md:focus:translate-y-6/12'
  | 'md:focus:translate-y-7/12'
  | 'md:focus:translate-y-8/12'
  | 'md:focus:translate-y-9/12'
  | 'md:focus:translate-y-10/12'
  | 'md:focus:translate-y-11/12'
  | 'md:focus:translate-y-full'
  | 'md:focus:-translate-y-1'
  | 'md:focus:-translate-y-2'
  | 'md:focus:-translate-y-3'
  | 'md:focus:-translate-y-4'
  | 'md:focus:-translate-y-5'
  | 'md:focus:-translate-y-6'
  | 'md:focus:-translate-y-7'
  | 'md:focus:-translate-y-8'
  | 'md:focus:-translate-y-9'
  | 'md:focus:-translate-y-10'
  | 'md:focus:-translate-y-11'
  | 'md:focus:-translate-y-12'
  | 'md:focus:-translate-y-13'
  | 'md:focus:-translate-y-14'
  | 'md:focus:-translate-y-15'
  | 'md:focus:-translate-y-16'
  | 'md:focus:-translate-y-20'
  | 'md:focus:-translate-y-24'
  | 'md:focus:-translate-y-28'
  | 'md:focus:-translate-y-32'
  | 'md:focus:-translate-y-36'
  | 'md:focus:-translate-y-40'
  | 'md:focus:-translate-y-48'
  | 'md:focus:-translate-y-56'
  | 'md:focus:-translate-y-60'
  | 'md:focus:-translate-y-64'
  | 'md:focus:-translate-y-72'
  | 'md:focus:-translate-y-80'
  | 'md:focus:-translate-y-96'
  | 'md:focus:-translate-y-px'
  | 'md:focus:-translate-y-0.5'
  | 'md:focus:-translate-y-1.5'
  | 'md:focus:-translate-y-2.5'
  | 'md:focus:-translate-y-3.5'
  | 'md:focus:-translate-y-1/2'
  | 'md:focus:-translate-y-1/3'
  | 'md:focus:-translate-y-2/3'
  | 'md:focus:-translate-y-1/4'
  | 'md:focus:-translate-y-2/4'
  | 'md:focus:-translate-y-3/4'
  | 'md:focus:-translate-y-1/5'
  | 'md:focus:-translate-y-2/5'
  | 'md:focus:-translate-y-3/5'
  | 'md:focus:-translate-y-4/5'
  | 'md:focus:-translate-y-1/6'
  | 'md:focus:-translate-y-2/6'
  | 'md:focus:-translate-y-3/6'
  | 'md:focus:-translate-y-4/6'
  | 'md:focus:-translate-y-5/6'
  | 'md:focus:-translate-y-1/12'
  | 'md:focus:-translate-y-2/12'
  | 'md:focus:-translate-y-3/12'
  | 'md:focus:-translate-y-4/12'
  | 'md:focus:-translate-y-5/12'
  | 'md:focus:-translate-y-6/12'
  | 'md:focus:-translate-y-7/12'
  | 'md:focus:-translate-y-8/12'
  | 'md:focus:-translate-y-9/12'
  | 'md:focus:-translate-y-10/12'
  | 'md:focus:-translate-y-11/12'
  | 'md:focus:-translate-y-full'
  | 'md:skew-x-0'
  | 'md:skew-x-3'
  | 'md:skew-x-6'
  | 'md:skew-x-12'
  | 'md:-skew-x-12'
  | 'md:-skew-x-6'
  | 'md:-skew-x-3'
  | 'md:skew-y-0'
  | 'md:skew-y-3'
  | 'md:skew-y-6'
  | 'md:skew-y-12'
  | 'md:-skew-y-12'
  | 'md:-skew-y-6'
  | 'md:-skew-y-3'
  | 'md:hover:skew-x-0'
  | 'md:hover:skew-x-3'
  | 'md:hover:skew-x-6'
  | 'md:hover:skew-x-12'
  | 'md:hover:-skew-x-12'
  | 'md:hover:-skew-x-6'
  | 'md:hover:-skew-x-3'
  | 'md:hover:skew-y-0'
  | 'md:hover:skew-y-3'
  | 'md:hover:skew-y-6'
  | 'md:hover:skew-y-12'
  | 'md:hover:-skew-y-12'
  | 'md:hover:-skew-y-6'
  | 'md:hover:-skew-y-3'
  | 'md:focus:skew-x-0'
  | 'md:focus:skew-x-3'
  | 'md:focus:skew-x-6'
  | 'md:focus:skew-x-12'
  | 'md:focus:-skew-x-12'
  | 'md:focus:-skew-x-6'
  | 'md:focus:-skew-x-3'
  | 'md:focus:skew-y-0'
  | 'md:focus:skew-y-3'
  | 'md:focus:skew-y-6'
  | 'md:focus:skew-y-12'
  | 'md:focus:-skew-y-12'
  | 'md:focus:-skew-y-6'
  | 'md:focus:-skew-y-3'
  | 'md:transition-none'
  | 'md:transition-all'
  | 'md:transition'
  | 'md:transition-colors'
  | 'md:transition-opacity'
  | 'md:transition-shadow'
  | 'md:transition-transform'
  | 'md:ease-linear'
  | 'md:ease-in'
  | 'md:ease-out'
  | 'md:ease-in-out'
  | 'md:duration-75'
  | 'md:duration-100'
  | 'md:duration-150'
  | 'md:duration-200'
  | 'md:duration-300'
  | 'md:duration-500'
  | 'md:duration-700'
  | 'md:duration-1000'
  | 'lg:sr-only'
  | 'lg:not-sr-only'
  | 'lg:focus:sr-only'
  | 'lg:focus:not-sr-only'
  | 'lg:appearance-none'
  | 'lg:bg-fixed'
  | 'lg:bg-local'
  | 'lg:bg-scroll'
  | 'lg:bg-transparent'
  | 'lg:bg-white'
  | 'lg:bg-black'
  | 'lg:bg-gray-50'
  | 'lg:bg-gray-100'
  | 'lg:bg-gray-200'
  | 'lg:bg-gray-300'
  | 'lg:bg-gray-400'
  | 'lg:bg-gray-500'
  | 'lg:bg-gray-600'
  | 'lg:bg-gray-700'
  | 'lg:bg-gray-800'
  | 'lg:bg-gray-900'
  | 'lg:bg-cool-gray-50'
  | 'lg:bg-cool-gray-100'
  | 'lg:bg-cool-gray-200'
  | 'lg:bg-cool-gray-300'
  | 'lg:bg-cool-gray-400'
  | 'lg:bg-cool-gray-500'
  | 'lg:bg-cool-gray-600'
  | 'lg:bg-cool-gray-700'
  | 'lg:bg-cool-gray-800'
  | 'lg:bg-cool-gray-900'
  | 'lg:bg-red-50'
  | 'lg:bg-red-100'
  | 'lg:bg-red-200'
  | 'lg:bg-red-300'
  | 'lg:bg-red-400'
  | 'lg:bg-red-500'
  | 'lg:bg-red-600'
  | 'lg:bg-red-700'
  | 'lg:bg-red-800'
  | 'lg:bg-red-900'
  | 'lg:bg-orange-50'
  | 'lg:bg-orange-100'
  | 'lg:bg-orange-200'
  | 'lg:bg-orange-300'
  | 'lg:bg-orange-400'
  | 'lg:bg-orange-500'
  | 'lg:bg-orange-600'
  | 'lg:bg-orange-700'
  | 'lg:bg-orange-800'
  | 'lg:bg-orange-900'
  | 'lg:bg-yellow-50'
  | 'lg:bg-yellow-100'
  | 'lg:bg-yellow-200'
  | 'lg:bg-yellow-300'
  | 'lg:bg-yellow-400'
  | 'lg:bg-yellow-500'
  | 'lg:bg-yellow-600'
  | 'lg:bg-yellow-700'
  | 'lg:bg-yellow-800'
  | 'lg:bg-yellow-900'
  | 'lg:bg-green-50'
  | 'lg:bg-green-100'
  | 'lg:bg-green-200'
  | 'lg:bg-green-300'
  | 'lg:bg-green-400'
  | 'lg:bg-green-500'
  | 'lg:bg-green-600'
  | 'lg:bg-green-700'
  | 'lg:bg-green-800'
  | 'lg:bg-green-900'
  | 'lg:bg-teal-50'
  | 'lg:bg-teal-100'
  | 'lg:bg-teal-200'
  | 'lg:bg-teal-300'
  | 'lg:bg-teal-400'
  | 'lg:bg-teal-500'
  | 'lg:bg-teal-600'
  | 'lg:bg-teal-700'
  | 'lg:bg-teal-800'
  | 'lg:bg-teal-900'
  | 'lg:bg-blue-50'
  | 'lg:bg-blue-100'
  | 'lg:bg-blue-200'
  | 'lg:bg-blue-300'
  | 'lg:bg-blue-400'
  | 'lg:bg-blue-500'
  | 'lg:bg-blue-600'
  | 'lg:bg-blue-700'
  | 'lg:bg-blue-800'
  | 'lg:bg-blue-900'
  | 'lg:bg-indigo-50'
  | 'lg:bg-indigo-100'
  | 'lg:bg-indigo-200'
  | 'lg:bg-indigo-300'
  | 'lg:bg-indigo-400'
  | 'lg:bg-indigo-500'
  | 'lg:bg-indigo-600'
  | 'lg:bg-indigo-700'
  | 'lg:bg-indigo-800'
  | 'lg:bg-indigo-900'
  | 'lg:bg-purple-50'
  | 'lg:bg-purple-100'
  | 'lg:bg-purple-200'
  | 'lg:bg-purple-300'
  | 'lg:bg-purple-400'
  | 'lg:bg-purple-500'
  | 'lg:bg-purple-600'
  | 'lg:bg-purple-700'
  | 'lg:bg-purple-800'
  | 'lg:bg-purple-900'
  | 'lg:bg-pink-50'
  | 'lg:bg-pink-100'
  | 'lg:bg-pink-200'
  | 'lg:bg-pink-300'
  | 'lg:bg-pink-400'
  | 'lg:bg-pink-500'
  | 'lg:bg-pink-600'
  | 'lg:bg-pink-700'
  | 'lg:bg-pink-800'
  | 'lg:bg-pink-900'
  | 'lg:group-hover:bg-transparent'
  | 'lg:group-hover:bg-white'
  | 'lg:group-hover:bg-black'
  | 'lg:group-hover:bg-gray-50'
  | 'lg:group-hover:bg-gray-100'
  | 'lg:group-hover:bg-gray-200'
  | 'lg:group-hover:bg-gray-300'
  | 'lg:group-hover:bg-gray-400'
  | 'lg:group-hover:bg-gray-500'
  | 'lg:group-hover:bg-gray-600'
  | 'lg:group-hover:bg-gray-700'
  | 'lg:group-hover:bg-gray-800'
  | 'lg:group-hover:bg-gray-900'
  | 'lg:group-hover:bg-cool-gray-50'
  | 'lg:group-hover:bg-cool-gray-100'
  | 'lg:group-hover:bg-cool-gray-200'
  | 'lg:group-hover:bg-cool-gray-300'
  | 'lg:group-hover:bg-cool-gray-400'
  | 'lg:group-hover:bg-cool-gray-500'
  | 'lg:group-hover:bg-cool-gray-600'
  | 'lg:group-hover:bg-cool-gray-700'
  | 'lg:group-hover:bg-cool-gray-800'
  | 'lg:group-hover:bg-cool-gray-900'
  | 'lg:group-hover:bg-red-50'
  | 'lg:group-hover:bg-red-100'
  | 'lg:group-hover:bg-red-200'
  | 'lg:group-hover:bg-red-300'
  | 'lg:group-hover:bg-red-400'
  | 'lg:group-hover:bg-red-500'
  | 'lg:group-hover:bg-red-600'
  | 'lg:group-hover:bg-red-700'
  | 'lg:group-hover:bg-red-800'
  | 'lg:group-hover:bg-red-900'
  | 'lg:group-hover:bg-orange-50'
  | 'lg:group-hover:bg-orange-100'
  | 'lg:group-hover:bg-orange-200'
  | 'lg:group-hover:bg-orange-300'
  | 'lg:group-hover:bg-orange-400'
  | 'lg:group-hover:bg-orange-500'
  | 'lg:group-hover:bg-orange-600'
  | 'lg:group-hover:bg-orange-700'
  | 'lg:group-hover:bg-orange-800'
  | 'lg:group-hover:bg-orange-900'
  | 'lg:group-hover:bg-yellow-50'
  | 'lg:group-hover:bg-yellow-100'
  | 'lg:group-hover:bg-yellow-200'
  | 'lg:group-hover:bg-yellow-300'
  | 'lg:group-hover:bg-yellow-400'
  | 'lg:group-hover:bg-yellow-500'
  | 'lg:group-hover:bg-yellow-600'
  | 'lg:group-hover:bg-yellow-700'
  | 'lg:group-hover:bg-yellow-800'
  | 'lg:group-hover:bg-yellow-900'
  | 'lg:group-hover:bg-green-50'
  | 'lg:group-hover:bg-green-100'
  | 'lg:group-hover:bg-green-200'
  | 'lg:group-hover:bg-green-300'
  | 'lg:group-hover:bg-green-400'
  | 'lg:group-hover:bg-green-500'
  | 'lg:group-hover:bg-green-600'
  | 'lg:group-hover:bg-green-700'
  | 'lg:group-hover:bg-green-800'
  | 'lg:group-hover:bg-green-900'
  | 'lg:group-hover:bg-teal-50'
  | 'lg:group-hover:bg-teal-100'
  | 'lg:group-hover:bg-teal-200'
  | 'lg:group-hover:bg-teal-300'
  | 'lg:group-hover:bg-teal-400'
  | 'lg:group-hover:bg-teal-500'
  | 'lg:group-hover:bg-teal-600'
  | 'lg:group-hover:bg-teal-700'
  | 'lg:group-hover:bg-teal-800'
  | 'lg:group-hover:bg-teal-900'
  | 'lg:group-hover:bg-blue-50'
  | 'lg:group-hover:bg-blue-100'
  | 'lg:group-hover:bg-blue-200'
  | 'lg:group-hover:bg-blue-300'
  | 'lg:group-hover:bg-blue-400'
  | 'lg:group-hover:bg-blue-500'
  | 'lg:group-hover:bg-blue-600'
  | 'lg:group-hover:bg-blue-700'
  | 'lg:group-hover:bg-blue-800'
  | 'lg:group-hover:bg-blue-900'
  | 'lg:group-hover:bg-indigo-50'
  | 'lg:group-hover:bg-indigo-100'
  | 'lg:group-hover:bg-indigo-200'
  | 'lg:group-hover:bg-indigo-300'
  | 'lg:group-hover:bg-indigo-400'
  | 'lg:group-hover:bg-indigo-500'
  | 'lg:group-hover:bg-indigo-600'
  | 'lg:group-hover:bg-indigo-700'
  | 'lg:group-hover:bg-indigo-800'
  | 'lg:group-hover:bg-indigo-900'
  | 'lg:group-hover:bg-purple-50'
  | 'lg:group-hover:bg-purple-100'
  | 'lg:group-hover:bg-purple-200'
  | 'lg:group-hover:bg-purple-300'
  | 'lg:group-hover:bg-purple-400'
  | 'lg:group-hover:bg-purple-500'
  | 'lg:group-hover:bg-purple-600'
  | 'lg:group-hover:bg-purple-700'
  | 'lg:group-hover:bg-purple-800'
  | 'lg:group-hover:bg-purple-900'
  | 'lg:group-hover:bg-pink-50'
  | 'lg:group-hover:bg-pink-100'
  | 'lg:group-hover:bg-pink-200'
  | 'lg:group-hover:bg-pink-300'
  | 'lg:group-hover:bg-pink-400'
  | 'lg:group-hover:bg-pink-500'
  | 'lg:group-hover:bg-pink-600'
  | 'lg:group-hover:bg-pink-700'
  | 'lg:group-hover:bg-pink-800'
  | 'lg:group-hover:bg-pink-900'
  | 'lg:group-focus:bg-transparent'
  | 'lg:group-focus:bg-white'
  | 'lg:group-focus:bg-black'
  | 'lg:group-focus:bg-gray-50'
  | 'lg:group-focus:bg-gray-100'
  | 'lg:group-focus:bg-gray-200'
  | 'lg:group-focus:bg-gray-300'
  | 'lg:group-focus:bg-gray-400'
  | 'lg:group-focus:bg-gray-500'
  | 'lg:group-focus:bg-gray-600'
  | 'lg:group-focus:bg-gray-700'
  | 'lg:group-focus:bg-gray-800'
  | 'lg:group-focus:bg-gray-900'
  | 'lg:group-focus:bg-cool-gray-50'
  | 'lg:group-focus:bg-cool-gray-100'
  | 'lg:group-focus:bg-cool-gray-200'
  | 'lg:group-focus:bg-cool-gray-300'
  | 'lg:group-focus:bg-cool-gray-400'
  | 'lg:group-focus:bg-cool-gray-500'
  | 'lg:group-focus:bg-cool-gray-600'
  | 'lg:group-focus:bg-cool-gray-700'
  | 'lg:group-focus:bg-cool-gray-800'
  | 'lg:group-focus:bg-cool-gray-900'
  | 'lg:group-focus:bg-red-50'
  | 'lg:group-focus:bg-red-100'
  | 'lg:group-focus:bg-red-200'
  | 'lg:group-focus:bg-red-300'
  | 'lg:group-focus:bg-red-400'
  | 'lg:group-focus:bg-red-500'
  | 'lg:group-focus:bg-red-600'
  | 'lg:group-focus:bg-red-700'
  | 'lg:group-focus:bg-red-800'
  | 'lg:group-focus:bg-red-900'
  | 'lg:group-focus:bg-orange-50'
  | 'lg:group-focus:bg-orange-100'
  | 'lg:group-focus:bg-orange-200'
  | 'lg:group-focus:bg-orange-300'
  | 'lg:group-focus:bg-orange-400'
  | 'lg:group-focus:bg-orange-500'
  | 'lg:group-focus:bg-orange-600'
  | 'lg:group-focus:bg-orange-700'
  | 'lg:group-focus:bg-orange-800'
  | 'lg:group-focus:bg-orange-900'
  | 'lg:group-focus:bg-yellow-50'
  | 'lg:group-focus:bg-yellow-100'
  | 'lg:group-focus:bg-yellow-200'
  | 'lg:group-focus:bg-yellow-300'
  | 'lg:group-focus:bg-yellow-400'
  | 'lg:group-focus:bg-yellow-500'
  | 'lg:group-focus:bg-yellow-600'
  | 'lg:group-focus:bg-yellow-700'
  | 'lg:group-focus:bg-yellow-800'
  | 'lg:group-focus:bg-yellow-900'
  | 'lg:group-focus:bg-green-50'
  | 'lg:group-focus:bg-green-100'
  | 'lg:group-focus:bg-green-200'
  | 'lg:group-focus:bg-green-300'
  | 'lg:group-focus:bg-green-400'
  | 'lg:group-focus:bg-green-500'
  | 'lg:group-focus:bg-green-600'
  | 'lg:group-focus:bg-green-700'
  | 'lg:group-focus:bg-green-800'
  | 'lg:group-focus:bg-green-900'
  | 'lg:group-focus:bg-teal-50'
  | 'lg:group-focus:bg-teal-100'
  | 'lg:group-focus:bg-teal-200'
  | 'lg:group-focus:bg-teal-300'
  | 'lg:group-focus:bg-teal-400'
  | 'lg:group-focus:bg-teal-500'
  | 'lg:group-focus:bg-teal-600'
  | 'lg:group-focus:bg-teal-700'
  | 'lg:group-focus:bg-teal-800'
  | 'lg:group-focus:bg-teal-900'
  | 'lg:group-focus:bg-blue-50'
  | 'lg:group-focus:bg-blue-100'
  | 'lg:group-focus:bg-blue-200'
  | 'lg:group-focus:bg-blue-300'
  | 'lg:group-focus:bg-blue-400'
  | 'lg:group-focus:bg-blue-500'
  | 'lg:group-focus:bg-blue-600'
  | 'lg:group-focus:bg-blue-700'
  | 'lg:group-focus:bg-blue-800'
  | 'lg:group-focus:bg-blue-900'
  | 'lg:group-focus:bg-indigo-50'
  | 'lg:group-focus:bg-indigo-100'
  | 'lg:group-focus:bg-indigo-200'
  | 'lg:group-focus:bg-indigo-300'
  | 'lg:group-focus:bg-indigo-400'
  | 'lg:group-focus:bg-indigo-500'
  | 'lg:group-focus:bg-indigo-600'
  | 'lg:group-focus:bg-indigo-700'
  | 'lg:group-focus:bg-indigo-800'
  | 'lg:group-focus:bg-indigo-900'
  | 'lg:group-focus:bg-purple-50'
  | 'lg:group-focus:bg-purple-100'
  | 'lg:group-focus:bg-purple-200'
  | 'lg:group-focus:bg-purple-300'
  | 'lg:group-focus:bg-purple-400'
  | 'lg:group-focus:bg-purple-500'
  | 'lg:group-focus:bg-purple-600'
  | 'lg:group-focus:bg-purple-700'
  | 'lg:group-focus:bg-purple-800'
  | 'lg:group-focus:bg-purple-900'
  | 'lg:group-focus:bg-pink-50'
  | 'lg:group-focus:bg-pink-100'
  | 'lg:group-focus:bg-pink-200'
  | 'lg:group-focus:bg-pink-300'
  | 'lg:group-focus:bg-pink-400'
  | 'lg:group-focus:bg-pink-500'
  | 'lg:group-focus:bg-pink-600'
  | 'lg:group-focus:bg-pink-700'
  | 'lg:group-focus:bg-pink-800'
  | 'lg:group-focus:bg-pink-900'
  | 'lg:hover:bg-transparent'
  | 'lg:hover:bg-white'
  | 'lg:hover:bg-black'
  | 'lg:hover:bg-gray-50'
  | 'lg:hover:bg-gray-100'
  | 'lg:hover:bg-gray-200'
  | 'lg:hover:bg-gray-300'
  | 'lg:hover:bg-gray-400'
  | 'lg:hover:bg-gray-500'
  | 'lg:hover:bg-gray-600'
  | 'lg:hover:bg-gray-700'
  | 'lg:hover:bg-gray-800'
  | 'lg:hover:bg-gray-900'
  | 'lg:hover:bg-cool-gray-50'
  | 'lg:hover:bg-cool-gray-100'
  | 'lg:hover:bg-cool-gray-200'
  | 'lg:hover:bg-cool-gray-300'
  | 'lg:hover:bg-cool-gray-400'
  | 'lg:hover:bg-cool-gray-500'
  | 'lg:hover:bg-cool-gray-600'
  | 'lg:hover:bg-cool-gray-700'
  | 'lg:hover:bg-cool-gray-800'
  | 'lg:hover:bg-cool-gray-900'
  | 'lg:hover:bg-red-50'
  | 'lg:hover:bg-red-100'
  | 'lg:hover:bg-red-200'
  | 'lg:hover:bg-red-300'
  | 'lg:hover:bg-red-400'
  | 'lg:hover:bg-red-500'
  | 'lg:hover:bg-red-600'
  | 'lg:hover:bg-red-700'
  | 'lg:hover:bg-red-800'
  | 'lg:hover:bg-red-900'
  | 'lg:hover:bg-orange-50'
  | 'lg:hover:bg-orange-100'
  | 'lg:hover:bg-orange-200'
  | 'lg:hover:bg-orange-300'
  | 'lg:hover:bg-orange-400'
  | 'lg:hover:bg-orange-500'
  | 'lg:hover:bg-orange-600'
  | 'lg:hover:bg-orange-700'
  | 'lg:hover:bg-orange-800'
  | 'lg:hover:bg-orange-900'
  | 'lg:hover:bg-yellow-50'
  | 'lg:hover:bg-yellow-100'
  | 'lg:hover:bg-yellow-200'
  | 'lg:hover:bg-yellow-300'
  | 'lg:hover:bg-yellow-400'
  | 'lg:hover:bg-yellow-500'
  | 'lg:hover:bg-yellow-600'
  | 'lg:hover:bg-yellow-700'
  | 'lg:hover:bg-yellow-800'
  | 'lg:hover:bg-yellow-900'
  | 'lg:hover:bg-green-50'
  | 'lg:hover:bg-green-100'
  | 'lg:hover:bg-green-200'
  | 'lg:hover:bg-green-300'
  | 'lg:hover:bg-green-400'
  | 'lg:hover:bg-green-500'
  | 'lg:hover:bg-green-600'
  | 'lg:hover:bg-green-700'
  | 'lg:hover:bg-green-800'
  | 'lg:hover:bg-green-900'
  | 'lg:hover:bg-teal-50'
  | 'lg:hover:bg-teal-100'
  | 'lg:hover:bg-teal-200'
  | 'lg:hover:bg-teal-300'
  | 'lg:hover:bg-teal-400'
  | 'lg:hover:bg-teal-500'
  | 'lg:hover:bg-teal-600'
  | 'lg:hover:bg-teal-700'
  | 'lg:hover:bg-teal-800'
  | 'lg:hover:bg-teal-900'
  | 'lg:hover:bg-blue-50'
  | 'lg:hover:bg-blue-100'
  | 'lg:hover:bg-blue-200'
  | 'lg:hover:bg-blue-300'
  | 'lg:hover:bg-blue-400'
  | 'lg:hover:bg-blue-500'
  | 'lg:hover:bg-blue-600'
  | 'lg:hover:bg-blue-700'
  | 'lg:hover:bg-blue-800'
  | 'lg:hover:bg-blue-900'
  | 'lg:hover:bg-indigo-50'
  | 'lg:hover:bg-indigo-100'
  | 'lg:hover:bg-indigo-200'
  | 'lg:hover:bg-indigo-300'
  | 'lg:hover:bg-indigo-400'
  | 'lg:hover:bg-indigo-500'
  | 'lg:hover:bg-indigo-600'
  | 'lg:hover:bg-indigo-700'
  | 'lg:hover:bg-indigo-800'
  | 'lg:hover:bg-indigo-900'
  | 'lg:hover:bg-purple-50'
  | 'lg:hover:bg-purple-100'
  | 'lg:hover:bg-purple-200'
  | 'lg:hover:bg-purple-300'
  | 'lg:hover:bg-purple-400'
  | 'lg:hover:bg-purple-500'
  | 'lg:hover:bg-purple-600'
  | 'lg:hover:bg-purple-700'
  | 'lg:hover:bg-purple-800'
  | 'lg:hover:bg-purple-900'
  | 'lg:hover:bg-pink-50'
  | 'lg:hover:bg-pink-100'
  | 'lg:hover:bg-pink-200'
  | 'lg:hover:bg-pink-300'
  | 'lg:hover:bg-pink-400'
  | 'lg:hover:bg-pink-500'
  | 'lg:hover:bg-pink-600'
  | 'lg:hover:bg-pink-700'
  | 'lg:hover:bg-pink-800'
  | 'lg:hover:bg-pink-900'
  | 'lg:focus:bg-transparent'
  | 'lg:focus:bg-white'
  | 'lg:focus:bg-black'
  | 'lg:focus:bg-gray-50'
  | 'lg:focus:bg-gray-100'
  | 'lg:focus:bg-gray-200'
  | 'lg:focus:bg-gray-300'
  | 'lg:focus:bg-gray-400'
  | 'lg:focus:bg-gray-500'
  | 'lg:focus:bg-gray-600'
  | 'lg:focus:bg-gray-700'
  | 'lg:focus:bg-gray-800'
  | 'lg:focus:bg-gray-900'
  | 'lg:focus:bg-cool-gray-50'
  | 'lg:focus:bg-cool-gray-100'
  | 'lg:focus:bg-cool-gray-200'
  | 'lg:focus:bg-cool-gray-300'
  | 'lg:focus:bg-cool-gray-400'
  | 'lg:focus:bg-cool-gray-500'
  | 'lg:focus:bg-cool-gray-600'
  | 'lg:focus:bg-cool-gray-700'
  | 'lg:focus:bg-cool-gray-800'
  | 'lg:focus:bg-cool-gray-900'
  | 'lg:focus:bg-red-50'
  | 'lg:focus:bg-red-100'
  | 'lg:focus:bg-red-200'
  | 'lg:focus:bg-red-300'
  | 'lg:focus:bg-red-400'
  | 'lg:focus:bg-red-500'
  | 'lg:focus:bg-red-600'
  | 'lg:focus:bg-red-700'
  | 'lg:focus:bg-red-800'
  | 'lg:focus:bg-red-900'
  | 'lg:focus:bg-orange-50'
  | 'lg:focus:bg-orange-100'
  | 'lg:focus:bg-orange-200'
  | 'lg:focus:bg-orange-300'
  | 'lg:focus:bg-orange-400'
  | 'lg:focus:bg-orange-500'
  | 'lg:focus:bg-orange-600'
  | 'lg:focus:bg-orange-700'
  | 'lg:focus:bg-orange-800'
  | 'lg:focus:bg-orange-900'
  | 'lg:focus:bg-yellow-50'
  | 'lg:focus:bg-yellow-100'
  | 'lg:focus:bg-yellow-200'
  | 'lg:focus:bg-yellow-300'
  | 'lg:focus:bg-yellow-400'
  | 'lg:focus:bg-yellow-500'
  | 'lg:focus:bg-yellow-600'
  | 'lg:focus:bg-yellow-700'
  | 'lg:focus:bg-yellow-800'
  | 'lg:focus:bg-yellow-900'
  | 'lg:focus:bg-green-50'
  | 'lg:focus:bg-green-100'
  | 'lg:focus:bg-green-200'
  | 'lg:focus:bg-green-300'
  | 'lg:focus:bg-green-400'
  | 'lg:focus:bg-green-500'
  | 'lg:focus:bg-green-600'
  | 'lg:focus:bg-green-700'
  | 'lg:focus:bg-green-800'
  | 'lg:focus:bg-green-900'
  | 'lg:focus:bg-teal-50'
  | 'lg:focus:bg-teal-100'
  | 'lg:focus:bg-teal-200'
  | 'lg:focus:bg-teal-300'
  | 'lg:focus:bg-teal-400'
  | 'lg:focus:bg-teal-500'
  | 'lg:focus:bg-teal-600'
  | 'lg:focus:bg-teal-700'
  | 'lg:focus:bg-teal-800'
  | 'lg:focus:bg-teal-900'
  | 'lg:focus:bg-blue-50'
  | 'lg:focus:bg-blue-100'
  | 'lg:focus:bg-blue-200'
  | 'lg:focus:bg-blue-300'
  | 'lg:focus:bg-blue-400'
  | 'lg:focus:bg-blue-500'
  | 'lg:focus:bg-blue-600'
  | 'lg:focus:bg-blue-700'
  | 'lg:focus:bg-blue-800'
  | 'lg:focus:bg-blue-900'
  | 'lg:focus:bg-indigo-50'
  | 'lg:focus:bg-indigo-100'
  | 'lg:focus:bg-indigo-200'
  | 'lg:focus:bg-indigo-300'
  | 'lg:focus:bg-indigo-400'
  | 'lg:focus:bg-indigo-500'
  | 'lg:focus:bg-indigo-600'
  | 'lg:focus:bg-indigo-700'
  | 'lg:focus:bg-indigo-800'
  | 'lg:focus:bg-indigo-900'
  | 'lg:focus:bg-purple-50'
  | 'lg:focus:bg-purple-100'
  | 'lg:focus:bg-purple-200'
  | 'lg:focus:bg-purple-300'
  | 'lg:focus:bg-purple-400'
  | 'lg:focus:bg-purple-500'
  | 'lg:focus:bg-purple-600'
  | 'lg:focus:bg-purple-700'
  | 'lg:focus:bg-purple-800'
  | 'lg:focus:bg-purple-900'
  | 'lg:focus:bg-pink-50'
  | 'lg:focus:bg-pink-100'
  | 'lg:focus:bg-pink-200'
  | 'lg:focus:bg-pink-300'
  | 'lg:focus:bg-pink-400'
  | 'lg:focus:bg-pink-500'
  | 'lg:focus:bg-pink-600'
  | 'lg:focus:bg-pink-700'
  | 'lg:focus:bg-pink-800'
  | 'lg:focus:bg-pink-900'
  | 'lg:active:bg-transparent'
  | 'lg:active:bg-white'
  | 'lg:active:bg-black'
  | 'lg:active:bg-gray-50'
  | 'lg:active:bg-gray-100'
  | 'lg:active:bg-gray-200'
  | 'lg:active:bg-gray-300'
  | 'lg:active:bg-gray-400'
  | 'lg:active:bg-gray-500'
  | 'lg:active:bg-gray-600'
  | 'lg:active:bg-gray-700'
  | 'lg:active:bg-gray-800'
  | 'lg:active:bg-gray-900'
  | 'lg:active:bg-cool-gray-50'
  | 'lg:active:bg-cool-gray-100'
  | 'lg:active:bg-cool-gray-200'
  | 'lg:active:bg-cool-gray-300'
  | 'lg:active:bg-cool-gray-400'
  | 'lg:active:bg-cool-gray-500'
  | 'lg:active:bg-cool-gray-600'
  | 'lg:active:bg-cool-gray-700'
  | 'lg:active:bg-cool-gray-800'
  | 'lg:active:bg-cool-gray-900'
  | 'lg:active:bg-red-50'
  | 'lg:active:bg-red-100'
  | 'lg:active:bg-red-200'
  | 'lg:active:bg-red-300'
  | 'lg:active:bg-red-400'
  | 'lg:active:bg-red-500'
  | 'lg:active:bg-red-600'
  | 'lg:active:bg-red-700'
  | 'lg:active:bg-red-800'
  | 'lg:active:bg-red-900'
  | 'lg:active:bg-orange-50'
  | 'lg:active:bg-orange-100'
  | 'lg:active:bg-orange-200'
  | 'lg:active:bg-orange-300'
  | 'lg:active:bg-orange-400'
  | 'lg:active:bg-orange-500'
  | 'lg:active:bg-orange-600'
  | 'lg:active:bg-orange-700'
  | 'lg:active:bg-orange-800'
  | 'lg:active:bg-orange-900'
  | 'lg:active:bg-yellow-50'
  | 'lg:active:bg-yellow-100'
  | 'lg:active:bg-yellow-200'
  | 'lg:active:bg-yellow-300'
  | 'lg:active:bg-yellow-400'
  | 'lg:active:bg-yellow-500'
  | 'lg:active:bg-yellow-600'
  | 'lg:active:bg-yellow-700'
  | 'lg:active:bg-yellow-800'
  | 'lg:active:bg-yellow-900'
  | 'lg:active:bg-green-50'
  | 'lg:active:bg-green-100'
  | 'lg:active:bg-green-200'
  | 'lg:active:bg-green-300'
  | 'lg:active:bg-green-400'
  | 'lg:active:bg-green-500'
  | 'lg:active:bg-green-600'
  | 'lg:active:bg-green-700'
  | 'lg:active:bg-green-800'
  | 'lg:active:bg-green-900'
  | 'lg:active:bg-teal-50'
  | 'lg:active:bg-teal-100'
  | 'lg:active:bg-teal-200'
  | 'lg:active:bg-teal-300'
  | 'lg:active:bg-teal-400'
  | 'lg:active:bg-teal-500'
  | 'lg:active:bg-teal-600'
  | 'lg:active:bg-teal-700'
  | 'lg:active:bg-teal-800'
  | 'lg:active:bg-teal-900'
  | 'lg:active:bg-blue-50'
  | 'lg:active:bg-blue-100'
  | 'lg:active:bg-blue-200'
  | 'lg:active:bg-blue-300'
  | 'lg:active:bg-blue-400'
  | 'lg:active:bg-blue-500'
  | 'lg:active:bg-blue-600'
  | 'lg:active:bg-blue-700'
  | 'lg:active:bg-blue-800'
  | 'lg:active:bg-blue-900'
  | 'lg:active:bg-indigo-50'
  | 'lg:active:bg-indigo-100'
  | 'lg:active:bg-indigo-200'
  | 'lg:active:bg-indigo-300'
  | 'lg:active:bg-indigo-400'
  | 'lg:active:bg-indigo-500'
  | 'lg:active:bg-indigo-600'
  | 'lg:active:bg-indigo-700'
  | 'lg:active:bg-indigo-800'
  | 'lg:active:bg-indigo-900'
  | 'lg:active:bg-purple-50'
  | 'lg:active:bg-purple-100'
  | 'lg:active:bg-purple-200'
  | 'lg:active:bg-purple-300'
  | 'lg:active:bg-purple-400'
  | 'lg:active:bg-purple-500'
  | 'lg:active:bg-purple-600'
  | 'lg:active:bg-purple-700'
  | 'lg:active:bg-purple-800'
  | 'lg:active:bg-purple-900'
  | 'lg:active:bg-pink-50'
  | 'lg:active:bg-pink-100'
  | 'lg:active:bg-pink-200'
  | 'lg:active:bg-pink-300'
  | 'lg:active:bg-pink-400'
  | 'lg:active:bg-pink-500'
  | 'lg:active:bg-pink-600'
  | 'lg:active:bg-pink-700'
  | 'lg:active:bg-pink-800'
  | 'lg:active:bg-pink-900'
  | 'lg:bg-bottom'
  | 'lg:bg-center'
  | 'lg:bg-left'
  | 'lg:bg-left-bottom'
  | 'lg:bg-left-top'
  | 'lg:bg-right'
  | 'lg:bg-right-bottom'
  | 'lg:bg-right-top'
  | 'lg:bg-top'
  | 'lg:bg-repeat'
  | 'lg:bg-no-repeat'
  | 'lg:bg-repeat-x'
  | 'lg:bg-repeat-y'
  | 'lg:bg-repeat-round'
  | 'lg:bg-repeat-space'
  | 'lg:bg-auto'
  | 'lg:bg-cover'
  | 'lg:bg-contain'
  | 'lg:border-collapse'
  | 'lg:border-separate'
  | 'lg:border-transparent'
  | 'lg:border-white'
  | 'lg:border-black'
  | 'lg:border-gray-50'
  | 'lg:border-gray-100'
  | 'lg:border-gray-200'
  | 'lg:border-gray-300'
  | 'lg:border-gray-400'
  | 'lg:border-gray-500'
  | 'lg:border-gray-600'
  | 'lg:border-gray-700'
  | 'lg:border-gray-800'
  | 'lg:border-gray-900'
  | 'lg:border-cool-gray-50'
  | 'lg:border-cool-gray-100'
  | 'lg:border-cool-gray-200'
  | 'lg:border-cool-gray-300'
  | 'lg:border-cool-gray-400'
  | 'lg:border-cool-gray-500'
  | 'lg:border-cool-gray-600'
  | 'lg:border-cool-gray-700'
  | 'lg:border-cool-gray-800'
  | 'lg:border-cool-gray-900'
  | 'lg:border-red-50'
  | 'lg:border-red-100'
  | 'lg:border-red-200'
  | 'lg:border-red-300'
  | 'lg:border-red-400'
  | 'lg:border-red-500'
  | 'lg:border-red-600'
  | 'lg:border-red-700'
  | 'lg:border-red-800'
  | 'lg:border-red-900'
  | 'lg:border-orange-50'
  | 'lg:border-orange-100'
  | 'lg:border-orange-200'
  | 'lg:border-orange-300'
  | 'lg:border-orange-400'
  | 'lg:border-orange-500'
  | 'lg:border-orange-600'
  | 'lg:border-orange-700'
  | 'lg:border-orange-800'
  | 'lg:border-orange-900'
  | 'lg:border-yellow-50'
  | 'lg:border-yellow-100'
  | 'lg:border-yellow-200'
  | 'lg:border-yellow-300'
  | 'lg:border-yellow-400'
  | 'lg:border-yellow-500'
  | 'lg:border-yellow-600'
  | 'lg:border-yellow-700'
  | 'lg:border-yellow-800'
  | 'lg:border-yellow-900'
  | 'lg:border-green-50'
  | 'lg:border-green-100'
  | 'lg:border-green-200'
  | 'lg:border-green-300'
  | 'lg:border-green-400'
  | 'lg:border-green-500'
  | 'lg:border-green-600'
  | 'lg:border-green-700'
  | 'lg:border-green-800'
  | 'lg:border-green-900'
  | 'lg:border-teal-50'
  | 'lg:border-teal-100'
  | 'lg:border-teal-200'
  | 'lg:border-teal-300'
  | 'lg:border-teal-400'
  | 'lg:border-teal-500'
  | 'lg:border-teal-600'
  | 'lg:border-teal-700'
  | 'lg:border-teal-800'
  | 'lg:border-teal-900'
  | 'lg:border-blue-50'
  | 'lg:border-blue-100'
  | 'lg:border-blue-200'
  | 'lg:border-blue-300'
  | 'lg:border-blue-400'
  | 'lg:border-blue-500'
  | 'lg:border-blue-600'
  | 'lg:border-blue-700'
  | 'lg:border-blue-800'
  | 'lg:border-blue-900'
  | 'lg:border-indigo-50'
  | 'lg:border-indigo-100'
  | 'lg:border-indigo-200'
  | 'lg:border-indigo-300'
  | 'lg:border-indigo-400'
  | 'lg:border-indigo-500'
  | 'lg:border-indigo-600'
  | 'lg:border-indigo-700'
  | 'lg:border-indigo-800'
  | 'lg:border-indigo-900'
  | 'lg:border-purple-50'
  | 'lg:border-purple-100'
  | 'lg:border-purple-200'
  | 'lg:border-purple-300'
  | 'lg:border-purple-400'
  | 'lg:border-purple-500'
  | 'lg:border-purple-600'
  | 'lg:border-purple-700'
  | 'lg:border-purple-800'
  | 'lg:border-purple-900'
  | 'lg:border-pink-50'
  | 'lg:border-pink-100'
  | 'lg:border-pink-200'
  | 'lg:border-pink-300'
  | 'lg:border-pink-400'
  | 'lg:border-pink-500'
  | 'lg:border-pink-600'
  | 'lg:border-pink-700'
  | 'lg:border-pink-800'
  | 'lg:border-pink-900'
  | 'lg:group-hover:border-transparent'
  | 'lg:group-hover:border-white'
  | 'lg:group-hover:border-black'
  | 'lg:group-hover:border-gray-50'
  | 'lg:group-hover:border-gray-100'
  | 'lg:group-hover:border-gray-200'
  | 'lg:group-hover:border-gray-300'
  | 'lg:group-hover:border-gray-400'
  | 'lg:group-hover:border-gray-500'
  | 'lg:group-hover:border-gray-600'
  | 'lg:group-hover:border-gray-700'
  | 'lg:group-hover:border-gray-800'
  | 'lg:group-hover:border-gray-900'
  | 'lg:group-hover:border-cool-gray-50'
  | 'lg:group-hover:border-cool-gray-100'
  | 'lg:group-hover:border-cool-gray-200'
  | 'lg:group-hover:border-cool-gray-300'
  | 'lg:group-hover:border-cool-gray-400'
  | 'lg:group-hover:border-cool-gray-500'
  | 'lg:group-hover:border-cool-gray-600'
  | 'lg:group-hover:border-cool-gray-700'
  | 'lg:group-hover:border-cool-gray-800'
  | 'lg:group-hover:border-cool-gray-900'
  | 'lg:group-hover:border-red-50'
  | 'lg:group-hover:border-red-100'
  | 'lg:group-hover:border-red-200'
  | 'lg:group-hover:border-red-300'
  | 'lg:group-hover:border-red-400'
  | 'lg:group-hover:border-red-500'
  | 'lg:group-hover:border-red-600'
  | 'lg:group-hover:border-red-700'
  | 'lg:group-hover:border-red-800'
  | 'lg:group-hover:border-red-900'
  | 'lg:group-hover:border-orange-50'
  | 'lg:group-hover:border-orange-100'
  | 'lg:group-hover:border-orange-200'
  | 'lg:group-hover:border-orange-300'
  | 'lg:group-hover:border-orange-400'
  | 'lg:group-hover:border-orange-500'
  | 'lg:group-hover:border-orange-600'
  | 'lg:group-hover:border-orange-700'
  | 'lg:group-hover:border-orange-800'
  | 'lg:group-hover:border-orange-900'
  | 'lg:group-hover:border-yellow-50'
  | 'lg:group-hover:border-yellow-100'
  | 'lg:group-hover:border-yellow-200'
  | 'lg:group-hover:border-yellow-300'
  | 'lg:group-hover:border-yellow-400'
  | 'lg:group-hover:border-yellow-500'
  | 'lg:group-hover:border-yellow-600'
  | 'lg:group-hover:border-yellow-700'
  | 'lg:group-hover:border-yellow-800'
  | 'lg:group-hover:border-yellow-900'
  | 'lg:group-hover:border-green-50'
  | 'lg:group-hover:border-green-100'
  | 'lg:group-hover:border-green-200'
  | 'lg:group-hover:border-green-300'
  | 'lg:group-hover:border-green-400'
  | 'lg:group-hover:border-green-500'
  | 'lg:group-hover:border-green-600'
  | 'lg:group-hover:border-green-700'
  | 'lg:group-hover:border-green-800'
  | 'lg:group-hover:border-green-900'
  | 'lg:group-hover:border-teal-50'
  | 'lg:group-hover:border-teal-100'
  | 'lg:group-hover:border-teal-200'
  | 'lg:group-hover:border-teal-300'
  | 'lg:group-hover:border-teal-400'
  | 'lg:group-hover:border-teal-500'
  | 'lg:group-hover:border-teal-600'
  | 'lg:group-hover:border-teal-700'
  | 'lg:group-hover:border-teal-800'
  | 'lg:group-hover:border-teal-900'
  | 'lg:group-hover:border-blue-50'
  | 'lg:group-hover:border-blue-100'
  | 'lg:group-hover:border-blue-200'
  | 'lg:group-hover:border-blue-300'
  | 'lg:group-hover:border-blue-400'
  | 'lg:group-hover:border-blue-500'
  | 'lg:group-hover:border-blue-600'
  | 'lg:group-hover:border-blue-700'
  | 'lg:group-hover:border-blue-800'
  | 'lg:group-hover:border-blue-900'
  | 'lg:group-hover:border-indigo-50'
  | 'lg:group-hover:border-indigo-100'
  | 'lg:group-hover:border-indigo-200'
  | 'lg:group-hover:border-indigo-300'
  | 'lg:group-hover:border-indigo-400'
  | 'lg:group-hover:border-indigo-500'
  | 'lg:group-hover:border-indigo-600'
  | 'lg:group-hover:border-indigo-700'
  | 'lg:group-hover:border-indigo-800'
  | 'lg:group-hover:border-indigo-900'
  | 'lg:group-hover:border-purple-50'
  | 'lg:group-hover:border-purple-100'
  | 'lg:group-hover:border-purple-200'
  | 'lg:group-hover:border-purple-300'
  | 'lg:group-hover:border-purple-400'
  | 'lg:group-hover:border-purple-500'
  | 'lg:group-hover:border-purple-600'
  | 'lg:group-hover:border-purple-700'
  | 'lg:group-hover:border-purple-800'
  | 'lg:group-hover:border-purple-900'
  | 'lg:group-hover:border-pink-50'
  | 'lg:group-hover:border-pink-100'
  | 'lg:group-hover:border-pink-200'
  | 'lg:group-hover:border-pink-300'
  | 'lg:group-hover:border-pink-400'
  | 'lg:group-hover:border-pink-500'
  | 'lg:group-hover:border-pink-600'
  | 'lg:group-hover:border-pink-700'
  | 'lg:group-hover:border-pink-800'
  | 'lg:group-hover:border-pink-900'
  | 'lg:group-focus:border-transparent'
  | 'lg:group-focus:border-white'
  | 'lg:group-focus:border-black'
  | 'lg:group-focus:border-gray-50'
  | 'lg:group-focus:border-gray-100'
  | 'lg:group-focus:border-gray-200'
  | 'lg:group-focus:border-gray-300'
  | 'lg:group-focus:border-gray-400'
  | 'lg:group-focus:border-gray-500'
  | 'lg:group-focus:border-gray-600'
  | 'lg:group-focus:border-gray-700'
  | 'lg:group-focus:border-gray-800'
  | 'lg:group-focus:border-gray-900'
  | 'lg:group-focus:border-cool-gray-50'
  | 'lg:group-focus:border-cool-gray-100'
  | 'lg:group-focus:border-cool-gray-200'
  | 'lg:group-focus:border-cool-gray-300'
  | 'lg:group-focus:border-cool-gray-400'
  | 'lg:group-focus:border-cool-gray-500'
  | 'lg:group-focus:border-cool-gray-600'
  | 'lg:group-focus:border-cool-gray-700'
  | 'lg:group-focus:border-cool-gray-800'
  | 'lg:group-focus:border-cool-gray-900'
  | 'lg:group-focus:border-red-50'
  | 'lg:group-focus:border-red-100'
  | 'lg:group-focus:border-red-200'
  | 'lg:group-focus:border-red-300'
  | 'lg:group-focus:border-red-400'
  | 'lg:group-focus:border-red-500'
  | 'lg:group-focus:border-red-600'
  | 'lg:group-focus:border-red-700'
  | 'lg:group-focus:border-red-800'
  | 'lg:group-focus:border-red-900'
  | 'lg:group-focus:border-orange-50'
  | 'lg:group-focus:border-orange-100'
  | 'lg:group-focus:border-orange-200'
  | 'lg:group-focus:border-orange-300'
  | 'lg:group-focus:border-orange-400'
  | 'lg:group-focus:border-orange-500'
  | 'lg:group-focus:border-orange-600'
  | 'lg:group-focus:border-orange-700'
  | 'lg:group-focus:border-orange-800'
  | 'lg:group-focus:border-orange-900'
  | 'lg:group-focus:border-yellow-50'
  | 'lg:group-focus:border-yellow-100'
  | 'lg:group-focus:border-yellow-200'
  | 'lg:group-focus:border-yellow-300'
  | 'lg:group-focus:border-yellow-400'
  | 'lg:group-focus:border-yellow-500'
  | 'lg:group-focus:border-yellow-600'
  | 'lg:group-focus:border-yellow-700'
  | 'lg:group-focus:border-yellow-800'
  | 'lg:group-focus:border-yellow-900'
  | 'lg:group-focus:border-green-50'
  | 'lg:group-focus:border-green-100'
  | 'lg:group-focus:border-green-200'
  | 'lg:group-focus:border-green-300'
  | 'lg:group-focus:border-green-400'
  | 'lg:group-focus:border-green-500'
  | 'lg:group-focus:border-green-600'
  | 'lg:group-focus:border-green-700'
  | 'lg:group-focus:border-green-800'
  | 'lg:group-focus:border-green-900'
  | 'lg:group-focus:border-teal-50'
  | 'lg:group-focus:border-teal-100'
  | 'lg:group-focus:border-teal-200'
  | 'lg:group-focus:border-teal-300'
  | 'lg:group-focus:border-teal-400'
  | 'lg:group-focus:border-teal-500'
  | 'lg:group-focus:border-teal-600'
  | 'lg:group-focus:border-teal-700'
  | 'lg:group-focus:border-teal-800'
  | 'lg:group-focus:border-teal-900'
  | 'lg:group-focus:border-blue-50'
  | 'lg:group-focus:border-blue-100'
  | 'lg:group-focus:border-blue-200'
  | 'lg:group-focus:border-blue-300'
  | 'lg:group-focus:border-blue-400'
  | 'lg:group-focus:border-blue-500'
  | 'lg:group-focus:border-blue-600'
  | 'lg:group-focus:border-blue-700'
  | 'lg:group-focus:border-blue-800'
  | 'lg:group-focus:border-blue-900'
  | 'lg:group-focus:border-indigo-50'
  | 'lg:group-focus:border-indigo-100'
  | 'lg:group-focus:border-indigo-200'
  | 'lg:group-focus:border-indigo-300'
  | 'lg:group-focus:border-indigo-400'
  | 'lg:group-focus:border-indigo-500'
  | 'lg:group-focus:border-indigo-600'
  | 'lg:group-focus:border-indigo-700'
  | 'lg:group-focus:border-indigo-800'
  | 'lg:group-focus:border-indigo-900'
  | 'lg:group-focus:border-purple-50'
  | 'lg:group-focus:border-purple-100'
  | 'lg:group-focus:border-purple-200'
  | 'lg:group-focus:border-purple-300'
  | 'lg:group-focus:border-purple-400'
  | 'lg:group-focus:border-purple-500'
  | 'lg:group-focus:border-purple-600'
  | 'lg:group-focus:border-purple-700'
  | 'lg:group-focus:border-purple-800'
  | 'lg:group-focus:border-purple-900'
  | 'lg:group-focus:border-pink-50'
  | 'lg:group-focus:border-pink-100'
  | 'lg:group-focus:border-pink-200'
  | 'lg:group-focus:border-pink-300'
  | 'lg:group-focus:border-pink-400'
  | 'lg:group-focus:border-pink-500'
  | 'lg:group-focus:border-pink-600'
  | 'lg:group-focus:border-pink-700'
  | 'lg:group-focus:border-pink-800'
  | 'lg:group-focus:border-pink-900'
  | 'lg:hover:border-transparent'
  | 'lg:hover:border-white'
  | 'lg:hover:border-black'
  | 'lg:hover:border-gray-50'
  | 'lg:hover:border-gray-100'
  | 'lg:hover:border-gray-200'
  | 'lg:hover:border-gray-300'
  | 'lg:hover:border-gray-400'
  | 'lg:hover:border-gray-500'
  | 'lg:hover:border-gray-600'
  | 'lg:hover:border-gray-700'
  | 'lg:hover:border-gray-800'
  | 'lg:hover:border-gray-900'
  | 'lg:hover:border-cool-gray-50'
  | 'lg:hover:border-cool-gray-100'
  | 'lg:hover:border-cool-gray-200'
  | 'lg:hover:border-cool-gray-300'
  | 'lg:hover:border-cool-gray-400'
  | 'lg:hover:border-cool-gray-500'
  | 'lg:hover:border-cool-gray-600'
  | 'lg:hover:border-cool-gray-700'
  | 'lg:hover:border-cool-gray-800'
  | 'lg:hover:border-cool-gray-900'
  | 'lg:hover:border-red-50'
  | 'lg:hover:border-red-100'
  | 'lg:hover:border-red-200'
  | 'lg:hover:border-red-300'
  | 'lg:hover:border-red-400'
  | 'lg:hover:border-red-500'
  | 'lg:hover:border-red-600'
  | 'lg:hover:border-red-700'
  | 'lg:hover:border-red-800'
  | 'lg:hover:border-red-900'
  | 'lg:hover:border-orange-50'
  | 'lg:hover:border-orange-100'
  | 'lg:hover:border-orange-200'
  | 'lg:hover:border-orange-300'
  | 'lg:hover:border-orange-400'
  | 'lg:hover:border-orange-500'
  | 'lg:hover:border-orange-600'
  | 'lg:hover:border-orange-700'
  | 'lg:hover:border-orange-800'
  | 'lg:hover:border-orange-900'
  | 'lg:hover:border-yellow-50'
  | 'lg:hover:border-yellow-100'
  | 'lg:hover:border-yellow-200'
  | 'lg:hover:border-yellow-300'
  | 'lg:hover:border-yellow-400'
  | 'lg:hover:border-yellow-500'
  | 'lg:hover:border-yellow-600'
  | 'lg:hover:border-yellow-700'
  | 'lg:hover:border-yellow-800'
  | 'lg:hover:border-yellow-900'
  | 'lg:hover:border-green-50'
  | 'lg:hover:border-green-100'
  | 'lg:hover:border-green-200'
  | 'lg:hover:border-green-300'
  | 'lg:hover:border-green-400'
  | 'lg:hover:border-green-500'
  | 'lg:hover:border-green-600'
  | 'lg:hover:border-green-700'
  | 'lg:hover:border-green-800'
  | 'lg:hover:border-green-900'
  | 'lg:hover:border-teal-50'
  | 'lg:hover:border-teal-100'
  | 'lg:hover:border-teal-200'
  | 'lg:hover:border-teal-300'
  | 'lg:hover:border-teal-400'
  | 'lg:hover:border-teal-500'
  | 'lg:hover:border-teal-600'
  | 'lg:hover:border-teal-700'
  | 'lg:hover:border-teal-800'
  | 'lg:hover:border-teal-900'
  | 'lg:hover:border-blue-50'
  | 'lg:hover:border-blue-100'
  | 'lg:hover:border-blue-200'
  | 'lg:hover:border-blue-300'
  | 'lg:hover:border-blue-400'
  | 'lg:hover:border-blue-500'
  | 'lg:hover:border-blue-600'
  | 'lg:hover:border-blue-700'
  | 'lg:hover:border-blue-800'
  | 'lg:hover:border-blue-900'
  | 'lg:hover:border-indigo-50'
  | 'lg:hover:border-indigo-100'
  | 'lg:hover:border-indigo-200'
  | 'lg:hover:border-indigo-300'
  | 'lg:hover:border-indigo-400'
  | 'lg:hover:border-indigo-500'
  | 'lg:hover:border-indigo-600'
  | 'lg:hover:border-indigo-700'
  | 'lg:hover:border-indigo-800'
  | 'lg:hover:border-indigo-900'
  | 'lg:hover:border-purple-50'
  | 'lg:hover:border-purple-100'
  | 'lg:hover:border-purple-200'
  | 'lg:hover:border-purple-300'
  | 'lg:hover:border-purple-400'
  | 'lg:hover:border-purple-500'
  | 'lg:hover:border-purple-600'
  | 'lg:hover:border-purple-700'
  | 'lg:hover:border-purple-800'
  | 'lg:hover:border-purple-900'
  | 'lg:hover:border-pink-50'
  | 'lg:hover:border-pink-100'
  | 'lg:hover:border-pink-200'
  | 'lg:hover:border-pink-300'
  | 'lg:hover:border-pink-400'
  | 'lg:hover:border-pink-500'
  | 'lg:hover:border-pink-600'
  | 'lg:hover:border-pink-700'
  | 'lg:hover:border-pink-800'
  | 'lg:hover:border-pink-900'
  | 'lg:focus:border-transparent'
  | 'lg:focus:border-white'
  | 'lg:focus:border-black'
  | 'lg:focus:border-gray-50'
  | 'lg:focus:border-gray-100'
  | 'lg:focus:border-gray-200'
  | 'lg:focus:border-gray-300'
  | 'lg:focus:border-gray-400'
  | 'lg:focus:border-gray-500'
  | 'lg:focus:border-gray-600'
  | 'lg:focus:border-gray-700'
  | 'lg:focus:border-gray-800'
  | 'lg:focus:border-gray-900'
  | 'lg:focus:border-cool-gray-50'
  | 'lg:focus:border-cool-gray-100'
  | 'lg:focus:border-cool-gray-200'
  | 'lg:focus:border-cool-gray-300'
  | 'lg:focus:border-cool-gray-400'
  | 'lg:focus:border-cool-gray-500'
  | 'lg:focus:border-cool-gray-600'
  | 'lg:focus:border-cool-gray-700'
  | 'lg:focus:border-cool-gray-800'
  | 'lg:focus:border-cool-gray-900'
  | 'lg:focus:border-red-50'
  | 'lg:focus:border-red-100'
  | 'lg:focus:border-red-200'
  | 'lg:focus:border-red-300'
  | 'lg:focus:border-red-400'
  | 'lg:focus:border-red-500'
  | 'lg:focus:border-red-600'
  | 'lg:focus:border-red-700'
  | 'lg:focus:border-red-800'
  | 'lg:focus:border-red-900'
  | 'lg:focus:border-orange-50'
  | 'lg:focus:border-orange-100'
  | 'lg:focus:border-orange-200'
  | 'lg:focus:border-orange-300'
  | 'lg:focus:border-orange-400'
  | 'lg:focus:border-orange-500'
  | 'lg:focus:border-orange-600'
  | 'lg:focus:border-orange-700'
  | 'lg:focus:border-orange-800'
  | 'lg:focus:border-orange-900'
  | 'lg:focus:border-yellow-50'
  | 'lg:focus:border-yellow-100'
  | 'lg:focus:border-yellow-200'
  | 'lg:focus:border-yellow-300'
  | 'lg:focus:border-yellow-400'
  | 'lg:focus:border-yellow-500'
  | 'lg:focus:border-yellow-600'
  | 'lg:focus:border-yellow-700'
  | 'lg:focus:border-yellow-800'
  | 'lg:focus:border-yellow-900'
  | 'lg:focus:border-green-50'
  | 'lg:focus:border-green-100'
  | 'lg:focus:border-green-200'
  | 'lg:focus:border-green-300'
  | 'lg:focus:border-green-400'
  | 'lg:focus:border-green-500'
  | 'lg:focus:border-green-600'
  | 'lg:focus:border-green-700'
  | 'lg:focus:border-green-800'
  | 'lg:focus:border-green-900'
  | 'lg:focus:border-teal-50'
  | 'lg:focus:border-teal-100'
  | 'lg:focus:border-teal-200'
  | 'lg:focus:border-teal-300'
  | 'lg:focus:border-teal-400'
  | 'lg:focus:border-teal-500'
  | 'lg:focus:border-teal-600'
  | 'lg:focus:border-teal-700'
  | 'lg:focus:border-teal-800'
  | 'lg:focus:border-teal-900'
  | 'lg:focus:border-blue-50'
  | 'lg:focus:border-blue-100'
  | 'lg:focus:border-blue-200'
  | 'lg:focus:border-blue-300'
  | 'lg:focus:border-blue-400'
  | 'lg:focus:border-blue-500'
  | 'lg:focus:border-blue-600'
  | 'lg:focus:border-blue-700'
  | 'lg:focus:border-blue-800'
  | 'lg:focus:border-blue-900'
  | 'lg:focus:border-indigo-50'
  | 'lg:focus:border-indigo-100'
  | 'lg:focus:border-indigo-200'
  | 'lg:focus:border-indigo-300'
  | 'lg:focus:border-indigo-400'
  | 'lg:focus:border-indigo-500'
  | 'lg:focus:border-indigo-600'
  | 'lg:focus:border-indigo-700'
  | 'lg:focus:border-indigo-800'
  | 'lg:focus:border-indigo-900'
  | 'lg:focus:border-purple-50'
  | 'lg:focus:border-purple-100'
  | 'lg:focus:border-purple-200'
  | 'lg:focus:border-purple-300'
  | 'lg:focus:border-purple-400'
  | 'lg:focus:border-purple-500'
  | 'lg:focus:border-purple-600'
  | 'lg:focus:border-purple-700'
  | 'lg:focus:border-purple-800'
  | 'lg:focus:border-purple-900'
  | 'lg:focus:border-pink-50'
  | 'lg:focus:border-pink-100'
  | 'lg:focus:border-pink-200'
  | 'lg:focus:border-pink-300'
  | 'lg:focus:border-pink-400'
  | 'lg:focus:border-pink-500'
  | 'lg:focus:border-pink-600'
  | 'lg:focus:border-pink-700'
  | 'lg:focus:border-pink-800'
  | 'lg:focus:border-pink-900'
  | 'lg:rounded-none'
  | 'lg:rounded-sm'
  | 'lg:rounded'
  | 'lg:rounded-md'
  | 'lg:rounded-lg'
  | 'lg:rounded-full'
  | 'lg:rounded-t-none'
  | 'lg:rounded-r-none'
  | 'lg:rounded-b-none'
  | 'lg:rounded-l-none'
  | 'lg:rounded-t-sm'
  | 'lg:rounded-r-sm'
  | 'lg:rounded-b-sm'
  | 'lg:rounded-l-sm'
  | 'lg:rounded-t'
  | 'lg:rounded-r'
  | 'lg:rounded-b'
  | 'lg:rounded-l'
  | 'lg:rounded-t-md'
  | 'lg:rounded-r-md'
  | 'lg:rounded-b-md'
  | 'lg:rounded-l-md'
  | 'lg:rounded-t-lg'
  | 'lg:rounded-r-lg'
  | 'lg:rounded-b-lg'
  | 'lg:rounded-l-lg'
  | 'lg:rounded-t-full'
  | 'lg:rounded-r-full'
  | 'lg:rounded-b-full'
  | 'lg:rounded-l-full'
  | 'lg:rounded-tl-none'
  | 'lg:rounded-tr-none'
  | 'lg:rounded-br-none'
  | 'lg:rounded-bl-none'
  | 'lg:rounded-tl-sm'
  | 'lg:rounded-tr-sm'
  | 'lg:rounded-br-sm'
  | 'lg:rounded-bl-sm'
  | 'lg:rounded-tl'
  | 'lg:rounded-tr'
  | 'lg:rounded-br'
  | 'lg:rounded-bl'
  | 'lg:rounded-tl-md'
  | 'lg:rounded-tr-md'
  | 'lg:rounded-br-md'
  | 'lg:rounded-bl-md'
  | 'lg:rounded-tl-lg'
  | 'lg:rounded-tr-lg'
  | 'lg:rounded-br-lg'
  | 'lg:rounded-bl-lg'
  | 'lg:rounded-tl-full'
  | 'lg:rounded-tr-full'
  | 'lg:rounded-br-full'
  | 'lg:rounded-bl-full'
  | 'lg:border-solid'
  | 'lg:border-dashed'
  | 'lg:border-dotted'
  | 'lg:border-double'
  | 'lg:border-none'
  | 'lg:border-0'
  | 'lg:border-2'
  | 'lg:border-4'
  | 'lg:border-8'
  | 'lg:border'
  | 'lg:border-t-0'
  | 'lg:border-r-0'
  | 'lg:border-b-0'
  | 'lg:border-l-0'
  | 'lg:border-t-2'
  | 'lg:border-r-2'
  | 'lg:border-b-2'
  | 'lg:border-l-2'
  | 'lg:border-t-4'
  | 'lg:border-r-4'
  | 'lg:border-b-4'
  | 'lg:border-l-4'
  | 'lg:border-t-8'
  | 'lg:border-r-8'
  | 'lg:border-b-8'
  | 'lg:border-l-8'
  | 'lg:border-t'
  | 'lg:border-r'
  | 'lg:border-b'
  | 'lg:border-l'
  | 'lg:box-border'
  | 'lg:box-content'
  | 'lg:cursor-auto'
  | 'lg:cursor-default'
  | 'lg:cursor-pointer'
  | 'lg:cursor-wait'
  | 'lg:cursor-text'
  | 'lg:cursor-move'
  | 'lg:cursor-not-allowed'
  | 'lg:block'
  | 'lg:inline-block'
  | 'lg:inline'
  | 'lg:flex'
  | 'lg:inline-flex'
  | 'lg:grid'
  | 'lg:table'
  | 'lg:table-caption'
  | 'lg:table-cell'
  | 'lg:table-column'
  | 'lg:table-column-group'
  | 'lg:table-footer-group'
  | 'lg:table-header-group'
  | 'lg:table-row-group'
  | 'lg:table-row'
  | 'lg:hidden'
  | 'lg:flex-row'
  | 'lg:flex-row-reverse'
  | 'lg:flex-col'
  | 'lg:flex-col-reverse'
  | 'lg:flex-wrap'
  | 'lg:flex-wrap-reverse'
  | 'lg:flex-no-wrap'
  | 'lg:items-start'
  | 'lg:items-end'
  | 'lg:items-center'
  | 'lg:items-baseline'
  | 'lg:items-stretch'
  | 'lg:self-auto'
  | 'lg:self-start'
  | 'lg:self-end'
  | 'lg:self-center'
  | 'lg:self-stretch'
  | 'lg:justify-start'
  | 'lg:justify-end'
  | 'lg:justify-center'
  | 'lg:justify-between'
  | 'lg:justify-around'
  | 'lg:justify-evenly'
  | 'lg:content-center'
  | 'lg:content-start'
  | 'lg:content-end'
  | 'lg:content-between'
  | 'lg:content-around'
  | 'lg:flex-1'
  | 'lg:flex-auto'
  | 'lg:flex-initial'
  | 'lg:flex-none'
  | 'lg:flex-grow-0'
  | 'lg:flex-grow'
  | 'lg:flex-shrink-0'
  | 'lg:flex-shrink'
  | 'lg:order-1'
  | 'lg:order-2'
  | 'lg:order-3'
  | 'lg:order-4'
  | 'lg:order-5'
  | 'lg:order-6'
  | 'lg:order-7'
  | 'lg:order-8'
  | 'lg:order-9'
  | 'lg:order-10'
  | 'lg:order-11'
  | 'lg:order-12'
  | 'lg:order-first'
  | 'lg:order-last'
  | 'lg:order-none'
  | 'lg:float-right'
  | 'lg:float-left'
  | 'lg:float-none'
  | 'lg:clearfix'
  | 'lg:clear-left'
  | 'lg:clear-right'
  | 'lg:clear-both'
  | 'lg:font-sans'
  | 'lg:font-serif'
  | 'lg:font-mono'
  | 'lg:font-hairline'
  | 'lg:font-thin'
  | 'lg:font-light'
  | 'lg:font-normal'
  | 'lg:font-medium'
  | 'lg:font-semibold'
  | 'lg:font-bold'
  | 'lg:font-extrabold'
  | 'lg:font-black'
  | 'lg:hover:font-hairline'
  | 'lg:hover:font-thin'
  | 'lg:hover:font-light'
  | 'lg:hover:font-normal'
  | 'lg:hover:font-medium'
  | 'lg:hover:font-semibold'
  | 'lg:hover:font-bold'
  | 'lg:hover:font-extrabold'
  | 'lg:hover:font-black'
  | 'lg:focus:font-hairline'
  | 'lg:focus:font-thin'
  | 'lg:focus:font-light'
  | 'lg:focus:font-normal'
  | 'lg:focus:font-medium'
  | 'lg:focus:font-semibold'
  | 'lg:focus:font-bold'
  | 'lg:focus:font-extrabold'
  | 'lg:focus:font-black'
  | 'lg:h-0'
  | 'lg:h-1'
  | 'lg:h-2'
  | 'lg:h-3'
  | 'lg:h-4'
  | 'lg:h-5'
  | 'lg:h-6'
  | 'lg:h-7'
  | 'lg:h-8'
  | 'lg:h-9'
  | 'lg:h-10'
  | 'lg:h-11'
  | 'lg:h-12'
  | 'lg:h-13'
  | 'lg:h-14'
  | 'lg:h-15'
  | 'lg:h-16'
  | 'lg:h-20'
  | 'lg:h-24'
  | 'lg:h-28'
  | 'lg:h-32'
  | 'lg:h-36'
  | 'lg:h-40'
  | 'lg:h-48'
  | 'lg:h-56'
  | 'lg:h-60'
  | 'lg:h-64'
  | 'lg:h-72'
  | 'lg:h-80'
  | 'lg:h-96'
  | 'lg:h-auto'
  | 'lg:h-px'
  | 'lg:h-0.5'
  | 'lg:h-1.5'
  | 'lg:h-2.5'
  | 'lg:h-3.5'
  | 'lg:h-1/2'
  | 'lg:h-1/3'
  | 'lg:h-2/3'
  | 'lg:h-1/4'
  | 'lg:h-2/4'
  | 'lg:h-3/4'
  | 'lg:h-1/5'
  | 'lg:h-2/5'
  | 'lg:h-3/5'
  | 'lg:h-4/5'
  | 'lg:h-1/6'
  | 'lg:h-2/6'
  | 'lg:h-3/6'
  | 'lg:h-4/6'
  | 'lg:h-5/6'
  | 'lg:h-1/12'
  | 'lg:h-2/12'
  | 'lg:h-3/12'
  | 'lg:h-4/12'
  | 'lg:h-5/12'
  | 'lg:h-6/12'
  | 'lg:h-7/12'
  | 'lg:h-8/12'
  | 'lg:h-9/12'
  | 'lg:h-10/12'
  | 'lg:h-11/12'
  | 'lg:h-full'
  | 'lg:h-screen'
  | 'lg:leading-3'
  | 'lg:leading-4'
  | 'lg:leading-5'
  | 'lg:leading-6'
  | 'lg:leading-7'
  | 'lg:leading-8'
  | 'lg:leading-9'
  | 'lg:leading-10'
  | 'lg:leading-none'
  | 'lg:leading-tight'
  | 'lg:leading-snug'
  | 'lg:leading-normal'
  | 'lg:leading-relaxed'
  | 'lg:leading-loose'
  | 'lg:list-inside'
  | 'lg:list-outside'
  | 'lg:list-none'
  | 'lg:list-disc'
  | 'lg:list-decimal'
  | 'lg:m-0'
  | 'lg:m-1'
  | 'lg:m-2'
  | 'lg:m-3'
  | 'lg:m-4'
  | 'lg:m-5'
  | 'lg:m-6'
  | 'lg:m-7'
  | 'lg:m-8'
  | 'lg:m-9'
  | 'lg:m-10'
  | 'lg:m-11'
  | 'lg:m-12'
  | 'lg:m-13'
  | 'lg:m-14'
  | 'lg:m-15'
  | 'lg:m-16'
  | 'lg:m-20'
  | 'lg:m-24'
  | 'lg:m-28'
  | 'lg:m-32'
  | 'lg:m-36'
  | 'lg:m-40'
  | 'lg:m-48'
  | 'lg:m-56'
  | 'lg:m-60'
  | 'lg:m-64'
  | 'lg:m-72'
  | 'lg:m-80'
  | 'lg:m-96'
  | 'lg:m-auto'
  | 'lg:m-px'
  | 'lg:m-0.5'
  | 'lg:m-1.5'
  | 'lg:m-2.5'
  | 'lg:m-3.5'
  | 'lg:m-1/2'
  | 'lg:m-1/3'
  | 'lg:m-2/3'
  | 'lg:m-1/4'
  | 'lg:m-2/4'
  | 'lg:m-3/4'
  | 'lg:m-1/5'
  | 'lg:m-2/5'
  | 'lg:m-3/5'
  | 'lg:m-4/5'
  | 'lg:m-1/6'
  | 'lg:m-2/6'
  | 'lg:m-3/6'
  | 'lg:m-4/6'
  | 'lg:m-5/6'
  | 'lg:m-1/12'
  | 'lg:m-2/12'
  | 'lg:m-3/12'
  | 'lg:m-4/12'
  | 'lg:m-5/12'
  | 'lg:m-6/12'
  | 'lg:m-7/12'
  | 'lg:m-8/12'
  | 'lg:m-9/12'
  | 'lg:m-10/12'
  | 'lg:m-11/12'
  | 'lg:m-full'
  | 'lg:-m-1'
  | 'lg:-m-2'
  | 'lg:-m-3'
  | 'lg:-m-4'
  | 'lg:-m-5'
  | 'lg:-m-6'
  | 'lg:-m-7'
  | 'lg:-m-8'
  | 'lg:-m-9'
  | 'lg:-m-10'
  | 'lg:-m-11'
  | 'lg:-m-12'
  | 'lg:-m-13'
  | 'lg:-m-14'
  | 'lg:-m-15'
  | 'lg:-m-16'
  | 'lg:-m-20'
  | 'lg:-m-24'
  | 'lg:-m-28'
  | 'lg:-m-32'
  | 'lg:-m-36'
  | 'lg:-m-40'
  | 'lg:-m-48'
  | 'lg:-m-56'
  | 'lg:-m-60'
  | 'lg:-m-64'
  | 'lg:-m-72'
  | 'lg:-m-80'
  | 'lg:-m-96'
  | 'lg:-m-px'
  | 'lg:-m-0.5'
  | 'lg:-m-1.5'
  | 'lg:-m-2.5'
  | 'lg:-m-3.5'
  | 'lg:-m-1/2'
  | 'lg:-m-1/3'
  | 'lg:-m-2/3'
  | 'lg:-m-1/4'
  | 'lg:-m-2/4'
  | 'lg:-m-3/4'
  | 'lg:-m-1/5'
  | 'lg:-m-2/5'
  | 'lg:-m-3/5'
  | 'lg:-m-4/5'
  | 'lg:-m-1/6'
  | 'lg:-m-2/6'
  | 'lg:-m-3/6'
  | 'lg:-m-4/6'
  | 'lg:-m-5/6'
  | 'lg:-m-1/12'
  | 'lg:-m-2/12'
  | 'lg:-m-3/12'
  | 'lg:-m-4/12'
  | 'lg:-m-5/12'
  | 'lg:-m-6/12'
  | 'lg:-m-7/12'
  | 'lg:-m-8/12'
  | 'lg:-m-9/12'
  | 'lg:-m-10/12'
  | 'lg:-m-11/12'
  | 'lg:-m-full'
  | 'lg:my-0'
  | 'lg:mx-0'
  | 'lg:my-1'
  | 'lg:mx-1'
  | 'lg:my-2'
  | 'lg:mx-2'
  | 'lg:my-3'
  | 'lg:mx-3'
  | 'lg:my-4'
  | 'lg:mx-4'
  | 'lg:my-5'
  | 'lg:mx-5'
  | 'lg:my-6'
  | 'lg:mx-6'
  | 'lg:my-7'
  | 'lg:mx-7'
  | 'lg:my-8'
  | 'lg:mx-8'
  | 'lg:my-9'
  | 'lg:mx-9'
  | 'lg:my-10'
  | 'lg:mx-10'
  | 'lg:my-11'
  | 'lg:mx-11'
  | 'lg:my-12'
  | 'lg:mx-12'
  | 'lg:my-13'
  | 'lg:mx-13'
  | 'lg:my-14'
  | 'lg:mx-14'
  | 'lg:my-15'
  | 'lg:mx-15'
  | 'lg:my-16'
  | 'lg:mx-16'
  | 'lg:my-20'
  | 'lg:mx-20'
  | 'lg:my-24'
  | 'lg:mx-24'
  | 'lg:my-28'
  | 'lg:mx-28'
  | 'lg:my-32'
  | 'lg:mx-32'
  | 'lg:my-36'
  | 'lg:mx-36'
  | 'lg:my-40'
  | 'lg:mx-40'
  | 'lg:my-48'
  | 'lg:mx-48'
  | 'lg:my-56'
  | 'lg:mx-56'
  | 'lg:my-60'
  | 'lg:mx-60'
  | 'lg:my-64'
  | 'lg:mx-64'
  | 'lg:my-72'
  | 'lg:mx-72'
  | 'lg:my-80'
  | 'lg:mx-80'
  | 'lg:my-96'
  | 'lg:mx-96'
  | 'lg:my-auto'
  | 'lg:mx-auto'
  | 'lg:my-px'
  | 'lg:mx-px'
  | 'lg:my-0.5'
  | 'lg:mx-0.5'
  | 'lg:my-1.5'
  | 'lg:mx-1.5'
  | 'lg:my-2.5'
  | 'lg:mx-2.5'
  | 'lg:my-3.5'
  | 'lg:mx-3.5'
  | 'lg:my-1/2'
  | 'lg:mx-1/2'
  | 'lg:my-1/3'
  | 'lg:mx-1/3'
  | 'lg:my-2/3'
  | 'lg:mx-2/3'
  | 'lg:my-1/4'
  | 'lg:mx-1/4'
  | 'lg:my-2/4'
  | 'lg:mx-2/4'
  | 'lg:my-3/4'
  | 'lg:mx-3/4'
  | 'lg:my-1/5'
  | 'lg:mx-1/5'
  | 'lg:my-2/5'
  | 'lg:mx-2/5'
  | 'lg:my-3/5'
  | 'lg:mx-3/5'
  | 'lg:my-4/5'
  | 'lg:mx-4/5'
  | 'lg:my-1/6'
  | 'lg:mx-1/6'
  | 'lg:my-2/6'
  | 'lg:mx-2/6'
  | 'lg:my-3/6'
  | 'lg:mx-3/6'
  | 'lg:my-4/6'
  | 'lg:mx-4/6'
  | 'lg:my-5/6'
  | 'lg:mx-5/6'
  | 'lg:my-1/12'
  | 'lg:mx-1/12'
  | 'lg:my-2/12'
  | 'lg:mx-2/12'
  | 'lg:my-3/12'
  | 'lg:mx-3/12'
  | 'lg:my-4/12'
  | 'lg:mx-4/12'
  | 'lg:my-5/12'
  | 'lg:mx-5/12'
  | 'lg:my-6/12'
  | 'lg:mx-6/12'
  | 'lg:my-7/12'
  | 'lg:mx-7/12'
  | 'lg:my-8/12'
  | 'lg:mx-8/12'
  | 'lg:my-9/12'
  | 'lg:mx-9/12'
  | 'lg:my-10/12'
  | 'lg:mx-10/12'
  | 'lg:my-11/12'
  | 'lg:mx-11/12'
  | 'lg:my-full'
  | 'lg:mx-full'
  | 'lg:-my-1'
  | 'lg:-mx-1'
  | 'lg:-my-2'
  | 'lg:-mx-2'
  | 'lg:-my-3'
  | 'lg:-mx-3'
  | 'lg:-my-4'
  | 'lg:-mx-4'
  | 'lg:-my-5'
  | 'lg:-mx-5'
  | 'lg:-my-6'
  | 'lg:-mx-6'
  | 'lg:-my-7'
  | 'lg:-mx-7'
  | 'lg:-my-8'
  | 'lg:-mx-8'
  | 'lg:-my-9'
  | 'lg:-mx-9'
  | 'lg:-my-10'
  | 'lg:-mx-10'
  | 'lg:-my-11'
  | 'lg:-mx-11'
  | 'lg:-my-12'
  | 'lg:-mx-12'
  | 'lg:-my-13'
  | 'lg:-mx-13'
  | 'lg:-my-14'
  | 'lg:-mx-14'
  | 'lg:-my-15'
  | 'lg:-mx-15'
  | 'lg:-my-16'
  | 'lg:-mx-16'
  | 'lg:-my-20'
  | 'lg:-mx-20'
  | 'lg:-my-24'
  | 'lg:-mx-24'
  | 'lg:-my-28'
  | 'lg:-mx-28'
  | 'lg:-my-32'
  | 'lg:-mx-32'
  | 'lg:-my-36'
  | 'lg:-mx-36'
  | 'lg:-my-40'
  | 'lg:-mx-40'
  | 'lg:-my-48'
  | 'lg:-mx-48'
  | 'lg:-my-56'
  | 'lg:-mx-56'
  | 'lg:-my-60'
  | 'lg:-mx-60'
  | 'lg:-my-64'
  | 'lg:-mx-64'
  | 'lg:-my-72'
  | 'lg:-mx-72'
  | 'lg:-my-80'
  | 'lg:-mx-80'
  | 'lg:-my-96'
  | 'lg:-mx-96'
  | 'lg:-my-px'
  | 'lg:-mx-px'
  | 'lg:-my-0.5'
  | 'lg:-mx-0.5'
  | 'lg:-my-1.5'
  | 'lg:-mx-1.5'
  | 'lg:-my-2.5'
  | 'lg:-mx-2.5'
  | 'lg:-my-3.5'
  | 'lg:-mx-3.5'
  | 'lg:-my-1/2'
  | 'lg:-mx-1/2'
  | 'lg:-my-1/3'
  | 'lg:-mx-1/3'
  | 'lg:-my-2/3'
  | 'lg:-mx-2/3'
  | 'lg:-my-1/4'
  | 'lg:-mx-1/4'
  | 'lg:-my-2/4'
  | 'lg:-mx-2/4'
  | 'lg:-my-3/4'
  | 'lg:-mx-3/4'
  | 'lg:-my-1/5'
  | 'lg:-mx-1/5'
  | 'lg:-my-2/5'
  | 'lg:-mx-2/5'
  | 'lg:-my-3/5'
  | 'lg:-mx-3/5'
  | 'lg:-my-4/5'
  | 'lg:-mx-4/5'
  | 'lg:-my-1/6'
  | 'lg:-mx-1/6'
  | 'lg:-my-2/6'
  | 'lg:-mx-2/6'
  | 'lg:-my-3/6'
  | 'lg:-mx-3/6'
  | 'lg:-my-4/6'
  | 'lg:-mx-4/6'
  | 'lg:-my-5/6'
  | 'lg:-mx-5/6'
  | 'lg:-my-1/12'
  | 'lg:-mx-1/12'
  | 'lg:-my-2/12'
  | 'lg:-mx-2/12'
  | 'lg:-my-3/12'
  | 'lg:-mx-3/12'
  | 'lg:-my-4/12'
  | 'lg:-mx-4/12'
  | 'lg:-my-5/12'
  | 'lg:-mx-5/12'
  | 'lg:-my-6/12'
  | 'lg:-mx-6/12'
  | 'lg:-my-7/12'
  | 'lg:-mx-7/12'
  | 'lg:-my-8/12'
  | 'lg:-mx-8/12'
  | 'lg:-my-9/12'
  | 'lg:-mx-9/12'
  | 'lg:-my-10/12'
  | 'lg:-mx-10/12'
  | 'lg:-my-11/12'
  | 'lg:-mx-11/12'
  | 'lg:-my-full'
  | 'lg:-mx-full'
  | 'lg:mt-0'
  | 'lg:mr-0'
  | 'lg:mb-0'
  | 'lg:ml-0'
  | 'lg:mt-1'
  | 'lg:mr-1'
  | 'lg:mb-1'
  | 'lg:ml-1'
  | 'lg:mt-2'
  | 'lg:mr-2'
  | 'lg:mb-2'
  | 'lg:ml-2'
  | 'lg:mt-3'
  | 'lg:mr-3'
  | 'lg:mb-3'
  | 'lg:ml-3'
  | 'lg:mt-4'
  | 'lg:mr-4'
  | 'lg:mb-4'
  | 'lg:ml-4'
  | 'lg:mt-5'
  | 'lg:mr-5'
  | 'lg:mb-5'
  | 'lg:ml-5'
  | 'lg:mt-6'
  | 'lg:mr-6'
  | 'lg:mb-6'
  | 'lg:ml-6'
  | 'lg:mt-7'
  | 'lg:mr-7'
  | 'lg:mb-7'
  | 'lg:ml-7'
  | 'lg:mt-8'
  | 'lg:mr-8'
  | 'lg:mb-8'
  | 'lg:ml-8'
  | 'lg:mt-9'
  | 'lg:mr-9'
  | 'lg:mb-9'
  | 'lg:ml-9'
  | 'lg:mt-10'
  | 'lg:mr-10'
  | 'lg:mb-10'
  | 'lg:ml-10'
  | 'lg:mt-11'
  | 'lg:mr-11'
  | 'lg:mb-11'
  | 'lg:ml-11'
  | 'lg:mt-12'
  | 'lg:mr-12'
  | 'lg:mb-12'
  | 'lg:ml-12'
  | 'lg:mt-13'
  | 'lg:mr-13'
  | 'lg:mb-13'
  | 'lg:ml-13'
  | 'lg:mt-14'
  | 'lg:mr-14'
  | 'lg:mb-14'
  | 'lg:ml-14'
  | 'lg:mt-15'
  | 'lg:mr-15'
  | 'lg:mb-15'
  | 'lg:ml-15'
  | 'lg:mt-16'
  | 'lg:mr-16'
  | 'lg:mb-16'
  | 'lg:ml-16'
  | 'lg:mt-20'
  | 'lg:mr-20'
  | 'lg:mb-20'
  | 'lg:ml-20'
  | 'lg:mt-24'
  | 'lg:mr-24'
  | 'lg:mb-24'
  | 'lg:ml-24'
  | 'lg:mt-28'
  | 'lg:mr-28'
  | 'lg:mb-28'
  | 'lg:ml-28'
  | 'lg:mt-32'
  | 'lg:mr-32'
  | 'lg:mb-32'
  | 'lg:ml-32'
  | 'lg:mt-36'
  | 'lg:mr-36'
  | 'lg:mb-36'
  | 'lg:ml-36'
  | 'lg:mt-40'
  | 'lg:mr-40'
  | 'lg:mb-40'
  | 'lg:ml-40'
  | 'lg:mt-48'
  | 'lg:mr-48'
  | 'lg:mb-48'
  | 'lg:ml-48'
  | 'lg:mt-56'
  | 'lg:mr-56'
  | 'lg:mb-56'
  | 'lg:ml-56'
  | 'lg:mt-60'
  | 'lg:mr-60'
  | 'lg:mb-60'
  | 'lg:ml-60'
  | 'lg:mt-64'
  | 'lg:mr-64'
  | 'lg:mb-64'
  | 'lg:ml-64'
  | 'lg:mt-72'
  | 'lg:mr-72'
  | 'lg:mb-72'
  | 'lg:ml-72'
  | 'lg:mt-80'
  | 'lg:mr-80'
  | 'lg:mb-80'
  | 'lg:ml-80'
  | 'lg:mt-96'
  | 'lg:mr-96'
  | 'lg:mb-96'
  | 'lg:ml-96'
  | 'lg:mt-auto'
  | 'lg:mr-auto'
  | 'lg:mb-auto'
  | 'lg:ml-auto'
  | 'lg:mt-px'
  | 'lg:mr-px'
  | 'lg:mb-px'
  | 'lg:ml-px'
  | 'lg:mt-0.5'
  | 'lg:mr-0.5'
  | 'lg:mb-0.5'
  | 'lg:ml-0.5'
  | 'lg:mt-1.5'
  | 'lg:mr-1.5'
  | 'lg:mb-1.5'
  | 'lg:ml-1.5'
  | 'lg:mt-2.5'
  | 'lg:mr-2.5'
  | 'lg:mb-2.5'
  | 'lg:ml-2.5'
  | 'lg:mt-3.5'
  | 'lg:mr-3.5'
  | 'lg:mb-3.5'
  | 'lg:ml-3.5'
  | 'lg:mt-1/2'
  | 'lg:mr-1/2'
  | 'lg:mb-1/2'
  | 'lg:ml-1/2'
  | 'lg:mt-1/3'
  | 'lg:mr-1/3'
  | 'lg:mb-1/3'
  | 'lg:ml-1/3'
  | 'lg:mt-2/3'
  | 'lg:mr-2/3'
  | 'lg:mb-2/3'
  | 'lg:ml-2/3'
  | 'lg:mt-1/4'
  | 'lg:mr-1/4'
  | 'lg:mb-1/4'
  | 'lg:ml-1/4'
  | 'lg:mt-2/4'
  | 'lg:mr-2/4'
  | 'lg:mb-2/4'
  | 'lg:ml-2/4'
  | 'lg:mt-3/4'
  | 'lg:mr-3/4'
  | 'lg:mb-3/4'
  | 'lg:ml-3/4'
  | 'lg:mt-1/5'
  | 'lg:mr-1/5'
  | 'lg:mb-1/5'
  | 'lg:ml-1/5'
  | 'lg:mt-2/5'
  | 'lg:mr-2/5'
  | 'lg:mb-2/5'
  | 'lg:ml-2/5'
  | 'lg:mt-3/5'
  | 'lg:mr-3/5'
  | 'lg:mb-3/5'
  | 'lg:ml-3/5'
  | 'lg:mt-4/5'
  | 'lg:mr-4/5'
  | 'lg:mb-4/5'
  | 'lg:ml-4/5'
  | 'lg:mt-1/6'
  | 'lg:mr-1/6'
  | 'lg:mb-1/6'
  | 'lg:ml-1/6'
  | 'lg:mt-2/6'
  | 'lg:mr-2/6'
  | 'lg:mb-2/6'
  | 'lg:ml-2/6'
  | 'lg:mt-3/6'
  | 'lg:mr-3/6'
  | 'lg:mb-3/6'
  | 'lg:ml-3/6'
  | 'lg:mt-4/6'
  | 'lg:mr-4/6'
  | 'lg:mb-4/6'
  | 'lg:ml-4/6'
  | 'lg:mt-5/6'
  | 'lg:mr-5/6'
  | 'lg:mb-5/6'
  | 'lg:ml-5/6'
  | 'lg:mt-1/12'
  | 'lg:mr-1/12'
  | 'lg:mb-1/12'
  | 'lg:ml-1/12'
  | 'lg:mt-2/12'
  | 'lg:mr-2/12'
  | 'lg:mb-2/12'
  | 'lg:ml-2/12'
  | 'lg:mt-3/12'
  | 'lg:mr-3/12'
  | 'lg:mb-3/12'
  | 'lg:ml-3/12'
  | 'lg:mt-4/12'
  | 'lg:mr-4/12'
  | 'lg:mb-4/12'
  | 'lg:ml-4/12'
  | 'lg:mt-5/12'
  | 'lg:mr-5/12'
  | 'lg:mb-5/12'
  | 'lg:ml-5/12'
  | 'lg:mt-6/12'
  | 'lg:mr-6/12'
  | 'lg:mb-6/12'
  | 'lg:ml-6/12'
  | 'lg:mt-7/12'
  | 'lg:mr-7/12'
  | 'lg:mb-7/12'
  | 'lg:ml-7/12'
  | 'lg:mt-8/12'
  | 'lg:mr-8/12'
  | 'lg:mb-8/12'
  | 'lg:ml-8/12'
  | 'lg:mt-9/12'
  | 'lg:mr-9/12'
  | 'lg:mb-9/12'
  | 'lg:ml-9/12'
  | 'lg:mt-10/12'
  | 'lg:mr-10/12'
  | 'lg:mb-10/12'
  | 'lg:ml-10/12'
  | 'lg:mt-11/12'
  | 'lg:mr-11/12'
  | 'lg:mb-11/12'
  | 'lg:ml-11/12'
  | 'lg:mt-full'
  | 'lg:mr-full'
  | 'lg:mb-full'
  | 'lg:ml-full'
  | 'lg:-mt-1'
  | 'lg:-mr-1'
  | 'lg:-mb-1'
  | 'lg:-ml-1'
  | 'lg:-mt-2'
  | 'lg:-mr-2'
  | 'lg:-mb-2'
  | 'lg:-ml-2'
  | 'lg:-mt-3'
  | 'lg:-mr-3'
  | 'lg:-mb-3'
  | 'lg:-ml-3'
  | 'lg:-mt-4'
  | 'lg:-mr-4'
  | 'lg:-mb-4'
  | 'lg:-ml-4'
  | 'lg:-mt-5'
  | 'lg:-mr-5'
  | 'lg:-mb-5'
  | 'lg:-ml-5'
  | 'lg:-mt-6'
  | 'lg:-mr-6'
  | 'lg:-mb-6'
  | 'lg:-ml-6'
  | 'lg:-mt-7'
  | 'lg:-mr-7'
  | 'lg:-mb-7'
  | 'lg:-ml-7'
  | 'lg:-mt-8'
  | 'lg:-mr-8'
  | 'lg:-mb-8'
  | 'lg:-ml-8'
  | 'lg:-mt-9'
  | 'lg:-mr-9'
  | 'lg:-mb-9'
  | 'lg:-ml-9'
  | 'lg:-mt-10'
  | 'lg:-mr-10'
  | 'lg:-mb-10'
  | 'lg:-ml-10'
  | 'lg:-mt-11'
  | 'lg:-mr-11'
  | 'lg:-mb-11'
  | 'lg:-ml-11'
  | 'lg:-mt-12'
  | 'lg:-mr-12'
  | 'lg:-mb-12'
  | 'lg:-ml-12'
  | 'lg:-mt-13'
  | 'lg:-mr-13'
  | 'lg:-mb-13'
  | 'lg:-ml-13'
  | 'lg:-mt-14'
  | 'lg:-mr-14'
  | 'lg:-mb-14'
  | 'lg:-ml-14'
  | 'lg:-mt-15'
  | 'lg:-mr-15'
  | 'lg:-mb-15'
  | 'lg:-ml-15'
  | 'lg:-mt-16'
  | 'lg:-mr-16'
  | 'lg:-mb-16'
  | 'lg:-ml-16'
  | 'lg:-mt-20'
  | 'lg:-mr-20'
  | 'lg:-mb-20'
  | 'lg:-ml-20'
  | 'lg:-mt-24'
  | 'lg:-mr-24'
  | 'lg:-mb-24'
  | 'lg:-ml-24'
  | 'lg:-mt-28'
  | 'lg:-mr-28'
  | 'lg:-mb-28'
  | 'lg:-ml-28'
  | 'lg:-mt-32'
  | 'lg:-mr-32'
  | 'lg:-mb-32'
  | 'lg:-ml-32'
  | 'lg:-mt-36'
  | 'lg:-mr-36'
  | 'lg:-mb-36'
  | 'lg:-ml-36'
  | 'lg:-mt-40'
  | 'lg:-mr-40'
  | 'lg:-mb-40'
  | 'lg:-ml-40'
  | 'lg:-mt-48'
  | 'lg:-mr-48'
  | 'lg:-mb-48'
  | 'lg:-ml-48'
  | 'lg:-mt-56'
  | 'lg:-mr-56'
  | 'lg:-mb-56'
  | 'lg:-ml-56'
  | 'lg:-mt-60'
  | 'lg:-mr-60'
  | 'lg:-mb-60'
  | 'lg:-ml-60'
  | 'lg:-mt-64'
  | 'lg:-mr-64'
  | 'lg:-mb-64'
  | 'lg:-ml-64'
  | 'lg:-mt-72'
  | 'lg:-mr-72'
  | 'lg:-mb-72'
  | 'lg:-ml-72'
  | 'lg:-mt-80'
  | 'lg:-mr-80'
  | 'lg:-mb-80'
  | 'lg:-ml-80'
  | 'lg:-mt-96'
  | 'lg:-mr-96'
  | 'lg:-mb-96'
  | 'lg:-ml-96'
  | 'lg:-mt-px'
  | 'lg:-mr-px'
  | 'lg:-mb-px'
  | 'lg:-ml-px'
  | 'lg:-mt-0.5'
  | 'lg:-mr-0.5'
  | 'lg:-mb-0.5'
  | 'lg:-ml-0.5'
  | 'lg:-mt-1.5'
  | 'lg:-mr-1.5'
  | 'lg:-mb-1.5'
  | 'lg:-ml-1.5'
  | 'lg:-mt-2.5'
  | 'lg:-mr-2.5'
  | 'lg:-mb-2.5'
  | 'lg:-ml-2.5'
  | 'lg:-mt-3.5'
  | 'lg:-mr-3.5'
  | 'lg:-mb-3.5'
  | 'lg:-ml-3.5'
  | 'lg:-mt-1/2'
  | 'lg:-mr-1/2'
  | 'lg:-mb-1/2'
  | 'lg:-ml-1/2'
  | 'lg:-mt-1/3'
  | 'lg:-mr-1/3'
  | 'lg:-mb-1/3'
  | 'lg:-ml-1/3'
  | 'lg:-mt-2/3'
  | 'lg:-mr-2/3'
  | 'lg:-mb-2/3'
  | 'lg:-ml-2/3'
  | 'lg:-mt-1/4'
  | 'lg:-mr-1/4'
  | 'lg:-mb-1/4'
  | 'lg:-ml-1/4'
  | 'lg:-mt-2/4'
  | 'lg:-mr-2/4'
  | 'lg:-mb-2/4'
  | 'lg:-ml-2/4'
  | 'lg:-mt-3/4'
  | 'lg:-mr-3/4'
  | 'lg:-mb-3/4'
  | 'lg:-ml-3/4'
  | 'lg:-mt-1/5'
  | 'lg:-mr-1/5'
  | 'lg:-mb-1/5'
  | 'lg:-ml-1/5'
  | 'lg:-mt-2/5'
  | 'lg:-mr-2/5'
  | 'lg:-mb-2/5'
  | 'lg:-ml-2/5'
  | 'lg:-mt-3/5'
  | 'lg:-mr-3/5'
  | 'lg:-mb-3/5'
  | 'lg:-ml-3/5'
  | 'lg:-mt-4/5'
  | 'lg:-mr-4/5'
  | 'lg:-mb-4/5'
  | 'lg:-ml-4/5'
  | 'lg:-mt-1/6'
  | 'lg:-mr-1/6'
  | 'lg:-mb-1/6'
  | 'lg:-ml-1/6'
  | 'lg:-mt-2/6'
  | 'lg:-mr-2/6'
  | 'lg:-mb-2/6'
  | 'lg:-ml-2/6'
  | 'lg:-mt-3/6'
  | 'lg:-mr-3/6'
  | 'lg:-mb-3/6'
  | 'lg:-ml-3/6'
  | 'lg:-mt-4/6'
  | 'lg:-mr-4/6'
  | 'lg:-mb-4/6'
  | 'lg:-ml-4/6'
  | 'lg:-mt-5/6'
  | 'lg:-mr-5/6'
  | 'lg:-mb-5/6'
  | 'lg:-ml-5/6'
  | 'lg:-mt-1/12'
  | 'lg:-mr-1/12'
  | 'lg:-mb-1/12'
  | 'lg:-ml-1/12'
  | 'lg:-mt-2/12'
  | 'lg:-mr-2/12'
  | 'lg:-mb-2/12'
  | 'lg:-ml-2/12'
  | 'lg:-mt-3/12'
  | 'lg:-mr-3/12'
  | 'lg:-mb-3/12'
  | 'lg:-ml-3/12'
  | 'lg:-mt-4/12'
  | 'lg:-mr-4/12'
  | 'lg:-mb-4/12'
  | 'lg:-ml-4/12'
  | 'lg:-mt-5/12'
  | 'lg:-mr-5/12'
  | 'lg:-mb-5/12'
  | 'lg:-ml-5/12'
  | 'lg:-mt-6/12'
  | 'lg:-mr-6/12'
  | 'lg:-mb-6/12'
  | 'lg:-ml-6/12'
  | 'lg:-mt-7/12'
  | 'lg:-mr-7/12'
  | 'lg:-mb-7/12'
  | 'lg:-ml-7/12'
  | 'lg:-mt-8/12'
  | 'lg:-mr-8/12'
  | 'lg:-mb-8/12'
  | 'lg:-ml-8/12'
  | 'lg:-mt-9/12'
  | 'lg:-mr-9/12'
  | 'lg:-mb-9/12'
  | 'lg:-ml-9/12'
  | 'lg:-mt-10/12'
  | 'lg:-mr-10/12'
  | 'lg:-mb-10/12'
  | 'lg:-ml-10/12'
  | 'lg:-mt-11/12'
  | 'lg:-mr-11/12'
  | 'lg:-mb-11/12'
  | 'lg:-ml-11/12'
  | 'lg:-mt-full'
  | 'lg:-mr-full'
  | 'lg:-mb-full'
  | 'lg:-ml-full'
  | 'lg:max-h-0'
  | 'lg:max-h-1'
  | 'lg:max-h-2'
  | 'lg:max-h-3'
  | 'lg:max-h-4'
  | 'lg:max-h-5'
  | 'lg:max-h-6'
  | 'lg:max-h-7'
  | 'lg:max-h-8'
  | 'lg:max-h-9'
  | 'lg:max-h-10'
  | 'lg:max-h-11'
  | 'lg:max-h-12'
  | 'lg:max-h-13'
  | 'lg:max-h-14'
  | 'lg:max-h-15'
  | 'lg:max-h-16'
  | 'lg:max-h-20'
  | 'lg:max-h-24'
  | 'lg:max-h-28'
  | 'lg:max-h-32'
  | 'lg:max-h-36'
  | 'lg:max-h-40'
  | 'lg:max-h-48'
  | 'lg:max-h-56'
  | 'lg:max-h-60'
  | 'lg:max-h-64'
  | 'lg:max-h-72'
  | 'lg:max-h-80'
  | 'lg:max-h-96'
  | 'lg:max-h-screen'
  | 'lg:max-h-px'
  | 'lg:max-h-0.5'
  | 'lg:max-h-1.5'
  | 'lg:max-h-2.5'
  | 'lg:max-h-3.5'
  | 'lg:max-h-1/2'
  | 'lg:max-h-1/3'
  | 'lg:max-h-2/3'
  | 'lg:max-h-1/4'
  | 'lg:max-h-2/4'
  | 'lg:max-h-3/4'
  | 'lg:max-h-1/5'
  | 'lg:max-h-2/5'
  | 'lg:max-h-3/5'
  | 'lg:max-h-4/5'
  | 'lg:max-h-1/6'
  | 'lg:max-h-2/6'
  | 'lg:max-h-3/6'
  | 'lg:max-h-4/6'
  | 'lg:max-h-5/6'
  | 'lg:max-h-1/12'
  | 'lg:max-h-2/12'
  | 'lg:max-h-3/12'
  | 'lg:max-h-4/12'
  | 'lg:max-h-5/12'
  | 'lg:max-h-6/12'
  | 'lg:max-h-7/12'
  | 'lg:max-h-8/12'
  | 'lg:max-h-9/12'
  | 'lg:max-h-10/12'
  | 'lg:max-h-11/12'
  | 'lg:max-h-full'
  | 'lg:max-w-none'
  | 'lg:max-w-xs'
  | 'lg:max-w-sm'
  | 'lg:max-w-md'
  | 'lg:max-w-lg'
  | 'lg:max-w-xl'
  | 'lg:max-w-2xl'
  | 'lg:max-w-3xl'
  | 'lg:max-w-4xl'
  | 'lg:max-w-5xl'
  | 'lg:max-w-6xl'
  | 'lg:max-w-7xl'
  | 'lg:max-w-full'
  | 'lg:max-w-screen-sm'
  | 'lg:max-w-screen-md'
  | 'lg:max-w-screen-lg'
  | 'lg:max-w-screen-xl'
  | 'lg:min-h-0'
  | 'lg:min-h-full'
  | 'lg:min-h-screen'
  | 'lg:min-w-0'
  | 'lg:min-w-full'
  | 'lg:object-contain'
  | 'lg:object-cover'
  | 'lg:object-fill'
  | 'lg:object-none'
  | 'lg:object-scale-down'
  | 'lg:object-bottom'
  | 'lg:object-center'
  | 'lg:object-left'
  | 'lg:object-left-bottom'
  | 'lg:object-left-top'
  | 'lg:object-right'
  | 'lg:object-right-bottom'
  | 'lg:object-right-top'
  | 'lg:object-top'
  | 'lg:opacity-0'
  | 'lg:opacity-25'
  | 'lg:opacity-50'
  | 'lg:opacity-75'
  | 'lg:opacity-100'
  | 'lg:hover:opacity-0'
  | 'lg:hover:opacity-25'
  | 'lg:hover:opacity-50'
  | 'lg:hover:opacity-75'
  | 'lg:hover:opacity-100'
  | 'lg:focus:opacity-0'
  | 'lg:focus:opacity-25'
  | 'lg:focus:opacity-50'
  | 'lg:focus:opacity-75'
  | 'lg:focus:opacity-100'
  | 'lg:outline-none'
  | 'lg:focus:outline-none'
  | 'lg:overflow-auto'
  | 'lg:overflow-hidden'
  | 'lg:overflow-visible'
  | 'lg:overflow-scroll'
  | 'lg:overflow-x-auto'
  | 'lg:overflow-y-auto'
  | 'lg:overflow-x-hidden'
  | 'lg:overflow-y-hidden'
  | 'lg:overflow-x-visible'
  | 'lg:overflow-y-visible'
  | 'lg:overflow-x-scroll'
  | 'lg:overflow-y-scroll'
  | 'lg:scrolling-touch'
  | 'lg:scrolling-auto'
  | 'lg:p-0'
  | 'lg:p-1'
  | 'lg:p-2'
  | 'lg:p-3'
  | 'lg:p-4'
  | 'lg:p-5'
  | 'lg:p-6'
  | 'lg:p-7'
  | 'lg:p-8'
  | 'lg:p-9'
  | 'lg:p-10'
  | 'lg:p-11'
  | 'lg:p-12'
  | 'lg:p-13'
  | 'lg:p-14'
  | 'lg:p-15'
  | 'lg:p-16'
  | 'lg:p-20'
  | 'lg:p-24'
  | 'lg:p-28'
  | 'lg:p-32'
  | 'lg:p-36'
  | 'lg:p-40'
  | 'lg:p-48'
  | 'lg:p-56'
  | 'lg:p-60'
  | 'lg:p-64'
  | 'lg:p-72'
  | 'lg:p-80'
  | 'lg:p-96'
  | 'lg:p-px'
  | 'lg:p-0.5'
  | 'lg:p-1.5'
  | 'lg:p-2.5'
  | 'lg:p-3.5'
  | 'lg:p-1/2'
  | 'lg:p-1/3'
  | 'lg:p-2/3'
  | 'lg:p-1/4'
  | 'lg:p-2/4'
  | 'lg:p-3/4'
  | 'lg:p-1/5'
  | 'lg:p-2/5'
  | 'lg:p-3/5'
  | 'lg:p-4/5'
  | 'lg:p-1/6'
  | 'lg:p-2/6'
  | 'lg:p-3/6'
  | 'lg:p-4/6'
  | 'lg:p-5/6'
  | 'lg:p-1/12'
  | 'lg:p-2/12'
  | 'lg:p-3/12'
  | 'lg:p-4/12'
  | 'lg:p-5/12'
  | 'lg:p-6/12'
  | 'lg:p-7/12'
  | 'lg:p-8/12'
  | 'lg:p-9/12'
  | 'lg:p-10/12'
  | 'lg:p-11/12'
  | 'lg:p-full'
  | 'lg:py-0'
  | 'lg:px-0'
  | 'lg:py-1'
  | 'lg:px-1'
  | 'lg:py-2'
  | 'lg:px-2'
  | 'lg:py-3'
  | 'lg:px-3'
  | 'lg:py-4'
  | 'lg:px-4'
  | 'lg:py-5'
  | 'lg:px-5'
  | 'lg:py-6'
  | 'lg:px-6'
  | 'lg:py-7'
  | 'lg:px-7'
  | 'lg:py-8'
  | 'lg:px-8'
  | 'lg:py-9'
  | 'lg:px-9'
  | 'lg:py-10'
  | 'lg:px-10'
  | 'lg:py-11'
  | 'lg:px-11'
  | 'lg:py-12'
  | 'lg:px-12'
  | 'lg:py-13'
  | 'lg:px-13'
  | 'lg:py-14'
  | 'lg:px-14'
  | 'lg:py-15'
  | 'lg:px-15'
  | 'lg:py-16'
  | 'lg:px-16'
  | 'lg:py-20'
  | 'lg:px-20'
  | 'lg:py-24'
  | 'lg:px-24'
  | 'lg:py-28'
  | 'lg:px-28'
  | 'lg:py-32'
  | 'lg:px-32'
  | 'lg:py-36'
  | 'lg:px-36'
  | 'lg:py-40'
  | 'lg:px-40'
  | 'lg:py-48'
  | 'lg:px-48'
  | 'lg:py-56'
  | 'lg:px-56'
  | 'lg:py-60'
  | 'lg:px-60'
  | 'lg:py-64'
  | 'lg:px-64'
  | 'lg:py-72'
  | 'lg:px-72'
  | 'lg:py-80'
  | 'lg:px-80'
  | 'lg:py-96'
  | 'lg:px-96'
  | 'lg:py-px'
  | 'lg:px-px'
  | 'lg:py-0.5'
  | 'lg:px-0.5'
  | 'lg:py-1.5'
  | 'lg:px-1.5'
  | 'lg:py-2.5'
  | 'lg:px-2.5'
  | 'lg:py-3.5'
  | 'lg:px-3.5'
  | 'lg:py-1/2'
  | 'lg:px-1/2'
  | 'lg:py-1/3'
  | 'lg:px-1/3'
  | 'lg:py-2/3'
  | 'lg:px-2/3'
  | 'lg:py-1/4'
  | 'lg:px-1/4'
  | 'lg:py-2/4'
  | 'lg:px-2/4'
  | 'lg:py-3/4'
  | 'lg:px-3/4'
  | 'lg:py-1/5'
  | 'lg:px-1/5'
  | 'lg:py-2/5'
  | 'lg:px-2/5'
  | 'lg:py-3/5'
  | 'lg:px-3/5'
  | 'lg:py-4/5'
  | 'lg:px-4/5'
  | 'lg:py-1/6'
  | 'lg:px-1/6'
  | 'lg:py-2/6'
  | 'lg:px-2/6'
  | 'lg:py-3/6'
  | 'lg:px-3/6'
  | 'lg:py-4/6'
  | 'lg:px-4/6'
  | 'lg:py-5/6'
  | 'lg:px-5/6'
  | 'lg:py-1/12'
  | 'lg:px-1/12'
  | 'lg:py-2/12'
  | 'lg:px-2/12'
  | 'lg:py-3/12'
  | 'lg:px-3/12'
  | 'lg:py-4/12'
  | 'lg:px-4/12'
  | 'lg:py-5/12'
  | 'lg:px-5/12'
  | 'lg:py-6/12'
  | 'lg:px-6/12'
  | 'lg:py-7/12'
  | 'lg:px-7/12'
  | 'lg:py-8/12'
  | 'lg:px-8/12'
  | 'lg:py-9/12'
  | 'lg:px-9/12'
  | 'lg:py-10/12'
  | 'lg:px-10/12'
  | 'lg:py-11/12'
  | 'lg:px-11/12'
  | 'lg:py-full'
  | 'lg:px-full'
  | 'lg:pt-0'
  | 'lg:pr-0'
  | 'lg:pb-0'
  | 'lg:pl-0'
  | 'lg:pt-1'
  | 'lg:pr-1'
  | 'lg:pb-1'
  | 'lg:pl-1'
  | 'lg:pt-2'
  | 'lg:pr-2'
  | 'lg:pb-2'
  | 'lg:pl-2'
  | 'lg:pt-3'
  | 'lg:pr-3'
  | 'lg:pb-3'
  | 'lg:pl-3'
  | 'lg:pt-4'
  | 'lg:pr-4'
  | 'lg:pb-4'
  | 'lg:pl-4'
  | 'lg:pt-5'
  | 'lg:pr-5'
  | 'lg:pb-5'
  | 'lg:pl-5'
  | 'lg:pt-6'
  | 'lg:pr-6'
  | 'lg:pb-6'
  | 'lg:pl-6'
  | 'lg:pt-7'
  | 'lg:pr-7'
  | 'lg:pb-7'
  | 'lg:pl-7'
  | 'lg:pt-8'
  | 'lg:pr-8'
  | 'lg:pb-8'
  | 'lg:pl-8'
  | 'lg:pt-9'
  | 'lg:pr-9'
  | 'lg:pb-9'
  | 'lg:pl-9'
  | 'lg:pt-10'
  | 'lg:pr-10'
  | 'lg:pb-10'
  | 'lg:pl-10'
  | 'lg:pt-11'
  | 'lg:pr-11'
  | 'lg:pb-11'
  | 'lg:pl-11'
  | 'lg:pt-12'
  | 'lg:pr-12'
  | 'lg:pb-12'
  | 'lg:pl-12'
  | 'lg:pt-13'
  | 'lg:pr-13'
  | 'lg:pb-13'
  | 'lg:pl-13'
  | 'lg:pt-14'
  | 'lg:pr-14'
  | 'lg:pb-14'
  | 'lg:pl-14'
  | 'lg:pt-15'
  | 'lg:pr-15'
  | 'lg:pb-15'
  | 'lg:pl-15'
  | 'lg:pt-16'
  | 'lg:pr-16'
  | 'lg:pb-16'
  | 'lg:pl-16'
  | 'lg:pt-20'
  | 'lg:pr-20'
  | 'lg:pb-20'
  | 'lg:pl-20'
  | 'lg:pt-24'
  | 'lg:pr-24'
  | 'lg:pb-24'
  | 'lg:pl-24'
  | 'lg:pt-28'
  | 'lg:pr-28'
  | 'lg:pb-28'
  | 'lg:pl-28'
  | 'lg:pt-32'
  | 'lg:pr-32'
  | 'lg:pb-32'
  | 'lg:pl-32'
  | 'lg:pt-36'
  | 'lg:pr-36'
  | 'lg:pb-36'
  | 'lg:pl-36'
  | 'lg:pt-40'
  | 'lg:pr-40'
  | 'lg:pb-40'
  | 'lg:pl-40'
  | 'lg:pt-48'
  | 'lg:pr-48'
  | 'lg:pb-48'
  | 'lg:pl-48'
  | 'lg:pt-56'
  | 'lg:pr-56'
  | 'lg:pb-56'
  | 'lg:pl-56'
  | 'lg:pt-60'
  | 'lg:pr-60'
  | 'lg:pb-60'
  | 'lg:pl-60'
  | 'lg:pt-64'
  | 'lg:pr-64'
  | 'lg:pb-64'
  | 'lg:pl-64'
  | 'lg:pt-72'
  | 'lg:pr-72'
  | 'lg:pb-72'
  | 'lg:pl-72'
  | 'lg:pt-80'
  | 'lg:pr-80'
  | 'lg:pb-80'
  | 'lg:pl-80'
  | 'lg:pt-96'
  | 'lg:pr-96'
  | 'lg:pb-96'
  | 'lg:pl-96'
  | 'lg:pt-px'
  | 'lg:pr-px'
  | 'lg:pb-px'
  | 'lg:pl-px'
  | 'lg:pt-0.5'
  | 'lg:pr-0.5'
  | 'lg:pb-0.5'
  | 'lg:pl-0.5'
  | 'lg:pt-1.5'
  | 'lg:pr-1.5'
  | 'lg:pb-1.5'
  | 'lg:pl-1.5'
  | 'lg:pt-2.5'
  | 'lg:pr-2.5'
  | 'lg:pb-2.5'
  | 'lg:pl-2.5'
  | 'lg:pt-3.5'
  | 'lg:pr-3.5'
  | 'lg:pb-3.5'
  | 'lg:pl-3.5'
  | 'lg:pt-1/2'
  | 'lg:pr-1/2'
  | 'lg:pb-1/2'
  | 'lg:pl-1/2'
  | 'lg:pt-1/3'
  | 'lg:pr-1/3'
  | 'lg:pb-1/3'
  | 'lg:pl-1/3'
  | 'lg:pt-2/3'
  | 'lg:pr-2/3'
  | 'lg:pb-2/3'
  | 'lg:pl-2/3'
  | 'lg:pt-1/4'
  | 'lg:pr-1/4'
  | 'lg:pb-1/4'
  | 'lg:pl-1/4'
  | 'lg:pt-2/4'
  | 'lg:pr-2/4'
  | 'lg:pb-2/4'
  | 'lg:pl-2/4'
  | 'lg:pt-3/4'
  | 'lg:pr-3/4'
  | 'lg:pb-3/4'
  | 'lg:pl-3/4'
  | 'lg:pt-1/5'
  | 'lg:pr-1/5'
  | 'lg:pb-1/5'
  | 'lg:pl-1/5'
  | 'lg:pt-2/5'
  | 'lg:pr-2/5'
  | 'lg:pb-2/5'
  | 'lg:pl-2/5'
  | 'lg:pt-3/5'
  | 'lg:pr-3/5'
  | 'lg:pb-3/5'
  | 'lg:pl-3/5'
  | 'lg:pt-4/5'
  | 'lg:pr-4/5'
  | 'lg:pb-4/5'
  | 'lg:pl-4/5'
  | 'lg:pt-1/6'
  | 'lg:pr-1/6'
  | 'lg:pb-1/6'
  | 'lg:pl-1/6'
  | 'lg:pt-2/6'
  | 'lg:pr-2/6'
  | 'lg:pb-2/6'
  | 'lg:pl-2/6'
  | 'lg:pt-3/6'
  | 'lg:pr-3/6'
  | 'lg:pb-3/6'
  | 'lg:pl-3/6'
  | 'lg:pt-4/6'
  | 'lg:pr-4/6'
  | 'lg:pb-4/6'
  | 'lg:pl-4/6'
  | 'lg:pt-5/6'
  | 'lg:pr-5/6'
  | 'lg:pb-5/6'
  | 'lg:pl-5/6'
  | 'lg:pt-1/12'
  | 'lg:pr-1/12'
  | 'lg:pb-1/12'
  | 'lg:pl-1/12'
  | 'lg:pt-2/12'
  | 'lg:pr-2/12'
  | 'lg:pb-2/12'
  | 'lg:pl-2/12'
  | 'lg:pt-3/12'
  | 'lg:pr-3/12'
  | 'lg:pb-3/12'
  | 'lg:pl-3/12'
  | 'lg:pt-4/12'
  | 'lg:pr-4/12'
  | 'lg:pb-4/12'
  | 'lg:pl-4/12'
  | 'lg:pt-5/12'
  | 'lg:pr-5/12'
  | 'lg:pb-5/12'
  | 'lg:pl-5/12'
  | 'lg:pt-6/12'
  | 'lg:pr-6/12'
  | 'lg:pb-6/12'
  | 'lg:pl-6/12'
  | 'lg:pt-7/12'
  | 'lg:pr-7/12'
  | 'lg:pb-7/12'
  | 'lg:pl-7/12'
  | 'lg:pt-8/12'
  | 'lg:pr-8/12'
  | 'lg:pb-8/12'
  | 'lg:pl-8/12'
  | 'lg:pt-9/12'
  | 'lg:pr-9/12'
  | 'lg:pb-9/12'
  | 'lg:pl-9/12'
  | 'lg:pt-10/12'
  | 'lg:pr-10/12'
  | 'lg:pb-10/12'
  | 'lg:pl-10/12'
  | 'lg:pt-11/12'
  | 'lg:pr-11/12'
  | 'lg:pb-11/12'
  | 'lg:pl-11/12'
  | 'lg:pt-full'
  | 'lg:pr-full'
  | 'lg:pb-full'
  | 'lg:pl-full'
  | 'lg:placeholder-transparent'
  | 'lg:placeholder-white'
  | 'lg:placeholder-black'
  | 'lg:placeholder-gray-50'
  | 'lg:placeholder-gray-100'
  | 'lg:placeholder-gray-200'
  | 'lg:placeholder-gray-300'
  | 'lg:placeholder-gray-400'
  | 'lg:placeholder-gray-500'
  | 'lg:placeholder-gray-600'
  | 'lg:placeholder-gray-700'
  | 'lg:placeholder-gray-800'
  | 'lg:placeholder-gray-900'
  | 'lg:placeholder-cool-gray-50'
  | 'lg:placeholder-cool-gray-100'
  | 'lg:placeholder-cool-gray-200'
  | 'lg:placeholder-cool-gray-300'
  | 'lg:placeholder-cool-gray-400'
  | 'lg:placeholder-cool-gray-500'
  | 'lg:placeholder-cool-gray-600'
  | 'lg:placeholder-cool-gray-700'
  | 'lg:placeholder-cool-gray-800'
  | 'lg:placeholder-cool-gray-900'
  | 'lg:placeholder-red-50'
  | 'lg:placeholder-red-100'
  | 'lg:placeholder-red-200'
  | 'lg:placeholder-red-300'
  | 'lg:placeholder-red-400'
  | 'lg:placeholder-red-500'
  | 'lg:placeholder-red-600'
  | 'lg:placeholder-red-700'
  | 'lg:placeholder-red-800'
  | 'lg:placeholder-red-900'
  | 'lg:placeholder-orange-50'
  | 'lg:placeholder-orange-100'
  | 'lg:placeholder-orange-200'
  | 'lg:placeholder-orange-300'
  | 'lg:placeholder-orange-400'
  | 'lg:placeholder-orange-500'
  | 'lg:placeholder-orange-600'
  | 'lg:placeholder-orange-700'
  | 'lg:placeholder-orange-800'
  | 'lg:placeholder-orange-900'
  | 'lg:placeholder-yellow-50'
  | 'lg:placeholder-yellow-100'
  | 'lg:placeholder-yellow-200'
  | 'lg:placeholder-yellow-300'
  | 'lg:placeholder-yellow-400'
  | 'lg:placeholder-yellow-500'
  | 'lg:placeholder-yellow-600'
  | 'lg:placeholder-yellow-700'
  | 'lg:placeholder-yellow-800'
  | 'lg:placeholder-yellow-900'
  | 'lg:placeholder-green-50'
  | 'lg:placeholder-green-100'
  | 'lg:placeholder-green-200'
  | 'lg:placeholder-green-300'
  | 'lg:placeholder-green-400'
  | 'lg:placeholder-green-500'
  | 'lg:placeholder-green-600'
  | 'lg:placeholder-green-700'
  | 'lg:placeholder-green-800'
  | 'lg:placeholder-green-900'
  | 'lg:placeholder-teal-50'
  | 'lg:placeholder-teal-100'
  | 'lg:placeholder-teal-200'
  | 'lg:placeholder-teal-300'
  | 'lg:placeholder-teal-400'
  | 'lg:placeholder-teal-500'
  | 'lg:placeholder-teal-600'
  | 'lg:placeholder-teal-700'
  | 'lg:placeholder-teal-800'
  | 'lg:placeholder-teal-900'
  | 'lg:placeholder-blue-50'
  | 'lg:placeholder-blue-100'
  | 'lg:placeholder-blue-200'
  | 'lg:placeholder-blue-300'
  | 'lg:placeholder-blue-400'
  | 'lg:placeholder-blue-500'
  | 'lg:placeholder-blue-600'
  | 'lg:placeholder-blue-700'
  | 'lg:placeholder-blue-800'
  | 'lg:placeholder-blue-900'
  | 'lg:placeholder-indigo-50'
  | 'lg:placeholder-indigo-100'
  | 'lg:placeholder-indigo-200'
  | 'lg:placeholder-indigo-300'
  | 'lg:placeholder-indigo-400'
  | 'lg:placeholder-indigo-500'
  | 'lg:placeholder-indigo-600'
  | 'lg:placeholder-indigo-700'
  | 'lg:placeholder-indigo-800'
  | 'lg:placeholder-indigo-900'
  | 'lg:placeholder-purple-50'
  | 'lg:placeholder-purple-100'
  | 'lg:placeholder-purple-200'
  | 'lg:placeholder-purple-300'
  | 'lg:placeholder-purple-400'
  | 'lg:placeholder-purple-500'
  | 'lg:placeholder-purple-600'
  | 'lg:placeholder-purple-700'
  | 'lg:placeholder-purple-800'
  | 'lg:placeholder-purple-900'
  | 'lg:placeholder-pink-50'
  | 'lg:placeholder-pink-100'
  | 'lg:placeholder-pink-200'
  | 'lg:placeholder-pink-300'
  | 'lg:placeholder-pink-400'
  | 'lg:placeholder-pink-500'
  | 'lg:placeholder-pink-600'
  | 'lg:placeholder-pink-700'
  | 'lg:placeholder-pink-800'
  | 'lg:placeholder-pink-900'
  | 'lg:focus:placeholder-transparent'
  | 'lg:focus:placeholder-white'
  | 'lg:focus:placeholder-black'
  | 'lg:focus:placeholder-gray-50'
  | 'lg:focus:placeholder-gray-100'
  | 'lg:focus:placeholder-gray-200'
  | 'lg:focus:placeholder-gray-300'
  | 'lg:focus:placeholder-gray-400'
  | 'lg:focus:placeholder-gray-500'
  | 'lg:focus:placeholder-gray-600'
  | 'lg:focus:placeholder-gray-700'
  | 'lg:focus:placeholder-gray-800'
  | 'lg:focus:placeholder-gray-900'
  | 'lg:focus:placeholder-cool-gray-50'
  | 'lg:focus:placeholder-cool-gray-100'
  | 'lg:focus:placeholder-cool-gray-200'
  | 'lg:focus:placeholder-cool-gray-300'
  | 'lg:focus:placeholder-cool-gray-400'
  | 'lg:focus:placeholder-cool-gray-500'
  | 'lg:focus:placeholder-cool-gray-600'
  | 'lg:focus:placeholder-cool-gray-700'
  | 'lg:focus:placeholder-cool-gray-800'
  | 'lg:focus:placeholder-cool-gray-900'
  | 'lg:focus:placeholder-red-50'
  | 'lg:focus:placeholder-red-100'
  | 'lg:focus:placeholder-red-200'
  | 'lg:focus:placeholder-red-300'
  | 'lg:focus:placeholder-red-400'
  | 'lg:focus:placeholder-red-500'
  | 'lg:focus:placeholder-red-600'
  | 'lg:focus:placeholder-red-700'
  | 'lg:focus:placeholder-red-800'
  | 'lg:focus:placeholder-red-900'
  | 'lg:focus:placeholder-orange-50'
  | 'lg:focus:placeholder-orange-100'
  | 'lg:focus:placeholder-orange-200'
  | 'lg:focus:placeholder-orange-300'
  | 'lg:focus:placeholder-orange-400'
  | 'lg:focus:placeholder-orange-500'
  | 'lg:focus:placeholder-orange-600'
  | 'lg:focus:placeholder-orange-700'
  | 'lg:focus:placeholder-orange-800'
  | 'lg:focus:placeholder-orange-900'
  | 'lg:focus:placeholder-yellow-50'
  | 'lg:focus:placeholder-yellow-100'
  | 'lg:focus:placeholder-yellow-200'
  | 'lg:focus:placeholder-yellow-300'
  | 'lg:focus:placeholder-yellow-400'
  | 'lg:focus:placeholder-yellow-500'
  | 'lg:focus:placeholder-yellow-600'
  | 'lg:focus:placeholder-yellow-700'
  | 'lg:focus:placeholder-yellow-800'
  | 'lg:focus:placeholder-yellow-900'
  | 'lg:focus:placeholder-green-50'
  | 'lg:focus:placeholder-green-100'
  | 'lg:focus:placeholder-green-200'
  | 'lg:focus:placeholder-green-300'
  | 'lg:focus:placeholder-green-400'
  | 'lg:focus:placeholder-green-500'
  | 'lg:focus:placeholder-green-600'
  | 'lg:focus:placeholder-green-700'
  | 'lg:focus:placeholder-green-800'
  | 'lg:focus:placeholder-green-900'
  | 'lg:focus:placeholder-teal-50'
  | 'lg:focus:placeholder-teal-100'
  | 'lg:focus:placeholder-teal-200'
  | 'lg:focus:placeholder-teal-300'
  | 'lg:focus:placeholder-teal-400'
  | 'lg:focus:placeholder-teal-500'
  | 'lg:focus:placeholder-teal-600'
  | 'lg:focus:placeholder-teal-700'
  | 'lg:focus:placeholder-teal-800'
  | 'lg:focus:placeholder-teal-900'
  | 'lg:focus:placeholder-blue-50'
  | 'lg:focus:placeholder-blue-100'
  | 'lg:focus:placeholder-blue-200'
  | 'lg:focus:placeholder-blue-300'
  | 'lg:focus:placeholder-blue-400'
  | 'lg:focus:placeholder-blue-500'
  | 'lg:focus:placeholder-blue-600'
  | 'lg:focus:placeholder-blue-700'
  | 'lg:focus:placeholder-blue-800'
  | 'lg:focus:placeholder-blue-900'
  | 'lg:focus:placeholder-indigo-50'
  | 'lg:focus:placeholder-indigo-100'
  | 'lg:focus:placeholder-indigo-200'
  | 'lg:focus:placeholder-indigo-300'
  | 'lg:focus:placeholder-indigo-400'
  | 'lg:focus:placeholder-indigo-500'
  | 'lg:focus:placeholder-indigo-600'
  | 'lg:focus:placeholder-indigo-700'
  | 'lg:focus:placeholder-indigo-800'
  | 'lg:focus:placeholder-indigo-900'
  | 'lg:focus:placeholder-purple-50'
  | 'lg:focus:placeholder-purple-100'
  | 'lg:focus:placeholder-purple-200'
  | 'lg:focus:placeholder-purple-300'
  | 'lg:focus:placeholder-purple-400'
  | 'lg:focus:placeholder-purple-500'
  | 'lg:focus:placeholder-purple-600'
  | 'lg:focus:placeholder-purple-700'
  | 'lg:focus:placeholder-purple-800'
  | 'lg:focus:placeholder-purple-900'
  | 'lg:focus:placeholder-pink-50'
  | 'lg:focus:placeholder-pink-100'
  | 'lg:focus:placeholder-pink-200'
  | 'lg:focus:placeholder-pink-300'
  | 'lg:focus:placeholder-pink-400'
  | 'lg:focus:placeholder-pink-500'
  | 'lg:focus:placeholder-pink-600'
  | 'lg:focus:placeholder-pink-700'
  | 'lg:focus:placeholder-pink-800'
  | 'lg:focus:placeholder-pink-900'
  | 'lg:pointer-events-none'
  | 'lg:pointer-events-auto'
  | 'lg:static'
  | 'lg:fixed'
  | 'lg:absolute'
  | 'lg:relative'
  | 'lg:sticky'
  | 'lg:inset-0'
  | 'lg:inset-1'
  | 'lg:inset-2'
  | 'lg:inset-3'
  | 'lg:inset-4'
  | 'lg:inset-5'
  | 'lg:inset-6'
  | 'lg:inset-7'
  | 'lg:inset-8'
  | 'lg:inset-9'
  | 'lg:inset-10'
  | 'lg:inset-11'
  | 'lg:inset-12'
  | 'lg:inset-13'
  | 'lg:inset-14'
  | 'lg:inset-15'
  | 'lg:inset-16'
  | 'lg:inset-20'
  | 'lg:inset-24'
  | 'lg:inset-28'
  | 'lg:inset-32'
  | 'lg:inset-36'
  | 'lg:inset-40'
  | 'lg:inset-48'
  | 'lg:inset-56'
  | 'lg:inset-60'
  | 'lg:inset-64'
  | 'lg:inset-72'
  | 'lg:inset-80'
  | 'lg:inset-96'
  | 'lg:inset-auto'
  | 'lg:inset-px'
  | 'lg:inset-0.5'
  | 'lg:inset-1.5'
  | 'lg:inset-2.5'
  | 'lg:inset-3.5'
  | 'lg:inset-1/2'
  | 'lg:inset-1/3'
  | 'lg:inset-2/3'
  | 'lg:inset-1/4'
  | 'lg:inset-2/4'
  | 'lg:inset-3/4'
  | 'lg:inset-1/5'
  | 'lg:inset-2/5'
  | 'lg:inset-3/5'
  | 'lg:inset-4/5'
  | 'lg:inset-1/6'
  | 'lg:inset-2/6'
  | 'lg:inset-3/6'
  | 'lg:inset-4/6'
  | 'lg:inset-5/6'
  | 'lg:inset-1/12'
  | 'lg:inset-2/12'
  | 'lg:inset-3/12'
  | 'lg:inset-4/12'
  | 'lg:inset-5/12'
  | 'lg:inset-6/12'
  | 'lg:inset-7/12'
  | 'lg:inset-8/12'
  | 'lg:inset-9/12'
  | 'lg:inset-10/12'
  | 'lg:inset-11/12'
  | 'lg:inset-full'
  | 'lg:inset-y-0'
  | 'lg:inset-x-0'
  | 'lg:inset-y-1'
  | 'lg:inset-x-1'
  | 'lg:inset-y-2'
  | 'lg:inset-x-2'
  | 'lg:inset-y-3'
  | 'lg:inset-x-3'
  | 'lg:inset-y-4'
  | 'lg:inset-x-4'
  | 'lg:inset-y-5'
  | 'lg:inset-x-5'
  | 'lg:inset-y-6'
  | 'lg:inset-x-6'
  | 'lg:inset-y-7'
  | 'lg:inset-x-7'
  | 'lg:inset-y-8'
  | 'lg:inset-x-8'
  | 'lg:inset-y-9'
  | 'lg:inset-x-9'
  | 'lg:inset-y-10'
  | 'lg:inset-x-10'
  | 'lg:inset-y-11'
  | 'lg:inset-x-11'
  | 'lg:inset-y-12'
  | 'lg:inset-x-12'
  | 'lg:inset-y-13'
  | 'lg:inset-x-13'
  | 'lg:inset-y-14'
  | 'lg:inset-x-14'
  | 'lg:inset-y-15'
  | 'lg:inset-x-15'
  | 'lg:inset-y-16'
  | 'lg:inset-x-16'
  | 'lg:inset-y-20'
  | 'lg:inset-x-20'
  | 'lg:inset-y-24'
  | 'lg:inset-x-24'
  | 'lg:inset-y-28'
  | 'lg:inset-x-28'
  | 'lg:inset-y-32'
  | 'lg:inset-x-32'
  | 'lg:inset-y-36'
  | 'lg:inset-x-36'
  | 'lg:inset-y-40'
  | 'lg:inset-x-40'
  | 'lg:inset-y-48'
  | 'lg:inset-x-48'
  | 'lg:inset-y-56'
  | 'lg:inset-x-56'
  | 'lg:inset-y-60'
  | 'lg:inset-x-60'
  | 'lg:inset-y-64'
  | 'lg:inset-x-64'
  | 'lg:inset-y-72'
  | 'lg:inset-x-72'
  | 'lg:inset-y-80'
  | 'lg:inset-x-80'
  | 'lg:inset-y-96'
  | 'lg:inset-x-96'
  | 'lg:inset-y-auto'
  | 'lg:inset-x-auto'
  | 'lg:inset-y-px'
  | 'lg:inset-x-px'
  | 'lg:inset-y-0.5'
  | 'lg:inset-x-0.5'
  | 'lg:inset-y-1.5'
  | 'lg:inset-x-1.5'
  | 'lg:inset-y-2.5'
  | 'lg:inset-x-2.5'
  | 'lg:inset-y-3.5'
  | 'lg:inset-x-3.5'
  | 'lg:inset-y-1/2'
  | 'lg:inset-x-1/2'
  | 'lg:inset-y-1/3'
  | 'lg:inset-x-1/3'
  | 'lg:inset-y-2/3'
  | 'lg:inset-x-2/3'
  | 'lg:inset-y-1/4'
  | 'lg:inset-x-1/4'
  | 'lg:inset-y-2/4'
  | 'lg:inset-x-2/4'
  | 'lg:inset-y-3/4'
  | 'lg:inset-x-3/4'
  | 'lg:inset-y-1/5'
  | 'lg:inset-x-1/5'
  | 'lg:inset-y-2/5'
  | 'lg:inset-x-2/5'
  | 'lg:inset-y-3/5'
  | 'lg:inset-x-3/5'
  | 'lg:inset-y-4/5'
  | 'lg:inset-x-4/5'
  | 'lg:inset-y-1/6'
  | 'lg:inset-x-1/6'
  | 'lg:inset-y-2/6'
  | 'lg:inset-x-2/6'
  | 'lg:inset-y-3/6'
  | 'lg:inset-x-3/6'
  | 'lg:inset-y-4/6'
  | 'lg:inset-x-4/6'
  | 'lg:inset-y-5/6'
  | 'lg:inset-x-5/6'
  | 'lg:inset-y-1/12'
  | 'lg:inset-x-1/12'
  | 'lg:inset-y-2/12'
  | 'lg:inset-x-2/12'
  | 'lg:inset-y-3/12'
  | 'lg:inset-x-3/12'
  | 'lg:inset-y-4/12'
  | 'lg:inset-x-4/12'
  | 'lg:inset-y-5/12'
  | 'lg:inset-x-5/12'
  | 'lg:inset-y-6/12'
  | 'lg:inset-x-6/12'
  | 'lg:inset-y-7/12'
  | 'lg:inset-x-7/12'
  | 'lg:inset-y-8/12'
  | 'lg:inset-x-8/12'
  | 'lg:inset-y-9/12'
  | 'lg:inset-x-9/12'
  | 'lg:inset-y-10/12'
  | 'lg:inset-x-10/12'
  | 'lg:inset-y-11/12'
  | 'lg:inset-x-11/12'
  | 'lg:inset-y-full'
  | 'lg:inset-x-full'
  | 'lg:top-0'
  | 'lg:right-0'
  | 'lg:bottom-0'
  | 'lg:left-0'
  | 'lg:top-1'
  | 'lg:right-1'
  | 'lg:bottom-1'
  | 'lg:left-1'
  | 'lg:top-2'
  | 'lg:right-2'
  | 'lg:bottom-2'
  | 'lg:left-2'
  | 'lg:top-3'
  | 'lg:right-3'
  | 'lg:bottom-3'
  | 'lg:left-3'
  | 'lg:top-4'
  | 'lg:right-4'
  | 'lg:bottom-4'
  | 'lg:left-4'
  | 'lg:top-5'
  | 'lg:right-5'
  | 'lg:bottom-5'
  | 'lg:left-5'
  | 'lg:top-6'
  | 'lg:right-6'
  | 'lg:bottom-6'
  | 'lg:left-6'
  | 'lg:top-7'
  | 'lg:right-7'
  | 'lg:bottom-7'
  | 'lg:left-7'
  | 'lg:top-8'
  | 'lg:right-8'
  | 'lg:bottom-8'
  | 'lg:left-8'
  | 'lg:top-9'
  | 'lg:right-9'
  | 'lg:bottom-9'
  | 'lg:left-9'
  | 'lg:top-10'
  | 'lg:right-10'
  | 'lg:bottom-10'
  | 'lg:left-10'
  | 'lg:top-11'
  | 'lg:right-11'
  | 'lg:bottom-11'
  | 'lg:left-11'
  | 'lg:top-12'
  | 'lg:right-12'
  | 'lg:bottom-12'
  | 'lg:left-12'
  | 'lg:top-13'
  | 'lg:right-13'
  | 'lg:bottom-13'
  | 'lg:left-13'
  | 'lg:top-14'
  | 'lg:right-14'
  | 'lg:bottom-14'
  | 'lg:left-14'
  | 'lg:top-15'
  | 'lg:right-15'
  | 'lg:bottom-15'
  | 'lg:left-15'
  | 'lg:top-16'
  | 'lg:right-16'
  | 'lg:bottom-16'
  | 'lg:left-16'
  | 'lg:top-20'
  | 'lg:right-20'
  | 'lg:bottom-20'
  | 'lg:left-20'
  | 'lg:top-24'
  | 'lg:right-24'
  | 'lg:bottom-24'
  | 'lg:left-24'
  | 'lg:top-28'
  | 'lg:right-28'
  | 'lg:bottom-28'
  | 'lg:left-28'
  | 'lg:top-32'
  | 'lg:right-32'
  | 'lg:bottom-32'
  | 'lg:left-32'
  | 'lg:top-36'
  | 'lg:right-36'
  | 'lg:bottom-36'
  | 'lg:left-36'
  | 'lg:top-40'
  | 'lg:right-40'
  | 'lg:bottom-40'
  | 'lg:left-40'
  | 'lg:top-48'
  | 'lg:right-48'
  | 'lg:bottom-48'
  | 'lg:left-48'
  | 'lg:top-56'
  | 'lg:right-56'
  | 'lg:bottom-56'
  | 'lg:left-56'
  | 'lg:top-60'
  | 'lg:right-60'
  | 'lg:bottom-60'
  | 'lg:left-60'
  | 'lg:top-64'
  | 'lg:right-64'
  | 'lg:bottom-64'
  | 'lg:left-64'
  | 'lg:top-72'
  | 'lg:right-72'
  | 'lg:bottom-72'
  | 'lg:left-72'
  | 'lg:top-80'
  | 'lg:right-80'
  | 'lg:bottom-80'
  | 'lg:left-80'
  | 'lg:top-96'
  | 'lg:right-96'
  | 'lg:bottom-96'
  | 'lg:left-96'
  | 'lg:top-auto'
  | 'lg:right-auto'
  | 'lg:bottom-auto'
  | 'lg:left-auto'
  | 'lg:top-px'
  | 'lg:right-px'
  | 'lg:bottom-px'
  | 'lg:left-px'
  | 'lg:top-0.5'
  | 'lg:right-0.5'
  | 'lg:bottom-0.5'
  | 'lg:left-0.5'
  | 'lg:top-1.5'
  | 'lg:right-1.5'
  | 'lg:bottom-1.5'
  | 'lg:left-1.5'
  | 'lg:top-2.5'
  | 'lg:right-2.5'
  | 'lg:bottom-2.5'
  | 'lg:left-2.5'
  | 'lg:top-3.5'
  | 'lg:right-3.5'
  | 'lg:bottom-3.5'
  | 'lg:left-3.5'
  | 'lg:top-1/2'
  | 'lg:right-1/2'
  | 'lg:bottom-1/2'
  | 'lg:left-1/2'
  | 'lg:top-1/3'
  | 'lg:right-1/3'
  | 'lg:bottom-1/3'
  | 'lg:left-1/3'
  | 'lg:top-2/3'
  | 'lg:right-2/3'
  | 'lg:bottom-2/3'
  | 'lg:left-2/3'
  | 'lg:top-1/4'
  | 'lg:right-1/4'
  | 'lg:bottom-1/4'
  | 'lg:left-1/4'
  | 'lg:top-2/4'
  | 'lg:right-2/4'
  | 'lg:bottom-2/4'
  | 'lg:left-2/4'
  | 'lg:top-3/4'
  | 'lg:right-3/4'
  | 'lg:bottom-3/4'
  | 'lg:left-3/4'
  | 'lg:top-1/5'
  | 'lg:right-1/5'
  | 'lg:bottom-1/5'
  | 'lg:left-1/5'
  | 'lg:top-2/5'
  | 'lg:right-2/5'
  | 'lg:bottom-2/5'
  | 'lg:left-2/5'
  | 'lg:top-3/5'
  | 'lg:right-3/5'
  | 'lg:bottom-3/5'
  | 'lg:left-3/5'
  | 'lg:top-4/5'
  | 'lg:right-4/5'
  | 'lg:bottom-4/5'
  | 'lg:left-4/5'
  | 'lg:top-1/6'
  | 'lg:right-1/6'
  | 'lg:bottom-1/6'
  | 'lg:left-1/6'
  | 'lg:top-2/6'
  | 'lg:right-2/6'
  | 'lg:bottom-2/6'
  | 'lg:left-2/6'
  | 'lg:top-3/6'
  | 'lg:right-3/6'
  | 'lg:bottom-3/6'
  | 'lg:left-3/6'
  | 'lg:top-4/6'
  | 'lg:right-4/6'
  | 'lg:bottom-4/6'
  | 'lg:left-4/6'
  | 'lg:top-5/6'
  | 'lg:right-5/6'
  | 'lg:bottom-5/6'
  | 'lg:left-5/6'
  | 'lg:top-1/12'
  | 'lg:right-1/12'
  | 'lg:bottom-1/12'
  | 'lg:left-1/12'
  | 'lg:top-2/12'
  | 'lg:right-2/12'
  | 'lg:bottom-2/12'
  | 'lg:left-2/12'
  | 'lg:top-3/12'
  | 'lg:right-3/12'
  | 'lg:bottom-3/12'
  | 'lg:left-3/12'
  | 'lg:top-4/12'
  | 'lg:right-4/12'
  | 'lg:bottom-4/12'
  | 'lg:left-4/12'
  | 'lg:top-5/12'
  | 'lg:right-5/12'
  | 'lg:bottom-5/12'
  | 'lg:left-5/12'
  | 'lg:top-6/12'
  | 'lg:right-6/12'
  | 'lg:bottom-6/12'
  | 'lg:left-6/12'
  | 'lg:top-7/12'
  | 'lg:right-7/12'
  | 'lg:bottom-7/12'
  | 'lg:left-7/12'
  | 'lg:top-8/12'
  | 'lg:right-8/12'
  | 'lg:bottom-8/12'
  | 'lg:left-8/12'
  | 'lg:top-9/12'
  | 'lg:right-9/12'
  | 'lg:bottom-9/12'
  | 'lg:left-9/12'
  | 'lg:top-10/12'
  | 'lg:right-10/12'
  | 'lg:bottom-10/12'
  | 'lg:left-10/12'
  | 'lg:top-11/12'
  | 'lg:right-11/12'
  | 'lg:bottom-11/12'
  | 'lg:left-11/12'
  | 'lg:top-full'
  | 'lg:right-full'
  | 'lg:bottom-full'
  | 'lg:left-full'
  | 'lg:resize-none'
  | 'lg:resize-y'
  | 'lg:resize-x'
  | 'lg:resize'
  | 'lg:shadow-xs'
  | 'lg:shadow-sm'
  | 'lg:shadow'
  | 'lg:shadow-md'
  | 'lg:shadow-lg'
  | 'lg:shadow-xl'
  | 'lg:shadow-2xl'
  | 'lg:shadow-inner'
  | 'lg:shadow-outline'
  | 'lg:shadow-none'
  | 'lg:shadow-solid'
  | 'lg:shadow-outline-gray'
  | 'lg:shadow-outline-blue'
  | 'lg:shadow-outline-teal'
  | 'lg:shadow-outline-green'
  | 'lg:shadow-outline-yellow'
  | 'lg:shadow-outline-orange'
  | 'lg:shadow-outline-red'
  | 'lg:shadow-outline-pink'
  | 'lg:shadow-outline-purple'
  | 'lg:shadow-outline-indigo'
  | 'lg:hover:shadow-xs'
  | 'lg:hover:shadow-sm'
  | 'lg:hover:shadow'
  | 'lg:hover:shadow-md'
  | 'lg:hover:shadow-lg'
  | 'lg:hover:shadow-xl'
  | 'lg:hover:shadow-2xl'
  | 'lg:hover:shadow-inner'
  | 'lg:hover:shadow-outline'
  | 'lg:hover:shadow-none'
  | 'lg:hover:shadow-solid'
  | 'lg:hover:shadow-outline-gray'
  | 'lg:hover:shadow-outline-blue'
  | 'lg:hover:shadow-outline-teal'
  | 'lg:hover:shadow-outline-green'
  | 'lg:hover:shadow-outline-yellow'
  | 'lg:hover:shadow-outline-orange'
  | 'lg:hover:shadow-outline-red'
  | 'lg:hover:shadow-outline-pink'
  | 'lg:hover:shadow-outline-purple'
  | 'lg:hover:shadow-outline-indigo'
  | 'lg:focus:shadow-xs'
  | 'lg:focus:shadow-sm'
  | 'lg:focus:shadow'
  | 'lg:focus:shadow-md'
  | 'lg:focus:shadow-lg'
  | 'lg:focus:shadow-xl'
  | 'lg:focus:shadow-2xl'
  | 'lg:focus:shadow-inner'
  | 'lg:focus:shadow-outline'
  | 'lg:focus:shadow-none'
  | 'lg:focus:shadow-solid'
  | 'lg:focus:shadow-outline-gray'
  | 'lg:focus:shadow-outline-blue'
  | 'lg:focus:shadow-outline-teal'
  | 'lg:focus:shadow-outline-green'
  | 'lg:focus:shadow-outline-yellow'
  | 'lg:focus:shadow-outline-orange'
  | 'lg:focus:shadow-outline-red'
  | 'lg:focus:shadow-outline-pink'
  | 'lg:focus:shadow-outline-purple'
  | 'lg:focus:shadow-outline-indigo'
  | 'lg:fill-current'
  | 'lg:stroke-current'
  | 'lg:stroke-0'
  | 'lg:stroke-1'
  | 'lg:stroke-2'
  | 'lg:table-auto'
  | 'lg:table-fixed'
  | 'lg:text-left'
  | 'lg:text-center'
  | 'lg:text-right'
  | 'lg:text-justify'
  | 'lg:text-transparent'
  | 'lg:text-white'
  | 'lg:text-black'
  | 'lg:text-gray-50'
  | 'lg:text-gray-100'
  | 'lg:text-gray-200'
  | 'lg:text-gray-300'
  | 'lg:text-gray-400'
  | 'lg:text-gray-500'
  | 'lg:text-gray-600'
  | 'lg:text-gray-700'
  | 'lg:text-gray-800'
  | 'lg:text-gray-900'
  | 'lg:text-cool-gray-50'
  | 'lg:text-cool-gray-100'
  | 'lg:text-cool-gray-200'
  | 'lg:text-cool-gray-300'
  | 'lg:text-cool-gray-400'
  | 'lg:text-cool-gray-500'
  | 'lg:text-cool-gray-600'
  | 'lg:text-cool-gray-700'
  | 'lg:text-cool-gray-800'
  | 'lg:text-cool-gray-900'
  | 'lg:text-red-50'
  | 'lg:text-red-100'
  | 'lg:text-red-200'
  | 'lg:text-red-300'
  | 'lg:text-red-400'
  | 'lg:text-red-500'
  | 'lg:text-red-600'
  | 'lg:text-red-700'
  | 'lg:text-red-800'
  | 'lg:text-red-900'
  | 'lg:text-orange-50'
  | 'lg:text-orange-100'
  | 'lg:text-orange-200'
  | 'lg:text-orange-300'
  | 'lg:text-orange-400'
  | 'lg:text-orange-500'
  | 'lg:text-orange-600'
  | 'lg:text-orange-700'
  | 'lg:text-orange-800'
  | 'lg:text-orange-900'
  | 'lg:text-yellow-50'
  | 'lg:text-yellow-100'
  | 'lg:text-yellow-200'
  | 'lg:text-yellow-300'
  | 'lg:text-yellow-400'
  | 'lg:text-yellow-500'
  | 'lg:text-yellow-600'
  | 'lg:text-yellow-700'
  | 'lg:text-yellow-800'
  | 'lg:text-yellow-900'
  | 'lg:text-green-50'
  | 'lg:text-green-100'
  | 'lg:text-green-200'
  | 'lg:text-green-300'
  | 'lg:text-green-400'
  | 'lg:text-green-500'
  | 'lg:text-green-600'
  | 'lg:text-green-700'
  | 'lg:text-green-800'
  | 'lg:text-green-900'
  | 'lg:text-teal-50'
  | 'lg:text-teal-100'
  | 'lg:text-teal-200'
  | 'lg:text-teal-300'
  | 'lg:text-teal-400'
  | 'lg:text-teal-500'
  | 'lg:text-teal-600'
  | 'lg:text-teal-700'
  | 'lg:text-teal-800'
  | 'lg:text-teal-900'
  | 'lg:text-blue-50'
  | 'lg:text-blue-100'
  | 'lg:text-blue-200'
  | 'lg:text-blue-300'
  | 'lg:text-blue-400'
  | 'lg:text-blue-500'
  | 'lg:text-blue-600'
  | 'lg:text-blue-700'
  | 'lg:text-blue-800'
  | 'lg:text-blue-900'
  | 'lg:text-indigo-50'
  | 'lg:text-indigo-100'
  | 'lg:text-indigo-200'
  | 'lg:text-indigo-300'
  | 'lg:text-indigo-400'
  | 'lg:text-indigo-500'
  | 'lg:text-indigo-600'
  | 'lg:text-indigo-700'
  | 'lg:text-indigo-800'
  | 'lg:text-indigo-900'
  | 'lg:text-purple-50'
  | 'lg:text-purple-100'
  | 'lg:text-purple-200'
  | 'lg:text-purple-300'
  | 'lg:text-purple-400'
  | 'lg:text-purple-500'
  | 'lg:text-purple-600'
  | 'lg:text-purple-700'
  | 'lg:text-purple-800'
  | 'lg:text-purple-900'
  | 'lg:text-pink-50'
  | 'lg:text-pink-100'
  | 'lg:text-pink-200'
  | 'lg:text-pink-300'
  | 'lg:text-pink-400'
  | 'lg:text-pink-500'
  | 'lg:text-pink-600'
  | 'lg:text-pink-700'
  | 'lg:text-pink-800'
  | 'lg:text-pink-900'
  | 'lg:group-hover:text-transparent'
  | 'lg:group-hover:text-white'
  | 'lg:group-hover:text-black'
  | 'lg:group-hover:text-gray-50'
  | 'lg:group-hover:text-gray-100'
  | 'lg:group-hover:text-gray-200'
  | 'lg:group-hover:text-gray-300'
  | 'lg:group-hover:text-gray-400'
  | 'lg:group-hover:text-gray-500'
  | 'lg:group-hover:text-gray-600'
  | 'lg:group-hover:text-gray-700'
  | 'lg:group-hover:text-gray-800'
  | 'lg:group-hover:text-gray-900'
  | 'lg:group-hover:text-cool-gray-50'
  | 'lg:group-hover:text-cool-gray-100'
  | 'lg:group-hover:text-cool-gray-200'
  | 'lg:group-hover:text-cool-gray-300'
  | 'lg:group-hover:text-cool-gray-400'
  | 'lg:group-hover:text-cool-gray-500'
  | 'lg:group-hover:text-cool-gray-600'
  | 'lg:group-hover:text-cool-gray-700'
  | 'lg:group-hover:text-cool-gray-800'
  | 'lg:group-hover:text-cool-gray-900'
  | 'lg:group-hover:text-red-50'
  | 'lg:group-hover:text-red-100'
  | 'lg:group-hover:text-red-200'
  | 'lg:group-hover:text-red-300'
  | 'lg:group-hover:text-red-400'
  | 'lg:group-hover:text-red-500'
  | 'lg:group-hover:text-red-600'
  | 'lg:group-hover:text-red-700'
  | 'lg:group-hover:text-red-800'
  | 'lg:group-hover:text-red-900'
  | 'lg:group-hover:text-orange-50'
  | 'lg:group-hover:text-orange-100'
  | 'lg:group-hover:text-orange-200'
  | 'lg:group-hover:text-orange-300'
  | 'lg:group-hover:text-orange-400'
  | 'lg:group-hover:text-orange-500'
  | 'lg:group-hover:text-orange-600'
  | 'lg:group-hover:text-orange-700'
  | 'lg:group-hover:text-orange-800'
  | 'lg:group-hover:text-orange-900'
  | 'lg:group-hover:text-yellow-50'
  | 'lg:group-hover:text-yellow-100'
  | 'lg:group-hover:text-yellow-200'
  | 'lg:group-hover:text-yellow-300'
  | 'lg:group-hover:text-yellow-400'
  | 'lg:group-hover:text-yellow-500'
  | 'lg:group-hover:text-yellow-600'
  | 'lg:group-hover:text-yellow-700'
  | 'lg:group-hover:text-yellow-800'
  | 'lg:group-hover:text-yellow-900'
  | 'lg:group-hover:text-green-50'
  | 'lg:group-hover:text-green-100'
  | 'lg:group-hover:text-green-200'
  | 'lg:group-hover:text-green-300'
  | 'lg:group-hover:text-green-400'
  | 'lg:group-hover:text-green-500'
  | 'lg:group-hover:text-green-600'
  | 'lg:group-hover:text-green-700'
  | 'lg:group-hover:text-green-800'
  | 'lg:group-hover:text-green-900'
  | 'lg:group-hover:text-teal-50'
  | 'lg:group-hover:text-teal-100'
  | 'lg:group-hover:text-teal-200'
  | 'lg:group-hover:text-teal-300'
  | 'lg:group-hover:text-teal-400'
  | 'lg:group-hover:text-teal-500'
  | 'lg:group-hover:text-teal-600'
  | 'lg:group-hover:text-teal-700'
  | 'lg:group-hover:text-teal-800'
  | 'lg:group-hover:text-teal-900'
  | 'lg:group-hover:text-blue-50'
  | 'lg:group-hover:text-blue-100'
  | 'lg:group-hover:text-blue-200'
  | 'lg:group-hover:text-blue-300'
  | 'lg:group-hover:text-blue-400'
  | 'lg:group-hover:text-blue-500'
  | 'lg:group-hover:text-blue-600'
  | 'lg:group-hover:text-blue-700'
  | 'lg:group-hover:text-blue-800'
  | 'lg:group-hover:text-blue-900'
  | 'lg:group-hover:text-indigo-50'
  | 'lg:group-hover:text-indigo-100'
  | 'lg:group-hover:text-indigo-200'
  | 'lg:group-hover:text-indigo-300'
  | 'lg:group-hover:text-indigo-400'
  | 'lg:group-hover:text-indigo-500'
  | 'lg:group-hover:text-indigo-600'
  | 'lg:group-hover:text-indigo-700'
  | 'lg:group-hover:text-indigo-800'
  | 'lg:group-hover:text-indigo-900'
  | 'lg:group-hover:text-purple-50'
  | 'lg:group-hover:text-purple-100'
  | 'lg:group-hover:text-purple-200'
  | 'lg:group-hover:text-purple-300'
  | 'lg:group-hover:text-purple-400'
  | 'lg:group-hover:text-purple-500'
  | 'lg:group-hover:text-purple-600'
  | 'lg:group-hover:text-purple-700'
  | 'lg:group-hover:text-purple-800'
  | 'lg:group-hover:text-purple-900'
  | 'lg:group-hover:text-pink-50'
  | 'lg:group-hover:text-pink-100'
  | 'lg:group-hover:text-pink-200'
  | 'lg:group-hover:text-pink-300'
  | 'lg:group-hover:text-pink-400'
  | 'lg:group-hover:text-pink-500'
  | 'lg:group-hover:text-pink-600'
  | 'lg:group-hover:text-pink-700'
  | 'lg:group-hover:text-pink-800'
  | 'lg:group-hover:text-pink-900'
  | 'lg:group-focus:text-transparent'
  | 'lg:group-focus:text-white'
  | 'lg:group-focus:text-black'
  | 'lg:group-focus:text-gray-50'
  | 'lg:group-focus:text-gray-100'
  | 'lg:group-focus:text-gray-200'
  | 'lg:group-focus:text-gray-300'
  | 'lg:group-focus:text-gray-400'
  | 'lg:group-focus:text-gray-500'
  | 'lg:group-focus:text-gray-600'
  | 'lg:group-focus:text-gray-700'
  | 'lg:group-focus:text-gray-800'
  | 'lg:group-focus:text-gray-900'
  | 'lg:group-focus:text-cool-gray-50'
  | 'lg:group-focus:text-cool-gray-100'
  | 'lg:group-focus:text-cool-gray-200'
  | 'lg:group-focus:text-cool-gray-300'
  | 'lg:group-focus:text-cool-gray-400'
  | 'lg:group-focus:text-cool-gray-500'
  | 'lg:group-focus:text-cool-gray-600'
  | 'lg:group-focus:text-cool-gray-700'
  | 'lg:group-focus:text-cool-gray-800'
  | 'lg:group-focus:text-cool-gray-900'
  | 'lg:group-focus:text-red-50'
  | 'lg:group-focus:text-red-100'
  | 'lg:group-focus:text-red-200'
  | 'lg:group-focus:text-red-300'
  | 'lg:group-focus:text-red-400'
  | 'lg:group-focus:text-red-500'
  | 'lg:group-focus:text-red-600'
  | 'lg:group-focus:text-red-700'
  | 'lg:group-focus:text-red-800'
  | 'lg:group-focus:text-red-900'
  | 'lg:group-focus:text-orange-50'
  | 'lg:group-focus:text-orange-100'
  | 'lg:group-focus:text-orange-200'
  | 'lg:group-focus:text-orange-300'
  | 'lg:group-focus:text-orange-400'
  | 'lg:group-focus:text-orange-500'
  | 'lg:group-focus:text-orange-600'
  | 'lg:group-focus:text-orange-700'
  | 'lg:group-focus:text-orange-800'
  | 'lg:group-focus:text-orange-900'
  | 'lg:group-focus:text-yellow-50'
  | 'lg:group-focus:text-yellow-100'
  | 'lg:group-focus:text-yellow-200'
  | 'lg:group-focus:text-yellow-300'
  | 'lg:group-focus:text-yellow-400'
  | 'lg:group-focus:text-yellow-500'
  | 'lg:group-focus:text-yellow-600'
  | 'lg:group-focus:text-yellow-700'
  | 'lg:group-focus:text-yellow-800'
  | 'lg:group-focus:text-yellow-900'
  | 'lg:group-focus:text-green-50'
  | 'lg:group-focus:text-green-100'
  | 'lg:group-focus:text-green-200'
  | 'lg:group-focus:text-green-300'
  | 'lg:group-focus:text-green-400'
  | 'lg:group-focus:text-green-500'
  | 'lg:group-focus:text-green-600'
  | 'lg:group-focus:text-green-700'
  | 'lg:group-focus:text-green-800'
  | 'lg:group-focus:text-green-900'
  | 'lg:group-focus:text-teal-50'
  | 'lg:group-focus:text-teal-100'
  | 'lg:group-focus:text-teal-200'
  | 'lg:group-focus:text-teal-300'
  | 'lg:group-focus:text-teal-400'
  | 'lg:group-focus:text-teal-500'
  | 'lg:group-focus:text-teal-600'
  | 'lg:group-focus:text-teal-700'
  | 'lg:group-focus:text-teal-800'
  | 'lg:group-focus:text-teal-900'
  | 'lg:group-focus:text-blue-50'
  | 'lg:group-focus:text-blue-100'
  | 'lg:group-focus:text-blue-200'
  | 'lg:group-focus:text-blue-300'
  | 'lg:group-focus:text-blue-400'
  | 'lg:group-focus:text-blue-500'
  | 'lg:group-focus:text-blue-600'
  | 'lg:group-focus:text-blue-700'
  | 'lg:group-focus:text-blue-800'
  | 'lg:group-focus:text-blue-900'
  | 'lg:group-focus:text-indigo-50'
  | 'lg:group-focus:text-indigo-100'
  | 'lg:group-focus:text-indigo-200'
  | 'lg:group-focus:text-indigo-300'
  | 'lg:group-focus:text-indigo-400'
  | 'lg:group-focus:text-indigo-500'
  | 'lg:group-focus:text-indigo-600'
  | 'lg:group-focus:text-indigo-700'
  | 'lg:group-focus:text-indigo-800'
  | 'lg:group-focus:text-indigo-900'
  | 'lg:group-focus:text-purple-50'
  | 'lg:group-focus:text-purple-100'
  | 'lg:group-focus:text-purple-200'
  | 'lg:group-focus:text-purple-300'
  | 'lg:group-focus:text-purple-400'
  | 'lg:group-focus:text-purple-500'
  | 'lg:group-focus:text-purple-600'
  | 'lg:group-focus:text-purple-700'
  | 'lg:group-focus:text-purple-800'
  | 'lg:group-focus:text-purple-900'
  | 'lg:group-focus:text-pink-50'
  | 'lg:group-focus:text-pink-100'
  | 'lg:group-focus:text-pink-200'
  | 'lg:group-focus:text-pink-300'
  | 'lg:group-focus:text-pink-400'
  | 'lg:group-focus:text-pink-500'
  | 'lg:group-focus:text-pink-600'
  | 'lg:group-focus:text-pink-700'
  | 'lg:group-focus:text-pink-800'
  | 'lg:group-focus:text-pink-900'
  | 'lg:hover:text-transparent'
  | 'lg:hover:text-white'
  | 'lg:hover:text-black'
  | 'lg:hover:text-gray-50'
  | 'lg:hover:text-gray-100'
  | 'lg:hover:text-gray-200'
  | 'lg:hover:text-gray-300'
  | 'lg:hover:text-gray-400'
  | 'lg:hover:text-gray-500'
  | 'lg:hover:text-gray-600'
  | 'lg:hover:text-gray-700'
  | 'lg:hover:text-gray-800'
  | 'lg:hover:text-gray-900'
  | 'lg:hover:text-cool-gray-50'
  | 'lg:hover:text-cool-gray-100'
  | 'lg:hover:text-cool-gray-200'
  | 'lg:hover:text-cool-gray-300'
  | 'lg:hover:text-cool-gray-400'
  | 'lg:hover:text-cool-gray-500'
  | 'lg:hover:text-cool-gray-600'
  | 'lg:hover:text-cool-gray-700'
  | 'lg:hover:text-cool-gray-800'
  | 'lg:hover:text-cool-gray-900'
  | 'lg:hover:text-red-50'
  | 'lg:hover:text-red-100'
  | 'lg:hover:text-red-200'
  | 'lg:hover:text-red-300'
  | 'lg:hover:text-red-400'
  | 'lg:hover:text-red-500'
  | 'lg:hover:text-red-600'
  | 'lg:hover:text-red-700'
  | 'lg:hover:text-red-800'
  | 'lg:hover:text-red-900'
  | 'lg:hover:text-orange-50'
  | 'lg:hover:text-orange-100'
  | 'lg:hover:text-orange-200'
  | 'lg:hover:text-orange-300'
  | 'lg:hover:text-orange-400'
  | 'lg:hover:text-orange-500'
  | 'lg:hover:text-orange-600'
  | 'lg:hover:text-orange-700'
  | 'lg:hover:text-orange-800'
  | 'lg:hover:text-orange-900'
  | 'lg:hover:text-yellow-50'
  | 'lg:hover:text-yellow-100'
  | 'lg:hover:text-yellow-200'
  | 'lg:hover:text-yellow-300'
  | 'lg:hover:text-yellow-400'
  | 'lg:hover:text-yellow-500'
  | 'lg:hover:text-yellow-600'
  | 'lg:hover:text-yellow-700'
  | 'lg:hover:text-yellow-800'
  | 'lg:hover:text-yellow-900'
  | 'lg:hover:text-green-50'
  | 'lg:hover:text-green-100'
  | 'lg:hover:text-green-200'
  | 'lg:hover:text-green-300'
  | 'lg:hover:text-green-400'
  | 'lg:hover:text-green-500'
  | 'lg:hover:text-green-600'
  | 'lg:hover:text-green-700'
  | 'lg:hover:text-green-800'
  | 'lg:hover:text-green-900'
  | 'lg:hover:text-teal-50'
  | 'lg:hover:text-teal-100'
  | 'lg:hover:text-teal-200'
  | 'lg:hover:text-teal-300'
  | 'lg:hover:text-teal-400'
  | 'lg:hover:text-teal-500'
  | 'lg:hover:text-teal-600'
  | 'lg:hover:text-teal-700'
  | 'lg:hover:text-teal-800'
  | 'lg:hover:text-teal-900'
  | 'lg:hover:text-blue-50'
  | 'lg:hover:text-blue-100'
  | 'lg:hover:text-blue-200'
  | 'lg:hover:text-blue-300'
  | 'lg:hover:text-blue-400'
  | 'lg:hover:text-blue-500'
  | 'lg:hover:text-blue-600'
  | 'lg:hover:text-blue-700'
  | 'lg:hover:text-blue-800'
  | 'lg:hover:text-blue-900'
  | 'lg:hover:text-indigo-50'
  | 'lg:hover:text-indigo-100'
  | 'lg:hover:text-indigo-200'
  | 'lg:hover:text-indigo-300'
  | 'lg:hover:text-indigo-400'
  | 'lg:hover:text-indigo-500'
  | 'lg:hover:text-indigo-600'
  | 'lg:hover:text-indigo-700'
  | 'lg:hover:text-indigo-800'
  | 'lg:hover:text-indigo-900'
  | 'lg:hover:text-purple-50'
  | 'lg:hover:text-purple-100'
  | 'lg:hover:text-purple-200'
  | 'lg:hover:text-purple-300'
  | 'lg:hover:text-purple-400'
  | 'lg:hover:text-purple-500'
  | 'lg:hover:text-purple-600'
  | 'lg:hover:text-purple-700'
  | 'lg:hover:text-purple-800'
  | 'lg:hover:text-purple-900'
  | 'lg:hover:text-pink-50'
  | 'lg:hover:text-pink-100'
  | 'lg:hover:text-pink-200'
  | 'lg:hover:text-pink-300'
  | 'lg:hover:text-pink-400'
  | 'lg:hover:text-pink-500'
  | 'lg:hover:text-pink-600'
  | 'lg:hover:text-pink-700'
  | 'lg:hover:text-pink-800'
  | 'lg:hover:text-pink-900'
  | 'lg:focus-within:text-transparent'
  | 'lg:focus-within:text-white'
  | 'lg:focus-within:text-black'
  | 'lg:focus-within:text-gray-50'
  | 'lg:focus-within:text-gray-100'
  | 'lg:focus-within:text-gray-200'
  | 'lg:focus-within:text-gray-300'
  | 'lg:focus-within:text-gray-400'
  | 'lg:focus-within:text-gray-500'
  | 'lg:focus-within:text-gray-600'
  | 'lg:focus-within:text-gray-700'
  | 'lg:focus-within:text-gray-800'
  | 'lg:focus-within:text-gray-900'
  | 'lg:focus-within:text-cool-gray-50'
  | 'lg:focus-within:text-cool-gray-100'
  | 'lg:focus-within:text-cool-gray-200'
  | 'lg:focus-within:text-cool-gray-300'
  | 'lg:focus-within:text-cool-gray-400'
  | 'lg:focus-within:text-cool-gray-500'
  | 'lg:focus-within:text-cool-gray-600'
  | 'lg:focus-within:text-cool-gray-700'
  | 'lg:focus-within:text-cool-gray-800'
  | 'lg:focus-within:text-cool-gray-900'
  | 'lg:focus-within:text-red-50'
  | 'lg:focus-within:text-red-100'
  | 'lg:focus-within:text-red-200'
  | 'lg:focus-within:text-red-300'
  | 'lg:focus-within:text-red-400'
  | 'lg:focus-within:text-red-500'
  | 'lg:focus-within:text-red-600'
  | 'lg:focus-within:text-red-700'
  | 'lg:focus-within:text-red-800'
  | 'lg:focus-within:text-red-900'
  | 'lg:focus-within:text-orange-50'
  | 'lg:focus-within:text-orange-100'
  | 'lg:focus-within:text-orange-200'
  | 'lg:focus-within:text-orange-300'
  | 'lg:focus-within:text-orange-400'
  | 'lg:focus-within:text-orange-500'
  | 'lg:focus-within:text-orange-600'
  | 'lg:focus-within:text-orange-700'
  | 'lg:focus-within:text-orange-800'
  | 'lg:focus-within:text-orange-900'
  | 'lg:focus-within:text-yellow-50'
  | 'lg:focus-within:text-yellow-100'
  | 'lg:focus-within:text-yellow-200'
  | 'lg:focus-within:text-yellow-300'
  | 'lg:focus-within:text-yellow-400'
  | 'lg:focus-within:text-yellow-500'
  | 'lg:focus-within:text-yellow-600'
  | 'lg:focus-within:text-yellow-700'
  | 'lg:focus-within:text-yellow-800'
  | 'lg:focus-within:text-yellow-900'
  | 'lg:focus-within:text-green-50'
  | 'lg:focus-within:text-green-100'
  | 'lg:focus-within:text-green-200'
  | 'lg:focus-within:text-green-300'
  | 'lg:focus-within:text-green-400'
  | 'lg:focus-within:text-green-500'
  | 'lg:focus-within:text-green-600'
  | 'lg:focus-within:text-green-700'
  | 'lg:focus-within:text-green-800'
  | 'lg:focus-within:text-green-900'
  | 'lg:focus-within:text-teal-50'
  | 'lg:focus-within:text-teal-100'
  | 'lg:focus-within:text-teal-200'
  | 'lg:focus-within:text-teal-300'
  | 'lg:focus-within:text-teal-400'
  | 'lg:focus-within:text-teal-500'
  | 'lg:focus-within:text-teal-600'
  | 'lg:focus-within:text-teal-700'
  | 'lg:focus-within:text-teal-800'
  | 'lg:focus-within:text-teal-900'
  | 'lg:focus-within:text-blue-50'
  | 'lg:focus-within:text-blue-100'
  | 'lg:focus-within:text-blue-200'
  | 'lg:focus-within:text-blue-300'
  | 'lg:focus-within:text-blue-400'
  | 'lg:focus-within:text-blue-500'
  | 'lg:focus-within:text-blue-600'
  | 'lg:focus-within:text-blue-700'
  | 'lg:focus-within:text-blue-800'
  | 'lg:focus-within:text-blue-900'
  | 'lg:focus-within:text-indigo-50'
  | 'lg:focus-within:text-indigo-100'
  | 'lg:focus-within:text-indigo-200'
  | 'lg:focus-within:text-indigo-300'
  | 'lg:focus-within:text-indigo-400'
  | 'lg:focus-within:text-indigo-500'
  | 'lg:focus-within:text-indigo-600'
  | 'lg:focus-within:text-indigo-700'
  | 'lg:focus-within:text-indigo-800'
  | 'lg:focus-within:text-indigo-900'
  | 'lg:focus-within:text-purple-50'
  | 'lg:focus-within:text-purple-100'
  | 'lg:focus-within:text-purple-200'
  | 'lg:focus-within:text-purple-300'
  | 'lg:focus-within:text-purple-400'
  | 'lg:focus-within:text-purple-500'
  | 'lg:focus-within:text-purple-600'
  | 'lg:focus-within:text-purple-700'
  | 'lg:focus-within:text-purple-800'
  | 'lg:focus-within:text-purple-900'
  | 'lg:focus-within:text-pink-50'
  | 'lg:focus-within:text-pink-100'
  | 'lg:focus-within:text-pink-200'
  | 'lg:focus-within:text-pink-300'
  | 'lg:focus-within:text-pink-400'
  | 'lg:focus-within:text-pink-500'
  | 'lg:focus-within:text-pink-600'
  | 'lg:focus-within:text-pink-700'
  | 'lg:focus-within:text-pink-800'
  | 'lg:focus-within:text-pink-900'
  | 'lg:focus:text-transparent'
  | 'lg:focus:text-white'
  | 'lg:focus:text-black'
  | 'lg:focus:text-gray-50'
  | 'lg:focus:text-gray-100'
  | 'lg:focus:text-gray-200'
  | 'lg:focus:text-gray-300'
  | 'lg:focus:text-gray-400'
  | 'lg:focus:text-gray-500'
  | 'lg:focus:text-gray-600'
  | 'lg:focus:text-gray-700'
  | 'lg:focus:text-gray-800'
  | 'lg:focus:text-gray-900'
  | 'lg:focus:text-cool-gray-50'
  | 'lg:focus:text-cool-gray-100'
  | 'lg:focus:text-cool-gray-200'
  | 'lg:focus:text-cool-gray-300'
  | 'lg:focus:text-cool-gray-400'
  | 'lg:focus:text-cool-gray-500'
  | 'lg:focus:text-cool-gray-600'
  | 'lg:focus:text-cool-gray-700'
  | 'lg:focus:text-cool-gray-800'
  | 'lg:focus:text-cool-gray-900'
  | 'lg:focus:text-red-50'
  | 'lg:focus:text-red-100'
  | 'lg:focus:text-red-200'
  | 'lg:focus:text-red-300'
  | 'lg:focus:text-red-400'
  | 'lg:focus:text-red-500'
  | 'lg:focus:text-red-600'
  | 'lg:focus:text-red-700'
  | 'lg:focus:text-red-800'
  | 'lg:focus:text-red-900'
  | 'lg:focus:text-orange-50'
  | 'lg:focus:text-orange-100'
  | 'lg:focus:text-orange-200'
  | 'lg:focus:text-orange-300'
  | 'lg:focus:text-orange-400'
  | 'lg:focus:text-orange-500'
  | 'lg:focus:text-orange-600'
  | 'lg:focus:text-orange-700'
  | 'lg:focus:text-orange-800'
  | 'lg:focus:text-orange-900'
  | 'lg:focus:text-yellow-50'
  | 'lg:focus:text-yellow-100'
  | 'lg:focus:text-yellow-200'
  | 'lg:focus:text-yellow-300'
  | 'lg:focus:text-yellow-400'
  | 'lg:focus:text-yellow-500'
  | 'lg:focus:text-yellow-600'
  | 'lg:focus:text-yellow-700'
  | 'lg:focus:text-yellow-800'
  | 'lg:focus:text-yellow-900'
  | 'lg:focus:text-green-50'
  | 'lg:focus:text-green-100'
  | 'lg:focus:text-green-200'
  | 'lg:focus:text-green-300'
  | 'lg:focus:text-green-400'
  | 'lg:focus:text-green-500'
  | 'lg:focus:text-green-600'
  | 'lg:focus:text-green-700'
  | 'lg:focus:text-green-800'
  | 'lg:focus:text-green-900'
  | 'lg:focus:text-teal-50'
  | 'lg:focus:text-teal-100'
  | 'lg:focus:text-teal-200'
  | 'lg:focus:text-teal-300'
  | 'lg:focus:text-teal-400'
  | 'lg:focus:text-teal-500'
  | 'lg:focus:text-teal-600'
  | 'lg:focus:text-teal-700'
  | 'lg:focus:text-teal-800'
  | 'lg:focus:text-teal-900'
  | 'lg:focus:text-blue-50'
  | 'lg:focus:text-blue-100'
  | 'lg:focus:text-blue-200'
  | 'lg:focus:text-blue-300'
  | 'lg:focus:text-blue-400'
  | 'lg:focus:text-blue-500'
  | 'lg:focus:text-blue-600'
  | 'lg:focus:text-blue-700'
  | 'lg:focus:text-blue-800'
  | 'lg:focus:text-blue-900'
  | 'lg:focus:text-indigo-50'
  | 'lg:focus:text-indigo-100'
  | 'lg:focus:text-indigo-200'
  | 'lg:focus:text-indigo-300'
  | 'lg:focus:text-indigo-400'
  | 'lg:focus:text-indigo-500'
  | 'lg:focus:text-indigo-600'
  | 'lg:focus:text-indigo-700'
  | 'lg:focus:text-indigo-800'
  | 'lg:focus:text-indigo-900'
  | 'lg:focus:text-purple-50'
  | 'lg:focus:text-purple-100'
  | 'lg:focus:text-purple-200'
  | 'lg:focus:text-purple-300'
  | 'lg:focus:text-purple-400'
  | 'lg:focus:text-purple-500'
  | 'lg:focus:text-purple-600'
  | 'lg:focus:text-purple-700'
  | 'lg:focus:text-purple-800'
  | 'lg:focus:text-purple-900'
  | 'lg:focus:text-pink-50'
  | 'lg:focus:text-pink-100'
  | 'lg:focus:text-pink-200'
  | 'lg:focus:text-pink-300'
  | 'lg:focus:text-pink-400'
  | 'lg:focus:text-pink-500'
  | 'lg:focus:text-pink-600'
  | 'lg:focus:text-pink-700'
  | 'lg:focus:text-pink-800'
  | 'lg:focus:text-pink-900'
  | 'lg:active:text-transparent'
  | 'lg:active:text-white'
  | 'lg:active:text-black'
  | 'lg:active:text-gray-50'
  | 'lg:active:text-gray-100'
  | 'lg:active:text-gray-200'
  | 'lg:active:text-gray-300'
  | 'lg:active:text-gray-400'
  | 'lg:active:text-gray-500'
  | 'lg:active:text-gray-600'
  | 'lg:active:text-gray-700'
  | 'lg:active:text-gray-800'
  | 'lg:active:text-gray-900'
  | 'lg:active:text-cool-gray-50'
  | 'lg:active:text-cool-gray-100'
  | 'lg:active:text-cool-gray-200'
  | 'lg:active:text-cool-gray-300'
  | 'lg:active:text-cool-gray-400'
  | 'lg:active:text-cool-gray-500'
  | 'lg:active:text-cool-gray-600'
  | 'lg:active:text-cool-gray-700'
  | 'lg:active:text-cool-gray-800'
  | 'lg:active:text-cool-gray-900'
  | 'lg:active:text-red-50'
  | 'lg:active:text-red-100'
  | 'lg:active:text-red-200'
  | 'lg:active:text-red-300'
  | 'lg:active:text-red-400'
  | 'lg:active:text-red-500'
  | 'lg:active:text-red-600'
  | 'lg:active:text-red-700'
  | 'lg:active:text-red-800'
  | 'lg:active:text-red-900'
  | 'lg:active:text-orange-50'
  | 'lg:active:text-orange-100'
  | 'lg:active:text-orange-200'
  | 'lg:active:text-orange-300'
  | 'lg:active:text-orange-400'
  | 'lg:active:text-orange-500'
  | 'lg:active:text-orange-600'
  | 'lg:active:text-orange-700'
  | 'lg:active:text-orange-800'
  | 'lg:active:text-orange-900'
  | 'lg:active:text-yellow-50'
  | 'lg:active:text-yellow-100'
  | 'lg:active:text-yellow-200'
  | 'lg:active:text-yellow-300'
  | 'lg:active:text-yellow-400'
  | 'lg:active:text-yellow-500'
  | 'lg:active:text-yellow-600'
  | 'lg:active:text-yellow-700'
  | 'lg:active:text-yellow-800'
  | 'lg:active:text-yellow-900'
  | 'lg:active:text-green-50'
  | 'lg:active:text-green-100'
  | 'lg:active:text-green-200'
  | 'lg:active:text-green-300'
  | 'lg:active:text-green-400'
  | 'lg:active:text-green-500'
  | 'lg:active:text-green-600'
  | 'lg:active:text-green-700'
  | 'lg:active:text-green-800'
  | 'lg:active:text-green-900'
  | 'lg:active:text-teal-50'
  | 'lg:active:text-teal-100'
  | 'lg:active:text-teal-200'
  | 'lg:active:text-teal-300'
  | 'lg:active:text-teal-400'
  | 'lg:active:text-teal-500'
  | 'lg:active:text-teal-600'
  | 'lg:active:text-teal-700'
  | 'lg:active:text-teal-800'
  | 'lg:active:text-teal-900'
  | 'lg:active:text-blue-50'
  | 'lg:active:text-blue-100'
  | 'lg:active:text-blue-200'
  | 'lg:active:text-blue-300'
  | 'lg:active:text-blue-400'
  | 'lg:active:text-blue-500'
  | 'lg:active:text-blue-600'
  | 'lg:active:text-blue-700'
  | 'lg:active:text-blue-800'
  | 'lg:active:text-blue-900'
  | 'lg:active:text-indigo-50'
  | 'lg:active:text-indigo-100'
  | 'lg:active:text-indigo-200'
  | 'lg:active:text-indigo-300'
  | 'lg:active:text-indigo-400'
  | 'lg:active:text-indigo-500'
  | 'lg:active:text-indigo-600'
  | 'lg:active:text-indigo-700'
  | 'lg:active:text-indigo-800'
  | 'lg:active:text-indigo-900'
  | 'lg:active:text-purple-50'
  | 'lg:active:text-purple-100'
  | 'lg:active:text-purple-200'
  | 'lg:active:text-purple-300'
  | 'lg:active:text-purple-400'
  | 'lg:active:text-purple-500'
  | 'lg:active:text-purple-600'
  | 'lg:active:text-purple-700'
  | 'lg:active:text-purple-800'
  | 'lg:active:text-purple-900'
  | 'lg:active:text-pink-50'
  | 'lg:active:text-pink-100'
  | 'lg:active:text-pink-200'
  | 'lg:active:text-pink-300'
  | 'lg:active:text-pink-400'
  | 'lg:active:text-pink-500'
  | 'lg:active:text-pink-600'
  | 'lg:active:text-pink-700'
  | 'lg:active:text-pink-800'
  | 'lg:active:text-pink-900'
  | 'lg:text-xs'
  | 'lg:text-sm'
  | 'lg:text-base'
  | 'lg:text-lg'
  | 'lg:text-xl'
  | 'lg:text-2xl'
  | 'lg:text-3xl'
  | 'lg:text-4xl'
  | 'lg:text-5xl'
  | 'lg:text-6xl'
  | 'lg:italic'
  | 'lg:not-italic'
  | 'lg:uppercase'
  | 'lg:lowercase'
  | 'lg:capitalize'
  | 'lg:normal-case'
  | 'lg:underline'
  | 'lg:line-through'
  | 'lg:no-underline'
  | 'lg:group-hover:underline'
  | 'lg:group-hover:line-through'
  | 'lg:group-hover:no-underline'
  | 'lg:group-focus:underline'
  | 'lg:group-focus:line-through'
  | 'lg:group-focus:no-underline'
  | 'lg:hover:underline'
  | 'lg:hover:line-through'
  | 'lg:hover:no-underline'
  | 'lg:focus:underline'
  | 'lg:focus:line-through'
  | 'lg:focus:no-underline'
  | 'lg:antialiased'
  | 'lg:subpixel-antialiased'
  | 'lg:tracking-tighter'
  | 'lg:tracking-tight'
  | 'lg:tracking-normal'
  | 'lg:tracking-wide'
  | 'lg:tracking-wider'
  | 'lg:tracking-widest'
  | 'lg:select-none'
  | 'lg:select-text'
  | 'lg:select-all'
  | 'lg:select-auto'
  | 'lg:align-baseline'
  | 'lg:align-top'
  | 'lg:align-middle'
  | 'lg:align-bottom'
  | 'lg:align-text-top'
  | 'lg:align-text-bottom'
  | 'lg:visible'
  | 'lg:invisible'
  | 'lg:whitespace-normal'
  | 'lg:whitespace-no-wrap'
  | 'lg:whitespace-pre'
  | 'lg:whitespace-pre-line'
  | 'lg:whitespace-pre-wrap'
  | 'lg:break-normal'
  | 'lg:break-words'
  | 'lg:break-all'
  | 'lg:truncate'
  | 'lg:w-0'
  | 'lg:w-1'
  | 'lg:w-2'
  | 'lg:w-3'
  | 'lg:w-4'
  | 'lg:w-5'
  | 'lg:w-6'
  | 'lg:w-7'
  | 'lg:w-8'
  | 'lg:w-9'
  | 'lg:w-10'
  | 'lg:w-11'
  | 'lg:w-12'
  | 'lg:w-13'
  | 'lg:w-14'
  | 'lg:w-15'
  | 'lg:w-16'
  | 'lg:w-20'
  | 'lg:w-24'
  | 'lg:w-28'
  | 'lg:w-32'
  | 'lg:w-36'
  | 'lg:w-40'
  | 'lg:w-48'
  | 'lg:w-56'
  | 'lg:w-60'
  | 'lg:w-64'
  | 'lg:w-72'
  | 'lg:w-80'
  | 'lg:w-96'
  | 'lg:w-auto'
  | 'lg:w-px'
  | 'lg:w-0.5'
  | 'lg:w-1.5'
  | 'lg:w-2.5'
  | 'lg:w-3.5'
  | 'lg:w-1/2'
  | 'lg:w-1/3'
  | 'lg:w-2/3'
  | 'lg:w-1/4'
  | 'lg:w-2/4'
  | 'lg:w-3/4'
  | 'lg:w-1/5'
  | 'lg:w-2/5'
  | 'lg:w-3/5'
  | 'lg:w-4/5'
  | 'lg:w-1/6'
  | 'lg:w-2/6'
  | 'lg:w-3/6'
  | 'lg:w-4/6'
  | 'lg:w-5/6'
  | 'lg:w-1/12'
  | 'lg:w-2/12'
  | 'lg:w-3/12'
  | 'lg:w-4/12'
  | 'lg:w-5/12'
  | 'lg:w-6/12'
  | 'lg:w-7/12'
  | 'lg:w-8/12'
  | 'lg:w-9/12'
  | 'lg:w-10/12'
  | 'lg:w-11/12'
  | 'lg:w-full'
  | 'lg:w-screen'
  | 'lg:z-0'
  | 'lg:z-10'
  | 'lg:z-20'
  | 'lg:z-30'
  | 'lg:z-40'
  | 'lg:z-50'
  | 'lg:z-auto'
  | 'lg:focus-within:z-0'
  | 'lg:focus-within:z-10'
  | 'lg:focus-within:z-20'
  | 'lg:focus-within:z-30'
  | 'lg:focus-within:z-40'
  | 'lg:focus-within:z-50'
  | 'lg:focus-within:z-auto'
  | 'lg:focus:z-0'
  | 'lg:focus:z-10'
  | 'lg:focus:z-20'
  | 'lg:focus:z-30'
  | 'lg:focus:z-40'
  | 'lg:focus:z-50'
  | 'lg:focus:z-auto'
  | 'lg:gap-0'
  | 'lg:gap-1'
  | 'lg:gap-2'
  | 'lg:gap-3'
  | 'lg:gap-4'
  | 'lg:gap-5'
  | 'lg:gap-6'
  | 'lg:gap-7'
  | 'lg:gap-8'
  | 'lg:gap-9'
  | 'lg:gap-10'
  | 'lg:gap-11'
  | 'lg:gap-12'
  | 'lg:gap-13'
  | 'lg:gap-14'
  | 'lg:gap-15'
  | 'lg:gap-16'
  | 'lg:gap-20'
  | 'lg:gap-24'
  | 'lg:gap-28'
  | 'lg:gap-32'
  | 'lg:gap-36'
  | 'lg:gap-40'
  | 'lg:gap-48'
  | 'lg:gap-56'
  | 'lg:gap-60'
  | 'lg:gap-64'
  | 'lg:gap-72'
  | 'lg:gap-80'
  | 'lg:gap-96'
  | 'lg:gap-px'
  | 'lg:gap-0.5'
  | 'lg:gap-1.5'
  | 'lg:gap-2.5'
  | 'lg:gap-3.5'
  | 'lg:gap-1/2'
  | 'lg:gap-1/3'
  | 'lg:gap-2/3'
  | 'lg:gap-1/4'
  | 'lg:gap-2/4'
  | 'lg:gap-3/4'
  | 'lg:gap-1/5'
  | 'lg:gap-2/5'
  | 'lg:gap-3/5'
  | 'lg:gap-4/5'
  | 'lg:gap-1/6'
  | 'lg:gap-2/6'
  | 'lg:gap-3/6'
  | 'lg:gap-4/6'
  | 'lg:gap-5/6'
  | 'lg:gap-1/12'
  | 'lg:gap-2/12'
  | 'lg:gap-3/12'
  | 'lg:gap-4/12'
  | 'lg:gap-5/12'
  | 'lg:gap-6/12'
  | 'lg:gap-7/12'
  | 'lg:gap-8/12'
  | 'lg:gap-9/12'
  | 'lg:gap-10/12'
  | 'lg:gap-11/12'
  | 'lg:gap-full'
  | 'lg:col-gap-0'
  | 'lg:col-gap-1'
  | 'lg:col-gap-2'
  | 'lg:col-gap-3'
  | 'lg:col-gap-4'
  | 'lg:col-gap-5'
  | 'lg:col-gap-6'
  | 'lg:col-gap-7'
  | 'lg:col-gap-8'
  | 'lg:col-gap-9'
  | 'lg:col-gap-10'
  | 'lg:col-gap-11'
  | 'lg:col-gap-12'
  | 'lg:col-gap-13'
  | 'lg:col-gap-14'
  | 'lg:col-gap-15'
  | 'lg:col-gap-16'
  | 'lg:col-gap-20'
  | 'lg:col-gap-24'
  | 'lg:col-gap-28'
  | 'lg:col-gap-32'
  | 'lg:col-gap-36'
  | 'lg:col-gap-40'
  | 'lg:col-gap-48'
  | 'lg:col-gap-56'
  | 'lg:col-gap-60'
  | 'lg:col-gap-64'
  | 'lg:col-gap-72'
  | 'lg:col-gap-80'
  | 'lg:col-gap-96'
  | 'lg:col-gap-px'
  | 'lg:col-gap-0.5'
  | 'lg:col-gap-1.5'
  | 'lg:col-gap-2.5'
  | 'lg:col-gap-3.5'
  | 'lg:col-gap-1/2'
  | 'lg:col-gap-1/3'
  | 'lg:col-gap-2/3'
  | 'lg:col-gap-1/4'
  | 'lg:col-gap-2/4'
  | 'lg:col-gap-3/4'
  | 'lg:col-gap-1/5'
  | 'lg:col-gap-2/5'
  | 'lg:col-gap-3/5'
  | 'lg:col-gap-4/5'
  | 'lg:col-gap-1/6'
  | 'lg:col-gap-2/6'
  | 'lg:col-gap-3/6'
  | 'lg:col-gap-4/6'
  | 'lg:col-gap-5/6'
  | 'lg:col-gap-1/12'
  | 'lg:col-gap-2/12'
  | 'lg:col-gap-3/12'
  | 'lg:col-gap-4/12'
  | 'lg:col-gap-5/12'
  | 'lg:col-gap-6/12'
  | 'lg:col-gap-7/12'
  | 'lg:col-gap-8/12'
  | 'lg:col-gap-9/12'
  | 'lg:col-gap-10/12'
  | 'lg:col-gap-11/12'
  | 'lg:col-gap-full'
  | 'lg:row-gap-0'
  | 'lg:row-gap-1'
  | 'lg:row-gap-2'
  | 'lg:row-gap-3'
  | 'lg:row-gap-4'
  | 'lg:row-gap-5'
  | 'lg:row-gap-6'
  | 'lg:row-gap-7'
  | 'lg:row-gap-8'
  | 'lg:row-gap-9'
  | 'lg:row-gap-10'
  | 'lg:row-gap-11'
  | 'lg:row-gap-12'
  | 'lg:row-gap-13'
  | 'lg:row-gap-14'
  | 'lg:row-gap-15'
  | 'lg:row-gap-16'
  | 'lg:row-gap-20'
  | 'lg:row-gap-24'
  | 'lg:row-gap-28'
  | 'lg:row-gap-32'
  | 'lg:row-gap-36'
  | 'lg:row-gap-40'
  | 'lg:row-gap-48'
  | 'lg:row-gap-56'
  | 'lg:row-gap-60'
  | 'lg:row-gap-64'
  | 'lg:row-gap-72'
  | 'lg:row-gap-80'
  | 'lg:row-gap-96'
  | 'lg:row-gap-px'
  | 'lg:row-gap-0.5'
  | 'lg:row-gap-1.5'
  | 'lg:row-gap-2.5'
  | 'lg:row-gap-3.5'
  | 'lg:row-gap-1/2'
  | 'lg:row-gap-1/3'
  | 'lg:row-gap-2/3'
  | 'lg:row-gap-1/4'
  | 'lg:row-gap-2/4'
  | 'lg:row-gap-3/4'
  | 'lg:row-gap-1/5'
  | 'lg:row-gap-2/5'
  | 'lg:row-gap-3/5'
  | 'lg:row-gap-4/5'
  | 'lg:row-gap-1/6'
  | 'lg:row-gap-2/6'
  | 'lg:row-gap-3/6'
  | 'lg:row-gap-4/6'
  | 'lg:row-gap-5/6'
  | 'lg:row-gap-1/12'
  | 'lg:row-gap-2/12'
  | 'lg:row-gap-3/12'
  | 'lg:row-gap-4/12'
  | 'lg:row-gap-5/12'
  | 'lg:row-gap-6/12'
  | 'lg:row-gap-7/12'
  | 'lg:row-gap-8/12'
  | 'lg:row-gap-9/12'
  | 'lg:row-gap-10/12'
  | 'lg:row-gap-11/12'
  | 'lg:row-gap-full'
  | 'lg:grid-flow-row'
  | 'lg:grid-flow-col'
  | 'lg:grid-flow-row-dense'
  | 'lg:grid-flow-col-dense'
  | 'lg:grid-cols-1'
  | 'lg:grid-cols-2'
  | 'lg:grid-cols-3'
  | 'lg:grid-cols-4'
  | 'lg:grid-cols-5'
  | 'lg:grid-cols-6'
  | 'lg:grid-cols-7'
  | 'lg:grid-cols-8'
  | 'lg:grid-cols-9'
  | 'lg:grid-cols-10'
  | 'lg:grid-cols-11'
  | 'lg:grid-cols-12'
  | 'lg:grid-cols-none'
  | 'lg:col-auto'
  | 'lg:col-span-1'
  | 'lg:col-span-2'
  | 'lg:col-span-3'
  | 'lg:col-span-4'
  | 'lg:col-span-5'
  | 'lg:col-span-6'
  | 'lg:col-span-7'
  | 'lg:col-span-8'
  | 'lg:col-span-9'
  | 'lg:col-span-10'
  | 'lg:col-span-11'
  | 'lg:col-span-12'
  | 'lg:col-start-1'
  | 'lg:col-start-2'
  | 'lg:col-start-3'
  | 'lg:col-start-4'
  | 'lg:col-start-5'
  | 'lg:col-start-6'
  | 'lg:col-start-7'
  | 'lg:col-start-8'
  | 'lg:col-start-9'
  | 'lg:col-start-10'
  | 'lg:col-start-11'
  | 'lg:col-start-12'
  | 'lg:col-start-13'
  | 'lg:col-start-auto'
  | 'lg:col-end-1'
  | 'lg:col-end-2'
  | 'lg:col-end-3'
  | 'lg:col-end-4'
  | 'lg:col-end-5'
  | 'lg:col-end-6'
  | 'lg:col-end-7'
  | 'lg:col-end-8'
  | 'lg:col-end-9'
  | 'lg:col-end-10'
  | 'lg:col-end-11'
  | 'lg:col-end-12'
  | 'lg:col-end-13'
  | 'lg:col-end-auto'
  | 'lg:grid-rows-1'
  | 'lg:grid-rows-2'
  | 'lg:grid-rows-3'
  | 'lg:grid-rows-4'
  | 'lg:grid-rows-5'
  | 'lg:grid-rows-6'
  | 'lg:grid-rows-none'
  | 'lg:row-auto'
  | 'lg:row-span-1'
  | 'lg:row-span-2'
  | 'lg:row-span-3'
  | 'lg:row-span-4'
  | 'lg:row-span-5'
  | 'lg:row-span-6'
  | 'lg:row-start-1'
  | 'lg:row-start-2'
  | 'lg:row-start-3'
  | 'lg:row-start-4'
  | 'lg:row-start-5'
  | 'lg:row-start-6'
  | 'lg:row-start-7'
  | 'lg:row-start-auto'
  | 'lg:row-end-1'
  | 'lg:row-end-2'
  | 'lg:row-end-3'
  | 'lg:row-end-4'
  | 'lg:row-end-5'
  | 'lg:row-end-6'
  | 'lg:row-end-7'
  | 'lg:row-end-auto'
  | 'lg:transform'
  | 'lg:transform-none'
  | 'lg:origin-center'
  | 'lg:origin-top'
  | 'lg:origin-top-right'
  | 'lg:origin-right'
  | 'lg:origin-bottom-right'
  | 'lg:origin-bottom'
  | 'lg:origin-bottom-left'
  | 'lg:origin-left'
  | 'lg:origin-top-left'
  | 'lg:scale-0'
  | 'lg:scale-50'
  | 'lg:scale-75'
  | 'lg:scale-90'
  | 'lg:scale-95'
  | 'lg:scale-100'
  | 'lg:scale-105'
  | 'lg:scale-110'
  | 'lg:scale-125'
  | 'lg:scale-150'
  | 'lg:scale-x-0'
  | 'lg:scale-x-50'
  | 'lg:scale-x-75'
  | 'lg:scale-x-90'
  | 'lg:scale-x-95'
  | 'lg:scale-x-100'
  | 'lg:scale-x-105'
  | 'lg:scale-x-110'
  | 'lg:scale-x-125'
  | 'lg:scale-x-150'
  | 'lg:scale-y-0'
  | 'lg:scale-y-50'
  | 'lg:scale-y-75'
  | 'lg:scale-y-90'
  | 'lg:scale-y-95'
  | 'lg:scale-y-100'
  | 'lg:scale-y-105'
  | 'lg:scale-y-110'
  | 'lg:scale-y-125'
  | 'lg:scale-y-150'
  | 'lg:hover:scale-0'
  | 'lg:hover:scale-50'
  | 'lg:hover:scale-75'
  | 'lg:hover:scale-90'
  | 'lg:hover:scale-95'
  | 'lg:hover:scale-100'
  | 'lg:hover:scale-105'
  | 'lg:hover:scale-110'
  | 'lg:hover:scale-125'
  | 'lg:hover:scale-150'
  | 'lg:hover:scale-x-0'
  | 'lg:hover:scale-x-50'
  | 'lg:hover:scale-x-75'
  | 'lg:hover:scale-x-90'
  | 'lg:hover:scale-x-95'
  | 'lg:hover:scale-x-100'
  | 'lg:hover:scale-x-105'
  | 'lg:hover:scale-x-110'
  | 'lg:hover:scale-x-125'
  | 'lg:hover:scale-x-150'
  | 'lg:hover:scale-y-0'
  | 'lg:hover:scale-y-50'
  | 'lg:hover:scale-y-75'
  | 'lg:hover:scale-y-90'
  | 'lg:hover:scale-y-95'
  | 'lg:hover:scale-y-100'
  | 'lg:hover:scale-y-105'
  | 'lg:hover:scale-y-110'
  | 'lg:hover:scale-y-125'
  | 'lg:hover:scale-y-150'
  | 'lg:focus:scale-0'
  | 'lg:focus:scale-50'
  | 'lg:focus:scale-75'
  | 'lg:focus:scale-90'
  | 'lg:focus:scale-95'
  | 'lg:focus:scale-100'
  | 'lg:focus:scale-105'
  | 'lg:focus:scale-110'
  | 'lg:focus:scale-125'
  | 'lg:focus:scale-150'
  | 'lg:focus:scale-x-0'
  | 'lg:focus:scale-x-50'
  | 'lg:focus:scale-x-75'
  | 'lg:focus:scale-x-90'
  | 'lg:focus:scale-x-95'
  | 'lg:focus:scale-x-100'
  | 'lg:focus:scale-x-105'
  | 'lg:focus:scale-x-110'
  | 'lg:focus:scale-x-125'
  | 'lg:focus:scale-x-150'
  | 'lg:focus:scale-y-0'
  | 'lg:focus:scale-y-50'
  | 'lg:focus:scale-y-75'
  | 'lg:focus:scale-y-90'
  | 'lg:focus:scale-y-95'
  | 'lg:focus:scale-y-100'
  | 'lg:focus:scale-y-105'
  | 'lg:focus:scale-y-110'
  | 'lg:focus:scale-y-125'
  | 'lg:focus:scale-y-150'
  | 'lg:rotate-0'
  | 'lg:rotate-45'
  | 'lg:rotate-90'
  | 'lg:rotate-180'
  | 'lg:-rotate-180'
  | 'lg:-rotate-90'
  | 'lg:-rotate-45'
  | 'lg:hover:rotate-0'
  | 'lg:hover:rotate-45'
  | 'lg:hover:rotate-90'
  | 'lg:hover:rotate-180'
  | 'lg:hover:-rotate-180'
  | 'lg:hover:-rotate-90'
  | 'lg:hover:-rotate-45'
  | 'lg:focus:rotate-0'
  | 'lg:focus:rotate-45'
  | 'lg:focus:rotate-90'
  | 'lg:focus:rotate-180'
  | 'lg:focus:-rotate-180'
  | 'lg:focus:-rotate-90'
  | 'lg:focus:-rotate-45'
  | 'lg:translate-x-0'
  | 'lg:translate-x-1'
  | 'lg:translate-x-2'
  | 'lg:translate-x-3'
  | 'lg:translate-x-4'
  | 'lg:translate-x-5'
  | 'lg:translate-x-6'
  | 'lg:translate-x-7'
  | 'lg:translate-x-8'
  | 'lg:translate-x-9'
  | 'lg:translate-x-10'
  | 'lg:translate-x-11'
  | 'lg:translate-x-12'
  | 'lg:translate-x-13'
  | 'lg:translate-x-14'
  | 'lg:translate-x-15'
  | 'lg:translate-x-16'
  | 'lg:translate-x-20'
  | 'lg:translate-x-24'
  | 'lg:translate-x-28'
  | 'lg:translate-x-32'
  | 'lg:translate-x-36'
  | 'lg:translate-x-40'
  | 'lg:translate-x-48'
  | 'lg:translate-x-56'
  | 'lg:translate-x-60'
  | 'lg:translate-x-64'
  | 'lg:translate-x-72'
  | 'lg:translate-x-80'
  | 'lg:translate-x-96'
  | 'lg:translate-x-px'
  | 'lg:translate-x-0.5'
  | 'lg:translate-x-1.5'
  | 'lg:translate-x-2.5'
  | 'lg:translate-x-3.5'
  | 'lg:translate-x-1/2'
  | 'lg:translate-x-1/3'
  | 'lg:translate-x-2/3'
  | 'lg:translate-x-1/4'
  | 'lg:translate-x-2/4'
  | 'lg:translate-x-3/4'
  | 'lg:translate-x-1/5'
  | 'lg:translate-x-2/5'
  | 'lg:translate-x-3/5'
  | 'lg:translate-x-4/5'
  | 'lg:translate-x-1/6'
  | 'lg:translate-x-2/6'
  | 'lg:translate-x-3/6'
  | 'lg:translate-x-4/6'
  | 'lg:translate-x-5/6'
  | 'lg:translate-x-1/12'
  | 'lg:translate-x-2/12'
  | 'lg:translate-x-3/12'
  | 'lg:translate-x-4/12'
  | 'lg:translate-x-5/12'
  | 'lg:translate-x-6/12'
  | 'lg:translate-x-7/12'
  | 'lg:translate-x-8/12'
  | 'lg:translate-x-9/12'
  | 'lg:translate-x-10/12'
  | 'lg:translate-x-11/12'
  | 'lg:translate-x-full'
  | 'lg:-translate-x-1'
  | 'lg:-translate-x-2'
  | 'lg:-translate-x-3'
  | 'lg:-translate-x-4'
  | 'lg:-translate-x-5'
  | 'lg:-translate-x-6'
  | 'lg:-translate-x-7'
  | 'lg:-translate-x-8'
  | 'lg:-translate-x-9'
  | 'lg:-translate-x-10'
  | 'lg:-translate-x-11'
  | 'lg:-translate-x-12'
  | 'lg:-translate-x-13'
  | 'lg:-translate-x-14'
  | 'lg:-translate-x-15'
  | 'lg:-translate-x-16'
  | 'lg:-translate-x-20'
  | 'lg:-translate-x-24'
  | 'lg:-translate-x-28'
  | 'lg:-translate-x-32'
  | 'lg:-translate-x-36'
  | 'lg:-translate-x-40'
  | 'lg:-translate-x-48'
  | 'lg:-translate-x-56'
  | 'lg:-translate-x-60'
  | 'lg:-translate-x-64'
  | 'lg:-translate-x-72'
  | 'lg:-translate-x-80'
  | 'lg:-translate-x-96'
  | 'lg:-translate-x-px'
  | 'lg:-translate-x-0.5'
  | 'lg:-translate-x-1.5'
  | 'lg:-translate-x-2.5'
  | 'lg:-translate-x-3.5'
  | 'lg:-translate-x-1/2'
  | 'lg:-translate-x-1/3'
  | 'lg:-translate-x-2/3'
  | 'lg:-translate-x-1/4'
  | 'lg:-translate-x-2/4'
  | 'lg:-translate-x-3/4'
  | 'lg:-translate-x-1/5'
  | 'lg:-translate-x-2/5'
  | 'lg:-translate-x-3/5'
  | 'lg:-translate-x-4/5'
  | 'lg:-translate-x-1/6'
  | 'lg:-translate-x-2/6'
  | 'lg:-translate-x-3/6'
  | 'lg:-translate-x-4/6'
  | 'lg:-translate-x-5/6'
  | 'lg:-translate-x-1/12'
  | 'lg:-translate-x-2/12'
  | 'lg:-translate-x-3/12'
  | 'lg:-translate-x-4/12'
  | 'lg:-translate-x-5/12'
  | 'lg:-translate-x-6/12'
  | 'lg:-translate-x-7/12'
  | 'lg:-translate-x-8/12'
  | 'lg:-translate-x-9/12'
  | 'lg:-translate-x-10/12'
  | 'lg:-translate-x-11/12'
  | 'lg:-translate-x-full'
  | 'lg:translate-y-0'
  | 'lg:translate-y-1'
  | 'lg:translate-y-2'
  | 'lg:translate-y-3'
  | 'lg:translate-y-4'
  | 'lg:translate-y-5'
  | 'lg:translate-y-6'
  | 'lg:translate-y-7'
  | 'lg:translate-y-8'
  | 'lg:translate-y-9'
  | 'lg:translate-y-10'
  | 'lg:translate-y-11'
  | 'lg:translate-y-12'
  | 'lg:translate-y-13'
  | 'lg:translate-y-14'
  | 'lg:translate-y-15'
  | 'lg:translate-y-16'
  | 'lg:translate-y-20'
  | 'lg:translate-y-24'
  | 'lg:translate-y-28'
  | 'lg:translate-y-32'
  | 'lg:translate-y-36'
  | 'lg:translate-y-40'
  | 'lg:translate-y-48'
  | 'lg:translate-y-56'
  | 'lg:translate-y-60'
  | 'lg:translate-y-64'
  | 'lg:translate-y-72'
  | 'lg:translate-y-80'
  | 'lg:translate-y-96'
  | 'lg:translate-y-px'
  | 'lg:translate-y-0.5'
  | 'lg:translate-y-1.5'
  | 'lg:translate-y-2.5'
  | 'lg:translate-y-3.5'
  | 'lg:translate-y-1/2'
  | 'lg:translate-y-1/3'
  | 'lg:translate-y-2/3'
  | 'lg:translate-y-1/4'
  | 'lg:translate-y-2/4'
  | 'lg:translate-y-3/4'
  | 'lg:translate-y-1/5'
  | 'lg:translate-y-2/5'
  | 'lg:translate-y-3/5'
  | 'lg:translate-y-4/5'
  | 'lg:translate-y-1/6'
  | 'lg:translate-y-2/6'
  | 'lg:translate-y-3/6'
  | 'lg:translate-y-4/6'
  | 'lg:translate-y-5/6'
  | 'lg:translate-y-1/12'
  | 'lg:translate-y-2/12'
  | 'lg:translate-y-3/12'
  | 'lg:translate-y-4/12'
  | 'lg:translate-y-5/12'
  | 'lg:translate-y-6/12'
  | 'lg:translate-y-7/12'
  | 'lg:translate-y-8/12'
  | 'lg:translate-y-9/12'
  | 'lg:translate-y-10/12'
  | 'lg:translate-y-11/12'
  | 'lg:translate-y-full'
  | 'lg:-translate-y-1'
  | 'lg:-translate-y-2'
  | 'lg:-translate-y-3'
  | 'lg:-translate-y-4'
  | 'lg:-translate-y-5'
  | 'lg:-translate-y-6'
  | 'lg:-translate-y-7'
  | 'lg:-translate-y-8'
  | 'lg:-translate-y-9'
  | 'lg:-translate-y-10'
  | 'lg:-translate-y-11'
  | 'lg:-translate-y-12'
  | 'lg:-translate-y-13'
  | 'lg:-translate-y-14'
  | 'lg:-translate-y-15'
  | 'lg:-translate-y-16'
  | 'lg:-translate-y-20'
  | 'lg:-translate-y-24'
  | 'lg:-translate-y-28'
  | 'lg:-translate-y-32'
  | 'lg:-translate-y-36'
  | 'lg:-translate-y-40'
  | 'lg:-translate-y-48'
  | 'lg:-translate-y-56'
  | 'lg:-translate-y-60'
  | 'lg:-translate-y-64'
  | 'lg:-translate-y-72'
  | 'lg:-translate-y-80'
  | 'lg:-translate-y-96'
  | 'lg:-translate-y-px'
  | 'lg:-translate-y-0.5'
  | 'lg:-translate-y-1.5'
  | 'lg:-translate-y-2.5'
  | 'lg:-translate-y-3.5'
  | 'lg:-translate-y-1/2'
  | 'lg:-translate-y-1/3'
  | 'lg:-translate-y-2/3'
  | 'lg:-translate-y-1/4'
  | 'lg:-translate-y-2/4'
  | 'lg:-translate-y-3/4'
  | 'lg:-translate-y-1/5'
  | 'lg:-translate-y-2/5'
  | 'lg:-translate-y-3/5'
  | 'lg:-translate-y-4/5'
  | 'lg:-translate-y-1/6'
  | 'lg:-translate-y-2/6'
  | 'lg:-translate-y-3/6'
  | 'lg:-translate-y-4/6'
  | 'lg:-translate-y-5/6'
  | 'lg:-translate-y-1/12'
  | 'lg:-translate-y-2/12'
  | 'lg:-translate-y-3/12'
  | 'lg:-translate-y-4/12'
  | 'lg:-translate-y-5/12'
  | 'lg:-translate-y-6/12'
  | 'lg:-translate-y-7/12'
  | 'lg:-translate-y-8/12'
  | 'lg:-translate-y-9/12'
  | 'lg:-translate-y-10/12'
  | 'lg:-translate-y-11/12'
  | 'lg:-translate-y-full'
  | 'lg:hover:translate-x-0'
  | 'lg:hover:translate-x-1'
  | 'lg:hover:translate-x-2'
  | 'lg:hover:translate-x-3'
  | 'lg:hover:translate-x-4'
  | 'lg:hover:translate-x-5'
  | 'lg:hover:translate-x-6'
  | 'lg:hover:translate-x-7'
  | 'lg:hover:translate-x-8'
  | 'lg:hover:translate-x-9'
  | 'lg:hover:translate-x-10'
  | 'lg:hover:translate-x-11'
  | 'lg:hover:translate-x-12'
  | 'lg:hover:translate-x-13'
  | 'lg:hover:translate-x-14'
  | 'lg:hover:translate-x-15'
  | 'lg:hover:translate-x-16'
  | 'lg:hover:translate-x-20'
  | 'lg:hover:translate-x-24'
  | 'lg:hover:translate-x-28'
  | 'lg:hover:translate-x-32'
  | 'lg:hover:translate-x-36'
  | 'lg:hover:translate-x-40'
  | 'lg:hover:translate-x-48'
  | 'lg:hover:translate-x-56'
  | 'lg:hover:translate-x-60'
  | 'lg:hover:translate-x-64'
  | 'lg:hover:translate-x-72'
  | 'lg:hover:translate-x-80'
  | 'lg:hover:translate-x-96'
  | 'lg:hover:translate-x-px'
  | 'lg:hover:translate-x-0.5'
  | 'lg:hover:translate-x-1.5'
  | 'lg:hover:translate-x-2.5'
  | 'lg:hover:translate-x-3.5'
  | 'lg:hover:translate-x-1/2'
  | 'lg:hover:translate-x-1/3'
  | 'lg:hover:translate-x-2/3'
  | 'lg:hover:translate-x-1/4'
  | 'lg:hover:translate-x-2/4'
  | 'lg:hover:translate-x-3/4'
  | 'lg:hover:translate-x-1/5'
  | 'lg:hover:translate-x-2/5'
  | 'lg:hover:translate-x-3/5'
  | 'lg:hover:translate-x-4/5'
  | 'lg:hover:translate-x-1/6'
  | 'lg:hover:translate-x-2/6'
  | 'lg:hover:translate-x-3/6'
  | 'lg:hover:translate-x-4/6'
  | 'lg:hover:translate-x-5/6'
  | 'lg:hover:translate-x-1/12'
  | 'lg:hover:translate-x-2/12'
  | 'lg:hover:translate-x-3/12'
  | 'lg:hover:translate-x-4/12'
  | 'lg:hover:translate-x-5/12'
  | 'lg:hover:translate-x-6/12'
  | 'lg:hover:translate-x-7/12'
  | 'lg:hover:translate-x-8/12'
  | 'lg:hover:translate-x-9/12'
  | 'lg:hover:translate-x-10/12'
  | 'lg:hover:translate-x-11/12'
  | 'lg:hover:translate-x-full'
  | 'lg:hover:-translate-x-1'
  | 'lg:hover:-translate-x-2'
  | 'lg:hover:-translate-x-3'
  | 'lg:hover:-translate-x-4'
  | 'lg:hover:-translate-x-5'
  | 'lg:hover:-translate-x-6'
  | 'lg:hover:-translate-x-7'
  | 'lg:hover:-translate-x-8'
  | 'lg:hover:-translate-x-9'
  | 'lg:hover:-translate-x-10'
  | 'lg:hover:-translate-x-11'
  | 'lg:hover:-translate-x-12'
  | 'lg:hover:-translate-x-13'
  | 'lg:hover:-translate-x-14'
  | 'lg:hover:-translate-x-15'
  | 'lg:hover:-translate-x-16'
  | 'lg:hover:-translate-x-20'
  | 'lg:hover:-translate-x-24'
  | 'lg:hover:-translate-x-28'
  | 'lg:hover:-translate-x-32'
  | 'lg:hover:-translate-x-36'
  | 'lg:hover:-translate-x-40'
  | 'lg:hover:-translate-x-48'
  | 'lg:hover:-translate-x-56'
  | 'lg:hover:-translate-x-60'
  | 'lg:hover:-translate-x-64'
  | 'lg:hover:-translate-x-72'
  | 'lg:hover:-translate-x-80'
  | 'lg:hover:-translate-x-96'
  | 'lg:hover:-translate-x-px'
  | 'lg:hover:-translate-x-0.5'
  | 'lg:hover:-translate-x-1.5'
  | 'lg:hover:-translate-x-2.5'
  | 'lg:hover:-translate-x-3.5'
  | 'lg:hover:-translate-x-1/2'
  | 'lg:hover:-translate-x-1/3'
  | 'lg:hover:-translate-x-2/3'
  | 'lg:hover:-translate-x-1/4'
  | 'lg:hover:-translate-x-2/4'
  | 'lg:hover:-translate-x-3/4'
  | 'lg:hover:-translate-x-1/5'
  | 'lg:hover:-translate-x-2/5'
  | 'lg:hover:-translate-x-3/5'
  | 'lg:hover:-translate-x-4/5'
  | 'lg:hover:-translate-x-1/6'
  | 'lg:hover:-translate-x-2/6'
  | 'lg:hover:-translate-x-3/6'
  | 'lg:hover:-translate-x-4/6'
  | 'lg:hover:-translate-x-5/6'
  | 'lg:hover:-translate-x-1/12'
  | 'lg:hover:-translate-x-2/12'
  | 'lg:hover:-translate-x-3/12'
  | 'lg:hover:-translate-x-4/12'
  | 'lg:hover:-translate-x-5/12'
  | 'lg:hover:-translate-x-6/12'
  | 'lg:hover:-translate-x-7/12'
  | 'lg:hover:-translate-x-8/12'
  | 'lg:hover:-translate-x-9/12'
  | 'lg:hover:-translate-x-10/12'
  | 'lg:hover:-translate-x-11/12'
  | 'lg:hover:-translate-x-full'
  | 'lg:hover:translate-y-0'
  | 'lg:hover:translate-y-1'
  | 'lg:hover:translate-y-2'
  | 'lg:hover:translate-y-3'
  | 'lg:hover:translate-y-4'
  | 'lg:hover:translate-y-5'
  | 'lg:hover:translate-y-6'
  | 'lg:hover:translate-y-7'
  | 'lg:hover:translate-y-8'
  | 'lg:hover:translate-y-9'
  | 'lg:hover:translate-y-10'
  | 'lg:hover:translate-y-11'
  | 'lg:hover:translate-y-12'
  | 'lg:hover:translate-y-13'
  | 'lg:hover:translate-y-14'
  | 'lg:hover:translate-y-15'
  | 'lg:hover:translate-y-16'
  | 'lg:hover:translate-y-20'
  | 'lg:hover:translate-y-24'
  | 'lg:hover:translate-y-28'
  | 'lg:hover:translate-y-32'
  | 'lg:hover:translate-y-36'
  | 'lg:hover:translate-y-40'
  | 'lg:hover:translate-y-48'
  | 'lg:hover:translate-y-56'
  | 'lg:hover:translate-y-60'
  | 'lg:hover:translate-y-64'
  | 'lg:hover:translate-y-72'
  | 'lg:hover:translate-y-80'
  | 'lg:hover:translate-y-96'
  | 'lg:hover:translate-y-px'
  | 'lg:hover:translate-y-0.5'
  | 'lg:hover:translate-y-1.5'
  | 'lg:hover:translate-y-2.5'
  | 'lg:hover:translate-y-3.5'
  | 'lg:hover:translate-y-1/2'
  | 'lg:hover:translate-y-1/3'
  | 'lg:hover:translate-y-2/3'
  | 'lg:hover:translate-y-1/4'
  | 'lg:hover:translate-y-2/4'
  | 'lg:hover:translate-y-3/4'
  | 'lg:hover:translate-y-1/5'
  | 'lg:hover:translate-y-2/5'
  | 'lg:hover:translate-y-3/5'
  | 'lg:hover:translate-y-4/5'
  | 'lg:hover:translate-y-1/6'
  | 'lg:hover:translate-y-2/6'
  | 'lg:hover:translate-y-3/6'
  | 'lg:hover:translate-y-4/6'
  | 'lg:hover:translate-y-5/6'
  | 'lg:hover:translate-y-1/12'
  | 'lg:hover:translate-y-2/12'
  | 'lg:hover:translate-y-3/12'
  | 'lg:hover:translate-y-4/12'
  | 'lg:hover:translate-y-5/12'
  | 'lg:hover:translate-y-6/12'
  | 'lg:hover:translate-y-7/12'
  | 'lg:hover:translate-y-8/12'
  | 'lg:hover:translate-y-9/12'
  | 'lg:hover:translate-y-10/12'
  | 'lg:hover:translate-y-11/12'
  | 'lg:hover:translate-y-full'
  | 'lg:hover:-translate-y-1'
  | 'lg:hover:-translate-y-2'
  | 'lg:hover:-translate-y-3'
  | 'lg:hover:-translate-y-4'
  | 'lg:hover:-translate-y-5'
  | 'lg:hover:-translate-y-6'
  | 'lg:hover:-translate-y-7'
  | 'lg:hover:-translate-y-8'
  | 'lg:hover:-translate-y-9'
  | 'lg:hover:-translate-y-10'
  | 'lg:hover:-translate-y-11'
  | 'lg:hover:-translate-y-12'
  | 'lg:hover:-translate-y-13'
  | 'lg:hover:-translate-y-14'
  | 'lg:hover:-translate-y-15'
  | 'lg:hover:-translate-y-16'
  | 'lg:hover:-translate-y-20'
  | 'lg:hover:-translate-y-24'
  | 'lg:hover:-translate-y-28'
  | 'lg:hover:-translate-y-32'
  | 'lg:hover:-translate-y-36'
  | 'lg:hover:-translate-y-40'
  | 'lg:hover:-translate-y-48'
  | 'lg:hover:-translate-y-56'
  | 'lg:hover:-translate-y-60'
  | 'lg:hover:-translate-y-64'
  | 'lg:hover:-translate-y-72'
  | 'lg:hover:-translate-y-80'
  | 'lg:hover:-translate-y-96'
  | 'lg:hover:-translate-y-px'
  | 'lg:hover:-translate-y-0.5'
  | 'lg:hover:-translate-y-1.5'
  | 'lg:hover:-translate-y-2.5'
  | 'lg:hover:-translate-y-3.5'
  | 'lg:hover:-translate-y-1/2'
  | 'lg:hover:-translate-y-1/3'
  | 'lg:hover:-translate-y-2/3'
  | 'lg:hover:-translate-y-1/4'
  | 'lg:hover:-translate-y-2/4'
  | 'lg:hover:-translate-y-3/4'
  | 'lg:hover:-translate-y-1/5'
  | 'lg:hover:-translate-y-2/5'
  | 'lg:hover:-translate-y-3/5'
  | 'lg:hover:-translate-y-4/5'
  | 'lg:hover:-translate-y-1/6'
  | 'lg:hover:-translate-y-2/6'
  | 'lg:hover:-translate-y-3/6'
  | 'lg:hover:-translate-y-4/6'
  | 'lg:hover:-translate-y-5/6'
  | 'lg:hover:-translate-y-1/12'
  | 'lg:hover:-translate-y-2/12'
  | 'lg:hover:-translate-y-3/12'
  | 'lg:hover:-translate-y-4/12'
  | 'lg:hover:-translate-y-5/12'
  | 'lg:hover:-translate-y-6/12'
  | 'lg:hover:-translate-y-7/12'
  | 'lg:hover:-translate-y-8/12'
  | 'lg:hover:-translate-y-9/12'
  | 'lg:hover:-translate-y-10/12'
  | 'lg:hover:-translate-y-11/12'
  | 'lg:hover:-translate-y-full'
  | 'lg:focus:translate-x-0'
  | 'lg:focus:translate-x-1'
  | 'lg:focus:translate-x-2'
  | 'lg:focus:translate-x-3'
  | 'lg:focus:translate-x-4'
  | 'lg:focus:translate-x-5'
  | 'lg:focus:translate-x-6'
  | 'lg:focus:translate-x-7'
  | 'lg:focus:translate-x-8'
  | 'lg:focus:translate-x-9'
  | 'lg:focus:translate-x-10'
  | 'lg:focus:translate-x-11'
  | 'lg:focus:translate-x-12'
  | 'lg:focus:translate-x-13'
  | 'lg:focus:translate-x-14'
  | 'lg:focus:translate-x-15'
  | 'lg:focus:translate-x-16'
  | 'lg:focus:translate-x-20'
  | 'lg:focus:translate-x-24'
  | 'lg:focus:translate-x-28'
  | 'lg:focus:translate-x-32'
  | 'lg:focus:translate-x-36'
  | 'lg:focus:translate-x-40'
  | 'lg:focus:translate-x-48'
  | 'lg:focus:translate-x-56'
  | 'lg:focus:translate-x-60'
  | 'lg:focus:translate-x-64'
  | 'lg:focus:translate-x-72'
  | 'lg:focus:translate-x-80'
  | 'lg:focus:translate-x-96'
  | 'lg:focus:translate-x-px'
  | 'lg:focus:translate-x-0.5'
  | 'lg:focus:translate-x-1.5'
  | 'lg:focus:translate-x-2.5'
  | 'lg:focus:translate-x-3.5'
  | 'lg:focus:translate-x-1/2'
  | 'lg:focus:translate-x-1/3'
  | 'lg:focus:translate-x-2/3'
  | 'lg:focus:translate-x-1/4'
  | 'lg:focus:translate-x-2/4'
  | 'lg:focus:translate-x-3/4'
  | 'lg:focus:translate-x-1/5'
  | 'lg:focus:translate-x-2/5'
  | 'lg:focus:translate-x-3/5'
  | 'lg:focus:translate-x-4/5'
  | 'lg:focus:translate-x-1/6'
  | 'lg:focus:translate-x-2/6'
  | 'lg:focus:translate-x-3/6'
  | 'lg:focus:translate-x-4/6'
  | 'lg:focus:translate-x-5/6'
  | 'lg:focus:translate-x-1/12'
  | 'lg:focus:translate-x-2/12'
  | 'lg:focus:translate-x-3/12'
  | 'lg:focus:translate-x-4/12'
  | 'lg:focus:translate-x-5/12'
  | 'lg:focus:translate-x-6/12'
  | 'lg:focus:translate-x-7/12'
  | 'lg:focus:translate-x-8/12'
  | 'lg:focus:translate-x-9/12'
  | 'lg:focus:translate-x-10/12'
  | 'lg:focus:translate-x-11/12'
  | 'lg:focus:translate-x-full'
  | 'lg:focus:-translate-x-1'
  | 'lg:focus:-translate-x-2'
  | 'lg:focus:-translate-x-3'
  | 'lg:focus:-translate-x-4'
  | 'lg:focus:-translate-x-5'
  | 'lg:focus:-translate-x-6'
  | 'lg:focus:-translate-x-7'
  | 'lg:focus:-translate-x-8'
  | 'lg:focus:-translate-x-9'
  | 'lg:focus:-translate-x-10'
  | 'lg:focus:-translate-x-11'
  | 'lg:focus:-translate-x-12'
  | 'lg:focus:-translate-x-13'
  | 'lg:focus:-translate-x-14'
  | 'lg:focus:-translate-x-15'
  | 'lg:focus:-translate-x-16'
  | 'lg:focus:-translate-x-20'
  | 'lg:focus:-translate-x-24'
  | 'lg:focus:-translate-x-28'
  | 'lg:focus:-translate-x-32'
  | 'lg:focus:-translate-x-36'
  | 'lg:focus:-translate-x-40'
  | 'lg:focus:-translate-x-48'
  | 'lg:focus:-translate-x-56'
  | 'lg:focus:-translate-x-60'
  | 'lg:focus:-translate-x-64'
  | 'lg:focus:-translate-x-72'
  | 'lg:focus:-translate-x-80'
  | 'lg:focus:-translate-x-96'
  | 'lg:focus:-translate-x-px'
  | 'lg:focus:-translate-x-0.5'
  | 'lg:focus:-translate-x-1.5'
  | 'lg:focus:-translate-x-2.5'
  | 'lg:focus:-translate-x-3.5'
  | 'lg:focus:-translate-x-1/2'
  | 'lg:focus:-translate-x-1/3'
  | 'lg:focus:-translate-x-2/3'
  | 'lg:focus:-translate-x-1/4'
  | 'lg:focus:-translate-x-2/4'
  | 'lg:focus:-translate-x-3/4'
  | 'lg:focus:-translate-x-1/5'
  | 'lg:focus:-translate-x-2/5'
  | 'lg:focus:-translate-x-3/5'
  | 'lg:focus:-translate-x-4/5'
  | 'lg:focus:-translate-x-1/6'
  | 'lg:focus:-translate-x-2/6'
  | 'lg:focus:-translate-x-3/6'
  | 'lg:focus:-translate-x-4/6'
  | 'lg:focus:-translate-x-5/6'
  | 'lg:focus:-translate-x-1/12'
  | 'lg:focus:-translate-x-2/12'
  | 'lg:focus:-translate-x-3/12'
  | 'lg:focus:-translate-x-4/12'
  | 'lg:focus:-translate-x-5/12'
  | 'lg:focus:-translate-x-6/12'
  | 'lg:focus:-translate-x-7/12'
  | 'lg:focus:-translate-x-8/12'
  | 'lg:focus:-translate-x-9/12'
  | 'lg:focus:-translate-x-10/12'
  | 'lg:focus:-translate-x-11/12'
  | 'lg:focus:-translate-x-full'
  | 'lg:focus:translate-y-0'
  | 'lg:focus:translate-y-1'
  | 'lg:focus:translate-y-2'
  | 'lg:focus:translate-y-3'
  | 'lg:focus:translate-y-4'
  | 'lg:focus:translate-y-5'
  | 'lg:focus:translate-y-6'
  | 'lg:focus:translate-y-7'
  | 'lg:focus:translate-y-8'
  | 'lg:focus:translate-y-9'
  | 'lg:focus:translate-y-10'
  | 'lg:focus:translate-y-11'
  | 'lg:focus:translate-y-12'
  | 'lg:focus:translate-y-13'
  | 'lg:focus:translate-y-14'
  | 'lg:focus:translate-y-15'
  | 'lg:focus:translate-y-16'
  | 'lg:focus:translate-y-20'
  | 'lg:focus:translate-y-24'
  | 'lg:focus:translate-y-28'
  | 'lg:focus:translate-y-32'
  | 'lg:focus:translate-y-36'
  | 'lg:focus:translate-y-40'
  | 'lg:focus:translate-y-48'
  | 'lg:focus:translate-y-56'
  | 'lg:focus:translate-y-60'
  | 'lg:focus:translate-y-64'
  | 'lg:focus:translate-y-72'
  | 'lg:focus:translate-y-80'
  | 'lg:focus:translate-y-96'
  | 'lg:focus:translate-y-px'
  | 'lg:focus:translate-y-0.5'
  | 'lg:focus:translate-y-1.5'
  | 'lg:focus:translate-y-2.5'
  | 'lg:focus:translate-y-3.5'
  | 'lg:focus:translate-y-1/2'
  | 'lg:focus:translate-y-1/3'
  | 'lg:focus:translate-y-2/3'
  | 'lg:focus:translate-y-1/4'
  | 'lg:focus:translate-y-2/4'
  | 'lg:focus:translate-y-3/4'
  | 'lg:focus:translate-y-1/5'
  | 'lg:focus:translate-y-2/5'
  | 'lg:focus:translate-y-3/5'
  | 'lg:focus:translate-y-4/5'
  | 'lg:focus:translate-y-1/6'
  | 'lg:focus:translate-y-2/6'
  | 'lg:focus:translate-y-3/6'
  | 'lg:focus:translate-y-4/6'
  | 'lg:focus:translate-y-5/6'
  | 'lg:focus:translate-y-1/12'
  | 'lg:focus:translate-y-2/12'
  | 'lg:focus:translate-y-3/12'
  | 'lg:focus:translate-y-4/12'
  | 'lg:focus:translate-y-5/12'
  | 'lg:focus:translate-y-6/12'
  | 'lg:focus:translate-y-7/12'
  | 'lg:focus:translate-y-8/12'
  | 'lg:focus:translate-y-9/12'
  | 'lg:focus:translate-y-10/12'
  | 'lg:focus:translate-y-11/12'
  | 'lg:focus:translate-y-full'
  | 'lg:focus:-translate-y-1'
  | 'lg:focus:-translate-y-2'
  | 'lg:focus:-translate-y-3'
  | 'lg:focus:-translate-y-4'
  | 'lg:focus:-translate-y-5'
  | 'lg:focus:-translate-y-6'
  | 'lg:focus:-translate-y-7'
  | 'lg:focus:-translate-y-8'
  | 'lg:focus:-translate-y-9'
  | 'lg:focus:-translate-y-10'
  | 'lg:focus:-translate-y-11'
  | 'lg:focus:-translate-y-12'
  | 'lg:focus:-translate-y-13'
  | 'lg:focus:-translate-y-14'
  | 'lg:focus:-translate-y-15'
  | 'lg:focus:-translate-y-16'
  | 'lg:focus:-translate-y-20'
  | 'lg:focus:-translate-y-24'
  | 'lg:focus:-translate-y-28'
  | 'lg:focus:-translate-y-32'
  | 'lg:focus:-translate-y-36'
  | 'lg:focus:-translate-y-40'
  | 'lg:focus:-translate-y-48'
  | 'lg:focus:-translate-y-56'
  | 'lg:focus:-translate-y-60'
  | 'lg:focus:-translate-y-64'
  | 'lg:focus:-translate-y-72'
  | 'lg:focus:-translate-y-80'
  | 'lg:focus:-translate-y-96'
  | 'lg:focus:-translate-y-px'
  | 'lg:focus:-translate-y-0.5'
  | 'lg:focus:-translate-y-1.5'
  | 'lg:focus:-translate-y-2.5'
  | 'lg:focus:-translate-y-3.5'
  | 'lg:focus:-translate-y-1/2'
  | 'lg:focus:-translate-y-1/3'
  | 'lg:focus:-translate-y-2/3'
  | 'lg:focus:-translate-y-1/4'
  | 'lg:focus:-translate-y-2/4'
  | 'lg:focus:-translate-y-3/4'
  | 'lg:focus:-translate-y-1/5'
  | 'lg:focus:-translate-y-2/5'
  | 'lg:focus:-translate-y-3/5'
  | 'lg:focus:-translate-y-4/5'
  | 'lg:focus:-translate-y-1/6'
  | 'lg:focus:-translate-y-2/6'
  | 'lg:focus:-translate-y-3/6'
  | 'lg:focus:-translate-y-4/6'
  | 'lg:focus:-translate-y-5/6'
  | 'lg:focus:-translate-y-1/12'
  | 'lg:focus:-translate-y-2/12'
  | 'lg:focus:-translate-y-3/12'
  | 'lg:focus:-translate-y-4/12'
  | 'lg:focus:-translate-y-5/12'
  | 'lg:focus:-translate-y-6/12'
  | 'lg:focus:-translate-y-7/12'
  | 'lg:focus:-translate-y-8/12'
  | 'lg:focus:-translate-y-9/12'
  | 'lg:focus:-translate-y-10/12'
  | 'lg:focus:-translate-y-11/12'
  | 'lg:focus:-translate-y-full'
  | 'lg:skew-x-0'
  | 'lg:skew-x-3'
  | 'lg:skew-x-6'
  | 'lg:skew-x-12'
  | 'lg:-skew-x-12'
  | 'lg:-skew-x-6'
  | 'lg:-skew-x-3'
  | 'lg:skew-y-0'
  | 'lg:skew-y-3'
  | 'lg:skew-y-6'
  | 'lg:skew-y-12'
  | 'lg:-skew-y-12'
  | 'lg:-skew-y-6'
  | 'lg:-skew-y-3'
  | 'lg:hover:skew-x-0'
  | 'lg:hover:skew-x-3'
  | 'lg:hover:skew-x-6'
  | 'lg:hover:skew-x-12'
  | 'lg:hover:-skew-x-12'
  | 'lg:hover:-skew-x-6'
  | 'lg:hover:-skew-x-3'
  | 'lg:hover:skew-y-0'
  | 'lg:hover:skew-y-3'
  | 'lg:hover:skew-y-6'
  | 'lg:hover:skew-y-12'
  | 'lg:hover:-skew-y-12'
  | 'lg:hover:-skew-y-6'
  | 'lg:hover:-skew-y-3'
  | 'lg:focus:skew-x-0'
  | 'lg:focus:skew-x-3'
  | 'lg:focus:skew-x-6'
  | 'lg:focus:skew-x-12'
  | 'lg:focus:-skew-x-12'
  | 'lg:focus:-skew-x-6'
  | 'lg:focus:-skew-x-3'
  | 'lg:focus:skew-y-0'
  | 'lg:focus:skew-y-3'
  | 'lg:focus:skew-y-6'
  | 'lg:focus:skew-y-12'
  | 'lg:focus:-skew-y-12'
  | 'lg:focus:-skew-y-6'
  | 'lg:focus:-skew-y-3'
  | 'lg:transition-none'
  | 'lg:transition-all'
  | 'lg:transition'
  | 'lg:transition-colors'
  | 'lg:transition-opacity'
  | 'lg:transition-shadow'
  | 'lg:transition-transform'
  | 'lg:ease-linear'
  | 'lg:ease-in'
  | 'lg:ease-out'
  | 'lg:ease-in-out'
  | 'lg:duration-75'
  | 'lg:duration-100'
  | 'lg:duration-150'
  | 'lg:duration-200'
  | 'lg:duration-300'
  | 'lg:duration-500'
  | 'lg:duration-700'
  | 'lg:duration-1000'
  | 'xl:sr-only'
  | 'xl:not-sr-only'
  | 'xl:focus:sr-only'
  | 'xl:focus:not-sr-only'
  | 'xl:appearance-none'
  | 'xl:bg-fixed'
  | 'xl:bg-local'
  | 'xl:bg-scroll'
  | 'xl:bg-transparent'
  | 'xl:bg-white'
  | 'xl:bg-black'
  | 'xl:bg-gray-50'
  | 'xl:bg-gray-100'
  | 'xl:bg-gray-200'
  | 'xl:bg-gray-300'
  | 'xl:bg-gray-400'
  | 'xl:bg-gray-500'
  | 'xl:bg-gray-600'
  | 'xl:bg-gray-700'
  | 'xl:bg-gray-800'
  | 'xl:bg-gray-900'
  | 'xl:bg-cool-gray-50'
  | 'xl:bg-cool-gray-100'
  | 'xl:bg-cool-gray-200'
  | 'xl:bg-cool-gray-300'
  | 'xl:bg-cool-gray-400'
  | 'xl:bg-cool-gray-500'
  | 'xl:bg-cool-gray-600'
  | 'xl:bg-cool-gray-700'
  | 'xl:bg-cool-gray-800'
  | 'xl:bg-cool-gray-900'
  | 'xl:bg-red-50'
  | 'xl:bg-red-100'
  | 'xl:bg-red-200'
  | 'xl:bg-red-300'
  | 'xl:bg-red-400'
  | 'xl:bg-red-500'
  | 'xl:bg-red-600'
  | 'xl:bg-red-700'
  | 'xl:bg-red-800'
  | 'xl:bg-red-900'
  | 'xl:bg-orange-50'
  | 'xl:bg-orange-100'
  | 'xl:bg-orange-200'
  | 'xl:bg-orange-300'
  | 'xl:bg-orange-400'
  | 'xl:bg-orange-500'
  | 'xl:bg-orange-600'
  | 'xl:bg-orange-700'
  | 'xl:bg-orange-800'
  | 'xl:bg-orange-900'
  | 'xl:bg-yellow-50'
  | 'xl:bg-yellow-100'
  | 'xl:bg-yellow-200'
  | 'xl:bg-yellow-300'
  | 'xl:bg-yellow-400'
  | 'xl:bg-yellow-500'
  | 'xl:bg-yellow-600'
  | 'xl:bg-yellow-700'
  | 'xl:bg-yellow-800'
  | 'xl:bg-yellow-900'
  | 'xl:bg-green-50'
  | 'xl:bg-green-100'
  | 'xl:bg-green-200'
  | 'xl:bg-green-300'
  | 'xl:bg-green-400'
  | 'xl:bg-green-500'
  | 'xl:bg-green-600'
  | 'xl:bg-green-700'
  | 'xl:bg-green-800'
  | 'xl:bg-green-900'
  | 'xl:bg-teal-50'
  | 'xl:bg-teal-100'
  | 'xl:bg-teal-200'
  | 'xl:bg-teal-300'
  | 'xl:bg-teal-400'
  | 'xl:bg-teal-500'
  | 'xl:bg-teal-600'
  | 'xl:bg-teal-700'
  | 'xl:bg-teal-800'
  | 'xl:bg-teal-900'
  | 'xl:bg-blue-50'
  | 'xl:bg-blue-100'
  | 'xl:bg-blue-200'
  | 'xl:bg-blue-300'
  | 'xl:bg-blue-400'
  | 'xl:bg-blue-500'
  | 'xl:bg-blue-600'
  | 'xl:bg-blue-700'
  | 'xl:bg-blue-800'
  | 'xl:bg-blue-900'
  | 'xl:bg-indigo-50'
  | 'xl:bg-indigo-100'
  | 'xl:bg-indigo-200'
  | 'xl:bg-indigo-300'
  | 'xl:bg-indigo-400'
  | 'xl:bg-indigo-500'
  | 'xl:bg-indigo-600'
  | 'xl:bg-indigo-700'
  | 'xl:bg-indigo-800'
  | 'xl:bg-indigo-900'
  | 'xl:bg-purple-50'
  | 'xl:bg-purple-100'
  | 'xl:bg-purple-200'
  | 'xl:bg-purple-300'
  | 'xl:bg-purple-400'
  | 'xl:bg-purple-500'
  | 'xl:bg-purple-600'
  | 'xl:bg-purple-700'
  | 'xl:bg-purple-800'
  | 'xl:bg-purple-900'
  | 'xl:bg-pink-50'
  | 'xl:bg-pink-100'
  | 'xl:bg-pink-200'
  | 'xl:bg-pink-300'
  | 'xl:bg-pink-400'
  | 'xl:bg-pink-500'
  | 'xl:bg-pink-600'
  | 'xl:bg-pink-700'
  | 'xl:bg-pink-800'
  | 'xl:bg-pink-900'
  | 'xl:group-hover:bg-transparent'
  | 'xl:group-hover:bg-white'
  | 'xl:group-hover:bg-black'
  | 'xl:group-hover:bg-gray-50'
  | 'xl:group-hover:bg-gray-100'
  | 'xl:group-hover:bg-gray-200'
  | 'xl:group-hover:bg-gray-300'
  | 'xl:group-hover:bg-gray-400'
  | 'xl:group-hover:bg-gray-500'
  | 'xl:group-hover:bg-gray-600'
  | 'xl:group-hover:bg-gray-700'
  | 'xl:group-hover:bg-gray-800'
  | 'xl:group-hover:bg-gray-900'
  | 'xl:group-hover:bg-cool-gray-50'
  | 'xl:group-hover:bg-cool-gray-100'
  | 'xl:group-hover:bg-cool-gray-200'
  | 'xl:group-hover:bg-cool-gray-300'
  | 'xl:group-hover:bg-cool-gray-400'
  | 'xl:group-hover:bg-cool-gray-500'
  | 'xl:group-hover:bg-cool-gray-600'
  | 'xl:group-hover:bg-cool-gray-700'
  | 'xl:group-hover:bg-cool-gray-800'
  | 'xl:group-hover:bg-cool-gray-900'
  | 'xl:group-hover:bg-red-50'
  | 'xl:group-hover:bg-red-100'
  | 'xl:group-hover:bg-red-200'
  | 'xl:group-hover:bg-red-300'
  | 'xl:group-hover:bg-red-400'
  | 'xl:group-hover:bg-red-500'
  | 'xl:group-hover:bg-red-600'
  | 'xl:group-hover:bg-red-700'
  | 'xl:group-hover:bg-red-800'
  | 'xl:group-hover:bg-red-900'
  | 'xl:group-hover:bg-orange-50'
  | 'xl:group-hover:bg-orange-100'
  | 'xl:group-hover:bg-orange-200'
  | 'xl:group-hover:bg-orange-300'
  | 'xl:group-hover:bg-orange-400'
  | 'xl:group-hover:bg-orange-500'
  | 'xl:group-hover:bg-orange-600'
  | 'xl:group-hover:bg-orange-700'
  | 'xl:group-hover:bg-orange-800'
  | 'xl:group-hover:bg-orange-900'
  | 'xl:group-hover:bg-yellow-50'
  | 'xl:group-hover:bg-yellow-100'
  | 'xl:group-hover:bg-yellow-200'
  | 'xl:group-hover:bg-yellow-300'
  | 'xl:group-hover:bg-yellow-400'
  | 'xl:group-hover:bg-yellow-500'
  | 'xl:group-hover:bg-yellow-600'
  | 'xl:group-hover:bg-yellow-700'
  | 'xl:group-hover:bg-yellow-800'
  | 'xl:group-hover:bg-yellow-900'
  | 'xl:group-hover:bg-green-50'
  | 'xl:group-hover:bg-green-100'
  | 'xl:group-hover:bg-green-200'
  | 'xl:group-hover:bg-green-300'
  | 'xl:group-hover:bg-green-400'
  | 'xl:group-hover:bg-green-500'
  | 'xl:group-hover:bg-green-600'
  | 'xl:group-hover:bg-green-700'
  | 'xl:group-hover:bg-green-800'
  | 'xl:group-hover:bg-green-900'
  | 'xl:group-hover:bg-teal-50'
  | 'xl:group-hover:bg-teal-100'
  | 'xl:group-hover:bg-teal-200'
  | 'xl:group-hover:bg-teal-300'
  | 'xl:group-hover:bg-teal-400'
  | 'xl:group-hover:bg-teal-500'
  | 'xl:group-hover:bg-teal-600'
  | 'xl:group-hover:bg-teal-700'
  | 'xl:group-hover:bg-teal-800'
  | 'xl:group-hover:bg-teal-900'
  | 'xl:group-hover:bg-blue-50'
  | 'xl:group-hover:bg-blue-100'
  | 'xl:group-hover:bg-blue-200'
  | 'xl:group-hover:bg-blue-300'
  | 'xl:group-hover:bg-blue-400'
  | 'xl:group-hover:bg-blue-500'
  | 'xl:group-hover:bg-blue-600'
  | 'xl:group-hover:bg-blue-700'
  | 'xl:group-hover:bg-blue-800'
  | 'xl:group-hover:bg-blue-900'
  | 'xl:group-hover:bg-indigo-50'
  | 'xl:group-hover:bg-indigo-100'
  | 'xl:group-hover:bg-indigo-200'
  | 'xl:group-hover:bg-indigo-300'
  | 'xl:group-hover:bg-indigo-400'
  | 'xl:group-hover:bg-indigo-500'
  | 'xl:group-hover:bg-indigo-600'
  | 'xl:group-hover:bg-indigo-700'
  | 'xl:group-hover:bg-indigo-800'
  | 'xl:group-hover:bg-indigo-900'
  | 'xl:group-hover:bg-purple-50'
  | 'xl:group-hover:bg-purple-100'
  | 'xl:group-hover:bg-purple-200'
  | 'xl:group-hover:bg-purple-300'
  | 'xl:group-hover:bg-purple-400'
  | 'xl:group-hover:bg-purple-500'
  | 'xl:group-hover:bg-purple-600'
  | 'xl:group-hover:bg-purple-700'
  | 'xl:group-hover:bg-purple-800'
  | 'xl:group-hover:bg-purple-900'
  | 'xl:group-hover:bg-pink-50'
  | 'xl:group-hover:bg-pink-100'
  | 'xl:group-hover:bg-pink-200'
  | 'xl:group-hover:bg-pink-300'
  | 'xl:group-hover:bg-pink-400'
  | 'xl:group-hover:bg-pink-500'
  | 'xl:group-hover:bg-pink-600'
  | 'xl:group-hover:bg-pink-700'
  | 'xl:group-hover:bg-pink-800'
  | 'xl:group-hover:bg-pink-900'
  | 'xl:group-focus:bg-transparent'
  | 'xl:group-focus:bg-white'
  | 'xl:group-focus:bg-black'
  | 'xl:group-focus:bg-gray-50'
  | 'xl:group-focus:bg-gray-100'
  | 'xl:group-focus:bg-gray-200'
  | 'xl:group-focus:bg-gray-300'
  | 'xl:group-focus:bg-gray-400'
  | 'xl:group-focus:bg-gray-500'
  | 'xl:group-focus:bg-gray-600'
  | 'xl:group-focus:bg-gray-700'
  | 'xl:group-focus:bg-gray-800'
  | 'xl:group-focus:bg-gray-900'
  | 'xl:group-focus:bg-cool-gray-50'
  | 'xl:group-focus:bg-cool-gray-100'
  | 'xl:group-focus:bg-cool-gray-200'
  | 'xl:group-focus:bg-cool-gray-300'
  | 'xl:group-focus:bg-cool-gray-400'
  | 'xl:group-focus:bg-cool-gray-500'
  | 'xl:group-focus:bg-cool-gray-600'
  | 'xl:group-focus:bg-cool-gray-700'
  | 'xl:group-focus:bg-cool-gray-800'
  | 'xl:group-focus:bg-cool-gray-900'
  | 'xl:group-focus:bg-red-50'
  | 'xl:group-focus:bg-red-100'
  | 'xl:group-focus:bg-red-200'
  | 'xl:group-focus:bg-red-300'
  | 'xl:group-focus:bg-red-400'
  | 'xl:group-focus:bg-red-500'
  | 'xl:group-focus:bg-red-600'
  | 'xl:group-focus:bg-red-700'
  | 'xl:group-focus:bg-red-800'
  | 'xl:group-focus:bg-red-900'
  | 'xl:group-focus:bg-orange-50'
  | 'xl:group-focus:bg-orange-100'
  | 'xl:group-focus:bg-orange-200'
  | 'xl:group-focus:bg-orange-300'
  | 'xl:group-focus:bg-orange-400'
  | 'xl:group-focus:bg-orange-500'
  | 'xl:group-focus:bg-orange-600'
  | 'xl:group-focus:bg-orange-700'
  | 'xl:group-focus:bg-orange-800'
  | 'xl:group-focus:bg-orange-900'
  | 'xl:group-focus:bg-yellow-50'
  | 'xl:group-focus:bg-yellow-100'
  | 'xl:group-focus:bg-yellow-200'
  | 'xl:group-focus:bg-yellow-300'
  | 'xl:group-focus:bg-yellow-400'
  | 'xl:group-focus:bg-yellow-500'
  | 'xl:group-focus:bg-yellow-600'
  | 'xl:group-focus:bg-yellow-700'
  | 'xl:group-focus:bg-yellow-800'
  | 'xl:group-focus:bg-yellow-900'
  | 'xl:group-focus:bg-green-50'
  | 'xl:group-focus:bg-green-100'
  | 'xl:group-focus:bg-green-200'
  | 'xl:group-focus:bg-green-300'
  | 'xl:group-focus:bg-green-400'
  | 'xl:group-focus:bg-green-500'
  | 'xl:group-focus:bg-green-600'
  | 'xl:group-focus:bg-green-700'
  | 'xl:group-focus:bg-green-800'
  | 'xl:group-focus:bg-green-900'
  | 'xl:group-focus:bg-teal-50'
  | 'xl:group-focus:bg-teal-100'
  | 'xl:group-focus:bg-teal-200'
  | 'xl:group-focus:bg-teal-300'
  | 'xl:group-focus:bg-teal-400'
  | 'xl:group-focus:bg-teal-500'
  | 'xl:group-focus:bg-teal-600'
  | 'xl:group-focus:bg-teal-700'
  | 'xl:group-focus:bg-teal-800'
  | 'xl:group-focus:bg-teal-900'
  | 'xl:group-focus:bg-blue-50'
  | 'xl:group-focus:bg-blue-100'
  | 'xl:group-focus:bg-blue-200'
  | 'xl:group-focus:bg-blue-300'
  | 'xl:group-focus:bg-blue-400'
  | 'xl:group-focus:bg-blue-500'
  | 'xl:group-focus:bg-blue-600'
  | 'xl:group-focus:bg-blue-700'
  | 'xl:group-focus:bg-blue-800'
  | 'xl:group-focus:bg-blue-900'
  | 'xl:group-focus:bg-indigo-50'
  | 'xl:group-focus:bg-indigo-100'
  | 'xl:group-focus:bg-indigo-200'
  | 'xl:group-focus:bg-indigo-300'
  | 'xl:group-focus:bg-indigo-400'
  | 'xl:group-focus:bg-indigo-500'
  | 'xl:group-focus:bg-indigo-600'
  | 'xl:group-focus:bg-indigo-700'
  | 'xl:group-focus:bg-indigo-800'
  | 'xl:group-focus:bg-indigo-900'
  | 'xl:group-focus:bg-purple-50'
  | 'xl:group-focus:bg-purple-100'
  | 'xl:group-focus:bg-purple-200'
  | 'xl:group-focus:bg-purple-300'
  | 'xl:group-focus:bg-purple-400'
  | 'xl:group-focus:bg-purple-500'
  | 'xl:group-focus:bg-purple-600'
  | 'xl:group-focus:bg-purple-700'
  | 'xl:group-focus:bg-purple-800'
  | 'xl:group-focus:bg-purple-900'
  | 'xl:group-focus:bg-pink-50'
  | 'xl:group-focus:bg-pink-100'
  | 'xl:group-focus:bg-pink-200'
  | 'xl:group-focus:bg-pink-300'
  | 'xl:group-focus:bg-pink-400'
  | 'xl:group-focus:bg-pink-500'
  | 'xl:group-focus:bg-pink-600'
  | 'xl:group-focus:bg-pink-700'
  | 'xl:group-focus:bg-pink-800'
  | 'xl:group-focus:bg-pink-900'
  | 'xl:hover:bg-transparent'
  | 'xl:hover:bg-white'
  | 'xl:hover:bg-black'
  | 'xl:hover:bg-gray-50'
  | 'xl:hover:bg-gray-100'
  | 'xl:hover:bg-gray-200'
  | 'xl:hover:bg-gray-300'
  | 'xl:hover:bg-gray-400'
  | 'xl:hover:bg-gray-500'
  | 'xl:hover:bg-gray-600'
  | 'xl:hover:bg-gray-700'
  | 'xl:hover:bg-gray-800'
  | 'xl:hover:bg-gray-900'
  | 'xl:hover:bg-cool-gray-50'
  | 'xl:hover:bg-cool-gray-100'
  | 'xl:hover:bg-cool-gray-200'
  | 'xl:hover:bg-cool-gray-300'
  | 'xl:hover:bg-cool-gray-400'
  | 'xl:hover:bg-cool-gray-500'
  | 'xl:hover:bg-cool-gray-600'
  | 'xl:hover:bg-cool-gray-700'
  | 'xl:hover:bg-cool-gray-800'
  | 'xl:hover:bg-cool-gray-900'
  | 'xl:hover:bg-red-50'
  | 'xl:hover:bg-red-100'
  | 'xl:hover:bg-red-200'
  | 'xl:hover:bg-red-300'
  | 'xl:hover:bg-red-400'
  | 'xl:hover:bg-red-500'
  | 'xl:hover:bg-red-600'
  | 'xl:hover:bg-red-700'
  | 'xl:hover:bg-red-800'
  | 'xl:hover:bg-red-900'
  | 'xl:hover:bg-orange-50'
  | 'xl:hover:bg-orange-100'
  | 'xl:hover:bg-orange-200'
  | 'xl:hover:bg-orange-300'
  | 'xl:hover:bg-orange-400'
  | 'xl:hover:bg-orange-500'
  | 'xl:hover:bg-orange-600'
  | 'xl:hover:bg-orange-700'
  | 'xl:hover:bg-orange-800'
  | 'xl:hover:bg-orange-900'
  | 'xl:hover:bg-yellow-50'
  | 'xl:hover:bg-yellow-100'
  | 'xl:hover:bg-yellow-200'
  | 'xl:hover:bg-yellow-300'
  | 'xl:hover:bg-yellow-400'
  | 'xl:hover:bg-yellow-500'
  | 'xl:hover:bg-yellow-600'
  | 'xl:hover:bg-yellow-700'
  | 'xl:hover:bg-yellow-800'
  | 'xl:hover:bg-yellow-900'
  | 'xl:hover:bg-green-50'
  | 'xl:hover:bg-green-100'
  | 'xl:hover:bg-green-200'
  | 'xl:hover:bg-green-300'
  | 'xl:hover:bg-green-400'
  | 'xl:hover:bg-green-500'
  | 'xl:hover:bg-green-600'
  | 'xl:hover:bg-green-700'
  | 'xl:hover:bg-green-800'
  | 'xl:hover:bg-green-900'
  | 'xl:hover:bg-teal-50'
  | 'xl:hover:bg-teal-100'
  | 'xl:hover:bg-teal-200'
  | 'xl:hover:bg-teal-300'
  | 'xl:hover:bg-teal-400'
  | 'xl:hover:bg-teal-500'
  | 'xl:hover:bg-teal-600'
  | 'xl:hover:bg-teal-700'
  | 'xl:hover:bg-teal-800'
  | 'xl:hover:bg-teal-900'
  | 'xl:hover:bg-blue-50'
  | 'xl:hover:bg-blue-100'
  | 'xl:hover:bg-blue-200'
  | 'xl:hover:bg-blue-300'
  | 'xl:hover:bg-blue-400'
  | 'xl:hover:bg-blue-500'
  | 'xl:hover:bg-blue-600'
  | 'xl:hover:bg-blue-700'
  | 'xl:hover:bg-blue-800'
  | 'xl:hover:bg-blue-900'
  | 'xl:hover:bg-indigo-50'
  | 'xl:hover:bg-indigo-100'
  | 'xl:hover:bg-indigo-200'
  | 'xl:hover:bg-indigo-300'
  | 'xl:hover:bg-indigo-400'
  | 'xl:hover:bg-indigo-500'
  | 'xl:hover:bg-indigo-600'
  | 'xl:hover:bg-indigo-700'
  | 'xl:hover:bg-indigo-800'
  | 'xl:hover:bg-indigo-900'
  | 'xl:hover:bg-purple-50'
  | 'xl:hover:bg-purple-100'
  | 'xl:hover:bg-purple-200'
  | 'xl:hover:bg-purple-300'
  | 'xl:hover:bg-purple-400'
  | 'xl:hover:bg-purple-500'
  | 'xl:hover:bg-purple-600'
  | 'xl:hover:bg-purple-700'
  | 'xl:hover:bg-purple-800'
  | 'xl:hover:bg-purple-900'
  | 'xl:hover:bg-pink-50'
  | 'xl:hover:bg-pink-100'
  | 'xl:hover:bg-pink-200'
  | 'xl:hover:bg-pink-300'
  | 'xl:hover:bg-pink-400'
  | 'xl:hover:bg-pink-500'
  | 'xl:hover:bg-pink-600'
  | 'xl:hover:bg-pink-700'
  | 'xl:hover:bg-pink-800'
  | 'xl:hover:bg-pink-900'
  | 'xl:focus:bg-transparent'
  | 'xl:focus:bg-white'
  | 'xl:focus:bg-black'
  | 'xl:focus:bg-gray-50'
  | 'xl:focus:bg-gray-100'
  | 'xl:focus:bg-gray-200'
  | 'xl:focus:bg-gray-300'
  | 'xl:focus:bg-gray-400'
  | 'xl:focus:bg-gray-500'
  | 'xl:focus:bg-gray-600'
  | 'xl:focus:bg-gray-700'
  | 'xl:focus:bg-gray-800'
  | 'xl:focus:bg-gray-900'
  | 'xl:focus:bg-cool-gray-50'
  | 'xl:focus:bg-cool-gray-100'
  | 'xl:focus:bg-cool-gray-200'
  | 'xl:focus:bg-cool-gray-300'
  | 'xl:focus:bg-cool-gray-400'
  | 'xl:focus:bg-cool-gray-500'
  | 'xl:focus:bg-cool-gray-600'
  | 'xl:focus:bg-cool-gray-700'
  | 'xl:focus:bg-cool-gray-800'
  | 'xl:focus:bg-cool-gray-900'
  | 'xl:focus:bg-red-50'
  | 'xl:focus:bg-red-100'
  | 'xl:focus:bg-red-200'
  | 'xl:focus:bg-red-300'
  | 'xl:focus:bg-red-400'
  | 'xl:focus:bg-red-500'
  | 'xl:focus:bg-red-600'
  | 'xl:focus:bg-red-700'
  | 'xl:focus:bg-red-800'
  | 'xl:focus:bg-red-900'
  | 'xl:focus:bg-orange-50'
  | 'xl:focus:bg-orange-100'
  | 'xl:focus:bg-orange-200'
  | 'xl:focus:bg-orange-300'
  | 'xl:focus:bg-orange-400'
  | 'xl:focus:bg-orange-500'
  | 'xl:focus:bg-orange-600'
  | 'xl:focus:bg-orange-700'
  | 'xl:focus:bg-orange-800'
  | 'xl:focus:bg-orange-900'
  | 'xl:focus:bg-yellow-50'
  | 'xl:focus:bg-yellow-100'
  | 'xl:focus:bg-yellow-200'
  | 'xl:focus:bg-yellow-300'
  | 'xl:focus:bg-yellow-400'
  | 'xl:focus:bg-yellow-500'
  | 'xl:focus:bg-yellow-600'
  | 'xl:focus:bg-yellow-700'
  | 'xl:focus:bg-yellow-800'
  | 'xl:focus:bg-yellow-900'
  | 'xl:focus:bg-green-50'
  | 'xl:focus:bg-green-100'
  | 'xl:focus:bg-green-200'
  | 'xl:focus:bg-green-300'
  | 'xl:focus:bg-green-400'
  | 'xl:focus:bg-green-500'
  | 'xl:focus:bg-green-600'
  | 'xl:focus:bg-green-700'
  | 'xl:focus:bg-green-800'
  | 'xl:focus:bg-green-900'
  | 'xl:focus:bg-teal-50'
  | 'xl:focus:bg-teal-100'
  | 'xl:focus:bg-teal-200'
  | 'xl:focus:bg-teal-300'
  | 'xl:focus:bg-teal-400'
  | 'xl:focus:bg-teal-500'
  | 'xl:focus:bg-teal-600'
  | 'xl:focus:bg-teal-700'
  | 'xl:focus:bg-teal-800'
  | 'xl:focus:bg-teal-900'
  | 'xl:focus:bg-blue-50'
  | 'xl:focus:bg-blue-100'
  | 'xl:focus:bg-blue-200'
  | 'xl:focus:bg-blue-300'
  | 'xl:focus:bg-blue-400'
  | 'xl:focus:bg-blue-500'
  | 'xl:focus:bg-blue-600'
  | 'xl:focus:bg-blue-700'
  | 'xl:focus:bg-blue-800'
  | 'xl:focus:bg-blue-900'
  | 'xl:focus:bg-indigo-50'
  | 'xl:focus:bg-indigo-100'
  | 'xl:focus:bg-indigo-200'
  | 'xl:focus:bg-indigo-300'
  | 'xl:focus:bg-indigo-400'
  | 'xl:focus:bg-indigo-500'
  | 'xl:focus:bg-indigo-600'
  | 'xl:focus:bg-indigo-700'
  | 'xl:focus:bg-indigo-800'
  | 'xl:focus:bg-indigo-900'
  | 'xl:focus:bg-purple-50'
  | 'xl:focus:bg-purple-100'
  | 'xl:focus:bg-purple-200'
  | 'xl:focus:bg-purple-300'
  | 'xl:focus:bg-purple-400'
  | 'xl:focus:bg-purple-500'
  | 'xl:focus:bg-purple-600'
  | 'xl:focus:bg-purple-700'
  | 'xl:focus:bg-purple-800'
  | 'xl:focus:bg-purple-900'
  | 'xl:focus:bg-pink-50'
  | 'xl:focus:bg-pink-100'
  | 'xl:focus:bg-pink-200'
  | 'xl:focus:bg-pink-300'
  | 'xl:focus:bg-pink-400'
  | 'xl:focus:bg-pink-500'
  | 'xl:focus:bg-pink-600'
  | 'xl:focus:bg-pink-700'
  | 'xl:focus:bg-pink-800'
  | 'xl:focus:bg-pink-900'
  | 'xl:active:bg-transparent'
  | 'xl:active:bg-white'
  | 'xl:active:bg-black'
  | 'xl:active:bg-gray-50'
  | 'xl:active:bg-gray-100'
  | 'xl:active:bg-gray-200'
  | 'xl:active:bg-gray-300'
  | 'xl:active:bg-gray-400'
  | 'xl:active:bg-gray-500'
  | 'xl:active:bg-gray-600'
  | 'xl:active:bg-gray-700'
  | 'xl:active:bg-gray-800'
  | 'xl:active:bg-gray-900'
  | 'xl:active:bg-cool-gray-50'
  | 'xl:active:bg-cool-gray-100'
  | 'xl:active:bg-cool-gray-200'
  | 'xl:active:bg-cool-gray-300'
  | 'xl:active:bg-cool-gray-400'
  | 'xl:active:bg-cool-gray-500'
  | 'xl:active:bg-cool-gray-600'
  | 'xl:active:bg-cool-gray-700'
  | 'xl:active:bg-cool-gray-800'
  | 'xl:active:bg-cool-gray-900'
  | 'xl:active:bg-red-50'
  | 'xl:active:bg-red-100'
  | 'xl:active:bg-red-200'
  | 'xl:active:bg-red-300'
  | 'xl:active:bg-red-400'
  | 'xl:active:bg-red-500'
  | 'xl:active:bg-red-600'
  | 'xl:active:bg-red-700'
  | 'xl:active:bg-red-800'
  | 'xl:active:bg-red-900'
  | 'xl:active:bg-orange-50'
  | 'xl:active:bg-orange-100'
  | 'xl:active:bg-orange-200'
  | 'xl:active:bg-orange-300'
  | 'xl:active:bg-orange-400'
  | 'xl:active:bg-orange-500'
  | 'xl:active:bg-orange-600'
  | 'xl:active:bg-orange-700'
  | 'xl:active:bg-orange-800'
  | 'xl:active:bg-orange-900'
  | 'xl:active:bg-yellow-50'
  | 'xl:active:bg-yellow-100'
  | 'xl:active:bg-yellow-200'
  | 'xl:active:bg-yellow-300'
  | 'xl:active:bg-yellow-400'
  | 'xl:active:bg-yellow-500'
  | 'xl:active:bg-yellow-600'
  | 'xl:active:bg-yellow-700'
  | 'xl:active:bg-yellow-800'
  | 'xl:active:bg-yellow-900'
  | 'xl:active:bg-green-50'
  | 'xl:active:bg-green-100'
  | 'xl:active:bg-green-200'
  | 'xl:active:bg-green-300'
  | 'xl:active:bg-green-400'
  | 'xl:active:bg-green-500'
  | 'xl:active:bg-green-600'
  | 'xl:active:bg-green-700'
  | 'xl:active:bg-green-800'
  | 'xl:active:bg-green-900'
  | 'xl:active:bg-teal-50'
  | 'xl:active:bg-teal-100'
  | 'xl:active:bg-teal-200'
  | 'xl:active:bg-teal-300'
  | 'xl:active:bg-teal-400'
  | 'xl:active:bg-teal-500'
  | 'xl:active:bg-teal-600'
  | 'xl:active:bg-teal-700'
  | 'xl:active:bg-teal-800'
  | 'xl:active:bg-teal-900'
  | 'xl:active:bg-blue-50'
  | 'xl:active:bg-blue-100'
  | 'xl:active:bg-blue-200'
  | 'xl:active:bg-blue-300'
  | 'xl:active:bg-blue-400'
  | 'xl:active:bg-blue-500'
  | 'xl:active:bg-blue-600'
  | 'xl:active:bg-blue-700'
  | 'xl:active:bg-blue-800'
  | 'xl:active:bg-blue-900'
  | 'xl:active:bg-indigo-50'
  | 'xl:active:bg-indigo-100'
  | 'xl:active:bg-indigo-200'
  | 'xl:active:bg-indigo-300'
  | 'xl:active:bg-indigo-400'
  | 'xl:active:bg-indigo-500'
  | 'xl:active:bg-indigo-600'
  | 'xl:active:bg-indigo-700'
  | 'xl:active:bg-indigo-800'
  | 'xl:active:bg-indigo-900'
  | 'xl:active:bg-purple-50'
  | 'xl:active:bg-purple-100'
  | 'xl:active:bg-purple-200'
  | 'xl:active:bg-purple-300'
  | 'xl:active:bg-purple-400'
  | 'xl:active:bg-purple-500'
  | 'xl:active:bg-purple-600'
  | 'xl:active:bg-purple-700'
  | 'xl:active:bg-purple-800'
  | 'xl:active:bg-purple-900'
  | 'xl:active:bg-pink-50'
  | 'xl:active:bg-pink-100'
  | 'xl:active:bg-pink-200'
  | 'xl:active:bg-pink-300'
  | 'xl:active:bg-pink-400'
  | 'xl:active:bg-pink-500'
  | 'xl:active:bg-pink-600'
  | 'xl:active:bg-pink-700'
  | 'xl:active:bg-pink-800'
  | 'xl:active:bg-pink-900'
  | 'xl:bg-bottom'
  | 'xl:bg-center'
  | 'xl:bg-left'
  | 'xl:bg-left-bottom'
  | 'xl:bg-left-top'
  | 'xl:bg-right'
  | 'xl:bg-right-bottom'
  | 'xl:bg-right-top'
  | 'xl:bg-top'
  | 'xl:bg-repeat'
  | 'xl:bg-no-repeat'
  | 'xl:bg-repeat-x'
  | 'xl:bg-repeat-y'
  | 'xl:bg-repeat-round'
  | 'xl:bg-repeat-space'
  | 'xl:bg-auto'
  | 'xl:bg-cover'
  | 'xl:bg-contain'
  | 'xl:border-collapse'
  | 'xl:border-separate'
  | 'xl:border-transparent'
  | 'xl:border-white'
  | 'xl:border-black'
  | 'xl:border-gray-50'
  | 'xl:border-gray-100'
  | 'xl:border-gray-200'
  | 'xl:border-gray-300'
  | 'xl:border-gray-400'
  | 'xl:border-gray-500'
  | 'xl:border-gray-600'
  | 'xl:border-gray-700'
  | 'xl:border-gray-800'
  | 'xl:border-gray-900'
  | 'xl:border-cool-gray-50'
  | 'xl:border-cool-gray-100'
  | 'xl:border-cool-gray-200'
  | 'xl:border-cool-gray-300'
  | 'xl:border-cool-gray-400'
  | 'xl:border-cool-gray-500'
  | 'xl:border-cool-gray-600'
  | 'xl:border-cool-gray-700'
  | 'xl:border-cool-gray-800'
  | 'xl:border-cool-gray-900'
  | 'xl:border-red-50'
  | 'xl:border-red-100'
  | 'xl:border-red-200'
  | 'xl:border-red-300'
  | 'xl:border-red-400'
  | 'xl:border-red-500'
  | 'xl:border-red-600'
  | 'xl:border-red-700'
  | 'xl:border-red-800'
  | 'xl:border-red-900'
  | 'xl:border-orange-50'
  | 'xl:border-orange-100'
  | 'xl:border-orange-200'
  | 'xl:border-orange-300'
  | 'xl:border-orange-400'
  | 'xl:border-orange-500'
  | 'xl:border-orange-600'
  | 'xl:border-orange-700'
  | 'xl:border-orange-800'
  | 'xl:border-orange-900'
  | 'xl:border-yellow-50'
  | 'xl:border-yellow-100'
  | 'xl:border-yellow-200'
  | 'xl:border-yellow-300'
  | 'xl:border-yellow-400'
  | 'xl:border-yellow-500'
  | 'xl:border-yellow-600'
  | 'xl:border-yellow-700'
  | 'xl:border-yellow-800'
  | 'xl:border-yellow-900'
  | 'xl:border-green-50'
  | 'xl:border-green-100'
  | 'xl:border-green-200'
  | 'xl:border-green-300'
  | 'xl:border-green-400'
  | 'xl:border-green-500'
  | 'xl:border-green-600'
  | 'xl:border-green-700'
  | 'xl:border-green-800'
  | 'xl:border-green-900'
  | 'xl:border-teal-50'
  | 'xl:border-teal-100'
  | 'xl:border-teal-200'
  | 'xl:border-teal-300'
  | 'xl:border-teal-400'
  | 'xl:border-teal-500'
  | 'xl:border-teal-600'
  | 'xl:border-teal-700'
  | 'xl:border-teal-800'
  | 'xl:border-teal-900'
  | 'xl:border-blue-50'
  | 'xl:border-blue-100'
  | 'xl:border-blue-200'
  | 'xl:border-blue-300'
  | 'xl:border-blue-400'
  | 'xl:border-blue-500'
  | 'xl:border-blue-600'
  | 'xl:border-blue-700'
  | 'xl:border-blue-800'
  | 'xl:border-blue-900'
  | 'xl:border-indigo-50'
  | 'xl:border-indigo-100'
  | 'xl:border-indigo-200'
  | 'xl:border-indigo-300'
  | 'xl:border-indigo-400'
  | 'xl:border-indigo-500'
  | 'xl:border-indigo-600'
  | 'xl:border-indigo-700'
  | 'xl:border-indigo-800'
  | 'xl:border-indigo-900'
  | 'xl:border-purple-50'
  | 'xl:border-purple-100'
  | 'xl:border-purple-200'
  | 'xl:border-purple-300'
  | 'xl:border-purple-400'
  | 'xl:border-purple-500'
  | 'xl:border-purple-600'
  | 'xl:border-purple-700'
  | 'xl:border-purple-800'
  | 'xl:border-purple-900'
  | 'xl:border-pink-50'
  | 'xl:border-pink-100'
  | 'xl:border-pink-200'
  | 'xl:border-pink-300'
  | 'xl:border-pink-400'
  | 'xl:border-pink-500'
  | 'xl:border-pink-600'
  | 'xl:border-pink-700'
  | 'xl:border-pink-800'
  | 'xl:border-pink-900'
  | 'xl:group-hover:border-transparent'
  | 'xl:group-hover:border-white'
  | 'xl:group-hover:border-black'
  | 'xl:group-hover:border-gray-50'
  | 'xl:group-hover:border-gray-100'
  | 'xl:group-hover:border-gray-200'
  | 'xl:group-hover:border-gray-300'
  | 'xl:group-hover:border-gray-400'
  | 'xl:group-hover:border-gray-500'
  | 'xl:group-hover:border-gray-600'
  | 'xl:group-hover:border-gray-700'
  | 'xl:group-hover:border-gray-800'
  | 'xl:group-hover:border-gray-900'
  | 'xl:group-hover:border-cool-gray-50'
  | 'xl:group-hover:border-cool-gray-100'
  | 'xl:group-hover:border-cool-gray-200'
  | 'xl:group-hover:border-cool-gray-300'
  | 'xl:group-hover:border-cool-gray-400'
  | 'xl:group-hover:border-cool-gray-500'
  | 'xl:group-hover:border-cool-gray-600'
  | 'xl:group-hover:border-cool-gray-700'
  | 'xl:group-hover:border-cool-gray-800'
  | 'xl:group-hover:border-cool-gray-900'
  | 'xl:group-hover:border-red-50'
  | 'xl:group-hover:border-red-100'
  | 'xl:group-hover:border-red-200'
  | 'xl:group-hover:border-red-300'
  | 'xl:group-hover:border-red-400'
  | 'xl:group-hover:border-red-500'
  | 'xl:group-hover:border-red-600'
  | 'xl:group-hover:border-red-700'
  | 'xl:group-hover:border-red-800'
  | 'xl:group-hover:border-red-900'
  | 'xl:group-hover:border-orange-50'
  | 'xl:group-hover:border-orange-100'
  | 'xl:group-hover:border-orange-200'
  | 'xl:group-hover:border-orange-300'
  | 'xl:group-hover:border-orange-400'
  | 'xl:group-hover:border-orange-500'
  | 'xl:group-hover:border-orange-600'
  | 'xl:group-hover:border-orange-700'
  | 'xl:group-hover:border-orange-800'
  | 'xl:group-hover:border-orange-900'
  | 'xl:group-hover:border-yellow-50'
  | 'xl:group-hover:border-yellow-100'
  | 'xl:group-hover:border-yellow-200'
  | 'xl:group-hover:border-yellow-300'
  | 'xl:group-hover:border-yellow-400'
  | 'xl:group-hover:border-yellow-500'
  | 'xl:group-hover:border-yellow-600'
  | 'xl:group-hover:border-yellow-700'
  | 'xl:group-hover:border-yellow-800'
  | 'xl:group-hover:border-yellow-900'
  | 'xl:group-hover:border-green-50'
  | 'xl:group-hover:border-green-100'
  | 'xl:group-hover:border-green-200'
  | 'xl:group-hover:border-green-300'
  | 'xl:group-hover:border-green-400'
  | 'xl:group-hover:border-green-500'
  | 'xl:group-hover:border-green-600'
  | 'xl:group-hover:border-green-700'
  | 'xl:group-hover:border-green-800'
  | 'xl:group-hover:border-green-900'
  | 'xl:group-hover:border-teal-50'
  | 'xl:group-hover:border-teal-100'
  | 'xl:group-hover:border-teal-200'
  | 'xl:group-hover:border-teal-300'
  | 'xl:group-hover:border-teal-400'
  | 'xl:group-hover:border-teal-500'
  | 'xl:group-hover:border-teal-600'
  | 'xl:group-hover:border-teal-700'
  | 'xl:group-hover:border-teal-800'
  | 'xl:group-hover:border-teal-900'
  | 'xl:group-hover:border-blue-50'
  | 'xl:group-hover:border-blue-100'
  | 'xl:group-hover:border-blue-200'
  | 'xl:group-hover:border-blue-300'
  | 'xl:group-hover:border-blue-400'
  | 'xl:group-hover:border-blue-500'
  | 'xl:group-hover:border-blue-600'
  | 'xl:group-hover:border-blue-700'
  | 'xl:group-hover:border-blue-800'
  | 'xl:group-hover:border-blue-900'
  | 'xl:group-hover:border-indigo-50'
  | 'xl:group-hover:border-indigo-100'
  | 'xl:group-hover:border-indigo-200'
  | 'xl:group-hover:border-indigo-300'
  | 'xl:group-hover:border-indigo-400'
  | 'xl:group-hover:border-indigo-500'
  | 'xl:group-hover:border-indigo-600'
  | 'xl:group-hover:border-indigo-700'
  | 'xl:group-hover:border-indigo-800'
  | 'xl:group-hover:border-indigo-900'
  | 'xl:group-hover:border-purple-50'
  | 'xl:group-hover:border-purple-100'
  | 'xl:group-hover:border-purple-200'
  | 'xl:group-hover:border-purple-300'
  | 'xl:group-hover:border-purple-400'
  | 'xl:group-hover:border-purple-500'
  | 'xl:group-hover:border-purple-600'
  | 'xl:group-hover:border-purple-700'
  | 'xl:group-hover:border-purple-800'
  | 'xl:group-hover:border-purple-900'
  | 'xl:group-hover:border-pink-50'
  | 'xl:group-hover:border-pink-100'
  | 'xl:group-hover:border-pink-200'
  | 'xl:group-hover:border-pink-300'
  | 'xl:group-hover:border-pink-400'
  | 'xl:group-hover:border-pink-500'
  | 'xl:group-hover:border-pink-600'
  | 'xl:group-hover:border-pink-700'
  | 'xl:group-hover:border-pink-800'
  | 'xl:group-hover:border-pink-900'
  | 'xl:group-focus:border-transparent'
  | 'xl:group-focus:border-white'
  | 'xl:group-focus:border-black'
  | 'xl:group-focus:border-gray-50'
  | 'xl:group-focus:border-gray-100'
  | 'xl:group-focus:border-gray-200'
  | 'xl:group-focus:border-gray-300'
  | 'xl:group-focus:border-gray-400'
  | 'xl:group-focus:border-gray-500'
  | 'xl:group-focus:border-gray-600'
  | 'xl:group-focus:border-gray-700'
  | 'xl:group-focus:border-gray-800'
  | 'xl:group-focus:border-gray-900'
  | 'xl:group-focus:border-cool-gray-50'
  | 'xl:group-focus:border-cool-gray-100'
  | 'xl:group-focus:border-cool-gray-200'
  | 'xl:group-focus:border-cool-gray-300'
  | 'xl:group-focus:border-cool-gray-400'
  | 'xl:group-focus:border-cool-gray-500'
  | 'xl:group-focus:border-cool-gray-600'
  | 'xl:group-focus:border-cool-gray-700'
  | 'xl:group-focus:border-cool-gray-800'
  | 'xl:group-focus:border-cool-gray-900'
  | 'xl:group-focus:border-red-50'
  | 'xl:group-focus:border-red-100'
  | 'xl:group-focus:border-red-200'
  | 'xl:group-focus:border-red-300'
  | 'xl:group-focus:border-red-400'
  | 'xl:group-focus:border-red-500'
  | 'xl:group-focus:border-red-600'
  | 'xl:group-focus:border-red-700'
  | 'xl:group-focus:border-red-800'
  | 'xl:group-focus:border-red-900'
  | 'xl:group-focus:border-orange-50'
  | 'xl:group-focus:border-orange-100'
  | 'xl:group-focus:border-orange-200'
  | 'xl:group-focus:border-orange-300'
  | 'xl:group-focus:border-orange-400'
  | 'xl:group-focus:border-orange-500'
  | 'xl:group-focus:border-orange-600'
  | 'xl:group-focus:border-orange-700'
  | 'xl:group-focus:border-orange-800'
  | 'xl:group-focus:border-orange-900'
  | 'xl:group-focus:border-yellow-50'
  | 'xl:group-focus:border-yellow-100'
  | 'xl:group-focus:border-yellow-200'
  | 'xl:group-focus:border-yellow-300'
  | 'xl:group-focus:border-yellow-400'
  | 'xl:group-focus:border-yellow-500'
  | 'xl:group-focus:border-yellow-600'
  | 'xl:group-focus:border-yellow-700'
  | 'xl:group-focus:border-yellow-800'
  | 'xl:group-focus:border-yellow-900'
  | 'xl:group-focus:border-green-50'
  | 'xl:group-focus:border-green-100'
  | 'xl:group-focus:border-green-200'
  | 'xl:group-focus:border-green-300'
  | 'xl:group-focus:border-green-400'
  | 'xl:group-focus:border-green-500'
  | 'xl:group-focus:border-green-600'
  | 'xl:group-focus:border-green-700'
  | 'xl:group-focus:border-green-800'
  | 'xl:group-focus:border-green-900'
  | 'xl:group-focus:border-teal-50'
  | 'xl:group-focus:border-teal-100'
  | 'xl:group-focus:border-teal-200'
  | 'xl:group-focus:border-teal-300'
  | 'xl:group-focus:border-teal-400'
  | 'xl:group-focus:border-teal-500'
  | 'xl:group-focus:border-teal-600'
  | 'xl:group-focus:border-teal-700'
  | 'xl:group-focus:border-teal-800'
  | 'xl:group-focus:border-teal-900'
  | 'xl:group-focus:border-blue-50'
  | 'xl:group-focus:border-blue-100'
  | 'xl:group-focus:border-blue-200'
  | 'xl:group-focus:border-blue-300'
  | 'xl:group-focus:border-blue-400'
  | 'xl:group-focus:border-blue-500'
  | 'xl:group-focus:border-blue-600'
  | 'xl:group-focus:border-blue-700'
  | 'xl:group-focus:border-blue-800'
  | 'xl:group-focus:border-blue-900'
  | 'xl:group-focus:border-indigo-50'
  | 'xl:group-focus:border-indigo-100'
  | 'xl:group-focus:border-indigo-200'
  | 'xl:group-focus:border-indigo-300'
  | 'xl:group-focus:border-indigo-400'
  | 'xl:group-focus:border-indigo-500'
  | 'xl:group-focus:border-indigo-600'
  | 'xl:group-focus:border-indigo-700'
  | 'xl:group-focus:border-indigo-800'
  | 'xl:group-focus:border-indigo-900'
  | 'xl:group-focus:border-purple-50'
  | 'xl:group-focus:border-purple-100'
  | 'xl:group-focus:border-purple-200'
  | 'xl:group-focus:border-purple-300'
  | 'xl:group-focus:border-purple-400'
  | 'xl:group-focus:border-purple-500'
  | 'xl:group-focus:border-purple-600'
  | 'xl:group-focus:border-purple-700'
  | 'xl:group-focus:border-purple-800'
  | 'xl:group-focus:border-purple-900'
  | 'xl:group-focus:border-pink-50'
  | 'xl:group-focus:border-pink-100'
  | 'xl:group-focus:border-pink-200'
  | 'xl:group-focus:border-pink-300'
  | 'xl:group-focus:border-pink-400'
  | 'xl:group-focus:border-pink-500'
  | 'xl:group-focus:border-pink-600'
  | 'xl:group-focus:border-pink-700'
  | 'xl:group-focus:border-pink-800'
  | 'xl:group-focus:border-pink-900'
  | 'xl:hover:border-transparent'
  | 'xl:hover:border-white'
  | 'xl:hover:border-black'
  | 'xl:hover:border-gray-50'
  | 'xl:hover:border-gray-100'
  | 'xl:hover:border-gray-200'
  | 'xl:hover:border-gray-300'
  | 'xl:hover:border-gray-400'
  | 'xl:hover:border-gray-500'
  | 'xl:hover:border-gray-600'
  | 'xl:hover:border-gray-700'
  | 'xl:hover:border-gray-800'
  | 'xl:hover:border-gray-900'
  | 'xl:hover:border-cool-gray-50'
  | 'xl:hover:border-cool-gray-100'
  | 'xl:hover:border-cool-gray-200'
  | 'xl:hover:border-cool-gray-300'
  | 'xl:hover:border-cool-gray-400'
  | 'xl:hover:border-cool-gray-500'
  | 'xl:hover:border-cool-gray-600'
  | 'xl:hover:border-cool-gray-700'
  | 'xl:hover:border-cool-gray-800'
  | 'xl:hover:border-cool-gray-900'
  | 'xl:hover:border-red-50'
  | 'xl:hover:border-red-100'
  | 'xl:hover:border-red-200'
  | 'xl:hover:border-red-300'
  | 'xl:hover:border-red-400'
  | 'xl:hover:border-red-500'
  | 'xl:hover:border-red-600'
  | 'xl:hover:border-red-700'
  | 'xl:hover:border-red-800'
  | 'xl:hover:border-red-900'
  | 'xl:hover:border-orange-50'
  | 'xl:hover:border-orange-100'
  | 'xl:hover:border-orange-200'
  | 'xl:hover:border-orange-300'
  | 'xl:hover:border-orange-400'
  | 'xl:hover:border-orange-500'
  | 'xl:hover:border-orange-600'
  | 'xl:hover:border-orange-700'
  | 'xl:hover:border-orange-800'
  | 'xl:hover:border-orange-900'
  | 'xl:hover:border-yellow-50'
  | 'xl:hover:border-yellow-100'
  | 'xl:hover:border-yellow-200'
  | 'xl:hover:border-yellow-300'
  | 'xl:hover:border-yellow-400'
  | 'xl:hover:border-yellow-500'
  | 'xl:hover:border-yellow-600'
  | 'xl:hover:border-yellow-700'
  | 'xl:hover:border-yellow-800'
  | 'xl:hover:border-yellow-900'
  | 'xl:hover:border-green-50'
  | 'xl:hover:border-green-100'
  | 'xl:hover:border-green-200'
  | 'xl:hover:border-green-300'
  | 'xl:hover:border-green-400'
  | 'xl:hover:border-green-500'
  | 'xl:hover:border-green-600'
  | 'xl:hover:border-green-700'
  | 'xl:hover:border-green-800'
  | 'xl:hover:border-green-900'
  | 'xl:hover:border-teal-50'
  | 'xl:hover:border-teal-100'
  | 'xl:hover:border-teal-200'
  | 'xl:hover:border-teal-300'
  | 'xl:hover:border-teal-400'
  | 'xl:hover:border-teal-500'
  | 'xl:hover:border-teal-600'
  | 'xl:hover:border-teal-700'
  | 'xl:hover:border-teal-800'
  | 'xl:hover:border-teal-900'
  | 'xl:hover:border-blue-50'
  | 'xl:hover:border-blue-100'
  | 'xl:hover:border-blue-200'
  | 'xl:hover:border-blue-300'
  | 'xl:hover:border-blue-400'
  | 'xl:hover:border-blue-500'
  | 'xl:hover:border-blue-600'
  | 'xl:hover:border-blue-700'
  | 'xl:hover:border-blue-800'
  | 'xl:hover:border-blue-900'
  | 'xl:hover:border-indigo-50'
  | 'xl:hover:border-indigo-100'
  | 'xl:hover:border-indigo-200'
  | 'xl:hover:border-indigo-300'
  | 'xl:hover:border-indigo-400'
  | 'xl:hover:border-indigo-500'
  | 'xl:hover:border-indigo-600'
  | 'xl:hover:border-indigo-700'
  | 'xl:hover:border-indigo-800'
  | 'xl:hover:border-indigo-900'
  | 'xl:hover:border-purple-50'
  | 'xl:hover:border-purple-100'
  | 'xl:hover:border-purple-200'
  | 'xl:hover:border-purple-300'
  | 'xl:hover:border-purple-400'
  | 'xl:hover:border-purple-500'
  | 'xl:hover:border-purple-600'
  | 'xl:hover:border-purple-700'
  | 'xl:hover:border-purple-800'
  | 'xl:hover:border-purple-900'
  | 'xl:hover:border-pink-50'
  | 'xl:hover:border-pink-100'
  | 'xl:hover:border-pink-200'
  | 'xl:hover:border-pink-300'
  | 'xl:hover:border-pink-400'
  | 'xl:hover:border-pink-500'
  | 'xl:hover:border-pink-600'
  | 'xl:hover:border-pink-700'
  | 'xl:hover:border-pink-800'
  | 'xl:hover:border-pink-900'
  | 'xl:focus:border-transparent'
  | 'xl:focus:border-white'
  | 'xl:focus:border-black'
  | 'xl:focus:border-gray-50'
  | 'xl:focus:border-gray-100'
  | 'xl:focus:border-gray-200'
  | 'xl:focus:border-gray-300'
  | 'xl:focus:border-gray-400'
  | 'xl:focus:border-gray-500'
  | 'xl:focus:border-gray-600'
  | 'xl:focus:border-gray-700'
  | 'xl:focus:border-gray-800'
  | 'xl:focus:border-gray-900'
  | 'xl:focus:border-cool-gray-50'
  | 'xl:focus:border-cool-gray-100'
  | 'xl:focus:border-cool-gray-200'
  | 'xl:focus:border-cool-gray-300'
  | 'xl:focus:border-cool-gray-400'
  | 'xl:focus:border-cool-gray-500'
  | 'xl:focus:border-cool-gray-600'
  | 'xl:focus:border-cool-gray-700'
  | 'xl:focus:border-cool-gray-800'
  | 'xl:focus:border-cool-gray-900'
  | 'xl:focus:border-red-50'
  | 'xl:focus:border-red-100'
  | 'xl:focus:border-red-200'
  | 'xl:focus:border-red-300'
  | 'xl:focus:border-red-400'
  | 'xl:focus:border-red-500'
  | 'xl:focus:border-red-600'
  | 'xl:focus:border-red-700'
  | 'xl:focus:border-red-800'
  | 'xl:focus:border-red-900'
  | 'xl:focus:border-orange-50'
  | 'xl:focus:border-orange-100'
  | 'xl:focus:border-orange-200'
  | 'xl:focus:border-orange-300'
  | 'xl:focus:border-orange-400'
  | 'xl:focus:border-orange-500'
  | 'xl:focus:border-orange-600'
  | 'xl:focus:border-orange-700'
  | 'xl:focus:border-orange-800'
  | 'xl:focus:border-orange-900'
  | 'xl:focus:border-yellow-50'
  | 'xl:focus:border-yellow-100'
  | 'xl:focus:border-yellow-200'
  | 'xl:focus:border-yellow-300'
  | 'xl:focus:border-yellow-400'
  | 'xl:focus:border-yellow-500'
  | 'xl:focus:border-yellow-600'
  | 'xl:focus:border-yellow-700'
  | 'xl:focus:border-yellow-800'
  | 'xl:focus:border-yellow-900'
  | 'xl:focus:border-green-50'
  | 'xl:focus:border-green-100'
  | 'xl:focus:border-green-200'
  | 'xl:focus:border-green-300'
  | 'xl:focus:border-green-400'
  | 'xl:focus:border-green-500'
  | 'xl:focus:border-green-600'
  | 'xl:focus:border-green-700'
  | 'xl:focus:border-green-800'
  | 'xl:focus:border-green-900'
  | 'xl:focus:border-teal-50'
  | 'xl:focus:border-teal-100'
  | 'xl:focus:border-teal-200'
  | 'xl:focus:border-teal-300'
  | 'xl:focus:border-teal-400'
  | 'xl:focus:border-teal-500'
  | 'xl:focus:border-teal-600'
  | 'xl:focus:border-teal-700'
  | 'xl:focus:border-teal-800'
  | 'xl:focus:border-teal-900'
  | 'xl:focus:border-blue-50'
  | 'xl:focus:border-blue-100'
  | 'xl:focus:border-blue-200'
  | 'xl:focus:border-blue-300'
  | 'xl:focus:border-blue-400'
  | 'xl:focus:border-blue-500'
  | 'xl:focus:border-blue-600'
  | 'xl:focus:border-blue-700'
  | 'xl:focus:border-blue-800'
  | 'xl:focus:border-blue-900'
  | 'xl:focus:border-indigo-50'
  | 'xl:focus:border-indigo-100'
  | 'xl:focus:border-indigo-200'
  | 'xl:focus:border-indigo-300'
  | 'xl:focus:border-indigo-400'
  | 'xl:focus:border-indigo-500'
  | 'xl:focus:border-indigo-600'
  | 'xl:focus:border-indigo-700'
  | 'xl:focus:border-indigo-800'
  | 'xl:focus:border-indigo-900'
  | 'xl:focus:border-purple-50'
  | 'xl:focus:border-purple-100'
  | 'xl:focus:border-purple-200'
  | 'xl:focus:border-purple-300'
  | 'xl:focus:border-purple-400'
  | 'xl:focus:border-purple-500'
  | 'xl:focus:border-purple-600'
  | 'xl:focus:border-purple-700'
  | 'xl:focus:border-purple-800'
  | 'xl:focus:border-purple-900'
  | 'xl:focus:border-pink-50'
  | 'xl:focus:border-pink-100'
  | 'xl:focus:border-pink-200'
  | 'xl:focus:border-pink-300'
  | 'xl:focus:border-pink-400'
  | 'xl:focus:border-pink-500'
  | 'xl:focus:border-pink-600'
  | 'xl:focus:border-pink-700'
  | 'xl:focus:border-pink-800'
  | 'xl:focus:border-pink-900'
  | 'xl:rounded-none'
  | 'xl:rounded-sm'
  | 'xl:rounded'
  | 'xl:rounded-md'
  | 'xl:rounded-lg'
  | 'xl:rounded-full'
  | 'xl:rounded-t-none'
  | 'xl:rounded-r-none'
  | 'xl:rounded-b-none'
  | 'xl:rounded-l-none'
  | 'xl:rounded-t-sm'
  | 'xl:rounded-r-sm'
  | 'xl:rounded-b-sm'
  | 'xl:rounded-l-sm'
  | 'xl:rounded-t'
  | 'xl:rounded-r'
  | 'xl:rounded-b'
  | 'xl:rounded-l'
  | 'xl:rounded-t-md'
  | 'xl:rounded-r-md'
  | 'xl:rounded-b-md'
  | 'xl:rounded-l-md'
  | 'xl:rounded-t-lg'
  | 'xl:rounded-r-lg'
  | 'xl:rounded-b-lg'
  | 'xl:rounded-l-lg'
  | 'xl:rounded-t-full'
  | 'xl:rounded-r-full'
  | 'xl:rounded-b-full'
  | 'xl:rounded-l-full'
  | 'xl:rounded-tl-none'
  | 'xl:rounded-tr-none'
  | 'xl:rounded-br-none'
  | 'xl:rounded-bl-none'
  | 'xl:rounded-tl-sm'
  | 'xl:rounded-tr-sm'
  | 'xl:rounded-br-sm'
  | 'xl:rounded-bl-sm'
  | 'xl:rounded-tl'
  | 'xl:rounded-tr'
  | 'xl:rounded-br'
  | 'xl:rounded-bl'
  | 'xl:rounded-tl-md'
  | 'xl:rounded-tr-md'
  | 'xl:rounded-br-md'
  | 'xl:rounded-bl-md'
  | 'xl:rounded-tl-lg'
  | 'xl:rounded-tr-lg'
  | 'xl:rounded-br-lg'
  | 'xl:rounded-bl-lg'
  | 'xl:rounded-tl-full'
  | 'xl:rounded-tr-full'
  | 'xl:rounded-br-full'
  | 'xl:rounded-bl-full'
  | 'xl:border-solid'
  | 'xl:border-dashed'
  | 'xl:border-dotted'
  | 'xl:border-double'
  | 'xl:border-none'
  | 'xl:border-0'
  | 'xl:border-2'
  | 'xl:border-4'
  | 'xl:border-8'
  | 'xl:border'
  | 'xl:border-t-0'
  | 'xl:border-r-0'
  | 'xl:border-b-0'
  | 'xl:border-l-0'
  | 'xl:border-t-2'
  | 'xl:border-r-2'
  | 'xl:border-b-2'
  | 'xl:border-l-2'
  | 'xl:border-t-4'
  | 'xl:border-r-4'
  | 'xl:border-b-4'
  | 'xl:border-l-4'
  | 'xl:border-t-8'
  | 'xl:border-r-8'
  | 'xl:border-b-8'
  | 'xl:border-l-8'
  | 'xl:border-t'
  | 'xl:border-r'
  | 'xl:border-b'
  | 'xl:border-l'
  | 'xl:box-border'
  | 'xl:box-content'
  | 'xl:cursor-auto'
  | 'xl:cursor-default'
  | 'xl:cursor-pointer'
  | 'xl:cursor-wait'
  | 'xl:cursor-text'
  | 'xl:cursor-move'
  | 'xl:cursor-not-allowed'
  | 'xl:block'
  | 'xl:inline-block'
  | 'xl:inline'
  | 'xl:flex'
  | 'xl:inline-flex'
  | 'xl:grid'
  | 'xl:table'
  | 'xl:table-caption'
  | 'xl:table-cell'
  | 'xl:table-column'
  | 'xl:table-column-group'
  | 'xl:table-footer-group'
  | 'xl:table-header-group'
  | 'xl:table-row-group'
  | 'xl:table-row'
  | 'xl:hidden'
  | 'xl:flex-row'
  | 'xl:flex-row-reverse'
  | 'xl:flex-col'
  | 'xl:flex-col-reverse'
  | 'xl:flex-wrap'
  | 'xl:flex-wrap-reverse'
  | 'xl:flex-no-wrap'
  | 'xl:items-start'
  | 'xl:items-end'
  | 'xl:items-center'
  | 'xl:items-baseline'
  | 'xl:items-stretch'
  | 'xl:self-auto'
  | 'xl:self-start'
  | 'xl:self-end'
  | 'xl:self-center'
  | 'xl:self-stretch'
  | 'xl:justify-start'
  | 'xl:justify-end'
  | 'xl:justify-center'
  | 'xl:justify-between'
  | 'xl:justify-around'
  | 'xl:justify-evenly'
  | 'xl:content-center'
  | 'xl:content-start'
  | 'xl:content-end'
  | 'xl:content-between'
  | 'xl:content-around'
  | 'xl:flex-1'
  | 'xl:flex-auto'
  | 'xl:flex-initial'
  | 'xl:flex-none'
  | 'xl:flex-grow-0'
  | 'xl:flex-grow'
  | 'xl:flex-shrink-0'
  | 'xl:flex-shrink'
  | 'xl:order-1'
  | 'xl:order-2'
  | 'xl:order-3'
  | 'xl:order-4'
  | 'xl:order-5'
  | 'xl:order-6'
  | 'xl:order-7'
  | 'xl:order-8'
  | 'xl:order-9'
  | 'xl:order-10'
  | 'xl:order-11'
  | 'xl:order-12'
  | 'xl:order-first'
  | 'xl:order-last'
  | 'xl:order-none'
  | 'xl:float-right'
  | 'xl:float-left'
  | 'xl:float-none'
  | 'xl:clearfix'
  | 'xl:clear-left'
  | 'xl:clear-right'
  | 'xl:clear-both'
  | 'xl:font-sans'
  | 'xl:font-serif'
  | 'xl:font-mono'
  | 'xl:font-hairline'
  | 'xl:font-thin'
  | 'xl:font-light'
  | 'xl:font-normal'
  | 'xl:font-medium'
  | 'xl:font-semibold'
  | 'xl:font-bold'
  | 'xl:font-extrabold'
  | 'xl:font-black'
  | 'xl:hover:font-hairline'
  | 'xl:hover:font-thin'
  | 'xl:hover:font-light'
  | 'xl:hover:font-normal'
  | 'xl:hover:font-medium'
  | 'xl:hover:font-semibold'
  | 'xl:hover:font-bold'
  | 'xl:hover:font-extrabold'
  | 'xl:hover:font-black'
  | 'xl:focus:font-hairline'
  | 'xl:focus:font-thin'
  | 'xl:focus:font-light'
  | 'xl:focus:font-normal'
  | 'xl:focus:font-medium'
  | 'xl:focus:font-semibold'
  | 'xl:focus:font-bold'
  | 'xl:focus:font-extrabold'
  | 'xl:focus:font-black'
  | 'xl:h-0'
  | 'xl:h-1'
  | 'xl:h-2'
  | 'xl:h-3'
  | 'xl:h-4'
  | 'xl:h-5'
  | 'xl:h-6'
  | 'xl:h-7'
  | 'xl:h-8'
  | 'xl:h-9'
  | 'xl:h-10'
  | 'xl:h-11'
  | 'xl:h-12'
  | 'xl:h-13'
  | 'xl:h-14'
  | 'xl:h-15'
  | 'xl:h-16'
  | 'xl:h-20'
  | 'xl:h-24'
  | 'xl:h-28'
  | 'xl:h-32'
  | 'xl:h-36'
  | 'xl:h-40'
  | 'xl:h-48'
  | 'xl:h-56'
  | 'xl:h-60'
  | 'xl:h-64'
  | 'xl:h-72'
  | 'xl:h-80'
  | 'xl:h-96'
  | 'xl:h-auto'
  | 'xl:h-px'
  | 'xl:h-0.5'
  | 'xl:h-1.5'
  | 'xl:h-2.5'
  | 'xl:h-3.5'
  | 'xl:h-1/2'
  | 'xl:h-1/3'
  | 'xl:h-2/3'
  | 'xl:h-1/4'
  | 'xl:h-2/4'
  | 'xl:h-3/4'
  | 'xl:h-1/5'
  | 'xl:h-2/5'
  | 'xl:h-3/5'
  | 'xl:h-4/5'
  | 'xl:h-1/6'
  | 'xl:h-2/6'
  | 'xl:h-3/6'
  | 'xl:h-4/6'
  | 'xl:h-5/6'
  | 'xl:h-1/12'
  | 'xl:h-2/12'
  | 'xl:h-3/12'
  | 'xl:h-4/12'
  | 'xl:h-5/12'
  | 'xl:h-6/12'
  | 'xl:h-7/12'
  | 'xl:h-8/12'
  | 'xl:h-9/12'
  | 'xl:h-10/12'
  | 'xl:h-11/12'
  | 'xl:h-full'
  | 'xl:h-screen'
  | 'xl:leading-3'
  | 'xl:leading-4'
  | 'xl:leading-5'
  | 'xl:leading-6'
  | 'xl:leading-7'
  | 'xl:leading-8'
  | 'xl:leading-9'
  | 'xl:leading-10'
  | 'xl:leading-none'
  | 'xl:leading-tight'
  | 'xl:leading-snug'
  | 'xl:leading-normal'
  | 'xl:leading-relaxed'
  | 'xl:leading-loose'
  | 'xl:list-inside'
  | 'xl:list-outside'
  | 'xl:list-none'
  | 'xl:list-disc'
  | 'xl:list-decimal'
  | 'xl:m-0'
  | 'xl:m-1'
  | 'xl:m-2'
  | 'xl:m-3'
  | 'xl:m-4'
  | 'xl:m-5'
  | 'xl:m-6'
  | 'xl:m-7'
  | 'xl:m-8'
  | 'xl:m-9'
  | 'xl:m-10'
  | 'xl:m-11'
  | 'xl:m-12'
  | 'xl:m-13'
  | 'xl:m-14'
  | 'xl:m-15'
  | 'xl:m-16'
  | 'xl:m-20'
  | 'xl:m-24'
  | 'xl:m-28'
  | 'xl:m-32'
  | 'xl:m-36'
  | 'xl:m-40'
  | 'xl:m-48'
  | 'xl:m-56'
  | 'xl:m-60'
  | 'xl:m-64'
  | 'xl:m-72'
  | 'xl:m-80'
  | 'xl:m-96'
  | 'xl:m-auto'
  | 'xl:m-px'
  | 'xl:m-0.5'
  | 'xl:m-1.5'
  | 'xl:m-2.5'
  | 'xl:m-3.5'
  | 'xl:m-1/2'
  | 'xl:m-1/3'
  | 'xl:m-2/3'
  | 'xl:m-1/4'
  | 'xl:m-2/4'
  | 'xl:m-3/4'
  | 'xl:m-1/5'
  | 'xl:m-2/5'
  | 'xl:m-3/5'
  | 'xl:m-4/5'
  | 'xl:m-1/6'
  | 'xl:m-2/6'
  | 'xl:m-3/6'
  | 'xl:m-4/6'
  | 'xl:m-5/6'
  | 'xl:m-1/12'
  | 'xl:m-2/12'
  | 'xl:m-3/12'
  | 'xl:m-4/12'
  | 'xl:m-5/12'
  | 'xl:m-6/12'
  | 'xl:m-7/12'
  | 'xl:m-8/12'
  | 'xl:m-9/12'
  | 'xl:m-10/12'
  | 'xl:m-11/12'
  | 'xl:m-full'
  | 'xl:-m-1'
  | 'xl:-m-2'
  | 'xl:-m-3'
  | 'xl:-m-4'
  | 'xl:-m-5'
  | 'xl:-m-6'
  | 'xl:-m-7'
  | 'xl:-m-8'
  | 'xl:-m-9'
  | 'xl:-m-10'
  | 'xl:-m-11'
  | 'xl:-m-12'
  | 'xl:-m-13'
  | 'xl:-m-14'
  | 'xl:-m-15'
  | 'xl:-m-16'
  | 'xl:-m-20'
  | 'xl:-m-24'
  | 'xl:-m-28'
  | 'xl:-m-32'
  | 'xl:-m-36'
  | 'xl:-m-40'
  | 'xl:-m-48'
  | 'xl:-m-56'
  | 'xl:-m-60'
  | 'xl:-m-64'
  | 'xl:-m-72'
  | 'xl:-m-80'
  | 'xl:-m-96'
  | 'xl:-m-px'
  | 'xl:-m-0.5'
  | 'xl:-m-1.5'
  | 'xl:-m-2.5'
  | 'xl:-m-3.5'
  | 'xl:-m-1/2'
  | 'xl:-m-1/3'
  | 'xl:-m-2/3'
  | 'xl:-m-1/4'
  | 'xl:-m-2/4'
  | 'xl:-m-3/4'
  | 'xl:-m-1/5'
  | 'xl:-m-2/5'
  | 'xl:-m-3/5'
  | 'xl:-m-4/5'
  | 'xl:-m-1/6'
  | 'xl:-m-2/6'
  | 'xl:-m-3/6'
  | 'xl:-m-4/6'
  | 'xl:-m-5/6'
  | 'xl:-m-1/12'
  | 'xl:-m-2/12'
  | 'xl:-m-3/12'
  | 'xl:-m-4/12'
  | 'xl:-m-5/12'
  | 'xl:-m-6/12'
  | 'xl:-m-7/12'
  | 'xl:-m-8/12'
  | 'xl:-m-9/12'
  | 'xl:-m-10/12'
  | 'xl:-m-11/12'
  | 'xl:-m-full'
  | 'xl:my-0'
  | 'xl:mx-0'
  | 'xl:my-1'
  | 'xl:mx-1'
  | 'xl:my-2'
  | 'xl:mx-2'
  | 'xl:my-3'
  | 'xl:mx-3'
  | 'xl:my-4'
  | 'xl:mx-4'
  | 'xl:my-5'
  | 'xl:mx-5'
  | 'xl:my-6'
  | 'xl:mx-6'
  | 'xl:my-7'
  | 'xl:mx-7'
  | 'xl:my-8'
  | 'xl:mx-8'
  | 'xl:my-9'
  | 'xl:mx-9'
  | 'xl:my-10'
  | 'xl:mx-10'
  | 'xl:my-11'
  | 'xl:mx-11'
  | 'xl:my-12'
  | 'xl:mx-12'
  | 'xl:my-13'
  | 'xl:mx-13'
  | 'xl:my-14'
  | 'xl:mx-14'
  | 'xl:my-15'
  | 'xl:mx-15'
  | 'xl:my-16'
  | 'xl:mx-16'
  | 'xl:my-20'
  | 'xl:mx-20'
  | 'xl:my-24'
  | 'xl:mx-24'
  | 'xl:my-28'
  | 'xl:mx-28'
  | 'xl:my-32'
  | 'xl:mx-32'
  | 'xl:my-36'
  | 'xl:mx-36'
  | 'xl:my-40'
  | 'xl:mx-40'
  | 'xl:my-48'
  | 'xl:mx-48'
  | 'xl:my-56'
  | 'xl:mx-56'
  | 'xl:my-60'
  | 'xl:mx-60'
  | 'xl:my-64'
  | 'xl:mx-64'
  | 'xl:my-72'
  | 'xl:mx-72'
  | 'xl:my-80'
  | 'xl:mx-80'
  | 'xl:my-96'
  | 'xl:mx-96'
  | 'xl:my-auto'
  | 'xl:mx-auto'
  | 'xl:my-px'
  | 'xl:mx-px'
  | 'xl:my-0.5'
  | 'xl:mx-0.5'
  | 'xl:my-1.5'
  | 'xl:mx-1.5'
  | 'xl:my-2.5'
  | 'xl:mx-2.5'
  | 'xl:my-3.5'
  | 'xl:mx-3.5'
  | 'xl:my-1/2'
  | 'xl:mx-1/2'
  | 'xl:my-1/3'
  | 'xl:mx-1/3'
  | 'xl:my-2/3'
  | 'xl:mx-2/3'
  | 'xl:my-1/4'
  | 'xl:mx-1/4'
  | 'xl:my-2/4'
  | 'xl:mx-2/4'
  | 'xl:my-3/4'
  | 'xl:mx-3/4'
  | 'xl:my-1/5'
  | 'xl:mx-1/5'
  | 'xl:my-2/5'
  | 'xl:mx-2/5'
  | 'xl:my-3/5'
  | 'xl:mx-3/5'
  | 'xl:my-4/5'
  | 'xl:mx-4/5'
  | 'xl:my-1/6'
  | 'xl:mx-1/6'
  | 'xl:my-2/6'
  | 'xl:mx-2/6'
  | 'xl:my-3/6'
  | 'xl:mx-3/6'
  | 'xl:my-4/6'
  | 'xl:mx-4/6'
  | 'xl:my-5/6'
  | 'xl:mx-5/6'
  | 'xl:my-1/12'
  | 'xl:mx-1/12'
  | 'xl:my-2/12'
  | 'xl:mx-2/12'
  | 'xl:my-3/12'
  | 'xl:mx-3/12'
  | 'xl:my-4/12'
  | 'xl:mx-4/12'
  | 'xl:my-5/12'
  | 'xl:mx-5/12'
  | 'xl:my-6/12'
  | 'xl:mx-6/12'
  | 'xl:my-7/12'
  | 'xl:mx-7/12'
  | 'xl:my-8/12'
  | 'xl:mx-8/12'
  | 'xl:my-9/12'
  | 'xl:mx-9/12'
  | 'xl:my-10/12'
  | 'xl:mx-10/12'
  | 'xl:my-11/12'
  | 'xl:mx-11/12'
  | 'xl:my-full'
  | 'xl:mx-full'
  | 'xl:-my-1'
  | 'xl:-mx-1'
  | 'xl:-my-2'
  | 'xl:-mx-2'
  | 'xl:-my-3'
  | 'xl:-mx-3'
  | 'xl:-my-4'
  | 'xl:-mx-4'
  | 'xl:-my-5'
  | 'xl:-mx-5'
  | 'xl:-my-6'
  | 'xl:-mx-6'
  | 'xl:-my-7'
  | 'xl:-mx-7'
  | 'xl:-my-8'
  | 'xl:-mx-8'
  | 'xl:-my-9'
  | 'xl:-mx-9'
  | 'xl:-my-10'
  | 'xl:-mx-10'
  | 'xl:-my-11'
  | 'xl:-mx-11'
  | 'xl:-my-12'
  | 'xl:-mx-12'
  | 'xl:-my-13'
  | 'xl:-mx-13'
  | 'xl:-my-14'
  | 'xl:-mx-14'
  | 'xl:-my-15'
  | 'xl:-mx-15'
  | 'xl:-my-16'
  | 'xl:-mx-16'
  | 'xl:-my-20'
  | 'xl:-mx-20'
  | 'xl:-my-24'
  | 'xl:-mx-24'
  | 'xl:-my-28'
  | 'xl:-mx-28'
  | 'xl:-my-32'
  | 'xl:-mx-32'
  | 'xl:-my-36'
  | 'xl:-mx-36'
  | 'xl:-my-40'
  | 'xl:-mx-40'
  | 'xl:-my-48'
  | 'xl:-mx-48'
  | 'xl:-my-56'
  | 'xl:-mx-56'
  | 'xl:-my-60'
  | 'xl:-mx-60'
  | 'xl:-my-64'
  | 'xl:-mx-64'
  | 'xl:-my-72'
  | 'xl:-mx-72'
  | 'xl:-my-80'
  | 'xl:-mx-80'
  | 'xl:-my-96'
  | 'xl:-mx-96'
  | 'xl:-my-px'
  | 'xl:-mx-px'
  | 'xl:-my-0.5'
  | 'xl:-mx-0.5'
  | 'xl:-my-1.5'
  | 'xl:-mx-1.5'
  | 'xl:-my-2.5'
  | 'xl:-mx-2.5'
  | 'xl:-my-3.5'
  | 'xl:-mx-3.5'
  | 'xl:-my-1/2'
  | 'xl:-mx-1/2'
  | 'xl:-my-1/3'
  | 'xl:-mx-1/3'
  | 'xl:-my-2/3'
  | 'xl:-mx-2/3'
  | 'xl:-my-1/4'
  | 'xl:-mx-1/4'
  | 'xl:-my-2/4'
  | 'xl:-mx-2/4'
  | 'xl:-my-3/4'
  | 'xl:-mx-3/4'
  | 'xl:-my-1/5'
  | 'xl:-mx-1/5'
  | 'xl:-my-2/5'
  | 'xl:-mx-2/5'
  | 'xl:-my-3/5'
  | 'xl:-mx-3/5'
  | 'xl:-my-4/5'
  | 'xl:-mx-4/5'
  | 'xl:-my-1/6'
  | 'xl:-mx-1/6'
  | 'xl:-my-2/6'
  | 'xl:-mx-2/6'
  | 'xl:-my-3/6'
  | 'xl:-mx-3/6'
  | 'xl:-my-4/6'
  | 'xl:-mx-4/6'
  | 'xl:-my-5/6'
  | 'xl:-mx-5/6'
  | 'xl:-my-1/12'
  | 'xl:-mx-1/12'
  | 'xl:-my-2/12'
  | 'xl:-mx-2/12'
  | 'xl:-my-3/12'
  | 'xl:-mx-3/12'
  | 'xl:-my-4/12'
  | 'xl:-mx-4/12'
  | 'xl:-my-5/12'
  | 'xl:-mx-5/12'
  | 'xl:-my-6/12'
  | 'xl:-mx-6/12'
  | 'xl:-my-7/12'
  | 'xl:-mx-7/12'
  | 'xl:-my-8/12'
  | 'xl:-mx-8/12'
  | 'xl:-my-9/12'
  | 'xl:-mx-9/12'
  | 'xl:-my-10/12'
  | 'xl:-mx-10/12'
  | 'xl:-my-11/12'
  | 'xl:-mx-11/12'
  | 'xl:-my-full'
  | 'xl:-mx-full'
  | 'xl:mt-0'
  | 'xl:mr-0'
  | 'xl:mb-0'
  | 'xl:ml-0'
  | 'xl:mt-1'
  | 'xl:mr-1'
  | 'xl:mb-1'
  | 'xl:ml-1'
  | 'xl:mt-2'
  | 'xl:mr-2'
  | 'xl:mb-2'
  | 'xl:ml-2'
  | 'xl:mt-3'
  | 'xl:mr-3'
  | 'xl:mb-3'
  | 'xl:ml-3'
  | 'xl:mt-4'
  | 'xl:mr-4'
  | 'xl:mb-4'
  | 'xl:ml-4'
  | 'xl:mt-5'
  | 'xl:mr-5'
  | 'xl:mb-5'
  | 'xl:ml-5'
  | 'xl:mt-6'
  | 'xl:mr-6'
  | 'xl:mb-6'
  | 'xl:ml-6'
  | 'xl:mt-7'
  | 'xl:mr-7'
  | 'xl:mb-7'
  | 'xl:ml-7'
  | 'xl:mt-8'
  | 'xl:mr-8'
  | 'xl:mb-8'
  | 'xl:ml-8'
  | 'xl:mt-9'
  | 'xl:mr-9'
  | 'xl:mb-9'
  | 'xl:ml-9'
  | 'xl:mt-10'
  | 'xl:mr-10'
  | 'xl:mb-10'
  | 'xl:ml-10'
  | 'xl:mt-11'
  | 'xl:mr-11'
  | 'xl:mb-11'
  | 'xl:ml-11'
  | 'xl:mt-12'
  | 'xl:mr-12'
  | 'xl:mb-12'
  | 'xl:ml-12'
  | 'xl:mt-13'
  | 'xl:mr-13'
  | 'xl:mb-13'
  | 'xl:ml-13'
  | 'xl:mt-14'
  | 'xl:mr-14'
  | 'xl:mb-14'
  | 'xl:ml-14'
  | 'xl:mt-15'
  | 'xl:mr-15'
  | 'xl:mb-15'
  | 'xl:ml-15'
  | 'xl:mt-16'
  | 'xl:mr-16'
  | 'xl:mb-16'
  | 'xl:ml-16'
  | 'xl:mt-20'
  | 'xl:mr-20'
  | 'xl:mb-20'
  | 'xl:ml-20'
  | 'xl:mt-24'
  | 'xl:mr-24'
  | 'xl:mb-24'
  | 'xl:ml-24'
  | 'xl:mt-28'
  | 'xl:mr-28'
  | 'xl:mb-28'
  | 'xl:ml-28'
  | 'xl:mt-32'
  | 'xl:mr-32'
  | 'xl:mb-32'
  | 'xl:ml-32'
  | 'xl:mt-36'
  | 'xl:mr-36'
  | 'xl:mb-36'
  | 'xl:ml-36'
  | 'xl:mt-40'
  | 'xl:mr-40'
  | 'xl:mb-40'
  | 'xl:ml-40'
  | 'xl:mt-48'
  | 'xl:mr-48'
  | 'xl:mb-48'
  | 'xl:ml-48'
  | 'xl:mt-56'
  | 'xl:mr-56'
  | 'xl:mb-56'
  | 'xl:ml-56'
  | 'xl:mt-60'
  | 'xl:mr-60'
  | 'xl:mb-60'
  | 'xl:ml-60'
  | 'xl:mt-64'
  | 'xl:mr-64'
  | 'xl:mb-64'
  | 'xl:ml-64'
  | 'xl:mt-72'
  | 'xl:mr-72'
  | 'xl:mb-72'
  | 'xl:ml-72'
  | 'xl:mt-80'
  | 'xl:mr-80'
  | 'xl:mb-80'
  | 'xl:ml-80'
  | 'xl:mt-96'
  | 'xl:mr-96'
  | 'xl:mb-96'
  | 'xl:ml-96'
  | 'xl:mt-auto'
  | 'xl:mr-auto'
  | 'xl:mb-auto'
  | 'xl:ml-auto'
  | 'xl:mt-px'
  | 'xl:mr-px'
  | 'xl:mb-px'
  | 'xl:ml-px'
  | 'xl:mt-0.5'
  | 'xl:mr-0.5'
  | 'xl:mb-0.5'
  | 'xl:ml-0.5'
  | 'xl:mt-1.5'
  | 'xl:mr-1.5'
  | 'xl:mb-1.5'
  | 'xl:ml-1.5'
  | 'xl:mt-2.5'
  | 'xl:mr-2.5'
  | 'xl:mb-2.5'
  | 'xl:ml-2.5'
  | 'xl:mt-3.5'
  | 'xl:mr-3.5'
  | 'xl:mb-3.5'
  | 'xl:ml-3.5'
  | 'xl:mt-1/2'
  | 'xl:mr-1/2'
  | 'xl:mb-1/2'
  | 'xl:ml-1/2'
  | 'xl:mt-1/3'
  | 'xl:mr-1/3'
  | 'xl:mb-1/3'
  | 'xl:ml-1/3'
  | 'xl:mt-2/3'
  | 'xl:mr-2/3'
  | 'xl:mb-2/3'
  | 'xl:ml-2/3'
  | 'xl:mt-1/4'
  | 'xl:mr-1/4'
  | 'xl:mb-1/4'
  | 'xl:ml-1/4'
  | 'xl:mt-2/4'
  | 'xl:mr-2/4'
  | 'xl:mb-2/4'
  | 'xl:ml-2/4'
  | 'xl:mt-3/4'
  | 'xl:mr-3/4'
  | 'xl:mb-3/4'
  | 'xl:ml-3/4'
  | 'xl:mt-1/5'
  | 'xl:mr-1/5'
  | 'xl:mb-1/5'
  | 'xl:ml-1/5'
  | 'xl:mt-2/5'
  | 'xl:mr-2/5'
  | 'xl:mb-2/5'
  | 'xl:ml-2/5'
  | 'xl:mt-3/5'
  | 'xl:mr-3/5'
  | 'xl:mb-3/5'
  | 'xl:ml-3/5'
  | 'xl:mt-4/5'
  | 'xl:mr-4/5'
  | 'xl:mb-4/5'
  | 'xl:ml-4/5'
  | 'xl:mt-1/6'
  | 'xl:mr-1/6'
  | 'xl:mb-1/6'
  | 'xl:ml-1/6'
  | 'xl:mt-2/6'
  | 'xl:mr-2/6'
  | 'xl:mb-2/6'
  | 'xl:ml-2/6'
  | 'xl:mt-3/6'
  | 'xl:mr-3/6'
  | 'xl:mb-3/6'
  | 'xl:ml-3/6'
  | 'xl:mt-4/6'
  | 'xl:mr-4/6'
  | 'xl:mb-4/6'
  | 'xl:ml-4/6'
  | 'xl:mt-5/6'
  | 'xl:mr-5/6'
  | 'xl:mb-5/6'
  | 'xl:ml-5/6'
  | 'xl:mt-1/12'
  | 'xl:mr-1/12'
  | 'xl:mb-1/12'
  | 'xl:ml-1/12'
  | 'xl:mt-2/12'
  | 'xl:mr-2/12'
  | 'xl:mb-2/12'
  | 'xl:ml-2/12'
  | 'xl:mt-3/12'
  | 'xl:mr-3/12'
  | 'xl:mb-3/12'
  | 'xl:ml-3/12'
  | 'xl:mt-4/12'
  | 'xl:mr-4/12'
  | 'xl:mb-4/12'
  | 'xl:ml-4/12'
  | 'xl:mt-5/12'
  | 'xl:mr-5/12'
  | 'xl:mb-5/12'
  | 'xl:ml-5/12'
  | 'xl:mt-6/12'
  | 'xl:mr-6/12'
  | 'xl:mb-6/12'
  | 'xl:ml-6/12'
  | 'xl:mt-7/12'
  | 'xl:mr-7/12'
  | 'xl:mb-7/12'
  | 'xl:ml-7/12'
  | 'xl:mt-8/12'
  | 'xl:mr-8/12'
  | 'xl:mb-8/12'
  | 'xl:ml-8/12'
  | 'xl:mt-9/12'
  | 'xl:mr-9/12'
  | 'xl:mb-9/12'
  | 'xl:ml-9/12'
  | 'xl:mt-10/12'
  | 'xl:mr-10/12'
  | 'xl:mb-10/12'
  | 'xl:ml-10/12'
  | 'xl:mt-11/12'
  | 'xl:mr-11/12'
  | 'xl:mb-11/12'
  | 'xl:ml-11/12'
  | 'xl:mt-full'
  | 'xl:mr-full'
  | 'xl:mb-full'
  | 'xl:ml-full'
  | 'xl:-mt-1'
  | 'xl:-mr-1'
  | 'xl:-mb-1'
  | 'xl:-ml-1'
  | 'xl:-mt-2'
  | 'xl:-mr-2'
  | 'xl:-mb-2'
  | 'xl:-ml-2'
  | 'xl:-mt-3'
  | 'xl:-mr-3'
  | 'xl:-mb-3'
  | 'xl:-ml-3'
  | 'xl:-mt-4'
  | 'xl:-mr-4'
  | 'xl:-mb-4'
  | 'xl:-ml-4'
  | 'xl:-mt-5'
  | 'xl:-mr-5'
  | 'xl:-mb-5'
  | 'xl:-ml-5'
  | 'xl:-mt-6'
  | 'xl:-mr-6'
  | 'xl:-mb-6'
  | 'xl:-ml-6'
  | 'xl:-mt-7'
  | 'xl:-mr-7'
  | 'xl:-mb-7'
  | 'xl:-ml-7'
  | 'xl:-mt-8'
  | 'xl:-mr-8'
  | 'xl:-mb-8'
  | 'xl:-ml-8'
  | 'xl:-mt-9'
  | 'xl:-mr-9'
  | 'xl:-mb-9'
  | 'xl:-ml-9'
  | 'xl:-mt-10'
  | 'xl:-mr-10'
  | 'xl:-mb-10'
  | 'xl:-ml-10'
  | 'xl:-mt-11'
  | 'xl:-mr-11'
  | 'xl:-mb-11'
  | 'xl:-ml-11'
  | 'xl:-mt-12'
  | 'xl:-mr-12'
  | 'xl:-mb-12'
  | 'xl:-ml-12'
  | 'xl:-mt-13'
  | 'xl:-mr-13'
  | 'xl:-mb-13'
  | 'xl:-ml-13'
  | 'xl:-mt-14'
  | 'xl:-mr-14'
  | 'xl:-mb-14'
  | 'xl:-ml-14'
  | 'xl:-mt-15'
  | 'xl:-mr-15'
  | 'xl:-mb-15'
  | 'xl:-ml-15'
  | 'xl:-mt-16'
  | 'xl:-mr-16'
  | 'xl:-mb-16'
  | 'xl:-ml-16'
  | 'xl:-mt-20'
  | 'xl:-mr-20'
  | 'xl:-mb-20'
  | 'xl:-ml-20'
  | 'xl:-mt-24'
  | 'xl:-mr-24'
  | 'xl:-mb-24'
  | 'xl:-ml-24'
  | 'xl:-mt-28'
  | 'xl:-mr-28'
  | 'xl:-mb-28'
  | 'xl:-ml-28'
  | 'xl:-mt-32'
  | 'xl:-mr-32'
  | 'xl:-mb-32'
  | 'xl:-ml-32'
  | 'xl:-mt-36'
  | 'xl:-mr-36'
  | 'xl:-mb-36'
  | 'xl:-ml-36'
  | 'xl:-mt-40'
  | 'xl:-mr-40'
  | 'xl:-mb-40'
  | 'xl:-ml-40'
  | 'xl:-mt-48'
  | 'xl:-mr-48'
  | 'xl:-mb-48'
  | 'xl:-ml-48'
  | 'xl:-mt-56'
  | 'xl:-mr-56'
  | 'xl:-mb-56'
  | 'xl:-ml-56'
  | 'xl:-mt-60'
  | 'xl:-mr-60'
  | 'xl:-mb-60'
  | 'xl:-ml-60'
  | 'xl:-mt-64'
  | 'xl:-mr-64'
  | 'xl:-mb-64'
  | 'xl:-ml-64'
  | 'xl:-mt-72'
  | 'xl:-mr-72'
  | 'xl:-mb-72'
  | 'xl:-ml-72'
  | 'xl:-mt-80'
  | 'xl:-mr-80'
  | 'xl:-mb-80'
  | 'xl:-ml-80'
  | 'xl:-mt-96'
  | 'xl:-mr-96'
  | 'xl:-mb-96'
  | 'xl:-ml-96'
  | 'xl:-mt-px'
  | 'xl:-mr-px'
  | 'xl:-mb-px'
  | 'xl:-ml-px'
  | 'xl:-mt-0.5'
  | 'xl:-mr-0.5'
  | 'xl:-mb-0.5'
  | 'xl:-ml-0.5'
  | 'xl:-mt-1.5'
  | 'xl:-mr-1.5'
  | 'xl:-mb-1.5'
  | 'xl:-ml-1.5'
  | 'xl:-mt-2.5'
  | 'xl:-mr-2.5'
  | 'xl:-mb-2.5'
  | 'xl:-ml-2.5'
  | 'xl:-mt-3.5'
  | 'xl:-mr-3.5'
  | 'xl:-mb-3.5'
  | 'xl:-ml-3.5'
  | 'xl:-mt-1/2'
  | 'xl:-mr-1/2'
  | 'xl:-mb-1/2'
  | 'xl:-ml-1/2'
  | 'xl:-mt-1/3'
  | 'xl:-mr-1/3'
  | 'xl:-mb-1/3'
  | 'xl:-ml-1/3'
  | 'xl:-mt-2/3'
  | 'xl:-mr-2/3'
  | 'xl:-mb-2/3'
  | 'xl:-ml-2/3'
  | 'xl:-mt-1/4'
  | 'xl:-mr-1/4'
  | 'xl:-mb-1/4'
  | 'xl:-ml-1/4'
  | 'xl:-mt-2/4'
  | 'xl:-mr-2/4'
  | 'xl:-mb-2/4'
  | 'xl:-ml-2/4'
  | 'xl:-mt-3/4'
  | 'xl:-mr-3/4'
  | 'xl:-mb-3/4'
  | 'xl:-ml-3/4'
  | 'xl:-mt-1/5'
  | 'xl:-mr-1/5'
  | 'xl:-mb-1/5'
  | 'xl:-ml-1/5'
  | 'xl:-mt-2/5'
  | 'xl:-mr-2/5'
  | 'xl:-mb-2/5'
  | 'xl:-ml-2/5'
  | 'xl:-mt-3/5'
  | 'xl:-mr-3/5'
  | 'xl:-mb-3/5'
  | 'xl:-ml-3/5'
  | 'xl:-mt-4/5'
  | 'xl:-mr-4/5'
  | 'xl:-mb-4/5'
  | 'xl:-ml-4/5'
  | 'xl:-mt-1/6'
  | 'xl:-mr-1/6'
  | 'xl:-mb-1/6'
  | 'xl:-ml-1/6'
  | 'xl:-mt-2/6'
  | 'xl:-mr-2/6'
  | 'xl:-mb-2/6'
  | 'xl:-ml-2/6'
  | 'xl:-mt-3/6'
  | 'xl:-mr-3/6'
  | 'xl:-mb-3/6'
  | 'xl:-ml-3/6'
  | 'xl:-mt-4/6'
  | 'xl:-mr-4/6'
  | 'xl:-mb-4/6'
  | 'xl:-ml-4/6'
  | 'xl:-mt-5/6'
  | 'xl:-mr-5/6'
  | 'xl:-mb-5/6'
  | 'xl:-ml-5/6'
  | 'xl:-mt-1/12'
  | 'xl:-mr-1/12'
  | 'xl:-mb-1/12'
  | 'xl:-ml-1/12'
  | 'xl:-mt-2/12'
  | 'xl:-mr-2/12'
  | 'xl:-mb-2/12'
  | 'xl:-ml-2/12'
  | 'xl:-mt-3/12'
  | 'xl:-mr-3/12'
  | 'xl:-mb-3/12'
  | 'xl:-ml-3/12'
  | 'xl:-mt-4/12'
  | 'xl:-mr-4/12'
  | 'xl:-mb-4/12'
  | 'xl:-ml-4/12'
  | 'xl:-mt-5/12'
  | 'xl:-mr-5/12'
  | 'xl:-mb-5/12'
  | 'xl:-ml-5/12'
  | 'xl:-mt-6/12'
  | 'xl:-mr-6/12'
  | 'xl:-mb-6/12'
  | 'xl:-ml-6/12'
  | 'xl:-mt-7/12'
  | 'xl:-mr-7/12'
  | 'xl:-mb-7/12'
  | 'xl:-ml-7/12'
  | 'xl:-mt-8/12'
  | 'xl:-mr-8/12'
  | 'xl:-mb-8/12'
  | 'xl:-ml-8/12'
  | 'xl:-mt-9/12'
  | 'xl:-mr-9/12'
  | 'xl:-mb-9/12'
  | 'xl:-ml-9/12'
  | 'xl:-mt-10/12'
  | 'xl:-mr-10/12'
  | 'xl:-mb-10/12'
  | 'xl:-ml-10/12'
  | 'xl:-mt-11/12'
  | 'xl:-mr-11/12'
  | 'xl:-mb-11/12'
  | 'xl:-ml-11/12'
  | 'xl:-mt-full'
  | 'xl:-mr-full'
  | 'xl:-mb-full'
  | 'xl:-ml-full'
  | 'xl:max-h-0'
  | 'xl:max-h-1'
  | 'xl:max-h-2'
  | 'xl:max-h-3'
  | 'xl:max-h-4'
  | 'xl:max-h-5'
  | 'xl:max-h-6'
  | 'xl:max-h-7'
  | 'xl:max-h-8'
  | 'xl:max-h-9'
  | 'xl:max-h-10'
  | 'xl:max-h-11'
  | 'xl:max-h-12'
  | 'xl:max-h-13'
  | 'xl:max-h-14'
  | 'xl:max-h-15'
  | 'xl:max-h-16'
  | 'xl:max-h-20'
  | 'xl:max-h-24'
  | 'xl:max-h-28'
  | 'xl:max-h-32'
  | 'xl:max-h-36'
  | 'xl:max-h-40'
  | 'xl:max-h-48'
  | 'xl:max-h-56'
  | 'xl:max-h-60'
  | 'xl:max-h-64'
  | 'xl:max-h-72'
  | 'xl:max-h-80'
  | 'xl:max-h-96'
  | 'xl:max-h-screen'
  | 'xl:max-h-px'
  | 'xl:max-h-0.5'
  | 'xl:max-h-1.5'
  | 'xl:max-h-2.5'
  | 'xl:max-h-3.5'
  | 'xl:max-h-1/2'
  | 'xl:max-h-1/3'
  | 'xl:max-h-2/3'
  | 'xl:max-h-1/4'
  | 'xl:max-h-2/4'
  | 'xl:max-h-3/4'
  | 'xl:max-h-1/5'
  | 'xl:max-h-2/5'
  | 'xl:max-h-3/5'
  | 'xl:max-h-4/5'
  | 'xl:max-h-1/6'
  | 'xl:max-h-2/6'
  | 'xl:max-h-3/6'
  | 'xl:max-h-4/6'
  | 'xl:max-h-5/6'
  | 'xl:max-h-1/12'
  | 'xl:max-h-2/12'
  | 'xl:max-h-3/12'
  | 'xl:max-h-4/12'
  | 'xl:max-h-5/12'
  | 'xl:max-h-6/12'
  | 'xl:max-h-7/12'
  | 'xl:max-h-8/12'
  | 'xl:max-h-9/12'
  | 'xl:max-h-10/12'
  | 'xl:max-h-11/12'
  | 'xl:max-h-full'
  | 'xl:max-w-none'
  | 'xl:max-w-xs'
  | 'xl:max-w-sm'
  | 'xl:max-w-md'
  | 'xl:max-w-lg'
  | 'xl:max-w-xl'
  | 'xl:max-w-2xl'
  | 'xl:max-w-3xl'
  | 'xl:max-w-4xl'
  | 'xl:max-w-5xl'
  | 'xl:max-w-6xl'
  | 'xl:max-w-7xl'
  | 'xl:max-w-full'
  | 'xl:max-w-screen-sm'
  | 'xl:max-w-screen-md'
  | 'xl:max-w-screen-lg'
  | 'xl:max-w-screen-xl'
  | 'xl:min-h-0'
  | 'xl:min-h-full'
  | 'xl:min-h-screen'
  | 'xl:min-w-0'
  | 'xl:min-w-full'
  | 'xl:object-contain'
  | 'xl:object-cover'
  | 'xl:object-fill'
  | 'xl:object-none'
  | 'xl:object-scale-down'
  | 'xl:object-bottom'
  | 'xl:object-center'
  | 'xl:object-left'
  | 'xl:object-left-bottom'
  | 'xl:object-left-top'
  | 'xl:object-right'
  | 'xl:object-right-bottom'
  | 'xl:object-right-top'
  | 'xl:object-top'
  | 'xl:opacity-0'
  | 'xl:opacity-25'
  | 'xl:opacity-50'
  | 'xl:opacity-75'
  | 'xl:opacity-100'
  | 'xl:hover:opacity-0'
  | 'xl:hover:opacity-25'
  | 'xl:hover:opacity-50'
  | 'xl:hover:opacity-75'
  | 'xl:hover:opacity-100'
  | 'xl:focus:opacity-0'
  | 'xl:focus:opacity-25'
  | 'xl:focus:opacity-50'
  | 'xl:focus:opacity-75'
  | 'xl:focus:opacity-100'
  | 'xl:outline-none'
  | 'xl:focus:outline-none'
  | 'xl:overflow-auto'
  | 'xl:overflow-hidden'
  | 'xl:overflow-visible'
  | 'xl:overflow-scroll'
  | 'xl:overflow-x-auto'
  | 'xl:overflow-y-auto'
  | 'xl:overflow-x-hidden'
  | 'xl:overflow-y-hidden'
  | 'xl:overflow-x-visible'
  | 'xl:overflow-y-visible'
  | 'xl:overflow-x-scroll'
  | 'xl:overflow-y-scroll'
  | 'xl:scrolling-touch'
  | 'xl:scrolling-auto'
  | 'xl:p-0'
  | 'xl:p-1'
  | 'xl:p-2'
  | 'xl:p-3'
  | 'xl:p-4'
  | 'xl:p-5'
  | 'xl:p-6'
  | 'xl:p-7'
  | 'xl:p-8'
  | 'xl:p-9'
  | 'xl:p-10'
  | 'xl:p-11'
  | 'xl:p-12'
  | 'xl:p-13'
  | 'xl:p-14'
  | 'xl:p-15'
  | 'xl:p-16'
  | 'xl:p-20'
  | 'xl:p-24'
  | 'xl:p-28'
  | 'xl:p-32'
  | 'xl:p-36'
  | 'xl:p-40'
  | 'xl:p-48'
  | 'xl:p-56'
  | 'xl:p-60'
  | 'xl:p-64'
  | 'xl:p-72'
  | 'xl:p-80'
  | 'xl:p-96'
  | 'xl:p-px'
  | 'xl:p-0.5'
  | 'xl:p-1.5'
  | 'xl:p-2.5'
  | 'xl:p-3.5'
  | 'xl:p-1/2'
  | 'xl:p-1/3'
  | 'xl:p-2/3'
  | 'xl:p-1/4'
  | 'xl:p-2/4'
  | 'xl:p-3/4'
  | 'xl:p-1/5'
  | 'xl:p-2/5'
  | 'xl:p-3/5'
  | 'xl:p-4/5'
  | 'xl:p-1/6'
  | 'xl:p-2/6'
  | 'xl:p-3/6'
  | 'xl:p-4/6'
  | 'xl:p-5/6'
  | 'xl:p-1/12'
  | 'xl:p-2/12'
  | 'xl:p-3/12'
  | 'xl:p-4/12'
  | 'xl:p-5/12'
  | 'xl:p-6/12'
  | 'xl:p-7/12'
  | 'xl:p-8/12'
  | 'xl:p-9/12'
  | 'xl:p-10/12'
  | 'xl:p-11/12'
  | 'xl:p-full'
  | 'xl:py-0'
  | 'xl:px-0'
  | 'xl:py-1'
  | 'xl:px-1'
  | 'xl:py-2'
  | 'xl:px-2'
  | 'xl:py-3'
  | 'xl:px-3'
  | 'xl:py-4'
  | 'xl:px-4'
  | 'xl:py-5'
  | 'xl:px-5'
  | 'xl:py-6'
  | 'xl:px-6'
  | 'xl:py-7'
  | 'xl:px-7'
  | 'xl:py-8'
  | 'xl:px-8'
  | 'xl:py-9'
  | 'xl:px-9'
  | 'xl:py-10'
  | 'xl:px-10'
  | 'xl:py-11'
  | 'xl:px-11'
  | 'xl:py-12'
  | 'xl:px-12'
  | 'xl:py-13'
  | 'xl:px-13'
  | 'xl:py-14'
  | 'xl:px-14'
  | 'xl:py-15'
  | 'xl:px-15'
  | 'xl:py-16'
  | 'xl:px-16'
  | 'xl:py-20'
  | 'xl:px-20'
  | 'xl:py-24'
  | 'xl:px-24'
  | 'xl:py-28'
  | 'xl:px-28'
  | 'xl:py-32'
  | 'xl:px-32'
  | 'xl:py-36'
  | 'xl:px-36'
  | 'xl:py-40'
  | 'xl:px-40'
  | 'xl:py-48'
  | 'xl:px-48'
  | 'xl:py-56'
  | 'xl:px-56'
  | 'xl:py-60'
  | 'xl:px-60'
  | 'xl:py-64'
  | 'xl:px-64'
  | 'xl:py-72'
  | 'xl:px-72'
  | 'xl:py-80'
  | 'xl:px-80'
  | 'xl:py-96'
  | 'xl:px-96'
  | 'xl:py-px'
  | 'xl:px-px'
  | 'xl:py-0.5'
  | 'xl:px-0.5'
  | 'xl:py-1.5'
  | 'xl:px-1.5'
  | 'xl:py-2.5'
  | 'xl:px-2.5'
  | 'xl:py-3.5'
  | 'xl:px-3.5'
  | 'xl:py-1/2'
  | 'xl:px-1/2'
  | 'xl:py-1/3'
  | 'xl:px-1/3'
  | 'xl:py-2/3'
  | 'xl:px-2/3'
  | 'xl:py-1/4'
  | 'xl:px-1/4'
  | 'xl:py-2/4'
  | 'xl:px-2/4'
  | 'xl:py-3/4'
  | 'xl:px-3/4'
  | 'xl:py-1/5'
  | 'xl:px-1/5'
  | 'xl:py-2/5'
  | 'xl:px-2/5'
  | 'xl:py-3/5'
  | 'xl:px-3/5'
  | 'xl:py-4/5'
  | 'xl:px-4/5'
  | 'xl:py-1/6'
  | 'xl:px-1/6'
  | 'xl:py-2/6'
  | 'xl:px-2/6'
  | 'xl:py-3/6'
  | 'xl:px-3/6'
  | 'xl:py-4/6'
  | 'xl:px-4/6'
  | 'xl:py-5/6'
  | 'xl:px-5/6'
  | 'xl:py-1/12'
  | 'xl:px-1/12'
  | 'xl:py-2/12'
  | 'xl:px-2/12'
  | 'xl:py-3/12'
  | 'xl:px-3/12'
  | 'xl:py-4/12'
  | 'xl:px-4/12'
  | 'xl:py-5/12'
  | 'xl:px-5/12'
  | 'xl:py-6/12'
  | 'xl:px-6/12'
  | 'xl:py-7/12'
  | 'xl:px-7/12'
  | 'xl:py-8/12'
  | 'xl:px-8/12'
  | 'xl:py-9/12'
  | 'xl:px-9/12'
  | 'xl:py-10/12'
  | 'xl:px-10/12'
  | 'xl:py-11/12'
  | 'xl:px-11/12'
  | 'xl:py-full'
  | 'xl:px-full'
  | 'xl:pt-0'
  | 'xl:pr-0'
  | 'xl:pb-0'
  | 'xl:pl-0'
  | 'xl:pt-1'
  | 'xl:pr-1'
  | 'xl:pb-1'
  | 'xl:pl-1'
  | 'xl:pt-2'
  | 'xl:pr-2'
  | 'xl:pb-2'
  | 'xl:pl-2'
  | 'xl:pt-3'
  | 'xl:pr-3'
  | 'xl:pb-3'
  | 'xl:pl-3'
  | 'xl:pt-4'
  | 'xl:pr-4'
  | 'xl:pb-4'
  | 'xl:pl-4'
  | 'xl:pt-5'
  | 'xl:pr-5'
  | 'xl:pb-5'
  | 'xl:pl-5'
  | 'xl:pt-6'
  | 'xl:pr-6'
  | 'xl:pb-6'
  | 'xl:pl-6'
  | 'xl:pt-7'
  | 'xl:pr-7'
  | 'xl:pb-7'
  | 'xl:pl-7'
  | 'xl:pt-8'
  | 'xl:pr-8'
  | 'xl:pb-8'
  | 'xl:pl-8'
  | 'xl:pt-9'
  | 'xl:pr-9'
  | 'xl:pb-9'
  | 'xl:pl-9'
  | 'xl:pt-10'
  | 'xl:pr-10'
  | 'xl:pb-10'
  | 'xl:pl-10'
  | 'xl:pt-11'
  | 'xl:pr-11'
  | 'xl:pb-11'
  | 'xl:pl-11'
  | 'xl:pt-12'
  | 'xl:pr-12'
  | 'xl:pb-12'
  | 'xl:pl-12'
  | 'xl:pt-13'
  | 'xl:pr-13'
  | 'xl:pb-13'
  | 'xl:pl-13'
  | 'xl:pt-14'
  | 'xl:pr-14'
  | 'xl:pb-14'
  | 'xl:pl-14'
  | 'xl:pt-15'
  | 'xl:pr-15'
  | 'xl:pb-15'
  | 'xl:pl-15'
  | 'xl:pt-16'
  | 'xl:pr-16'
  | 'xl:pb-16'
  | 'xl:pl-16'
  | 'xl:pt-20'
  | 'xl:pr-20'
  | 'xl:pb-20'
  | 'xl:pl-20'
  | 'xl:pt-24'
  | 'xl:pr-24'
  | 'xl:pb-24'
  | 'xl:pl-24'
  | 'xl:pt-28'
  | 'xl:pr-28'
  | 'xl:pb-28'
  | 'xl:pl-28'
  | 'xl:pt-32'
  | 'xl:pr-32'
  | 'xl:pb-32'
  | 'xl:pl-32'
  | 'xl:pt-36'
  | 'xl:pr-36'
  | 'xl:pb-36'
  | 'xl:pl-36'
  | 'xl:pt-40'
  | 'xl:pr-40'
  | 'xl:pb-40'
  | 'xl:pl-40'
  | 'xl:pt-48'
  | 'xl:pr-48'
  | 'xl:pb-48'
  | 'xl:pl-48'
  | 'xl:pt-56'
  | 'xl:pr-56'
  | 'xl:pb-56'
  | 'xl:pl-56'
  | 'xl:pt-60'
  | 'xl:pr-60'
  | 'xl:pb-60'
  | 'xl:pl-60'
  | 'xl:pt-64'
  | 'xl:pr-64'
  | 'xl:pb-64'
  | 'xl:pl-64'
  | 'xl:pt-72'
  | 'xl:pr-72'
  | 'xl:pb-72'
  | 'xl:pl-72'
  | 'xl:pt-80'
  | 'xl:pr-80'
  | 'xl:pb-80'
  | 'xl:pl-80'
  | 'xl:pt-96'
  | 'xl:pr-96'
  | 'xl:pb-96'
  | 'xl:pl-96'
  | 'xl:pt-px'
  | 'xl:pr-px'
  | 'xl:pb-px'
  | 'xl:pl-px'
  | 'xl:pt-0.5'
  | 'xl:pr-0.5'
  | 'xl:pb-0.5'
  | 'xl:pl-0.5'
  | 'xl:pt-1.5'
  | 'xl:pr-1.5'
  | 'xl:pb-1.5'
  | 'xl:pl-1.5'
  | 'xl:pt-2.5'
  | 'xl:pr-2.5'
  | 'xl:pb-2.5'
  | 'xl:pl-2.5'
  | 'xl:pt-3.5'
  | 'xl:pr-3.5'
  | 'xl:pb-3.5'
  | 'xl:pl-3.5'
  | 'xl:pt-1/2'
  | 'xl:pr-1/2'
  | 'xl:pb-1/2'
  | 'xl:pl-1/2'
  | 'xl:pt-1/3'
  | 'xl:pr-1/3'
  | 'xl:pb-1/3'
  | 'xl:pl-1/3'
  | 'xl:pt-2/3'
  | 'xl:pr-2/3'
  | 'xl:pb-2/3'
  | 'xl:pl-2/3'
  | 'xl:pt-1/4'
  | 'xl:pr-1/4'
  | 'xl:pb-1/4'
  | 'xl:pl-1/4'
  | 'xl:pt-2/4'
  | 'xl:pr-2/4'
  | 'xl:pb-2/4'
  | 'xl:pl-2/4'
  | 'xl:pt-3/4'
  | 'xl:pr-3/4'
  | 'xl:pb-3/4'
  | 'xl:pl-3/4'
  | 'xl:pt-1/5'
  | 'xl:pr-1/5'
  | 'xl:pb-1/5'
  | 'xl:pl-1/5'
  | 'xl:pt-2/5'
  | 'xl:pr-2/5'
  | 'xl:pb-2/5'
  | 'xl:pl-2/5'
  | 'xl:pt-3/5'
  | 'xl:pr-3/5'
  | 'xl:pb-3/5'
  | 'xl:pl-3/5'
  | 'xl:pt-4/5'
  | 'xl:pr-4/5'
  | 'xl:pb-4/5'
  | 'xl:pl-4/5'
  | 'xl:pt-1/6'
  | 'xl:pr-1/6'
  | 'xl:pb-1/6'
  | 'xl:pl-1/6'
  | 'xl:pt-2/6'
  | 'xl:pr-2/6'
  | 'xl:pb-2/6'
  | 'xl:pl-2/6'
  | 'xl:pt-3/6'
  | 'xl:pr-3/6'
  | 'xl:pb-3/6'
  | 'xl:pl-3/6'
  | 'xl:pt-4/6'
  | 'xl:pr-4/6'
  | 'xl:pb-4/6'
  | 'xl:pl-4/6'
  | 'xl:pt-5/6'
  | 'xl:pr-5/6'
  | 'xl:pb-5/6'
  | 'xl:pl-5/6'
  | 'xl:pt-1/12'
  | 'xl:pr-1/12'
  | 'xl:pb-1/12'
  | 'xl:pl-1/12'
  | 'xl:pt-2/12'
  | 'xl:pr-2/12'
  | 'xl:pb-2/12'
  | 'xl:pl-2/12'
  | 'xl:pt-3/12'
  | 'xl:pr-3/12'
  | 'xl:pb-3/12'
  | 'xl:pl-3/12'
  | 'xl:pt-4/12'
  | 'xl:pr-4/12'
  | 'xl:pb-4/12'
  | 'xl:pl-4/12'
  | 'xl:pt-5/12'
  | 'xl:pr-5/12'
  | 'xl:pb-5/12'
  | 'xl:pl-5/12'
  | 'xl:pt-6/12'
  | 'xl:pr-6/12'
  | 'xl:pb-6/12'
  | 'xl:pl-6/12'
  | 'xl:pt-7/12'
  | 'xl:pr-7/12'
  | 'xl:pb-7/12'
  | 'xl:pl-7/12'
  | 'xl:pt-8/12'
  | 'xl:pr-8/12'
  | 'xl:pb-8/12'
  | 'xl:pl-8/12'
  | 'xl:pt-9/12'
  | 'xl:pr-9/12'
  | 'xl:pb-9/12'
  | 'xl:pl-9/12'
  | 'xl:pt-10/12'
  | 'xl:pr-10/12'
  | 'xl:pb-10/12'
  | 'xl:pl-10/12'
  | 'xl:pt-11/12'
  | 'xl:pr-11/12'
  | 'xl:pb-11/12'
  | 'xl:pl-11/12'
  | 'xl:pt-full'
  | 'xl:pr-full'
  | 'xl:pb-full'
  | 'xl:pl-full'
  | 'xl:placeholder-transparent'
  | 'xl:placeholder-white'
  | 'xl:placeholder-black'
  | 'xl:placeholder-gray-50'
  | 'xl:placeholder-gray-100'
  | 'xl:placeholder-gray-200'
  | 'xl:placeholder-gray-300'
  | 'xl:placeholder-gray-400'
  | 'xl:placeholder-gray-500'
  | 'xl:placeholder-gray-600'
  | 'xl:placeholder-gray-700'
  | 'xl:placeholder-gray-800'
  | 'xl:placeholder-gray-900'
  | 'xl:placeholder-cool-gray-50'
  | 'xl:placeholder-cool-gray-100'
  | 'xl:placeholder-cool-gray-200'
  | 'xl:placeholder-cool-gray-300'
  | 'xl:placeholder-cool-gray-400'
  | 'xl:placeholder-cool-gray-500'
  | 'xl:placeholder-cool-gray-600'
  | 'xl:placeholder-cool-gray-700'
  | 'xl:placeholder-cool-gray-800'
  | 'xl:placeholder-cool-gray-900'
  | 'xl:placeholder-red-50'
  | 'xl:placeholder-red-100'
  | 'xl:placeholder-red-200'
  | 'xl:placeholder-red-300'
  | 'xl:placeholder-red-400'
  | 'xl:placeholder-red-500'
  | 'xl:placeholder-red-600'
  | 'xl:placeholder-red-700'
  | 'xl:placeholder-red-800'
  | 'xl:placeholder-red-900'
  | 'xl:placeholder-orange-50'
  | 'xl:placeholder-orange-100'
  | 'xl:placeholder-orange-200'
  | 'xl:placeholder-orange-300'
  | 'xl:placeholder-orange-400'
  | 'xl:placeholder-orange-500'
  | 'xl:placeholder-orange-600'
  | 'xl:placeholder-orange-700'
  | 'xl:placeholder-orange-800'
  | 'xl:placeholder-orange-900'
  | 'xl:placeholder-yellow-50'
  | 'xl:placeholder-yellow-100'
  | 'xl:placeholder-yellow-200'
  | 'xl:placeholder-yellow-300'
  | 'xl:placeholder-yellow-400'
  | 'xl:placeholder-yellow-500'
  | 'xl:placeholder-yellow-600'
  | 'xl:placeholder-yellow-700'
  | 'xl:placeholder-yellow-800'
  | 'xl:placeholder-yellow-900'
  | 'xl:placeholder-green-50'
  | 'xl:placeholder-green-100'
  | 'xl:placeholder-green-200'
  | 'xl:placeholder-green-300'
  | 'xl:placeholder-green-400'
  | 'xl:placeholder-green-500'
  | 'xl:placeholder-green-600'
  | 'xl:placeholder-green-700'
  | 'xl:placeholder-green-800'
  | 'xl:placeholder-green-900'
  | 'xl:placeholder-teal-50'
  | 'xl:placeholder-teal-100'
  | 'xl:placeholder-teal-200'
  | 'xl:placeholder-teal-300'
  | 'xl:placeholder-teal-400'
  | 'xl:placeholder-teal-500'
  | 'xl:placeholder-teal-600'
  | 'xl:placeholder-teal-700'
  | 'xl:placeholder-teal-800'
  | 'xl:placeholder-teal-900'
  | 'xl:placeholder-blue-50'
  | 'xl:placeholder-blue-100'
  | 'xl:placeholder-blue-200'
  | 'xl:placeholder-blue-300'
  | 'xl:placeholder-blue-400'
  | 'xl:placeholder-blue-500'
  | 'xl:placeholder-blue-600'
  | 'xl:placeholder-blue-700'
  | 'xl:placeholder-blue-800'
  | 'xl:placeholder-blue-900'
  | 'xl:placeholder-indigo-50'
  | 'xl:placeholder-indigo-100'
  | 'xl:placeholder-indigo-200'
  | 'xl:placeholder-indigo-300'
  | 'xl:placeholder-indigo-400'
  | 'xl:placeholder-indigo-500'
  | 'xl:placeholder-indigo-600'
  | 'xl:placeholder-indigo-700'
  | 'xl:placeholder-indigo-800'
  | 'xl:placeholder-indigo-900'
  | 'xl:placeholder-purple-50'
  | 'xl:placeholder-purple-100'
  | 'xl:placeholder-purple-200'
  | 'xl:placeholder-purple-300'
  | 'xl:placeholder-purple-400'
  | 'xl:placeholder-purple-500'
  | 'xl:placeholder-purple-600'
  | 'xl:placeholder-purple-700'
  | 'xl:placeholder-purple-800'
  | 'xl:placeholder-purple-900'
  | 'xl:placeholder-pink-50'
  | 'xl:placeholder-pink-100'
  | 'xl:placeholder-pink-200'
  | 'xl:placeholder-pink-300'
  | 'xl:placeholder-pink-400'
  | 'xl:placeholder-pink-500'
  | 'xl:placeholder-pink-600'
  | 'xl:placeholder-pink-700'
  | 'xl:placeholder-pink-800'
  | 'xl:placeholder-pink-900'
  | 'xl:focus:placeholder-transparent'
  | 'xl:focus:placeholder-white'
  | 'xl:focus:placeholder-black'
  | 'xl:focus:placeholder-gray-50'
  | 'xl:focus:placeholder-gray-100'
  | 'xl:focus:placeholder-gray-200'
  | 'xl:focus:placeholder-gray-300'
  | 'xl:focus:placeholder-gray-400'
  | 'xl:focus:placeholder-gray-500'
  | 'xl:focus:placeholder-gray-600'
  | 'xl:focus:placeholder-gray-700'
  | 'xl:focus:placeholder-gray-800'
  | 'xl:focus:placeholder-gray-900'
  | 'xl:focus:placeholder-cool-gray-50'
  | 'xl:focus:placeholder-cool-gray-100'
  | 'xl:focus:placeholder-cool-gray-200'
  | 'xl:focus:placeholder-cool-gray-300'
  | 'xl:focus:placeholder-cool-gray-400'
  | 'xl:focus:placeholder-cool-gray-500'
  | 'xl:focus:placeholder-cool-gray-600'
  | 'xl:focus:placeholder-cool-gray-700'
  | 'xl:focus:placeholder-cool-gray-800'
  | 'xl:focus:placeholder-cool-gray-900'
  | 'xl:focus:placeholder-red-50'
  | 'xl:focus:placeholder-red-100'
  | 'xl:focus:placeholder-red-200'
  | 'xl:focus:placeholder-red-300'
  | 'xl:focus:placeholder-red-400'
  | 'xl:focus:placeholder-red-500'
  | 'xl:focus:placeholder-red-600'
  | 'xl:focus:placeholder-red-700'
  | 'xl:focus:placeholder-red-800'
  | 'xl:focus:placeholder-red-900'
  | 'xl:focus:placeholder-orange-50'
  | 'xl:focus:placeholder-orange-100'
  | 'xl:focus:placeholder-orange-200'
  | 'xl:focus:placeholder-orange-300'
  | 'xl:focus:placeholder-orange-400'
  | 'xl:focus:placeholder-orange-500'
  | 'xl:focus:placeholder-orange-600'
  | 'xl:focus:placeholder-orange-700'
  | 'xl:focus:placeholder-orange-800'
  | 'xl:focus:placeholder-orange-900'
  | 'xl:focus:placeholder-yellow-50'
  | 'xl:focus:placeholder-yellow-100'
  | 'xl:focus:placeholder-yellow-200'
  | 'xl:focus:placeholder-yellow-300'
  | 'xl:focus:placeholder-yellow-400'
  | 'xl:focus:placeholder-yellow-500'
  | 'xl:focus:placeholder-yellow-600'
  | 'xl:focus:placeholder-yellow-700'
  | 'xl:focus:placeholder-yellow-800'
  | 'xl:focus:placeholder-yellow-900'
  | 'xl:focus:placeholder-green-50'
  | 'xl:focus:placeholder-green-100'
  | 'xl:focus:placeholder-green-200'
  | 'xl:focus:placeholder-green-300'
  | 'xl:focus:placeholder-green-400'
  | 'xl:focus:placeholder-green-500'
  | 'xl:focus:placeholder-green-600'
  | 'xl:focus:placeholder-green-700'
  | 'xl:focus:placeholder-green-800'
  | 'xl:focus:placeholder-green-900'
  | 'xl:focus:placeholder-teal-50'
  | 'xl:focus:placeholder-teal-100'
  | 'xl:focus:placeholder-teal-200'
  | 'xl:focus:placeholder-teal-300'
  | 'xl:focus:placeholder-teal-400'
  | 'xl:focus:placeholder-teal-500'
  | 'xl:focus:placeholder-teal-600'
  | 'xl:focus:placeholder-teal-700'
  | 'xl:focus:placeholder-teal-800'
  | 'xl:focus:placeholder-teal-900'
  | 'xl:focus:placeholder-blue-50'
  | 'xl:focus:placeholder-blue-100'
  | 'xl:focus:placeholder-blue-200'
  | 'xl:focus:placeholder-blue-300'
  | 'xl:focus:placeholder-blue-400'
  | 'xl:focus:placeholder-blue-500'
  | 'xl:focus:placeholder-blue-600'
  | 'xl:focus:placeholder-blue-700'
  | 'xl:focus:placeholder-blue-800'
  | 'xl:focus:placeholder-blue-900'
  | 'xl:focus:placeholder-indigo-50'
  | 'xl:focus:placeholder-indigo-100'
  | 'xl:focus:placeholder-indigo-200'
  | 'xl:focus:placeholder-indigo-300'
  | 'xl:focus:placeholder-indigo-400'
  | 'xl:focus:placeholder-indigo-500'
  | 'xl:focus:placeholder-indigo-600'
  | 'xl:focus:placeholder-indigo-700'
  | 'xl:focus:placeholder-indigo-800'
  | 'xl:focus:placeholder-indigo-900'
  | 'xl:focus:placeholder-purple-50'
  | 'xl:focus:placeholder-purple-100'
  | 'xl:focus:placeholder-purple-200'
  | 'xl:focus:placeholder-purple-300'
  | 'xl:focus:placeholder-purple-400'
  | 'xl:focus:placeholder-purple-500'
  | 'xl:focus:placeholder-purple-600'
  | 'xl:focus:placeholder-purple-700'
  | 'xl:focus:placeholder-purple-800'
  | 'xl:focus:placeholder-purple-900'
  | 'xl:focus:placeholder-pink-50'
  | 'xl:focus:placeholder-pink-100'
  | 'xl:focus:placeholder-pink-200'
  | 'xl:focus:placeholder-pink-300'
  | 'xl:focus:placeholder-pink-400'
  | 'xl:focus:placeholder-pink-500'
  | 'xl:focus:placeholder-pink-600'
  | 'xl:focus:placeholder-pink-700'
  | 'xl:focus:placeholder-pink-800'
  | 'xl:focus:placeholder-pink-900'
  | 'xl:pointer-events-none'
  | 'xl:pointer-events-auto'
  | 'xl:static'
  | 'xl:fixed'
  | 'xl:absolute'
  | 'xl:relative'
  | 'xl:sticky'
  | 'xl:inset-0'
  | 'xl:inset-1'
  | 'xl:inset-2'
  | 'xl:inset-3'
  | 'xl:inset-4'
  | 'xl:inset-5'
  | 'xl:inset-6'
  | 'xl:inset-7'
  | 'xl:inset-8'
  | 'xl:inset-9'
  | 'xl:inset-10'
  | 'xl:inset-11'
  | 'xl:inset-12'
  | 'xl:inset-13'
  | 'xl:inset-14'
  | 'xl:inset-15'
  | 'xl:inset-16'
  | 'xl:inset-20'
  | 'xl:inset-24'
  | 'xl:inset-28'
  | 'xl:inset-32'
  | 'xl:inset-36'
  | 'xl:inset-40'
  | 'xl:inset-48'
  | 'xl:inset-56'
  | 'xl:inset-60'
  | 'xl:inset-64'
  | 'xl:inset-72'
  | 'xl:inset-80'
  | 'xl:inset-96'
  | 'xl:inset-auto'
  | 'xl:inset-px'
  | 'xl:inset-0.5'
  | 'xl:inset-1.5'
  | 'xl:inset-2.5'
  | 'xl:inset-3.5'
  | 'xl:inset-1/2'
  | 'xl:inset-1/3'
  | 'xl:inset-2/3'
  | 'xl:inset-1/4'
  | 'xl:inset-2/4'
  | 'xl:inset-3/4'
  | 'xl:inset-1/5'
  | 'xl:inset-2/5'
  | 'xl:inset-3/5'
  | 'xl:inset-4/5'
  | 'xl:inset-1/6'
  | 'xl:inset-2/6'
  | 'xl:inset-3/6'
  | 'xl:inset-4/6'
  | 'xl:inset-5/6'
  | 'xl:inset-1/12'
  | 'xl:inset-2/12'
  | 'xl:inset-3/12'
  | 'xl:inset-4/12'
  | 'xl:inset-5/12'
  | 'xl:inset-6/12'
  | 'xl:inset-7/12'
  | 'xl:inset-8/12'
  | 'xl:inset-9/12'
  | 'xl:inset-10/12'
  | 'xl:inset-11/12'
  | 'xl:inset-full'
  | 'xl:inset-y-0'
  | 'xl:inset-x-0'
  | 'xl:inset-y-1'
  | 'xl:inset-x-1'
  | 'xl:inset-y-2'
  | 'xl:inset-x-2'
  | 'xl:inset-y-3'
  | 'xl:inset-x-3'
  | 'xl:inset-y-4'
  | 'xl:inset-x-4'
  | 'xl:inset-y-5'
  | 'xl:inset-x-5'
  | 'xl:inset-y-6'
  | 'xl:inset-x-6'
  | 'xl:inset-y-7'
  | 'xl:inset-x-7'
  | 'xl:inset-y-8'
  | 'xl:inset-x-8'
  | 'xl:inset-y-9'
  | 'xl:inset-x-9'
  | 'xl:inset-y-10'
  | 'xl:inset-x-10'
  | 'xl:inset-y-11'
  | 'xl:inset-x-11'
  | 'xl:inset-y-12'
  | 'xl:inset-x-12'
  | 'xl:inset-y-13'
  | 'xl:inset-x-13'
  | 'xl:inset-y-14'
  | 'xl:inset-x-14'
  | 'xl:inset-y-15'
  | 'xl:inset-x-15'
  | 'xl:inset-y-16'
  | 'xl:inset-x-16'
  | 'xl:inset-y-20'
  | 'xl:inset-x-20'
  | 'xl:inset-y-24'
  | 'xl:inset-x-24'
  | 'xl:inset-y-28'
  | 'xl:inset-x-28'
  | 'xl:inset-y-32'
  | 'xl:inset-x-32'
  | 'xl:inset-y-36'
  | 'xl:inset-x-36'
  | 'xl:inset-y-40'
  | 'xl:inset-x-40'
  | 'xl:inset-y-48'
  | 'xl:inset-x-48'
  | 'xl:inset-y-56'
  | 'xl:inset-x-56'
  | 'xl:inset-y-60'
  | 'xl:inset-x-60'
  | 'xl:inset-y-64'
  | 'xl:inset-x-64'
  | 'xl:inset-y-72'
  | 'xl:inset-x-72'
  | 'xl:inset-y-80'
  | 'xl:inset-x-80'
  | 'xl:inset-y-96'
  | 'xl:inset-x-96'
  | 'xl:inset-y-auto'
  | 'xl:inset-x-auto'
  | 'xl:inset-y-px'
  | 'xl:inset-x-px'
  | 'xl:inset-y-0.5'
  | 'xl:inset-x-0.5'
  | 'xl:inset-y-1.5'
  | 'xl:inset-x-1.5'
  | 'xl:inset-y-2.5'
  | 'xl:inset-x-2.5'
  | 'xl:inset-y-3.5'
  | 'xl:inset-x-3.5'
  | 'xl:inset-y-1/2'
  | 'xl:inset-x-1/2'
  | 'xl:inset-y-1/3'
  | 'xl:inset-x-1/3'
  | 'xl:inset-y-2/3'
  | 'xl:inset-x-2/3'
  | 'xl:inset-y-1/4'
  | 'xl:inset-x-1/4'
  | 'xl:inset-y-2/4'
  | 'xl:inset-x-2/4'
  | 'xl:inset-y-3/4'
  | 'xl:inset-x-3/4'
  | 'xl:inset-y-1/5'
  | 'xl:inset-x-1/5'
  | 'xl:inset-y-2/5'
  | 'xl:inset-x-2/5'
  | 'xl:inset-y-3/5'
  | 'xl:inset-x-3/5'
  | 'xl:inset-y-4/5'
  | 'xl:inset-x-4/5'
  | 'xl:inset-y-1/6'
  | 'xl:inset-x-1/6'
  | 'xl:inset-y-2/6'
  | 'xl:inset-x-2/6'
  | 'xl:inset-y-3/6'
  | 'xl:inset-x-3/6'
  | 'xl:inset-y-4/6'
  | 'xl:inset-x-4/6'
  | 'xl:inset-y-5/6'
  | 'xl:inset-x-5/6'
  | 'xl:inset-y-1/12'
  | 'xl:inset-x-1/12'
  | 'xl:inset-y-2/12'
  | 'xl:inset-x-2/12'
  | 'xl:inset-y-3/12'
  | 'xl:inset-x-3/12'
  | 'xl:inset-y-4/12'
  | 'xl:inset-x-4/12'
  | 'xl:inset-y-5/12'
  | 'xl:inset-x-5/12'
  | 'xl:inset-y-6/12'
  | 'xl:inset-x-6/12'
  | 'xl:inset-y-7/12'
  | 'xl:inset-x-7/12'
  | 'xl:inset-y-8/12'
  | 'xl:inset-x-8/12'
  | 'xl:inset-y-9/12'
  | 'xl:inset-x-9/12'
  | 'xl:inset-y-10/12'
  | 'xl:inset-x-10/12'
  | 'xl:inset-y-11/12'
  | 'xl:inset-x-11/12'
  | 'xl:inset-y-full'
  | 'xl:inset-x-full'
  | 'xl:top-0'
  | 'xl:right-0'
  | 'xl:bottom-0'
  | 'xl:left-0'
  | 'xl:top-1'
  | 'xl:right-1'
  | 'xl:bottom-1'
  | 'xl:left-1'
  | 'xl:top-2'
  | 'xl:right-2'
  | 'xl:bottom-2'
  | 'xl:left-2'
  | 'xl:top-3'
  | 'xl:right-3'
  | 'xl:bottom-3'
  | 'xl:left-3'
  | 'xl:top-4'
  | 'xl:right-4'
  | 'xl:bottom-4'
  | 'xl:left-4'
  | 'xl:top-5'
  | 'xl:right-5'
  | 'xl:bottom-5'
  | 'xl:left-5'
  | 'xl:top-6'
  | 'xl:right-6'
  | 'xl:bottom-6'
  | 'xl:left-6'
  | 'xl:top-7'
  | 'xl:right-7'
  | 'xl:bottom-7'
  | 'xl:left-7'
  | 'xl:top-8'
  | 'xl:right-8'
  | 'xl:bottom-8'
  | 'xl:left-8'
  | 'xl:top-9'
  | 'xl:right-9'
  | 'xl:bottom-9'
  | 'xl:left-9'
  | 'xl:top-10'
  | 'xl:right-10'
  | 'xl:bottom-10'
  | 'xl:left-10'
  | 'xl:top-11'
  | 'xl:right-11'
  | 'xl:bottom-11'
  | 'xl:left-11'
  | 'xl:top-12'
  | 'xl:right-12'
  | 'xl:bottom-12'
  | 'xl:left-12'
  | 'xl:top-13'
  | 'xl:right-13'
  | 'xl:bottom-13'
  | 'xl:left-13'
  | 'xl:top-14'
  | 'xl:right-14'
  | 'xl:bottom-14'
  | 'xl:left-14'
  | 'xl:top-15'
  | 'xl:right-15'
  | 'xl:bottom-15'
  | 'xl:left-15'
  | 'xl:top-16'
  | 'xl:right-16'
  | 'xl:bottom-16'
  | 'xl:left-16'
  | 'xl:top-20'
  | 'xl:right-20'
  | 'xl:bottom-20'
  | 'xl:left-20'
  | 'xl:top-24'
  | 'xl:right-24'
  | 'xl:bottom-24'
  | 'xl:left-24'
  | 'xl:top-28'
  | 'xl:right-28'
  | 'xl:bottom-28'
  | 'xl:left-28'
  | 'xl:top-32'
  | 'xl:right-32'
  | 'xl:bottom-32'
  | 'xl:left-32'
  | 'xl:top-36'
  | 'xl:right-36'
  | 'xl:bottom-36'
  | 'xl:left-36'
  | 'xl:top-40'
  | 'xl:right-40'
  | 'xl:bottom-40'
  | 'xl:left-40'
  | 'xl:top-48'
  | 'xl:right-48'
  | 'xl:bottom-48'
  | 'xl:left-48'
  | 'xl:top-56'
  | 'xl:right-56'
  | 'xl:bottom-56'
  | 'xl:left-56'
  | 'xl:top-60'
  | 'xl:right-60'
  | 'xl:bottom-60'
  | 'xl:left-60'
  | 'xl:top-64'
  | 'xl:right-64'
  | 'xl:bottom-64'
  | 'xl:left-64'
  | 'xl:top-72'
  | 'xl:right-72'
  | 'xl:bottom-72'
  | 'xl:left-72'
  | 'xl:top-80'
  | 'xl:right-80'
  | 'xl:bottom-80'
  | 'xl:left-80'
  | 'xl:top-96'
  | 'xl:right-96'
  | 'xl:bottom-96'
  | 'xl:left-96'
  | 'xl:top-auto'
  | 'xl:right-auto'
  | 'xl:bottom-auto'
  | 'xl:left-auto'
  | 'xl:top-px'
  | 'xl:right-px'
  | 'xl:bottom-px'
  | 'xl:left-px'
  | 'xl:top-0.5'
  | 'xl:right-0.5'
  | 'xl:bottom-0.5'
  | 'xl:left-0.5'
  | 'xl:top-1.5'
  | 'xl:right-1.5'
  | 'xl:bottom-1.5'
  | 'xl:left-1.5'
  | 'xl:top-2.5'
  | 'xl:right-2.5'
  | 'xl:bottom-2.5'
  | 'xl:left-2.5'
  | 'xl:top-3.5'
  | 'xl:right-3.5'
  | 'xl:bottom-3.5'
  | 'xl:left-3.5'
  | 'xl:top-1/2'
  | 'xl:right-1/2'
  | 'xl:bottom-1/2'
  | 'xl:left-1/2'
  | 'xl:top-1/3'
  | 'xl:right-1/3'
  | 'xl:bottom-1/3'
  | 'xl:left-1/3'
  | 'xl:top-2/3'
  | 'xl:right-2/3'
  | 'xl:bottom-2/3'
  | 'xl:left-2/3'
  | 'xl:top-1/4'
  | 'xl:right-1/4'
  | 'xl:bottom-1/4'
  | 'xl:left-1/4'
  | 'xl:top-2/4'
  | 'xl:right-2/4'
  | 'xl:bottom-2/4'
  | 'xl:left-2/4'
  | 'xl:top-3/4'
  | 'xl:right-3/4'
  | 'xl:bottom-3/4'
  | 'xl:left-3/4'
  | 'xl:top-1/5'
  | 'xl:right-1/5'
  | 'xl:bottom-1/5'
  | 'xl:left-1/5'
  | 'xl:top-2/5'
  | 'xl:right-2/5'
  | 'xl:bottom-2/5'
  | 'xl:left-2/5'
  | 'xl:top-3/5'
  | 'xl:right-3/5'
  | 'xl:bottom-3/5'
  | 'xl:left-3/5'
  | 'xl:top-4/5'
  | 'xl:right-4/5'
  | 'xl:bottom-4/5'
  | 'xl:left-4/5'
  | 'xl:top-1/6'
  | 'xl:right-1/6'
  | 'xl:bottom-1/6'
  | 'xl:left-1/6'
  | 'xl:top-2/6'
  | 'xl:right-2/6'
  | 'xl:bottom-2/6'
  | 'xl:left-2/6'
  | 'xl:top-3/6'
  | 'xl:right-3/6'
  | 'xl:bottom-3/6'
  | 'xl:left-3/6'
  | 'xl:top-4/6'
  | 'xl:right-4/6'
  | 'xl:bottom-4/6'
  | 'xl:left-4/6'
  | 'xl:top-5/6'
  | 'xl:right-5/6'
  | 'xl:bottom-5/6'
  | 'xl:left-5/6'
  | 'xl:top-1/12'
  | 'xl:right-1/12'
  | 'xl:bottom-1/12'
  | 'xl:left-1/12'
  | 'xl:top-2/12'
  | 'xl:right-2/12'
  | 'xl:bottom-2/12'
  | 'xl:left-2/12'
  | 'xl:top-3/12'
  | 'xl:right-3/12'
  | 'xl:bottom-3/12'
  | 'xl:left-3/12'
  | 'xl:top-4/12'
  | 'xl:right-4/12'
  | 'xl:bottom-4/12'
  | 'xl:left-4/12'
  | 'xl:top-5/12'
  | 'xl:right-5/12'
  | 'xl:bottom-5/12'
  | 'xl:left-5/12'
  | 'xl:top-6/12'
  | 'xl:right-6/12'
  | 'xl:bottom-6/12'
  | 'xl:left-6/12'
  | 'xl:top-7/12'
  | 'xl:right-7/12'
  | 'xl:bottom-7/12'
  | 'xl:left-7/12'
  | 'xl:top-8/12'
  | 'xl:right-8/12'
  | 'xl:bottom-8/12'
  | 'xl:left-8/12'
  | 'xl:top-9/12'
  | 'xl:right-9/12'
  | 'xl:bottom-9/12'
  | 'xl:left-9/12'
  | 'xl:top-10/12'
  | 'xl:right-10/12'
  | 'xl:bottom-10/12'
  | 'xl:left-10/12'
  | 'xl:top-11/12'
  | 'xl:right-11/12'
  | 'xl:bottom-11/12'
  | 'xl:left-11/12'
  | 'xl:top-full'
  | 'xl:right-full'
  | 'xl:bottom-full'
  | 'xl:left-full'
  | 'xl:resize-none'
  | 'xl:resize-y'
  | 'xl:resize-x'
  | 'xl:resize'
  | 'xl:shadow-xs'
  | 'xl:shadow-sm'
  | 'xl:shadow'
  | 'xl:shadow-md'
  | 'xl:shadow-lg'
  | 'xl:shadow-xl'
  | 'xl:shadow-2xl'
  | 'xl:shadow-inner'
  | 'xl:shadow-outline'
  | 'xl:shadow-none'
  | 'xl:shadow-solid'
  | 'xl:shadow-outline-gray'
  | 'xl:shadow-outline-blue'
  | 'xl:shadow-outline-teal'
  | 'xl:shadow-outline-green'
  | 'xl:shadow-outline-yellow'
  | 'xl:shadow-outline-orange'
  | 'xl:shadow-outline-red'
  | 'xl:shadow-outline-pink'
  | 'xl:shadow-outline-purple'
  | 'xl:shadow-outline-indigo'
  | 'xl:hover:shadow-xs'
  | 'xl:hover:shadow-sm'
  | 'xl:hover:shadow'
  | 'xl:hover:shadow-md'
  | 'xl:hover:shadow-lg'
  | 'xl:hover:shadow-xl'
  | 'xl:hover:shadow-2xl'
  | 'xl:hover:shadow-inner'
  | 'xl:hover:shadow-outline'
  | 'xl:hover:shadow-none'
  | 'xl:hover:shadow-solid'
  | 'xl:hover:shadow-outline-gray'
  | 'xl:hover:shadow-outline-blue'
  | 'xl:hover:shadow-outline-teal'
  | 'xl:hover:shadow-outline-green'
  | 'xl:hover:shadow-outline-yellow'
  | 'xl:hover:shadow-outline-orange'
  | 'xl:hover:shadow-outline-red'
  | 'xl:hover:shadow-outline-pink'
  | 'xl:hover:shadow-outline-purple'
  | 'xl:hover:shadow-outline-indigo'
  | 'xl:focus:shadow-xs'
  | 'xl:focus:shadow-sm'
  | 'xl:focus:shadow'
  | 'xl:focus:shadow-md'
  | 'xl:focus:shadow-lg'
  | 'xl:focus:shadow-xl'
  | 'xl:focus:shadow-2xl'
  | 'xl:focus:shadow-inner'
  | 'xl:focus:shadow-outline'
  | 'xl:focus:shadow-none'
  | 'xl:focus:shadow-solid'
  | 'xl:focus:shadow-outline-gray'
  | 'xl:focus:shadow-outline-blue'
  | 'xl:focus:shadow-outline-teal'
  | 'xl:focus:shadow-outline-green'
  | 'xl:focus:shadow-outline-yellow'
  | 'xl:focus:shadow-outline-orange'
  | 'xl:focus:shadow-outline-red'
  | 'xl:focus:shadow-outline-pink'
  | 'xl:focus:shadow-outline-purple'
  | 'xl:focus:shadow-outline-indigo'
  | 'xl:fill-current'
  | 'xl:stroke-current'
  | 'xl:stroke-0'
  | 'xl:stroke-1'
  | 'xl:stroke-2'
  | 'xl:table-auto'
  | 'xl:table-fixed'
  | 'xl:text-left'
  | 'xl:text-center'
  | 'xl:text-right'
  | 'xl:text-justify'
  | 'xl:text-transparent'
  | 'xl:text-white'
  | 'xl:text-black'
  | 'xl:text-gray-50'
  | 'xl:text-gray-100'
  | 'xl:text-gray-200'
  | 'xl:text-gray-300'
  | 'xl:text-gray-400'
  | 'xl:text-gray-500'
  | 'xl:text-gray-600'
  | 'xl:text-gray-700'
  | 'xl:text-gray-800'
  | 'xl:text-gray-900'
  | 'xl:text-cool-gray-50'
  | 'xl:text-cool-gray-100'
  | 'xl:text-cool-gray-200'
  | 'xl:text-cool-gray-300'
  | 'xl:text-cool-gray-400'
  | 'xl:text-cool-gray-500'
  | 'xl:text-cool-gray-600'
  | 'xl:text-cool-gray-700'
  | 'xl:text-cool-gray-800'
  | 'xl:text-cool-gray-900'
  | 'xl:text-red-50'
  | 'xl:text-red-100'
  | 'xl:text-red-200'
  | 'xl:text-red-300'
  | 'xl:text-red-400'
  | 'xl:text-red-500'
  | 'xl:text-red-600'
  | 'xl:text-red-700'
  | 'xl:text-red-800'
  | 'xl:text-red-900'
  | 'xl:text-orange-50'
  | 'xl:text-orange-100'
  | 'xl:text-orange-200'
  | 'xl:text-orange-300'
  | 'xl:text-orange-400'
  | 'xl:text-orange-500'
  | 'xl:text-orange-600'
  | 'xl:text-orange-700'
  | 'xl:text-orange-800'
  | 'xl:text-orange-900'
  | 'xl:text-yellow-50'
  | 'xl:text-yellow-100'
  | 'xl:text-yellow-200'
  | 'xl:text-yellow-300'
  | 'xl:text-yellow-400'
  | 'xl:text-yellow-500'
  | 'xl:text-yellow-600'
  | 'xl:text-yellow-700'
  | 'xl:text-yellow-800'
  | 'xl:text-yellow-900'
  | 'xl:text-green-50'
  | 'xl:text-green-100'
  | 'xl:text-green-200'
  | 'xl:text-green-300'
  | 'xl:text-green-400'
  | 'xl:text-green-500'
  | 'xl:text-green-600'
  | 'xl:text-green-700'
  | 'xl:text-green-800'
  | 'xl:text-green-900'
  | 'xl:text-teal-50'
  | 'xl:text-teal-100'
  | 'xl:text-teal-200'
  | 'xl:text-teal-300'
  | 'xl:text-teal-400'
  | 'xl:text-teal-500'
  | 'xl:text-teal-600'
  | 'xl:text-teal-700'
  | 'xl:text-teal-800'
  | 'xl:text-teal-900'
  | 'xl:text-blue-50'
  | 'xl:text-blue-100'
  | 'xl:text-blue-200'
  | 'xl:text-blue-300'
  | 'xl:text-blue-400'
  | 'xl:text-blue-500'
  | 'xl:text-blue-600'
  | 'xl:text-blue-700'
  | 'xl:text-blue-800'
  | 'xl:text-blue-900'
  | 'xl:text-indigo-50'
  | 'xl:text-indigo-100'
  | 'xl:text-indigo-200'
  | 'xl:text-indigo-300'
  | 'xl:text-indigo-400'
  | 'xl:text-indigo-500'
  | 'xl:text-indigo-600'
  | 'xl:text-indigo-700'
  | 'xl:text-indigo-800'
  | 'xl:text-indigo-900'
  | 'xl:text-purple-50'
  | 'xl:text-purple-100'
  | 'xl:text-purple-200'
  | 'xl:text-purple-300'
  | 'xl:text-purple-400'
  | 'xl:text-purple-500'
  | 'xl:text-purple-600'
  | 'xl:text-purple-700'
  | 'xl:text-purple-800'
  | 'xl:text-purple-900'
  | 'xl:text-pink-50'
  | 'xl:text-pink-100'
  | 'xl:text-pink-200'
  | 'xl:text-pink-300'
  | 'xl:text-pink-400'
  | 'xl:text-pink-500'
  | 'xl:text-pink-600'
  | 'xl:text-pink-700'
  | 'xl:text-pink-800'
  | 'xl:text-pink-900'
  | 'xl:group-hover:text-transparent'
  | 'xl:group-hover:text-white'
  | 'xl:group-hover:text-black'
  | 'xl:group-hover:text-gray-50'
  | 'xl:group-hover:text-gray-100'
  | 'xl:group-hover:text-gray-200'
  | 'xl:group-hover:text-gray-300'
  | 'xl:group-hover:text-gray-400'
  | 'xl:group-hover:text-gray-500'
  | 'xl:group-hover:text-gray-600'
  | 'xl:group-hover:text-gray-700'
  | 'xl:group-hover:text-gray-800'
  | 'xl:group-hover:text-gray-900'
  | 'xl:group-hover:text-cool-gray-50'
  | 'xl:group-hover:text-cool-gray-100'
  | 'xl:group-hover:text-cool-gray-200'
  | 'xl:group-hover:text-cool-gray-300'
  | 'xl:group-hover:text-cool-gray-400'
  | 'xl:group-hover:text-cool-gray-500'
  | 'xl:group-hover:text-cool-gray-600'
  | 'xl:group-hover:text-cool-gray-700'
  | 'xl:group-hover:text-cool-gray-800'
  | 'xl:group-hover:text-cool-gray-900'
  | 'xl:group-hover:text-red-50'
  | 'xl:group-hover:text-red-100'
  | 'xl:group-hover:text-red-200'
  | 'xl:group-hover:text-red-300'
  | 'xl:group-hover:text-red-400'
  | 'xl:group-hover:text-red-500'
  | 'xl:group-hover:text-red-600'
  | 'xl:group-hover:text-red-700'
  | 'xl:group-hover:text-red-800'
  | 'xl:group-hover:text-red-900'
  | 'xl:group-hover:text-orange-50'
  | 'xl:group-hover:text-orange-100'
  | 'xl:group-hover:text-orange-200'
  | 'xl:group-hover:text-orange-300'
  | 'xl:group-hover:text-orange-400'
  | 'xl:group-hover:text-orange-500'
  | 'xl:group-hover:text-orange-600'
  | 'xl:group-hover:text-orange-700'
  | 'xl:group-hover:text-orange-800'
  | 'xl:group-hover:text-orange-900'
  | 'xl:group-hover:text-yellow-50'
  | 'xl:group-hover:text-yellow-100'
  | 'xl:group-hover:text-yellow-200'
  | 'xl:group-hover:text-yellow-300'
  | 'xl:group-hover:text-yellow-400'
  | 'xl:group-hover:text-yellow-500'
  | 'xl:group-hover:text-yellow-600'
  | 'xl:group-hover:text-yellow-700'
  | 'xl:group-hover:text-yellow-800'
  | 'xl:group-hover:text-yellow-900'
  | 'xl:group-hover:text-green-50'
  | 'xl:group-hover:text-green-100'
  | 'xl:group-hover:text-green-200'
  | 'xl:group-hover:text-green-300'
  | 'xl:group-hover:text-green-400'
  | 'xl:group-hover:text-green-500'
  | 'xl:group-hover:text-green-600'
  | 'xl:group-hover:text-green-700'
  | 'xl:group-hover:text-green-800'
  | 'xl:group-hover:text-green-900'
  | 'xl:group-hover:text-teal-50'
  | 'xl:group-hover:text-teal-100'
  | 'xl:group-hover:text-teal-200'
  | 'xl:group-hover:text-teal-300'
  | 'xl:group-hover:text-teal-400'
  | 'xl:group-hover:text-teal-500'
  | 'xl:group-hover:text-teal-600'
  | 'xl:group-hover:text-teal-700'
  | 'xl:group-hover:text-teal-800'
  | 'xl:group-hover:text-teal-900'
  | 'xl:group-hover:text-blue-50'
  | 'xl:group-hover:text-blue-100'
  | 'xl:group-hover:text-blue-200'
  | 'xl:group-hover:text-blue-300'
  | 'xl:group-hover:text-blue-400'
  | 'xl:group-hover:text-blue-500'
  | 'xl:group-hover:text-blue-600'
  | 'xl:group-hover:text-blue-700'
  | 'xl:group-hover:text-blue-800'
  | 'xl:group-hover:text-blue-900'
  | 'xl:group-hover:text-indigo-50'
  | 'xl:group-hover:text-indigo-100'
  | 'xl:group-hover:text-indigo-200'
  | 'xl:group-hover:text-indigo-300'
  | 'xl:group-hover:text-indigo-400'
  | 'xl:group-hover:text-indigo-500'
  | 'xl:group-hover:text-indigo-600'
  | 'xl:group-hover:text-indigo-700'
  | 'xl:group-hover:text-indigo-800'
  | 'xl:group-hover:text-indigo-900'
  | 'xl:group-hover:text-purple-50'
  | 'xl:group-hover:text-purple-100'
  | 'xl:group-hover:text-purple-200'
  | 'xl:group-hover:text-purple-300'
  | 'xl:group-hover:text-purple-400'
  | 'xl:group-hover:text-purple-500'
  | 'xl:group-hover:text-purple-600'
  | 'xl:group-hover:text-purple-700'
  | 'xl:group-hover:text-purple-800'
  | 'xl:group-hover:text-purple-900'
  | 'xl:group-hover:text-pink-50'
  | 'xl:group-hover:text-pink-100'
  | 'xl:group-hover:text-pink-200'
  | 'xl:group-hover:text-pink-300'
  | 'xl:group-hover:text-pink-400'
  | 'xl:group-hover:text-pink-500'
  | 'xl:group-hover:text-pink-600'
  | 'xl:group-hover:text-pink-700'
  | 'xl:group-hover:text-pink-800'
  | 'xl:group-hover:text-pink-900'
  | 'xl:group-focus:text-transparent'
  | 'xl:group-focus:text-white'
  | 'xl:group-focus:text-black'
  | 'xl:group-focus:text-gray-50'
  | 'xl:group-focus:text-gray-100'
  | 'xl:group-focus:text-gray-200'
  | 'xl:group-focus:text-gray-300'
  | 'xl:group-focus:text-gray-400'
  | 'xl:group-focus:text-gray-500'
  | 'xl:group-focus:text-gray-600'
  | 'xl:group-focus:text-gray-700'
  | 'xl:group-focus:text-gray-800'
  | 'xl:group-focus:text-gray-900'
  | 'xl:group-focus:text-cool-gray-50'
  | 'xl:group-focus:text-cool-gray-100'
  | 'xl:group-focus:text-cool-gray-200'
  | 'xl:group-focus:text-cool-gray-300'
  | 'xl:group-focus:text-cool-gray-400'
  | 'xl:group-focus:text-cool-gray-500'
  | 'xl:group-focus:text-cool-gray-600'
  | 'xl:group-focus:text-cool-gray-700'
  | 'xl:group-focus:text-cool-gray-800'
  | 'xl:group-focus:text-cool-gray-900'
  | 'xl:group-focus:text-red-50'
  | 'xl:group-focus:text-red-100'
  | 'xl:group-focus:text-red-200'
  | 'xl:group-focus:text-red-300'
  | 'xl:group-focus:text-red-400'
  | 'xl:group-focus:text-red-500'
  | 'xl:group-focus:text-red-600'
  | 'xl:group-focus:text-red-700'
  | 'xl:group-focus:text-red-800'
  | 'xl:group-focus:text-red-900'
  | 'xl:group-focus:text-orange-50'
  | 'xl:group-focus:text-orange-100'
  | 'xl:group-focus:text-orange-200'
  | 'xl:group-focus:text-orange-300'
  | 'xl:group-focus:text-orange-400'
  | 'xl:group-focus:text-orange-500'
  | 'xl:group-focus:text-orange-600'
  | 'xl:group-focus:text-orange-700'
  | 'xl:group-focus:text-orange-800'
  | 'xl:group-focus:text-orange-900'
  | 'xl:group-focus:text-yellow-50'
  | 'xl:group-focus:text-yellow-100'
  | 'xl:group-focus:text-yellow-200'
  | 'xl:group-focus:text-yellow-300'
  | 'xl:group-focus:text-yellow-400'
  | 'xl:group-focus:text-yellow-500'
  | 'xl:group-focus:text-yellow-600'
  | 'xl:group-focus:text-yellow-700'
  | 'xl:group-focus:text-yellow-800'
  | 'xl:group-focus:text-yellow-900'
  | 'xl:group-focus:text-green-50'
  | 'xl:group-focus:text-green-100'
  | 'xl:group-focus:text-green-200'
  | 'xl:group-focus:text-green-300'
  | 'xl:group-focus:text-green-400'
  | 'xl:group-focus:text-green-500'
  | 'xl:group-focus:text-green-600'
  | 'xl:group-focus:text-green-700'
  | 'xl:group-focus:text-green-800'
  | 'xl:group-focus:text-green-900'
  | 'xl:group-focus:text-teal-50'
  | 'xl:group-focus:text-teal-100'
  | 'xl:group-focus:text-teal-200'
  | 'xl:group-focus:text-teal-300'
  | 'xl:group-focus:text-teal-400'
  | 'xl:group-focus:text-teal-500'
  | 'xl:group-focus:text-teal-600'
  | 'xl:group-focus:text-teal-700'
  | 'xl:group-focus:text-teal-800'
  | 'xl:group-focus:text-teal-900'
  | 'xl:group-focus:text-blue-50'
  | 'xl:group-focus:text-blue-100'
  | 'xl:group-focus:text-blue-200'
  | 'xl:group-focus:text-blue-300'
  | 'xl:group-focus:text-blue-400'
  | 'xl:group-focus:text-blue-500'
  | 'xl:group-focus:text-blue-600'
  | 'xl:group-focus:text-blue-700'
  | 'xl:group-focus:text-blue-800'
  | 'xl:group-focus:text-blue-900'
  | 'xl:group-focus:text-indigo-50'
  | 'xl:group-focus:text-indigo-100'
  | 'xl:group-focus:text-indigo-200'
  | 'xl:group-focus:text-indigo-300'
  | 'xl:group-focus:text-indigo-400'
  | 'xl:group-focus:text-indigo-500'
  | 'xl:group-focus:text-indigo-600'
  | 'xl:group-focus:text-indigo-700'
  | 'xl:group-focus:text-indigo-800'
  | 'xl:group-focus:text-indigo-900'
  | 'xl:group-focus:text-purple-50'
  | 'xl:group-focus:text-purple-100'
  | 'xl:group-focus:text-purple-200'
  | 'xl:group-focus:text-purple-300'
  | 'xl:group-focus:text-purple-400'
  | 'xl:group-focus:text-purple-500'
  | 'xl:group-focus:text-purple-600'
  | 'xl:group-focus:text-purple-700'
  | 'xl:group-focus:text-purple-800'
  | 'xl:group-focus:text-purple-900'
  | 'xl:group-focus:text-pink-50'
  | 'xl:group-focus:text-pink-100'
  | 'xl:group-focus:text-pink-200'
  | 'xl:group-focus:text-pink-300'
  | 'xl:group-focus:text-pink-400'
  | 'xl:group-focus:text-pink-500'
  | 'xl:group-focus:text-pink-600'
  | 'xl:group-focus:text-pink-700'
  | 'xl:group-focus:text-pink-800'
  | 'xl:group-focus:text-pink-900'
  | 'xl:hover:text-transparent'
  | 'xl:hover:text-white'
  | 'xl:hover:text-black'
  | 'xl:hover:text-gray-50'
  | 'xl:hover:text-gray-100'
  | 'xl:hover:text-gray-200'
  | 'xl:hover:text-gray-300'
  | 'xl:hover:text-gray-400'
  | 'xl:hover:text-gray-500'
  | 'xl:hover:text-gray-600'
  | 'xl:hover:text-gray-700'
  | 'xl:hover:text-gray-800'
  | 'xl:hover:text-gray-900'
  | 'xl:hover:text-cool-gray-50'
  | 'xl:hover:text-cool-gray-100'
  | 'xl:hover:text-cool-gray-200'
  | 'xl:hover:text-cool-gray-300'
  | 'xl:hover:text-cool-gray-400'
  | 'xl:hover:text-cool-gray-500'
  | 'xl:hover:text-cool-gray-600'
  | 'xl:hover:text-cool-gray-700'
  | 'xl:hover:text-cool-gray-800'
  | 'xl:hover:text-cool-gray-900'
  | 'xl:hover:text-red-50'
  | 'xl:hover:text-red-100'
  | 'xl:hover:text-red-200'
  | 'xl:hover:text-red-300'
  | 'xl:hover:text-red-400'
  | 'xl:hover:text-red-500'
  | 'xl:hover:text-red-600'
  | 'xl:hover:text-red-700'
  | 'xl:hover:text-red-800'
  | 'xl:hover:text-red-900'
  | 'xl:hover:text-orange-50'
  | 'xl:hover:text-orange-100'
  | 'xl:hover:text-orange-200'
  | 'xl:hover:text-orange-300'
  | 'xl:hover:text-orange-400'
  | 'xl:hover:text-orange-500'
  | 'xl:hover:text-orange-600'
  | 'xl:hover:text-orange-700'
  | 'xl:hover:text-orange-800'
  | 'xl:hover:text-orange-900'
  | 'xl:hover:text-yellow-50'
  | 'xl:hover:text-yellow-100'
  | 'xl:hover:text-yellow-200'
  | 'xl:hover:text-yellow-300'
  | 'xl:hover:text-yellow-400'
  | 'xl:hover:text-yellow-500'
  | 'xl:hover:text-yellow-600'
  | 'xl:hover:text-yellow-700'
  | 'xl:hover:text-yellow-800'
  | 'xl:hover:text-yellow-900'
  | 'xl:hover:text-green-50'
  | 'xl:hover:text-green-100'
  | 'xl:hover:text-green-200'
  | 'xl:hover:text-green-300'
  | 'xl:hover:text-green-400'
  | 'xl:hover:text-green-500'
  | 'xl:hover:text-green-600'
  | 'xl:hover:text-green-700'
  | 'xl:hover:text-green-800'
  | 'xl:hover:text-green-900'
  | 'xl:hover:text-teal-50'
  | 'xl:hover:text-teal-100'
  | 'xl:hover:text-teal-200'
  | 'xl:hover:text-teal-300'
  | 'xl:hover:text-teal-400'
  | 'xl:hover:text-teal-500'
  | 'xl:hover:text-teal-600'
  | 'xl:hover:text-teal-700'
  | 'xl:hover:text-teal-800'
  | 'xl:hover:text-teal-900'
  | 'xl:hover:text-blue-50'
  | 'xl:hover:text-blue-100'
  | 'xl:hover:text-blue-200'
  | 'xl:hover:text-blue-300'
  | 'xl:hover:text-blue-400'
  | 'xl:hover:text-blue-500'
  | 'xl:hover:text-blue-600'
  | 'xl:hover:text-blue-700'
  | 'xl:hover:text-blue-800'
  | 'xl:hover:text-blue-900'
  | 'xl:hover:text-indigo-50'
  | 'xl:hover:text-indigo-100'
  | 'xl:hover:text-indigo-200'
  | 'xl:hover:text-indigo-300'
  | 'xl:hover:text-indigo-400'
  | 'xl:hover:text-indigo-500'
  | 'xl:hover:text-indigo-600'
  | 'xl:hover:text-indigo-700'
  | 'xl:hover:text-indigo-800'
  | 'xl:hover:text-indigo-900'
  | 'xl:hover:text-purple-50'
  | 'xl:hover:text-purple-100'
  | 'xl:hover:text-purple-200'
  | 'xl:hover:text-purple-300'
  | 'xl:hover:text-purple-400'
  | 'xl:hover:text-purple-500'
  | 'xl:hover:text-purple-600'
  | 'xl:hover:text-purple-700'
  | 'xl:hover:text-purple-800'
  | 'xl:hover:text-purple-900'
  | 'xl:hover:text-pink-50'
  | 'xl:hover:text-pink-100'
  | 'xl:hover:text-pink-200'
  | 'xl:hover:text-pink-300'
  | 'xl:hover:text-pink-400'
  | 'xl:hover:text-pink-500'
  | 'xl:hover:text-pink-600'
  | 'xl:hover:text-pink-700'
  | 'xl:hover:text-pink-800'
  | 'xl:hover:text-pink-900'
  | 'xl:focus-within:text-transparent'
  | 'xl:focus-within:text-white'
  | 'xl:focus-within:text-black'
  | 'xl:focus-within:text-gray-50'
  | 'xl:focus-within:text-gray-100'
  | 'xl:focus-within:text-gray-200'
  | 'xl:focus-within:text-gray-300'
  | 'xl:focus-within:text-gray-400'
  | 'xl:focus-within:text-gray-500'
  | 'xl:focus-within:text-gray-600'
  | 'xl:focus-within:text-gray-700'
  | 'xl:focus-within:text-gray-800'
  | 'xl:focus-within:text-gray-900'
  | 'xl:focus-within:text-cool-gray-50'
  | 'xl:focus-within:text-cool-gray-100'
  | 'xl:focus-within:text-cool-gray-200'
  | 'xl:focus-within:text-cool-gray-300'
  | 'xl:focus-within:text-cool-gray-400'
  | 'xl:focus-within:text-cool-gray-500'
  | 'xl:focus-within:text-cool-gray-600'
  | 'xl:focus-within:text-cool-gray-700'
  | 'xl:focus-within:text-cool-gray-800'
  | 'xl:focus-within:text-cool-gray-900'
  | 'xl:focus-within:text-red-50'
  | 'xl:focus-within:text-red-100'
  | 'xl:focus-within:text-red-200'
  | 'xl:focus-within:text-red-300'
  | 'xl:focus-within:text-red-400'
  | 'xl:focus-within:text-red-500'
  | 'xl:focus-within:text-red-600'
  | 'xl:focus-within:text-red-700'
  | 'xl:focus-within:text-red-800'
  | 'xl:focus-within:text-red-900'
  | 'xl:focus-within:text-orange-50'
  | 'xl:focus-within:text-orange-100'
  | 'xl:focus-within:text-orange-200'
  | 'xl:focus-within:text-orange-300'
  | 'xl:focus-within:text-orange-400'
  | 'xl:focus-within:text-orange-500'
  | 'xl:focus-within:text-orange-600'
  | 'xl:focus-within:text-orange-700'
  | 'xl:focus-within:text-orange-800'
  | 'xl:focus-within:text-orange-900'
  | 'xl:focus-within:text-yellow-50'
  | 'xl:focus-within:text-yellow-100'
  | 'xl:focus-within:text-yellow-200'
  | 'xl:focus-within:text-yellow-300'
  | 'xl:focus-within:text-yellow-400'
  | 'xl:focus-within:text-yellow-500'
  | 'xl:focus-within:text-yellow-600'
  | 'xl:focus-within:text-yellow-700'
  | 'xl:focus-within:text-yellow-800'
  | 'xl:focus-within:text-yellow-900'
  | 'xl:focus-within:text-green-50'
  | 'xl:focus-within:text-green-100'
  | 'xl:focus-within:text-green-200'
  | 'xl:focus-within:text-green-300'
  | 'xl:focus-within:text-green-400'
  | 'xl:focus-within:text-green-500'
  | 'xl:focus-within:text-green-600'
  | 'xl:focus-within:text-green-700'
  | 'xl:focus-within:text-green-800'
  | 'xl:focus-within:text-green-900'
  | 'xl:focus-within:text-teal-50'
  | 'xl:focus-within:text-teal-100'
  | 'xl:focus-within:text-teal-200'
  | 'xl:focus-within:text-teal-300'
  | 'xl:focus-within:text-teal-400'
  | 'xl:focus-within:text-teal-500'
  | 'xl:focus-within:text-teal-600'
  | 'xl:focus-within:text-teal-700'
  | 'xl:focus-within:text-teal-800'
  | 'xl:focus-within:text-teal-900'
  | 'xl:focus-within:text-blue-50'
  | 'xl:focus-within:text-blue-100'
  | 'xl:focus-within:text-blue-200'
  | 'xl:focus-within:text-blue-300'
  | 'xl:focus-within:text-blue-400'
  | 'xl:focus-within:text-blue-500'
  | 'xl:focus-within:text-blue-600'
  | 'xl:focus-within:text-blue-700'
  | 'xl:focus-within:text-blue-800'
  | 'xl:focus-within:text-blue-900'
  | 'xl:focus-within:text-indigo-50'
  | 'xl:focus-within:text-indigo-100'
  | 'xl:focus-within:text-indigo-200'
  | 'xl:focus-within:text-indigo-300'
  | 'xl:focus-within:text-indigo-400'
  | 'xl:focus-within:text-indigo-500'
  | 'xl:focus-within:text-indigo-600'
  | 'xl:focus-within:text-indigo-700'
  | 'xl:focus-within:text-indigo-800'
  | 'xl:focus-within:text-indigo-900'
  | 'xl:focus-within:text-purple-50'
  | 'xl:focus-within:text-purple-100'
  | 'xl:focus-within:text-purple-200'
  | 'xl:focus-within:text-purple-300'
  | 'xl:focus-within:text-purple-400'
  | 'xl:focus-within:text-purple-500'
  | 'xl:focus-within:text-purple-600'
  | 'xl:focus-within:text-purple-700'
  | 'xl:focus-within:text-purple-800'
  | 'xl:focus-within:text-purple-900'
  | 'xl:focus-within:text-pink-50'
  | 'xl:focus-within:text-pink-100'
  | 'xl:focus-within:text-pink-200'
  | 'xl:focus-within:text-pink-300'
  | 'xl:focus-within:text-pink-400'
  | 'xl:focus-within:text-pink-500'
  | 'xl:focus-within:text-pink-600'
  | 'xl:focus-within:text-pink-700'
  | 'xl:focus-within:text-pink-800'
  | 'xl:focus-within:text-pink-900'
  | 'xl:focus:text-transparent'
  | 'xl:focus:text-white'
  | 'xl:focus:text-black'
  | 'xl:focus:text-gray-50'
  | 'xl:focus:text-gray-100'
  | 'xl:focus:text-gray-200'
  | 'xl:focus:text-gray-300'
  | 'xl:focus:text-gray-400'
  | 'xl:focus:text-gray-500'
  | 'xl:focus:text-gray-600'
  | 'xl:focus:text-gray-700'
  | 'xl:focus:text-gray-800'
  | 'xl:focus:text-gray-900'
  | 'xl:focus:text-cool-gray-50'
  | 'xl:focus:text-cool-gray-100'
  | 'xl:focus:text-cool-gray-200'
  | 'xl:focus:text-cool-gray-300'
  | 'xl:focus:text-cool-gray-400'
  | 'xl:focus:text-cool-gray-500'
  | 'xl:focus:text-cool-gray-600'
  | 'xl:focus:text-cool-gray-700'
  | 'xl:focus:text-cool-gray-800'
  | 'xl:focus:text-cool-gray-900'
  | 'xl:focus:text-red-50'
  | 'xl:focus:text-red-100'
  | 'xl:focus:text-red-200'
  | 'xl:focus:text-red-300'
  | 'xl:focus:text-red-400'
  | 'xl:focus:text-red-500'
  | 'xl:focus:text-red-600'
  | 'xl:focus:text-red-700'
  | 'xl:focus:text-red-800'
  | 'xl:focus:text-red-900'
  | 'xl:focus:text-orange-50'
  | 'xl:focus:text-orange-100'
  | 'xl:focus:text-orange-200'
  | 'xl:focus:text-orange-300'
  | 'xl:focus:text-orange-400'
  | 'xl:focus:text-orange-500'
  | 'xl:focus:text-orange-600'
  | 'xl:focus:text-orange-700'
  | 'xl:focus:text-orange-800'
  | 'xl:focus:text-orange-900'
  | 'xl:focus:text-yellow-50'
  | 'xl:focus:text-yellow-100'
  | 'xl:focus:text-yellow-200'
  | 'xl:focus:text-yellow-300'
  | 'xl:focus:text-yellow-400'
  | 'xl:focus:text-yellow-500'
  | 'xl:focus:text-yellow-600'
  | 'xl:focus:text-yellow-700'
  | 'xl:focus:text-yellow-800'
  | 'xl:focus:text-yellow-900'
  | 'xl:focus:text-green-50'
  | 'xl:focus:text-green-100'
  | 'xl:focus:text-green-200'
  | 'xl:focus:text-green-300'
  | 'xl:focus:text-green-400'
  | 'xl:focus:text-green-500'
  | 'xl:focus:text-green-600'
  | 'xl:focus:text-green-700'
  | 'xl:focus:text-green-800'
  | 'xl:focus:text-green-900'
  | 'xl:focus:text-teal-50'
  | 'xl:focus:text-teal-100'
  | 'xl:focus:text-teal-200'
  | 'xl:focus:text-teal-300'
  | 'xl:focus:text-teal-400'
  | 'xl:focus:text-teal-500'
  | 'xl:focus:text-teal-600'
  | 'xl:focus:text-teal-700'
  | 'xl:focus:text-teal-800'
  | 'xl:focus:text-teal-900'
  | 'xl:focus:text-blue-50'
  | 'xl:focus:text-blue-100'
  | 'xl:focus:text-blue-200'
  | 'xl:focus:text-blue-300'
  | 'xl:focus:text-blue-400'
  | 'xl:focus:text-blue-500'
  | 'xl:focus:text-blue-600'
  | 'xl:focus:text-blue-700'
  | 'xl:focus:text-blue-800'
  | 'xl:focus:text-blue-900'
  | 'xl:focus:text-indigo-50'
  | 'xl:focus:text-indigo-100'
  | 'xl:focus:text-indigo-200'
  | 'xl:focus:text-indigo-300'
  | 'xl:focus:text-indigo-400'
  | 'xl:focus:text-indigo-500'
  | 'xl:focus:text-indigo-600'
  | 'xl:focus:text-indigo-700'
  | 'xl:focus:text-indigo-800'
  | 'xl:focus:text-indigo-900'
  | 'xl:focus:text-purple-50'
  | 'xl:focus:text-purple-100'
  | 'xl:focus:text-purple-200'
  | 'xl:focus:text-purple-300'
  | 'xl:focus:text-purple-400'
  | 'xl:focus:text-purple-500'
  | 'xl:focus:text-purple-600'
  | 'xl:focus:text-purple-700'
  | 'xl:focus:text-purple-800'
  | 'xl:focus:text-purple-900'
  | 'xl:focus:text-pink-50'
  | 'xl:focus:text-pink-100'
  | 'xl:focus:text-pink-200'
  | 'xl:focus:text-pink-300'
  | 'xl:focus:text-pink-400'
  | 'xl:focus:text-pink-500'
  | 'xl:focus:text-pink-600'
  | 'xl:focus:text-pink-700'
  | 'xl:focus:text-pink-800'
  | 'xl:focus:text-pink-900'
  | 'xl:active:text-transparent'
  | 'xl:active:text-white'
  | 'xl:active:text-black'
  | 'xl:active:text-gray-50'
  | 'xl:active:text-gray-100'
  | 'xl:active:text-gray-200'
  | 'xl:active:text-gray-300'
  | 'xl:active:text-gray-400'
  | 'xl:active:text-gray-500'
  | 'xl:active:text-gray-600'
  | 'xl:active:text-gray-700'
  | 'xl:active:text-gray-800'
  | 'xl:active:text-gray-900'
  | 'xl:active:text-cool-gray-50'
  | 'xl:active:text-cool-gray-100'
  | 'xl:active:text-cool-gray-200'
  | 'xl:active:text-cool-gray-300'
  | 'xl:active:text-cool-gray-400'
  | 'xl:active:text-cool-gray-500'
  | 'xl:active:text-cool-gray-600'
  | 'xl:active:text-cool-gray-700'
  | 'xl:active:text-cool-gray-800'
  | 'xl:active:text-cool-gray-900'
  | 'xl:active:text-red-50'
  | 'xl:active:text-red-100'
  | 'xl:active:text-red-200'
  | 'xl:active:text-red-300'
  | 'xl:active:text-red-400'
  | 'xl:active:text-red-500'
  | 'xl:active:text-red-600'
  | 'xl:active:text-red-700'
  | 'xl:active:text-red-800'
  | 'xl:active:text-red-900'
  | 'xl:active:text-orange-50'
  | 'xl:active:text-orange-100'
  | 'xl:active:text-orange-200'
  | 'xl:active:text-orange-300'
  | 'xl:active:text-orange-400'
  | 'xl:active:text-orange-500'
  | 'xl:active:text-orange-600'
  | 'xl:active:text-orange-700'
  | 'xl:active:text-orange-800'
  | 'xl:active:text-orange-900'
  | 'xl:active:text-yellow-50'
  | 'xl:active:text-yellow-100'
  | 'xl:active:text-yellow-200'
  | 'xl:active:text-yellow-300'
  | 'xl:active:text-yellow-400'
  | 'xl:active:text-yellow-500'
  | 'xl:active:text-yellow-600'
  | 'xl:active:text-yellow-700'
  | 'xl:active:text-yellow-800'
  | 'xl:active:text-yellow-900'
  | 'xl:active:text-green-50'
  | 'xl:active:text-green-100'
  | 'xl:active:text-green-200'
  | 'xl:active:text-green-300'
  | 'xl:active:text-green-400'
  | 'xl:active:text-green-500'
  | 'xl:active:text-green-600'
  | 'xl:active:text-green-700'
  | 'xl:active:text-green-800'
  | 'xl:active:text-green-900'
  | 'xl:active:text-teal-50'
  | 'xl:active:text-teal-100'
  | 'xl:active:text-teal-200'
  | 'xl:active:text-teal-300'
  | 'xl:active:text-teal-400'
  | 'xl:active:text-teal-500'
  | 'xl:active:text-teal-600'
  | 'xl:active:text-teal-700'
  | 'xl:active:text-teal-800'
  | 'xl:active:text-teal-900'
  | 'xl:active:text-blue-50'
  | 'xl:active:text-blue-100'
  | 'xl:active:text-blue-200'
  | 'xl:active:text-blue-300'
  | 'xl:active:text-blue-400'
  | 'xl:active:text-blue-500'
  | 'xl:active:text-blue-600'
  | 'xl:active:text-blue-700'
  | 'xl:active:text-blue-800'
  | 'xl:active:text-blue-900'
  | 'xl:active:text-indigo-50'
  | 'xl:active:text-indigo-100'
  | 'xl:active:text-indigo-200'
  | 'xl:active:text-indigo-300'
  | 'xl:active:text-indigo-400'
  | 'xl:active:text-indigo-500'
  | 'xl:active:text-indigo-600'
  | 'xl:active:text-indigo-700'
  | 'xl:active:text-indigo-800'
  | 'xl:active:text-indigo-900'
  | 'xl:active:text-purple-50'
  | 'xl:active:text-purple-100'
  | 'xl:active:text-purple-200'
  | 'xl:active:text-purple-300'
  | 'xl:active:text-purple-400'
  | 'xl:active:text-purple-500'
  | 'xl:active:text-purple-600'
  | 'xl:active:text-purple-700'
  | 'xl:active:text-purple-800'
  | 'xl:active:text-purple-900'
  | 'xl:active:text-pink-50'
  | 'xl:active:text-pink-100'
  | 'xl:active:text-pink-200'
  | 'xl:active:text-pink-300'
  | 'xl:active:text-pink-400'
  | 'xl:active:text-pink-500'
  | 'xl:active:text-pink-600'
  | 'xl:active:text-pink-700'
  | 'xl:active:text-pink-800'
  | 'xl:active:text-pink-900'
  | 'xl:text-xs'
  | 'xl:text-sm'
  | 'xl:text-base'
  | 'xl:text-lg'
  | 'xl:text-xl'
  | 'xl:text-2xl'
  | 'xl:text-3xl'
  | 'xl:text-4xl'
  | 'xl:text-5xl'
  | 'xl:text-6xl'
  | 'xl:italic'
  | 'xl:not-italic'
  | 'xl:uppercase'
  | 'xl:lowercase'
  | 'xl:capitalize'
  | 'xl:normal-case'
  | 'xl:underline'
  | 'xl:line-through'
  | 'xl:no-underline'
  | 'xl:group-hover:underline'
  | 'xl:group-hover:line-through'
  | 'xl:group-hover:no-underline'
  | 'xl:group-focus:underline'
  | 'xl:group-focus:line-through'
  | 'xl:group-focus:no-underline'
  | 'xl:hover:underline'
  | 'xl:hover:line-through'
  | 'xl:hover:no-underline'
  | 'xl:focus:underline'
  | 'xl:focus:line-through'
  | 'xl:focus:no-underline'
  | 'xl:antialiased'
  | 'xl:subpixel-antialiased'
  | 'xl:tracking-tighter'
  | 'xl:tracking-tight'
  | 'xl:tracking-normal'
  | 'xl:tracking-wide'
  | 'xl:tracking-wider'
  | 'xl:tracking-widest'
  | 'xl:select-none'
  | 'xl:select-text'
  | 'xl:select-all'
  | 'xl:select-auto'
  | 'xl:align-baseline'
  | 'xl:align-top'
  | 'xl:align-middle'
  | 'xl:align-bottom'
  | 'xl:align-text-top'
  | 'xl:align-text-bottom'
  | 'xl:visible'
  | 'xl:invisible'
  | 'xl:whitespace-normal'
  | 'xl:whitespace-no-wrap'
  | 'xl:whitespace-pre'
  | 'xl:whitespace-pre-line'
  | 'xl:whitespace-pre-wrap'
  | 'xl:break-normal'
  | 'xl:break-words'
  | 'xl:break-all'
  | 'xl:truncate'
  | 'xl:w-0'
  | 'xl:w-1'
  | 'xl:w-2'
  | 'xl:w-3'
  | 'xl:w-4'
  | 'xl:w-5'
  | 'xl:w-6'
  | 'xl:w-7'
  | 'xl:w-8'
  | 'xl:w-9'
  | 'xl:w-10'
  | 'xl:w-11'
  | 'xl:w-12'
  | 'xl:w-13'
  | 'xl:w-14'
  | 'xl:w-15'
  | 'xl:w-16'
  | 'xl:w-20'
  | 'xl:w-24'
  | 'xl:w-28'
  | 'xl:w-32'
  | 'xl:w-36'
  | 'xl:w-40'
  | 'xl:w-48'
  | 'xl:w-56'
  | 'xl:w-60'
  | 'xl:w-64'
  | 'xl:w-72'
  | 'xl:w-80'
  | 'xl:w-96'
  | 'xl:w-auto'
  | 'xl:w-px'
  | 'xl:w-0.5'
  | 'xl:w-1.5'
  | 'xl:w-2.5'
  | 'xl:w-3.5'
  | 'xl:w-1/2'
  | 'xl:w-1/3'
  | 'xl:w-2/3'
  | 'xl:w-1/4'
  | 'xl:w-2/4'
  | 'xl:w-3/4'
  | 'xl:w-1/5'
  | 'xl:w-2/5'
  | 'xl:w-3/5'
  | 'xl:w-4/5'
  | 'xl:w-1/6'
  | 'xl:w-2/6'
  | 'xl:w-3/6'
  | 'xl:w-4/6'
  | 'xl:w-5/6'
  | 'xl:w-1/12'
  | 'xl:w-2/12'
  | 'xl:w-3/12'
  | 'xl:w-4/12'
  | 'xl:w-5/12'
  | 'xl:w-6/12'
  | 'xl:w-7/12'
  | 'xl:w-8/12'
  | 'xl:w-9/12'
  | 'xl:w-10/12'
  | 'xl:w-11/12'
  | 'xl:w-full'
  | 'xl:w-screen'
  | 'xl:z-0'
  | 'xl:z-10'
  | 'xl:z-20'
  | 'xl:z-30'
  | 'xl:z-40'
  | 'xl:z-50'
  | 'xl:z-auto'
  | 'xl:focus-within:z-0'
  | 'xl:focus-within:z-10'
  | 'xl:focus-within:z-20'
  | 'xl:focus-within:z-30'
  | 'xl:focus-within:z-40'
  | 'xl:focus-within:z-50'
  | 'xl:focus-within:z-auto'
  | 'xl:focus:z-0'
  | 'xl:focus:z-10'
  | 'xl:focus:z-20'
  | 'xl:focus:z-30'
  | 'xl:focus:z-40'
  | 'xl:focus:z-50'
  | 'xl:focus:z-auto'
  | 'xl:gap-0'
  | 'xl:gap-1'
  | 'xl:gap-2'
  | 'xl:gap-3'
  | 'xl:gap-4'
  | 'xl:gap-5'
  | 'xl:gap-6'
  | 'xl:gap-7'
  | 'xl:gap-8'
  | 'xl:gap-9'
  | 'xl:gap-10'
  | 'xl:gap-11'
  | 'xl:gap-12'
  | 'xl:gap-13'
  | 'xl:gap-14'
  | 'xl:gap-15'
  | 'xl:gap-16'
  | 'xl:gap-20'
  | 'xl:gap-24'
  | 'xl:gap-28'
  | 'xl:gap-32'
  | 'xl:gap-36'
  | 'xl:gap-40'
  | 'xl:gap-48'
  | 'xl:gap-56'
  | 'xl:gap-60'
  | 'xl:gap-64'
  | 'xl:gap-72'
  | 'xl:gap-80'
  | 'xl:gap-96'
  | 'xl:gap-px'
  | 'xl:gap-0.5'
  | 'xl:gap-1.5'
  | 'xl:gap-2.5'
  | 'xl:gap-3.5'
  | 'xl:gap-1/2'
  | 'xl:gap-1/3'
  | 'xl:gap-2/3'
  | 'xl:gap-1/4'
  | 'xl:gap-2/4'
  | 'xl:gap-3/4'
  | 'xl:gap-1/5'
  | 'xl:gap-2/5'
  | 'xl:gap-3/5'
  | 'xl:gap-4/5'
  | 'xl:gap-1/6'
  | 'xl:gap-2/6'
  | 'xl:gap-3/6'
  | 'xl:gap-4/6'
  | 'xl:gap-5/6'
  | 'xl:gap-1/12'
  | 'xl:gap-2/12'
  | 'xl:gap-3/12'
  | 'xl:gap-4/12'
  | 'xl:gap-5/12'
  | 'xl:gap-6/12'
  | 'xl:gap-7/12'
  | 'xl:gap-8/12'
  | 'xl:gap-9/12'
  | 'xl:gap-10/12'
  | 'xl:gap-11/12'
  | 'xl:gap-full'
  | 'xl:col-gap-0'
  | 'xl:col-gap-1'
  | 'xl:col-gap-2'
  | 'xl:col-gap-3'
  | 'xl:col-gap-4'
  | 'xl:col-gap-5'
  | 'xl:col-gap-6'
  | 'xl:col-gap-7'
  | 'xl:col-gap-8'
  | 'xl:col-gap-9'
  | 'xl:col-gap-10'
  | 'xl:col-gap-11'
  | 'xl:col-gap-12'
  | 'xl:col-gap-13'
  | 'xl:col-gap-14'
  | 'xl:col-gap-15'
  | 'xl:col-gap-16'
  | 'xl:col-gap-20'
  | 'xl:col-gap-24'
  | 'xl:col-gap-28'
  | 'xl:col-gap-32'
  | 'xl:col-gap-36'
  | 'xl:col-gap-40'
  | 'xl:col-gap-48'
  | 'xl:col-gap-56'
  | 'xl:col-gap-60'
  | 'xl:col-gap-64'
  | 'xl:col-gap-72'
  | 'xl:col-gap-80'
  | 'xl:col-gap-96'
  | 'xl:col-gap-px'
  | 'xl:col-gap-0.5'
  | 'xl:col-gap-1.5'
  | 'xl:col-gap-2.5'
  | 'xl:col-gap-3.5'
  | 'xl:col-gap-1/2'
  | 'xl:col-gap-1/3'
  | 'xl:col-gap-2/3'
  | 'xl:col-gap-1/4'
  | 'xl:col-gap-2/4'
  | 'xl:col-gap-3/4'
  | 'xl:col-gap-1/5'
  | 'xl:col-gap-2/5'
  | 'xl:col-gap-3/5'
  | 'xl:col-gap-4/5'
  | 'xl:col-gap-1/6'
  | 'xl:col-gap-2/6'
  | 'xl:col-gap-3/6'
  | 'xl:col-gap-4/6'
  | 'xl:col-gap-5/6'
  | 'xl:col-gap-1/12'
  | 'xl:col-gap-2/12'
  | 'xl:col-gap-3/12'
  | 'xl:col-gap-4/12'
  | 'xl:col-gap-5/12'
  | 'xl:col-gap-6/12'
  | 'xl:col-gap-7/12'
  | 'xl:col-gap-8/12'
  | 'xl:col-gap-9/12'
  | 'xl:col-gap-10/12'
  | 'xl:col-gap-11/12'
  | 'xl:col-gap-full'
  | 'xl:row-gap-0'
  | 'xl:row-gap-1'
  | 'xl:row-gap-2'
  | 'xl:row-gap-3'
  | 'xl:row-gap-4'
  | 'xl:row-gap-5'
  | 'xl:row-gap-6'
  | 'xl:row-gap-7'
  | 'xl:row-gap-8'
  | 'xl:row-gap-9'
  | 'xl:row-gap-10'
  | 'xl:row-gap-11'
  | 'xl:row-gap-12'
  | 'xl:row-gap-13'
  | 'xl:row-gap-14'
  | 'xl:row-gap-15'
  | 'xl:row-gap-16'
  | 'xl:row-gap-20'
  | 'xl:row-gap-24'
  | 'xl:row-gap-28'
  | 'xl:row-gap-32'
  | 'xl:row-gap-36'
  | 'xl:row-gap-40'
  | 'xl:row-gap-48'
  | 'xl:row-gap-56'
  | 'xl:row-gap-60'
  | 'xl:row-gap-64'
  | 'xl:row-gap-72'
  | 'xl:row-gap-80'
  | 'xl:row-gap-96'
  | 'xl:row-gap-px'
  | 'xl:row-gap-0.5'
  | 'xl:row-gap-1.5'
  | 'xl:row-gap-2.5'
  | 'xl:row-gap-3.5'
  | 'xl:row-gap-1/2'
  | 'xl:row-gap-1/3'
  | 'xl:row-gap-2/3'
  | 'xl:row-gap-1/4'
  | 'xl:row-gap-2/4'
  | 'xl:row-gap-3/4'
  | 'xl:row-gap-1/5'
  | 'xl:row-gap-2/5'
  | 'xl:row-gap-3/5'
  | 'xl:row-gap-4/5'
  | 'xl:row-gap-1/6'
  | 'xl:row-gap-2/6'
  | 'xl:row-gap-3/6'
  | 'xl:row-gap-4/6'
  | 'xl:row-gap-5/6'
  | 'xl:row-gap-1/12'
  | 'xl:row-gap-2/12'
  | 'xl:row-gap-3/12'
  | 'xl:row-gap-4/12'
  | 'xl:row-gap-5/12'
  | 'xl:row-gap-6/12'
  | 'xl:row-gap-7/12'
  | 'xl:row-gap-8/12'
  | 'xl:row-gap-9/12'
  | 'xl:row-gap-10/12'
  | 'xl:row-gap-11/12'
  | 'xl:row-gap-full'
  | 'xl:grid-flow-row'
  | 'xl:grid-flow-col'
  | 'xl:grid-flow-row-dense'
  | 'xl:grid-flow-col-dense'
  | 'xl:grid-cols-1'
  | 'xl:grid-cols-2'
  | 'xl:grid-cols-3'
  | 'xl:grid-cols-4'
  | 'xl:grid-cols-5'
  | 'xl:grid-cols-6'
  | 'xl:grid-cols-7'
  | 'xl:grid-cols-8'
  | 'xl:grid-cols-9'
  | 'xl:grid-cols-10'
  | 'xl:grid-cols-11'
  | 'xl:grid-cols-12'
  | 'xl:grid-cols-none'
  | 'xl:col-auto'
  | 'xl:col-span-1'
  | 'xl:col-span-2'
  | 'xl:col-span-3'
  | 'xl:col-span-4'
  | 'xl:col-span-5'
  | 'xl:col-span-6'
  | 'xl:col-span-7'
  | 'xl:col-span-8'
  | 'xl:col-span-9'
  | 'xl:col-span-10'
  | 'xl:col-span-11'
  | 'xl:col-span-12'
  | 'xl:col-start-1'
  | 'xl:col-start-2'
  | 'xl:col-start-3'
  | 'xl:col-start-4'
  | 'xl:col-start-5'
  | 'xl:col-start-6'
  | 'xl:col-start-7'
  | 'xl:col-start-8'
  | 'xl:col-start-9'
  | 'xl:col-start-10'
  | 'xl:col-start-11'
  | 'xl:col-start-12'
  | 'xl:col-start-13'
  | 'xl:col-start-auto'
  | 'xl:col-end-1'
  | 'xl:col-end-2'
  | 'xl:col-end-3'
  | 'xl:col-end-4'
  | 'xl:col-end-5'
  | 'xl:col-end-6'
  | 'xl:col-end-7'
  | 'xl:col-end-8'
  | 'xl:col-end-9'
  | 'xl:col-end-10'
  | 'xl:col-end-11'
  | 'xl:col-end-12'
  | 'xl:col-end-13'
  | 'xl:col-end-auto'
  | 'xl:grid-rows-1'
  | 'xl:grid-rows-2'
  | 'xl:grid-rows-3'
  | 'xl:grid-rows-4'
  | 'xl:grid-rows-5'
  | 'xl:grid-rows-6'
  | 'xl:grid-rows-none'
  | 'xl:row-auto'
  | 'xl:row-span-1'
  | 'xl:row-span-2'
  | 'xl:row-span-3'
  | 'xl:row-span-4'
  | 'xl:row-span-5'
  | 'xl:row-span-6'
  | 'xl:row-start-1'
  | 'xl:row-start-2'
  | 'xl:row-start-3'
  | 'xl:row-start-4'
  | 'xl:row-start-5'
  | 'xl:row-start-6'
  | 'xl:row-start-7'
  | 'xl:row-start-auto'
  | 'xl:row-end-1'
  | 'xl:row-end-2'
  | 'xl:row-end-3'
  | 'xl:row-end-4'
  | 'xl:row-end-5'
  | 'xl:row-end-6'
  | 'xl:row-end-7'
  | 'xl:row-end-auto'
  | 'xl:transform'
  | 'xl:transform-none'
  | 'xl:origin-center'
  | 'xl:origin-top'
  | 'xl:origin-top-right'
  | 'xl:origin-right'
  | 'xl:origin-bottom-right'
  | 'xl:origin-bottom'
  | 'xl:origin-bottom-left'
  | 'xl:origin-left'
  | 'xl:origin-top-left'
  | 'xl:scale-0'
  | 'xl:scale-50'
  | 'xl:scale-75'
  | 'xl:scale-90'
  | 'xl:scale-95'
  | 'xl:scale-100'
  | 'xl:scale-105'
  | 'xl:scale-110'
  | 'xl:scale-125'
  | 'xl:scale-150'
  | 'xl:scale-x-0'
  | 'xl:scale-x-50'
  | 'xl:scale-x-75'
  | 'xl:scale-x-90'
  | 'xl:scale-x-95'
  | 'xl:scale-x-100'
  | 'xl:scale-x-105'
  | 'xl:scale-x-110'
  | 'xl:scale-x-125'
  | 'xl:scale-x-150'
  | 'xl:scale-y-0'
  | 'xl:scale-y-50'
  | 'xl:scale-y-75'
  | 'xl:scale-y-90'
  | 'xl:scale-y-95'
  | 'xl:scale-y-100'
  | 'xl:scale-y-105'
  | 'xl:scale-y-110'
  | 'xl:scale-y-125'
  | 'xl:scale-y-150'
  | 'xl:hover:scale-0'
  | 'xl:hover:scale-50'
  | 'xl:hover:scale-75'
  | 'xl:hover:scale-90'
  | 'xl:hover:scale-95'
  | 'xl:hover:scale-100'
  | 'xl:hover:scale-105'
  | 'xl:hover:scale-110'
  | 'xl:hover:scale-125'
  | 'xl:hover:scale-150'
  | 'xl:hover:scale-x-0'
  | 'xl:hover:scale-x-50'
  | 'xl:hover:scale-x-75'
  | 'xl:hover:scale-x-90'
  | 'xl:hover:scale-x-95'
  | 'xl:hover:scale-x-100'
  | 'xl:hover:scale-x-105'
  | 'xl:hover:scale-x-110'
  | 'xl:hover:scale-x-125'
  | 'xl:hover:scale-x-150'
  | 'xl:hover:scale-y-0'
  | 'xl:hover:scale-y-50'
  | 'xl:hover:scale-y-75'
  | 'xl:hover:scale-y-90'
  | 'xl:hover:scale-y-95'
  | 'xl:hover:scale-y-100'
  | 'xl:hover:scale-y-105'
  | 'xl:hover:scale-y-110'
  | 'xl:hover:scale-y-125'
  | 'xl:hover:scale-y-150'
  | 'xl:focus:scale-0'
  | 'xl:focus:scale-50'
  | 'xl:focus:scale-75'
  | 'xl:focus:scale-90'
  | 'xl:focus:scale-95'
  | 'xl:focus:scale-100'
  | 'xl:focus:scale-105'
  | 'xl:focus:scale-110'
  | 'xl:focus:scale-125'
  | 'xl:focus:scale-150'
  | 'xl:focus:scale-x-0'
  | 'xl:focus:scale-x-50'
  | 'xl:focus:scale-x-75'
  | 'xl:focus:scale-x-90'
  | 'xl:focus:scale-x-95'
  | 'xl:focus:scale-x-100'
  | 'xl:focus:scale-x-105'
  | 'xl:focus:scale-x-110'
  | 'xl:focus:scale-x-125'
  | 'xl:focus:scale-x-150'
  | 'xl:focus:scale-y-0'
  | 'xl:focus:scale-y-50'
  | 'xl:focus:scale-y-75'
  | 'xl:focus:scale-y-90'
  | 'xl:focus:scale-y-95'
  | 'xl:focus:scale-y-100'
  | 'xl:focus:scale-y-105'
  | 'xl:focus:scale-y-110'
  | 'xl:focus:scale-y-125'
  | 'xl:focus:scale-y-150'
  | 'xl:rotate-0'
  | 'xl:rotate-45'
  | 'xl:rotate-90'
  | 'xl:rotate-180'
  | 'xl:-rotate-180'
  | 'xl:-rotate-90'
  | 'xl:-rotate-45'
  | 'xl:hover:rotate-0'
  | 'xl:hover:rotate-45'
  | 'xl:hover:rotate-90'
  | 'xl:hover:rotate-180'
  | 'xl:hover:-rotate-180'
  | 'xl:hover:-rotate-90'
  | 'xl:hover:-rotate-45'
  | 'xl:focus:rotate-0'
  | 'xl:focus:rotate-45'
  | 'xl:focus:rotate-90'
  | 'xl:focus:rotate-180'
  | 'xl:focus:-rotate-180'
  | 'xl:focus:-rotate-90'
  | 'xl:focus:-rotate-45'
  | 'xl:translate-x-0'
  | 'xl:translate-x-1'
  | 'xl:translate-x-2'
  | 'xl:translate-x-3'
  | 'xl:translate-x-4'
  | 'xl:translate-x-5'
  | 'xl:translate-x-6'
  | 'xl:translate-x-7'
  | 'xl:translate-x-8'
  | 'xl:translate-x-9'
  | 'xl:translate-x-10'
  | 'xl:translate-x-11'
  | 'xl:translate-x-12'
  | 'xl:translate-x-13'
  | 'xl:translate-x-14'
  | 'xl:translate-x-15'
  | 'xl:translate-x-16'
  | 'xl:translate-x-20'
  | 'xl:translate-x-24'
  | 'xl:translate-x-28'
  | 'xl:translate-x-32'
  | 'xl:translate-x-36'
  | 'xl:translate-x-40'
  | 'xl:translate-x-48'
  | 'xl:translate-x-56'
  | 'xl:translate-x-60'
  | 'xl:translate-x-64'
  | 'xl:translate-x-72'
  | 'xl:translate-x-80'
  | 'xl:translate-x-96'
  | 'xl:translate-x-px'
  | 'xl:translate-x-0.5'
  | 'xl:translate-x-1.5'
  | 'xl:translate-x-2.5'
  | 'xl:translate-x-3.5'
  | 'xl:translate-x-1/2'
  | 'xl:translate-x-1/3'
  | 'xl:translate-x-2/3'
  | 'xl:translate-x-1/4'
  | 'xl:translate-x-2/4'
  | 'xl:translate-x-3/4'
  | 'xl:translate-x-1/5'
  | 'xl:translate-x-2/5'
  | 'xl:translate-x-3/5'
  | 'xl:translate-x-4/5'
  | 'xl:translate-x-1/6'
  | 'xl:translate-x-2/6'
  | 'xl:translate-x-3/6'
  | 'xl:translate-x-4/6'
  | 'xl:translate-x-5/6'
  | 'xl:translate-x-1/12'
  | 'xl:translate-x-2/12'
  | 'xl:translate-x-3/12'
  | 'xl:translate-x-4/12'
  | 'xl:translate-x-5/12'
  | 'xl:translate-x-6/12'
  | 'xl:translate-x-7/12'
  | 'xl:translate-x-8/12'
  | 'xl:translate-x-9/12'
  | 'xl:translate-x-10/12'
  | 'xl:translate-x-11/12'
  | 'xl:translate-x-full'
  | 'xl:-translate-x-1'
  | 'xl:-translate-x-2'
  | 'xl:-translate-x-3'
  | 'xl:-translate-x-4'
  | 'xl:-translate-x-5'
  | 'xl:-translate-x-6'
  | 'xl:-translate-x-7'
  | 'xl:-translate-x-8'
  | 'xl:-translate-x-9'
  | 'xl:-translate-x-10'
  | 'xl:-translate-x-11'
  | 'xl:-translate-x-12'
  | 'xl:-translate-x-13'
  | 'xl:-translate-x-14'
  | 'xl:-translate-x-15'
  | 'xl:-translate-x-16'
  | 'xl:-translate-x-20'
  | 'xl:-translate-x-24'
  | 'xl:-translate-x-28'
  | 'xl:-translate-x-32'
  | 'xl:-translate-x-36'
  | 'xl:-translate-x-40'
  | 'xl:-translate-x-48'
  | 'xl:-translate-x-56'
  | 'xl:-translate-x-60'
  | 'xl:-translate-x-64'
  | 'xl:-translate-x-72'
  | 'xl:-translate-x-80'
  | 'xl:-translate-x-96'
  | 'xl:-translate-x-px'
  | 'xl:-translate-x-0.5'
  | 'xl:-translate-x-1.5'
  | 'xl:-translate-x-2.5'
  | 'xl:-translate-x-3.5'
  | 'xl:-translate-x-1/2'
  | 'xl:-translate-x-1/3'
  | 'xl:-translate-x-2/3'
  | 'xl:-translate-x-1/4'
  | 'xl:-translate-x-2/4'
  | 'xl:-translate-x-3/4'
  | 'xl:-translate-x-1/5'
  | 'xl:-translate-x-2/5'
  | 'xl:-translate-x-3/5'
  | 'xl:-translate-x-4/5'
  | 'xl:-translate-x-1/6'
  | 'xl:-translate-x-2/6'
  | 'xl:-translate-x-3/6'
  | 'xl:-translate-x-4/6'
  | 'xl:-translate-x-5/6'
  | 'xl:-translate-x-1/12'
  | 'xl:-translate-x-2/12'
  | 'xl:-translate-x-3/12'
  | 'xl:-translate-x-4/12'
  | 'xl:-translate-x-5/12'
  | 'xl:-translate-x-6/12'
  | 'xl:-translate-x-7/12'
  | 'xl:-translate-x-8/12'
  | 'xl:-translate-x-9/12'
  | 'xl:-translate-x-10/12'
  | 'xl:-translate-x-11/12'
  | 'xl:-translate-x-full'
  | 'xl:translate-y-0'
  | 'xl:translate-y-1'
  | 'xl:translate-y-2'
  | 'xl:translate-y-3'
  | 'xl:translate-y-4'
  | 'xl:translate-y-5'
  | 'xl:translate-y-6'
  | 'xl:translate-y-7'
  | 'xl:translate-y-8'
  | 'xl:translate-y-9'
  | 'xl:translate-y-10'
  | 'xl:translate-y-11'
  | 'xl:translate-y-12'
  | 'xl:translate-y-13'
  | 'xl:translate-y-14'
  | 'xl:translate-y-15'
  | 'xl:translate-y-16'
  | 'xl:translate-y-20'
  | 'xl:translate-y-24'
  | 'xl:translate-y-28'
  | 'xl:translate-y-32'
  | 'xl:translate-y-36'
  | 'xl:translate-y-40'
  | 'xl:translate-y-48'
  | 'xl:translate-y-56'
  | 'xl:translate-y-60'
  | 'xl:translate-y-64'
  | 'xl:translate-y-72'
  | 'xl:translate-y-80'
  | 'xl:translate-y-96'
  | 'xl:translate-y-px'
  | 'xl:translate-y-0.5'
  | 'xl:translate-y-1.5'
  | 'xl:translate-y-2.5'
  | 'xl:translate-y-3.5'
  | 'xl:translate-y-1/2'
  | 'xl:translate-y-1/3'
  | 'xl:translate-y-2/3'
  | 'xl:translate-y-1/4'
  | 'xl:translate-y-2/4'
  | 'xl:translate-y-3/4'
  | 'xl:translate-y-1/5'
  | 'xl:translate-y-2/5'
  | 'xl:translate-y-3/5'
  | 'xl:translate-y-4/5'
  | 'xl:translate-y-1/6'
  | 'xl:translate-y-2/6'
  | 'xl:translate-y-3/6'
  | 'xl:translate-y-4/6'
  | 'xl:translate-y-5/6'
  | 'xl:translate-y-1/12'
  | 'xl:translate-y-2/12'
  | 'xl:translate-y-3/12'
  | 'xl:translate-y-4/12'
  | 'xl:translate-y-5/12'
  | 'xl:translate-y-6/12'
  | 'xl:translate-y-7/12'
  | 'xl:translate-y-8/12'
  | 'xl:translate-y-9/12'
  | 'xl:translate-y-10/12'
  | 'xl:translate-y-11/12'
  | 'xl:translate-y-full'
  | 'xl:-translate-y-1'
  | 'xl:-translate-y-2'
  | 'xl:-translate-y-3'
  | 'xl:-translate-y-4'
  | 'xl:-translate-y-5'
  | 'xl:-translate-y-6'
  | 'xl:-translate-y-7'
  | 'xl:-translate-y-8'
  | 'xl:-translate-y-9'
  | 'xl:-translate-y-10'
  | 'xl:-translate-y-11'
  | 'xl:-translate-y-12'
  | 'xl:-translate-y-13'
  | 'xl:-translate-y-14'
  | 'xl:-translate-y-15'
  | 'xl:-translate-y-16'
  | 'xl:-translate-y-20'
  | 'xl:-translate-y-24'
  | 'xl:-translate-y-28'
  | 'xl:-translate-y-32'
  | 'xl:-translate-y-36'
  | 'xl:-translate-y-40'
  | 'xl:-translate-y-48'
  | 'xl:-translate-y-56'
  | 'xl:-translate-y-60'
  | 'xl:-translate-y-64'
  | 'xl:-translate-y-72'
  | 'xl:-translate-y-80'
  | 'xl:-translate-y-96'
  | 'xl:-translate-y-px'
  | 'xl:-translate-y-0.5'
  | 'xl:-translate-y-1.5'
  | 'xl:-translate-y-2.5'
  | 'xl:-translate-y-3.5'
  | 'xl:-translate-y-1/2'
  | 'xl:-translate-y-1/3'
  | 'xl:-translate-y-2/3'
  | 'xl:-translate-y-1/4'
  | 'xl:-translate-y-2/4'
  | 'xl:-translate-y-3/4'
  | 'xl:-translate-y-1/5'
  | 'xl:-translate-y-2/5'
  | 'xl:-translate-y-3/5'
  | 'xl:-translate-y-4/5'
  | 'xl:-translate-y-1/6'
  | 'xl:-translate-y-2/6'
  | 'xl:-translate-y-3/6'
  | 'xl:-translate-y-4/6'
  | 'xl:-translate-y-5/6'
  | 'xl:-translate-y-1/12'
  | 'xl:-translate-y-2/12'
  | 'xl:-translate-y-3/12'
  | 'xl:-translate-y-4/12'
  | 'xl:-translate-y-5/12'
  | 'xl:-translate-y-6/12'
  | 'xl:-translate-y-7/12'
  | 'xl:-translate-y-8/12'
  | 'xl:-translate-y-9/12'
  | 'xl:-translate-y-10/12'
  | 'xl:-translate-y-11/12'
  | 'xl:-translate-y-full'
  | 'xl:hover:translate-x-0'
  | 'xl:hover:translate-x-1'
  | 'xl:hover:translate-x-2'
  | 'xl:hover:translate-x-3'
  | 'xl:hover:translate-x-4'
  | 'xl:hover:translate-x-5'
  | 'xl:hover:translate-x-6'
  | 'xl:hover:translate-x-7'
  | 'xl:hover:translate-x-8'
  | 'xl:hover:translate-x-9'
  | 'xl:hover:translate-x-10'
  | 'xl:hover:translate-x-11'
  | 'xl:hover:translate-x-12'
  | 'xl:hover:translate-x-13'
  | 'xl:hover:translate-x-14'
  | 'xl:hover:translate-x-15'
  | 'xl:hover:translate-x-16'
  | 'xl:hover:translate-x-20'
  | 'xl:hover:translate-x-24'
  | 'xl:hover:translate-x-28'
  | 'xl:hover:translate-x-32'
  | 'xl:hover:translate-x-36'
  | 'xl:hover:translate-x-40'
  | 'xl:hover:translate-x-48'
  | 'xl:hover:translate-x-56'
  | 'xl:hover:translate-x-60'
  | 'xl:hover:translate-x-64'
  | 'xl:hover:translate-x-72'
  | 'xl:hover:translate-x-80'
  | 'xl:hover:translate-x-96'
  | 'xl:hover:translate-x-px'
  | 'xl:hover:translate-x-0.5'
  | 'xl:hover:translate-x-1.5'
  | 'xl:hover:translate-x-2.5'
  | 'xl:hover:translate-x-3.5'
  | 'xl:hover:translate-x-1/2'
  | 'xl:hover:translate-x-1/3'
  | 'xl:hover:translate-x-2/3'
  | 'xl:hover:translate-x-1/4'
  | 'xl:hover:translate-x-2/4'
  | 'xl:hover:translate-x-3/4'
  | 'xl:hover:translate-x-1/5'
  | 'xl:hover:translate-x-2/5'
  | 'xl:hover:translate-x-3/5'
  | 'xl:hover:translate-x-4/5'
  | 'xl:hover:translate-x-1/6'
  | 'xl:hover:translate-x-2/6'
  | 'xl:hover:translate-x-3/6'
  | 'xl:hover:translate-x-4/6'
  | 'xl:hover:translate-x-5/6'
  | 'xl:hover:translate-x-1/12'
  | 'xl:hover:translate-x-2/12'
  | 'xl:hover:translate-x-3/12'
  | 'xl:hover:translate-x-4/12'
  | 'xl:hover:translate-x-5/12'
  | 'xl:hover:translate-x-6/12'
  | 'xl:hover:translate-x-7/12'
  | 'xl:hover:translate-x-8/12'
  | 'xl:hover:translate-x-9/12'
  | 'xl:hover:translate-x-10/12'
  | 'xl:hover:translate-x-11/12'
  | 'xl:hover:translate-x-full'
  | 'xl:hover:-translate-x-1'
  | 'xl:hover:-translate-x-2'
  | 'xl:hover:-translate-x-3'
  | 'xl:hover:-translate-x-4'
  | 'xl:hover:-translate-x-5'
  | 'xl:hover:-translate-x-6'
  | 'xl:hover:-translate-x-7'
  | 'xl:hover:-translate-x-8'
  | 'xl:hover:-translate-x-9'
  | 'xl:hover:-translate-x-10'
  | 'xl:hover:-translate-x-11'
  | 'xl:hover:-translate-x-12'
  | 'xl:hover:-translate-x-13'
  | 'xl:hover:-translate-x-14'
  | 'xl:hover:-translate-x-15'
  | 'xl:hover:-translate-x-16'
  | 'xl:hover:-translate-x-20'
  | 'xl:hover:-translate-x-24'
  | 'xl:hover:-translate-x-28'
  | 'xl:hover:-translate-x-32'
  | 'xl:hover:-translate-x-36'
  | 'xl:hover:-translate-x-40'
  | 'xl:hover:-translate-x-48'
  | 'xl:hover:-translate-x-56'
  | 'xl:hover:-translate-x-60'
  | 'xl:hover:-translate-x-64'
  | 'xl:hover:-translate-x-72'
  | 'xl:hover:-translate-x-80'
  | 'xl:hover:-translate-x-96'
  | 'xl:hover:-translate-x-px'
  | 'xl:hover:-translate-x-0.5'
  | 'xl:hover:-translate-x-1.5'
  | 'xl:hover:-translate-x-2.5'
  | 'xl:hover:-translate-x-3.5'
  | 'xl:hover:-translate-x-1/2'
  | 'xl:hover:-translate-x-1/3'
  | 'xl:hover:-translate-x-2/3'
  | 'xl:hover:-translate-x-1/4'
  | 'xl:hover:-translate-x-2/4'
  | 'xl:hover:-translate-x-3/4'
  | 'xl:hover:-translate-x-1/5'
  | 'xl:hover:-translate-x-2/5'
  | 'xl:hover:-translate-x-3/5'
  | 'xl:hover:-translate-x-4/5'
  | 'xl:hover:-translate-x-1/6'
  | 'xl:hover:-translate-x-2/6'
  | 'xl:hover:-translate-x-3/6'
  | 'xl:hover:-translate-x-4/6'
  | 'xl:hover:-translate-x-5/6'
  | 'xl:hover:-translate-x-1/12'
  | 'xl:hover:-translate-x-2/12'
  | 'xl:hover:-translate-x-3/12'
  | 'xl:hover:-translate-x-4/12'
  | 'xl:hover:-translate-x-5/12'
  | 'xl:hover:-translate-x-6/12'
  | 'xl:hover:-translate-x-7/12'
  | 'xl:hover:-translate-x-8/12'
  | 'xl:hover:-translate-x-9/12'
  | 'xl:hover:-translate-x-10/12'
  | 'xl:hover:-translate-x-11/12'
  | 'xl:hover:-translate-x-full'
  | 'xl:hover:translate-y-0'
  | 'xl:hover:translate-y-1'
  | 'xl:hover:translate-y-2'
  | 'xl:hover:translate-y-3'
  | 'xl:hover:translate-y-4'
  | 'xl:hover:translate-y-5'
  | 'xl:hover:translate-y-6'
  | 'xl:hover:translate-y-7'
  | 'xl:hover:translate-y-8'
  | 'xl:hover:translate-y-9'
  | 'xl:hover:translate-y-10'
  | 'xl:hover:translate-y-11'
  | 'xl:hover:translate-y-12'
  | 'xl:hover:translate-y-13'
  | 'xl:hover:translate-y-14'
  | 'xl:hover:translate-y-15'
  | 'xl:hover:translate-y-16'
  | 'xl:hover:translate-y-20'
  | 'xl:hover:translate-y-24'
  | 'xl:hover:translate-y-28'
  | 'xl:hover:translate-y-32'
  | 'xl:hover:translate-y-36'
  | 'xl:hover:translate-y-40'
  | 'xl:hover:translate-y-48'
  | 'xl:hover:translate-y-56'
  | 'xl:hover:translate-y-60'
  | 'xl:hover:translate-y-64'
  | 'xl:hover:translate-y-72'
  | 'xl:hover:translate-y-80'
  | 'xl:hover:translate-y-96'
  | 'xl:hover:translate-y-px'
  | 'xl:hover:translate-y-0.5'
  | 'xl:hover:translate-y-1.5'
  | 'xl:hover:translate-y-2.5'
  | 'xl:hover:translate-y-3.5'
  | 'xl:hover:translate-y-1/2'
  | 'xl:hover:translate-y-1/3'
  | 'xl:hover:translate-y-2/3'
  | 'xl:hover:translate-y-1/4'
  | 'xl:hover:translate-y-2/4'
  | 'xl:hover:translate-y-3/4'
  | 'xl:hover:translate-y-1/5'
  | 'xl:hover:translate-y-2/5'
  | 'xl:hover:translate-y-3/5'
  | 'xl:hover:translate-y-4/5'
  | 'xl:hover:translate-y-1/6'
  | 'xl:hover:translate-y-2/6'
  | 'xl:hover:translate-y-3/6'
  | 'xl:hover:translate-y-4/6'
  | 'xl:hover:translate-y-5/6'
  | 'xl:hover:translate-y-1/12'
  | 'xl:hover:translate-y-2/12'
  | 'xl:hover:translate-y-3/12'
  | 'xl:hover:translate-y-4/12'
  | 'xl:hover:translate-y-5/12'
  | 'xl:hover:translate-y-6/12'
  | 'xl:hover:translate-y-7/12'
  | 'xl:hover:translate-y-8/12'
  | 'xl:hover:translate-y-9/12'
  | 'xl:hover:translate-y-10/12'
  | 'xl:hover:translate-y-11/12'
  | 'xl:hover:translate-y-full'
  | 'xl:hover:-translate-y-1'
  | 'xl:hover:-translate-y-2'
  | 'xl:hover:-translate-y-3'
  | 'xl:hover:-translate-y-4'
  | 'xl:hover:-translate-y-5'
  | 'xl:hover:-translate-y-6'
  | 'xl:hover:-translate-y-7'
  | 'xl:hover:-translate-y-8'
  | 'xl:hover:-translate-y-9'
  | 'xl:hover:-translate-y-10'
  | 'xl:hover:-translate-y-11'
  | 'xl:hover:-translate-y-12'
  | 'xl:hover:-translate-y-13'
  | 'xl:hover:-translate-y-14'
  | 'xl:hover:-translate-y-15'
  | 'xl:hover:-translate-y-16'
  | 'xl:hover:-translate-y-20'
  | 'xl:hover:-translate-y-24'
  | 'xl:hover:-translate-y-28'
  | 'xl:hover:-translate-y-32'
  | 'xl:hover:-translate-y-36'
  | 'xl:hover:-translate-y-40'
  | 'xl:hover:-translate-y-48'
  | 'xl:hover:-translate-y-56'
  | 'xl:hover:-translate-y-60'
  | 'xl:hover:-translate-y-64'
  | 'xl:hover:-translate-y-72'
  | 'xl:hover:-translate-y-80'
  | 'xl:hover:-translate-y-96'
  | 'xl:hover:-translate-y-px'
  | 'xl:hover:-translate-y-0.5'
  | 'xl:hover:-translate-y-1.5'
  | 'xl:hover:-translate-y-2.5'
  | 'xl:hover:-translate-y-3.5'
  | 'xl:hover:-translate-y-1/2'
  | 'xl:hover:-translate-y-1/3'
  | 'xl:hover:-translate-y-2/3'
  | 'xl:hover:-translate-y-1/4'
  | 'xl:hover:-translate-y-2/4'
  | 'xl:hover:-translate-y-3/4'
  | 'xl:hover:-translate-y-1/5'
  | 'xl:hover:-translate-y-2/5'
  | 'xl:hover:-translate-y-3/5'
  | 'xl:hover:-translate-y-4/5'
  | 'xl:hover:-translate-y-1/6'
  | 'xl:hover:-translate-y-2/6'
  | 'xl:hover:-translate-y-3/6'
  | 'xl:hover:-translate-y-4/6'
  | 'xl:hover:-translate-y-5/6'
  | 'xl:hover:-translate-y-1/12'
  | 'xl:hover:-translate-y-2/12'
  | 'xl:hover:-translate-y-3/12'
  | 'xl:hover:-translate-y-4/12'
  | 'xl:hover:-translate-y-5/12'
  | 'xl:hover:-translate-y-6/12'
  | 'xl:hover:-translate-y-7/12'
  | 'xl:hover:-translate-y-8/12'
  | 'xl:hover:-translate-y-9/12'
  | 'xl:hover:-translate-y-10/12'
  | 'xl:hover:-translate-y-11/12'
  | 'xl:hover:-translate-y-full'
  | 'xl:focus:translate-x-0'
  | 'xl:focus:translate-x-1'
  | 'xl:focus:translate-x-2'
  | 'xl:focus:translate-x-3'
  | 'xl:focus:translate-x-4'
  | 'xl:focus:translate-x-5'
  | 'xl:focus:translate-x-6'
  | 'xl:focus:translate-x-7'
  | 'xl:focus:translate-x-8'
  | 'xl:focus:translate-x-9'
  | 'xl:focus:translate-x-10'
  | 'xl:focus:translate-x-11'
  | 'xl:focus:translate-x-12'
  | 'xl:focus:translate-x-13'
  | 'xl:focus:translate-x-14'
  | 'xl:focus:translate-x-15'
  | 'xl:focus:translate-x-16'
  | 'xl:focus:translate-x-20'
  | 'xl:focus:translate-x-24'
  | 'xl:focus:translate-x-28'
  | 'xl:focus:translate-x-32'
  | 'xl:focus:translate-x-36'
  | 'xl:focus:translate-x-40'
  | 'xl:focus:translate-x-48'
  | 'xl:focus:translate-x-56'
  | 'xl:focus:translate-x-60'
  | 'xl:focus:translate-x-64'
  | 'xl:focus:translate-x-72'
  | 'xl:focus:translate-x-80'
  | 'xl:focus:translate-x-96'
  | 'xl:focus:translate-x-px'
  | 'xl:focus:translate-x-0.5'
  | 'xl:focus:translate-x-1.5'
  | 'xl:focus:translate-x-2.5'
  | 'xl:focus:translate-x-3.5'
  | 'xl:focus:translate-x-1/2'
  | 'xl:focus:translate-x-1/3'
  | 'xl:focus:translate-x-2/3'
  | 'xl:focus:translate-x-1/4'
  | 'xl:focus:translate-x-2/4'
  | 'xl:focus:translate-x-3/4'
  | 'xl:focus:translate-x-1/5'
  | 'xl:focus:translate-x-2/5'
  | 'xl:focus:translate-x-3/5'
  | 'xl:focus:translate-x-4/5'
  | 'xl:focus:translate-x-1/6'
  | 'xl:focus:translate-x-2/6'
  | 'xl:focus:translate-x-3/6'
  | 'xl:focus:translate-x-4/6'
  | 'xl:focus:translate-x-5/6'
  | 'xl:focus:translate-x-1/12'
  | 'xl:focus:translate-x-2/12'
  | 'xl:focus:translate-x-3/12'
  | 'xl:focus:translate-x-4/12'
  | 'xl:focus:translate-x-5/12'
  | 'xl:focus:translate-x-6/12'
  | 'xl:focus:translate-x-7/12'
  | 'xl:focus:translate-x-8/12'
  | 'xl:focus:translate-x-9/12'
  | 'xl:focus:translate-x-10/12'
  | 'xl:focus:translate-x-11/12'
  | 'xl:focus:translate-x-full'
  | 'xl:focus:-translate-x-1'
  | 'xl:focus:-translate-x-2'
  | 'xl:focus:-translate-x-3'
  | 'xl:focus:-translate-x-4'
  | 'xl:focus:-translate-x-5'
  | 'xl:focus:-translate-x-6'
  | 'xl:focus:-translate-x-7'
  | 'xl:focus:-translate-x-8'
  | 'xl:focus:-translate-x-9'
  | 'xl:focus:-translate-x-10'
  | 'xl:focus:-translate-x-11'
  | 'xl:focus:-translate-x-12'
  | 'xl:focus:-translate-x-13'
  | 'xl:focus:-translate-x-14'
  | 'xl:focus:-translate-x-15'
  | 'xl:focus:-translate-x-16'
  | 'xl:focus:-translate-x-20'
  | 'xl:focus:-translate-x-24'
  | 'xl:focus:-translate-x-28'
  | 'xl:focus:-translate-x-32'
  | 'xl:focus:-translate-x-36'
  | 'xl:focus:-translate-x-40'
  | 'xl:focus:-translate-x-48'
  | 'xl:focus:-translate-x-56'
  | 'xl:focus:-translate-x-60'
  | 'xl:focus:-translate-x-64'
  | 'xl:focus:-translate-x-72'
  | 'xl:focus:-translate-x-80'
  | 'xl:focus:-translate-x-96'
  | 'xl:focus:-translate-x-px'
  | 'xl:focus:-translate-x-0.5'
  | 'xl:focus:-translate-x-1.5'
  | 'xl:focus:-translate-x-2.5'
  | 'xl:focus:-translate-x-3.5'
  | 'xl:focus:-translate-x-1/2'
  | 'xl:focus:-translate-x-1/3'
  | 'xl:focus:-translate-x-2/3'
  | 'xl:focus:-translate-x-1/4'
  | 'xl:focus:-translate-x-2/4'
  | 'xl:focus:-translate-x-3/4'
  | 'xl:focus:-translate-x-1/5'
  | 'xl:focus:-translate-x-2/5'
  | 'xl:focus:-translate-x-3/5'
  | 'xl:focus:-translate-x-4/5'
  | 'xl:focus:-translate-x-1/6'
  | 'xl:focus:-translate-x-2/6'
  | 'xl:focus:-translate-x-3/6'
  | 'xl:focus:-translate-x-4/6'
  | 'xl:focus:-translate-x-5/6'
  | 'xl:focus:-translate-x-1/12'
  | 'xl:focus:-translate-x-2/12'
  | 'xl:focus:-translate-x-3/12'
  | 'xl:focus:-translate-x-4/12'
  | 'xl:focus:-translate-x-5/12'
  | 'xl:focus:-translate-x-6/12'
  | 'xl:focus:-translate-x-7/12'
  | 'xl:focus:-translate-x-8/12'
  | 'xl:focus:-translate-x-9/12'
  | 'xl:focus:-translate-x-10/12'
  | 'xl:focus:-translate-x-11/12'
  | 'xl:focus:-translate-x-full'
  | 'xl:focus:translate-y-0'
  | 'xl:focus:translate-y-1'
  | 'xl:focus:translate-y-2'
  | 'xl:focus:translate-y-3'
  | 'xl:focus:translate-y-4'
  | 'xl:focus:translate-y-5'
  | 'xl:focus:translate-y-6'
  | 'xl:focus:translate-y-7'
  | 'xl:focus:translate-y-8'
  | 'xl:focus:translate-y-9'
  | 'xl:focus:translate-y-10'
  | 'xl:focus:translate-y-11'
  | 'xl:focus:translate-y-12'
  | 'xl:focus:translate-y-13'
  | 'xl:focus:translate-y-14'
  | 'xl:focus:translate-y-15'
  | 'xl:focus:translate-y-16'
  | 'xl:focus:translate-y-20'
  | 'xl:focus:translate-y-24'
  | 'xl:focus:translate-y-28'
  | 'xl:focus:translate-y-32'
  | 'xl:focus:translate-y-36'
  | 'xl:focus:translate-y-40'
  | 'xl:focus:translate-y-48'
  | 'xl:focus:translate-y-56'
  | 'xl:focus:translate-y-60'
  | 'xl:focus:translate-y-64'
  | 'xl:focus:translate-y-72'
  | 'xl:focus:translate-y-80'
  | 'xl:focus:translate-y-96'
  | 'xl:focus:translate-y-px'
  | 'xl:focus:translate-y-0.5'
  | 'xl:focus:translate-y-1.5'
  | 'xl:focus:translate-y-2.5'
  | 'xl:focus:translate-y-3.5'
  | 'xl:focus:translate-y-1/2'
  | 'xl:focus:translate-y-1/3'
  | 'xl:focus:translate-y-2/3'
  | 'xl:focus:translate-y-1/4'
  | 'xl:focus:translate-y-2/4'
  | 'xl:focus:translate-y-3/4'
  | 'xl:focus:translate-y-1/5'
  | 'xl:focus:translate-y-2/5'
  | 'xl:focus:translate-y-3/5'
  | 'xl:focus:translate-y-4/5'
  | 'xl:focus:translate-y-1/6'
  | 'xl:focus:translate-y-2/6'
  | 'xl:focus:translate-y-3/6'
  | 'xl:focus:translate-y-4/6'
  | 'xl:focus:translate-y-5/6'
  | 'xl:focus:translate-y-1/12'
  | 'xl:focus:translate-y-2/12'
  | 'xl:focus:translate-y-3/12'
  | 'xl:focus:translate-y-4/12'
  | 'xl:focus:translate-y-5/12'
  | 'xl:focus:translate-y-6/12'
  | 'xl:focus:translate-y-7/12'
  | 'xl:focus:translate-y-8/12'
  | 'xl:focus:translate-y-9/12'
  | 'xl:focus:translate-y-10/12'
  | 'xl:focus:translate-y-11/12'
  | 'xl:focus:translate-y-full'
  | 'xl:focus:-translate-y-1'
  | 'xl:focus:-translate-y-2'
  | 'xl:focus:-translate-y-3'
  | 'xl:focus:-translate-y-4'
  | 'xl:focus:-translate-y-5'
  | 'xl:focus:-translate-y-6'
  | 'xl:focus:-translate-y-7'
  | 'xl:focus:-translate-y-8'
  | 'xl:focus:-translate-y-9'
  | 'xl:focus:-translate-y-10'
  | 'xl:focus:-translate-y-11'
  | 'xl:focus:-translate-y-12'
  | 'xl:focus:-translate-y-13'
  | 'xl:focus:-translate-y-14'
  | 'xl:focus:-translate-y-15'
  | 'xl:focus:-translate-y-16'
  | 'xl:focus:-translate-y-20'
  | 'xl:focus:-translate-y-24'
  | 'xl:focus:-translate-y-28'
  | 'xl:focus:-translate-y-32'
  | 'xl:focus:-translate-y-36'
  | 'xl:focus:-translate-y-40'
  | 'xl:focus:-translate-y-48'
  | 'xl:focus:-translate-y-56'
  | 'xl:focus:-translate-y-60'
  | 'xl:focus:-translate-y-64'
  | 'xl:focus:-translate-y-72'
  | 'xl:focus:-translate-y-80'
  | 'xl:focus:-translate-y-96'
  | 'xl:focus:-translate-y-px'
  | 'xl:focus:-translate-y-0.5'
  | 'xl:focus:-translate-y-1.5'
  | 'xl:focus:-translate-y-2.5'
  | 'xl:focus:-translate-y-3.5'
  | 'xl:focus:-translate-y-1/2'
  | 'xl:focus:-translate-y-1/3'
  | 'xl:focus:-translate-y-2/3'
  | 'xl:focus:-translate-y-1/4'
  | 'xl:focus:-translate-y-2/4'
  | 'xl:focus:-translate-y-3/4'
  | 'xl:focus:-translate-y-1/5'
  | 'xl:focus:-translate-y-2/5'
  | 'xl:focus:-translate-y-3/5'
  | 'xl:focus:-translate-y-4/5'
  | 'xl:focus:-translate-y-1/6'
  | 'xl:focus:-translate-y-2/6'
  | 'xl:focus:-translate-y-3/6'
  | 'xl:focus:-translate-y-4/6'
  | 'xl:focus:-translate-y-5/6'
  | 'xl:focus:-translate-y-1/12'
  | 'xl:focus:-translate-y-2/12'
  | 'xl:focus:-translate-y-3/12'
  | 'xl:focus:-translate-y-4/12'
  | 'xl:focus:-translate-y-5/12'
  | 'xl:focus:-translate-y-6/12'
  | 'xl:focus:-translate-y-7/12'
  | 'xl:focus:-translate-y-8/12'
  | 'xl:focus:-translate-y-9/12'
  | 'xl:focus:-translate-y-10/12'
  | 'xl:focus:-translate-y-11/12'
  | 'xl:focus:-translate-y-full'
  | 'xl:skew-x-0'
  | 'xl:skew-x-3'
  | 'xl:skew-x-6'
  | 'xl:skew-x-12'
  | 'xl:-skew-x-12'
  | 'xl:-skew-x-6'
  | 'xl:-skew-x-3'
  | 'xl:skew-y-0'
  | 'xl:skew-y-3'
  | 'xl:skew-y-6'
  | 'xl:skew-y-12'
  | 'xl:-skew-y-12'
  | 'xl:-skew-y-6'
  | 'xl:-skew-y-3'
  | 'xl:hover:skew-x-0'
  | 'xl:hover:skew-x-3'
  | 'xl:hover:skew-x-6'
  | 'xl:hover:skew-x-12'
  | 'xl:hover:-skew-x-12'
  | 'xl:hover:-skew-x-6'
  | 'xl:hover:-skew-x-3'
  | 'xl:hover:skew-y-0'
  | 'xl:hover:skew-y-3'
  | 'xl:hover:skew-y-6'
  | 'xl:hover:skew-y-12'
  | 'xl:hover:-skew-y-12'
  | 'xl:hover:-skew-y-6'
  | 'xl:hover:-skew-y-3'
  | 'xl:focus:skew-x-0'
  | 'xl:focus:skew-x-3'
  | 'xl:focus:skew-x-6'
  | 'xl:focus:skew-x-12'
  | 'xl:focus:-skew-x-12'
  | 'xl:focus:-skew-x-6'
  | 'xl:focus:-skew-x-3'
  | 'xl:focus:skew-y-0'
  | 'xl:focus:skew-y-3'
  | 'xl:focus:skew-y-6'
  | 'xl:focus:skew-y-12'
  | 'xl:focus:-skew-y-12'
  | 'xl:focus:-skew-y-6'
  | 'xl:focus:-skew-y-3'
  | 'xl:transition-none'
  | 'xl:transition-all'
  | 'xl:transition'
  | 'xl:transition-colors'
  | 'xl:transition-opacity'
  | 'xl:transition-shadow'
  | 'xl:transition-transform'
  | 'xl:ease-linear'
  | 'xl:ease-in'
  | 'xl:ease-out'
  | 'xl:ease-in-out'
  | 'xl:duration-75'
  | 'xl:duration-100'
  | 'xl:duration-150'
  | 'xl:duration-200'
  | 'xl:duration-300'
  | 'xl:duration-500'
  | 'xl:duration-700'
  | 'xl:duration-1000'
)


/** Because of { exportClassNamesHelper: true } configuration. */
type ClassNamesValue = ClassNamesValue[] | TailwindClass | boolean | null | undefined | { [key in TailwindClass]: boolean};
type TailwindClassNamesFunction = (...classes: ClassNamesValue[]) => string
export const tw: TailwindClassNamesFunction = require('classnames')
